/* eslint-disable react-hooks/exhaustive-deps */
import { useFormik } from "formik";
import React, { useContext, useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import * as Yup from "yup";
import AppContext from "../../../../AppContext";
import useMultiLanguage from "../../../../hook/useMultiLanguage";
import Autocomplete from "../../../component/input-field/Autocomplete";
import { RangeDatePicker } from "../../../component/input-field/RangeDatePicker";
import TextValidator from "../../../component/input-field/TextValidator";
import { TYPE_CATEGORY } from "../../../constant";
import { getAllSimpleValue } from "../../../services";
import { RESPONSE_STATUS_CODE, TYPE, VARIABLE_STRING } from "../../../utils/Constant";
import { convertNumberPrice, convertTextPrice } from "../../../utils/FunctionUtils";
import { INIT_SALARY_DEVELOPMENT, salaryRate } from "../../const/ProfileConst";
import {
  addLichSuLuong,
  searchPhongBan_DonVi,
  updateLichSuLuong
} from "../../services/DialogServices";
import { convertDataSubmit, convertDataUI } from "../../utils/SalaryDevelopmentUtil";
import { useProfileContext } from "../../ProfileContext";
import UploadFile from "../../../component/FileUpload/UploadFile";
import { IFile } from "../../models/DialogModels";
import { FILE_TYPE } from "../../../component/FileUpload/constant";
import { ISalaryDevelopment } from "../../models/ProfileModels";
import { checkInvalidDate } from "../../../utils/ValidationSchema";
import Deduct from "../Partials/Deduct/Deduct";
import Allowance from "../Partials/Allowance/Allowance";

interface Props {
  handleClose: () => void;
  isView: boolean;
  salaryInfo: any;
  show: boolean;
  employeeProfiles: any;
  updateParentTable: any;
}

function SalaryDevelopmentDialog(props: Props) {
  const { handleClose, isView, salaryInfo, show, employeeProfiles, updateParentTable } = props;
  const { lang, intl } = useMultiLanguage();
  const { setPageLoading } = useContext(AppContext);
  const { isResign } = useProfileContext();

  const { SUCCESS } = RESPONSE_STATUS_CODE;
  const [isEdit, setIsEdit] = useState<boolean>(!isView);

  useEffect(() => {
    let data = null;
    if (salaryInfo?.id) {
      data = convertDataUI(salaryInfo);
    } else {
      data = {
        ...INIT_SALARY_DEVELOPMENT,
        phongBan: employeeProfiles?.phongBan,
        chucVu: employeeProfiles?.chucVu,
        viTriCongViec: employeeProfiles?.viTriCongViec,
      };
    }
    formik.setValues(data);
  }, [salaryInfo]);

  const handleFormSubmit = async (values: ISalaryDevelopment) => {
    try {
      setPageLoading(true);
      const dataSubmit = convertDataSubmit(values, employeeProfiles?.id);
      const { data, status } = await (!salaryInfo?.id
        ? addLichSuLuong(dataSubmit)
        : updateLichSuLuong(salaryInfo?.id, dataSubmit));
      if (status === SUCCESS && data?.code === SUCCESS) {
        toast.success(lang(`${salaryInfo?.id ? "TOAST.EDIT.SUCCESS" : "TOAST.ADD.SUCCESS"}`));
        updateParentTable();
        handleClose();
      } else {
        toast.error(data?.message);
      }
    } catch (error) {
      toast.error(lang("GENERAL.ERROR"));
    } finally {
      setPageLoading(false);
    }
  };

  const validationSchema = Yup.object().shape({
    phongBan: Yup.object().shape({}).nullable(),
    chucVu: Yup.object().shape({}).nullable(),
    viTriCongViec: Yup.object().nullable(),
    ngayHuongLuongTuNgay: Yup.date()
      .concat(checkInvalidDate(intl))
      .required(lang("VALIDATION.REQUIRE"))
      .nullable(),
    ngayHuongLuongDenNgay: Yup.date()
      .concat(checkInvalidDate(intl))
      .when("ngayHuongLuongTuNgay", {
        is: (ngayHuongLuongTuNgay: Date | null) => ngayHuongLuongTuNgay,
        then: Yup.date()
          .min(
            Yup.ref("ngayHuongLuongTuNgay"),
            lang("VALIDATION.MINDATE") +
            lang("INPUT.EFFECTIVEDATE_START")
          )
          .nullable()
      })
      .nullable(),
    luongCoBan: Yup.number().required(lang("VALIDATION.REQUIRE")).nullable(),
    phanTramHuong: Yup.object().required(lang("VALIDATION.REQUIRE")).nullable(),
  });

  const formik = useFormik({
    initialValues: INIT_SALARY_DEVELOPMENT,
    onSubmit: handleFormSubmit,
    validationSchema
  });

  const handleChangeSelect = (name: string, value: any) => {
    switch (name) {
      case VARIABLE_STRING.VI_TRI_CONG_VIEC:
        formik.setValues({
          ...formik.values,
          [name]: value,
        });
        break;
      default:
        formik.setFieldValue(name, value);
        break;
    }
  };

  const handleChangeMoney = (event: React.ChangeEvent<any>) => {
    const { name, value } = event.target;
    formik.setFieldValue(name, convertTextPrice(value));
  }

  const handleChangeFile = (data: IFile) => {
    formik.setFieldValue("fileId", data.id);
    formik.setFieldValue("fileName", data.name);
  };

  return (
    <Modal
      show={show}
      size="xl"
      centered
      aria-labelledby="example-custom-modal-styling-title"
      onHide={() => handleClose()}
    >
      <Modal.Header closeButton className="py-4">
        <Modal.Title
          id="example-custom-modal-styling-title"
          className="heading-5"
        >
          {salaryInfo?.id ? lang("GENERAL.INFO.SALARY.EDIT") : lang("GENERAL.INFO.SALARY.ADD")}
        </Modal.Title>
      </Modal.Header>
      <Form onSubmit={formik.handleSubmit}>
        <Modal.Body className="pt-4">
          <span className="fs-3 fw-bold">
            {lang("GENERAL.SALARY.INFORMATION")}
          </span>
          <Row className="ms-2 gap-col-modal spaces z-index-3">
            <Col xs={12} md={4} lg={4} xl={4} xxl={4} className="spaces pt-6">
              <Autocomplete
                lable={lang("INPUT.SALARY.DEPARTMENT")}
                name="phongBan"
                value={formik.values?.phongBan || null}
                isReadOnly={!isEdit}
                searchFunction={searchPhongBan_DonVi}
                searchObject={{ id: employeeProfiles?.donViCongTacId }}
                onChange={(value) => handleChangeSelect("phongBan", value)}
                urlData="data.data"
                options={[]}
              />
            </Col>
            <Col xs={12} md={4} lg={4} xl={3} xxl={3} className="spaces pt-6">
              <Autocomplete
                lable={lang("PROFILE.ROLE")}
                name="chucVu"
                value={formik.values?.chucVu || null}
                searchFunction={getAllSimpleValue}
                searchObject={TYPE_CATEGORY.chucDanh}
                isReadOnly={!isEdit}
                onChange={(value) => handleChangeSelect("chucVu", value)}
                options={[]}
                getOptionLabel={(options) => options.value}
              />
            </Col>
            <Col xs={12} md={4} lg={4} xl={3} xxl={3} className="spaces pt-6">
              <Autocomplete
                lable={lang("GENERAL.EMPLOYEE.JOB_POSITION")}
                name="viTriCongViec"
                value={formik.values?.viTriCongViec || null}
                searchFunction={getAllSimpleValue}
                searchObject={TYPE_CATEGORY.viTriCongViec}
                isReadOnly={!isEdit}
                onChange={(value) => handleChangeSelect(VARIABLE_STRING.VI_TRI_CONG_VIEC, value)}
                options={[]}
                getOptionLabel={(options) => options.value}
              />
            </Col>
            <Col xs={12} md={4} lg={3} xl={2} xxl={2} className="spaces pt-6">
              <TextValidator
                lable={lang("GENERAL.EMPLOYEE.BASIC_SALARY")}
                name="luongCoBan"
                isRequired={true}
                value={convertNumberPrice(formik.values?.luongCoBan || null)}
                type="text"
                readOnly={!isEdit}
                onChange={handleChangeMoney}
                errors={formik.errors?.luongCoBan}
                touched={formik.touched?.luongCoBan}
              />
            </Col>
            <Col xs={12} md={4} lg={4} xl={3} xxl={2} className="spaces pt-6">
              <Autocomplete
                lable={lang("INPUT.SALARY.RATE")}
                isReadOnly={!isEdit}
                options={salaryRate}
                value={formik.values?.phanTramHuong}
                name="phanTramHuong"
                isRequired
                onChange={(selectedOption) => handleChangeSelect("phanTramHuong", selectedOption)}
                errors={formik.errors?.phanTramHuong}
                touched={formik.touched?.phanTramHuong}
              />
            </Col>
            <Col  xs={12} md={4} lg={5} xl={4} xxl={4} className="spaces pt-6">
              <RangeDatePicker
                label={lang("INPUT.HEALTHINSURANCE.EFFECTIVEDATE")}
                isRequired
                startDateName="ngayHuongLuongTuNgay"
                endDateName="ngayHuongLuongDenNgay"
                isView={!isEdit}
                isReadOnlyEndDate={false}
                handleChange={formik.handleChange}
                value={formik.values}
                setFieldValue={formik.setFieldValue}
                touch={formik.touched}
                errors={formik.errors}
              />
            </Col>
            <Col xs={12} md={4} lg={4} xl={2} xxl={2} className="spaces pt-6">
              <TextValidator
                lable={lang("GENERAL.EMPLOYEE.INSURANCE_SALARY")}
                name="luongDongBaoHiem"
                value={convertNumberPrice(formik.values?.luongDongBaoHiem || null)}
                type="text"
                readOnly={!isEdit}
                onChange={handleChangeMoney}
              />
            </Col>
            <Col xs={6} md={4} lg={4} xl={3} xxl={4} className="spaces pt-6">
              <UploadFile
                label="CONTRACT.ATTACHMENTS"
                setValue={handleChangeFile}
                isReadOnly={!isEdit}
                fileValue={{
                  id: formik.values.fileId || "",
                  name: formik.values.fileName || ""
                }}
                allowFileTypes={`${FILE_TYPE.PDF},${FILE_TYPE.DOC},${FILE_TYPE.DOCX},${FILE_TYPE.XLS},${FILE_TYPE.XLSX}`}
              />
            </Col>
            <Col xs={12} className="spaces pt-6">
              <TextValidator
                className="h-auto-input spaces"
                lable={lang("ALLOWANCE.NOTE")}
                name="ghiChu"
                value={formik.values.ghiChu}
                type="text"
                readOnly={!isEdit}
                rows={2}
                onChange={formik.handleChange}
                as={TYPE.TEXTAREA}
              />
            </Col>
          </Row>
          {salaryInfo?.id && (
            <div className="mt-6">
              <Allowance
                isView={!isEdit}
                salaryId={salaryInfo.id || ""}
              />
              <Deduct
                isView={!isEdit}
                salaryId={salaryInfo.id || ""}
                employeeProfiles={employeeProfiles}
              />
            </div>
          )}
        </Modal.Body>
        <Modal.Footer className="flex-center p-4">
          <button
            type="button"
            className="button-gray-outline"
            onClick={() => handleClose()}
          >
            {lang("BTN.CANCEL")}
          </button>
          {!isResign &&
            (isEdit ? (
              <button type="submit" className="button-primary">
                {lang("BTN.SAVE")}
              </button>
            ) : (
              <Button
                variant="primary"
                className="button-primary btn-sm"
                onClick={() => setIsEdit(true)}
                type="button"
              >
                {lang("BTN.EDIT")}
              </Button>
            ))}
        </Modal.Footer>
      </Form>
    </Modal>
  );
}

export default SalaryDevelopmentDialog;
