/* eslint-disable react-hooks/exhaustive-deps */
import { useFormik } from "formik";
import { ChangeEvent, FC, useContext, useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import * as Yup from "yup";
import AppContext from "../../../AppContext";
import useMultiLanguage from "../../../hook/useMultiLanguage";
import UploadFile from "../../component/FileUpload/UploadFile";
import { FILE_TYPE } from "../../component/FileUpload/constant";
import { GroupButton } from "../../component/GroupButton";
import Autocomplete from "../../component/input-field/Autocomplete";
import { RangeDatePicker } from "../../component/input-field/RangeDatePicker";
import TextValidator from "../../component/input-field/TextValidator";
import { TYPE_CATEGORY } from "../../constant";
import { IFile } from "../../profile/models/DialogModels";
import { searchPhongBan_DonVi } from "../../profile/services/DialogServices";
import { getEmployeeProfileById } from "../../profile/services/ProfileServices";
import { getAllSimpleValue, getListWorkUnit, searchAllEmployee } from "../../utils/CategoryServices";
import { COMPLETED_STATUS, COMPLETED_STATUS_CODE, SEARCH_OBJECT_MAX_SIZE, TYPE, VARIABLE_STRING } from "../../utils/Constant";
import { handleBlurDate, hasRole } from "../../utils/FunctionUtils";
import { INIT_BO_NHIEM, LOAI, LOAI_BO_NHIEM } from "../const/AppointmentConst";
import { IAppointment } from "../models/IAppointment";
import { addNewAppointment, editAppointment } from "../services/AppointmentServices";
import { RESPONSE_STATUS_CODE } from "../../auth/core/_consts";
import { ROLE } from "../../../Constant";

interface Iprops {
  appointmentInfo: IAppointment;
  handleClose: () => void;
  handleCloseAndSearch: () => void;
  handleOpenUpdateDialog: () => void;
  handleCloseUpdateDialog: () => void;
  isView: boolean;
  setIsView: (value: boolean) => void;
}
const AppointmentForm: FC<Iprops> = (props) => {
  const { lang } = useMultiLanguage()
  const { setPageLoading } = useContext(AppContext);

  const {
    handleClose,
    handleCloseAndSearch,
    appointmentInfo,
    handleCloseUpdateDialog,
    isView,
    setIsView
  } = props;

  const [isCheckPhongBanMoi, setIsCheckPhongBanMoi] = useState<boolean>(appointmentInfo?.phongBanMoiKhac ? true : false);
  const [isCheckChucVuMoi, setIsCheckChucVuMoi] = useState<boolean>(appointmentInfo?.chucVuMoiKhac ? true : false);
  const [isShowAcceptAppointmentBtn, setIsShowAcceptAppointmentBtn] = useState<boolean>(false);
  const [isAcceptAppointment, setIsAcceptAppointment] = useState<boolean>(appointmentInfo.xacNhanBoNhiem || false);
  const [isShowBtnSave, setIsShowBtnSave] = useState<boolean>(true);

  const validationSchema = Yup.object().shape({
    employee: Yup.object()
      .required(lang("VALIDATION.REQUIRE"))
      .nullable(),
    maNhanVien: Yup.string()
      .required(lang("VALIDATION.REQUIRE"))
      .nullable(),
    loaiBoNhiem: Yup.object()
      .required(lang("VALIDATION.REQUIRE"))
      .nullable(),
    chucVuText: Yup.string()
      .required(lang("VALIDATION.REQUIRE"))
      .nullable(),
    donViCongTacText: Yup.string()
      .required(lang("VALIDATION.REQUIRE"))
      .nullable(),
    phongBanText: Yup.string()
      .required(lang("VALIDATION.REQUIRE"))
      .nullable(),
    thoiGianHieuLucTuNgay: Yup.date()
      .required(lang("VALIDATION.REQUIRE"))
      .nullable(),
    thoiGianHieuLucDenNgay: Yup.date()
      .min(Yup.ref("thoiGianHieuLucTuNgay"), lang("VALIDATION.MINDATE") + lang("GENERAL_EFFECTIVE_DATE_START"))
      .nullable(),
    nhiemKyTuNgay: Yup.date()
      .nullable(),
    nhiemKyDenNgay: Yup.date()
      .min(Yup.ref("nhiemKyTuNgay"), lang("VALIDATION.MINDATE") + lang("GENERAL_EFFECTIVE_DATE_START"))
      .nullable(),
    soQuyetDinh: Yup.string()
      .required(lang("VALIDATION.REQUIRE"))
      .nullable()
      .max(255, lang("VALIDATION.MAX255")),
    donViCongTacMoi: Yup.object()
      .required(lang("VALIDATION.REQUIRE"))
      .nullable(),
    phongBanMoi: Yup.object()
      .required(lang("VALIDATION.REQUIRE"))
      .nullable(),
    chucVuMoi: Yup.object()
      .required(lang("VALIDATION.REQUIRE"))
      .nullable()
  });

  useEffect(() => {
    if(!appointmentInfo?.id){
      setIsShowAcceptAppointmentBtn(true);
    }else{
      setIsShowAcceptAppointmentBtn(!appointmentInfo?.xacNhanBoNhiem)
    }
  },[appointmentInfo])

  useEffect(() => {
    if(!appointmentInfo?.id){
      formik.setValues({
        ...INIT_BO_NHIEM,
        loaiBoNhiem: LOAI_BO_NHIEM.find(option => option.code === LOAI.BO_NHIEM_LAN_DAU) || null
      })
    } else {
      let values = {
        ...appointmentInfo,
        donViCongTacMoi: {
          id: appointmentInfo?.donViCongTacMoiId,
          name: appointmentInfo?.donViCongTacMoiText
        },
        phongBanMoi: {
          id: appointmentInfo?.phongBanMoiId,
          name: appointmentInfo?.phongBanMoiText
        },
        chucVuMoi: {
          id: appointmentInfo?.chucVuMoiId,
          value: appointmentInfo?.chucVuMoiText
        }
      }
      formik.setValues(values);
    }
    if (appointmentInfo?.employeeId) {
      getEmployeeInfo(appointmentInfo.employeeId);
    }
  }, [appointmentInfo]);

  const getEmployeeInfo = async (id: string) => {
    try {
      setPageLoading(true);
      const { data } = await getEmployeeProfileById(id);
      if (data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
        formik.setFieldValue(VARIABLE_STRING.EMPLOYEE, data?.data);
      }else{
        toast.error(data?.message);
      }
      setPageLoading(false);
    } catch (error) {
      setPageLoading(false);
      console.error(error);
      toast.error(lang("GENERAL.ERROR"));
    }
  };

  const handleEmployeeChange = (name: string, employeeInfo: any) => {
    let addInfoHasUserRole = hasRole(ROLE.USER)
      ? {
          donViCongTacMoi: {
            id: employeeInfo.donViCongTacId || "",
            name: employeeInfo.donViCongTacText || ""
          },
          donViCongTacMoiId: employeeInfo.donViCongTacId || "",
          donViCongTacMoiText: employeeInfo.donViCongTacText || ""
        }
      : {};
      
    formik.setValues({
      ...formik.values,
      maNhanVien: employeeInfo.maNhanVien || "",
      employee: employeeInfo,
      employeeId: employeeInfo.id,
      fullName: employeeInfo.name,
      donViCongTacText: employeeInfo.donViCongTacText || "",
      donViCongTacId: employeeInfo.donViCongTacId || "",
      phongBanId: employeeInfo.phongBanId || "",
      phongBanText: employeeInfo.phongBanText || "",
      chucVuId: employeeInfo.chucVuId || "",
      chucVuText: employeeInfo.chucVuText || "",
      ...addInfoHasUserRole
    })
  }

  const convertDataSubmit = (data: IAppointment) => {
    return {
      ...data,
      chucVuMoiId: data?.chucVuMoi?.id,
      chucVuMoiText: data?.chucVuMoi?.value,
      chucVuText: data?.employee?.chucVuText,
      donViCongTacMoiId: data?.donViCongTacMoi?.id,
      donViCongTacMoiText: data?.donViCongTacMoi?.name,
      maNhanVien: data?.employee?.maNhanVien,
      phongBanMoiId: data?.phongBanMoi?.id,
      phongBanMoiText: data?.phongBanMoi?.name,
      phongBanId: data?.employee?.phongBanId,
      phongBanText: data?.employee?.phongBanText,
      ten: data?.employee?.name,
      xacNhanBoNhiem: isAcceptAppointment
    }
  }

  const handleFile = (data: IFile) => {
    formik.setFieldValue("fileId", data.id);
    formik.setFieldValue("fileName", data.name);
  }

  const handleChangeSelect = (name: string, value: any) => {
    switch (name) {
      case VARIABLE_STRING.LOAI_BO_NHIEM:
        formik.setFieldValue(VARIABLE_STRING.LOAI_BO_NHIEM, value);
        break;
      case VARIABLE_STRING.DON_VI_CONG_TAC_MOI:
        formik.setValues({
          ...formik.values,
          [name]: value,
          [VARIABLE_STRING.PHONG_BAN_MOI]: null,
          [VARIABLE_STRING.CHUC_VU_MOI]: null
        });
        break;
      case VARIABLE_STRING.PHONG_BAN_MOI:
        formik.setValues({
          ...formik.values,
          [name]: value,
          [VARIABLE_STRING.CHUC_VU_MOI]: null,
          [VARIABLE_STRING.PHONG_BAN_MOI_KHAC]: null
        });
        break;
      case VARIABLE_STRING.CHUC_VU_MOI:
        formik.setValues({
          ...formik.values,
          [name]: value,
          [VARIABLE_STRING.CHUC_VU_MOI_KHAC]: null
        });
        break;
      default:
        formik.setFieldValue(name, value);
        break;
    }
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>, nameObj: string) => {
    const { name, value } = event.target
    formik.setFieldValue(name, value)
    formik.setFieldValue(nameObj, null)
  }

  const handleSubmit = async (values: IAppointment) => {
    try {
      setPageLoading(true);
      const res = appointmentInfo.id
        ? await editAppointment(appointmentInfo.id, convertDataSubmit(values))
        : await addNewAppointment(convertDataSubmit(values));
      if (res?.data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
        toast.success(
          appointmentInfo.id
            ? lang("TOAST.EDIT.SUCCESS")
            : lang("TOAST.CREATE.SUCCESS")
        );
        handleCloseAndSearch();
      } else {
        toast.error(`${res?.data?.message}`);
      }
    } catch (err) {
      toast.error(lang("GENERAL.ERROR"));
    } finally {
      setPageLoading(false)
    }
  };

  const formik = useFormik({
    initialValues: INIT_BO_NHIEM ,
    validationSchema,
    onSubmit: handleSubmit
  })

  const handleAcceptAppointment = () => {
    setIsAcceptAppointment(true);
    formik.handleSubmit();
  }

  const handleChangeDate = (event: React.ChangeEvent<any>) => {
    const { name, value } = event.target;
    formik.setFieldValue(name, value);
    if (name === VARIABLE_STRING.THOI_GIAN_HIEU_LUC_TU_NGAY) {
      const effectiveDate = value ? new Date(value) : null;
      const currentDate = new Date();
      if (effectiveDate && effectiveDate <= currentDate) {
        setIsShowBtnSave(false);
      } else setIsShowBtnSave(true);
    }
  }
  
  return (
    <div className="addNewContract h-full">
      <Row className="contract-header header-box h-full">
        <Col xs={12}>
        <div className="profile-title spaces py-12">
            <GroupButton type="btn-back" handleClose={handleClose} />
            <div className="flex gap-4">
              {(!isView && appointmentInfo?.trangThai?.code === COMPLETED_STATUS_CODE.UNFULFILLED && appointmentInfo?.id) && (
                <Button
                  variant="outline-secondary"
                  className="button-secondary btn-sm btn"
                  onClick={handleCloseUpdateDialog}
                >
                  {lang("BTN.CANCEL")}
                </Button>
              )}
              {isView && appointmentInfo?.id && appointmentInfo?.trangThai?.code !== COMPLETED_STATUS_CODE.COMPLETED && !appointmentInfo.xacNhanBoNhiem && (
                <GroupButton
                  type="btn-edit"
                  handleSaveEdit={() => setIsView(false)}
                />
              )}
              {(!isView && isShowBtnSave && (appointmentInfo?.trangThai?.code === COMPLETED_STATUS_CODE.UNFULFILLED || !appointmentInfo?.id)) && (
                <GroupButton
                  type="btn-save"
                  handleClose={handleCloseUpdateDialog}
                  handleSubmit={formik.handleSubmit}
                  value={formik.values}
                />
              )}
              {isShowAcceptAppointmentBtn && (
                <button className="button-primary" type="button" onClick={handleAcceptAppointment}>{lang("APPOINTMENT.ACCEPT")}</button>
              )}
            </div>
          </div>
        </Col>
      </Row>

      <div className="layout-v2 flex align-items-start">
        <div className="flex-1 overflow-auto h-100">
          <div className="form-container add-contract">
            <Form onSubmit={formik.handleSubmit}>
              <div className="sub-title-form">{appointmentInfo?.id ? lang("APPOINTMENT.EDIT") : lang("APPOINTMENT.ADD")}</div>
              <div className="sub-title pb-2 pt-0">{lang("APPOINTMENT_INFO_EMPLOYEE")}</div>
              <Row className="g-4">
                <Col sm={4} md={4} lg={4} xl={3} xxl={3}>
                  <Autocomplete
                    isRequired
                    lable={lang("GENERAL.FULLNAME")}
                    options={[]}
                    className="spaces"
                    onChange={(value) => handleEmployeeChange("employee", value)}
                    name="employee"
                    value={formik.values?.employee || null}
                    searchFunction={searchAllEmployee}
                    searchObject={{ ...SEARCH_OBJECT_MAX_SIZE, isDangLamViec: true }}
                    isReadOnly={isView}
                    errors={formik.errors.employee}
                    touched={formik.touched.employee}
                    getOptionLabel={(option) => `${option?.maNhanVien} - ${option?.name}`}
                  />
                </Col>
                <Col sm={3} md={3} lg={3} xl={2} xxl={3}>
                  <TextValidator
                    name="maNhanVien"
                    lable={lang("INPUT_APPOINTMENT_CODE")}
                    type="text"
                    value={formik.values?.maNhanVien}
                    readOnly={true}
                    errors={formik?.errors?.maNhanVien}
                    touched={formik?.touched?.maNhanVien}
                    isRequired
                  />
                </Col>
                <Col sm={5} md={5} lg={5} xl={3} xxl={3}>
                  <TextValidator
                    name="donViCongTacText"
                    lable={lang("INPUT_APPOINTMENT_WORK_UNINT")}
                    type="text"
                    value={formik.values?.donViCongTacText}
                    readOnly={true}
                    isRequired
                    errors={formik?.errors?.donViCongTacText}
                    touched={formik?.touched?.donViCongTacText}
                  />
                </Col>
                <Col sm={6} md={5} lg={5} xl={4} xxl={3}>
                  <TextValidator
                    name="phongBanText"
                    lable={lang("INPUT_APPOINTMENT_UNIT_DEPARTMENT")}
                    type="text"
                    value={formik.values?.phongBanText}
                    readOnly={true}
                    isRequired
                    errors={formik?.errors?.phongBanText}
                    touched={formik?.touched?.phongBanText}
                  />
                </Col>
                <Col sm={6} md={5} lg={5} xl={4} xxl={4}>
                  <TextValidator
                    name="chucVuText"
                    lable={lang("INPUT_APPOINTMENT_JOB_POSITION")}
                    type="text"
                    value={formik.values?.chucVuText}
                    readOnly={true}
                    isRequired
                    errors={formik?.errors?.chucVuText}
                    touched={formik?.touched?.chucVuText}
                  />
                </Col>
              </Row>
              <div className="sub-title pb-2">{lang("APPOINTMENT_INFO")}</div>
              <Row className="g-4">
                <Col sm={6} md={3} lg={3} xl={3} xxl={2}>
                  <Autocomplete
                    isRequired
                    lable={lang("INPUT_APPOINTMENT_TYPE")}
                    options={LOAI_BO_NHIEM}
                    isReadOnly={isView}
                    value={formik.values?.loaiBoNhiem || null}
                    name="loaiBoNhiem"
                    onChange={(selectedOption) => handleChangeSelect(VARIABLE_STRING.LOAI_BO_NHIEM, selectedOption)}
                    touched={formik.touched?.loaiBoNhiem}
                    errors={formik.errors?.loaiBoNhiem}
                  />
                </Col>
                <Col sm={6} md={4} lg={4} xl={4} xxl={3}>
                  <Autocomplete
                    name="donViCongTacMoi"
                    lable={lang("INPUT_APPOINTMENT_WORK_UNINT_NEW")}
                    searchFunction={getListWorkUnit}
                    searchObject={{}}
                    onChange={(value) => handleChangeSelect(VARIABLE_STRING.DON_VI_CONG_TAC_MOI, value)}
                    value={formik.values.donViCongTacMoi || null}
                    isRequired
                    options={[]}
                    errors={formik?.errors?.donViCongTacMoi}
                    touched={formik?.touched?.donViCongTacMoi}
                    isReadOnly={hasRole(ROLE.USER)}
                  />
                </Col>
                <Col sm={6} md={5} lg={5} xl={5} xxl={4}>
                  <Autocomplete
                    lable={lang("INPUT_APPOINTMENT_UNIT_DEPARTMENT_NEW")}
                    isReadOnly={isView}
                    searchFunction={searchPhongBan_DonVi}
                    searchObject={{ id: formik.values?.donViCongTacMoi?.id }}
                    options={[]}
                    isRequired
                    formCheckBox={true}
                    value={isCheckPhongBanMoi ? formik.values?.phongBanMoiKhac : formik.values?.phongBanMoi}
                    name={isCheckPhongBanMoi ? VARIABLE_STRING.PHONG_BAN_MOI_KHAC : VARIABLE_STRING.PHONG_BAN_MOI}
                    setIsCheckBox={setIsCheckPhongBanMoi}
                    isCheckBox={isCheckPhongBanMoi}
                    onChange={(selectedOption) => isCheckPhongBanMoi ? handleChange(selectedOption, VARIABLE_STRING.PHONG_BAN_MOI) : handleChangeSelect(VARIABLE_STRING.PHONG_BAN_MOI, selectedOption)}
                    urlData="data.data"
                    errors={isCheckPhongBanMoi ? formik.errors?.phongBanMoiKhac : formik.errors?.phongBanMoi}
                    touched={isCheckPhongBanMoi ? formik.touched?.phongBanMoiKhac : formik.touched?.phongBanMoi}
                    dependencies={[formik.values?.donViCongTacMoi]}
                  />
                </Col>
                <Col sm={6} md={4} lg={4} xl={4} xxl={3}>
                  <Autocomplete
                    lable={lang("INPUT_APPOINTMENT_NEW_JOB_POSITION_NEW")}
                    isReadOnly={isView}
                    searchFunction={getAllSimpleValue}
                    searchObject={TYPE_CATEGORY.chucDanh}
                    options={[]}
                    isRequired
                    formCheckBox={true}
                    value={isCheckChucVuMoi ? formik.values?.chucVuMoiKhac : formik.values?.chucVuMoi}
                    name={isCheckChucVuMoi ? VARIABLE_STRING.CHUC_VU_MOI_KHAC : VARIABLE_STRING.CHUC_VU_MOI}
                    setIsCheckBox={setIsCheckChucVuMoi}
                    isCheckBox={isCheckChucVuMoi}
                    onChange={(selectedOption) => isCheckChucVuMoi ? handleChange(selectedOption, VARIABLE_STRING.CHUC_VU_MOI) : handleChangeSelect(VARIABLE_STRING.CHUC_VU_MOI, selectedOption)}
                    errors={isCheckChucVuMoi ? formik.errors?.chucVuMoiKhac : formik.errors?.chucVuMoi}
                    touched={isCheckChucVuMoi ? formik.touched?.chucVuMoiKhac : formik.touched?.chucVuMoi}
                    getOptionLabel={(option) => option?.value}
                  />
                </Col>
                <Col sm={8} md={5} lg={5} xl={5} xxl={3}>
                  <RangeDatePicker
                    label={lang("INPUT_APPOINTMENT_TIME_EFFECT")}
                    startDateName="thoiGianHieuLucTuNgay"
                    endDateName="thoiGianHieuLucDenNgay"
                    isView={isView}
                    isRequired
                    handleChange={handleChangeDate}
                    value={formik.values}
                    setFieldValue={formik.setFieldValue}
                    touch={formik.touched}
                    errors={formik.errors}
                  />
                </Col>
                <Col sm={4} md={3} lg={3} xl={3} xxl={3}>
                  <TextValidator
                    name="soQuyetDinh"
                    lable={lang("INPUT_APPOINTMENT_NUMBER")}
                    type="text"
                    value={formik.values?.soQuyetDinh}
                    readOnly={isView}
                    isRequired
                    onChange={formik.handleChange}
                    errors={formik?.errors?.soQuyetDinh}
                    touched={formik?.touched?.soQuyetDinh}
                  />
                </Col>
                <Col sm={6} md={3} lg={2} xl={3} xxl={3}>
                  <TextValidator
                    name="ngayQuyetDinh"
                    lable={lang("INPUT_APPOINTMENT_CONFIRM_DATE")}
                    type="date"
                    value={formik.values?.ngayQuyetDinh}
                    readOnly={isView}
                    onChange={formik.handleChange}
                    onBlur={() => handleBlurDate(formik.setFieldValue, formik.values?.ngayQuyetDinh, "ngayQuyetDinh")}
                    errors={formik?.errors?.ngayQuyetDinh}
                    touched={formik?.touched?.ngayQuyetDinh}
                  />
                </Col>
                <Col sm={6} md={3} lg={4} xl={3} xxl={3}>
                  <RangeDatePicker
                    label={lang("INPUT_APPOINTMENT_TERM")}
                    startDateName="nhiemKyTuNgay"
                    endDateName="nhiemKyDenNgay"
                    isView={isView}
                    handleChange={formik.handleChange}
                    value={formik.values}
                    setFieldValue={formik.setFieldValue}
                    touch={formik.touched}
                    errors={formik.errors}
                  />
                </Col>
                <Col sm={6} md={3} lg={3} xl={3} xxl={3}>
                  <UploadFile
                    label="INPUT_APPOINTMENT_ATTACHMENTS"
                    setValue={handleFile}
                    fileValue={{
                      id: formik.values.fileId || "",
                      name: formik.values.fileName || ""
                    }}
                    allowFileTypes={`${FILE_TYPE.PDF},${FILE_TYPE.DOC},${FILE_TYPE.DOCX},${FILE_TYPE.XLS},${FILE_TYPE.XLSX}`}
                  />
                </Col>
                <Col sm={6} md={3} lg={3} xl={3} xxl={3}>
                  <Autocomplete
                    lable={lang("GENERAL.STATUS")}
                    isReadOnly={isView}
                    options={COMPLETED_STATUS}
                    value={formik.values?.trangThai || null}
                    name="trangThai"
                    onChange={(selectedOption) => handleChangeSelect(VARIABLE_STRING.TRANG_THAI, selectedOption)}
                    errors={formik.errors?.trangThai}
                    touched={formik.touched?.trangThai}
                  />
                </Col>
                <Col xs={12}>
                  <TextValidator
                    className="h-auto-input spaces"
                    lable={lang("ALLOWANCE.NOTE")}
                    name="ghiChu"
                    value={formik.values.ghiChu}
                    type="text"
                    readOnly={isView}
                    rows={5}
                    onChange={formik.handleChange}
                    as={TYPE.TEXTAREA}
                  />
                </Col>
              </Row>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
};
export default AppointmentForm;
