import { ILang } from "../../../hook/useMultiLanguage";
import { TYPE_CATEGORY } from "../../constant";
import { searchNguoiDaiDienKy } from "../../contract/services/contractServices";
import { OptionReactSelect } from "../../models/models";
import { getAllDonVi, getAllEmployee } from "../../profile/services/ProfileServices";
import { searchAllSimpleValue } from "../../services";
import { MISSION_STATUS, SEARCH_OBJECT_MAX_SIZE, TYPE } from "../../utils/Constant";
import { BonusInfo } from "../model/BonusModel";

export const bonusType: OptionReactSelect[] = [
  {
    name: "Đảng",
    code: 1
  },
  {
    name: "Chính quyền",
    code: 2
  },
  {
    name: "Đoàn thể",
    code: 3
  },
  {
    name: "Khác",
    code: 4
  }
];

export const OBJECT_BONUS_CODE = {
  PERSONAL: 1,
  GROUP: 2
};

export const bonusObject: OptionReactSelect[] = [
  {
    name: "Cá nhân",
    code: OBJECT_BONUS_CODE.PERSONAL
  },
  {
    name: "Tập thể",
    code: OBJECT_BONUS_CODE.GROUP
  }
];

export const objectDetails: OptionReactSelect[] = [
  {
    name: "Nhân viên",
    code: 1
  },
  {
    name: "Phòng ban",
    code: 2
  },
  {
    name: "Đội nhóm",
    code: 3
  }
];

export const bonusLevel: OptionReactSelect[] = [
  {
    name: "Công ty",
    code: 1
  },
  {
    name: "Chi nhánh",
    code: 2
  },
  {
    name: "Phòng ban",
    code: 3
  }
];

export const bonusForm: OptionReactSelect[] = [
  {
    name: "Huân chương",
    code: 1
  },
  {
    name: "Huy chương",
    code: 2
  },
  {
    name: "Danh hiệu vinh dự nhà nước",
    code: 3
  },
  {
    name: "Giải thưởng Hồ Chí Minh “Giải thưởng vinh dự nhà nước”",
    code: 4
  },
  {
    name: "Kỷ niệm chương và huy hiệu",
    code: 5
  },
  {
    name: "Giấy khen",
    code: 6
  },
  {
    name: "Chưa xác định",
    code: 7
  }
];

export const bonusDecisionLevel: OptionReactSelect[] = [
  {
    name: "Cấp nhà nước",
    code: 1
  },
  {
    name: "Cấp bộ",
    code: 2
  },
  {
    name: "Cấp tỉnh",
    code: 3
  },
  {
    name: "Cấp huyện",
    code: 4
  },
  {
    name: "Cấp cơ sở",
    code: 5
  },
  {
    name: "Khác",
    code: 6
  },
  {
    name: "Bệnh viện",
    code: 7
  }
];

export const INIT_BONUS_INFO: BonusInfo = {
  ahtgDieuChinhLuong: false,
  thoiGianHuongLuongKeoDai: null,
  capQuyetDinh: null,
  daXetDieuChinhLuong: false,
  danhHieuThiDuaId: "",
  danhHieuThiDuaText: "",
  doiTuongKhenThuong: null,
  donViBanHanhId: "",
  donViBanHanhText: "",
  dotKhenThuong: "",
  file: "",
  ghiChu: "",
  hinhThucKhenThuongId: "",
  hinhThucKhenThuongText: "",
  khenThuongCaoNhat: false,
  khenThuongList: null,
  loaiKhenThuong: null,
  namKhenThuong: "",
  ngayBanHanh: "",
  ngayKhenThuong: "",
  noiDungKhenThuong: "",
  soQuyetDinh: "",
  trangThai: null,
  hinhThucKhenThuong: null,
  danhHieuThiDua: null,
  donViBanHanh: null,
  fileId: "",
  fileName: "",
  nguoiKyQuyetDinhText: "",
  nguoiKyQuyetDinhId: "",
  nguoiKyQuyetDinh: null
};

export const bonusEmulation: OptionReactSelect[] = [
  {
    code: 1,
    name: "Chưa xác định"
  },
  {
    code: 2,
    name: "Huân chương Sao vàng"
  },
  {
    code: 3,
    name: "Huân chương Hồ Chí Minh"
  },
  {
    code: 4,
    name: "Huân chương Độc lập hạng nhất"
  },
  {
    code: 5,
    name: "Huân chương Độc lập hạng nhì"
  },
  {
    code: 6,
    name: "Huân chương hạng ba"
  },
  {
    code: 7,
    name: "Huân chương Quân công hạng nhất"
  },
  {
    code: 8,
    name: "Huân chương Quân công hạng nhì"
  },
  {
    code: 9,
    name: "Huân chương Quân công hạng ba"
  },
  {
    code: 10,
    name: "Huân chương Lao động hạng nhất"
  },
  {
    code: 11,
    name: "Huân chương Lao động hạng nhì"
  },
  {
    code: 12,
    name: "Huân chương Lao động hạng ba"
  }
];

export const ListSearch = (lang: ILang) => {
  return [
    {
      name: lang("APPRECIATIVE.NAME"),
      field: "dotKhenThuong",
      type: TYPE.TEXT
    },
    {
      name: lang("APPRECIATIVE.YEAR"),
      field: "namKhenThuong",
      type: TYPE.TEXT
    },
    {
      name: lang("APPRECIATIVE.LOAIKHENTHUONGTEXT"),
      field: "loaiKhenThuong",
      type: TYPE.SELECT,
      listOption: bonusType
    },
    {
      name: lang("APPRECIATIVE.DOITUONGKHENTHUONGTEXT"),
      field: "doiTuongKhenThuong",
      type: TYPE.SELECT,
      listOption: bonusObject
    },
    {
      name: lang("APPRECIATIVE.HINHTHUCKHENTHUONGTEXT"),
      field: "hinhThucKhenThuongId",
      type: TYPE.SELECT,
      searchFunction: searchAllSimpleValue,
      searchObject: { type: TYPE_CATEGORY.hinhThucKhenThuong },
      optionLabel: "value"
    },
    {
      name: lang("APPRECIATIVE.SOQUYETDINH"),
      field: "soQuyetDinh",
      type: TYPE.TEXT
    },
    {
      name: lang("APPRECIATIVE.PERSON_SINGING"),
      field: "nguoiKyQuyetDinhId",
      type: TYPE.SELECT,
      searchFunction: searchNguoiDaiDienKy,
      searchObject: { ...SEARCH_OBJECT_MAX_SIZE }
    },
    {
      name: lang("APPRECIATIVE.AGENCIES_UNIT_DECITION"),
      field: "donViBanHanh",
      type: TYPE.SELECT,
      searchFunction: getAllDonVi,
      searchObject: { ...SEARCH_OBJECT_MAX_SIZE }
    },
    {
      name: lang("INPUT.EMPLOYEE"),
      field: "employeeId",
      type: TYPE.SELECT,
      searchFunction: getAllEmployee,
      searchObject: { ...SEARCH_OBJECT_MAX_SIZE }
    },
    {
      name: lang("APPRECIATIVE.TRANGTHAITEXT"),
      field: "trangThai",
      type: TYPE.SELECT,
      listOption: MISSION_STATUS
    },
  ];
}