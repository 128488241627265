import axios, { AxiosResponse } from "axios";
import { IProblem } from "../models/ProblemModel";
import { paramsConfig } from "../../utils/ParamsUtils";

const API_URL = process.env.REACT_APP_API_URL;

export const addNewProblem = (data: IProblem) => {
  const url = API_URL + "/su-co";
  return axios.post(url, data);
}

export const updateProblem = (data: IProblem, id: string) => {
  const url = `${API_URL}/su-co/${id}`;
  return axios.put(url, data);
}

export const searchProblem = (searchData?: object) => {
  let url = `${API_URL}/su-co/page`;
  return axios.get(url, paramsConfig(searchData || {}));
};

export const deleteProblem = (ids: string[] | string) => {
  let url = `${API_URL}/su-co/ids`;
  return axios.delete(url, paramsConfig({ids: ids.toString()}));
};

export const getEmployeeListByProblemId = (problemId: string) => {
  let url = `${API_URL}/su-co-list/su-co/${problemId}`;
  return axios.get(url);
};

export const searchProblemProfile = (searchData: object) => {
  let url = `${API_URL}/su-co-list/page`;
  return axios.get(url, paramsConfig(searchData || {}));
}