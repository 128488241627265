import { GroupButton } from "../../component/GroupButton";
import { IProblem, IProblemType } from "../models/ProblemModel";
import { useFormik } from "formik";
import {
  INIT_PROBLEM,
  PROBLEM_OBJECTS,
  PROBLEM_OBJECTS_CODE,
  PROBLEM_STATUS_CODE,
  PROBLEM_STATUS_LIST,
  PROBLEM_TYPE_CODES,
  PROLEM_FORM_PROCESS_CODES
} from "../constant/ProblemConst";
import * as Yup from "yup";
import { Button, Col, Form, Row } from "react-bootstrap";
import useMultiLanguage from "../../../hook/useMultiLanguage";
import { KTSVG } from "../../../../_metronic/helpers";
import {
  convertNumberPrice,
  convertTextPrice,
  exportToFile,
  getFullYear,
  handleBlurDate
} from "../../utils/FunctionUtils";
import { EXPORT_FILE_NAME } from "../../../Constant";
import { useContext, useEffect, useState } from "react";
import AppContext from "../../../AppContext";
import TextValidator from "../../component/input-field/TextValidator";
import { LIST_MONTH, RESPONSE_STATUS_CODE, SEARCH_OBJECT_MAX_SIZE, TYPE, VARIABLE_STRING } from "../../utils/Constant";
import Autocomplete from "../../component/input-field/Autocomplete";
import { IFile } from "../../profile/models/DialogModels";
import UploadFile from "../../component/FileUpload/UploadFile";
import { FILE_TYPE } from "../../component/FileUpload/constant";
import { ColCustom, RowCustom } from "../../component/GridComponent";
import TableCollapseCustom, { columnNamesType } from "../../component/table-collapse-custom/TableCollapseCustom";
import SelectEmployeeByDepartment from "../../component/SelectEmployeeByDepartment/SelectEmployeeByDepartment";
import { STATUS_NV } from "../../profile/const/DialogChildConstants";
import { convertDataEmployeeSelect, convertDataSubmit, convertDataUI } from "../utils/ProblemUtils";
import SelectEmployeeDialog from "../../component/SelectEmployeeDialog/SelectEmployeeDialog";
import { searchAllSimpleValue, searchSimpleValueById } from "../../services";
import { TYPE_CATEGORY } from "../../constant";
import { toast } from "react-toastify";
import { addNewProblem, getEmployeeListByProblemId, updateProblem } from "../services/ProblemServices";
import { exportSuCoChiTiet } from "../../utils/ExportFileServices";
import { searchAllEmployee } from "../../utils/CategoryServices";

type IProps = {
  problemInfo: IProblem;
  isView: boolean;
  setIsView: (value: boolean) => void;
  handleCloseDialog: () => void;
  handleCloseAndSearch: () => void;
  handleOpenUpdateDialog: () => void;
  handleCloseUpdateDialog: () => void;
};

function ProblemForm(props: IProps) {
  const {
    problemInfo,
    isView,
    handleCloseDialog,
    handleCloseUpdateDialog,
    handleCloseAndSearch,
    handleOpenUpdateDialog
  } = props;
  const { lang } = useMultiLanguage();
  const { setPageLoading } = useContext(AppContext);

  const [problemTypes, setProblemTypes] = useState<any[]>([]);
  const [employeeChecked, setEmployeeChecked] = useState<any[]>([]);
  const [employeeList, setEmployeeList] = useState<any[]>([]);
  const [shouldOpenSelectEmployeeDialog, setShouldOpenSelectEmployeeDialog] = useState<boolean>(false);
  const [shouldOpenEmployeeListSigleDialog, setShouldOpenEmployeeListSigleDialog] = useState<boolean>(false);

  useEffect(() => {
    if (problemInfo?.id) {
      formik.setValues(convertDataUI(problemInfo));
      getEmployeeList();
      getFormProcess();
    }
  }, [problemInfo]);

  useEffect(() => {
    getProblemTypes();
  }, []);

  const getFormProcess = async () => {
    try {
      setPageLoading(true);
      const { data } = await searchSimpleValueById(String(problemInfo?.hinhThucXuLyId));
      if (data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
        formik.setFieldValue("hinhThucXuLyCode", data?.data?.mappingName);
      }
    } catch (error) {
      toast.error(lang("GENERAL.ERROR"));
    } finally {
      setPageLoading(false);
    }
  };

  const getEmployeeList = async () => {
    try {
      setPageLoading(true);
      const { data } = await getEmployeeListByProblemId(String(problemInfo?.id));
      if (data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
        setEmployeeList(data?.data || []);
      }
    } catch (error) {
      toast.error(lang("GENERAL.ERROR"));
    } finally {
      setPageLoading(false);
    }
  };

  const getProblemTypes = async () => {
    try {
      setPageLoading(true);
      const { data } = await searchAllSimpleValue({
        type: TYPE_CATEGORY.loaiSuCo,
        ...SEARCH_OBJECT_MAX_SIZE
      });
      if (data?.code !== RESPONSE_STATUS_CODE.SUCCESS) return toast.error(data?.message);

      setProblemTypes(data?.data?.content || []);
      if (!problemInfo?.id) {
        formik.setFieldValue(VARIABLE_STRING.LOAI_SU_CO, data?.data?.content[0] || null);
      } else {
        let prolemTypeFinded = data?.data?.content.find((item: IProblemType) => item?.id === problemInfo?.loaiSuCoId);
        formik.setFieldValue(VARIABLE_STRING.LOAI_SU_CO, prolemTypeFinded || null);
      }
    } catch (error) {
      toast.error(lang("GENERAL.ERROR"));
    } finally {
      setPageLoading(false);
    }
  };

  const handleSubmit = async (values: IProblem) => {
    if (employeeList?.length <= 0) return toast.warning(lang("TOAST.PROBLEM.PLEASE_ADD_EMPLOYEE_LIST"));
    if (
      !(employeeList?.length > 1) &&
      !(employeeList[0]?.data?.length > 1) &&
      formik.values?.doiTuong?.code === PROBLEM_OBJECTS_CODE.GROUP
    )
      return toast.warning(lang("TOAST.EMPLOYEE.GROUP_BIGEST_TWO"));
    try {
      setPageLoading(true);
      const dataSubmit = convertDataSubmit(values, employeeList);
      const { data } = await (!problemInfo?.id
        ? addNewProblem(dataSubmit)
        : updateProblem(dataSubmit, problemInfo?.id));
      if (data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
        let message = problemInfo?.id ? "TOAST.PROBLEM.EDIT_SUCCESS" : "TOAST.PROBLEM.ADD_SUCCESS";
        toast.success(lang(message));
        handleCloseAndSearch();
      } else {
        toast.error(data?.message);
      }
    } catch (error) {
      toast.error(lang("GENERAL.ERROR"));
    } finally {
      setPageLoading(false);
    }
  };

  const validationSchema = Yup.object().shape({
    doiTuong: Yup.object().required(lang("VALIDATION.REQUIRE")).nullable(),
    ngayXayRa: Yup.date()
      .required(lang("VALIDATION.REQUIRE"))
      .max(new Date(), lang("VALIDATON.DATE_CURRENT"))
      .nullable(),
    tenSuCo: Yup.string().required(lang("VALIDATION.REQUIRE")).nullable(),
    loaiSuCo: Yup.object().required(lang("VALIDATION.REQUIRE")).nullable(),
    trangThai: Yup.object().required(lang("VALIDATION.REQUIRE")).nullable(),
    hinhThucXuLy: Yup.object().required(lang("VALIDATION.REQUIRE")).nullable(),
    thangTruLuongObj: Yup.object().nullable(),
    namTruLuongObj: Yup.object()
      .when("thangTruLuongObj", {
        is: (thangTruLuong: object) => thangTruLuong !== null && thangTruLuong !== undefined,
        then: Yup.object().required(lang("VALIDATION.REQUIRE")).nullable(),
        otherwise: Yup.object().notRequired().nullable()
      })
      .nullable(),
    soTienViPham: Yup.number()
      .when("hinhThucXuLyCode", {
        is: (hinhThucXuLyCode: any) => hinhThucXuLyCode === PROLEM_FORM_PROCESS_CODES.EXCEPT_SALARY,
        then: Yup.number().required(lang("VALIDATION.REQUIRE")).nullable(),
        otherwise: Yup.number().notRequired().nullable()
      })
      .nullable(),
    xuLy: Yup.string()
      .when("hinhThucXuLyCode", {
        is: (hinhThucXuLyCode: any) => hinhThucXuLyCode === PROLEM_FORM_PROCESS_CODES.HANDLE_IN_PLACE,
        then: Yup.string().required(lang("VALIDATION.REQUIRE")).nullable(),
        otherwise: Yup.string().notRequired().nullable()
      })
      .nullable()
  });

  const formik = useFormik({
    initialValues: INIT_PROBLEM,
    onSubmit: handleSubmit,
    validationSchema
  });

  const handleChangeSelect = (name: string, value: any) => {
    switch (name) {
      case VARIABLE_STRING.DOI_TUONG:
        setEmployeeList([]);
        formik.setFieldValue(name, value);
        break;
      case VARIABLE_STRING.LOAI_SU_CO:
        formik.setValues({
          ...formik.values,
          [name]: value,
          hinhThucXuLy: null
        });
        break;
      case VARIABLE_STRING.HINH_THUC_XU_LY:
        formik.setValues({
          ...formik.values,
          [name]: value,
          hinhThucXuLyCode: value?.mappingName
        });
        break;
      case VARIABLE_STRING.NGUOI_PHAT_HIEN:
        formik.setValues({
          ...formik.values,
          [name]: value,
          phongBanId: value?.phongBanId || "",
          phongBanText: value?.phongBanText || ""
        });
        break;
      default:
        formik.setFieldValue(name, value);
        break;
    }
  };

  const handleExport = () => {
    exportToFile({
      exportAPI: () => exportSuCoChiTiet(problemInfo?.id || ""),
      fileName: EXPORT_FILE_NAME.CHI_TIET_SU_CO,
      setPageLoading
    });
  };

  const handleFile = (data: IFile) => {
    formik.setFieldValue("fileId", data.id);
    formik.setFieldValue("fileName", data.name);
  };

  const handleDeleteEmployeeSelect = () => {
    const newSelectedData = employeeList?.map((row: any) => {
      if (row?.isParentChecked) return null;
      return {
        ...row,
        data: (row?.data || []).filter(
          (oldItem: any) => !employeeChecked?.some((removeItem: any) => oldItem?.employeeId === removeItem?.employeeId)
        )
      };
    });

    let newDataFilter = newSelectedData?.filter((item: any) => item !== null);

    setEmployeeList(newDataFilter || []);
    setEmployeeChecked([]);
  };

  const handleSetEmployeeSelect = (data: any[]) => {
    setEmployeeList(data);
  };

  const handleSaveEmployeeSingle = (employees: any[]) => {
    const convertDataTable = [
      {
        phongBanId: employees[0]?.phongBanId || "",
        phongBanText: employees[0]?.phongBanText || "",
        data: [{ ...employees[0], isChecked: false }]
      }
    ];
    setEmployeeList(convertDataTable);
  };

  const handleOpenEmployeeSelectDialog = () => {
    if (formik.values?.doiTuong?.code === PROBLEM_OBJECTS_CODE.PERSONAL) {
      setShouldOpenEmployeeListSigleDialog(true);
    } else {
      setShouldOpenSelectEmployeeDialog(true);
    }
  };

  const handleChangeFieldTable = (event: React.ChangeEvent<any>, index: number, parentIndex: number) => {
    const { value } = event.target;
    let cloneEmployeeList = employeeList;
    cloneEmployeeList[parentIndex].data[index].soTienViPham = Number(convertTextPrice(value));
    setEmployeeList([...cloneEmployeeList]);
  };

  const handleChangeMoney = (event: React.ChangeEvent<any>) => {
    const { name, value } = event.target;
    formik.setFieldValue(name, value !== "" ? Number(convertTextPrice(value)) : null);
  };

  const employeeColumns: columnNamesType[] = [
    {
      name: "STT",
      field: "",
      render: (row: any, index: number) => <span>{index + 1}</span>
    },
    {
      name: lang("EMPLOYEE.ID"),
      field: "maNhanVien",
      headerCellProps: {
        minWidth: "200px"
      }
    },
    {
      name: lang("GENERAL.FULLNAME"),
      field: "tenNhanVien",
      headerCellProps: {
        minWidth: "200px"
      },
      bodyCellProps: {
        minWidth: "200px",
        textAlign: "left"
      }
    },
    {
      name: lang("INPUT.SALARY.DEPARTMENT"),
      field: "phongBanText",
      headerCellProps: {
        minWidth: "300px"
      },
      bodyCellProps: {
        textAlign: "left"
      }
    },
    {
      name: lang("PROBLEM.VIOLATION_AMOUNT"),
      field: "field",
      headerCellProps: {
        minWidth: "200px"
      },
      bodyCellProps: {
        textAlign: "left"
      },
      render: (row, index, parentIndex) => (
        <span onClick={(event) => event.stopPropagation()}>
          <TextValidator
            name="soTienViPham"
            lable={lang("PROBLEM.VIOLATION_AMOUNT")}
            type="text"
            value={convertNumberPrice(row?.soTienViPham || "")}
            onChange={(event: React.ChangeEvent<any>) => handleChangeFieldTable(event, index, parentIndex)}
            readOnly={isView}
            showLabel={false}
          />
        </span>
      )
    }
  ];

  const columnsSelected = [
    {
      name: lang("EMPLOYEE.ID"),
      field: "maNhanVien",
      headerCellProps: {
        minWidth: "200px"
      }
    },
    {
      name: lang("GENERAL.FULLNAME"),
      field: "tenNhanVien",
      headerCellProps: {
        minWidth: "200px"
      },
      bodyCellProps: {
        minWidth: "200px",
        textAlign: "left"
      }
    },
    {
      name: lang("INPUT.SALARY.DEPARTMENT"),
      field: "phongBanText",
      headerCellProps: {
        minWidth: "200px"
      },
      bodyCellProps: {
        textAlign: "left"
      }
    }
  ];

  const columnsSelect = [
    {
      name: lang("EMPLOYEE.ID"),
      field: "maNhanVien",
      headerStyle: {
        minWidth: "200px"
      },
      render: (row: any) => <span className="color-steel-blue fw-600">{row?.maNhanVien}</span>
    },
    {
      name: lang("GENERAL.FULLNAME"),
      field: "tenNhanVien",
      headerStyle: {
        minWidth: "200px"
      },
      cellStyle: {
        minWidth: "200px",
        textAlign: "left"
      }
    },
    {
      name: lang("INPUT.SALARY.DEPARTMENT"),
      field: "phongBanText",
      headerStyle: {
        minWidth: "200px"
      },
      cellStyle: {
        textAlign: "left"
      }
    }
  ];

  return (
    <div className="problem-form">
      <Form onSubmit={formik.handleSubmit}>
        <Row className="contract-header header-bo spaces py-12">
          <Col xs={12}>
            <div className="profile-title">
              <GroupButton type="btn-back" handleClose={handleCloseDialog} />
              <div className="flex">
                {problemInfo?.id && (
                  <button
                    className="spaces flex flex-middle table-btn outline mr-16"
                    onClick={handleExport}
                    type="button"
                  >
                    <KTSVG path="/media/icons/export-excel.svg" /> {lang("BTN.EXPORT")}
                  </button>
                )}
                {problemInfo?.trangThai?.code !== PROBLEM_STATUS_CODE.COMPLETED &&
                  (isView ? (
                    <GroupButton type="btn-edit" handleSaveEdit={handleOpenUpdateDialog} />
                  ) : (
                    <>
                      {problemInfo.id && (
                        <Button
                          variant="outline-secondary"
                          className="button-primary-outline me-2"
                          onClick={handleCloseUpdateDialog}
                          type="button"
                        >
                          {lang("BTN.CANCEL")}
                        </Button>
                      )}
                      <GroupButton type="btn-save" handleClose={handleCloseUpdateDialog} />
                    </>
                  ))}
              </div>
            </div>
          </Col>
        </Row>
        <div className="form-container bg-white">
          <Row className="p-4 w-100">
            <Col xs={12} className="relative z-index-2">
              <div className="sub-title-form">{problemInfo?.id ? lang("PROBLEM.EDIT") : lang("PROBLEM.ADD")}</div>
              <div className="spaces mb-6">
                <span className="fs-3 fw-bold">{lang("PROBLEM.INFOMATION")}</span>
              </div>
              <RowCustom xs={1} sm={1} md={2} lg={3} xl={5} xxl={5}>
                <ColCustom xs={1}>
                  <Row>
                    <Col xs={6}>
                      <Autocomplete
                        isRequired
                        lable={lang("PROBLEM.OBJECT")}
                        options={PROBLEM_OBJECTS}
                        isReadOnly={isView}
                        value={formik.values?.doiTuong || null}
                        name="doiTuong"
                        onChange={(value) => handleChangeSelect(VARIABLE_STRING.DOI_TUONG, value)}
                        isClearable={false}
                        touched={formik.touched?.doiTuong}
                        errors={formik.errors?.doiTuong}
                      />
                    </Col>
                    <Col xs={6}>
                      <TextValidator
                        name="ngayXayRa"
                        isRequired
                        lable={lang("PROBLEM.NAMEDATE_OF_OCCURRED")}
                        type="date"
                        value={formik.values?.ngayXayRa || ""}
                        onChange={formik.handleChange}
                        onBlur={() => handleBlurDate(formik.setFieldValue, formik.values?.ngayXayRa, "ngayXayRa")}
                        readOnly={isView}
                        touched={formik.touched?.ngayXayRa}
                        errors={formik.errors?.ngayXayRa}
                      />
                    </Col>
                  </Row>
                </ColCustom>
                <ColCustom xs={1}>
                  <TextValidator
                    name="tenSuCo"
                    isRequired
                    lable={lang("PROBLEM.NAME")}
                    type="text"
                    value={formik.values?.tenSuCo}
                    onChange={formik.handleChange}
                    readOnly={isView}
                    touched={formik.touched?.tenSuCo}
                    errors={formik.errors?.tenSuCo}
                  />
                </ColCustom>
                <ColCustom xs={1}>
                  <Autocomplete
                    isRequired
                    lable={lang("PROBLEM.TYPE")}
                    getOptionLabel={(value) => value?.value}
                    options={problemTypes}
                    isReadOnly={isView}
                    value={formik.values?.loaiSuCo || null}
                    name="loaiSuCo"
                    onChange={(value) => handleChangeSelect(VARIABLE_STRING.LOAI_SU_CO, value)}
                    isClearable={false}
                    touched={formik.touched?.loaiSuCo}
                    errors={formik.errors?.loaiSuCo}
                  />
                </ColCustom>
                <ColCustom xs={1}>
                  <Autocomplete
                    lable={lang("GENERAL.STATUS")}
                    isReadOnly={isView}
                    isRequired
                    options={PROBLEM_STATUS_LIST}
                    value={formik.values?.trangThai || null}
                    name="trangThai"
                    onChange={(value) => handleChangeSelect(VARIABLE_STRING.TRANG_THAI, value)}
                    errors={formik.errors?.trangThai}
                    touched={formik.touched?.trangThai}
                  />
                </ColCustom>
                <ColCustom xs={1}>
                  <Autocomplete
                    isRequired
                    lable={lang("PROBLEM.FORMS_PROCESSING")}
                    searchFunction={searchAllSimpleValue}
                    searchObject={{
                      ...SEARCH_OBJECT_MAX_SIZE,
                      type: TYPE_CATEGORY.hinhThucXuly,
                      attributeCode: formik.values?.loaiSuCo?.mappingName
                    }}
                    dependencies={[formik.values?.loaiSuCo]}
                    getOptionLabel={(value) => value?.value}
                    options={[]}
                    isReadOnly={isView}
                    value={formik.values?.hinhThucXuLy || null}
                    name="hinhThucXuLy"
                    onChange={(value) => handleChangeSelect(VARIABLE_STRING.HINH_THUC_XU_LY, value)}
                    isClearable={false}
                    touched={formik.touched?.hinhThucXuLy}
                    errors={formik.errors?.hinhThucXuLy}
                  />
                </ColCustom>
                {formik.values?.hinhThucXuLyCode === PROLEM_FORM_PROCESS_CODES.HANDLE_IN_PLACE && (
                  <ColCustom xs={1}>
                    <TextValidator
                      isRequired
                      lable={lang("PROBLEM.HANDLE")}
                      type="text"
                      name="xuLy"
                      value={formik.values?.xuLy || ""}
                      onChange={formik.handleChange}
                      readOnly={isView}
                      touched={formik.touched?.xuLy}
                      errors={formik.errors?.xuLy}
                    />
                  </ColCustom>
                )}
                <ColCustom xs={1}>
                  <TextValidator
                    name="giaTriThietHai"
                    lable={lang("PROBLEM.TOTAL_VALUE_OF_DAMAGES")}
                    type="text"
                    value={convertNumberPrice(formik.values?.giaTriThietHai || "")}
                    onChange={handleChangeMoney}
                    readOnly={isView}
                    touched={formik.touched?.giaTriThietHai}
                    errors={formik.errors?.giaTriThietHai}
                  />
                </ColCustom>
                <ColCustom xs={1}>
                  <TextValidator
                    name="soTienViPham"
                    lable={lang("PROBLEM.VIOLATION_AMOUNT")}
                    type="text"
                    value={convertNumberPrice(formik.values?.soTienViPham || "")}
                    onChange={handleChangeMoney}
                    readOnly={isView}
                    touched={formik.touched?.soTienViPham}
                    errors={formik.errors?.soTienViPham}
                    isRequired={formik.values?.hinhThucXuLyCode === PROLEM_FORM_PROCESS_CODES.EXCEPT_SALARY}
                  />
                </ColCustom>
                {formik.values?.loaiSuCo?.mappingName === PROBLEM_TYPE_CODES.LABOR_ACCIDENT && (
                  <>
                    <ColCustom xs={1}>
                      <TextValidator
                        name="soTienXuLyBanDau"
                        lable={lang("PROBLEM.MONEY_HANDLE_INIT")}
                        type="text"
                        value={convertNumberPrice(formik.values?.soTienXuLyBanDau || "")}
                        onChange={handleChangeMoney}
                        readOnly={isView}
                        touched={formik.touched?.soTienXuLyBanDau}
                        errors={formik.errors?.soTienXuLyBanDau}
                      />
                    </ColCustom>
                    <ColCustom xs={1}>
                      <TextValidator
                        name="soTienBhThanhToan"
                        lable={lang("PROBLEM.PAYMENT_ACIDENT")}
                        type="text"
                        value={convertNumberPrice(formik.values?.soTienBhThanhToan || "")}
                        onChange={handleChangeMoney}
                        readOnly={isView}
                        touched={formik.touched?.soTienBhThanhToan}
                        errors={formik.errors?.soTienBhThanhToan}
                      />
                    </ColCustom>
                  </>
                )}
                <ColCustom xs={1}>
                  <Row>
                    <Col xs={6}>
                      <Autocomplete
                        lable={lang("PROBLEM.EXCEPT_MONTHLY_SALARY")}
                        options={LIST_MONTH}
                        isReadOnly={isView}
                        value={formik.values?.thangTruLuongObj || null}
                        name="thangTruLuongObj"
                        onChange={(value) => handleChangeSelect("thangTruLuongObj", value)}
                        touched={formik.touched?.thangTruLuongObj}
                        errors={formik.errors?.thangTruLuongObj}
                      />
                    </Col>
                    <Col xs={6}>
                      <Autocomplete
                        lable={lang("PROBLEM.EXCEPT_YEAR_SALARY")}
                        options={getFullYear()}
                        onChange={(value) => handleChangeSelect("namTruLuongObj", value)}
                        name="namTruLuongObj"
                        value={formik.values?.namTruLuongObj || null}
                        isReadOnly={isView}
                        touched={formik.touched?.namTruLuongObj}
                        errors={formik.errors?.namTruLuongObj}
                        isRequired={formik.values?.thangTruLuongObj}
                      />
                    </Col>
                  </Row>
                </ColCustom>
                <ColCustom xs={1}>
                  <UploadFile
                    label="INPUT_APPOINTMENT_ATTACHMENTS"
                    setValue={handleFile}
                    fileValue={{
                      id: formik.values?.fileId || "",
                      name: formik.values?.fileName || ""
                    }}
                    allowFileTypes={`${FILE_TYPE.PDF},${FILE_TYPE.DOC},${FILE_TYPE.DOCX},${FILE_TYPE.XLS},${FILE_TYPE.XLSX}`}
                    isReadOnly={isView}
                  />
                </ColCustom>
                <ColCustom xs={1}>
                  <TextValidator
                    name="noiXayRa"
                    lable={lang("PROBLEM.PLACE_OF_OCCURRED")}
                    type="text"
                    value={formik.values?.noiXayRa}
                    onChange={formik.handleChange}
                    readOnly={isView}
                    touched={formik.touched?.noiXayRa}
                    errors={formik.errors?.noiXayRa}
                  />
                </ColCustom>
                <ColCustom xs={1}>
                  <Autocomplete
                    lable={lang("PROBLEM.PEOPLE_DISCOVERED")}
                    options={[]}
                    className="spaces"
                    onChange={(value) => handleChangeSelect("nguoiPhatHien", value)}
                    name="nguoiPhatHien"
                    value={formik.values?.nguoiPhatHien || null}
                    searchFunction={searchAllEmployee}
                    searchObject={{ ...SEARCH_OBJECT_MAX_SIZE, isDangLamViec: true }}
                    isReadOnly={isView}
                    touched={formik.touched?.nguoiPhatHien}
                    errors={formik.errors?.nguoiPhatHien}
                  />
                </ColCustom>
                <ColCustom xs={1}>
                  <TextValidator
                    name="phongBanText"
                    lable={lang("PROBLEM.DEPARTMENT")}
                    type="text"
                    value={formik.values?.phongBanText}
                    onChange={formik.handleChange}
                    readOnly={true}
                    touched={formik.touched?.phongBan}
                    errors={formik.errors?.phongBan}
                  />
                </ColCustom>
                <ColCustom xs={1} sm={1} md={2} lg={2} xl={2} xxl={2}>
                  <TextValidator
                    name="nguyenNhan"
                    lable={lang("PROBLEM.REASON")}
                    type="text"
                    value={formik.values?.nguyenNhan}
                    onChange={formik.handleChange}
                    readOnly={isView}
                    touched={formik.touched?.nguyenNhan}
                    errors={formik.errors?.nguyenNhan}
                  />
                </ColCustom>
                <ColCustom xs={1} sm={1} md={2} lg={3} xl={5} xxl={5}>
                  <Row>
                    <Col md={12} lg={6} xl={6}>
                      <TextValidator
                        className="h-auto-input spaces"
                        lable={lang("PROBLEM.DESCRIPTION")}
                        name="moTa"
                        value={formik.values?.moTa}
                        type="text"
                        readOnly={isView}
                        rows={3}
                        onChange={formik.handleChange}
                        as={TYPE.TEXTAREA}
                      />
                    </Col>
                    <Col md={12} lg={6} xl={6}>
                      <TextValidator
                        className="h-auto-input spaces"
                        lable={lang("GENERAL.NOTE")}
                        name="note"
                        value={formik.values.note}
                        type="text"
                        readOnly={isView}
                        rows={3}
                        onChange={formik.handleChange}
                        as={TYPE.TEXTAREA}
                      />
                    </Col>
                  </Row>
                </ColCustom>
              </RowCustom>
            </Col>
          </Row>
          <Row className="p-4 w-100">
            <TableCollapseCustom
              title={lang("PROBLEM.EMPLOYEE_LIST")}
              columnNameList={employeeColumns}
              data={employeeList || []}
              nameParent="phongBanText"
              nameChildren="data"
              selectData={setEmployeeChecked}
              setData={setEmployeeList}
              selectionMode="multiple"
              isSelect={true}
              buttonAdd={!isView}
              handleOpenDialog={handleOpenEmployeeSelectDialog}
              handleDelete={handleDeleteEmployeeSelect}
              notDelete={isView}
              uniquePrefix="employeeId"
              disabledSelect={isView}
            />
          </Row>
        </div>
      </Form>
      {shouldOpenSelectEmployeeDialog && (
        <SelectEmployeeByDepartment
          title={lang("GENERAL.LIST_EMPLOYEE_BY_DEPARTMENT")}
          open={shouldOpenSelectEmployeeDialog}
          handleClose={() => setShouldOpenSelectEmployeeDialog(false)}
          parentSelectedData={employeeList || []}
          setParentSelectedData={handleSetEmployeeSelect}
          funcConvert={convertDataEmployeeSelect}
          prefixNameChildren="data"
          columnsEmployeeDepartment={columnsSelect}
          columnsSelected={columnsSelected}
          searchEmployeeObject={{
            trangThaiLamViec: Object.values(STATUS_NV)
              .filter((status) => status !== STATUS_NV.THOI_VIEC)
              .join(",")
          }}
        />
      )}

      {shouldOpenEmployeeListSigleDialog && (
        <SelectEmployeeDialog
          open={shouldOpenEmployeeListSigleDialog}
          handleClose={() => setShouldOpenEmployeeListSigleDialog(false)}
          handleSaveData={handleSaveEmployeeSingle}
          dataParent={employeeList[0]?.data || []}
          columnsCustom={columnsSelect}
          funcConvert={convertDataEmployeeSelect}
          uniquePrefix="employeeId"
        />
      )}
    </div>
  );
}

export default ProblemForm;
