
import { APIResponse } from "../../models/models";
import axios, { AxiosResponse } from "axios";
import { TransitionInFo, danhSachDieuChuyenInfo } from './../models/transitionModel';
import { paramsConfig } from "../../utils/ParamsUtils";

const API_URL = process.env.REACT_APP_API_URL;
export const getListTransfer = (searchData: any): Promise<AxiosResponse<APIResponse>> => {
  let url = `${API_URL}/employee-thuyen-chuyen/page`;
  return axios.get(url, paramsConfig(searchData));
};

export const addNewTransfer = (data: TransitionInFo): Promise<AxiosResponse<APIResponse>> => {
  let url = `${API_URL}/employee-thuyen-chuyen`;
  return axios.post(url, data);
};

export const editTransfer = (id: string, data: TransitionInFo): Promise<AxiosResponse<APIResponse>> => {
  let url = `${API_URL}/employee-thuyen-chuyen/${id}`;
  return axios.put(url, data);
};

export const checkTransfer = (data: TransitionInFo): Promise<AxiosResponse<APIResponse>> => {
  let url = `${API_URL}/employee-thuyen-chuyen/exists-long-term`;
  return axios.post(url, data);
};

export const deleteTransfer = (ids: string[]): Promise<AxiosResponse<APIResponse>> => {
  let url = `${API_URL}/employee-thuyen-chuyen/ids?ids=${ids.toString()}`;
  return axios.delete(url);
};

export const getTransferList = (searchData?: any): Promise<AxiosResponse<APIResponse>> => {
  let url = `${API_URL}/thuyen-chuyen-list/page`;
  return axios.get(url, paramsConfig(searchData));
};

export const updateTransferList = (id: string, data: danhSachDieuChuyenInfo): Promise<AxiosResponse<APIResponse>> => {
  let url = `${API_URL}/thuyen-chuyen-list/${id}`;
  return axios.put(url, data);
};

export const addNewTransferList = (data: danhSachDieuChuyenInfo): Promise<AxiosResponse<APIResponse>> => {
  let url = `${API_URL}/thuyen-chuyen-list`;
  return axios.post(url, data);
};

export const deleteTransferList = (ids: string[]): Promise<AxiosResponse<APIResponse>> => {
  let url = `${API_URL}/thuyen-chuyen-list/ids?ids=${ids.toString()}`;
  return axios.delete(url);
};
