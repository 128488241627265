import { Modal, Button } from "react-bootstrap";
import useMultiLanguage from "../../../../hook/useMultiLanguage";
import { useEffect, useState } from "react";
import { IInsuranceDetail } from "../../models/insuranceModel";

interface IProps {
  open: boolean;
  handleClose: () => void;
  dataView: any;
  insuranceInfo: IInsuranceDetail;
}

function InsuranceViewDialog(props: IProps) {
  const { open, handleClose, dataView, insuranceInfo } = props;
  const { lang } = useMultiLanguage();
  const { ViewFormComponent } = insuranceInfo;

  const [data, setData] = useState<any>({});

  useEffect(() => {
    if (dataView?.id) {
      setData(dataView);
    }
  }, [dataView]);

  return (
    <Modal show={open} onHide={handleClose} centered size="xl">
      <Modal.Header closeButton className="spaces py-12">
        <Modal.Title className="spaces fs-20 fw-600">{lang("INSURANCE.DETAIL_INFOMATION")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ViewFormComponent data={data}/>
      </Modal.Body>
      <Modal.Footer className="flex-center">
        <Button variant="outline-secondary" className="button-secondary btn-sm" onClick={handleClose}>
          {lang("BTN.CANCEL")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default InsuranceViewDialog;
