/* eslint-disable react-hooks/exhaustive-deps */
import { useFormik } from "formik";
import { FC, useContext, useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { FILE_TYPE } from "../../component/FileUpload/constant";
import { GroupButton } from "../../component/GroupButton";
import Autocomplete from "../../component/input-field/Autocomplete";
import TextValidator from "../../component/input-field/TextValidator";
import { TYPE_CATEGORY } from "../../constant";
import EmployeeInfo from "../../profile/component/EmployeeInfo";
import { searchPhongBan_DonVi } from "../../profile/services/DialogServices";
import { getEmployeeProfileById } from "../../profile/services/ProfileServices";
import { getAllSimpleValue, searchAllSimpleValue } from "../../services";
import { searchAllEmployee, searchListWorkUnit } from "../../utils/CategoryServices";
import { DATA_WORD_TYPES, RESPONSE_STATUS_CODE, SEARCH_OBJECT_MAX_SIZE, TYPE, VARIABLE_STRING } from "../../utils/Constant";
import { addMoreYear, convertNumberPrice, convertTextPrice, exportToFile, handleBlurDate, hasRole, matchTemplateWord } from "../../utils/FunctionUtils";
import { checkInvalidDate } from "../../utils/ValidationSchema";
import {
  CODE_HOP_DONG,
  INIT_CONTACT,
  INIT_CONTACT_ANNEX_INFO,
  SUCCESS_CODE,
  codeStatusContract,
  contractStatus,
  contractTypeHopDong,
  contractWordExportList,
  signinStatus,
  workShifts
} from "../const/ContractConst";
import { addNewContract, searchNguoiDaiDienKy, updateContract } from "../services/contractServices";
import { IContractAnnexInfo, IContractInfo } from "../services/models/IContract";
import { convertDataContactDto, filterObject, switchContractStatus } from "../utils/FunctionUtils";
import TableCustom from "../../component/table-custom/TableCustom";
import { ContractAnnexColumn } from "../../profile/component/Column/ContractAnnexColumn";
import { deleteMultipleContractAnnex, getListAnnexByContract } from "../services/annexServices";
import { AddNewContractAnnex } from "./AddNewContractAnnex";
import { AxiosResponse } from "axios";
import { APIResponse, IOrganization, ISimpleValue } from "../../models/models";
import useMultiLanguage from "../../../hook/useMultiLanguage";
import UploadFile from "../../component/FileUpload/UploadFile";
import { IFile } from "../../profile/models/DialogModels";
import { OFFICER_CODE, STATUS_NV } from "../../profile/const/DialogChildConstants";
import { TYPE_POSITION } from "../../profile/const/ProfileConst";
import { exportHopDongPhuLuc } from "../../utils/ExportFileServices";
import { EXPORT_FILE_NAME, ROLE } from "../../../Constant";
import AppContext from "../../../AppContext";
import TrainingContract from "../../profile/component/Partials/TrainingContract/TrainingContract";
import { ExportWord, ITemplateExportWord } from "../../component/exportWord";
import TextArea from "../../component/input-field/TextArea";

interface IProps {
  view: boolean;
  handleOpenUpdateDialog: () => void;
  handleClose: () => void;
  handleCloseUpdateDialog: () => void;
  contractInfo: IContractInfo;
}

const AddNewContract: FC<IProps> = (props) => {
  const { handleClose, view, handleOpenUpdateDialog, contractInfo, handleCloseUpdateDialog } = props;
  const [isHopDongDaiHan, setIsHopDongDaiHan] = useState(false);
  const [shouldOpenAddContractAnnex, setShouldOpenAddContractAnnex] = useState<boolean>(false);
  const [isShowDialogWord, setIsShowDialogWord] = useState<boolean>(false);
  const [annexInfo, setAnnexInfo] = useState<IContractAnnexInfo>(INIT_CONTACT_ANNEX_INFO);
  const [contractAnnexList, setContractAnnexList] = useState<IContractAnnexInfo[]>([]);
  const [listWorkUnit, setListWorkUnit] = useState<any[]>([]);
  const [listNguoiDaiDien, setListNguoiDaiDien] = useState<any[]>([]);
  const [isCollaborators, setIsCollaborators] = useState<boolean>(false);
  const [isTrainingContract, setIsTrainingContract] = useState<boolean>(false);
  const [workTimesList, setWorkTimesList] = useState<ISimpleValue[]>([]);
  const [dayPaySalaryList, setDayPaySalaryList] = useState<ISimpleValue[]>([]);
  const [departments, setDepartments] = useState<IOrganization[]>([])
  const [templateWord, setTemplateWord] = useState<ITemplateExportWord[] | null>(null);

  const { setPageLoading } = useContext(AppContext);
  const { lang, intl } = useMultiLanguage();

  useEffect(() => {
    getWorkTimes();
    getDayPaySalary();
  }, [])

  const getWorkTimes = async () => {
    try {
      setPageLoading(true);
      const { data } = await searchAllSimpleValue({ ...SEARCH_OBJECT_MAX_SIZE, type: TYPE_CATEGORY.thoiGianLamViec });
      if (data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
        setWorkTimesList(data?.data?.content || []);
      }
    } catch (error) {
      toast.error(lang("GENERAL.ERROR"));
    } finally {
      setPageLoading(false);
    }
  }

  const getDayPaySalary = async () => {
    try {
      setPageLoading(true);
      const { data } = await searchAllSimpleValue({ ...SEARCH_OBJECT_MAX_SIZE, type: TYPE_CATEGORY.ngayTraLuongThang });
      if (data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
        setDayPaySalaryList(data?.data?.content || []);
      }
    } catch (error) {
      toast.error(lang("GENERAL.ERROR"));
    } finally {
      setPageLoading(false);
    }
  }

  const validationSchema: any = Yup.object().shape({
    employee: Yup.object().required(lang("VALIDATION.REQUIRE")).nullable(),
    trangThaiHopDong: Yup.object().required(lang("VALIDATION.REQUIRE")).nullable(),
    donViKyHopDong: Yup.object().required(lang("VALIDATION.REQUIRE")).nullable(),
    soHopDong: Yup.string().required(lang("VALIDATION.REQUIRE")).nullable(),
    loaiHopDong: Yup.object().required(lang("VALIDATION.REQUIRE")).nullable(),
    nguoiDaiDienCtyKy: Yup.object().required(lang("VALIDATION.REQUIRE")).nullable(),
    luongCoBan: Yup.string().required(lang("VALIDATION.REQUIRE")).nullable(),
    ngayCoHieuLuc: Yup.date()
      .concat(checkInvalidDate(intl))
      .required(lang("VALIDATION.REQUIRE"))
      .when("ngayHetHan", {
        is: (ngayHetHan: Date | null) => ngayHetHan,
        then: Yup.date()
          .max(
            Yup.ref("ngayHetHan"),
            lang("VALIDATION.MAXDATE") +
            lang("CONTRACT.EXPIRATIONDATE")
          )
          .nullable()
      })
      .nullable(),
    ngayHetHan: Yup.date().when(VARIABLE_STRING.LOAI_HOP_DONG, {
      is: (loaiHopDong: any) =>
        loaiHopDong?.code === CODE_HOP_DONG.VIEN_CHUC_DAI_HAN || loaiHopDong?.code === CODE_HOP_DONG.HOP_DONG_DAI_HAN,
      then: Yup.date().nullable(),
      otherwise: Yup.date()
        .required(intl.formatMessage({ id: "VALIDATION.REQUIRE" }))
        .concat(checkInvalidDate(intl))
        .min(Yup.ref(VARIABLE_STRING.NGAY_CO_HIEU_LUC), lang("VALIDATE.EXPIRATION_DATE_AFTER_EFFECTIVE_DATE"))
        .nullable()
    }),
    ngayKyHopDong: Yup.date()
      .concat(checkInvalidDate(intl))
      .when("ngayHetHan", {
        is: (ngayHetHan: Date | null) => ngayHetHan && ngayHetHan < (new Date()),
        then: Yup.date()
          .max(
            Yup.ref("ngayHetHan"),
            lang("VALIDATION.SIGN_DAY.EQUAL_OF_LESS")
          )
          .nullable()
      })
      .max(new Date(), lang("VALIDATION.INVALID_SIGN_DATE"))
      .nullable(),
    phongBan: Yup.object().required(lang("VALIDATION.REQUIRE")).nullable(),
    chucVu: Yup.object().required(lang("VALIDATION.REQUIRE")).nullable(),
    viTriCongViec: Yup.object().required(lang("VALIDATION.REQUIRE")).nullable(),
    diaDiemLamViec: Yup.object().required(lang("VALIDATION.REQUIRE")).nullable(),
    moTaCongViec: Yup.string().required(lang("VALIDATION.REQUIRE")).nullable()
  });

  const handleFormSubmit = async (values: IContractInfo) => {
    const { SUCCESS } = RESPONSE_STATUS_CODE;
    try {
      setPageLoading(true);
      const dataContract = filterObject(convertDataContactDto(values));
      const res = contractInfo.id
        ? await updateContract(contractInfo.id, dataContract)
        : await addNewContract(dataContract);

      if (res?.data?.code === SUCCESS) {
        toast.success(contractInfo.id
          ? lang("TOAST.EDIT.CONTRACT.SUCCESS")
          : lang("TOAST.ADD.CONTRACT.SUCCESS"));
        handleClose();
      } else {
        toast.error(`${res?.data?.message}`);
      }
    } catch (error) {
      toast.error(lang("GENERAL.ERROR"));
    } finally {
      setPageLoading(false);
    }
  };

  const formik = useFormik({
    initialValues: INIT_CONTACT,
    validationSchema,
    onSubmit: handleFormSubmit
  });

  useEffect(() => {
    if (formik.values?.employee?.donViCongTacId) {
      getDepartments();
    }
  }, [formik.values?.employee?.donViCongTacId])

  const getDepartments = async () => {
    try {
      setPageLoading(true);
      const { data } = await searchPhongBan_DonVi({ id: formik.values?.employee?.donViCongTacId });
      if (data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
        setDepartments(data?.data || []);
      }
    } catch (error) {
      toast.error(lang("GENERAL.ERROR"));
    } finally {
      setPageLoading(false);
    }
  }

  useEffect(() => {
    let departmentType = departments.find((phongBan) => formik.values.phongBan?.id === phongBan.id)?.type;
    if (departmentType) {
      let workTimeFinded = workTimesList.find((item) => Number(item.mappingName) === departmentType);
      let dayPayFinded = dayPaySalaryList.find((item) => Number(item.mappingName) === departmentType);
      formik.setValues({
        ...formik.values,
        thoiGianLamViecText: workTimeFinded?.value || "",
        thoiGianLamViecId: workTimeFinded?.id || "",
        ngayTraLuongThangText: dayPayFinded?.value || "",
        ngayTraLuongThangId: dayPayFinded?.id || ""
      })
    }
  }, [formik.values.phongBan?.id, departments, workTimesList, dayPaySalaryList])

  const handleChangeSelect = (name: string, value: any) => {
    switch (name) {
      case VARIABLE_STRING.LOAI_HOP_DONG:
        const { VIEN_CHUC_DAI_HAN, HOP_DONG_DAI_HAN, ONE_YEAR } = CODE_HOP_DONG;
        switch (value?.code) {
          case VIEN_CHUC_DAI_HAN:
          case HOP_DONG_DAI_HAN:
            formik.setValues({
              ...formik.values,
              [name]: value,
              ngayHetHan: ""
            });
            setIsHopDongDaiHan(true);
            break;
          case ONE_YEAR:
            setIsHopDongDaiHan(true);
            formik.setValues({
              ...formik.values,
              [name]: value,
              ngayHetHan: addMoreYear(formik.values?.ngayCoHieuLuc, 1)
            });
            break;
          default:
            formik.setFieldValue(VARIABLE_STRING.LOAI_HOP_DONG, value);
            setIsHopDongDaiHan(false);
            break;
        }
        break;
      case VARIABLE_STRING.NGUOI_DAI_DIEN_KY:
        formik.setValues({
          ...formik.values,
          nguoiDaiDienCtyKy: value,
          nguoiDaiDienCtyChucDanhId: value?.chucVuId,
          nguoiDaiDienCtyChucDanhText: value?.chucDanh,
          nguoiDaiDienCtyKyId: value?.id,
          nguoiDaiDienCtyKyText: value?.name
        });
        break;
      case VARIABLE_STRING.CHUC_DANH:
        formik.setFieldValue(name, value);
        formik.setFieldValue(VARIABLE_STRING.BAC_LUONG_OPTION, null);
        formik.setFieldValue(VARIABLE_STRING.HE_SO_LUONG, undefined);
        break;
      case VARIABLE_STRING.BAC_LUONG_OPTION:
        formik.setFieldValue(name, value);
        formik.setFieldValue(VARIABLE_STRING.HE_SO_LUONG, value?.heSoLuong || "");
        break;
      default:
        formik.setFieldValue(name, value);
        break;
    }
  };

  const handleChangeNgayCoHieuLuc = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    formik.setValues({
      ...formik.values,
      [name]: value,
      ngayHetHan:
        formik.values?.loaiHopDong?.code === CODE_HOP_DONG.ONE_YEAR ? addMoreYear(value, 1) : formik.values?.ngayHetHan
    });
  };

  useEffect(() => {
    formik.setValues(contractInfo);
    if (contractInfo?.employeeId) {
      getEmployeeInfo(contractInfo.employeeId);
    }
    const checkHopDongDaiHan = Object.values(CODE_HOP_DONG).some(value => value === Number(contractInfo?.loaiHopDong?.code))
    setIsHopDongDaiHan(checkHopDongDaiHan);

    setTemplateWord(matchTemplateWord(contractWordExportList, Number(contractInfo?.viTriCongViecType || 1)));
    updateDataContractAnnex();
  }, [contractInfo]);

  useEffect(() => {
    let officerType = formik.values?.employee?.loaiCanBo;
    const conditionalCodes = [OFFICER_CODE.CONG_TAC_VIEN];
    if (officerType?.code) {
      let isCooperator = conditionalCodes.includes(Number(officerType?.code));
      setIsCollaborators(isCooperator);
      if (isCooperator) {
        if (!formik.values?.employee?.chucVu) {
          const getPosition = async () => {
            const { data } = await getAllSimpleValue(TYPE_CATEGORY.chucDanh);
            const positionCollaborator = data.data?.content?.find((position: any) => position?.mappingName === TYPE_POSITION.CONG_TAC_VIEN);
            formik.setFieldValue("chucVu", positionCollaborator);
          }
          getPosition();
        }
      }
    }
  }, [formik.values?.employee])

  const getEmployeeInfo = async (id: string) => {
    try {
      const { data } = await getEmployeeProfileById(id);
      if (data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
        formik.setFieldValue(VARIABLE_STRING.EMPLOYEE, data?.data);
      }
    } catch (error) {
      console.error(error);
      toast.error(lang("GENERAL.ERROR"));
    }
  };

  const updateDataContractAnnex = async () => {
    let contractId = contractInfo?.id;
    if (!contractId) return;
    try {
      let { data } = await getListAnnexByContract(contractId);
      if (data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
        setContractAnnexList(data?.data);
      }
    } catch (error) {
      console.error(error);
      toast.error(lang("GENERAL.ERROR"));
    }
  };

  const handleOpenDialog = (
    row: any,
    setShouldOpenDialog: (value: React.SetStateAction<boolean>) => void,
    setData: (value: any) => void
  ): void => {
    setShouldOpenDialog(true);
    setData(row);
  };

  const handleCloseDialog = (
    initData: any,
    setShouldOpenDialog: (value: React.SetStateAction<boolean>) => void,
    setData: (value: any) => void
  ): void => {
    setShouldOpenDialog(false);
    setData(initData);
  };

  const donViKyHopDong = (employee: any) => {
    if (hasRole(ROLE.USER)) {
      return employee?.donViCongTacId
        ? {
          id: employee?.donViCongTacId,
          name: employee?.donViCongTacText
        }
        : null;
    } else {
      return employee?.donViKyHopDongId
        ? {
          id: employee?.donViKyHopDongId,
          value: employee?.donViKyHopDongText
        }
        : null;
    }
  };

  const handleChangeEmployee = async (employee: any) => {
    let values: IContractInfo = {
      ...formik.values,
      employee: employee,
      employeeCode: employee?.maNhanVien,
      employeeId: employee?.id,
      tenNguoiLaoDong: employee?.name,
      donViKyHopDong: donViKyHopDong(employee),
      viTriCongViec: employee?.viTriCongViecText
        ? {
          id: employee?.viTriCongViecId,
          value: employee?.viTriCongViecText
        }
        : null,
      phongBan: employee?.phongBanText
        ? {
          id: employee?.phongBanId,
          name: employee?.phongBanText
        }
        : null,
      chucVu: employee?.chucVuText
        ? {
          id: employee?.chucVuId,
          value: employee?.chucVuText
        }
        : null,
      bacLuongOption: employee?.bacLuong
        ? {
          bacLuong: employee?.bacLuong,
          heSoLuong: employee?.heSoLuong
        }
        : null
    };
    formik.setValues(values);
    switchContractStatus(employee?.loaiCanBo, formik, values);
  };

  const handleChangeCheckBox = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;

    switch (name) {
      case VARIABLE_STRING.VIEN_CHUC:
        formik.setFieldValue(name, checked);
        formik.setFieldValue(VARIABLE_STRING.HOP_DONG_LAO_DONG, false);
        break;
      case VARIABLE_STRING.HOP_DONG_LAO_DONG:
        formik.setFieldValue(name, checked);
        formik.setFieldValue(VARIABLE_STRING.VIEN_CHUC, false);
        break;
      default:
        formik.setFieldValue(name, checked);
        break;
    }
  };

  const handleDeleteItems = async (
    handleDelete: (...params: any) => Promise<AxiosResponse<APIResponse>>,
    handleUpdate: () => Promise<void>,
    ids: string
  ) => {
    try {
      const res = await handleDelete?.(ids);
      if (res?.data?.code === SUCCESS_CODE) {
        toast.success(lang("GENERAL.DELETE_SUCCESS"));
        updateDataContractAnnex();
        return true;
      } else toast.error(`${res?.data?.message}`);
    } catch (err) {
      toast.error(lang("GENERAL.ERROR"));
    }
    await handleUpdate?.();
  };

  const handleChangeMoney = (event: React.ChangeEvent<any>) => {
    const { name, value } = event.target;
    formik.setFieldValue(name, convertTextPrice(value));
  };

  const handleFile = (data: IFile) => {
    formik.setFieldValue("fileId", data.id);
    formik.setFieldValue("fileName", data.name);
  }

  const getListWorkUnit = async () => {
    try {
      let { data } = await searchListWorkUnit(SEARCH_OBJECT_MAX_SIZE)
      if (data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
        setListWorkUnit(data?.data?.content)
      }
    } catch (error) { }
  }

  const getNguoiDaiDienKy = async () => {
    try {
      let { data } = await searchNguoiDaiDienKy()
      if (data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
        setListNguoiDaiDien(data?.data?.content)

        if (data?.data?.content?.length > 0 && !formik?.values?.id && !contractInfo?.id) {
          let nguoikyDefault = data?.data?.content?.find((item: any) => item?.isDefault);
          formik.setValues({
            ...formik.values,
            nguoiDaiDienCtyKy: nguoikyDefault,
            nguoiDaiDienCtyChucDanhId: nguoikyDefault?.chucVuId,
            nguoiDaiDienCtyChucDanhText: nguoikyDefault?.chucDanh,
            nguoiDaiDienCtyKyId: nguoikyDefault?.id,
            nguoiDaiDienCtyKyText: nguoikyDefault?.name
          })
        }
      }
    } catch (error) { }
  }

  useEffect(() => {
    getNguoiDaiDienKy()
    setIsTrainingContract(Boolean(contractInfo?.id) && contractInfo?.trangThaiHopDong?.code === codeStatusContract.IN_EFFECT)
    getListWorkUnit()
  }, [])

  useEffect(() => {
    if (listWorkUnit?.length > 0 && !formik?.values?.id) {
      formik.setFieldValue("donViKyHopDong", listWorkUnit[0])
    }
  }, [listWorkUnit, formik.values?.employee?.id])

  return (
    <div className="addNewContract h-full">
      <Row className="contract-header header-box h-full">
        <Col xs={12}>
          <div className="spaces profile-title py-12">
            <GroupButton
              type="btn-back"
              handleClose={handleClose}
            />
            <div className="flex-center">
              {view && (contractInfo?.id && contractInfo?.trangThaiHopDong?.code !== codeStatusContract.EXPIRE) && (
                <GroupButton
                  type="btn-edit"
                  handleSaveEdit={handleOpenUpdateDialog}
                />
              )}
              {!view && (
                <GroupButton
                  type="btn-save"
                  handleClose={handleCloseUpdateDialog}
                  handleSubmit={formik.handleSubmit}
                  value={formik.values}
                />
              )}
              {(contractInfo?.id && templateWord) && (
                <Button
                  variant="primary"
                  className="button-primary btn-sm"
                  onClick={() => setIsShowDialogWord(true)}
                >
                  {lang("GENERAL.EXPORT_WORD")}
                </Button>
              )}
            </div>
          </div>
        </Col>
      </Row>

      <div className="layout-v2 flex align-items-start">
        <div className="general-user-info flex-column align-items-start align-self-stretch">
          <EmployeeInfo
            isView={true}
            employeeProfiles={formik.values?.employee || null}
          />
        </div>
        <div className="flex-1 overflow-auto h-100">
          <div className="form-container add-contract">
            <div className="sub-title-form pt-3">
              {contractInfo?.id ? lang("CONTRACT.UPDATE") : lang("CONTRACT.ADD_NEW")}
            </div>
            <Form onSubmit={formik.handleSubmit} className="spaces z-index-3">
              <div className="sub-title p-0">{lang("CONTRACT.INFO")}</div>
              <Row>
                <Col xs={6} md={5} xl={5} xxl={3} className="spaces pt-6">
                  <Autocomplete
                    isRequired
                    className="spaces z-index-5"
                    lable={lang("PROFILE.NAME")}
                    options={[]}
                    onChange={(value) => handleChangeEmployee(value)}
                    name="employee"
                    value={formik.values?.employee || null}
                    searchFunction={searchAllEmployee}
                    searchObject={{
                      ...SEARCH_OBJECT_MAX_SIZE,
                      trangThaiLamViec: Object.values(STATUS_NV).filter(status => status !== STATUS_NV.THOI_VIEC).join(',')
                    }}
                    isReadOnly={view || Boolean(formik?.values?.id)}
                    errors={formik.errors.employee}
                    touched={formik.touched.employee}
                    getOptionLabel={(option) => `${option?.maNhanVien} - ${option?.name}`}
                  />
                </Col>
                <Col xs={6} md={2} xl={2} xxl={2} className="spaces pt-6">
                  <TextValidator
                    lable={lang("INPUT.CODE_NEW")}
                    name="maNhanVien"
                    value={formik.values?.employee?.maNhanVien || ""}
                    type="text"
                    readOnly={true}
                    onChange={formik.handleChange}
                  />
                </Col>
                <Col xs={6} md={5} xl={5} xxl={4} className="spaces pt-6">
                  <Autocomplete
                    isRequired
                    className="spaces z-index-5"
                    lable={lang("INPUT.DEPARTMENTS")}
                    isReadOnly={view}
                    options={departments}
                    value={formik.values?.phongBan || null}
                    name="phongBan"
                    onChange={(selectedOption) => handleChangeSelect("phongBan", selectedOption)}
                    touched={formik.touched?.phongBan}
                    errors={formik.errors?.phongBan}
                  />
                </Col>
                <Col xs={6} xl={6} xxl={3} className="spaces pt-6">
                  <Autocomplete
                    isRequired
                    className="spaces z-index-4"
                    lable={lang("GENERAL.EMPLOYEE.POSITION")}
                    name="chucVu"
                    value={formik.values?.chucVu || null}
                    searchFunction={getAllSimpleValue}
                    searchObject={TYPE_CATEGORY.chucDanh}
                    isReadOnly={view || isCollaborators}
                    onChange={(selectedOption) => handleChangeSelect(VARIABLE_STRING.CHUC_VU, selectedOption)}
                    errors={formik.errors?.chucVu}
                    touched={formik.touched?.chucVu}
                    options={[]}
                    getOptionLabel={(option) => option?.value}
                  />
                </Col>
                <Col md={7} lg={7} xl={4} xxl={3} className="spaces pt-6">
                  <Autocomplete
                    lable={lang("GENERAL.EMPLOYEE.JOB_POSITION")}
                    className="spaces z-index-4"
                    name="viTriCongViec"
                    isRequired
                    value={formik.values?.viTriCongViec || null}
                    searchFunction={getAllSimpleValue}
                    searchObject={TYPE_CATEGORY.viTriCongViec}
                    isReadOnly={view}
                    onChange={(selectedOption) => handleChangeSelect(VARIABLE_STRING.VI_TRI_CONG_VIEC, selectedOption)}
                    options={[]}
                    getOptionLabel={(option) => option?.value}
                    errors={formik.errors?.viTriCongViec}
                    touched={formik.touched?.viTriCongViec}
                  />
                </Col>
              </Row>
              <div className="sub-title">{lang("CONTRACT.INFO_DETAIL")}</div>
              <Row>
                <Col xs={6} md={4} xl={4} xxl={3} className="spaces pt-6">
                  <Autocomplete
                    isRequired
                    lable={lang("CONTRACT.SIGNINGUNIT")}
                    options={listWorkUnit || []}
                    value={formik.values?.donViKyHopDong || null}
                    name="donViKyHopDong"
                    onChange={(value) => handleChangeSelect(VARIABLE_STRING.DON_VI_KY_HOP_DONG, value)}
                    touched={formik.touched?.donViKyHopDong}
                    errors={formik.errors?.donViKyHopDong}
                    isReadOnly={view || hasRole(ROLE.USER)}
                  />
                </Col>
                <Col xs={6} md={4} xl={2} xxl={2} className="spaces pt-6">
                  <TextValidator
                    isRequired
                    lable={lang("CONTRACT.NUMBER")}
                    name="soHopDong"
                    value={formik.values?.soHopDong || ""}
                    type="text"
                    readOnly={view}
                    onChange={formik.handleChange}
                    touched={formik.touched?.soHopDong}
                    errors={formik.errors?.soHopDong}
                  />
                </Col>
                <Col xs={6} md={4} xl={3} xxl={2} className="spaces pt-6">
                  <TextValidator
                    lable={lang("CONTRACT.NAME")}
                    name="tenHopDong"
                    value={formik.values?.tenHopDong || ""}
                    type="text"
                    readOnly={view}
                    onChange={formik.handleChange}
                  />
                </Col>
                <Col xs={6} md={4} xl={3} xxl={3} className="spaces pt-6">
                  <Autocomplete
                    isRequired
                    lable={lang("GENERAL.EMPLOYEE.CONTRACT_TYPE")}
                    options={contractTypeHopDong}
                    onChange={(value) => handleChangeSelect(VARIABLE_STRING.LOAI_HOP_DONG, value)}
                    name="loaiHopDong"
                    value={formik.values?.loaiHopDong || null}
                    isReadOnly={view}
                    touched={formik.touched?.loaiHopDong}
                    errors={formik.errors?.loaiHopDong}
                    dependencies={[formik.values?.vienChuc, formik.values?.hopDongLaoDong]}
                  />
                </Col>
                <Col xs={6} md={4} xl={3} xxl={2} className="spaces pt-6">
                  <Autocomplete
                    lable={lang("CONTRACT.WORK_SHIFT")}
                    options={workShifts}
                    onChange={(value) => handleChangeSelect(VARIABLE_STRING.CA_LAM_VIEC, value)}
                    name="caLamViec"
                    value={formik.values?.caLamViec || null}
                    isReadOnly={view}
                  />
                </Col>
                <Col xs={6} md={4} xl={3} xxl={2} className="spaces pt-6">
                  <TextValidator
                    lable={lang("CONTRACT.EFFECTIVEDATE")}
                    name="ngayCoHieuLuc"
                    value={formik.values?.ngayCoHieuLuc || ""}
                    type="date"
                    isRequired
                    readOnly={view}
                    onChange={handleChangeNgayCoHieuLuc}
                    onBlur={() => handleBlurDate(formik.setFieldValue, formik.values?.ngayCoHieuLuc, "ngayCoHieuLuc")}
                    errors={formik.errors?.ngayCoHieuLuc}
                    touched={formik.touched?.ngayCoHieuLuc}
                  />
                </Col>
                <Col xs={6} md={4} xl={3} xxl={2} className="spaces pt-6">
                  <TextValidator
                    lable={lang("CONTRACT.EXPIRATIONDATE")}
                    name="ngayHetHan"
                    value={formik.values?.ngayHetHan || ""}
                    type="date"
                    readOnly={view || isHopDongDaiHan}
                    isRequired={!isHopDongDaiHan}
                    onChange={formik.handleChange}
                    onBlur={() => handleBlurDate(formik.setFieldValue, formik.values?.ngayHetHan, "ngayHetHan")}
                    errors={formik.errors?.ngayHetHan}
                    touched={formik.touched?.ngayHetHan}
                  />
                </Col>
                <Col xs={6} md={4} xl={3} xxl={2} className="spaces pt-6">
                  <TextValidator
                    lable={lang("CONTRACT.BASESALARY")}
                    name="luongCoBan"
                    isRequired={true}
                    value={convertNumberPrice(formik.values?.luongCoBan || "")}
                    type="text"
                    readOnly={view}
                    onChange={handleChangeMoney}
                    errors={formik.errors?.luongCoBan}
                    touched={formik.touched?.luongCoBan}
                  />
                </Col>
                <Col xs={6} md={4} xl={3} xxl={2} className="spaces pt-6">
                  <TextValidator
                    lable={lang("CONTRACT.SALARY_PERCENT")}
                    name="tyLeHuongLuong"
                    value={formik.values?.tyLeHuongLuong || ""}
                    type="number"
                    readOnly={view}
                    onChange={formik.handleChange}
                  />
                </Col>
                <Col xs={6} md={4} xl={3} xxl={2} className="spaces pt-6">
                  <TextValidator
                    lable={lang("CONTRACT.INSURANCECONTRIBUTION")}
                    name="luongDongBaoHiem"
                    value={convertNumberPrice(formik.values?.luongDongBaoHiem || "")}
                    type="text"
                    readOnly={view}
                    onChange={handleChangeMoney}
                  />
                </Col>
                <Col xs={6} md={4} xl={3} xxl={2} className="spaces pt-6">
                  <Autocomplete
                    isRequired
                    lable={lang("CONTRACT.STATUS")}
                    options={contractStatus}
                    onChange={(value) => handleChangeSelect(VARIABLE_STRING.TRANG_THAI_HOP_DONG, value)}
                    name="trangThaiHopDong"
                    value={formik.values?.trangThaiHopDong || null}
                    isReadOnly={view}
                    errors={formik.errors?.trangThaiHopDong}
                    touched={formik.touched?.trangThaiHopDong}
                  />
                </Col>
                <Col xs={6} md={4} xl={3} xxl={2} className="spaces pt-6">
                  <TextValidator
                    lable={lang("CONTRACT.DAY_SALARY_PAY")}
                    name="ngayTraLuongThangText"
                    value={formik.values?.ngayTraLuongThangText || ""}
                    type="text"
                    readOnly={true}
                    onChange={formik.handleChange}
                  />
                </Col>
                <Col xs={6} md={4} xl={3} xxl={2} className="spaces pt-6">
                  <TextValidator
                    lable={lang("CONTRACT.WORK_TIME")}
                    name="thoiGianLamViecText"
                    value={formik.values?.thoiGianLamViecText || ""}
                    type="text"
                    readOnly={true}
                    onChange={formik.handleChange}
                  />
                </Col>
                <Col xs={6} md={4} xl={3} xxl={2} className="spaces pt-6">
                  <UploadFile
                    label="CONTRACT.ATTACHMENTS"
                    setValue={handleFile}
                    isReadOnly={view}
                    fileValue={{
                      id: formik.values.fileId || "",
                      name: formik.values.fileName || ""
                    }}
                    allowFileTypes={`${FILE_TYPE.PDF},${FILE_TYPE.DOC},${FILE_TYPE.DOCX},${FILE_TYPE.XLS},${FILE_TYPE.XLSX}`}
                  />
                </Col>
                <Col xs={12} xl={8} xxl={6} className="spaces pt-6">
                  <Autocomplete
                    isRequired
                    lable={lang("CONTRACT.WORK_PLACE")}
                    options={[]}
                    onChange={(value) => handleChangeSelect("diaDiemLamViec", value)}
                    name="diaDiemLamViec"
                    value={formik.values?.diaDiemLamViec}
                    isReadOnly={view}
                    searchFunction={searchAllSimpleValue}
                    searchObject={{ ...SEARCH_OBJECT_MAX_SIZE, type: TYPE_CATEGORY.diaDiemLamViec }}
                    getOptionLabel={(options) => options?.value}
                    errors={formik.errors?.diaDiemLamViec}
                    touched={formik.touched?.diaDiemLamViec}
                  />
                </Col>
                <Col xs={12} md={12} xl={6} xxl={6} className="spaces pt-6">
                  <TextArea
                    isRequired
                    className="w-100"
                    lable={lang("CONTRACT.JOB_DESCRIPTION")}
                    name="moTaCongViec"
                    value={formik.values?.moTaCongViec || ""}
                    type="text"
                    as="textarea"
                    rows="3"
                    readOnly={view}
                    bulletPoints="+"
                    onChange={(value: any) => formik.setFieldValue("moTaCongViec", value)}
                    touched={formik.touched?.moTaCongViec}
                    errors={formik.errors?.moTaCongViec}
                  />
                </Col>
                <Col xs={12} md={12} xl={6} xxl={6} className="spaces pt-6">
                  <TextValidator
                    lable={lang("CONTRACT.NOTE")}
                    name="ghiChu"
                    value={formik.values?.ghiChu}
                    as={TYPE.TEXTAREA}
                    rows={3}
                    readOnly={view}
                    onChange={formik.handleChange}
                  />
                </Col>
              </Row>
              <div className="sub-title">{lang("CONTRACT.SALARY_SIGN")}</div>
              <Row>
                <Col xs={6} md={4} xl={3} xxl={2} className="spaces pt-6">
                  <Form.Check
                    label={lang("CONTRACT.PAPER_AUTHORITY")}
                    checked={formik.values?.giayUyQuyen}
                    onChange={handleChangeCheckBox}
                    className="custom-form-check"
                    readOnly={view}
                    disabled={view}
                    name="giayUyQuyen"
                  />
                </Col>
                <Col xs={6} md={4} xl={3} xxl={3} className="spaces pt-6">
                  <Autocomplete
                    isRequired
                    lable={lang("CONTRACT.SIGNEDCOMPANYREPRESENTATIVE")}
                    options={listNguoiDaiDien}
                    value={formik.values?.nguoiDaiDienCtyKy || null}
                    name="nguoiDaiDienCtyKy"
                    onChange={(value) => handleChangeSelect(VARIABLE_STRING.NGUOI_DAI_DIEN_KY, value)}
                    isReadOnly={view}
                    errors={formik.errors?.nguoiDaiDienCtyKy}
                    touched={formik.touched?.nguoiDaiDienCtyKy}
                  />
                </Col>
                <Col xs={6} md={4} xl={3} xxl={3} className="spaces pt-6">
                  <TextValidator
                    lable={lang("CONTRACT.REPRESENTATIVETITLE")}
                    name="nguoiDaiDienCtyChucDanh"
                    value={formik.values?.nguoiDaiDienCtyChucDanhText || ""}
                    type="text"
                    readOnly={true}
                  />
                </Col>
                <Col xs={6} md={4} xl={3} xxl={2} className="spaces pt-6">
                  <TextValidator
                    lable={lang("ANNEX.SIGNINGDATE")}
                    name="ngayKyHopDong"
                    value={formik.values?.ngayKyHopDong || ""}
                    type="date"
                    readOnly={view}
                    onBlur={() => handleBlurDate(formik.setFieldValue, formik.values?.ngayKyHopDong, "ngayKyHopDong")}
                    onChange={formik.handleChange}
                    errors={formik.errors?.ngayKyHopDong}
                    touched={formik.touched?.ngayKyHopDong}
                  />
                </Col>
                <Col xs={6} md={4} xl={3} xxl={2} className="spaces pt-6">
                  <Autocomplete
                    lable={lang("CONTRACT.SIGNINGSTATUS")}
                    options={signinStatus}
                    value={formik.values?.trangThaiKy || null}
                    name="trangThaiKy"
                    onChange={(value) => handleChangeSelect(VARIABLE_STRING.TRANG_THAI_KY, value)}
                    isReadOnly={view}
                  />
                </Col>
              </Row>
            </Form>
            {contractInfo?.id && (
              <>
                <div className="spaces mt-16">
                  <TrainingContract
                    isView={view}
                    employeeId={contractInfo?.employeeId}
                    hopDongLaoDongId={contractInfo?.id}
                    isDisplayBtnAdd={isTrainingContract}
                  />
                </div>
                <TableCustom
                  id="contractAnnex"
                  title={lang("CONTRACT.ANNEX")}
                  className="pt-4"
                  data={contractAnnexList}
                  columns={ContractAnnexColumn()}
                  handleDelete={(ids: string) =>
                    handleDeleteItems(deleteMultipleContractAnnex, updateDataContractAnnex, ids)
                  }
                  isActionTableTab
                  notEdit={view || isTrainingContract}
                  buttonAdd={!view && isTrainingContract}
                  buttonExportExcel={true}
                  type={TYPE.MULTILINE}
                  fixedColumnsCount={3}
                  noToolbar={true}
                  noPagination={true}
                  handleExportExcel={() => exportToFile({
                    exportAPI: () => exportHopDongPhuLuc(contractInfo.id),
                    fileName: EXPORT_FILE_NAME.PHU_LUC_HD,
                    setPageLoading
                  })}
                  handleOpenDialog={(row) => handleOpenDialog(annexInfo, setShouldOpenAddContractAnnex, setAnnexInfo)}
                  handleDoubleClick={(row: any) => handleOpenDialog(row, setShouldOpenAddContractAnnex, setAnnexInfo)}
                />
                {/* Tạm ẩn phụ cấp trong hợp đồng */}
                {/* <div className="spaces mt-16">
                  <Allowance
                    isView={view}
                    identify={contractInfo?.employeeId || ""}
                    isDisplayBtnAdd={formik.values?.trangThaiHopDong?.code !== codeStatusContract.EXPIRE}
                  />
                </div> */}
              </>
            )}
            {shouldOpenAddContractAnnex && (
              <AddNewContractAnnex
                annexInfo={annexInfo}
                contractInfo={contractInfo}
                getListAnnex={updateDataContractAnnex}
                handleCLoseAddContractAnnex={() =>
                  handleCloseDialog(INIT_CONTACT_ANNEX_INFO, setShouldOpenAddContractAnnex, setAnnexInfo)
                }
                isView={view}
                isCollaborators={isCollaborators}
              />
            )}

            {isShowDialogWord && (
              <ExportWord
                open={isShowDialogWord}
                handleClose={() => setIsShowDialogWord(false)}
                templateList={templateWord || []}
                getObj={{
                  employeeId: contractInfo?.employeeId,
                  hopDongId: contractInfo?.id,
                  hopDongType: contractInfo?.viTriCongViecType,
                  type: DATA_WORD_TYPES.FACTORY_CONTRACT
                }}
                customFileName={(data, nameBase) => `${data.fullName} (${nameBase})`}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export { AddNewContract };
