/* eslint-disable react-hooks/exhaustive-deps */
import { useFormik } from "formik";
import React, { useContext, useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import AppContext from "../../../AppContext";
import useMultiLanguage from "../../../hook/useMultiLanguage";
import TextValidator from "../../component/input-field/TextValidator";
import { checkObject, exportToFile, removeDiacritics } from "../../utils/FunctionUtils";
import { AxiosResponse } from "axios";
import { KEY, RESPONSE_STATUS_CODE, TYPE } from "../../utils/Constant";
import Autocomplete from "../input-field/Autocomplete";
import { RangeDatePicker } from "../input-field/RangeDatePicker";
import * as Yup from "yup";
import { checkInvalidDate } from "../../utils/ValidationSchema";
import { ICodeExportField, ITemplate } from "../../models/exportExcelModel";
import { GroupButton } from "../GroupButton";

interface IProps {
  handleClose: () => void;
  ids: string[];
  listTemplates?: ITemplate[];
  defaultCodeExportField?: ICodeExportField[];
  maxExportFields?: number;
  exportAPI: (obj: any) => void;
  getFieldsAPI: () => Promise<AxiosResponse<any, any>>;
  fileName: string;
  showRequestDate?: boolean;
  showSearchFields?: boolean;
};

export const ExportExcelDialog: React.FC<IProps> = ({
  handleClose,
  ids,
  listTemplates,
  defaultCodeExportField = [],
  maxExportFields,
  exportAPI,
  getFieldsAPI,
  fileName,
  showRequestDate = false,
  showSearchFields = false
}) => {
  const { lang, intl } = useMultiLanguage();
  const { setPageLoading } = useContext(AppContext);

  const [keyword, setKeyword] = useState<string>();
  const [dataFields, setDataFields] = useState<any[]>([]);
  const [exportFields, setExportFields] = useState<any[]>([]);
  const [keys, setKeys] = useState<string[]>([]);
  const [openSections, setOpenSections] = useState<any>({});
	const [template, setTemplate] = useState<any>();
  const [hasCollapse, setHasCollapse] = useState<Boolean>(false);
  const [defaultField, setDefaultField] = useState<ICodeExportField[]>((listTemplates && listTemplates.length > 0) 
    ? listTemplates[0].defaultCodeExportField
    : defaultCodeExportField
  );
  const [dataTable, setDataTable] = useState<any>({});

  const validationSchema = Yup.object().shape({
    tuNgay: Yup.date().concat(checkInvalidDate(intl)).nullable(),
    denNgay: Yup.date().concat(checkInvalidDate(intl)).nullable(),
  });

  useEffect(() => {
    listTemplates && listTemplates.length > 0 && setTemplate(listTemplates[0]);
	}, [listTemplates]);

  useEffect(() => {
    template && setDefaultField(template.defaultCodeExportField);
  }, [template]);

  useEffect(() => {
    if(defaultField) {
      setDataFields(addIndexPath(dataFields));
      fillDefaultExportField()
    } 
  }, [defaultField]);
  
  useEffect(() => {
    let _openSections: any = openSections;
    dataFields?.map((item) => {
      let key = item?.header;
      if (item?.matched === true) {
        openSections[key] = true;
      } else if (item?.matched === false && !openSections[key]) {
        openSections[key] = false;
      }
      return item;
    });
    setOpenSections({ ..._openSections });
  }, [dataFields]);

  const addNamePath = (fields: any[], parentNamePath: string) => {
    return fields.map((field, index) => {
      const currentNamePath = parentNamePath
        ? `${parentNamePath}/${field.header}`
        : field.header;
      const updatedField = {
        ...field,
        namePath: currentNamePath,
        selected: defaultField.some((item: any) => item.code === field.code) || false
      };
      if (field?.fields) {
        updatedField.fields = addNamePath(field.fields, currentNamePath);
      }
      return updatedField;
    });
  };

  const handleSearch = (data: any, keyword: string) => {
    let _data = data;
    data.forEach((obj: any, index: number) => {
      if (
        removeDiacritics(obj.header.toLowerCase()).includes(
          removeDiacritics(keyword.toLowerCase())
        ) &&
        keyword
      ) {
        obj.matched = true;
      } else if (obj?.fields && obj.fields.length > 0 && keyword) {
        obj.matched = obj.fields?.some((field: any) => field.matched === true);
        handleSearch(obj.fields, keyword);
      } else {
        let key = obj?.header;
        obj.matched = false;
        openSections[key] = false;
      }
    });
    return _data;
  };

  const filterFieldsByCode = (fields: any, codes: ICodeExportField[]) => {
    return fields
      ?.filter((field: any) => codes.some((code: ICodeExportField) => code.code === field.code))
      ?.map((field: any) => {
        if (field?.fields) {
          return {
            ...field,
            fields: filterFieldsByCode(field.fields, codes)
          };
        } else {
          return { ...field, isRemove: codes.find((code: ICodeExportField) => code.code === field.code)?.isRemove };
        }
      });
  };

  const generateFilteredData = (data: any, codes: ICodeExportField[]) => {
    return data?.map((category: any) => {
      return {
        ...category,
        fields: filterFieldsByCode(category.fields, codes)
      };
    });
  };

  const flattenFields = (data: any) => {
    let flattenedFields: any[] = [];

    function recursiveFlatten(fields: any) {
      fields.forEach((field: any) => {
        flattenedFields.push(field);
        if (field.fields) {
          recursiveFlatten(field.fields);
        }
      });
    }

    data.forEach((category: any) => {
      recursiveFlatten(category.fields);
    });

    return flattenedFields;
  };

  const toggleSelectField = (item: any, selected: boolean) => {
    let indexPath = item?.indexPath?.split(".");
    return dataFields.map((dataField, index) => {
      let depth = 1;
      if (Number(indexPath[depth - 1]) === index) {
        if (dataField?.fields) {
          depth += 1;
          let updatedField = dataField.fields?.map((item: any, index: number) => {
            if (Number(indexPath[depth - 1]) === index) {
              return {
                ...item,
                selected
              };
            }
            return item;
          });
          dataField.fields = updatedField;
        }
        dataField = {
          ...dataField,
          selected: selected || dataField.fields?.some((item: any) => item.selected)
        };
        return dataField;
      }
      return dataField;
    });
  };

  const addIndexPath = (data: any, path: string[] = []) => {
    return (typeof data === TYPE.OBJECT ? data : Object.keys(data))?.map((field: any, index: number) => {
      const newPath = [...path, String(index)];
      const current = data[index];

      const updated = {
        ...current,
        indexPath: newPath.join("."),
        namePath: current.header
      };

      if (current?.fields) {
        let newFieldsWithIndexPath = addIndexPath(current.fields, newPath);
        let newFieldsWithNamePath = addNamePath(current.fields, current.header);
        let mergeFields = newFieldsWithIndexPath.map((item: any, index: number) => ({
          ...item,
          ...newFieldsWithNamePath[index]
        }));
        updated.fields = mergeFields;
        updated.selected = mergeFields?.some(
          (field: any) => field.selected
        );
      } else {
        updated.selected = defaultField.some((item: any) => item.code === field.code) || false;
      }
      return updated;
    });
  };

  const toggleSection = (sectionKey: string) => {
    setOpenSections((prevOpenSections: any) => ({
      ...prevOpenSections,
      [sectionKey]: !prevOpenSections[sectionKey]
    }));
  };

  const handleAddField = (e: any, item: any) => {
    e.stopPropagation();
    if (exportFields?.length === maxExportFields) {
      toast.warning(lang("GENERAL.MAX.FIELD.EXPORT"));
      return;
    } else {
      setExportFields([...exportFields, item]);
      setDataFields([...toggleSelectField(item, true)]);
    }
  };

  const handleDeleteField = (e: any, item: any) => {
    e.stopPropagation();
    let code = item?.code;
    let index = exportFields.findIndex((item) => item?.code === code);
    let _exportFields = [...exportFields];
    _exportFields.splice(index, 1);
    setDataFields([...toggleSelectField(item, false)]);
    setExportFields([..._exportFields]);
  };

  const renderElement = (item: any, sectionKey: string) => {
    const handleClickAddField = (e: any) => {
      let key = openSections[sectionKey];
      setOpenSections({
        ...openSections,
        [key]: true
      });
      handleAddField(e, item);
    };

    return !item?.selected &&
    (!keyword ? (
      <div key={item?.code} className="accordion-item relative">
        {item.header}
        <i
          className="spaces bi bi-plus fs-20 color-primary add-field-btn"
          data-bs-toggle="tooltip"
          data-bs-placement="top"
          title="Thêm trường"
          onClick={(e: any) => handleAddField(e, item)}
        ></i>
      </div>
    ) : item?.matched && keyword ? (
      <div key={item?.code} className="accordion-item relative">
        {item.header}
        <i
          className="spaces bi bi-plus fs-20 color-primary add-field-btn"
          data-bs-toggle="tooltip"
          data-bs-placement="top"
          title="Thêm trường"
          onClick={handleClickAddField}
        ></i>
      </div>
    ) : null);
  }

  const renderAccordionItem = (item: any, parentKey = "") => {
    const sectionKey = parentKey ? `${parentKey}_${item.header}` : item.header;

    return (
      <>
        {item?.fields ? (
          <div key={sectionKey} className="accordion-wrapper">
            <div
              className="accordion-header"
              onClick={() => toggleSection(sectionKey)}
            >
              <button
                className="accordion-button justify-content-between"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target={`#collapse${sectionKey}`}
                aria-expanded="true"
                aria-controls={`collapse${sectionKey}`}
              >
                <div className="flex gap-2">
                  <i
                    className={`fs-5 bi bi-chevron-compact-right ${
                      openSections[sectionKey] ? "down" : ""
                    }`}
                  />
                  {item.header}
                </div>
              </button>
            </div>
            {openSections[sectionKey] && (
              <div
                id={`collapse${sectionKey}`}
                className={`accordion-collapse collapse ${sectionKey && "show"}`}
                data-bs-parent="#accordionExample"
              >
                {item?.fields?.map((field: any, index: any) => {
                  return field?.fields
                    ? renderAccordionItem(field, sectionKey)
                    : renderElement(field, sectionKey);
                })}
              </div>
            )}
          </div>
          ) : renderElement(item, sectionKey)
        }
      </>
    );
  };

  const handleSubmit = async (value: any) => {
    if (exportFields.length === 0) return toast.warning(lang("TOAST.SELECT_LIST_FIELDS"));
    let data: any = {};
    if(hasCollapse) {
      dataFields.forEach((item, index) => {
        let key = keys[index];
        data[key] = item;
      });
    } else {
      data = {
        ...dataTable,
        fields: dataFields
      }
    }
    try {
      setPageLoading(true);
      await exportToFile({
        exportAPI: () =>
          exportAPI({
            fieldExportResponseDto: data,
            ids,
            type: template?.type,
            tuNgay: value?.tuNgay,
            denNgay: value?.denNgay,
          }),
        fileName: (template && !checkObject(template)) ? template.name : fileName
      });
      setPageLoading(false);
    } catch (error) {
      setPageLoading(false);
      toast.error(lang("GENERAL.ERROR"));
    }
  };

  const fillDefaultExportField = (dataFieldsWithIndexPath: any = dataFields, notChildren: any = !hasCollapse) => {
    const filteredData = notChildren
      ? filterFieldsByCode(dataFieldsWithIndexPath, defaultField)
      : generateFilteredData(dataFieldsWithIndexPath, defaultField);
    setExportFields(notChildren ? filteredData : flattenFields(filteredData));
  }

  const fetchAvailableFieldsExport = async () => {
    try {
      setPageLoading(true);
      const { data } = await getFieldsAPI();
      if (data?.code === RESPONSE_STATUS_CODE.SUCCESS && data?.data) {
        const keys = Object.keys(data.data);
        setKeys(keys);
        setDataTable(data.data);
        const notChildren = Boolean(data.data?.fields);
        setHasCollapse(!notChildren)
        const dataFieldsWithIndexPath = addIndexPath(notChildren ? data.data?.fields : Object.values(data.data));
        defaultField && fillDefaultExportField(dataFieldsWithIndexPath, notChildren);
        setDataFields(dataFieldsWithIndexPath);
      }
    } catch (error) {
      console.error(error);
      toast.error(lang("GENERAL.ERROR"));
    } finally {
      setPageLoading(false);
    }
  };

  useEffect(() => {
    fetchAvailableFieldsExport();
  }, []);

  const formik = useFormik({
    initialValues: {
      tuNgay: null,
      denNgay: null
    },
    validationSchema,
    onSubmit: handleSubmit
  });

  const handleChangeKeyword = (e: any) => {
    let { value } = e.target;
    setKeyword(value);
    let _data = handleSearch(dataFields, value);
    setDataFields([..._data]);
  };

  const handleKeyDown = (e: any) => {
    if (e.key === KEY.ENTER) {
      e.preventDefault();
    }
  }

  return (
    <Modal
      show={true}
      size="xl"
      onHide={handleClose}
      centered
      aria-labelledby="example-custom-modal-styling-title"
      className="export-excel-dialog"
    >
      <Form onSubmit={formik.handleSubmit}>
        <Modal.Header closeButton>
          <Modal.Title
            id="example-custom-modal-styling-title"
            className="heading-5"
          >
            {lang("GENERAL.DATA.EXPORT")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row className="align-items-end">
            <Col md={6}>
              {listTemplates && listTemplates.length > 0 && 
                <div className="mb-4">
                  <Autocomplete
                    horizontal={true}
                    lable={lang("GENERAL.EXPORT.TEMPLATE")}
                    options={listTemplates}
                    value={template || null}
                    onChange={(value) => setTemplate(value)}
                    isClearable={false}
                  />
                </div>
              }
              {showSearchFields && 
                <TextValidator
                  lable={lang("INPUT.FIELDS.AVAILABLES")}
                  name="cacTruongKhaDung"
                  value={keyword || ""}
                  type="text"
                  className="flex gap-4 mb-4"
                  onKeyDown={handleKeyDown}
                  onChange={handleChangeKeyword}
                />
              }
            </Col>
            <Col md={6}>
              {showRequestDate && (
                <RangeDatePicker
                  label={lang("GENERAL.TIME")}
                  startDateName="tuNgay"
                  endDateName="denNgay"
                  horizontal={true}
                  className="mb-4"
                  handleChange={formik.handleChange}
                  value={formik.values}
                  setFieldValue={formik.setFieldValue}
                  errors={formik.errors}
                  touch={formik.touched}
                />
              )}
            </Col>
            <Col md={6}>
              <div className="tree-list">
                <div className="accordion" id="accordionExample">
                  {dataFields.map((item: any, index: any) => {
                    return renderAccordionItem(item);
                  })}
                </div>
              </div>
            </Col>
            <Col md={6}>
              <div className="selected-fields">
                <div className="selected-fields-wrapper">
                  {exportFields?.map((item, index) => (
                    <div
                      key={index}
                      className="flex justify-content-between p-2 selected-item cursor-pointer"
                    >
                      <div className="flex gap-2 align-items-center">
                        <i className="bi bi-arrows-move color-primary"></i>
                        {item?.namePath}
                      </div>
                      {item?.isRemove !== false && (
                        <i
                          className="bi bi-trash fs-4 color-red"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          title="Xóa trường"
                          onClick={(e: any) => handleDeleteField(e, item)}
                        ></i>
                      )}
                    </div>
                  ))}
                </div>
              </div>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer className="flex-center">
          <GroupButton type="btn-cancel" handleCancel={handleClose} />
          <Button
            variant="primary"
            className="button-primary btn-sm"
            type="submit"
          >
            {lang("GENERAL.EXPORT")}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};
