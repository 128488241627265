/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { exportTiemChung } from "../../utils/ExportFileServices";
import { exportToFile, formatDateTable } from "../../utils/FunctionUtils";
import { REF_TAB } from "../const/ProfileConst";
import { InitVaccine, VaccineInfo } from "../models/DialogModels";
import { deleteTiemChung, getAllTiemChungById } from "../services/DialogServices";
import { VaccinationDialog } from "./Dialog/VaccinationDialog";
import useMultiLanguage from "../../../hook/useMultiLanguage";
import TableCustom from "../../component/table-custom/TableCustom";
import { RESPONSE_STATUS_CODE, TYPE } from "../../utils/Constant";
import AppContext from "../../../AppContext";
import { EXPORT_FILE_NAME } from "../../../Constant";

const VaccinationInformation = (props: any) => {
  const { lang } = useMultiLanguage();
  const { identify, isView, employeeProfiles, activeTab } = props;
  const [shouldOpenVaccinationDialog, setShouldOpenVaccinationDialog] = useState<boolean>(false);
  const [listVaccine, setListVaccine] = useState<VaccineInfo[]>([]);
  const [dataSelect, setDataSelect] = useState<VaccineInfo>(InitVaccine);
  const [lastDate, setLastDate] = useState<string | null>(null);
  const { setPageLoading } = useContext(AppContext);

  useEffect(() => {
    if (!listVaccine || !listVaccine.length) return;
    setLastDate(listVaccine[0]?.ngayTiem);
  }, [listVaccine])

  useEffect(() => {
    if (identify && activeTab === REF_TAB.TT_TIEM_CHUNG) {
      updateDataVaccine();
    }
  }, [employeeProfiles, activeTab]);

  const handleOpenVaccinationDialog = (row: any) => {
    setShouldOpenVaccinationDialog(true);
    setDataSelect(row);
  };
  const handleCloseVaccinationDialog = (): void => {
    updateDataVaccine();
    setShouldOpenVaccinationDialog(false);
    setDataSelect(InitVaccine);
  };

  const updateDataVaccine = async () => {
    if (identify) {
      try {
        setPageLoading(true)
        const data = await getAllTiemChungById(identify);
        if (data?.data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
          setListVaccine(data?.data?.data);
          setPageLoading(false);
        }
      } catch (error) {
        toast.error(lang("GENERAL.ERROR"));
      }
    }
  };

  const handleDelete = async (ids: string) => {
    try {
      const { status } = await deleteTiemChung(ids);
      if (status === RESPONSE_STATUS_CODE.SUCCESS) {
        toast.success(lang("TOAST.DELETE.SUCCESS"));
        updateDataVaccine();
        return true;
      }
    } catch (error) {
      toast.error(lang("GENERAL.ERROR"));
    }
  };

  const columns = [
    {
      name: lang("TABLE.INDEX"),
      field: "",
      render: (row: any, index: number, stt: number) => <span>{stt}</span>
    },
    {
      name: lang("INPUT.VACCINATION.DATE"),
      field: "ngayTiem",
      headerStyle: {
        minWidth: "200px"
      },
      render: (row: any) => <span>{formatDateTable(row?.ngayTiem)}</span>
    },
    {
      name: lang("INPUT.VACCINATION.DISEASES"),
      field: "phongBenhText",
      headerStyle: {
        minWidth: "200px"
      }
    },
    {
      name: lang("INPUT.VACCINATION.KIND"),
      field: "loaiVacxin",
      headerStyle: {
        minWidth: "200px"
      }
    },
    {
      name: lang("INPUT.VACCINATION.COUNT"),
      field: "muiTiem",
      headerStyle: {
        minWidth: "200px"
      },
      render: (row: any) => <span>{row?.muiTiem?.name}</span>
    },
    {
      name: lang("INPUT.VACCINATION.STATUSAFTER"),
      field: "tinhTrangSkSauTiem",
      headerStyle: {
        minWidth: "200px"
      }
    },
    {
      name: lang("INPUT.VACCINATION.NEXTTIME"),
      field: "lanTiemTiepTheo",
      headerStyle: {
        minWidth: "200px"
      },
      render: (row: any) => <span>{formatDateTable(row?.lanTiemTiepTheo)}</span>
    }
  ];

  return (
    <div className="form-info">
      <div className="block-content">
        <TableCustom
          id="listVaccine"
          data={listVaccine}
          columns={columns}
          isActionTableTab
          type={TYPE.MULTILINE}
          title={lang("INPUT.VACCINATION")}
          fixedColumnsCount={3}
          noToolbar={true}
          noPagination={true}
          buttonAdd={!isView}
          notDelete={isView}
          buttonExportExcel={true}
          handleDoubleClick={handleOpenVaccinationDialog}
          handleOpenDialog={handleOpenVaccinationDialog}
          handleDelete={handleDelete}
          handleExportExcel={() => 
            exportToFile({
              exportAPI: () => exportTiemChung(employeeProfiles.id), 
              fileName: EXPORT_FILE_NAME.TIEM_CHUNG, 
              setPageLoading
            })
          }
          dependencies={activeTab}
        />
      </div>
      {shouldOpenVaccinationDialog && (
        <VaccinationDialog
          open={shouldOpenVaccinationDialog}
          handleClose={handleCloseVaccinationDialog}
          identify={identify}
          isView={isView}
          handleReload={updateDataVaccine}
          dataSelect={dataSelect}
          lastDate={lastDate}
        />
      )}
    </div>
  );
};

export default VaccinationInformation;
