import { useEffect, useState } from "react";
import { REF_TAB } from "../const/ProfileConst";
import ProblemTable from "./Partials/Problem/ProblemTable";

interface Iprops {
  employeeProfiles: any;
  activeTab: string;
}

function ProblemInfomation(props: Iprops) {
  const { employeeProfiles, activeTab } = props;
  const [isCallApi, setIsCallApi] = useState(false);

  useEffect(() => {
    setIsCallApi(employeeProfiles?.id && activeTab === REF_TAB.SU_CO);
  }, [employeeProfiles, activeTab]);

  return (
    <div className="form-info">
      <div className="block-content">
        <ProblemTable
          employeeProfiles={employeeProfiles}
          isCallApi={isCallApi}
        />
      </div>
    </div>
  );
}

export default ProblemInfomation;
