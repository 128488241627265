import { IEmployeeAddition, IEmployeeSelect, IEmployeeSelectList } from "../models/insuranceModel";

export const convertDataEmployeeSelect = (data: IEmployeeAddition[]): IEmployeeSelect[] => {
  return data?.map((item) => {
    return {
      tenNhanVien: item?.name || "",
      employeeId: item?.id || item?.employeeId || "",
      maNhanVien: item?.maNhanVien || "",
      phongBanId: item?.phongBanId || "",
      phongBanText: item?.phongBanText || "",
      chucVuId: item?.chucVuId || "",
      chucVuText: item?.chucVuText || "",
      ngaySinh: item?.birthDate || ""
    };
  });
};

export const convertSaveDataEmployee = (
  data: IEmployeeSelectList[],
  moneyAdd: {
    moneyCompanySupport?: number | null;
    moneyEmployeePay?: number | null;
  }
) => {
  const { moneyCompanySupport, moneyEmployeePay } = moneyAdd;
  return data.map((dataDepartment) => {
    const dataEmployee = dataDepartment.data.map((employee) => {
      const updatedEmployee = {
        ...employee
      };

      if (moneyCompanySupport !== undefined) {
        updatedEmployee.employeeSoTienCongTyHoTro = moneyCompanySupport;
      }

      if (moneyEmployeePay !== undefined) {
        updatedEmployee.employeeSoTienDong = moneyEmployeePay;
      }

      return updatedEmployee;
    });

    return { ...dataDepartment, data: dataEmployee };
  });
};

export const convertDataEmployeeSingle = (
  data: IEmployeeSelect[],
  moneyCompanySupport: number | null,
  moneyEmployeePay: number | null
) => {
  let employeeConverted: IEmployeeSelect = {
    ...data[0],
    employeeSoTienDong: moneyEmployeePay,
    employeeSoTienCongTyHoTro: moneyCompanySupport
  };

  return [
    {
      phongBanId: data[0]?.phongBanId || "",
      phongBanText: data[0]?.phongBanText || "",
      data: [{ ...employeeConverted, isChecked: false }]
    }
  ];
};
