import useMultiLanguage, { ILang } from "../../../hook/useMultiLanguage";
import { TYPE_CATEGORY } from "../../constant";
import { OptionReactSelect } from "../../models/models";
import { IItemSearch } from "../../profile/models/ProfileModels";
import { searchPhongBan } from "../../profile/services/DialogServices";
import { getAllDonVi } from "../../profile/services/ProfileServices";
import { getAllSimpleValue } from "../../services";
import { getListWorkUnit } from "../../utils/CategoryServices";
import { COMPLETED_STATUS, TYPE } from "../../utils/Constant";
import { IAppointment } from "../models/IAppointment";
import { SEARCH_OBJECT_MAX_SIZE } from './../../utils/Constant';

export const LOAI = {
  BO_NHIEM_LAN_DAU: 1,
  BO_NHIEM_LAI: 2
}

export const LOAI_BO_NHIEM: OptionReactSelect[] = [
  { name: "Bổ nhiệm lần đầu", code: LOAI.BO_NHIEM_LAN_DAU },
  { name: "Bổ nhiệm lại", code: LOAI.BO_NHIEM_LAI },
]

export const INIT_BO_NHIEM: IAppointment = {
  maNhanVien: "",
  employeeId: "",
  employee: null,
  fullName:"",
  donViCongTacText: "",
  donViCongTacId:"",
  phongBanText: "",
  phongBanId: "",
  chucVuText: "",
  chucVuId: "",
  loaiBoNhiem: null,
  donViCongTacMoiText: "",
  donViCongTacMoiId: "",
  donViCongTacMoi: null,
  phongBanMoiText: "",
  phongBanMoiId: "",
  phongBanMoi: null,
  chucVuMoiId: "",
  chucVuMoiText: "",
  chucVuMoi: null,
  thoiGianHieuLucTuNgay: "",
  thoiGianHieuLucDenNgay: "",
  nhiemKyTuNgay: "",
  nhiemKyDenNgay: "",
  ngayQuyetDinh: "",
  nhiemKy: "",
  soQuyetDinh: "",
  fileId: "",
  fileName: "",
  trangThai: null,
  ghiChu: "",
  phongBanMoiKhac: "",
  chucVuMoiKhac: ""
}

export const ListSearch = (lang: ILang) => {
  return [
    {
      name: lang("PROFILE.CODE"),
      field: "maNhanVien",
      type: TYPE.TEXT,
    },
    {
      name: lang("PROFILE.NAME"),
      field: "ten",
      type: TYPE.TEXT,
    },
    {
      name: lang("GENERAL.CURRENT_ORGANIZATION"),
      field: "donViCongTacId",
      searchFunction: getListWorkUnit,
      searchObject: { ...SEARCH_OBJECT_MAX_SIZE },
      type: TYPE.SELECT,
    },
    {
      name: lang("INPUT_DISMISSED_UNIT_DEPARTMENT"),
      field: "phongBanIds",
      searchFunction: searchPhongBan,
      searchObject: { ...SEARCH_OBJECT_MAX_SIZE },
      type: TYPE.SELECT,
      isMulti: true,
    },
    {
      name: lang("INPUT_DISMISSED_JOB_POSITION"),
      field: "chucVuId",
      searchFunction: getAllSimpleValue,
      searchObject: TYPE_CATEGORY.chucDanh,
      type: TYPE.SELECT,
      optionLabel: "value"
    },
    {
      name: lang("INPUT_DISMISSED_WORK_UNIT_NEW"),
      field: "donViCongTacMoiId",
      searchFunction: getAllDonVi,
      searchObject: { ...SEARCH_OBJECT_MAX_SIZE },
      type: TYPE.SELECT,
    },
    {
      name: lang("INPUT_DISMISSED_UNIT_DEPARTMENT_NEW"),
      field: "phongBanMoiIds",
      searchFunction: searchPhongBan,
      searchObject: { ...SEARCH_OBJECT_MAX_SIZE },
      type: TYPE.SELECT,
      isMulti: true,
    },
    {
      name: lang("INPUT_DISMISSED_JOB_POSITION_NEW"),
      field: "chucVuMoiId",
      searchFunction: getAllSimpleValue,
      searchObject: TYPE_CATEGORY.chucDanh,
      type: TYPE.SELECT,
      optionLabel: "value"
    },
    {
      name: lang("INPUT.WORKPROCESS.DECISION.NUMBER"),
      field: "soQuyetDinh",
      type: TYPE.TEXT,
    },
    {
      name: lang("GENERAL.STATUS"),
      field: "trangThai",
      type: TYPE.SELECT,
      listOption: COMPLETED_STATUS,
      isMulti: true,
    },
  ]
}

export const INIT_INPUT_SEARCH: IItemSearch = {
  name: "",
  type: TYPE.TEXT,
  field: "",
  value: null
};
