/* eslint-disable react-hooks/exhaustive-deps */
import { AxiosResponse } from "axios";
import { useContext, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import useMultiLanguage from "../../hook/useMultiLanguage";
import Autocomplete from "../component/input-field/Autocomplete";
import InputSearch from "../component/input-field/InputSearch";
import TextValidator from "../component/input-field/TextValidator";
import TableCollapseCustom, { columnNamesType } from "../component/table-collapse-custom/TableCollapseCustom";
import { SUCCESS_CODE } from "../contract/const/ContractConst";
import { searchListWorkUnit } from "../utils/CategoryServices";
import { RESPONSE_STATUS_CODE, SEARCH_OBJECT_MAX_SIZE, VARIABLE_STRING } from "../utils/Constant";
import "./Warning.scss";
import { WarningChart } from "./components/WarningChart";
import { canhBaoHetHanHopDongColumn } from "./components/columns/canhBaoHetHanHopDongColumn";
import { canhBaoHetHanNangLuongColumn } from "./components/columns/canhBaoHetHanNangLuongColumn";
import {
  EXPIRE_STATUS_KEY,
  HOE_NHAI_CODE,
  TYPE_CHART,
  WARNING,
  chartHeight,
  chartOptions,
  chartType,
  infoTextColorLabel,
  infoTextKey,
  infoTextLabel,
  totalKey,
  warningId,
  warningPrefix,
  warningTitle
} from "./const/WarningConst";
import { IAdjustSalary, IDataSeries, IParams, IRetireInfo, IWarning, IWarningStatus } from "./models/WarningModels";
import {
  getDataCanhBaoDenHanVanBangChungChi,
  getDataCanhBaoHetHanHopDong,
  getDataCanhBaoHetHanLuanChuyen,
  getDataCanhBaoHetHanNangLuong,
  getDataCanhBaoNhanSuDenTuoiNghiHuu,
  getDataWarning,
  getNghiViecChamDutHopDong
} from "./services/WarningServices";
import { AdjustSalaryDialog } from "./components/dialogs/AdjustSalaryDialog";
import { canhBaoNhanSuDenTuoiNghiHuu } from "./components/columns/canhBaoNhanSuDenTuoiNghiHuu";
import { RetireDecisionDialog } from "./components/dialogs/RetireDecisionDialog";
import { canhBaoDenHanVanBangChungChi } from "./components/columns/canhBaoDenHanVanBangChungChi";
import AppContext from "../../AppContext";
import moment from "moment";
import { ContractDialog } from "../profile/component/Dialog/ContractDialog";
import { IContractInfo } from "../contract/services/models/IContract";
import { getEmployeeProfileById } from "../profile/services/ProfileServices";
import { convertDataUI as convertEmployeeInfo } from "../profile/utils/FunctionUtils";
import EndContractDialog from "./components/dialogs/EndContractDialog";
import { ResignInfo } from "../resign/models/IResign";
import { canhBaoHetHanLuanChuyenColumn } from "./components/columns/canhBaoHetHanLuanChuyenColumn";
import TransitionWarningDialog from "./components/dialogs/TransitionWarningDialog";
import { TransitionInFo } from "../transition/models/transitionModel";
import { removeDiacritics } from "../utils/FunctionUtils";

function Warning() {
  const { lang } = useMultiLanguage();
  const { setPageLoading } = useContext(AppContext);

  const [isViewDetails, setIsViewDetails] = useState<boolean>(false);
  const [isViewAll, setIsViewAll] = useState<boolean>(true);
  const [dataChart, setDataChart] = useState<any>();
  const [dataWarningDetails, setDataWarningDetails] = useState<any[]>([]);
  const [listWarning, setListWarning] = useState<IWarning[]>([]);
  const [columns, setColumns] = useState<columnNamesType[]>([]);
  const [title, setTitle] = useState<string>("");
  const [salaryInfo, setSalaryInfo] = useState<IAdjustSalary>({} as IAdjustSalary);
  const [isOpenAdjustSalaryDialog, setIsOpenAdjustSalaryDialog] = useState<boolean>(false);
  const [retireInfo, setRetireInfo] = useState<IRetireInfo>({} as IRetireInfo);
  const [isOpenRetireDialog, setIsOpenRetireDialog] = useState<boolean>(false);
  const [currentDetail, setCurrentDetail] = useState("");
  const [isOpenAddContractDialog, setIsOpenAddContractDialog] = useState<boolean>(false);
  const [isOpenEndContractDialog, setIsOpenEndContractDialog] = useState<boolean>(false);
  const [isOpenTransitionDialog, setIsOpenTransitionDialog] = useState<boolean>(false);
  const [employeeInfo, setEmployeeInfo] = useState<any>({});
  const [initContract, setInitContract] = useState<IContractInfo>({} as IContractInfo);
  const [endResignInfo, setEndResignInfo] = useState<ResignInfo | null>(null);
  const [curWarningInfo, setCurWarningInfo] = useState<any>({});
  const [searchObject, setSearchObject] = useState<any>({
    donVi: null,
    orgId: "",
    keyword: null,
    requestDate: moment().format("YYYY-MM-DD")
  });

  const fetchDataWarning = async (params: IParams = searchObject) => {
    try {
      setPageLoading(true);
      let { data } = await getDataWarning(params);
      if (data?.code === SUCCESS_CODE) {
        setDataChart(data?.data);
      }
      setPageLoading(false);
    } catch (error) {
      setPageLoading(false);
      toast.error(lang("GENERAL.ERROR"));
    }
  };

  const getCanhBao = async (fetchFunc: (data: IParams) => Promise<AxiosResponse<any>>, expireStatusKey: string, searchParam: IParams = searchObject) => {
    try {
      setPageLoading(true);
      let { data } = await fetchFunc(searchParam);
      if (data?.code === SUCCESS_CODE) {
        let _data = data?.data?.map((item: any) => ({
          ...item,
          data: item?.data?.map((subItem: any) => ({
            ...subItem,
            [expireStatusKey]: {
              ...subItem[expireStatusKey],
              bgColor: checkColorCanhBao({ ...subItem[expireStatusKey] })
            }
          }))
        }));
        setDataWarningDetails(_data);
      }
    } catch (error) {
      toast.error(lang("GENERAL.ERROR"));
    } finally {
      setPageLoading(false);
    }
  };

  useEffect(() => {
    if (!dataChart) return;
    const listChart = convertDataChart(dataChart);
    if(!searchObject.keyword) {
      setListWarning(listChart);
      return;
    }
    setListWarning(listChart.filter((chart: any) => 
      removeDiacritics(chart.title).includes(removeDiacritics(searchObject.keyword))
    ));
  }, [dataChart, isViewAll]);

  const convertChartOptions = (chartOptions: any, typeChart: string, dataSeries: IDataSeries[]) => {
    switch (typeChart) {
      case TYPE_CHART.DONUT:
        return {
          ...chartOptions,
          series: [
            {
              ...chartOptions.series[0],
              data: dataSeries
            }
          ]
        };
      default:
        return chartOptions;
    }
  };

  const convertDataChart = (dataChart: IWarning) => {
    if (!dataChart) return [];
    let result: IWarning[] = [];
    warningPrefix.forEach((prefix, index) => {
      let obj: any = {};
      let dataChartObj: any = {};
      Object.keys(dataChart).forEach((key) => {
        obj.title = warningTitle[index];
        obj.id = warningId[index];
        if (key.startsWith(prefix) && key !== totalKey[index]) {
          dataChartObj[key] = dataChart[key as keyof Pick<IWarning, "value">];
        }
      });
      let _dataInfoText = infoTextKey[index].map((key, idx) => ({
        key: key,
        name: infoTextLabel[index][idx],
        value: dataChart[key as keyof Pick<IWarning, "value">],
        colorLabel: infoTextColorLabel[index][idx]
      }));
      let _dataSeries: IDataSeries[] = infoTextKey[index]
        .filter((key) => key !== totalKey[index])
        .map((key, idx) => ({
          name: infoTextLabel[index][idx],
          value: dataChart[key as keyof Pick<IWarning, "value">]
        }));
      result.push({
        ...obj,
        dataInfoText: _dataInfoText,
        type: chartType[index],
        options: convertChartOptions(chartOptions[index], chartType[index], _dataSeries),
        height: chartHeight[index]
      });
    });

    if (!isViewAll) {
      result = result.filter((item: any) => item?.dataInfoText?.some((dataInfo: any) => dataInfo.value))
    }

    return result;
  };

  const checkColorCanhBao = (status: IWarningStatus) => {
    switch (status.code) {
      case WARNING.STATUS.CODE.GAN_DEN_HAN:
        return "bg-green";
      case WARNING.STATUS.CODE.DEN_HAN:
        return "bg-dark-orange";
      case WARNING.STATUS.CODE.QUA_HAN:
        return "bg-dark-red";
      default:
        break;
    }
  };

  useEffect(() => {
    fetchDataUnit();
  }, []);

  const fetchDataUnit = async () => {
    try {
      let { data } = await searchListWorkUnit(SEARCH_OBJECT_MAX_SIZE);
      if (data?.code === SUCCESS_CODE) {
        const unitDefault = data?.data?.content?.find((unit: any) => unit?.code === HOE_NHAI_CODE);
        const _searchObject = {
          ...searchObject,
          donVi: unitDefault || null,
          orgId: unitDefault?.id || ""
        };
        setSearchObject(_searchObject);
        await fetchDataWarning(_searchObject);
      }
    } catch (error) {
      toast.error(lang("GENERAL.ERROR"));
    }
  };

  const handleAdjustSalary = (row: IAdjustSalary) => {
    setSalaryInfo(row);
    setIsOpenAdjustSalaryDialog(true);
  };

  const handleDecideToRetire = (row: any) => {
    setRetireInfo(row);
    setIsOpenRetireDialog(true);
  };

  const handleReSignContract = async (row: any) => {
    try {
      setPageLoading(true);
      setCurWarningInfo(row);
      const { data } = await getEmployeeProfileById(row?.employeeId || "");
      if (data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
        const employeeInfoConverted = convertEmployeeInfo(data?.data || {});
        const initContract = {
          employee: employeeInfoConverted,
          employeeCode: employeeInfoConverted?.code,
          phongBan: employeeInfoConverted?.phongBan,
          chucVu: employeeInfoConverted?.chucVu,
          chucDanh: employeeInfoConverted?.viTriCongViec,
          loaiCanBo: employeeInfoConverted?.loaiCanBo,
          tenNguoiLaoDong: employeeInfoConverted?.name,
          employeeId: employeeInfoConverted?.id
        };
        await setEmployeeInfo(employeeInfoConverted);
        await setInitContract(initContract as IContractInfo);
        setIsOpenAddContractDialog(true);
      }
      setPageLoading(false);
    } catch (error) {
      toast.error(lang("GENERAL.ERROR"));
      setPageLoading(false);
    }
  };

  const handleEndContract = async (row: any) => {
    setCurWarningInfo(row);
    try {
      setPageLoading(true);
      const { data } = await getNghiViecChamDutHopDong(row?.employeeId);
      if (data?.code === RESPONSE_STATUS_CODE.SUCCESS) { 
        setEndResignInfo(data?.data || null);
      }
    } catch (error) {
      toast.error(lang("GENERAL.ERROR"));
    } finally {
      setPageLoading(false);
    }
    setIsOpenEndContractDialog(true);
  };

  const handleAddTrasition = (row: any) => {
    setCurWarningInfo(row);
    setIsOpenTransitionDialog(true);
  }

  const handleOpenDetails = (idWarning: string, title: string) => {
    const _searchObject = {
      ...searchObject,
      keyword: null
    };
    setSearchObject(_searchObject);
    switch (idWarning) {
      case VARIABLE_STRING.CANH_BAO_HET_HAN_HOP_DONG:
        getCanhBao(getDataCanhBaoHetHanHopDong, EXPIRE_STATUS_KEY.HET_HAN_HOP_DONG, _searchObject);
        setColumns(canhBaoHetHanHopDongColumn(handleReSignContract, handleEndContract));
        setCurrentDetail(VARIABLE_STRING.CANH_BAO_HET_HAN_HOP_DONG);
        break;
      case VARIABLE_STRING.CANH_BAO_DEN_HAN_NANG_LUONG:
        getCanhBao(getDataCanhBaoHetHanNangLuong, EXPIRE_STATUS_KEY.DEN_HAN_NANG_LUONG, _searchObject);
        setColumns(canhBaoHetHanNangLuongColumn(handleAdjustSalary));
        setCurrentDetail(VARIABLE_STRING.CANH_BAO_DEN_HAN_NANG_LUONG);
        break;
      case VARIABLE_STRING.CANH_BAO_NHAN_SU_DEN_TUOI_NGHI_HUU:
        getCanhBao(getDataCanhBaoNhanSuDenTuoiNghiHuu, EXPIRE_STATUS_KEY.DEN_TUOI_NGHI_HUU, _searchObject);
        setColumns(canhBaoNhanSuDenTuoiNghiHuu(handleDecideToRetire));
        setCurrentDetail(VARIABLE_STRING.CANH_BAO_NHAN_SU_DEN_TUOI_NGHI_HUU);
        break;
      case VARIABLE_STRING.CANH_BAO_DEN_HAN_VAN_BANG_CHUNG_CHI:
        getCanhBao(getDataCanhBaoDenHanVanBangChungChi, EXPIRE_STATUS_KEY.DEN_HAN_VAN_BANG_CHUNG_CHI, _searchObject);
        setColumns(canhBaoDenHanVanBangChungChi());
        setCurrentDetail(VARIABLE_STRING.CANH_BAO_DEN_HAN_VAN_BANG_CHUNG_CHI);
        break;
      case VARIABLE_STRING.CANH_BAO_HET_HAN_LUAN_CHUYEN:
        getCanhBao(getDataCanhBaoHetHanLuanChuyen, EXPIRE_STATUS_KEY.HET_HAN_LUAN_CHUYEN, _searchObject);
        setColumns(canhBaoHetHanLuanChuyenColumn(handleAddTrasition));
        setCurrentDetail(VARIABLE_STRING.CANH_BAO_HET_HAN_LUAN_CHUYEN);
        break;
      default:
        setDataWarningDetails([]);
        setCurrentDetail("");
        break;
    }
    setTitle(title);
    setIsViewDetails(true);
  };

  const handleSelectData = (data: any) => {};

  const handleSetData = (data: any) => {};

  const handleCloseAdjustSalaryDialog = () => {
    setSalaryInfo({} as IAdjustSalary);
    setIsOpenAdjustSalaryDialog(false);
  };

  const handleCloseRetireDialog = () => {
    setRetireInfo({} as IRetireInfo);
    setIsOpenRetireDialog(false);
  };

  const handleCloseDetail = () => {
    const _searchObject = {
      ...searchObject,
      keyword: null
    };
    setSearchObject(_searchObject);
    setIsViewDetails(false);
    setDataWarningDetails([]);
    setTitle("");
    setCurrentDetail("");
    fetchDataWarning(_searchObject);
  };

  const handleSearch = () => {
    searchObject.keyword = searchObject?.keyword?.trim() || ""
    switch (currentDetail) {
      case VARIABLE_STRING.CANH_BAO_HET_HAN_HOP_DONG:
        getCanhBao(getDataCanhBaoHetHanHopDong, EXPIRE_STATUS_KEY.HET_HAN_HOP_DONG, searchObject);
        break;
      case VARIABLE_STRING.CANH_BAO_DEN_HAN_NANG_LUONG:
        getCanhBao(getDataCanhBaoHetHanNangLuong, EXPIRE_STATUS_KEY.DEN_HAN_NANG_LUONG, searchObject);
        break;
      case VARIABLE_STRING.CANH_BAO_NHAN_SU_DEN_TUOI_NGHI_HUU:
        getCanhBao(getDataCanhBaoNhanSuDenTuoiNghiHuu, EXPIRE_STATUS_KEY.DEN_TUOI_NGHI_HUU, searchObject);
        break;
      case VARIABLE_STRING.CANH_BAO_DEN_HAN_VAN_BANG_CHUNG_CHI:
        getCanhBao(getDataCanhBaoDenHanVanBangChungChi, EXPIRE_STATUS_KEY.DEN_HAN_VAN_BANG_CHUNG_CHI, searchObject);
        break;
      case VARIABLE_STRING.CANH_BAO_HET_HAN_LUAN_CHUYEN:
        getCanhBao(getDataCanhBaoHetHanLuanChuyen, EXPIRE_STATUS_KEY.HET_HAN_LUAN_CHUYEN, searchObject);
        break;
      default:
        fetchDataWarning(searchObject);
        break;
    }
  }

  const handleChangeSelect = (name: string, value: any) => {
    setSearchObject({
      ...searchObject,
      [name]: value
    });
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setSearchObject({
      ...searchObject,
      [name]: value
    });
  }

  return (
    <div className="wrapper">
      <div className="spaces flex justify-content-between align-items-center mt-16">
        <h2 className="breadcrumb-title">{`${lang("GENERAL.WARNING.INFO")}${title ? ` / ${title}` : ""}`}</h2>
        <div className="flex gap-4 align-self-end">
          <div className="mr-2 warning-input-search">
            <InputSearch
              className="border-3px"
              name="keyword"
              value={searchObject.keyword || ""}
              placeholder="Nhập vào đây"
              isEnter={true}
              handleSearch={handleSearch}
              handleChange={handleChange}
            />
          </div>
          <button
            className="spaces button-primary"
            onClick={handleSearch}
          >
            {lang("GENERAL.SEARCH")}
          </button>
        </div>
      </div>
      <div className="content-wrapper">
        <Row className="search-area">
          <Col xs={7} xxl={6} className="warning-info">
            {isViewDetails && (
              <div
                className="inline-flex align-items-center cursor-pointer pb-2"
                onClick={handleCloseDetail}
              >
                <i className="bi bi-arrow-bar-left fs-2 me-2 text-primary "></i>
                <span className="fs-3 fw-bold text-primary">{lang("AUTH.GENERAL.BACK_BUTTON")}</span>
              </div>
            )}
            <Row className="align-items-end pb-4">
              <Col
                xs={4}
                md={5}
              >
                <Autocomplete
                  lable={lang("WARNING.UNIT")}
                  options={[]}
                  value={searchObject.donVi || null}
                  name="donVi"
                  onChange={(value) => handleChangeSelect(VARIABLE_STRING.DON_VI, value)}
                  searchFunction={searchListWorkUnit}
                  searchObject={SEARCH_OBJECT_MAX_SIZE}
                />
              </Col>
              <Col
                xs={4}
                md={3}
              >
                <TextValidator
                  lable={lang("WARNING.TO_DATE")}
                  name="requestDate"
                  value={searchObject.requestDate || moment().format("YYYY-MM-DD")}
                  type="date"
                  onChange={handleChange}
                />
              </Col>
              <Col
                xs={4}
                md={4}
                className=""
              >
                <button className="spaces button-primary" onClick={handleSearch}>{lang("WARNING.UPDATE")}</button>
              </Col>
            </Row>
          </Col>
          <Col xs={5} xxl={6} className="advance-search flex justify-content-end align-items-end pb-4">
            {!isViewDetails && (
              <button className="spaces button-primary-outline mr-10" type="submit" onClick={() => setIsViewAll(!isViewAll)}>
                {`${isViewAll ? 'Thu gọn' : 'Hiển thị tất cả'}`}
              </button>
            )}
          </Col>
        </Row>
        {!isViewDetails ? (
          <Row className="warning">
            {listWarning?.map((item: IWarning, index: number) => (
              <Col
                key={index}
                xxl={4}
                xl={6}
                lg={6}
                md={12}
                className="spaces p-12"
                onClick={() => handleOpenDetails(item?.id, item?.title)}
              >
                <WarningChart data={item} />
              </Col>
            ))}
          </Row>
        ) : (
          <>
            <Row className="table">
              <TableCollapseCustom
                columnNameList={columns}
                data={dataWarningDetails}
                nameParent="phongBan"
                nameChildren="data"
                isSelect
                noToolbar
                selectData={handleSelectData}
                setData={handleSetData}
                selectionMode="multiple"
              />
            </Row>
          </>
        )}
      </div>
      {isOpenAdjustSalaryDialog && (
        <AdjustSalaryDialog
          handleClose={handleCloseAdjustSalaryDialog}
          salaryInfo={salaryInfo}
          handleUpdateParent={() => getCanhBao(getDataCanhBaoHetHanNangLuong, EXPIRE_STATUS_KEY.DEN_HAN_NANG_LUONG)}
        />
      )}
      {isOpenRetireDialog && (
        <RetireDecisionDialog
          handleClose={handleCloseRetireDialog}
          retireInfo={retireInfo}
          handleUpdateParent={() => getCanhBao(getDataCanhBaoNhanSuDenTuoiNghiHuu, EXPIRE_STATUS_KEY.DEN_TUOI_NGHI_HUU)}
        />
      )}

      {isOpenAddContractDialog && (
        <ContractDialog
          isView={false}
          handleCloseContractDialog={() => setIsOpenAddContractDialog(false)}
          fetchContractParent={() => getCanhBao(getDataCanhBaoHetHanHopDong, EXPIRE_STATUS_KEY.HET_HAN_HOP_DONG)}
          shouldOpenContractDialog={isOpenAddContractDialog}
          employeeInfo={employeeInfo}
          contractEdit={initContract}
          isReSignContract={true}
          warningInfo={curWarningInfo}
        />
      )}

      {isOpenEndContractDialog && (
        <EndContractDialog
          open={isOpenEndContractDialog}
          handleClose={() => setIsOpenEndContractDialog(false)}
          isView={false}
          resignInfo={endResignInfo}
          warningInfo={curWarningInfo}
          handleReload={() => getCanhBao(getDataCanhBaoHetHanHopDong, EXPIRE_STATUS_KEY.HET_HAN_HOP_DONG)}
        />
      )}

      {isOpenTransitionDialog && (
        <TransitionWarningDialog
          open={isOpenTransitionDialog}
          handleClose={() => setIsOpenTransitionDialog(false)}
          isView={false}
          transferInfo={{} as TransitionInFo}
          handleReload={() => getCanhBao(getDataCanhBaoHetHanLuanChuyen, EXPIRE_STATUS_KEY.HET_HAN_LUAN_CHUYEN)}
          warningInfo={curWarningInfo}
        />
      )}
    </div>
  );
}

export default Warning;
