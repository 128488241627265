import { FC, useState } from "react";
import { INIT_RESIGN_INFO, REF_TAB_RESIGN } from "./const/ResignConst";
import "./resign.scss";
import ResignInformation from "./components/resign/ResignInformation";
import { ResignDialog } from "./components/resign/ResignDialog";
import { ResignInfo } from "./models/IResign";
import InterruptInformation from "./components/Interruption/InterruptInfomation";
import { InterruptDialog } from "./components/Interruption/InterruptDialog";
import { InterruptInfo } from "./models/IInterrupt";
import { INIT_INTERRUPT_INFO } from "./const/InterruptConst";
import { Tab, Tabs,  } from "react-bootstrap";

const Resign: FC = () => {
  const [activeTab, setActiveTab] = useState<string>(REF_TAB_RESIGN.THOI_VIEC);
  const [shouldOpenDialogResign, setShouldOpenDialogResign] = useState<boolean>(false);
  const [shouldOpenDialogInterrupt, setShouldOpenDialogInterrupt] = useState<boolean>(false);
  const [viewInfoResign, setViewInfoResign] = useState<boolean>(false);
  const [viewInfoInterrupt, setViewInfoInterrupt] = useState<boolean>(false);
  const [resignInfo, setResignInfo] = useState<ResignInfo>(INIT_RESIGN_INFO as ResignInfo);
  const [interruptInfo, setInterruptInfo] = useState<InterruptInfo>({} as InterruptInfo);
  const [listResign, setListResign] = useState<ResignInfo[]>([]);
  const [listInterrupt, setListInterrupt] = useState<InterruptInfo[]>([]);
  const [searchObject, setSearchObject] = useState<any>({});

  const handleTabChange = (key: string | null) => {
    setSearchObject({});
    key && setActiveTab(key);
  };
  
  const handleCloseDialog = () => {
    setShouldOpenDialogResign(false);
    setShouldOpenDialogInterrupt(false);
    setViewInfoResign(false);
    setViewInfoInterrupt(false);
    setResignInfo(INIT_RESIGN_INFO);
    setInterruptInfo(INIT_INTERRUPT_INFO);
  };
  
  const handleOpenDialogResign = (row: ResignInfo) => {
    setViewInfoResign(true);
    setShouldOpenDialogResign(true);
    setResignInfo(row);
  };

  const handleOpenAddNewResign = () => {
    setShouldOpenDialogResign(true);
  };

  const handleOpenUpdateDialogResign = () => {
    setViewInfoResign(false);
  };

  const handleCloseUpdateDialogResign = () => {
    setViewInfoResign(true);
  };
  
  const handleOpenDialogInterrupt = (row: InterruptInfo) => {
    setViewInfoInterrupt(true);
    setShouldOpenDialogInterrupt(true);
    setInterruptInfo(row);
  };

  const handleOpenAddNewInterrupt = () => {
    setShouldOpenDialogInterrupt(true);
  };

  const handleOpenUpdateDialogInterrupt = () => {
    setViewInfoInterrupt(false);
  };

  const handleCloseUpdateDialogInterrupt = () => {
    setViewInfoInterrupt(true);
  };

  return (
    <div className="resign spaces mt-16">
      {!shouldOpenDialogResign && !shouldOpenDialogInterrupt && (
        <Tabs
          activeKey={activeTab}
          defaultActiveKey={REF_TAB_RESIGN.THOI_VIEC}
          id="justify-tab-example"
          className="tabs-user-info"
          onSelect={handleTabChange}
        >
          <Tab eventKey={REF_TAB_RESIGN.THOI_VIEC} title="Thôi việc" className="tab-content" id={REF_TAB_RESIGN.THOI_VIEC} mountOnEnter>
            <ResignInformation 
              listResign={listResign}
              setListResign={setListResign}
              handleOpenDialog={handleOpenDialogResign}
              handleOpenAddNewDialog={handleOpenAddNewResign}
              initSearchObject={searchObject}
              setInitSearchObject={setSearchObject}
            />
          </Tab>
          <Tab eventKey={REF_TAB_RESIGN.GIAN_DOAN} title="Gián đoạn" className="tab-content" id={REF_TAB_RESIGN.GIAN_DOAN} mountOnEnter>
            <InterruptInformation 
              listInterrupt={listInterrupt}
              activeTab={activeTab}
              setListInterrupt={setListInterrupt}
              handleOpenDialog={handleOpenDialogInterrupt}
              handleOpenAddNewDialog={handleOpenAddNewInterrupt}
              initSearchObject={searchObject}
              setInitSearchObject={setSearchObject}
            />
          </Tab>
        </Tabs>
      )}
      {shouldOpenDialogResign && (
        <ResignDialog
          isView={viewInfoResign}
          resignInfo={resignInfo}
          setResignInfo={(data: ResignInfo) => setResignInfo(data)}
          handleClose={handleCloseDialog}
          handleOpenUpdateDialog={handleOpenUpdateDialogResign}
          handleCloseUpdateDialog={handleCloseUpdateDialogResign}
        />
      )}
      {shouldOpenDialogInterrupt && (
        <InterruptDialog
          isView={viewInfoInterrupt}
          interruptInfo={interruptInfo}
          listInterrupt={listInterrupt}
          handleClose={handleCloseDialog}
          handleOpenUpdateDialog={handleOpenUpdateDialogInterrupt}
          handleCloseUpdateDialog={handleCloseUpdateDialogInterrupt}
        />
      )}
    </div>
  );
};
export { Resign };
