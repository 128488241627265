import { useContext, useEffect, useState } from "react";
import { IAllowance } from "../../../../contract/services/models/IAllowance";
import { checkStatus, convertNumberPrice, exportToFile, formatDateTable } from "../../../../utils/FunctionUtils";
import { exportPhuCap } from "../../../../utils/ExportFileServices";
import TableCustom from "../../../../component/table-custom/TableCustom";
import { getAllowancesByEmployee } from "../../../services/DialogServices";
import { TYPE } from "../../../../utils/Constant";
import { EmployeeAllowanceDialog } from "../../Dialog/EmployeeAllowanceDialog";
import { deleteAllowance } from "../../../../contract/services/allowanceServices";
import { toast } from "react-toastify";
import { INIT_ALLOWANCE, SUCCESS_CODE } from "../../../../contract/const/ContractConst";
import useMultiLanguage from "../../../../../hook/useMultiLanguage";
import { EXPORT_FILE_NAME } from "../../../../../Constant";
import AppContext from "../../../../../AppContext";
import { LIST_STATUS_ALLOWANCE } from "../../../const/DialogChildConstants";

interface Props {
  isView: boolean;
  salaryId: string;
  isDisplayBtnAdd?: boolean;
}

function Allowance(props: Props) {
  const { lang } = useMultiLanguage();
  const { isView, salaryId, isDisplayBtnAdd = true } = props;

  const [openAllowanceDialog, setOpenAllowanceDialog] = useState<boolean>(false);
  const [allowanceInfo, setAllowanceInfo] = useState<IAllowance>({});
  const [listAllowance, setListAllowance] = useState<IAllowance[]>([]);
  const { setPageLoading } = useContext(AppContext);

  useEffect(() => {
    updateDataAllowance();
  }, [salaryId])

  const handleOpenAllowanceDialog = (data: any = {}) => {
    setOpenAllowanceDialog(true);
    setAllowanceInfo(data);
  };

  const handleCloseAllowanceDialog = () => {
    setAllowanceInfo({});
    setOpenAllowanceDialog(false);
  };

  const updateDataAllowance = async () => {
    try {
      setPageLoading(true);
      let data = await getAllowancesByEmployee(salaryId || "");
      setListAllowance(data?.data?.data || []);
    } catch (error) {
      toast.error(lang("GENERAL.ERROR"));
    } finally {
      setPageLoading(false);
    }
  };

  const handleDeleteItems = async (ids: string) => {
    try {
      const res = await deleteAllowance(ids);
      if (res?.data?.code === SUCCESS_CODE) {
        toast.success(lang("GENERAL.DELETE_SUCCESS"));
        updateDataAllowance();
        return true;
      } else toast.error(`${res?.data?.message}`);
    } catch (error) {
      toast.error(lang("GENERAL.ERROR"));
    }
  };

  const allowanceColumns = [
    {
      name: lang("TABLE.INDEX"),
      field: "stt",
      headerStyle: {
        minWidth: "50px"
      },
      render: (row: any, index: number, STT: number) => <span>{STT}</span>
    },
    {
      name: "Trạng thái",
      field: "trangThai",
      headerStyle: {
        minWidth: "140px",
        textAlign: "center",
      },
      render: (row: any, index: number) => (
        <span 
          className={
            `rounded-5xl spaces px-12 py-4 text-white 
            ${checkStatus(LIST_STATUS_ALLOWANCE, Number(row?.trangThai?.code))}`
          }
        >
          {row?.trangThai?.name}
        </span>
      )
    },
    {
      name: lang("ALLOWANCE.TYPE"),
      field: "tenKhoanPhuCap",
      headerStyle: {
        minWidth: "200px"
      },
      render: (row: any) => <div>{row?.tenKhoanPhuCap?.name}</div>
    },
    {
      name: lang("ALLOWANCE.VALUE"),
      field: "giaTri",
      headerStyle: {
        minWidth: "150px"
      },
      render: (row: any) => <span>{convertNumberPrice(row?.giaTri || null)}</span>
    },
    {
      name: lang("GENERAL.NOTE"),
      field: "ghiChu",
      headerStyle: {
        minWidth: "200px"
      }
    },
    {
      name: lang("INPUT.HEALTHINSURANCE.EFFECTIVEDATE"),
      field: "ngayHieuLuc",
      headerStyle: {
        minWidth: "200px"
      },
      render: (row: any) => (
        <span>
          <span>{formatDateTable(row?.thoiGianHieuLucBatDau)}</span>
          {row?.thoiGianHieuLucKetThuc ? <span> {" - " + formatDateTable(row?.thoiGianHieuLucKetThuc)}</span> : ""}
        </span>
      )
    }
  ];

  return (
    <div className="block-content">
      <TableCustom
        id="allowance"
        title={lang("GENERAL.ALLOWANCE")}
        isActionTableTab
        buttonAdd={!isView && isDisplayBtnAdd}
        buttonExportExcel={true}
        notDelete={isView}
        noToolbar={true}
        data={listAllowance}
        columns={allowanceColumns}
        handleDelete={handleDeleteItems}
        justFilter={true}
        type={TYPE.MULTILINE}
        noPagination={true}
        handleOpenDialog={() => handleOpenAllowanceDialog(INIT_ALLOWANCE)}
        handleExportExcel={() => 
          exportToFile({
            exportAPI: () => exportPhuCap(String(salaryId)), 
            fileName: EXPORT_FILE_NAME.PHU_CAP,
            setPageLoading
          })
        }
        handleDoubleClick={handleOpenAllowanceDialog}
      />

      {openAllowanceDialog && (
        <EmployeeAllowanceDialog
          salaryId={salaryId}
          isView={isView}
          getListAllowance={updateDataAllowance}
          handleCLoseAllowanceDialog={handleCloseAllowanceDialog}
          allowanceInfo={allowanceInfo}
        />
      )}
    </div>
  );
}

export default Allowance;
