import { ILang } from "../../../hook/useMultiLanguage";
import { TYPE_CATEGORY } from "../../constant";
import { IItemSearch } from "../../profile/models/ProfileModels";
import { searchPhongBan } from "../../profile/services/DialogServices";
import { searchAllSimpleValue } from "../../utils/CategoryServices";
import { MISSION_STATUS, SEARCH_OBJECT_MAX_SIZE, TYPE } from "../../utils/Constant";
import { IConcurrently } from "../models/concurrentlyModel";

export const INIT_INPUT_SEARCH: IItemSearch = {
  name: "",
  type: TYPE.TEXT,
  field: "",
  value: null
};

export const INIT_CONCURRENTLY: IConcurrently = {
  employee: null,
  chucVuId: "",
  chucVuMoi: null,
  chucVuMoiId: "",
  chucVuMoiText: "",
  chucVuText: "",
  createDate: "",
  createdBy: "",
  denNgay: null,
  employeeId: "",
  fileId: "",
  fileName: "",
  ghiChu: "",
  id: "",
  maNhanVien: "",
  modifiedBy: "",
  modifyDate: "",
  name: "",
  ngayQuyetDinh: "",
  phongBanId: "",
  phongBanMoi: null,
  phongBanMoiId: "",
  phongBanMoiText: "",
  phongBanText: "",
  soQuyetDinh: "",
  trangThai: null,
  tuNgay: null,
  xacNhanKiemNhiem: false,
} 

export const ListSearch = (lang: ILang) => {
  return [
    {
      name: lang("PROFILE.CODE"),
      field: "maNhanVien",
      type: TYPE.TEXT,
    },
    {
      name: lang("PROFILE.NAME"),
      field: "name",
      type: TYPE.TEXT,
    },
    {
      name: lang("INPUT.WORKPROCESS.DEPARTMENT.OLD"),
      field: "phongBanIds",
      searchFunction: searchPhongBan,
      searchObject: { ...SEARCH_OBJECT_MAX_SIZE },
      type: TYPE.SELECT,
      isMulti: true,
    },
    {
      name: lang("RESIGN.OLDPOSITION"),
      field: "chucVuId",
      searchFunction: searchAllSimpleValue,
      searchObject: { ...SEARCH_OBJECT_MAX_SIZE, type: TYPE_CATEGORY.chucDanh },
      type: TYPE.SELECT,
      optionLabel: "value"
    },
    {
      name: lang("INPUT.WORKPROCESS.DEPARTMENT.NEW"),
      field: "phongBanMoiIds",
      searchFunction: searchPhongBan,
      searchObject: { ...SEARCH_OBJECT_MAX_SIZE },
      type: TYPE.SELECT,
      isMulti: true,
    },
    {
      name: lang("RESIGN.NEWPOSITION"),
      field: "chucVuMoiId",
      searchFunction: searchAllSimpleValue,
      searchObject: { ...SEARCH_OBJECT_MAX_SIZE, type: TYPE_CATEGORY.chucDanh },
      type: TYPE.SELECT,
      optionLabel: "value"
    },
    {
      name: lang("INPUT.WORKPROCESS.DECISION.NUMBER"),
      field: "soQuyetDinh",
      type: TYPE.TEXT,
    },
    {
      name: lang("GENERAL.STATUS"),
      field: "trangThai",
      type: TYPE.SELECT,
      listOption: MISSION_STATUS,
      isMulti: true,
    },
  ]
}