import { IProblem, IProblemEmployeeList } from "../models/ProblemModel";

export const checkStatus = (listStatus: any[], code: any) => {
  let itemFinded = listStatus ? listStatus.find((item: any) => item?.code === code) : null;
  return itemFinded?.styleClass || "";
};

export const convertDataEmployeeSelect = (data: any[]) => {
  return data?.map((item) => {
    return {
      tenNhanVien: item?.name || "",
      employeeId: item?.id || item?.employeeId || "",
      maNhanVien: item?.maNhanVien || "",
      phongBanId: item?.phongBanId || "",
      phongBanText: item?.phongBanText || "",
      chucVuId: item?.chucVuId || "",
      chucVuText: item?.chucVuText || "",
      soTienViPham: null
    };
  });
};

export const convertDataSubmit = (data: IProblem, employees: any[]) => {
  const dataConverted = {
    ...data,
    hinhThucXuLyId: data?.hinhThucXuLy?.id || "",
    hinhThucXuLyText: String(data?.hinhThucXuLy?.value) || "",
    employeeSuCoList: employees?.map((item: any) => item?.data)?.flat(),
    loaiSuCoId: data?.loaiSuCo?.id || "",
    loaiSuCoText: data?.loaiSuCo?.value || "",
    namTruLuong: Number(data?.namTruLuongObj?.name) ?? null,
    thangTruLuong: Number(data?.thangTruLuongObj?.code) ?? null,
    nguoiPhatHienId: data?.nguoiPhatHien?.id || "",
    nguoiPhatHienText: data?.nguoiPhatHien?.name || ""
  };

  return dataConverted;
};

export const convertDataUI = (data: IProblem) => {
  const dataConverted: IProblem = {
    ...data,
    hinhThucXuLy: data?.hinhThucXuLyId
      ? {
          id: data?.hinhThucXuLyId || "",
          value: data?.hinhThucXuLyText || ""
        }
      : null,
    loaiSuCo: data?.loaiSuCoId
      ? {
          id: data?.loaiSuCoId || "",
          value: data?.loaiSuCoText || ""
        }
      : null,
    namTruLuongObj: data?.namTruLuong
      ? {
          name: data?.namTruLuong || ""
        }
      : null,
    thangTruLuongObj: data?.thangTruLuong
      ? {
          code: data?.thangTruLuong,
          name: `Tháng ${data?.thangTruLuong}`
        }
      : null,
    nguoiPhatHien: data?.nguoiPhatHienId
      ? {
          id: data?.nguoiPhatHienId || "",
          name: data?.nguoiPhatHienText || ""
        }
      : null
  };

  return dataConverted;
};
