import { EXPORT_FILE_NAME } from "../../../Constant";
import { ILang } from "../../../hook/useMultiLanguage";
import { IStatus, OptionReactSelect } from "../../models/models";
import { searchPhongBan } from "../../profile/services/DialogServices";
import { SEARCH_OBJECT_MAX_SIZE, TYPE } from "../../utils/Constant";
import CombineHumanInsuranceForm from "../components/CombineHumanInsuranceForm";
import Insurance24Form from "../components/Insurance24Form";
import CombineHumanViewForm from "../components/viewForm/CombineHumanViewForm";
import InsuranceViewForm from "../components/viewForm/InsuranceViewForm";
import { IInsurance, IInsuranceDetail } from "../models/insuranceModel";
import { CombineHumanInsuranceColumn, CombineHumanViewColumn } from "./columns/combineHumanInsuranceColumn";
import { Insurance24Column, Insurance24ViewColumn } from "./columns/insurance24Column";

export const INSURANCE_TYPES = {
  COMBINE_HUMAN: 1, //Bảo hiểm kết hợp con người
  INSURANCE_24: 2 // Bảo hiểm 24/24
};

export const INSURANCE_LIST: IInsuranceDetail[] = [
  {
    name: "Bảo hiểm kết hợp con người",
    columns: CombineHumanInsuranceColumn(),
    FormComponent: CombineHumanInsuranceForm,
    ViewFormComponent: CombineHumanViewForm,
    type: INSURANCE_TYPES.COMBINE_HUMAN,
    exportFileName: EXPORT_FILE_NAME.BAO_HIEM_KET_HOP_CON_NGUOI,
    viewColumns: CombineHumanViewColumn()
  },
  {
    name: "Bảo hiểm 24/24",
    columns: Insurance24Column(),
    FormComponent: Insurance24Form,
    ViewFormComponent: InsuranceViewForm,
    type: INSURANCE_TYPES.INSURANCE_24,
    exportFileName: EXPORT_FILE_NAME.BAO_HIEM_24,
    viewColumns: Insurance24ViewColumn()
  }
];

export const INSURANCE_STATUS_CODE = {
  UNFULFILLED: 1,
  PROCESSING: 2,
  COMPLETED: 3
};

export const INSURANCE_STATUS_LIST: IStatus[] = [
  {
    name: "Chưa áp dụng",
    code: INSURANCE_STATUS_CODE.UNFULFILLED,
    styleClass: "bg-gray-700"
  },
  {
    name: "Còn hiệu lực",
    code: INSURANCE_STATUS_CODE.PROCESSING,
    styleClass: "bg-orange-dark"
  },
  {
    name: "Hết hiệu lực",
    code: INSURANCE_STATUS_CODE.COMPLETED,
    styleClass: "bg-green"
  }
];

export const INSURANCE_OBJECTS_CODE = {
  PERSONAL: 1,
  GROUP: 2
};

export const INSURANCE_OBJECTS: OptionReactSelect[] = [
  {
    code: INSURANCE_OBJECTS_CODE.PERSONAL,
    name: "Cá nhân"
  },
  {
    code: INSURANCE_OBJECTS_CODE.GROUP,
    name: "Tập thể"
  }
];

export const INIT_INSURANCE: IInsurance = {
  id: "",
  trangThai: INSURANCE_STATUS_LIST[0],
  doiTuong: INSURANCE_OBJECTS[0],
  goiMua: "Bảo hiểm 24/24",
  fileId: "",
  fileName: "",
  baoHiemId: "",
  dieuKienA: "",
  dieuKienB: "",
  dieuKienC: "",
  hieuLucDenNgay: "",
  hieuLucTuNgay: "",
  mucMua: 0,
  ngayMua: "",
  note: "",
  soTienCongTyHoTro: 0,
  soTienNhanVienDong: 0,
  thamGiaList: [],
  type: null,
  phiMua: 0,
  employeeSoTienDong: null,
  employeeSoTienCongTyHoTro: null,
  lanMua: null
};

export const ListSearch = (lang: ILang) => {
  return [
    {
      name: lang("PROBLEM.RELATED_DEPARTMENTS"),
      field: "phongBanId",
      type: TYPE.SELECT,
      searchFunction: searchPhongBan,
      searchObject: {
        ...SEARCH_OBJECT_MAX_SIZE
      }
    },
    {
      name: lang("GENERAL.STATUS"),
      field: "trangThai",
      type: TYPE.SELECT,
      listOption: INSURANCE_STATUS_LIST
    },
    {
      name: lang("INSURANCE.DATE_EFFECT"),
      field: "hieuLucTuNgay",
      type: TYPE.DATE
    },
    {
      name: lang("INSURANCE.BUY_TIMES"),
      field: "lanMua",
      type: TYPE.TEXT
    },
    {
      name: lang("GENERAL.FULLNAME"),
      field: "tenNhanVien",
      type: TYPE.TEXT
    },
    {
      name: lang("INSURANCE.BUY_LEVEL"),
      field: "mucMua",
      type: TYPE.TEXT
    }
  ];
};
