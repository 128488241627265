import { useFormik } from "formik";
import { FC, useContext, useState } from "react";
import { Button, Col, Modal, Row, Form } from "react-bootstrap";
import { toast } from "react-toastify";
import * as Yup from "yup";
import Autocomplete from "../../../component/input-field/Autocomplete";
import TextValidator from "../../../component/input-field/TextValidator";
import { SUCCESS_CODE } from "../../../contract/const/ContractConst";
import { INIT_DEDUCT } from "../../const/DialogConstant";
import { IDeduct } from "../../models/ProfileModels";
import { addKhauTru, updateKhauTru } from "../../services/DialogServices";
import { deductOpt } from "./../../../contract/const/AllowanceConst";
import AppContext from "../../../../AppContext";
import { useProfileContext } from "../../ProfileContext";
import { convertNumberPrice, convertTextPrice } from "../../../utils/FunctionUtils";
import useMultiLanguage from "../../../../hook/useMultiLanguage";
import { GroupButton } from "../../../component/GroupButton";
import { TYPE, VARIABLE_STRING } from "../../../utils/Constant";
import { RangeDatePicker } from "../../../component/input-field/RangeDatePicker";
import { LIST_STATUS_ALLOWANCE } from "../../const/DialogChildConstants";
import { checkInvalidDate } from "../../../utils/ValidationSchema";
import "../../profile.scss"
interface IProps {
  handleCLoseDeductDialog: () => void;
  deductInfo: IDeduct;
  isView: boolean;
  getListDeduct: () => Promise<void>;
  salaryId: string;
}

const DeductDialog: FC<IProps> = (props) => {
  const { handleCLoseDeductDialog, getListDeduct, deductInfo, salaryId, isView } = props;
  const { lang, intl } = useMultiLanguage();

  const { setPageLoading } = useContext(AppContext);
  const { isResign } = useProfileContext();

  const [isEdit, setIsEdit] = useState<boolean>(!isView);

  const validationSchema = Yup.object().shape({
    tenKhoanKhauTru: Yup.object()
      .required(lang("VALIDATION.REQUIRE"))
      .nullable(),
    giaTri: Yup.string()
      .required(lang("VALIDATION.REQUIRE"))
      .nullable(),
    hieuLucTuNgay: Yup.date()
      .concat(checkInvalidDate(intl))
      .required(lang("VALIDATION.REQUIRE"))
      .nullable(),
    hieuLucDenNgay: Yup.date()
      .concat(checkInvalidDate(intl))
      .when("hieuLucTuNgay", {
        is: (hieuLucTuNgay: Date | null) => hieuLucTuNgay,
        then: Yup.date()
          .min(
            Yup.ref("hieuLucTuNgay"),
            lang("VALIDATION.MINDATE") +
            lang("INPUT.EFFECTIVEDATE_START")
          )
          .nullable()
      })
      .nullable(),
    trangThai: Yup.object().required(lang("VALIDATION.REQUIRE")).nullable()
  });

  const handleSubmit = async (values: IDeduct) => {
    try {
      setPageLoading(true);
      values.lichSuLuongId = String(salaryId);
      const res = deductInfo?.id
        ? await updateKhauTru(deductInfo?.id, values)
        : await addKhauTru(values);
      if (res?.data?.code === SUCCESS_CODE) {
        toast.success(
          deductInfo?.id
            ? lang("TOAST.EDIT.SUCCESS")
            : lang("TOAST.CREATE.SUCCESS")
        );
        await getListDeduct();
        handleCLoseDeductDialog();
      } else {
        toast.error(`${res?.data?.message}`);
      }
    } catch (err) {
      toast.error(lang("GENERAL.ERROR"));
    } finally {
      setPageLoading(false);
    }
  };

  const formik = useFormik({
    initialValues: deductInfo?.id ? deductInfo : INIT_DEDUCT,
    onSubmit: handleSubmit,
    validationSchema
  });

  const handleChangeMoney = (event: React.ChangeEvent<any>) => {
    const { name, value } = event.target;
    formik.setFieldValue(name, Number(convertTextPrice(value)));
  };

  const handleChangeSelect = (name: string, value: any) => {
    formik.setFieldValue(name, value);
  };

  return (
    <Modal
      show={true}
      onHide={handleCLoseDeductDialog}
      size="lg"
      className="custom-modal profile modal-size-custom"
      centered
    >
      <Form onSubmit={formik.handleSubmit}>
        <Modal.Header closeButton>
          <Modal.Title>
            {lang(`${deductInfo?.id ? "GENERAL.DEDUCT.UPDATE" : "GENERAL.DEDUCT.ADD"}`)}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row className="gap-col-modal">
            <Col xs={3}>
              <Autocomplete
                lable={lang("ALLOWANCE.NAME")}
                name="tenKhoanKhauTru"
                isRequired
                options={deductOpt}
                value={formik.values?.tenKhoanKhauTru || null}
                isReadOnly={!isEdit}
                onChange={(selectedOption) =>
                  formik.setFieldValue("tenKhoanKhauTru", selectedOption)
                }
                errors={formik.errors?.tenKhoanKhauTru}
                touched={formik.touched?.tenKhoanKhauTru}
              />
            </Col>
            <Col xs={2}>
              <TextValidator
                lable={lang("ALLOWANCE.VALUE")}
                isRequired
                name="giaTri"
                type="text"
                readOnly={!isEdit}
                onChange={handleChangeMoney}
                value={convertNumberPrice(formik.values?.giaTri || null)}
                errors={formik.errors?.giaTri}
                touched={formik.touched?.giaTri}
              />
            </Col>
            <Col xs={4}>
              <RangeDatePicker
                label={lang("INPUT.HEALTHINSURANCE.EFFECTIVEDATE")}
                isRequired
                startDateName="hieuLucTuNgay"
                endDateName="hieuLucDenNgay"
                isView={!isEdit}
                isReadOnlyEndDate={false}
                handleChange={formik.handleChange}
                value={formik.values}
                setFieldValue={formik.setFieldValue}
                touch={formik.touched}
                errors={formik.errors}
              />
            </Col>
            <Col xs={3}>
              <Autocomplete
                lable={lang("GENERAL.STATUS")}
                isReadOnly={!isEdit}
                options={LIST_STATUS_ALLOWANCE}
                value={formik.values?.trangThai || null}
                name="trangThai"
                isRequired
                onChange={(selectedOption) => handleChangeSelect(VARIABLE_STRING.TRANG_THAI, selectedOption)}
                errors={formik.errors?.trangThai}
                touched={formik.touched?.trangThai}
              />
          </Col>
            <Col xs={12}>
              <TextValidator
                className="h-auto-input spaces"
                lable={lang("ALLOWANCE.NOTE")}
                name="ghiChu"
                value={formik.values.ghiChu}
                type="text"
                readOnly={!isEdit}
                rows={2}
                onChange={formik.handleChange}
                as={TYPE.TEXTAREA}
              />
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-center">
          <GroupButton 
            type="btn-cancel"
            handleCancel={handleCLoseDeductDialog}
          />
          {!isResign &&
            (isEdit ? (
              <Button className="button-primary btn-sm" type="submit">
                {lang("BTN.SAVE")}
              </Button>
            ) : (
              <Button
                variant="primary"
                className="button-primary btn-sm"
                onClick={(e: any) => {
                  e.preventDefault();
                  setIsEdit(true);
                }}
              >
                {lang("BTN.EDIT")}
              </Button>
            ))}
        </Modal.Footer>
      </Form>
    </Modal>
  );
};
export { DeductDialog };

