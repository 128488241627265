import { Col, Row } from "react-bootstrap";
import useMultiLanguage from "../../hook/useMultiLanguage";
import { useContext, useEffect, useState } from "react";
import ReportDetail from "./ReportDetail";
import { searchAllSimpleCategory, searchAllSimpleValue } from "../services";
import { RESPONSE_STATUS_CODE, SEARCH_OBJECT_MAX_SIZE } from "../utils/Constant";
import { INIT_SEARCH_OBJECT, TYPE_CATEGORY } from "../constant";
import AppContext from "../../AppContext";
import { toast } from "react-toastify";
import InputSearch from "../component/input-field/InputSearch";
import TableCustom, { columnNamesType } from "../component/table-custom/TableCustom";
import ListMenuVertical from "../component/ListMenuVertical/ListMenuVertical";
import { itemListAdditional } from "./consts/reportConsts";
import { IReportCategory, IReportInfo } from "./models/reportModels";
import "./reportStyle.scss";

function Report() {
  const { lang } = useMultiLanguage();
  const { setPageLoading } = useContext(AppContext);

  const [listData, setListData] = useState<IReportInfo[]>([]);
  const [totalElements, setTotalElements] = useState<number>(0);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [numberOfElements, setNumberOfElements] = useState<number>(0);
  const [shouldOpenDetail, setShouldOpenDetail] = useState<boolean>(false);
  const [reportInfo, setReportInfo] = useState<IReportInfo>({} as IReportInfo);
  const [reportCategory, setReportCategory] = useState<IReportCategory[]>([]);
  const [listItemIndex, setListItemIndex] = useState<number>(0);
  const [searchObject, setSearchObject] = useState<any>({ ...INIT_SEARCH_OBJECT, type: TYPE_CATEGORY.phanLoaiBaoCao })

  useEffect(() => {
    getAllReportCategory();
  }, []);

  useEffect(() => {
    handleSearch();
  }, [searchObject?.pageIndex, searchObject?.pageSize])

  const getAllReportCategory = async () => {
    try {
      setPageLoading(true);
      let searchData = {
        type: TYPE_CATEGORY.phanLoaiBaoCao,
        ...SEARCH_OBJECT_MAX_SIZE
      };
      const { data } = await searchAllSimpleCategory(searchData);
      if (data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
        let dataSorted = data?.data?.content
          ? [itemListAdditional, ...data?.data?.content.sort((a: any, b: any) => a.code - b.code)]
          : [];
        setReportCategory(dataSorted);
      }
      setPageLoading(false);
    } catch (error) {
      toast.error(lang("GENERAL.ERROR"));
      setPageLoading(false);
    }
  };

  const updatePageData = async (searchObject?: any) => {
    try {
      setPageLoading(true);
      setSearchObject({ ...searchObject });
      const { data } = await searchAllSimpleValue(searchObject);
      if (data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
        setListData(data?.data?.content || []);
        setTotalPages(data?.data?.totalPages || 0);
        setTotalElements(data?.data?.totalElements || 0);
        setNumberOfElements(data?.data?.numberOfElements || 0);
      }
      setPageLoading(false);
    } catch (error) {
      toast.error(lang("GENERAL.ERROR"));
      setPageLoading(false);
    }
  };

  const handleChangeList = (index: number, listItemInfo: any) => {
    setListItemIndex(index);
    updatePageData({ ...searchObject, attributeId: listItemInfo.id });
  };

  const handleOpenViewDialog = (row: any) => {
    setShouldOpenDetail(true);
    setReportInfo(row);
  };

  const handleSearch = async (data: any = {}) => {
    const dataSearch: any = {
      ...searchObject,
      ...data
    }

    dataSearch.keyword = dataSearch?.keyword?.trim() || ""
    updatePageData(dataSearch)
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchObject({
      ...searchObject,
      keyword: e.target?.value
    })
  }

  const columns: columnNamesType[] = [
    {
      name: lang("REPORT.NAME"),
      field: "value",
      cellStyle: {
        textAlign: "left"
      },
      render: (row) => (
        <p className="spaces p-0 m-0 flex flex-middle">
          <span>
            <i className="bi bi-heart spaces fs-20 flex px-10"></i>
          </span>
          <span>{row.value}</span>
        </p>
      )
    },
    {
      name: lang("REPORT.TYPE"),
      field: "attributeName"
    }
  ];

  return (
    <div className="report spaces px-8 py-12">
      {!shouldOpenDetail ? (
        <>
          <Row className="spaces mb-12 flex-middle h-35">
            <Col xs={5} xl={6} xxl={7} className="spaces p-0">
              <h2 className="breadcrumb-title">{lang("REPORT.TITLE")}</h2>
            </Col>
          </Row>
          <Row className="bg-white">
            <Col xs={2}>
              <ListMenuVertical
                activeIndex={listItemIndex}
                handleChangeActiveItem={handleChangeList}
                listData={reportCategory}
                fieldTitle="name"
              />
            </Col>
            <Col xs={10}>
              <div className="flex flex-end flex-middle spaces pt-12">
                <div className="w-1/2">
                  <span className="text-header">{reportCategory[listItemIndex]?.name}</span>
                </div>
                <div className="flex w-1/2">
                  <div className="spaces mr-10 flex-1">
                    <InputSearch
                      className="border-3px"
                      value={searchObject?.keyword || ""}
                      placeholder={lang("GENERAL.ENTER_HERE")}
                      handleSearch={() => handleSearch({ pageIndex: 1 })}
                      handleChange={handleChange}
                      isEnter
                    />
                  </div>
                  <button
                    className="spaces button-primary mr-10"
                    onClick={() => handleSearch({ pageIndex: 1 })}
                  >
                    {lang("GENERAL.SEARCH")}
                  </button>
                </div>
              </div>
              <div className="spaces flex-1 mb-6 table-custom">
                <TableCustom
                  data={listData}
                  columns={columns}
                  searchObject={searchObject}
                  setSearchObject={setSearchObject}
                  noToolbar
                  handleDoubleClick={handleOpenViewDialog}
                  totalPages={totalPages}
                  totalElements={totalElements}
                  numberOfElements={numberOfElements}
                />
              </div>
            </Col>
          </Row>
        </>
      ) : (
        <ReportDetail
          reportInfo={reportInfo}
          handleBack={() => setShouldOpenDetail(false)}
        />
      )}
    </div>
  );
}

export default Report;
