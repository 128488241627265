import { TYPE_CATEGORY } from "../../constant";
import { OptionReactSelect } from "../../models/models";
import { searchPhongBan } from "../../profile/services/DialogServices";
import { searchAllSimpleValue } from "../../services";
import { SEARCH_OBJECT_MAX_SIZE, TYPE } from "../../utils/Constant";
import { IItemSearch, ReceivableInfo, ResignInfo, ResignTab } from "../models/IResign";

export const listStatusResign = [
    {
        code: 1,
        styleClass: "bg-gray-700",
        name: "Chưa thực hiện"
    },
    {
        code: 3,
        styleClass: "bg-green",
        name: "Đã thực hiện"
    }
]

export const INIT_RESIGN_INFO: ResignInfo = {
    employee: null,
    employeeCode: "",
    employeeId: "",
    phongBan: null,
    phongBanId: "",
    phongBanText: "",
    chucVuId: "",
    chucVuText: "",
    chucDanhId: "", 
    chucDanhText: "",
    doiTuongKhenThuong: null,
    doiTuongKhenThuongId: "",
    doiTuongKhenThuongText: "",
    createDateTime: null,
    createdBy: "",
    loaiThoiViec: null,
    loaiThoiViecId: "",
    loaiThoiViecText: "",
    trangThaiBaoHiem: null,
    trangThaiBaoHiemId: "",
    trangThaiBaoHiemText: "",
    troCapThoiViec: null,
    troCapMatViec: null,
    cacKhoanTraKhac: null,
    cacKhoanThuKhac: null,
    thangKhauTruOption: null,
    namKhauTruOption: null,
    coPhieuXacNhan: false,
    ngayQuyetDinh: "",
    ngayHieuLuc: "",
    soQuyetDinh: "",
    nguoiKy: null,
    nguoiKyId: "",
    nguoiKyText: "",
    lyDoThoiViec: "",
    daBoSungHoSo: false,
    daHoanTatThuTuc: false,
    fileId: "",
    fileName: "",
    trangThai: null,
    id: "",
    modifiedBy: "",
    modifyDate: null,
    ghiChu: "",
    thoiGianDungDongBH: "",
    hinhThucDieuChinh: null,
    thoiGianThucHien: "",
    raQuyetDinh: false
}

export enum REF_TAB_RESIGN {
    THOI_VIEC = "#kt_tab_pane_1",
    GIAN_DOAN = "#kt_tab_pane_2"
}
  
export const resignTabs: ResignTab[] = [
    {
        id: 1,
        name: "Thôi việc",
        ref: REF_TAB_RESIGN.THOI_VIEC
    },
    {
        id: 2,
        name: "Gián đoạn",
        ref: REF_TAB_RESIGN.GIAN_DOAN
    }
];

export const INIT_INPUT_SEARCH: IItemSearch = {
    name: "",
    type: TYPE.TEXT,
    field: "",
    value: null
};

export const INIT_RECEIVABLE_INFO: ReceivableInfo = {
    khoanThu: "",
    giaTri: null, 
    ghiChu: ""
}

export const RESIGN_TYPE = {
    KET_THUC_HOP_DONG: 2
}

export const listResignType : OptionReactSelect[] = [
    {
        code: 1,
        name: "Tinh giản biên chế"
    },
    {
        code: RESIGN_TYPE.KET_THUC_HOP_DONG,
        name: "Nghỉ kết thúc hợp đồng"
    },
    {
        code: 3,
        name: "Nghỉ hưu"
    },
    {
        code: 4,
        name: "Kỷ luật buộc thôi việc"
    },
    {
        code: 5,
        name: "Chết"
    },
    {
        code: 6,
        name: "Nghỉ theo nguyện vọng"
    },
    {
        code: 7,
        name: "Luân chuyển/Điều động"
    },
    {
        code: 8,
        name: "Lý do khác"
    },
]

export const listResignTypeInsurance : OptionReactSelect[] = [
    {
        code: 1,
        name: "Không thay đổi bảo hiểm"
    },
    {
        code: 2,
        name: "Dừng đóng bảo hiểm"
    },
    {
        code: 3,
        name: "Báo giảm bảo hiểm"
    },
]

export const listFormOfAdjustment : OptionReactSelect[] = [
    {
        code: 1,
        name: "Giảm"
    }
]

export const RESIGN = {
    STATUS: {
        UNFINISH: 1,
        FINISH: 3
    }
}

export enum INSURANCE_TYPE_CODE {
    KHONG_THAY_DOI = 1,
    DUNG_DONG = 2,
    BAO_GIAM = 3
}


export const FIELD_SEARCH_RESIGN = [
    {
        name: "Mã nhân viên",
        field: "maNhanVien",
        type: TYPE.TEXT
    },
    {
        name: "Họ và tên",
        field: "fullName",
        type: TYPE.TEXT
    },
    {
        name: "Phòng/Ban",
        field: "phongBanIds",
        searchFunction: searchPhongBan,
        searchObject: { ...SEARCH_OBJECT_MAX_SIZE },
        type: TYPE.SELECT,
        isMulti: true,
    },
    {
        name: "Chức vụ",
        field: "chucVuId",
        searchFunction: searchAllSimpleValue,
        searchObject: { ...SEARCH_OBJECT_MAX_SIZE, type: TYPE_CATEGORY.chucDanh },
        type: TYPE.SELECT,
        optionLabel: "value"
    },
    {
        name: "Vị trí công việc",
        field: "viTriCongViecIds    ",
        searchFunction: searchAllSimpleValue,
        searchObject: { ...SEARCH_OBJECT_MAX_SIZE, type: TYPE_CATEGORY.viTriCongViec },
        type: TYPE.SELECT,
        optionLabel: "value",
        isMulti: true,
    },
    {
        name: "Loại thôi việc",
        field: "loaiThoiViec",
        type: TYPE.SELECT,
        listOption: listResignType
    },
    {
        name: "Ngày thôi việc",
        field: "ngayHieuLuc",
        type: TYPE.DATE
    },
    {
        name: "Số quyết định",
        field: "soQuyetDinh",
        type: TYPE.TEXT
    },
    {
        name: "Trạng thái",
        field: "trangThai",
        type: TYPE.SELECT,
        listOption: listStatusResign,
        isMulti: true,
    }
];