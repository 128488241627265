import { DisciplineInfo } from "../model/DisciplineModel";

export const convertDataSubmit = (data: DisciplineInfo, employeeList: any) => {
  const dataConvert: DisciplineInfo = {
    ...data,
    nguoiKyQuyetDinhId: data?.nguoiKyQuyetDinh?.id || "",
    nguoiKyQuyetDinhText: data?.nguoiKyQuyetDinh?.name || "",
    coQuanQuyetDinhId: data?.coQuanQuyetDinh?.id || "",
    coQuanQuyetDinhText: data?.coQuanQuyetDinh?.name || "",
    employeeKyLuats: [{...employeeList[0], employeeId: employeeList[0]?.employeeId || employeeList[0]?.id}]
  };

  return dataConvert;
};

export const convertDataUI = (data: DisciplineInfo) => {
  const dataConvert = {
    ...data,
    nguoiKyQuyetDinh: data?.nguoiKyQuyetDinhId
      ? {
          id: data?.nguoiKyQuyetDinhId || "",
          name: data?.nguoiKyQuyetDinhText || ""
        }
      : null,
    coQuanQuyetDinh: data?.coQuanQuyetDinhId
      ? {
          id: data?.coQuanQuyetDinhId || "",
          name: data?.coQuanQuyetDinhText || ""
        }
      : null
  };

  return dataConvert;
};
