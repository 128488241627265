import { Col, Row } from "react-bootstrap";
import Autocomplete from "../../../component/input-field/Autocomplete";
import TextValidator from "../../../component/input-field/TextValidator";
import useMultiLanguage from "../../../../hook/useMultiLanguage";
import { IInsurance, IPropsViewForm } from "../../models/insuranceModel";
import UploadFile from "../../../component/FileUpload/UploadFile";
import { FILE_TYPE } from "../../../component/FileUpload/constant";
import { TYPE } from "../../../utils/Constant";
import { INSURANCE_OBJECTS, INSURANCE_STATUS_LIST } from "../../constant/isuranceConst";
import { RangeDatePicker } from "../../../component/input-field/RangeDatePicker";
import { convertNumberPrice } from "../../../utils/FunctionUtils";

function CombineHumanViewForm(props: IPropsViewForm<IInsurance>) {
  const { data } = props;
  const { lang } = useMultiLanguage();

  return (
    <Row className="gap-col-modal">
      <Col xs={4}>
        <Autocomplete
          lable={lang("INSURANCE.OBJECT")}
          options={INSURANCE_OBJECTS}
          isReadOnly={true}
          value={data?.doiTuong}
        />
      </Col>
      <Col xs={4}>
        <TextValidator
          lable={lang("INSURANCE.BUY_DATE")}
          type="date"
          value={data?.ngayMua}
          readOnly={true}
        />
      </Col>
      <Col xs={4}>
        <TextValidator
          lable={lang("INSURANCE.CONĐITION_A")}
          type="text"
          value={data?.dieuKienA}
          readOnly={true}
        />
      </Col>
      <Col xs={4}>
        <TextValidator
          lable={lang("INSURANCE.CONĐITION_B")}
          type="text"
          value={data?.dieuKienB}
          readOnly={true}
        />
      </Col>
      <Col xs={4}>
        <TextValidator
          lable={lang("INSURANCE.CONĐITION_C")}
          type="text"
          value={data?.dieuKienC}
          readOnly={true}
        />
      </Col>
      <Col xs={4}>
        <TextValidator
          lable={lang("INSURANCE.BUY_LEVEL")}
          type="text"
          value={convertNumberPrice(data?.mucMua)}
          readOnly={true}
        />
      </Col>
      <Col xs={4}>
        <TextValidator
          lable={lang("INSURANCE.COMPANY_SUPPORT")}
          type="text"
          value={convertNumberPrice(data?.employeeSoTienCongTyHoTro)}
          readOnly={true}
        />
      </Col>
      <Col xs={4}>
        <TextValidator
          lable={lang("INSURANCE.EMPLOYEE_PAY")}
          type="text"
          value={convertNumberPrice(data?.employeeSoTienDong)}
          readOnly={true}
        />
      </Col>
      <Col xs={4}>
        <TextValidator
          lable={lang("INSURANCE.BUY_TIMES")}
          type="text"
          value={data?.lanMua}
          readOnly={true}
        />
      </Col>
      <Col xs={4}>
        <RangeDatePicker
          label={lang("INSURANCE.DATE_EFFECT")}
          startDateName="hieuLucTuNgay"
          endDateName="hieuLucDenNgay"
          isView={true}
          value={data}
        />
      </Col>
      <Col xs={4}>
        <Autocomplete
          lable={lang("GENERAL.STATUS")}
          options={INSURANCE_STATUS_LIST}
          isReadOnly={true}
          value={data?.trangThai}
        />
      </Col>
      <Col xs={4}>
        <UploadFile
          label="INPUT_APPOINTMENT_ATTACHMENTS"
          setValue={() => {}}
          fileValue={{
            id: data?.fileId,
            name: data?.fileName
          }}
          allowFileTypes={`${FILE_TYPE.PDF},${FILE_TYPE.DOC},${FILE_TYPE.DOCX},${FILE_TYPE.XLS},${FILE_TYPE.XLSX}`}
          isReadOnly={true}
        />
      </Col>
      <Col xs={12}>
        <TextValidator
          lable={lang("GENERAL.NOTE")}
          type="text"
          value={data?.note}
          readOnly={true}
          as={TYPE.TEXTAREA}
          rows={3}
        />
      </Col>
    </Row>
  );
}

export default CombineHumanViewForm;
