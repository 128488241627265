import { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { useFormik } from "formik";
import { Col, Form, Row } from "react-bootstrap";
import useMultiLanguage from "../../../hook/useMultiLanguage";
import Autocomplete from "../../component/input-field/Autocomplete";
import TextValidator from "../../component/input-field/TextValidator";
import { FILE_TYPE } from "../../component/FileUpload/constant";
import {
  AFFECT_SALARY,
  DISCIPLINE_AFFECT_SALARY_STATUS_CODE,
  DISCIPLINE_STATUS_CODE,
  INIT_DISCIPLINE_INFO,
  decisionLevels,
  disciplineFormOfs,
  disciplineStatus,
  disciplineType
} from "../consts/DisciplineConsts";
import { GroupButton } from "../../component/GroupButton";
import { RangeDatePicker } from "../../component/input-field/RangeDatePicker";
import { addNewDiscipline, getKyLuatEmployeeList, updateDiscipline } from "../services/DisciplineService";
import { RESPONSE_STATUS_CODE, SEARCH_OBJECT_MAX_SIZE, VARIABLE_STRING } from "../../utils/Constant";
import { searchNguoiDaiDienKy } from "../../contract/services/contractServices";
import { convertDataSubmit, convertDataUI } from "../utils/util";
import { getAllDonVi, getEmployeeProfileById } from "../../profile/services/ProfileServices";
import { IFile } from "../../profile/models/DialogModels";
import UploadFile from "../../component/FileUpload/UploadFile";
import AppContext from "../../../AppContext";
import SelectEmployeeDialog from "../../component/SelectEmployeeDialog/SelectEmployeeDialog";
import TableCustom, { columnNamesType } from "../../component/table-custom/TableCustom";
import { addMoreMonth, hasRole, justAllowNumber } from "../../utils/FunctionUtils";
import { ROLE } from "../../../Constant";
import { localStorageItem } from "../../utils/LocalStorage";
import { KEY_LOCALSTORAGE } from "../../auth/core/_consts";

interface IProps {
  handleClose: () => void;
  handleCloseAndSearch: () => void;
  isView?: boolean;
  disciplineEdit: any;
  setIsView: (data: any) => void;
}

function DisciplineDialog(props: IProps) {
  const { lang } = useMultiLanguage();
  const { handleClose, handleCloseAndSearch, isView = false, disciplineEdit, setIsView } = props;

  const [shouldOpenAddEmployeeDialog, setShouldOpenAddEmployeeDialog] = useState<boolean>(false);
  const [employeeSelected, setEmployeeSelected] = useState<any[]>([]);
  const [isViewOnly, setIsViewOnly] = useState<boolean>(false);
  const { setPageLoading } = useContext(AppContext);

  useEffect(() => {
    if (disciplineEdit?.id) {
      formik.setValues(convertDataUI(disciplineEdit));
      setIsViewOnly(disciplineEdit?.trangThai?.code === DISCIPLINE_STATUS_CODE.COMPLETED);
      getEmployeeSelected();
    } else {
      formik.setFieldValue(VARIABLE_STRING.TRANG_THAI, disciplineStatus[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [disciplineEdit]);

  const getEmployeeSelected = async () => {
    try {
      setPageLoading(true);
      const { data } = await getKyLuatEmployeeList(String(disciplineEdit?.id));
      if (data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
        setEmployeeSelected(data?.data[0]?.listEmployee || []);
      }
      setPageLoading(false);
    } catch (error) {
      toast.error(lang("GENERAL.ERROR"));
      setPageLoading(false);
    }
  };

  const handleOpenSelectEmployeeDialog = () => {
    setShouldOpenAddEmployeeDialog(true);
  };

  const validationSchema = Yup.object().shape({
    loaiKyLuat: Yup.object().required(lang("VALIDATION.REQUIRE")).nullable(),
    capQuyetDinh: Yup.object().required(lang("VALIDATION.REQUIRE")).nullable(),
    trangThai: Yup.object().required(lang("VALIDATION.REQUIRE")).nullable(),
    hanhVi: Yup.string().required(lang("VALIDATION.REQUIRE")).nullable(),
    hinhThucKyLuat: Yup.object().required(lang("VALIDATION.REQUIRE")).nullable(),
    coQuanQuyetDinh: Yup.object().required(lang("VALIDATION.REQUIRE")).nullable(),
    soQuyetDinh: Yup.string().required(lang("VALIDATION.REQUIRE")).nullable(),
    tuNgay: Yup.date().required(lang("VALIDATION.REQUIRE")).nullable(),
    denNgay: Yup.date()
      .min(Yup.ref("tuNgay"), lang("VALIDATION.NOT_SMALLER_FROM_DAY"))
      .nullable(),
    thoiGianKeoDaiDieuChinhLuong: Yup.number()
      .when("ahtgDieuChinhLuong", {
        is: (ahtgDieuChinhLuong: boolean) => ahtgDieuChinhLuong,
        then: Yup.number().required(lang("VALIDATION.REQUIRE")).nullable(),
      })
      .nullable(),
  });

  const handleSubmit = async (values: any) => {
    if (!(employeeSelected?.length > 0)) return toast.warning(lang("TOAST.SELECT_LIST_DISCIPLINE"));
    try {
      setPageLoading(true);
      const dataSubmit = convertDataSubmit(values, employeeSelected);
      const { data } = disciplineEdit?.id
        ? await updateDiscipline(disciplineEdit.id, dataSubmit)
        : await addNewDiscipline(dataSubmit);
      if (data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
        let message = disciplineEdit?.id ? "TOAST.EDIT.SUCCESS" : "TOAST.CREATE.SUCCESS";
        toast.success(lang(message));
        handleCloseAndSearch();
      } else {
        toast.error(`${data?.message}`);
      }
    } catch (err) {
      toast.error(lang("GENERAL.ERROR"));
    } finally {
      setPageLoading(false);
    }
  };

  const formik = useFormik({
    initialValues: INIT_DISCIPLINE_INFO,
    onSubmit: handleSubmit,
    validationSchema
  });

  const handleChangeSelect = (name: string, value: any) => {
    switch (name) {
      case VARIABLE_STRING.HINH_THUC_KY_LUAT:
        const affectSalary = DISCIPLINE_AFFECT_SALARY_STATUS_CODE.find(status => status.code === value?.code);
        formik.setValues({
          ...formik.values,
          [name]: value,
          ahtgDieuChinhLuong: Boolean(affectSalary),
          thoiGianKeoDaiDieuChinhLuong: affectSalary ? affectSalary.value : null
        });
        break;
      default:
        formik.setFieldValue(name, value);
    }
  };

  const handleChangeCheckBox = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;
    if (name === VARIABLE_STRING.ANH_HUONG_THOI_GIAN_DIEU_CHINH_LUONG) {
      formik.setValues({
        ...formik.values,
        [name]: checked,
        thoiGianKeoDaiDieuChinhLuong: null
      });
    } else {
      formik.setFieldValue(name, checked);
    }
  };

  const columns: columnNamesType[] = [
    {
      name: lang("TABLE.INDEX"),
      field: "index",
      headerStyle: {
        minWidth: 50
      },
      cellStyle: {
        textAlign: "center"
      },
      render: (row: any, index: number) => <span>{index + 1}</span>
    },
    {
      name: lang("INPUT.CODE"),
      field: "maNhanVien",
      headerStyle: {
        minWidth: 80
      },
      cellStyle: {
        textAlign: "center"
      },
      render: (row: any) => <span className="color-steel-blue fw-600">{row?.maNhanVien}</span>
    },
    {
      name: lang("INPUT.FULLNAME"),
      field: "name",
      headerStyle: {
        minWidth: 200
      }
    },
    {
      name: lang("PROFILE.ROLE"),
      field: "chucVuText",
      headerStyle: {
        minWidth: 250
      }
    },
    {
      name: lang("GENERAL.EMPLOYEE.JOB_POSITION"),
      field: "viTriCongViecText",
      headerStyle: {
        minWidth: 150
      }
    }
  ];

  const handleSetFile = (data: IFile) => {
    formik.setFieldValue("fileId", data.id);
    formik.setFieldValue("fileName", data.name);
  };

  const handleSaveEmployeeSingle = (employee: any) => {
    setEmployeeSelected([{ ...employee[0], isChecked: false }]);
  };

  const handleChangePositiveNumber = (event: React.ChangeEvent<any>) => {
    if (!event.target?.value?.startsWith("0")) {
      formik.handleChange(event);
    }
  };

  useEffect(() => {
    const tuNgay = formik.values.tuNgay;
    const thoiGianKeoDai = formik.values.thoiGianKeoDaiDieuChinhLuong;
    if (tuNgay && thoiGianKeoDai) {
      formik.setFieldValue("denNgay", addMoreMonth(tuNgay, Number(thoiGianKeoDai)));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    formik.values.tuNgay,
    formik.values.hinhThucKyLuat,
    formik.values.thoiGianKeoDaiDieuChinhLuong
  ]);

  const getEmployeeInfoByRoleUser = async () => {
    try {
      const accountEmployeeId = localStorageItem.get(KEY_LOCALSTORAGE.ACCOUNT_EMPLOYEE_ID);
      const { data } = await getEmployeeProfileById(accountEmployeeId);
      if(data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
        formik.setFieldValue(VARIABLE_STRING.CO_QUAN_QUYET_DINH, {
          id: data?.data?.donViCongTacId || "",
          name: data?.data?.donViCongTacText || "",
        });
      }
    } catch (error) {
      toast.error(lang("GENERAL.ERROR"));
    }
  }

  useEffect(() => {
    if(hasRole(ROLE.USER) && !disciplineEdit?.id) {
      getEmployeeInfoByRoleUser();
    }
  }, [])

  return (
    <div className="discipline-dialog spaces overflow-y-auto overflow-x-hidden bg-transparent">
      <Form
        onSubmit={formik.handleSubmit}
        className="flex-column min-h-full"
      >
        <div className="spaces flex flex-middle flex-space-between py-12 color-primary text-uppercase">
          <GroupButton
            type="btn-back"
            handleClose={handleClose}
          />
          {/* {!isViewOnly && ( */}
          <>
            {isView ? (
              <GroupButton
                type="btn-edit"
                handleSaveEdit={() => setIsView(false)}
              />
            ) : (
              <button
                className="button-primary btn-sm mr-16"
                type="submit"
              >
                {lang("BTN.SAVE")}
              </button>
            )}
          </>
          {/* )} */}
        </div>
        <div className="flex-column flex-1 bg-white spaces p-18">
          <Row className="gap-col-modal z-index-2 relative">
            <Col xs={12} className="sub-title-form">{disciplineEdit?.id ? lang("DISCIPLINE.TITLE.EDIT") : lang("DISCIPLINE.TITLE.ADD")}</Col>
            <Col xs={6} md={4} lg={3} xxl={2}>
              <Autocomplete
                isRequired
                name="loaiKyLuat"
                lable={lang("DISCIPLINE.TYPE")}
                isReadOnly={isView}
                value={formik.values?.loaiKyLuat || null}
                onChange={(value) => handleChangeSelect(VARIABLE_STRING.LOAI_KY_LUAT, value)}
                options={disciplineType}
                errors={formik.errors?.loaiKyLuat}
                touched={formik.touched?.loaiKyLuat}
              />
            </Col>
            <Col xs={6} md={4} lg={3} xxl={2}>
              <Autocomplete
                isRequired
                name="capQuyetDinh"
                lable={lang("DISCIPLINE.DECISION_LEVEL")}
                isReadOnly={isView}
                value={formik.values?.capQuyetDinh || null}
                onChange={(value) => handleChangeSelect(VARIABLE_STRING.CAP_QUYET_DINH, value)}
                options={decisionLevels}
                errors={formik.errors?.capQuyetDinh}
                touched={formik.touched?.capQuyetDinh}
              />
            </Col>
            <Col xs={6} md={4} lg={3} xxl={2}>
              <TextValidator
                isRequired
                name="hanhVi"
                lable={lang("DISCIPLINE.VIOLATION_BEHAVIOR")}
                readOnly={isView}
                type="text"
                value={formik.values?.hanhVi || ""}
                onChange={formik.handleChange}
                errors={formik.errors?.hanhVi}
                touched={formik.touched?.hanhVi}
              />
            </Col>
            <Col xs={6} md={4} lg={3} xxl={2}>
              <Autocomplete
                isRequired
                name="hinhThucKyLuat"
                lable={lang("DISCIPLINE.FORM_OF")}
                isReadOnly={isView}
                value={formik.values?.hinhThucKyLuat || null}
                onChange={(value) => handleChangeSelect(VARIABLE_STRING.HINH_THUC_KY_LUAT, value)}
                options={disciplineFormOfs}
                errors={formik.errors?.hinhThucKyLuat}
                touched={formik.touched?.hinhThucKyLuat}
              />
            </Col>
            <Col xs={6} md={4} lg={3} xxl={2}>
              <TextValidator
                isRequired
                name="soQuyetDinh"
                lable={lang("INPUT.WORKPROCESS.DECISION.NUMBER")}
                readOnly={isView}
                type="text"
                value={formik.values?.soQuyetDinh || ""}
                onChange={formik.handleChange}
                errors={formik.errors?.soQuyetDinh}
                touched={formik.touched?.soQuyetDinh}
              />
            </Col>
            <Col xs={6} md={4} lg={3} xxl={2}>
              <Autocomplete
                isRequired
                name="coQuanQuyetDinh"
                lable={lang("DISCIPLINE.ISSUING_AGENCY_UNIT")}
                isReadOnly={isView || hasRole(ROLE.USER)}
                value={formik.values?.coQuanQuyetDinh || null}
                onChange={(value) => handleChangeSelect(VARIABLE_STRING.CO_QUAN_QUYET_DINH, value)}
                options={[]}
                searchFunction={getAllDonVi}
                searchObject={SEARCH_OBJECT_MAX_SIZE}
                errors={formik.errors?.coQuanQuyetDinh}
                touched={formik.touched?.coQuanQuyetDinh}
              />
            </Col>
            <Col xs={6} md={4} lg={3} xxl={2}>
              <Autocomplete
                isRequired
                name="trangThai"
                lable={lang("GENERAL.STATUS")}
                isReadOnly={isViewOnly ? isViewOnly : isView}
                value={formik.values?.trangThai || null}
                onChange={(value) => handleChangeSelect(VARIABLE_STRING.TRANG_THAI, value)}
                options={disciplineStatus}
                errors={formik.errors?.trangThai}
                touched={formik.touched?.trangThai}
              />
            </Col>
            <Col xs={6} md={4} lg={3} xxl={2}>
              <Autocomplete
                name="nguoiKyQuyetDinh"
                lable={lang("APPRECIATIVE.PERSON_SINGING")}
                isReadOnly={isView}
                value={formik.values?.nguoiKyQuyetDinh || null}
                onChange={(value) => handleChangeSelect(VARIABLE_STRING.NGUOI_KY_QUYET_DINH, value)}
                options={[]}
                searchFunction={searchNguoiDaiDienKy}
                searchObject={{}}
              />
            </Col>
            <Col xs={6} md={4} lg={3} xxl={4} className="range-date-gap-sm">
              <RangeDatePicker
                isRequired
                label={lang("GENERAL.TIME")}
                startDateName="tuNgay"
                endDateName="denNgay"
                isView={isView}
                isReadOnlyEndDate={formik.values?.ahtgDieuChinhLuong}
                handleChange={formik.handleChange}
                value={formik.values}
                setFieldValue={formik.setFieldValue}
                touch={formik.touched}
                errors={formik.errors}
              />
            </Col>
            <Col xs={6} md={4} lg={3} xxl={2}>
              <UploadFile
                label="CONTRACT.ATTACHMENTS"
                setValue={handleSetFile}
                fileValue={{
                  id: formik.values.fileId || "",
                  name: formik.values.fileName || ""
                }}
                allowFileTypes={`${FILE_TYPE.PDF},${FILE_TYPE.DOC},${FILE_TYPE.DOCX},${FILE_TYPE.XLS},${FILE_TYPE.XLSX}`}
                isReadOnly={isView}
              />
            </Col>
            <Col xs={6} md={4} lg={2} xxl={2}>
              <Form.Check
                label={lang("DISCIPLINE.MOST")}
                type="checkbox"
                className="checkBox custom-form-check"
                name="kyLuatCaoNhat"
                checked={formik.values?.kyLuatCaoNhat}
                onChange={handleChangeCheckBox}
                disabled={isView}
              />
            </Col>
            <Col xs={6} md={4} lg={4} xxl={3}>
              <Form.Check
                label={lang("APPRECIATIVE.SALARY_AUJUSTMENT_TIME")}
                type="checkbox"
                className="checkBox custom-form-check"
                name="ahtgDieuChinhLuong"
                checked={formik.values?.ahtgDieuChinhLuong}
                onChange={handleChangeCheckBox}
                disabled={isView || formik.values?.hinhThucKyLuat?.code !== AFFECT_SALARY.KHAC}
              />
            </Col>
            <Col xs={6} md={4} lg={4} xxl={3}>
              <TextValidator
                isRequired={formik.values?.ahtgDieuChinhLuong}
                name="thoiGianKeoDaiDieuChinhLuong"
                lable={lang("APPRECIATIVE.SALARY_AUJUSTMENT_EXTENDED_TIME")}
                readOnly={
                  isView
                  || formik.values?.hinhThucKyLuat?.code !== AFFECT_SALARY.KHAC
                  || !formik.values?.ahtgDieuChinhLuong
                }
                type="number"
                value={formik.values?.thoiGianKeoDaiDieuChinhLuong || ""}
                onChange={handleChangePositiveNumber}
                onKeyDown={justAllowNumber}
                errors={formik.errors?.thoiGianKeoDaiDieuChinhLuong}
                touched={formik.touched?.thoiGianKeoDaiDieuChinhLuong}
              />
            </Col>
            <Col xs={6} md={formik.values?.ahtgDieuChinhLuong ? 8 : 12} lg={8} xxl={6}>
              <TextValidator
                name="ghiChu"
                lable={lang("INPUT.WORKPROCESS.NOTE")}
                readOnly={isView}
                value={formik.values?.ghiChu || ""}
                onChange={formik.handleChange}
                as="textarea"
              />
            </Col>
          </Row>
          <Row className="flex-1 flex-column flex-between">
            <Col
              xs={12}
              className="spaces mt-24"
            >
              <TableCustom
                title={lang("DISCIPLINE.EMPLOYEE")}
                columns={columns}
                data={employeeSelected}
                handleOpenDialog={handleOpenSelectEmployeeDialog}
                buttonAdd={!isView}
                notDelete
                noPagination
                noToolbar
                isActionTableTab
                uniquePrefix="employeeId"
              />
            </Col>

            {shouldOpenAddEmployeeDialog && (
              <SelectEmployeeDialog
                open={shouldOpenAddEmployeeDialog}
                handleClose={() => setShouldOpenAddEmployeeDialog(false)}
                handleSaveData={handleSaveEmployeeSingle}
                dataParent={employeeSelected}
              />
            )}
          </Row>
        </div>
      </Form>
    </div>
  );
}

export default DisciplineDialog;
