import { FC, useContext, useEffect, useState } from "react";
import { AddNewContract } from "./components/AddNewContract";
import "./contract.scss";
import { IContractInfoDto, IContractInfo } from "./services/models/IContract";
import { getListContracts } from "./services/contractServices";
import { toast } from "react-toastify";
import { checkStatus, convertSearch, exportToFile, formatDateTable, hasRole, searchDataRoleUser } from "../utils/FunctionUtils";
import { exportHopDong } from "../utils/ExportFileServices";
import TableCustom from "../component/table-custom/TableCustom";
import { RESPONSE_STATUS_CODE, SEARCH_OBJECT_MAX_SIZE, TYPE } from "../utils/Constant";
import { INIT_CONTACT, ListSearch, contractStatus } from "./const/ContractConst";
import { convertDataUI } from "./utils/FunctionUtils";
import { Col, Row } from "react-bootstrap";
import InputSearch from "../component/input-field/InputSearch";
import AdvancedSearch from "../profile/component/Dialog/AdvancedSearch";
import { INIT_INPUT_SEARCH } from "../profile/const/ProfileConst";
import { IItemSearch } from "../profile/models/ProfileModels";
import { KTSVG } from "../../../_metronic/helpers";
import SelectTree from "../component/SelectTree";
import { INIT_SEARCH_OBJECT, TYPE_CATEGORY } from "../constant";
import { getAllDonVi } from "../profile/services/ProfileServices";
import AppContext from "../../AppContext";
import { EXPORT_FILE_NAME, ROLE } from "../../Constant";
import { getPhongBanByDonVi } from "../profile/utils/FunctionUtils";
import useMultiLanguage from "../../hook/useMultiLanguage";

const Contract: FC = () => {
  const { lang } = useMultiLanguage();
  document.title = `${lang("GENERAL.APP.NAME")} | ${lang("GENERAL.CONTRACT")}`;
  const [shouldOpenAddNewContract, setShouldOpenAddNewContract] = useState<boolean>(false);
  const [viewInfoContract, setViewInfoContract] = useState<boolean>(false);
  const [contractInfo, setContractInfo] = useState<IContractInfo>(INIT_CONTACT);
  const [contracts, setContracts] = useState<IContractInfoDto[]>([]);
  const [totalPage, setTotalPage] = useState<number>(0);
  const [totalElements, setTotalElements] = useState<number>(0);
  const [numberOfElements, setNumberOfElements] = useState<number>(0);

  const [isAdvancedSearch, setIsAdvancedSearch] = useState<boolean>(false);
  const [searchObject, setSearchObject] = useState<any>(INIT_SEARCH_OBJECT);
  const [listInputSearch, setListInputSearch] = useState<any[]>([INIT_INPUT_SEARCH]);
  const [codeCollapses, setCodeCollapses] = useState<string | null>(null);
  const [idSelected, setIdSelected] = useState<string>("");
  const [listTreeSelect, setListTreeSelect] = useState<any>([])
  const { setPageLoading } = useContext(AppContext);

  useEffect(() => {
    if(searchObject?.pageIndex || searchObject?.pageSize){
      handleSearch();
    }
  }, [searchObject?.pageIndex, searchObject?.pageSize])

  const updatePageData = async (searchData?: any) => {
    try {
      setPageLoading(true);
      setSearchObject({ ...searchData });
      const { data } = await getListContracts(hasRole(ROLE.USER) ? searchDataRoleUser(searchData) : {...searchData});
      if (data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
        setContracts(data?.data?.content || []);
        setTotalPage(data?.data?.totalPages);
        setTotalElements(data?.data?.totalElements);
        setNumberOfElements(data?.data?.numberOfElements);
        setIsAdvancedSearch(false);
      }
    } catch (error) {
      toast.error(lang("GENERAL.ERROR"));
    } finally {
      setPageLoading(false);
    }
  };

  const handleCloseDialog = () => {
    setShouldOpenAddNewContract(false);
    setViewInfoContract(false);
    setContractInfo(INIT_CONTACT);
    updatePageData(searchObject);
  };

  const handleOpenInfoDialog = (row: any) => {
    setViewInfoContract(true);
    setShouldOpenAddNewContract(true);
    setContractInfo(convertDataUI(row));
  };

  const handleOpenAddNewContract = () => {
    setShouldOpenAddNewContract(true);
  };

  const handleOpenUpdateDialog = () => {
    setViewInfoContract(false);
  };

  const handleCloseUpdateDialog = () => {
    setViewInfoContract(true);
  };

  const handleSearch = async (data: any = {}) => {
    let donViKyHopDongId = null;
    if(listTreeSelect.length === 0) {
      const treeSelect = await getDonVi();
      if(treeSelect.length > 0) {
        donViKyHopDongId = treeSelect[0]?.id;
        setIdSelected(donViKyHopDongId);
      }
    }
    const dataSearch: any = {
      ...searchObject,
      donViKyHopDongId,
      ...data
    }
    dataSearch.keyword = dataSearch?.keyword?.trim() || ""
    updatePageData(dataSearch)
  }

  const handleOpenAdvancedSearch = () => {
    setIsAdvancedSearch(true);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchObject({
      ...searchObject,
      keyword: e.target?.value
    });
  };

  const handleAdvancedSearch = (data: IItemSearch[]) => {
    setListInputSearch(data);
    handleSearch({ ...convertSearch(data), pageIndex: 1 });
  };

  const handelTreeSelect = async (item: any) => {
    if (item?.type === TYPE_CATEGORY.donVi) {
      try {
        if (codeCollapses !== item.code) {
          setPageLoading(true);
          const treeSelect = await getPhongBanByDonVi(listTreeSelect, item);
          setListTreeSelect(treeSelect);
          handleSearch({
            pageIndex: 1,
            phongBanIds: null,
            donViKyHopDongId: item?.id
          })
        }
      } catch (error) {
      } finally {
        setPageLoading(false);
      }
      return;
    }

    if (item?.type === TYPE_CATEGORY.phongBan) {
      handleSearch({
        pageIndex: 1,
        donViKyHopDongId: null,
        phongBanIds: item?.id
      })
      return;
    }

    handleSearch({
      donViKyHopDongId: null,
      phongBanIds: null,
    })
  }

  const getDonVi = async () => {
    let treeSelect: any = []
    try {
      let dataDonVi = (await getAllDonVi(SEARCH_OBJECT_MAX_SIZE))?.data?.data?.content
  
      dataDonVi?.forEach((donVi: any) => {
        let itemTreeSelect: any = {
          id: donVi?.id,
          code: donVi?.code,
          name: donVi?.name,
          type: TYPE_CATEGORY.donVi
        }
        treeSelect.push(itemTreeSelect)
      });
      setListTreeSelect(treeSelect)
    } catch (error) {
    }
    return treeSelect;
  };

  const columns = [
    {
      name: "STT",
      field: "",
      render: (row: any, index: number, stt: number) => <span>{stt}</span>
    },
    {
      name: lang("CONTRACT.STATUS"),
      field: "trangThaiHopDong",
      headerStyle: {
        minWidth: "120px"
      },
      cellStyle: {
        textAlign: "center" 
      },
      render: (row: any) => <span className={`${checkStatus(contractStatus, row?.trangThaiHopDong?.code)} status`}>{row?.trangThaiHopDong?.name}</span>
    },
    {
      name: lang("INPUT.CODE_NEW"),
      field: "employeeCode",
      headerStyle: {
        minWidth: "150px"
      },
      cellStyle: {
        textAlign: "left"
      },
      render: (row: any) => <span className="color-steel-blue fw-600">{row?.employeeCode}</span>
    },
    {
      name: lang("CONTRACT.WORKERFULLNAME"),
      field: "tenNguoiLaoDong",
      headerStyle: {
        minWidth: "150px"
      },
      cellStyle: {
        minWidth: "150px",
        textAlign: "left"
      }
    },
    {
      name: lang("CONTRACT.NUMBER"),
      field: "soHopDong",
      headerStyle: {
        minWidth: "180px"
      },
      cellStyle: {
        textAlign: "left"
      }
    },
    {
      name: lang("PROFILE.ROLE"),
      field: "chucVuText",
      headerStyle: {
        minWidth: "200px"
      },
      cellStyle: {
        minWidth: "200px",
        textAlign: "left"
      }
    },
    {
      name: lang("GENERAL.EMPLOYEE.JOB_POSITION"),
      field: "viTriCongViecText",
      headerStyle: {
        minWidth: "270px"
      },
      cellStyle: {
        minWidth: "270px",
        textAlign: "left"
      }
    },
    {
      name: lang("INPUT.DEPARTMENTS"),
      field: "phongBanText",
      headerStyle: {
        minWidth: "200px"
      },
      cellStyle: {
        minWidth: "200px",
        textAlign: "left"
      }
    },
    {
      name: lang("CONTRACT.TYPE"),
      field: "loaiHopDong",
      headerStyle: {
        minWidth: "150px"
      },
      cellStyle: {
        minWidth: "150px",
        textAlign: "left"
      },
      render: (row: any) => <span>{row?.loaiHopDong?.name || ""}</span>
    },
    {
      name: lang("CONTRACT.SIGNINGSTATUS"),
      field: "trangThaiKy",
      headerStyle: {
        minWidth: "120px"
      },
      render: (row: any) => <span>{row?.trangThaiKy?.name || ""}</span>
    },
    {
      name: lang("CONTRACT.SIGNINGDATE"),
      field: "ngayKyHopDong",
      headerStyle: {
        minWidth: "100px"
      },
      render: (row: any) => <span>{formatDateTable(row?.ngayKyHopDong)}</span>
    }
  ];

  return (
    <div className="contract container-page">
      {!shouldOpenAddNewContract && (
        <>
          <Row className="spaces mb-12 flex-middle">
            <Col xs={5} xxl={7} className="spaces p-0">
              <h2 className="breadcrumb-title">{lang("CONTRACT.ALL")}</h2>
            </Col>
            <Col xs={7} xxl={5} className="flex">
              <div className="spaces mr-10 flex-1">
                <InputSearch
                  className="border-3px"
                  value={searchObject?.keyword || ""}
                  placeholder="Tìm kiếm theo mã NV, họ và tên, số HĐ và phòng ban"
                  handleSearch={() => handleSearch({ pageIndex: 1})}
                  handleChange={handleChange}
                  isEnter
                />
              </div>
              <button
                className="spaces button-primary mr-10"
                onClick={() => handleSearch({ pageIndex: 1})}
              >
                Tìm kiếm
              </button>
              <button
                className="spaces button-primary-outline mr-10"
                onClick={handleOpenAdvancedSearch}
              >
                Tìm nâng cao
              </button>

              {isAdvancedSearch && (
                <AdvancedSearch
                  open={isAdvancedSearch}
                  listInputSearch={listInputSearch}
                  handleSearch={handleAdvancedSearch}
                  listOption={ListSearch()}
                  handeleClose={() => setIsAdvancedSearch(false)}
                />
              )}
            </Col>
          </Row>

          <Row className="bg-white">
            {!hasRole(ROLE.USER) && (
              <Col
                xs={2}
                className="box-shadow-menu spaces p-0"
              >
                <div className="spaces p-10">
                  <div className="spaces py-10 flex ">
                    <KTSVG
                      path={"/media/svg/icons/sidebar-reverse.svg"}
                      className="svg-icon-sidebar"
                    />
                    <h2 className="spaces fw-600 pl-8 m-0 ">{lang("GENERAL.UNIT_DEPARTMENT")}</h2>
                  </div>
                  <InputSearch
                    className="spaces h-30 p-8"
                    value=""
                    placeholder={lang("GENERAL.UNIT_DEPARTMENT")}
                    handleChange={() => {}}
                    handleSearch={() => {}}
                  />
                </div>
                <div className="spaces pl-4 select-tree">
                  <SelectTree
                    codeCollapses={codeCollapses}
                    handleChangeCollapsesCode={setCodeCollapses}
                    idSelected={idSelected}
                    handleChangeSelectId={setIdSelected}
                    selectTree={listTreeSelect}
                    handleRow={handelTreeSelect}
                  />
                </div>
              </Col>
            )}
            <Col
              xs={hasRole(ROLE.USER) ? 12 : 10}
              className="spaces pl-0 table-custom"
            > 
              <TableCustom
                id="contracts"
                data={contracts}
                columns={columns}
                searchObject={searchObject}
                setSearchObject={setSearchObject}
                buttonAdd={true}
                buttonExportExcel={!hasRole(ROLE.USER)}
                notDelete={true}
                handleExportExcel={(ids: string[]) => 
                  exportToFile({
                    exportAPI: () => exportHopDong({ ids, ...searchObject }), 
                    fileName: EXPORT_FILE_NAME.HOP_DONG, 
                    setPageLoading
                  })
                }
                type={TYPE.MULTILINE}
                fixedColumnsCount={4}
                totalPages={totalPage}
                totalElements={totalElements}
                numberOfElements={numberOfElements}
                handleOpenDialog={handleOpenAddNewContract}
                handleDoubleClick={handleOpenInfoDialog}
              />
            </Col>
          </Row>
        </>
      )}
      {shouldOpenAddNewContract && (
        <AddNewContract
          view={viewInfoContract}
          contractInfo={contractInfo}
          handleClose={handleCloseDialog}
          handleOpenUpdateDialog={handleOpenUpdateDialog}
          handleCloseUpdateDialog={handleCloseUpdateDialog}
        />
      )}
    </div>
  );
};
export { Contract };
