import { Col, Row } from "react-bootstrap";
import useMultiLanguage from "../../hook/useMultiLanguage";
import { useContext, useState, useEffect } from "react";
import { RESPONSE_STATUS_CODE, TYPE } from "../utils/Constant";
import AppContext from "../../AppContext";
import { toast } from "react-toastify";
import InputSearch from "../component/input-field/InputSearch";
import TableCustom from "../component/table-custom/TableCustom";
import ListMenuVertical from "../component/ListMenuVertical/ListMenuVertical";
import AdvancedSearch from "../profile/component/Dialog/AdvancedSearch";
import { INIT_INPUT_SEARCH } from "../profile/const/ProfileConst";
import { IItemSearch } from "../profile/models/ProfileModels";
import { convertSearch, exportToFile, hasAuthority } from "../utils/FunctionUtils";
import { PERMISSIONS, PERMISSION_ABILITY } from "../../Constant";
import { IInsuranceDetail } from "./models/insuranceModel";
import { INSURANCE_LIST, INSURANCE_STATUS_CODE, ListSearch } from "./constant/isuranceConst";
import { IObject } from "../models/models";
import { deleteInsurance, searchInsurance } from "./services/insuranceServices";
import { exportThongTinBaoHiem } from "../utils/ExportFileServices";
import { INIT_SEARCH_OBJECT } from "../constant";

function Insurance() {
  const { lang } = useMultiLanguage();
  const { setPageLoading } = useContext(AppContext);

  const [isView, setIsView] = useState<boolean>(false);
  const [totalElements, setTotalElements] = useState<number>(0);
  const [totalPage, setTotalPage] = useState<number>(0);
  const [numberOfElements, setNumberOfElements] = useState<number>(0);
  const [shouldOpenForm, setShoulOpenForm] = useState<boolean>(false);
  const [activeMenuIndex, setActiveMenuIndex] = useState<number>(0);
  const [searchObject, setSearchObject] = useState<IObject>(INIT_SEARCH_OBJECT);
  const [isAdvancedSearch, setIsAdvancedSearch] = useState<boolean>(false);
  const [listInputSearch, setListInputSearch] = useState<IItemSearch[]>([INIT_INPUT_SEARCH]);
  const [listData, setListData] = useState<any[]>([]);
  const [rowInfo, setRowInfo] = useState<IObject>({});
  const [insuranceDetailInfo, setInsuranceDetailInfo] = useState<IInsuranceDetail>(INSURANCE_LIST[0]);

  const { FormComponent } = insuranceDetailInfo;

  useEffect(() => {
    if(searchObject?.pageIndex || searchObject?.pageSize){
      handleSearch();
    }
  }, [searchObject?.pageIndex, searchObject?.pageSize])

  useEffect(() => {
    setSearchObject(INIT_SEARCH_OBJECT);
    handleSearch(INIT_SEARCH_OBJECT);
  }, [insuranceDetailInfo.type])

  const updatePageData = async (searchData?: IObject) => {
    try {
      setPageLoading(true);
      setSearchObject({ ...searchData });
      const { data } = await searchInsurance({ ...searchData, type: insuranceDetailInfo.type });
      if (data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
        setListData(data?.data?.content || []);
        setTotalPage(data?.data?.totalPages || 0);
        setTotalElements(data?.data?.totalElements || 0);
        setNumberOfElements(data?.data?.numberOfElements || 0);
        setIsAdvancedSearch(false);
      }
    } catch (error) {
      toast.error(lang("GENERAL.ERROR"));
    } finally {
      setPageLoading(false);
    }
  };

  const handleChangeActiveMenu = (index: number, dataActive: IInsuranceDetail) => {
    setActiveMenuIndex(index);
    setInsuranceDetailInfo(dataActive);
  };

  const handleSearch = (data?: IObject) => {
    const dataSearch = {
      ...searchObject,
      ...data
    };
    dataSearch.keyword = dataSearch?.keyword?.trim() || null;
    updatePageData(dataSearch);
  };

  const handleAdvancedSearch = (data: IItemSearch[]) => {
    setListInputSearch(data);
    handleSearch({ ...convertSearch(data), pageIndex: 1 });
  };

  const handleOpenAdvancedSearch = () => {
    setIsAdvancedSearch(true);
  };

  const handleChangeKeyword = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchObject({
      ...searchObject,
      keyword: event.target?.value
    });
  };

  const handleCloseDialog = () => {
    setShoulOpenForm(false);
    setRowInfo({});
    setIsView(false);
  };

  const handleCloseAndSearch = () => {
    handleSearch();
    handleCloseDialog();
  }

  const handleOpenUpdateDialog = () => {
    setIsView(false);
  };

  const handleCloseUpdateDialog = () => {
    setIsView(true);
  };

  const handleOpenDialog = () => {
    setShoulOpenForm(true);
  };

  const handleOpenInfoDialog = (row: any) => {
    setIsView(true);
    setShoulOpenForm(true);
    setRowInfo(row);
  };

  const handleDelete = async (ids: string[]) => {
    try {
      const { data } = await deleteInsurance(ids);
      if (data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
        toast.success(lang("TOAST.DELETE.SUCCESS"));
        updatePageData(searchObject);
        return true;
      }
    } catch (error) {
      toast.error(lang("GENERAL.ERROR"));
    }
  };

  const handleExportExcel = (ids: string[]) => {
    exportToFile({
      exportAPI: () => exportThongTinBaoHiem({ ids, ...searchObject, type: insuranceDetailInfo.type }),
      fileName: insuranceDetailInfo.exportFileName,
      setPageLoading
    });
  };

  return (
    <div className="report spaces px-8 py-12">
      {!shouldOpenForm ? (
        <>
          <Row className="spaces mb-12 flex-middle">
            <Col xs={5} xxl={7} className="spaces p-0">
              <h2 className="breadcrumb-title">{lang("INSURANCE.LIST")}</h2>
            </Col>
            <Col xs={7} xxl={5} className="flex">
              <div className="spaces mr-10 flex-1">
                <InputSearch
                  className="border-3px"
                  value={searchObject?.keyword}
                  placeholder={lang("INSURANCE.PLACEHOLDER")}
                  handleSearch={() => handleSearch({ pageIndex: 1 })}
                  handleChange={handleChangeKeyword}
                  isEnter
                />
              </div>
              <button className="spaces button-primary mr-10" onClick={() => handleSearch({ pageIndex: 1 })}>
                {lang("GENERAL.SEARCH")}
              </button>
              <button className="spaces button-primary-outline mr-10" onClick={handleOpenAdvancedSearch}>
                {lang("BTN.ADVANCE_SEARCH")}
              </button>

              {isAdvancedSearch && (
                <AdvancedSearch
                  open={isAdvancedSearch}
                  listInputSearch={listInputSearch}
                  handleSearch={handleAdvancedSearch}
                  listOption={ListSearch(lang)}
                  handeleClose={() => setIsAdvancedSearch(false)}
                />
              )}
            </Col>
          </Row>
          <Row className="bg-white">
            <Col xs={2}>
              <ListMenuVertical
                activeIndex={activeMenuIndex}
                handleChangeActiveItem={handleChangeActiveMenu}
                listData={INSURANCE_LIST}
                fieldTitle="name"
              />
            </Col>
            <Col xs={10} className="table-custom">
              <div className="spaces pt-16">
                <h3 className="spaces fs-18 text-header-table fw-600">{insuranceDetailInfo.name}</h3>
              </div>
              <TableCustom
                id="insurance-list"
                data={listData}
                columns={insuranceDetailInfo.columns}
                searchObject={searchObject}
                setSearchObject={setSearchObject}
                buttonAdd={hasAuthority(PERMISSIONS.SU_CO, PERMISSION_ABILITY.CREATE)}
                buttonExportExcel={hasAuthority(PERMISSIONS.SU_CO, PERMISSION_ABILITY.VIEW)}
                notDelete={!hasAuthority(PERMISSIONS.SU_CO, PERMISSION_ABILITY.DELETE)}
                handleExportExcel={handleExportExcel}
                type={TYPE.MULTILINE}
                fixedColumnsCount={3}
                totalPages={totalPage}
                totalElements={totalElements}
                numberOfElements={numberOfElements}
                handleOpenDialog={handleOpenDialog}
                handleDoubleClick={handleOpenInfoDialog}
                handleDelete={handleDelete}
                deleteConditional={[
                  {
                    keyPath: "trangThai.code",
                    value: INSURANCE_STATUS_CODE.UNFULFILLED
                  }
                ]}
                uniquePrefix="baoHiemId"
              />
            </Col>
          </Row>
        </>
      ) : (
        <FormComponent
          dataEdit={rowInfo}
          isView={isView}
          handleCloseDialog={handleCloseDialog}
          handleCloseAndSearch={handleCloseAndSearch}
          handleCloseUpdateDialog={handleCloseUpdateDialog}
          handleOpenUpdateDialog={handleOpenUpdateDialog}
        />
      )}
    </div>
  );
}

export default Insurance;
