import { useEffect, useState } from "react";
import { REF_TAB } from "../const/ProfileConst";
import BonusTable from "./Partials/Bonus/BonusTable";
import DisciplineTable from "./Partials/Discipline/DisciplineTable";

interface Iprops {
  employeeProfiles: any;
  activeTab: string;
}

function BonusAndDisciplineInfomation(props: Iprops) {
  const { employeeProfiles, activeTab } = props;
  const [isCallApi, setIsCallApi] = useState(false);

  useEffect(() => {
    setIsCallApi(employeeProfiles?.id && activeTab === REF_TAB.KHEN_THUONG_KY_LUAT);
  }, [employeeProfiles, activeTab]);

  return (
    <div className="form-info">
      <div className="block-content">
        <BonusTable
          isCallApi={isCallApi}
          employeeProfiles={employeeProfiles}
        />
        <DisciplineTable
          isCallApi={isCallApi}
          employeeProfiles={employeeProfiles}
        />
      </div>
    </div>
  );
}

export default BonusAndDisciplineInfomation;
