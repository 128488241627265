import { Col, Row } from "react-bootstrap";
import Autocomplete from "../../component/input-field/Autocomplete";
import useMultiLanguage from "../../../hook/useMultiLanguage";
import { INSURANCE_LIST } from "../../insurance/constant/isuranceConst";
import { useContext, useEffect, useState } from "react";
import { IInsuranceDetail } from "../../insurance/models/insuranceModel";
import TableCustom from "../../component/table-custom/TableCustom";
import { toast } from "react-toastify";
import { RESPONSE_STATUS_CODE } from "../../utils/Constant";
import { IObject } from "../../models/models";
import { REF_TAB } from "../const/ProfileConst";
import InsuranceViewDialog from "../../insurance/components/viewDialog/InsuranceViewDialog";
import { getInsuranceProfile } from "../../insurance/services/insuranceServices";
import AppContext from "../../../AppContext";

interface Iprops {
  employeeProfiles: any;
  activeTab: string;
}

function ListInsuranreInfomation(props: Iprops) {
  const { employeeProfiles, activeTab } = props;
  const { lang } = useMultiLanguage();

  const [insuranceInfo, setInsuranceInfo] = useState<IInsuranceDetail>(INSURANCE_LIST[0]);

  const [totalElements, setTotalElements] = useState<number>(0);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [numberOfElements, setNumberOfElements] = useState<number>(0);
  const [dataList, setdataList] = useState<any[]>([]);
  const [rowInfo, setRowInfo] = useState<IObject>({});
  const [shouldOpenViewDialog, setShouldOpenViewDialog] = useState<boolean>(false);
  const [isCallApi, setIsCallApi] = useState(false);
  const { setPageLoading } = useContext(AppContext);

  useEffect(() => {
    setIsCallApi(employeeProfiles?.id && activeTab === REF_TAB.LIST_BAO_HIEM);
  }, [employeeProfiles, activeTab]);

  useEffect(() => {
    updatePageData();
  }, [isCallApi, insuranceInfo.type]);

  const updatePageData = async () => {
    if (!isCallApi) return;
    try {
      setPageLoading(true);
      const { data } = await getInsuranceProfile(employeeProfiles?.id, insuranceInfo.type);
      if (data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
        setdataList(data?.data || []);
        setTotalPages(data?.data?.totalPages || 0);
        setTotalElements(data?.data?.totalElements || 0);
        setNumberOfElements(data?.data?.numberOfElements || 0);
      }
    } catch (error) {
      toast.error(lang("GENERAL.ERROR"));
    } finally {
      setPageLoading(false);
    }
  };

  const handleExportExcel = () => {};

  const handleOpenDialog = (row: any) => {
    setShouldOpenViewDialog(true);
    setRowInfo(row);
  };

  const handleCloseDialog = () => {
    setShouldOpenViewDialog(false);
  };

  return (
    <div className="form-info insurance-info">
      <div className="block-content">
        <Row className="z-index-2 relative">
          <Col xs={8}>
            <Autocomplete
              lable={lang("INSURANCE.TYPES")}
              options={INSURANCE_LIST}
              name="insuranceInfo"
              value={insuranceInfo}
              onChange={(value) => setInsuranceInfo(value)}
              horizontal
            />
          </Col>
        </Row>
        <div>
          <TableCustom
            title={insuranceInfo.name}
            isActionTableTab
            data={dataList}
            columns={insuranceInfo.viewColumns}
            handleOpenDialog={handleOpenDialog}
            handleExportExcel={handleExportExcel}
            fixedColumnsCount={4}
            handleDoubleClick={handleOpenDialog}
            totalPages={totalPages}
            totalElements={totalElements}
            numberOfElements={numberOfElements}
            notDelete
            noPagination
          />
        </div>
        {shouldOpenViewDialog && (
          <InsuranceViewDialog
            dataView={rowInfo}
            handleClose={handleCloseDialog}
            insuranceInfo={insuranceInfo}
            open={shouldOpenViewDialog}
          />
        )}
      </div>
    </div>
  );
}

export default ListInsuranreInfomation;
