import { COMPLETED_STATUS, SEARCH_OBJECT_MAX_SIZE } from './../../utils/Constant';
import { TYPE } from "../../utils/Constant";
import { TYPE_CATEGORY } from '../../constant';
import { getListWorkUnit } from '../../utils/CategoryServices';
import { getAllSimpleValue } from '../../services';
import { IDismissed } from '../model/IDismissed';
import { searchPhongBan } from '../../profile/services/DialogServices';
import { getAllDonVi } from '../../profile/services/ProfileServices';
import { ILang } from '../../../hook/useMultiLanguage';

export const INIT_DISMISSED: IDismissed = {
  id: "",
  fullName: "",
  maNhanVien: "",
  employeeId: "",
  employee: null,
  donViCongTacId: "",
  donViCongTacText: "",
  chucVuId: "",
  chucVuText: "",
  phongBanId: "",
  phongBanText: "",
  thoiGianHieuLucTuNgay: "",
  thoiGianHieuLucDenNgay: "",
  fileId: "",
  fileName: "",
  ghiChu: "",
  donViCongTacMoiId: "",
  donViCongTacMoiText: "",
  donViCongTacMoi: null,
  phongBanMoiId: "",
  phongBanMoiText: "",
  phongBanMoi: null,
  chucVuMoiId: "",
  chucVuMoiText: "",
  chucVuMoi: null,
  ngayQuyetDinh: "",
  soQuyetDinh: "",
  isConfirmed : false,
  trangThai: null,
  phongBanMoiKhac: "",
  chucVuMoiKhac: "",
  xacNhanMienNhiem: false
}

export const ListSearch = (lang: ILang) => {
  return [
    {
      name: lang("GENERAL.STATUS"),
      field: "trangThai",
      type: TYPE.SELECT,
      listOption: COMPLETED_STATUS,
      isMulti: true,
    },
    {
      name: lang("PROFILE.CODE"),
      field: "maNhanVien",
      type: TYPE.TEXT,
    },
    {
      name: lang("PROFILE.NAME"),
      field: "ten",
      type: TYPE.TEXT,
    },
    {
      name: lang("GENERAL.CURRENT_ORGANIZATION"),
      field: "donViCongTacId",
      searchFunction: getListWorkUnit,
      searchObject: { ...SEARCH_OBJECT_MAX_SIZE },
      type: TYPE.SELECT,
    },
    {
      name: lang("INPUT_DISMISSED_UNIT_DEPARTMENT"),
      field: "phongBanIds",
      searchFunction: searchPhongBan,
      searchObject: { ...SEARCH_OBJECT_MAX_SIZE },
      type: TYPE.SELECT,
      isMulti: true,
    },
    {
      name: lang("INPUT_DISMISSED_JOB_POSITION"),
      field: "chucVuId",
      searchFunction: getAllSimpleValue,
      searchObject: TYPE_CATEGORY.chucDanh,
      type: TYPE.SELECT,
      optionLabel: "value"
    },
    {
      name: lang("INPUT_DISMISSED_WORK_UNIT_NEW"),
      field: "donViCongTacMoiId",
      searchFunction: getAllDonVi,
      searchObject: { ...SEARCH_OBJECT_MAX_SIZE },
      type: TYPE.SELECT,
    },
    {
      name: lang("INPUT_DISMISSED_UNIT_DEPARTMENT_NEW"),
      field: "phongBanMoiIds",
      searchFunction: searchPhongBan,
      searchObject: { ...SEARCH_OBJECT_MAX_SIZE },
      type: TYPE.SELECT,
      isMulti: true,
    },
    {
      name: lang("INPUT_DISMISSED_JOB_POSITION_NEW"),
      field: "chucVuMoiId",
      searchFunction: getAllSimpleValue,
      searchObject: TYPE_CATEGORY.chucDanh,
      type: TYPE.SELECT,
      optionLabel: "value"
    },
    {
      name: lang("INPUT.WORKPROCESS.DECISION.NUMBER"),
      field: "soQuyetDinh",
      type: TYPE.TEXT,
    },
  ]
}
