const CATEGORY_ONE = {
  LABEL:{
    QUA_HAN: "Quá hạn",
    DEN_HAN: "Đến hạn",
    GAN_DEN_HAN: "Gần đến hạn",
    TONG_SO:"Tổng số"
  },
  COLOR_LABEL:{
    QUA_HAN: "color-dark-red",
    DEN_HAN: "color-dark-orange",
    GAN_DEN_HAN: "color-green",
    TONG_SO: ""
  }
}

export const LOAI_CANH_BAO = {
  NHAN_SU_DEN_TUOI_NGHI_HUU: {
    ID: "canhBaoNhanSuDenTuoiNghiHuu",
    PREFIX: "nghiHuuDuKien",
    TITLE: "Cảnh báo nhân sự đến tuổi nghỉ hưu",
    TOTAL_KEY: "nghiHuuDuKienTongSo",
    OPTIONS: {
      tooltip: {
        trigger: 'item',
        formatter: '{b}: {c} ({d}%)'
      },
      color: ["#DC3545", "#FF9C07", "#28A745"],
      series: [
        {
          type: 'pie',
          radius: ['25%', '60%'],
          left: '-15%',
          top: '-15%',
          width: "125%",
          height: "125%",
          labelLine: {
            length: 10
          },
          label: {
            formatter: '{c}',
          },
          data: [
            { value: 7, name: 'Quá hạn' },
            { value: 4, name: 'Đến hạn' },
            { value: 2, name: 'Gần đến hạn' }
          ]
        }
      ]
    },
    TYPE: "donut",
    HEIGHT: 180,
    INFO_TEXT: {
      KEY: ["nghiHuuDuKienQuaHan", "nghiHuuDuKienDenHan", "nghiHuuDuKienGanDenHan", "nghiHuuDuKienTongSo"],
      LABEL: [CATEGORY_ONE.LABEL.QUA_HAN, CATEGORY_ONE.LABEL.DEN_HAN,CATEGORY_ONE.LABEL.GAN_DEN_HAN, CATEGORY_ONE.LABEL.TONG_SO],
      COLOR_LABEL: [CATEGORY_ONE.COLOR_LABEL.QUA_HAN, CATEGORY_ONE.COLOR_LABEL.DEN_HAN,CATEGORY_ONE.COLOR_LABEL.GAN_DEN_HAN, CATEGORY_ONE.COLOR_LABEL.TONG_SO]
    }
  },
  // DU_KIEN_DEN_TUOI_NGHI_HUU: {
  //   ID: "canhBaoDuKienDenTuoiNghiHuu",
  //   PREFIX: "nghiHuuDuKien", 
  //   TITLE: "Cảnh báo dự kiến đến tuổi nghỉ hưu",
  //   TOTAL_KEY: "nghiHuuDuKienTongSo",
  //   OPTIONS: {
  //     tooltip: {
  //       trigger: 'item',
  //       formatter: '{b}: {c} ({d}%)'
  //     },
  //     color: ["#DC3545", "#FF9C07", "#28A745"],
  //     series: [
  //       {
  //         type: 'pie',
  //         radius: ['25%', '60%'],
  //         left: '-15%',
  //         top: '-10%',
  //         width: "125%",
  //         height: "125%",
  //         labelLine: {
  //           length: 20
  //         },
  //         label: {
  //           formatter: '{c}',
  //         },
  //         data: [
  //           { value: 7, name: 'Quá hạn' },
  //           { value: 4, name: 'Đến hạn' },
  //           { value: 2, name: 'Gần đến hạn' }
  //         ]
  //       }
  //     ]
  //   },
  //   TYPE: "donut",
  //   HEIGHT: 180,
  //   INFO_TEXT: {
  //     KEY: ["hdQuaHan", "hdDenHan", "hdChuaDenHan", "hdTongSo"],
  //     LABEL: [CATEGORY_ONE.LABEL.QUA_HAN, CATEGORY_ONE.LABEL.DEN_HAN,CATEGORY_ONE.LABEL.GAN_DEN_HAN, CATEGORY_ONE.LABEL.TONG_SO],
  //     COLOR_LABEL: [CATEGORY_ONE.COLOR_LABEL.QUA_HAN, CATEGORY_ONE.COLOR_LABEL.DEN_HAN,CATEGORY_ONE.COLOR_LABEL.GAN_DEN_HAN, CATEGORY_ONE.COLOR_LABEL.TONG_SO]
  //   }
  // },
  // HET_HAN_GIAN_DOAN: {
  //   ID: "canhBaoHetHanGianDoan",
  //   PREFIX: "hhgd",
  //   TITLE: "Cảnh báo hết hạn giãn đoạn",
  //   TOTAL_KEY: "hhgdTongSo",
  //   OPTIONS: {
  //     tooltip: {
  //       trigger: 'item',
  //       formatter: '{b}: {c} ({d}%)'
  //     },
  //     color: ["#DC3545", "#FF9C07", "#28A745"],
  //     series: [
  //       {
  //         type: 'pie',
  //         radius: ['25%', '60%'],
  //         left: '-15%',
  //         top: '-10%',
  //         width: "125%",
  //         height: "125%",
  //         labelLine: {
  //           length: 20
  //         },
  //         label: {
  //           formatter: '{c}',
  //         },
  //         data: [
  //           { value: 7, name: 'Quá hạn' },
  //           { value: 4, name: 'Đến hạn' },
  //           { value: 2, name: 'Gần đến hạn' }
  //         ]
  //       }
  //     ]
  //   },
  //   TYPE: "donut",
  //   HEIGHT: 180,
  //   INFO_TEXT: {
  //     KEY: ["hdQuaHan", "hdDenHan", "hdChuaDenHan", "hdTongSo"],
  //     LABEL: [CATEGORY_ONE.LABEL.QUA_HAN, CATEGORY_ONE.LABEL.DEN_HAN,CATEGORY_ONE.LABEL.GAN_DEN_HAN, CATEGORY_ONE.LABEL.TONG_SO],
  //     COLOR_LABEL: [CATEGORY_ONE.COLOR_LABEL.QUA_HAN, CATEGORY_ONE.COLOR_LABEL.DEN_HAN,CATEGORY_ONE.COLOR_LABEL.GAN_DEN_HAN, CATEGORY_ONE.COLOR_LABEL.TONG_SO]
  //   }
  // },
  // HO_SO_NHAN_SU_TU_KHAI: {
  //   ID: "hoSoNhanSuTuKhai",
  //   PREFIX: "hsnstk", 
  //   TITLE: "Hồ sơ nhân sự tự khai",
  //   TOTAL_KEY: "hsnstkTongSo",
  //   OPTIONS: {
  //     tooltip: {
  //       trigger: 'item',
  //       formatter: '{b}: {c} ({d}%)'
  //     },
  //     color: ["#DC3545", "#FF9C07", "#28A745"],
  //     series: [
  //       {
  //         type: 'pie',
  //         radius: ['25%', '60%'],
  //         left: '-15%',
  //         top: '-10%',
  //         width: "125%",
  //         height: "125%",
  //         labelLine: {
  //           length: 20
  //         },
  //         label: {
  //           formatter: '{c}',
  //         },
  //         data: [
  //           { value: 7, name: 'Quá hạn' },
  //           { value: 4, name: 'Đến hạn' },
  //           { value: 2, name: 'Gần đến hạn' }
  //         ]
  //       }
  //     ]
  //   },
  //   TYPE: "donut",
  //   HEIGHT: 180,
  //   INFO_TEXT: {
  //     KEY: ["hsnstkChoPheDuyet", "hsnstkTongSo"],
  //     LABEL: ["Chờ phê duyệt", "Tổng số"],
  //     COLOR_LABEL: ["color-dark-red", ""]
  //   }
  // },
  // HET_HAN_HOP_DONG_TAP_SU: {
  //   ID: "canhBaoHetHanHopDongTapSu",
  //   PREFIX: "hdts",
  //   TITLE: "Cảnh báo hết hạn hợp đồng tập sự",
  //   TOTAL_KEY: "hdtsTongSo",
  //   OPTIONS: {
  //     tooltip: {
  //       trigger: 'item',
  //       formatter: '{b}: {c} ({d}%)'
  //     },
  //     color: ["#DC3545", "#FF9C07", "#28A745"],
  //     series: [
  //       {
  //         type: 'pie',
  //         radius: ['25%', '60%'],
  //         left: '-15%',
  //         top: '-10%',
  //         width: "125%",
  //         height: "125%",
  //         labelLine: {
  //           length: 20
  //         },
  //         label: {
  //           formatter: '{c}',
  //         },
  //         data: [
  //           { value: 7, name: 'Quá hạn' },
  //           { value: 4, name: 'Đến hạn' },
  //           { value: 2, name: 'Gần đến hạn' }
  //         ]
  //       }
  //     ]
  //   },
  //   TYPE: "donut",
  //   HEIGHT: 180,
  //   INFO_TEXT: {
  //     KEY: ["hdQuaHan", "hdDenHan", "hdChuaDenHan", "hdTongSo"],
  //     LABEL: [CATEGORY_ONE.LABEL.QUA_HAN, CATEGORY_ONE.LABEL.DEN_HAN,CATEGORY_ONE.LABEL.GAN_DEN_HAN, CATEGORY_ONE.LABEL.TONG_SO],
  //     COLOR_LABEL: [CATEGORY_ONE.COLOR_LABEL.QUA_HAN, CATEGORY_ONE.COLOR_LABEL.DEN_HAN,CATEGORY_ONE.COLOR_LABEL.GAN_DEN_HAN, CATEGORY_ONE.COLOR_LABEL.TONG_SO]
  //   }
  // },
  HET_HAN_HOP_DONG: {
    ID: "canhBaoHetHanHopDong",
    PREFIX: "hd", 
    TITLE: "Cảnh báo hết hạn hợp đồng",
    TOTAL_KEY: "hdTongSo",
    OPTIONS: {
      tooltip: {
        trigger: 'item',
        formatter: '{b}: {c} ({d}%)'
      },
      color: ["#DC3545", "#FF9C07", "#28A745"],
      series: [
        {
          type: 'pie',
          radius: ['25%', '60%'],
          left: '-15%',
          top: '-15%',
          width: "125%",
          height: "125%",
          labelLine: {
            length: 10
          },
          label: {
            formatter: '{c}',
          },
          data: [
            { value: 7, name: 'Quá hạn' },
            { value: 4, name: 'Đến hạn' },
            { value: 2, name: 'Gần đến hạn' }
          ]
        }
      ]
    },
    TYPE: "donut",
    HEIGHT: 180,
    INFO_TEXT: {
      KEY: ["hdQuaHan", "hdDenHan", "hdChuaDenHan", "hdTongSo"],
      LABEL: [CATEGORY_ONE.LABEL.QUA_HAN, CATEGORY_ONE.LABEL.DEN_HAN,CATEGORY_ONE.LABEL.GAN_DEN_HAN, CATEGORY_ONE.LABEL.TONG_SO],
      COLOR_LABEL: [CATEGORY_ONE.COLOR_LABEL.QUA_HAN, CATEGORY_ONE.COLOR_LABEL.DEN_HAN,CATEGORY_ONE.COLOR_LABEL.GAN_DEN_HAN, CATEGORY_ONE.COLOR_LABEL.TONG_SO]
    }
  },
  HET_HAN_LUAN_CHUYEN: {
    ID: "canhBaoHetHanLuanChuyen",
    PREFIX: "luanChuyen",
    TITLE: "Cảnh báo hết hạn luân chuyển",
    TOTAL_KEY: "luanChuyenTongSo",
    OPTIONS: {
      tooltip: {
        trigger: 'item',
        formatter: '{b}: {c} ({d}%)'
      },
      color: ["#DC3545", "#FF9C07", "#28A745"],
      series: [
        {
          type: 'pie',
          radius: ['25%', '60%'],
          left: '-15%',
          top: '-15%',
          width: "125%",
          height: "125%",
          labelLine: {
            length: 20
          },
          label: {
            formatter: '{c}',
          },
          data: [
            { value: 7, name: 'Quá hạn' },
            { value: 4, name: 'Đến hạn' },
            { value: 2, name: 'Gần đến hạn' }
          ]
        }
      ]
    },
    TYPE: "donut",
    HEIGHT: 180,
    INFO_TEXT: {
      KEY: ["luanChuyenQuaHan", "luanChuyenDenHan", "luanChuyenGanDenHan", "luanChuyenTongSo"],
      LABEL: [CATEGORY_ONE.LABEL.QUA_HAN, CATEGORY_ONE.LABEL.DEN_HAN,CATEGORY_ONE.LABEL.GAN_DEN_HAN, CATEGORY_ONE.LABEL.TONG_SO],
      COLOR_LABEL: [CATEGORY_ONE.COLOR_LABEL.QUA_HAN, CATEGORY_ONE.COLOR_LABEL.DEN_HAN,CATEGORY_ONE.COLOR_LABEL.GAN_DEN_HAN, CATEGORY_ONE.COLOR_LABEL.TONG_SO]
    }
  },
  HET_HAN_NANG_LUONG: {
    ID: "canhBaoHetHanNangLuong",
    PREFIX: "nangLuong", 
    TITLE: "Cảnh báo đến hạn nâng lương",
    TOTAL_KEY: "nangLuongTongSo",
    OPTIONS: {
      tooltip: {
        trigger: 'item',
        formatter: '{b}: {c} ({d}%)'
      },
      color: ["#DC3545", "#FF9C07", "#28A745"],
      series: [
        {
          type: 'pie',
          radius: ['25%', '60%'],
          left: '-15%',
          top: '-15%',
          width: "125%",
          height: "125%",
          labelLine: {
            length: 10
          },
          label: {
            formatter: '{c}',
          },
          data: [
            { value: 7, name: 'Quá hạn' },
            { value: 4, name: 'Đến hạn' },
            { value: 2, name: 'Gần đến hạn' }
          ]
        }
      ]
    },
    TYPE: "donut",
    HEIGHT: 180,
    INFO_TEXT: {
      KEY: ["nangLuongQuaHan", "nangLuongDenHan", "nangLuongGanHan", "nangLuongTongSo"],
      LABEL: [CATEGORY_ONE.LABEL.QUA_HAN, CATEGORY_ONE.LABEL.DEN_HAN,CATEGORY_ONE.LABEL.GAN_DEN_HAN, CATEGORY_ONE.LABEL.TONG_SO],
      COLOR_LABEL: [CATEGORY_ONE.COLOR_LABEL.QUA_HAN, CATEGORY_ONE.COLOR_LABEL.DEN_HAN,CATEGORY_ONE.COLOR_LABEL.GAN_DEN_HAN, CATEGORY_ONE.COLOR_LABEL.TONG_SO]
    }
  },
  // NANG_LUONG_TRUOC_HAN: {
  //   ID: "canhBaoNangLuongTruocHan",
  //   PREFIX: "nlth",
  //   TITLE: "Cảnh báo nâng lương trước hạn",
  //   TOTAL_KEY: "nlthTongSo",
  //   OPTIONS: {
  //     tooltip: {
  //       trigger: 'item',
  //       formatter: '{b}: {c} ({d}%)'
  //     },
  //     color: ["#DC3545", "#FF9C07", "#28A745"],
  //     series: [
  //       {
  //         type: 'pie',
  //         radius: ['25%', '60%'],
  //         left: '-15%',
  //         top: '-10%',
  //         width: "125%",
  //         height: "125%",
  //         labelLine: {
  //           length: 20
  //         },
  //         label: {
  //           formatter: '{c}',
  //         },
  //         data: [
  //           { value: 7, name: 'Quá hạn' },
  //           { value: 4, name: 'Đến hạn' },
  //           { value: 2, name: 'Gần đến hạn' }
  //         ]
  //       }
  //     ]
  //   },
  //   TYPE: "donut",
  //   HEIGHT: 180,
  //   INFO_TEXT: {
  //     KEY: ["hdQuaHan", "hdDenHan", "hdChuaDenHan", "hdTongSo"],
  //     LABEL: [CATEGORY_ONE.LABEL.QUA_HAN, CATEGORY_ONE.LABEL.DEN_HAN,CATEGORY_ONE.LABEL.GAN_DEN_HAN, CATEGORY_ONE.LABEL.TONG_SO],
  //     COLOR_LABEL: [CATEGORY_ONE.COLOR_LABEL.QUA_HAN, CATEGORY_ONE.COLOR_LABEL.DEN_HAN,CATEGORY_ONE.COLOR_LABEL.GAN_DEN_HAN, CATEGORY_ONE.COLOR_LABEL.TONG_SO]
  //   }
  // },
  // NHAN_SU_CHUA_XAC_NHAN: {
  //   ID: "canhBaoNhanSuChuaXacNhan",
  //   PREFIX: "nscxn", 
  //   TITLE: "Cảnh báo nhân sự chưa xác nhận",
  //   TOTAL_KEY: "nscxnTongSo",
  //   OPTIONS: {
  //     tooltip: {
  //       trigger: 'item',
  //       formatter: '{b}: {c} ({d}%)'
  //     },
  //     color: ["#DC3545", "#FF9C07", "#28A745"],
  //     series: [
  //       {
  //         type: 'pie',
  //         radius: ['25%', '60%'],
  //         left: '-15%',
  //         top: '-10%',
  //         width: "125%",
  //         height: "125%",
  //         labelLine: {
  //           length: 20
  //         },
  //         label: {
  //           formatter: '{c}',
  //         },
  //         data: [
  //           { value: 7, name: 'Quá hạn' },
  //           { value: 4, name: 'Đến hạn' },
  //           { value: 2, name: 'Gần đến hạn' }
  //         ]
  //       }
  //     ]
  //   },
  //   TYPE: "donut",
  //   HEIGHT: 180,
  //   INFO_TEXT: {
  //     KEY: ["hdQuaHan", "hdDenHan", "hdChuaDenHan", "hdTongSo"],
  //     LABEL: [CATEGORY_ONE.LABEL.QUA_HAN, CATEGORY_ONE.LABEL.DEN_HAN,CATEGORY_ONE.LABEL.GAN_DEN_HAN, CATEGORY_ONE.LABEL.TONG_SO],
  //     COLOR_LABEL: [CATEGORY_ONE.COLOR_LABEL.QUA_HAN, CATEGORY_ONE.COLOR_LABEL.DEN_HAN,CATEGORY_ONE.COLOR_LABEL.GAN_DEN_HAN, CATEGORY_ONE.COLOR_LABEL.TONG_SO]
  //   }
  // },
  // NANG_LUONG_BAO_HIEM: {
  //   ID: "canhBaoNangLuongBaoHiem",
  //   PREFIX: "nlbh",
  //   TITLE: "Cảnh báo nâng lương bảo hiểm",
  //   TOTAL_KEY: "nlbhTongSo",
  //   OPTIONS: {
  //     tooltip: {
  //       trigger: 'item',
  //       formatter: '{b}: {c} ({d}%)'
  //     },
  //     color: ["#DC3545", "#FF9C07", "#28A745"],
  //     series: [
  //       {
  //         type: 'pie',
  //         radius: ['25%', '60%'],
  //         left: '-15%',
  //         top: '-10%',
  //         width: "125%",
  //         height: "125%",
  //         labelLine: {
  //           length: 20
  //         },
  //         label: {
  //           formatter: '{c}',
  //         },
  //         data: [
  //           { value: 7, name: 'Quá hạn' },
  //           { value: 4, name: 'Đến hạn' },
  //           { value: 2, name: 'Gần đến hạn' }
  //         ]
  //       }
  //     ]
  //   },
  //   TYPE: "donut",
  //   HEIGHT: 180,
  //   INFO_TEXT: {
  //     KEY: ["hdQuaHan", "hdDenHan", "hdChuaDenHan", "hdTongSo"],
  //     LABEL: [CATEGORY_ONE.LABEL.QUA_HAN, CATEGORY_ONE.LABEL.DEN_HAN,CATEGORY_ONE.LABEL.GAN_DEN_HAN, CATEGORY_ONE.LABEL.TONG_SO],
  //     COLOR_LABEL: [CATEGORY_ONE.COLOR_LABEL.QUA_HAN, CATEGORY_ONE.COLOR_LABEL.DEN_HAN,CATEGORY_ONE.COLOR_LABEL.GAN_DEN_HAN, CATEGORY_ONE.COLOR_LABEL.TONG_SO]
  //   }
  // },
  // TAO_MO_HO_SO_BAO_HIEM: {
  //   ID: "canhBaoTaoMoHoSoBaoHiem",
  //   PREFIX: "tmhsbh", 
  //   TITLE: "Cảnh báo tạo mở hồ sơ bảo hiểm",
  //   TOTAL_KEY: "tmhsbhTongSo",
  //   OPTIONS: {
  //     tooltip: {
  //       trigger: 'item',
  //       formatter: '{b}: {c} ({d}%)'
  //     },
  //     color: ["#DC3545", "#FF9C07", "#28A745"],
  //     series: [
  //       {
  //         type: 'pie',
  //         radius: ['25%', '60%'],
  //         left: '-15%',
  //         top: '-10%',
  //         width: "125%",
  //         height: "125%",
  //         labelLine: {
  //           length: 20
  //         },
  //         label: {
  //           formatter: '{c}',
  //         },
  //         data: [
  //           { value: 7, name: 'Quá hạn' },
  //           { value: 4, name: 'Đến hạn' },
  //           { value: 2, name: 'Gần đến hạn' }
  //         ]
  //       }
  //     ]
  //   },
  //   TYPE: "donut",
  //   HEIGHT: 180,
  //   INFO_TEXT: {
  //     KEY: ["hdQuaHan", "hdDenHan", "hdChuaDenHan", "hdTongSo"],
  //     LABEL: [CATEGORY_ONE.LABEL.QUA_HAN, CATEGORY_ONE.LABEL.DEN_HAN,CATEGORY_ONE.LABEL.GAN_DEN_HAN, CATEGORY_ONE.LABEL.TONG_SO],
  //     COLOR_LABEL: [CATEGORY_ONE.COLOR_LABEL.QUA_HAN, CATEGORY_ONE.COLOR_LABEL.DEN_HAN,CATEGORY_ONE.COLOR_LABEL.GAN_DEN_HAN, CATEGORY_ONE.COLOR_LABEL.TONG_SO]
  //   }
  // },
  // PHU_CAP: {
  //   ID: "canhBaoPhuCap",
  //   PREFIX: "pc", 
  //   TITLE: "Cảnh báo phụ cấp",
  //   TOTAL_KEY: "pcTongSo",
  //   OPTIONS: {
  //     tooltip: {
  //       trigger: 'item',
  //       formatter: '{b}: {c} ({d}%)'
  //     },
  //     color: ["#DC3545", "#FF9C07", "#28A745"],
  //     series: [
  //       {
  //         type: 'pie',
  //         radius: ['25%', '60%'],
  //         left: '-15%',
  //         top: '-10%',
  //         width: "125%",
  //         height: "125%",
  //         labelLine: {
  //           length: 20
  //         },
  //         label: {
  //           formatter: '{c}',
  //         },
  //         data: [
  //           { value: 7, name: 'Quá hạn' },
  //           { value: 4, name: 'Đến hạn' },
  //           { value: 2, name: 'Gần đến hạn' }
  //         ]
  //       }
  //     ]
  //   },
  //   TYPE: "donut",
  //   HEIGHT: 180,
  //   INFO_TEXT: {
  //     KEY: ["hdQuaHan", "hdDenHan", "hdChuaDenHan", "hdTongSo"],
  //     LABEL: [CATEGORY_ONE.LABEL.QUA_HAN, CATEGORY_ONE.LABEL.DEN_HAN,CATEGORY_ONE.LABEL.GAN_DEN_HAN, CATEGORY_ONE.LABEL.TONG_SO],
  //     COLOR_LABEL: [CATEGORY_ONE.COLOR_LABEL.QUA_HAN, CATEGORY_ONE.COLOR_LABEL.DEN_HAN,CATEGORY_ONE.COLOR_LABEL.GAN_DEN_HAN, CATEGORY_ONE.COLOR_LABEL.TONG_SO]
  //   }
  // },
  // DEN_HAN_NGUOI_PHU_THUOC: {
  //   ID: "canhBaoDenHanNguoiPhuThuoc",
  //   PREFIX: "dhnpt", 
  //   TITLE: "Cảnh báo đến hạn người phụ thuộc",
  //   TOTAL_KEY: "dhnptTongSo",
  //   OPTIONS: {
  //     tooltip: {
  //       trigger: 'item',
  //       formatter: '{b}: {c} ({d}%)'
  //     },
  //     color: ["#DC3545", "#FF9C07", "#28A745"],
  //     series: [
  //       {
  //         type: 'pie',
  //         radius: ['25%', '60%'],
  //         left: '-15%',
  //         top: '-10%',
  //         width: "125%",
  //         height: "125%",
  //         labelLine: {
  //           length: 20
  //         },
  //         label: {
  //           formatter: '{c}',
  //         },
  //         data: [
  //           { value: 7, name: 'Quá hạn' },
  //           { value: 4, name: 'Đến hạn' },
  //           { value: 2, name: 'Gần đến hạn' }
  //         ]
  //       }
  //     ]
  //   },
  //   TYPE: "donut",
  //   HEIGHT: 180,
  //   INFO_TEXT: {
  //     KEY: ["hdQuaHan", "hdDenHan", "hdTongSo"],
  //     LABEL: ["Quá hạn", "Đến hạn", "Tổng số"],
  //     COLOR_LABEL: ["color-dark-red", "color-dark-orange", ""]
  //   }
  // },
  // TUOI_DANG: {
  //   ID: "canhBaoTuoiDang",
  //   PREFIX: "td", 
  //   TITLE: "Cảnh báo tuổi đảng",
  //   TOTAL_KEY: "tdTongSo",
  //   OPTIONS: {
  //     tooltip: {
  //       trigger: 'item',
  //       formatter: '{b}: {c} ({d}%)'
  //     },
  //     color: ["#DC3545", "#FF9C07", "#28A745"],
  //     series: [
  //       {
  //         type: 'pie',
  //         radius: ['25%', '60%'],
  //         left: '-15%',
  //         top: '-10%',
  //         width: "125%",
  //         height: "125%",
  //         labelLine: {
  //           length: 20
  //         },
  //         label: {
  //           formatter: '{c}',
  //         },
  //         data: [
  //           { value: 7, name: 'Quá hạn' },
  //           { value: 4, name: 'Đến hạn' },
  //           { value: 2, name: 'Gần đến hạn' }
  //         ]
  //       }
  //     ]
  //   },
  //   TYPE: "donut",
  //   HEIGHT: 180,
  //   INFO_TEXT: {
  //     KEY: ["10Nam", "20Nam", "30Nam", "40Nam", "50Nam", "tdTongSo"],
  //     LABEL: ["10 năm", "20 năm", "30 năm", "40 năm", "50 năm", "Tổng số"],
  //     COLOR_LABEL: ["", "", "", "", ""]
  //   }
  // },
  // DANG_VIEN_DU_BI: {
  //   ID: "canhBaoDangVienDuBi",
  //   PREFIX: "dvdb", 
  //   TITLE: "Cảnh báo đảng viên dự bị",
  //   TOTAL_KEY: "dvdbTongSo",
  //   OPTIONS: {
  //     tooltip: {
  //       trigger: 'item',
  //       formatter: '{b}: {c} ({d}%)'
  //     },
  //     color: ["#DC3545", "#FF9C07", "#28A745"],
  //     series: [
  //       {
  //         type: 'pie',
  //         radius: ['25%', '60%'],
  //         left: '-15%',
  //         top: '-10%',
  //         width: "125%",
  //         height: "125%",
  //         labelLine: {
  //           length: 20
  //         },
  //         label: {
  //           formatter: '{c}',
  //         },
  //         data: [
  //           { value: 7, name: 'Quá hạn' },
  //           { value: 4, name: 'Đến hạn' },
  //           { value: 2, name: 'Gần đến hạn' }
  //         ]
  //       }
  //     ]
  //   },
  //   TYPE: "donut",
  //   HEIGHT: 180,
  //   INFO_TEXT: {
  //     KEY: ["hdQuaHan", "hdDenHan", "hdChuaDenHan", "hdTongSo"],
  //     LABEL: [CATEGORY_ONE.LABEL.QUA_HAN, CATEGORY_ONE.LABEL.DEN_HAN,CATEGORY_ONE.LABEL.GAN_DEN_HAN, CATEGORY_ONE.LABEL.TONG_SO],
  //     COLOR_LABEL: [CATEGORY_ONE.COLOR_LABEL.QUA_HAN, CATEGORY_ONE.COLOR_LABEL.DEN_HAN,CATEGORY_ONE.COLOR_LABEL.GAN_DEN_HAN, CATEGORY_ONE.COLOR_LABEL.TONG_SO]
  //   }
  // },
  DEN_HAN_VAN_BANG: {
    ID: "canhBaoDenHanVanBangChungChi",
    PREFIX: "chungChi", 
    TITLE: "Cảnh báo đến hạn văn bằng, chứng chỉ",
    TOTAL_KEY: "chungChiTongSo",
    OPTIONS: {
      tooltip: {
        trigger: 'item',
        formatter: '{b}: {c} ({d}%)'
      },
      color: ["#DC3545", "#FF9C07", "#28A745"],
      series: [
        {
          type: 'pie',
          radius: ['25%', '60%'],
          left: '-15%',
          top: '-15%',
          width: "125%",
          height: "125%",
          labelLine: {
            length: 10
          },
          label: {
            formatter: '{c}',
          },
          data: [
            { value: 7, name: 'Quá hạn' },
            { value: 4, name: 'Đến hạn' },
            { value: 2, name: 'Gần đến hạn' }
          ]
        }
      ]
    },
    TYPE: "donut",
    HEIGHT: 180,
    INFO_TEXT: {
      KEY: ["chungChiQuaHan", "chungChiDenHan", "chungChiGanDenHan", "chungChiTongSo"],
      LABEL: [CATEGORY_ONE.LABEL.QUA_HAN, CATEGORY_ONE.LABEL.DEN_HAN,CATEGORY_ONE.LABEL.GAN_DEN_HAN, CATEGORY_ONE.LABEL.TONG_SO],
      COLOR_LABEL: [CATEGORY_ONE.COLOR_LABEL.QUA_HAN, CATEGORY_ONE.COLOR_LABEL.DEN_HAN,CATEGORY_ONE.COLOR_LABEL.GAN_DEN_HAN, CATEGORY_ONE.COLOR_LABEL.TONG_SO]
    }
  },
  // TANG_GIAM_THAY_DOI_BH: {
  //   ID: "canhBaoTangGiamBaoHiem",
  //   PREFIX: "tgbh", 
  //   TITLE: "Cảnh báo tăng/giảm/thay đổi bảo hiểm",
  //   TOTAL_KEY: "tgbhTongSo",
  //   OPTIONS: {
  //     tooltip: {
  //       trigger: 'item',
  //       formatter: '{b}: {c} ({d}%)'
  //     },
  //     color: ["#DC3545", "#FF9C07", "#28A745"],
  //     series: [
  //       {
  //         type: 'pie',
  //         radius: ['25%', '60%'],
  //         left: '-15%',
  //         top: '-10%',
  //         width: "125%",
  //         height: "125%",
  //         labelLine: {
  //           length: 20
  //         },
  //         label: {
  //           formatter: '{c}',
  //         },
  //         data: [
  //           { value: 7, name: 'Quá hạn' },
  //           { value: 4, name: 'Đến hạn' },
  //           { value: 2, name: 'Gần đến hạn' }
  //         ]
  //       }
  //     ]
  //   },
  //   TYPE: "donut",
  //   HEIGHT: 180,
  //   INFO_TEXT: {
  //     KEY: ["hdQuaHan", "hdDenHan", "hdChuaDenHan", "hdTongSo"],
  //     LABEL: ["Báo tăng", "Báo giảm", "Báo thay đổi", "Tổng số"],
  //     COLOR_LABEL: ["color-green", "color-dark-red", "color-dark-orange", ""]
  //   }
  // },
}

export const warningPrefix = Object.values(LOAI_CANH_BAO).map(value => value.PREFIX);
export const warningTitle = Object.values(LOAI_CANH_BAO).map(value => value.TITLE);
export const warningId = Object.values(LOAI_CANH_BAO).map(value => value.ID);
export const totalKey = Object.values(LOAI_CANH_BAO).map(value => value.TOTAL_KEY);
export const infoTextKey = Object.values(LOAI_CANH_BAO).map(value => value.INFO_TEXT.KEY);
export const infoTextLabel = Object.values(LOAI_CANH_BAO).map(value => value.INFO_TEXT.LABEL);
export const infoTextColorLabel = Object.values(LOAI_CANH_BAO).map(value => value.INFO_TEXT.COLOR_LABEL);
export const chartOptions = Object.values(LOAI_CANH_BAO).map(value => value.OPTIONS);
export const chartType = Object.values(LOAI_CANH_BAO).map(value => value.TYPE);
export const chartHeight = Object.values(LOAI_CANH_BAO).map(value => value.HEIGHT);

export const WARNING = {
  STATUS: {
    CODE: {
      GAN_DEN_HAN: 1,
      DEN_HAN: 2,
      QUA_HAN: 3
    }
  }
};

export const TYPE_CHART = {
  DONUT: "donut",
  BAR: "bar"
}

export const EXPIRE_STATUS_KEY = {
  HET_HAN_HOP_DONG: "trangThaiHetHanHd",
  DEN_HAN_NANG_LUONG: "trangThaiHetHanNangLuong",
  DEN_TUOI_NGHI_HUU: "trangThaiHetHanNghiHuu",
  DEN_HAN_VAN_BANG_CHUNG_CHI: "trangThaiHetHan",
  HET_HAN_LUAN_CHUYEN: "trangThaiHetHan",
}

export const retireOptions = [
  {
    code: 1,
    name: "Tinh giản biên chế"
  },
  {
    code: 2,
    name: "Nghỉ kết thúc hợp đồng"
  },
  {
    code: 3,
    name: "Nghỉ hưu"
  },
  {
    code: 4,
    name: "Kỷ luật buộc thôi việc"
  },
  {
    code: 5,
    name: "Chết"
  },
  {
    code: 6,
    name: "Nghỉ theo nguyện vọng"
  },
  {
    code: 7,
    name: "Luân chuyển/Điều động"
  },
  {
    code: 8,
    name: "Lý do khác"
  }
]

export const insuranceStatusOptions = [
  {
    code: 1,
    name: "Không thay đổi bảo hiểm"
  },
  {
    code: 2,
    name: "Dừng đóng bảo hiểm"
  },
  {
    code: 3,
    name: "Báo giảm bảo hiểm"
  }
]

export const HOE_NHAI_CODE = "BVDKHN";

export enum TYPE_OF_RESIGN_CODE {
  NGHI_HUU = 3
}