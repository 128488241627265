import { Col, Row } from "react-bootstrap";
import { IStatisticalChart } from "../models/IStatisticalChart";
import { StatisticalChart } from "./chart/StatisticalChart";
import { useEffect, useState } from "react";
import { LAYOUT_CHART } from "../const/StatisticalConst";

type IProps = {
  listChart: IStatisticalChart[];
}

export const SplitLayout: React.FC<IProps> = ({ listChart }) => {
  const [activeTab, setActiveTab] = useState(0);
  const [chartsData, setChartsData] = useState(listChart);

  useEffect(() => {
    setChartsData(listChart);
    setActiveTab(0);
  }, [listChart]);

  const handleTabClick = (index: number) => {
    setActiveTab(index);
  };

  return (
    <Row className="split-layout-wrapper">
      <Col xs={3} className="chart-tabs">
        <ul className="nav nav-tabs nav-pills border-0 flex-row flex-md-column me-4">
          {chartsData?.map((chart: IStatisticalChart, index: number) => (
            <li className="nav-item me-0" key={index}>
              <a
                className={`nav-link ${index === activeTab ? "active" : ""}`}
                data-bs-toggle="tab"
                href={`#kt_vtab_pane_${index + 1}`}
                title={chart?.title}
                onClick={() => handleTabClick(index)}
              >
                <span className="line-clamp-1">{chart?.title}</span>
              </a>
            </li>
          ))}
        </ul>
      </Col>
      <Col xs={9} className="charts">
        <div className="tab-content h-100" id="myTabContent">
          {chartsData?.map((chart: IStatisticalChart, index: number) => (
            <div
              className={`tab-pane fade h-100 ${chart.type === "donut" ? "chart-pie" : ""
                } ${index === activeTab ? "active show" : ""}`}
              id={`kt_vtab_pane_${index + 1}`}
              role="tabpanel"
              key={index}
            >
              <StatisticalChart options={chart.options} title={chart?.title} typeId={chart?.id} year={chart?.year} typeLayout={LAYOUT_CHART.SPLIT}/>
            </div>
          ))}
        </div>
      </Col>
    </Row>
  );
};
