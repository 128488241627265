import { Col, Form, Row } from "react-bootstrap";
import useMultiLanguage from "../../hook/useMultiLanguage";
import { useContext, useEffect, useState } from "react";
import { KTSVG } from "../../../_metronic/helpers/components/KTSVG";
import { RESPONSE_STATUS_CODE, SEARCH_OBJECT_MAX_SIZE, VARIABLE_STRING } from "../utils/Constant";
import { toast } from "react-toastify";
import AppContext from "../../AppContext";
import { getReportDetail } from "./services/reportServices";
import { countLevels, exportToFile, getCurrentQuarter, getFullYear } from "../utils/FunctionUtils";
import { exportBaoCao } from "../utils/ExportFileServices";
import { GroupButton } from "../component/GroupButton";
import Autocomplete from "../component/input-field/Autocomplete";
import { useFormik } from "formik";
import { IColumns, IReportInfo, ISearchDetail } from "./models/reportModels";
import { searchListWorkUnit } from "../utils/CategoryServices";
import TableGrouping, { IColumnsTotal } from "../component/TableGrouping/TableGrouping";
import { QUARTER_LIST, REPORT_MAPING_COLUMNS, REPORT_TYPE } from "./consts/reportConsts";
import { convertDataReport, getHeadOfMonth, getHeadOfYear, templateTitleReport } from "./util/reportUtil";
import { RangeDatePicker } from "../component/input-field/RangeDatePicker";
import { EXPORT_FILE_NAME } from "../../Constant";
import * as Yup from "yup";
import { checkInvalidDate } from "../utils/ValidationSchema";
import moment from "moment";
import { PROBLEM_STATUS_LIST } from "../problem/constant/ProblemConst";
import { LIST_LEVEL } from "../component/Constants";

interface IProps {
  reportInfo: IReportInfo;
  handleBack: () => void;
}

function ReportDetail(props: IProps) {
  const { lang, intl } = useMultiLanguage();
  const { reportInfo, handleBack } = props;

  const [data, setData] = useState<any[]>([]);
  const [workUnitList, setWorkUnitList] = useState<any[]>([]);
  const [comlumns, setColumns] = useState<IColumns[]>([]);
  const [comlumnsTotal, setColumnsTotal] = useState<IColumnsTotal[]>([]);
  const [isTitleRoman, setIsTitleRoman] = useState<boolean>(false);
  const [titleReport, setTitleReport] = useState<string>("");
  const [isSalaryFund, setIsSalaryFund] = useState<boolean>(false);
  const [isViolation, setIsViolation] = useState<boolean>(false);
  const { setPageLoading } = useContext(AppContext);

  const validationSchema: any = Yup.object().shape({
    tuNgay: Yup.date().concat(checkInvalidDate(intl)).required(lang("VALIDATION.REQUIRE")).nullable(),
    denNgay: Yup.date()
      .concat(checkInvalidDate(intl))
      .when("tuNgay", {
        is: (tuNgay: Date | null) => tuNgay,
        then: Yup.date().min(Yup.ref("tuNgay"), lang("VALIDATION.END_DATE_BIGGER_START_DATE"))
      })
      .required(lang("VALIDATION.REQUIRE")).nullable(),
  });
  
  useEffect(() => {
    initComponent();
    let columnsByType = REPORT_MAPING_COLUMNS.find((column) => column.type === reportInfo?.mappingName);
    setColumns(columnsByType?.columns || []);
    setColumnsTotal(columnsByType?.columnsTotal || []);
    setIsTitleRoman(columnsByType?.isFirstTitleRoman || false);
    setIsSalaryFund(REPORT_TYPE.QUY_LUONG === reportInfo?.mappingName);
    setIsViolation(REPORT_TYPE.TONG_HOP_VI_PHAM === reportInfo?.mappingName);
  }, [reportInfo, isSalaryFund, isViolation]);

  useEffect(() => {
    getWorkUnit();
  }, []);

  const initComponent = () => {
    const yearDefault = getFullYear().find((year) => Number(year?.name) === new Date().getFullYear());
    const quarterDefault = QUARTER_LIST.find((quarter) => quarter.code === getCurrentQuarter());

    let stateDefault = {
      ...formik.values,
      year: yearDefault || null,
      quy: quarterDefault || null
    };

    let searchSalary = isSalaryFund
      ? {
          tuNgay: yearDefault?.name && quarterDefault ? yearDefault?.name + quarterDefault?.startDate : null,
          denNgay: yearDefault?.name && quarterDefault ? yearDefault?.name + quarterDefault?.endDate : null
        }
      : {};

    let searchViolation = isViolation
      ? {
          tuNgay: getHeadOfMonth(new Date()),
          denNgay: moment(new Date()).format("YYYY-MM-DD")
        }
      : {};

    let searchWorkAccident = [REPORT_TYPE.TAI_NAN_LAO_DONG, REPORT_TYPE.SUC_KHOE_HANG_NAM].includes(reportInfo.mappingName)
      ? {
          tuNgay: getHeadOfYear(new Date()),
          denNgay: moment(new Date()).format("YYYY-MM-DD")
        }
      : {};

    const searchObj: ISearchDetail = {
      ...stateDefault,
      type: reportInfo?.mappingName,
      ...searchSalary,
      ...searchViolation,
      ...searchWorkAccident
    };
    formik.setValues(searchObj);
    updatePageData(searchObj);
  };

  const getWorkUnit = async () => {
    try {
      const { data } = await searchListWorkUnit(SEARCH_OBJECT_MAX_SIZE);
      if (data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
        setWorkUnitList(data?.data?.content || []);
      }
    } catch (error) {
      toast.error(lang("GENERAL.ERROR"));
    }
  };

  const updatePageData = async ({ donVi, ...searchData }: ISearchDetail) => {
    try {
      setPageLoading(true);
      const { data } = await getReportDetail(searchData);
      if (data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
        const reportData = data?.data;
        countLevels(reportData) !== LIST_LEVEL.ONE
          ? setData(reportData ? convertDataReport(reportData) : [])
          : setData(reportData ? (Array.isArray(reportData) ? reportData : [reportData]) : []);
        setTitleReport(templateTitleReport(reportInfo, {
          quarter: Number(searchData.quy?.code),
          year: searchData.year?.name || "",
          tuNgay: searchData.tuNgay,
          denNgay: searchData.denNgay
        }));
      }
      setPageLoading(false);
    } catch (error) {
      toast.error(lang("GENERAL.ERROR"));
      setPageLoading(false);
    }
  };

  const handleExportExcel = () => {
    exportToFile({
      exportAPI: () =>
        exportBaoCao({ type: reportInfo?.mappingName, trangThai: formik.values?.trangThaiOption?.code || null }),
      fileName: EXPORT_FILE_NAME.BAO_CAO,
      setPageLoading
    });
  };

  const handleSubmit = (values: ISearchDetail) => {
    let startDateSalary = values?.year?.name && values?.quy ? values?.year?.name + values?.quy?.startDate : null;
    let endDateSalary = values?.year?.name && values?.quy ? values?.year?.name + values?.quy?.endDate : null;
    const searchData: ISearchDetail = {
      ...values,
      tuNgay: isSalaryFund ? startDateSalary : values?.tuNgay || "",
      denNgay: isSalaryFund ? endDateSalary : values?.denNgay || "",
      type: reportInfo?.mappingName,
      trangThai: values?.trangThaiOption?.code,
      donViCongTacId: values?.donVi?.id || null
    };
    updatePageData(searchData);
  };

  const formik = useFormik({
    initialValues: {} as ISearchDetail,
    validationSchema,
    onSubmit: handleSubmit
  });

  const handleChangeSelect = (name: string, value: any) => {
    formik.setFieldValue(name, value);
  };

  return (
    <div className="flex-1">
      <div className="spaces flex flex-middle flex-space-between py-12 color-primary text-uppercase">
        <GroupButton
          type="btn-back"
          handleClose={handleBack}
        />
      </div>

      <div className="report-detail spaces p-12 flex flex-column">
        <Form onSubmit={formik.handleSubmit} className="spaces z-index-4">
          <Row className="pb-4 relative">
            <Col xs={3}>
              <Autocomplete
                lable={lang("WARNING.UNIT")}
                options={workUnitList}
                value={formik.values?.donVi || null}
                name="donVi"
                onChange={(value) => handleChangeSelect(VARIABLE_STRING.DON_VI, value)}
              />
            </Col>
            <Col xs={7}>
              <Row>
                {isSalaryFund ? (
                  <>
                    <Col xs={3} xxl={2}>
                      <Autocomplete
                        lable={lang("GENERAL.YEAR")}
                        options={getFullYear()}
                        value={formik.values?.year || null}
                        name="year"
                        onChange={(value) => handleChangeSelect(VARIABLE_STRING.YEAR, value)}
                      />
                    </Col>
                    <Col xs={3} xxl={2}>
                      <Autocomplete
                        lable={lang("GENERAL.QUARTER")}
                        options={QUARTER_LIST}
                        value={formik.values?.quy || null}
                        name="quy"
                        onChange={(value) => handleChangeSelect(VARIABLE_STRING.QUY, value)}
                      />
                    </Col>
                  </>
                ) : (
                  <>
                    <Col xs={6} xxl={5}>
                      <RangeDatePicker
                        label={lang("GENERAL.TIME")}
                        startDateName="tuNgay"
                        endDateName="denNgay"
                        isRequired
                        handleChange={formik.handleChange}
                        value={formik.values}
                        setFieldValue={formik.setFieldValue}
                        touch={formik.touched}
                        errors={formik.errors}
                      />
                    </Col>
                    {isViolation && 
                      <Col xs={3}>
                        <Autocomplete
                          lable={lang("GENERAL.STATUS")}
                          options={PROBLEM_STATUS_LIST}
                          value={formik.values?.trangThaiOption || null}
                          name="trangThaiOption"
                          onChange={(value) => handleChangeSelect("trangThaiOption", value)}
                        />
                      </Col>
                    }
                  </>
                )}
                <Col xs={2} className="spaces mt-21">
                  <button className="button-primary flex flex-middle cursor-pointer" type="submit">
                    <i className="bi bi-funnel spaces flex fs-18 text-white mr-6"></i>
                    {lang("GENERAL.FILTER")}
                  </button>
                </Col>
              </Row>
            </Col>
            <Col xs={2} className="spaces mt-21 flex-end">
              <div>
                <button
                  className="spaces flex flex-middle table-btn outline mr-16"
                  type="button"
                  onClick={handleExportExcel}
                >
                  <KTSVG path="/media/icons/export-excel.svg" />
                  {lang("BTN.EXPORT")}
                </button>
              </div>
            </Col>
          </Row>
          <div className="text-center">
            <span className="text-header spaces fs-20">{titleReport}</span>
          </div>
        </Form>

        <div className="bg-white spaces mt-10 flex-1">
          <TableGrouping
            id="reportDetail"
            columns={comlumns || []}
            data={data || []}
            className="table-custom"
            showTotalRow={Array.isArray(comlumnsTotal) && comlumnsTotal.length > 0}
            columnsTotal={comlumnsTotal}
            prefixDataField="data"
            prefixTitleField="phongBanText"
            isFirstTitleRoman={isTitleRoman}
          />
        </div>
      </div>
    </div>
  );
}

export default ReportDetail;
