import { OptionReactSelect } from "../../models/models";
import { searchPhongBan } from "../../profile/services/DialogServices";
import { InterruptInfo } from "../models/IInterrupt";
import { MISSION_STATUS, SEARCH_OBJECT_MAX_SIZE, TYPE } from "../../utils/Constant";
import { searchAllSimpleValue } from "../../services";
import { TYPE_CATEGORY } from "../../constant";
import { EXPORT_FILE_NAME } from "../../../Constant";
import { ITemplate } from "../../models/exportExcelModel";

export const INIT_INTERRUPT_INFO: InterruptInfo = {
	employee: null,
	employeeCode: "",
	employeeId: "",
	chucVu: null,
	chucVuId: "",
	chucVuText: "",
	phongBan: null,
	phongBanId: "",
	phongBanText: "",
	viTriCongViec: null,
	viTriCongViecId: "",
	viTriCongViecText: "",
	createDateTime: null,
	createdBy: "",
	loaiNghi: null,
	ngayBatDau: "",
	ngayQuayLaiLV: "",
	lyDoNghi: "",
	soQuyetDinh: "",
	nguoiKy: null,
	nguoiKyId: "",
	nguoiKyText: "",
	fileId: "",
	fileName: "",
	trangThaiBaoHiem: null,
	thoiGianDungDongBH: "",
	hinhThucDieuChinh: null,
	thoiGianThucHien: "",
	modifiedBy: "",
	modifyDate: null,
	soTienBhxhThanhToan: null
}

export const listInterruptType: OptionReactSelect[] = [
	{
		code: 1,
		name: "Nghỉ thai sản"
	},
	{
		code: 2,
		name: "Nghỉ ốm"
	},
	{
		code: 3,
		name: "Nghỉ không lương"
	},
	{
		code: 4,
		name: "Đình chỉ công tác"
	},
	{
		code: 5,
		name: "Lý do khác"
	},
]

export const FIELD_SEARCH_INTERRUPT = [
	{
		name: "Mã nhân viên",
		field: "code",
		type: TYPE.TEXT
	},
	{
		name: "Họ và tên",
		field: "name",
		type: TYPE.TEXT
	},
	{
		name: "Phòng/Ban",
		field: "phongBanIds",
		type: TYPE.SELECT,
		searchFunction: searchPhongBan,
		searchObject: { ...SEARCH_OBJECT_MAX_SIZE },
		isMulti: true,
	},
	{
		name: "Chức vụ",
		field: "chucVuId",
		searchFunction: searchAllSimpleValue,
		searchObject: { ...SEARCH_OBJECT_MAX_SIZE, type: TYPE_CATEGORY.chucDanh },
		type: TYPE.SELECT,
		optionLabel: "value"
	},
	{
		name: "Vị trí công việc",
		field: "viTriCongViecIds",
		searchFunction: searchAllSimpleValue,
		searchObject: { ...SEARCH_OBJECT_MAX_SIZE, type: TYPE_CATEGORY.viTriCongViec },
		type: TYPE.SELECT,
		optionLabel: "value",
		isMulti: true,
	},
	{
		name: "Loại nghỉ",
		field: "loaiNghi",
		type: TYPE.SELECT,
		listOption: listInterruptType
	},
	{
		name: "Ngày bắt đầu",
		field: "ngayBatDau",
		type: TYPE.DATE
	},
	{
		name: "Ngày trở lại làm việc",
		field: "ngayQuayLaiLV",
		type: TYPE.DATE
	},
	{
		name: "Số quyết định",
		field: "soQuyetDinh",
		type: TYPE.TEXT
	},
	{
		name: "Trạng thái",
		field: "trangThai",
		type: TYPE.SELECT,
		listOption: MISSION_STATUS,
		isMulti: true,
	},
];

export const LIST_TEMPLATE_INTERRUPTS: ITemplate[] = [
	{
		type: 1,
		name: EXPORT_FILE_NAME.GIAN_DOAN,
		defaultCodeExportField: [
			{ code: "maNhanVien", isRemove: true },
			{ code: "name", isRemove: true },
			{ code: "phongBanText", isRemove: true },
			{ code: "chucVuText", isRemove: true },
			{ code: "viTriCongViecText", isRemove: true },
			{ code: "loaiNghi", isRemove: true },
			{ code: "ngayBatDau", isRemove: true },
			{ code: "ngayQuayLaiLV", isRemove: true },
			{ code: "lyDoNghi", isRemove: true },
			{ code: "soQuyetDinh", isRemove: true },
			{ code: "nguoiKyText", isRemove: true },
			{ code: "trangThaiBaoHiem", isRemove: true },
			{ code: "ghiChu", isRemove: true },
			{ code: "thoiGianDungDongBH", isRemove: true },
			{ code: "hinhThucDieuChinh", isRemove: true },
			{ code: "thoiGianThucHien", isRemove: true },
			{ code: "trangThai", isRemove: true }
		]
	}, {
		type: 2,
		name: EXPORT_FILE_NAME.NGHI_OM,
		defaultCodeExportField: [
			{ code: "maNhanVien", isRemove: false },
			{ code: "name", isRemove: false },
			{ code: "birthDate", isRemove: false },
			{ code: "ngayBatDau", isRemove: false },
			{ code: "ngayQuayLaiLV", isRemove: false },
			{ code: "soNgayNghi", isRemove: false },
			{ code: "lyDoNghi", isRemove: false },
			{ code: "soTienBhxhThanhToan", isRemove: false },
		]
	}
];