/* eslint-disable react-hooks/exhaustive-deps */
import { useFormik } from "formik";
import { FC, useContext, useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { FILE_TYPE } from "../../component/FileUpload/constant";
import Autocomplete from "../../component/input-field/Autocomplete";
import TextValidator from "../../component/input-field/TextValidator";
import { TYPE_CATEGORY } from "../../constant";
import { getAllSimpleValue } from "../../services";
import { searchListWorkUnit } from "../../utils/CategoryServices";
import { REGEX, SEARCH_OBJECT_MAX_SIZE, VARIABLE_STRING } from "../../utils/Constant";
import { convertNumberPrice, convertTextPrice, handleBlurDate } from "../../utils/FunctionUtils";
import { checkInvalidDate } from "../../utils/ValidationSchema";
import { CODE_HOP_DONG, INIT_CONTACT_ANNEX_INFO, SUCCESS_CODE, contractTypes, signinStatus } from "../const/ContractConst";
import { addNewAnnex, updateAnnex } from "../services/annexServices";
import { IContractAnnexInfo, IContractInfo } from "../services/models/IContract";
import { convertDataContactDto, filterObject } from "../utils/FunctionUtils";
import useMultiLanguage from "../../../hook/useMultiLanguage";
import AppContext from "../../../AppContext";
import UploadFile from "../../component/FileUpload/UploadFile";
import { IFile } from "../../profile/models/DialogModels";
import { searchNguoiDaiDienKy } from "../services/contractServices";

interface IProps {
  handleCLoseAddContractAnnex: () => void;
  getListAnnex: () => Promise<void>;
  contractInfo: IContractInfo;
  annexInfo: IContractAnnexInfo;
  isView?: boolean;
  isCollaborators?: boolean;
}
const AddNewContractAnnex: FC<IProps> = (props) => {
  const { handleCLoseAddContractAnnex, getListAnnex, contractInfo, annexInfo, isView = false, isCollaborators = false } = props;
  const { lang, intl } = useMultiLanguage();
  const [isEdit, setIsEdit] = useState<boolean>(!isView);
  const [isLongTermContract, setIsLongTermContract] = useState<boolean>(false);
  const { setPageLoading } = useContext(AppContext);

  const validationSchema = Yup.object().shape({
    soPhuLuc: Yup.string().required(lang("VALIDATION.REQUIRE")).nullable().max(255, lang("VALIDATION.255MAX")),
    tenPhuLuc: Yup.string().required(lang("VALIDATION.REQUIRE")).nullable().max(255, lang("VALIDATION.255MAX")),
    ngayCoHieuLuc: Yup.date().concat(checkInvalidDate(intl)).nullable(),
    ngayHetHan: Yup.date().concat(checkInvalidDate(intl)).nullable(),
    ngayKy: Yup.date()
      .concat(checkInvalidDate(intl))
      .required(lang("VALIDATION.REQUIRE"))
      .min(Yup.ref('ngayCoHieuLuc'), lang("VALIDATE.SIGNING_DATE_AFTER_EFFECTIVE_DATE"))
      .when('ngayHetHan', {
        is: (ngayHetHan: Date | null) => ngayHetHan,
        then: Yup.date()
          .max(Yup.ref('ngayHetHan'), lang('VALIDATE.SIGNING_DATE_AFTER_EXPIRATION_DATE')),
      })
      .nullable(),
    ngayCoHieuLucPhuLuc: Yup.date().required(lang("VALIDATION.REQUIRE"))
      .concat(checkInvalidDate(intl))
      .when("ngayHetHan", {
        is: (ngayHetHan: Date | null) => ngayHetHan,
        then: Yup.date()
          .max(Yup.ref('ngayHetHan'), lang("VALIDATE.EFFECTIVE_DATE_BEFORE_EXPIRATION_DATE")).nullable(),
      })
      .min(Yup.ref('ngayCoHieuLuc'), lang("VALIDATE.EFFECTIVE_ADDENDUM_DATE_AFTER_EFFECTIVE_DATE"))
      .nullable(),
    donViKyHopDong: Yup.object().required(lang("VALIDATION.REQUIRE")).nullable(),
    viTriCongViec: Yup.object().required(lang("VALIDATION.REQUIRE")).nullable(),
    loaiHopDong: Yup.object()
      .required(lang("VALIDATION.REQUIRE"))
      .nullable(),
    tyLeHuongLuong: Yup.string().nullable().matches(REGEX.PERCENTAGE, lang("VALIDATE.SALARY.RATE.INVALID")),
    luongCoBan: Yup.string().required(lang("VALIDATION.REQUIRE")).nullable(),
    nguoiDaiDienCtyKy: Yup.object().required(lang("VALIDATION.REQUIRE")).nullable()
  });

  useEffect(() => {
    const checkHopDongDaiHan = contractInfo?.loaiHopDong?.code === CODE_HOP_DONG.ONE_YEAR || contractInfo?.loaiHopDong?.code === CODE_HOP_DONG.HOP_DONG_DAI_HAN || contractInfo?.loaiHopDong?.code === CODE_HOP_DONG.VIEN_CHUC_DAI_HAN
    setIsLongTermContract(checkHopDongDaiHan);
    if (contractInfo?.id) {
      formik.setValues({
        ...formik.values,
        loaiHopDong: contractInfo?.loaiHopDong,
        hopDongGoc: contractInfo?.soHopDong,
        donViKyHopDong: contractInfo?.donViKyHopDong,
        ngayCoHieuLuc: contractInfo?.ngayCoHieuLuc,
        ngayHetHan: contractInfo?.ngayHetHan
      });
    }
  }, [contractInfo])

  const convertData = (data: IContractAnnexInfo) => {
    return {
      ...data,
      bacLuongOption: data?.bacLuong ? {
        bacLuong: data.bacLuong,
        heSoLuong: data?.heSoLuong
      } : null,
      donViKyHopDong: data?.donViKyHopDongId ? {
        id: data?.donViKyHopDongId,
        name: data?.donViKyHopDongText
      } : null,
      nguoiDaiDienCtyKy: data?.nguoiDaiDienCtyKyId ? {
        id: data?.nguoiDaiDienCtyKyId,
        name: data?.nguoiDaiDienCtyKyText
      } : null,
      nguoiDaiDienCtyChucDanh: data?.nguoiDaiDienCtyChucDanhId ? {
        id: data?.nguoiDaiDienCtyChucDanhId,
        value: data?.nguoiDaiDienCtyChucDanhText
      } : null,
      viTriCongViec: data?.viTriCongViecId ? {
        id: data?.viTriCongViecId,
        value: data?.viTriCongViecText
      } : null
    };
  };

  const handleSubmit = async (values: IContractAnnexInfo) => {
    try {
      setPageLoading(true);
      values.employeeId = contractInfo.employeeId || "";
      values.hopDongLaoDongId = contractInfo.id;
      const dataAnnex = filterObject(convertDataContactDto(values));
      const res = annexInfo.id ? await updateAnnex(annexInfo.id, dataAnnex) : await addNewAnnex(dataAnnex);
      if (res?.data?.code === SUCCESS_CODE) {
        toast.success(annexInfo.id ? lang("TOAST.EDIT.SUCCESS") : lang("TOAST.CREATE.SUCCESS"));
        await getListAnnex();
        handleCLoseAddContractAnnex();
      } else {
        toast.error(`${res?.data?.message}`);
      }
    } catch (err) {
      toast.error(lang("GENERAL.ERROR"));
    } finally {
      setPageLoading(false)
    }
  };

  const formik = useFormik({
    initialValues: annexInfo?.id ? convertData(annexInfo) : INIT_CONTACT_ANNEX_INFO,
    validationSchema,
    onSubmit: handleSubmit
  });

  const handleChangeSelect = (name: string, value: any) => {
    formik.setFieldValue(name, value);
    switch (name) {
      case VARIABLE_STRING.BAC_LUONG_OPTION:
        formik.setFieldValue(name, value);
        formik.setFieldTouched(name, false, false);
        formik.setFieldValue(VARIABLE_STRING.HE_SO_LUONG, value?.heSoLuong || "");
        break;
      case VARIABLE_STRING.NGUOI_DAI_DIEN_KY:
        formik.setValues({
          ...formik.values,
          nguoiDaiDienCtyChucDanhId: value?.chucVuId,
          nguoiDaiDienCtyChucDanhText: value?.chucDanh,
          nguoiDaiDienCtyKyId: value?.employeeId,
          nguoiDaiDienCtyKyText: value?.name
        });
        formik.setFieldValue(name, value);
        break;
      default:
        formik.setFieldValue(name, value);
        break;
    }
  };

  const handleCheckBoxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;
    formik.setFieldValue(name, checked);
  };

  const handleChangeMoney = (event: React.ChangeEvent<any>) => {
    const { name, value } = event.target;
    formik.setFieldValue(name, convertTextPrice(value));
  };

  const handleFile = (data: IFile) => {
    formik.setFieldValue("fileId", data.id);
    formik.setFieldValue("fileName", data.name);
  }

  return (
    <Modal
      show={true}
      size="xl"
      onHide={handleCLoseAddContractAnnex}
      className="custom-modal"
      centered
    >
      <Form onSubmit={formik.handleSubmit}>
        <Modal.Header closeButton>
          <Modal.Title className="heading-5">{lang("CONTRACT.ANNEX")}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="annex-dialog contract-dialog-content">
          <Row>
            <Row>
              <Col
                xs={12}
                className="mx-4"
              >
                <span className="fs-3 fw-bold">{lang("INFO.MAIN")}</span>
              </Col>
              <Col xs={12}>
                <Row className="ms-8">
                  <Col xs={4} className="mt-3">
                    <TextValidator
                      lable={lang("ANNEX.NUMBER")}
                      name="soPhuLuc"
                      value={formik.values?.soPhuLuc || ""}
                      type="text"
                      isRequired
                      readOnly={!isEdit}
                      onChange={formik.handleChange}
                      errors={formik.errors?.soPhuLuc}
                      touched={formik.touched?.soPhuLuc}
                    />
                  </Col>
                  <Col xs={4} className="mt-3">
                    <TextValidator
                      lable={lang("ANNEX.NAME")}
                      name="tenPhuLuc"
                      value={formik.values?.tenPhuLuc || ""}
                      type="text"
                      isRequired
                      readOnly={!isEdit}
                      onChange={formik.handleChange}
                      errors={formik.errors?.tenPhuLuc}
                      touched={formik.touched?.tenPhuLuc}
                    />
                  </Col>
                  <Col xs={4} className="mt-3">
                    <TextValidator
                      lable={lang("ANNEX.SIGNINGDATE")}
                      isRequired
                      name="ngayKy"
                      value={formik.values?.ngayKy || ""}
                      type="date"
                      readOnly={!isEdit}
                      onChange={formik.handleChange}
                      onBlur={() => handleBlurDate(formik.setFieldValue, formik.values?.ngayKy, "ngayKy")}
                      errors={formik.errors?.ngayKy}
                      touched={formik.touched?.ngayKy}
                    />
                  </Col>
                  <Col xs={4} className="mt-3">
                    <TextValidator
                      lable={lang("ANNEX.EFFECTIVEDATE")}
                      name="ngayCoHieuLucPhuLuc"
                      value={formik.values?.ngayCoHieuLucPhuLuc || ""}
                      type="date"
                      isRequired
                      readOnly={!isEdit}
                      onChange={formik.handleChange}
                      onBlur={() =>
                        handleBlurDate(formik.setFieldValue, formik.values?.ngayCoHieuLucPhuLuc, "ngayCoHieuLucPhuLuc")
                      }
                      errors={formik.errors?.ngayCoHieuLucPhuLuc}
                      touched={formik.touched?.ngayCoHieuLucPhuLuc}
                    />
                  </Col>
                  <Col xs={4} className="mt-3">
                    <TextValidator
                      lable={lang("CONTRACT.ORIGINAL")}
                      name="hopDongGoc"
                      value={formik.values?.hopDongGoc || ""}
                      type="text"
                      readOnly={!isEdit}
                      onChange={formik.handleChange}
                    />
                  </Col>
                  <Col xs={4} className="mt-3">
                    <Autocomplete
                      lable={lang("CONTRACT.SIGNINGSTATUS")}
                      options={signinStatus}
                      value={formik.values?.trangThaiKy || null}
                      name="trangThaiKy"
                      isReadOnly={!isEdit}
                      onChange={(selectedOption) => handleChangeSelect("trangThaiKy", selectedOption)}
                    />
                  </Col>
                </Row>
              </Col>
              <Col
                xs={12}
                className="mt-12 mx-4"
              >
                <span className="fs-3 fw-bold ">{lang("INFO.CHANGE")}</span>
              </Col>
              <Col xs={12}>
                <Row className="ms-8">
                  <Col xs={4} className="mt-3">
                    <Autocomplete
                      isRequired
                      lable={lang("CONTRACT.SIGNINGUNIT")}
                      options={[]}
                      value={formik.values?.donViKyHopDong || null}
                      name="donViKyHopDong"
                      isReadOnly={!isEdit}
                      onChange={(selectedOption) => handleChangeSelect("donViKyHopDong", selectedOption)}
                      searchFunction={searchListWorkUnit}
                      searchObject={SEARCH_OBJECT_MAX_SIZE}
                      touched={formik.touched?.donViKyHopDong}
                      errors={formik.errors?.donViKyHopDong}
                    />
                  </Col>
                  <Col xs={4} className="mt-3">
                    <Autocomplete
                      isRequired
                      lable={lang("CONTRACT.JOBPOSITION")}
                      options={[]}
                      value={formik.values?.viTriCongViec || null}
                      name="viTriCongViec"
                      isReadOnly={!isEdit}
                      onChange={(selectedOption) => handleChangeSelect("viTriCongViec", selectedOption)}
                      getOptionLabel={(option) => option?.value}
                      searchFunction={getAllSimpleValue}
                      searchObject={TYPE_CATEGORY.chucDanh}
                      touched={formik.touched?.viTriCongViec}
                      errors={formik.errors?.viTriCongViec}
                    />
                  </Col>
                  <Col xs={4} className="mt-3">
                    <Autocomplete
                      isRequired
                      lable={lang("CONTRACT.TYPE")}
                      options={contractTypes}
                      isReadOnly
                      value={formik.values?.loaiHopDong || null}
                      name="loaiHopDong"
                      onChange={(selectedOption) => handleChangeSelect("loaiHopDong", selectedOption)}
                      touched={formik.touched?.loaiHopDong}
                      errors={formik.errors?.loaiHopDong}
                    />
                  </Col>
                  <Col xs={4} className="mt-3">
                    <TextValidator
                      lable={lang("CONTRACT.EFFECTIVEDATE")}
                      name="ngayCoHieuLuc"
                      isRequired
                      value={formik.values?.ngayCoHieuLuc || ""}
                      type="date"
                      readOnly={true}
                      errors={formik.errors?.ngayCoHieuLuc}
                      touched={formik.touched?.ngayCoHieuLuc}
                    />
                  </Col>
                  <Col xs={4} className="mt-3">
                    <TextValidator
                      lable={lang("CONTRACT.EXPIRATIONDATE")}
                      name="ngayHetHan"
                      readOnly={true}
                      isRequired={!isLongTermContract}
                      value={formik.values?.ngayHetHan || ""}
                      type="date"
                      errors={formik.errors?.ngayHetHan}
                      touched={formik.touched?.ngayHetHan}
                    />
                  </Col>
                  <Col xs={4} className="mt-3">
                    <TextValidator
                      lable={lang("CONTRACT.BASESALARY")}
                      name="luongCoBan"
                      value={convertNumberPrice(formik.values?.luongCoBan)}
                      type="text"
                      readOnly={!isEdit}
                      isRequired
                      onChange={handleChangeMoney}
                      errors={formik.errors?.luongCoBan}
                      touched={formik.touched?.luongCoBan}
                    />
                  </Col>
                  <Col xs={4} className="mt-3">
                    <TextValidator
                      lable={lang("CONTRACT.INSURANCECONTRIBUTION")}
                      name="luongDongBaoHiem"
                      value={convertNumberPrice(formik.values?.luongDongBaoHiem)}
                      type="text"
                      readOnly={!isEdit}
                      onChange={handleChangeMoney}
                    />
                  </Col>
                  <Col xs={4} className="mt-3">
                    <TextValidator
                      lable={lang("CONTRACT.SALARYRATE")}
                      name="tyLeHuongLuong"
                      value={formik.values?.tyLeHuongLuong || ""}
                      type="text"
                      readOnly={!isEdit}
                      onChange={formik.handleChange}
                      touched={formik.touched?.tyLeHuongLuong}
                      errors={formik.errors?.tyLeHuongLuong}
                    />
                  </Col>
                  <Col xs={4} className="mt-3">
                    <Autocomplete
                      lable={lang("CONTRACT.SIGNEDCOMPANYREPRESENTATIVE")}
                      options={[]}
                      isRequired
                      isReadOnly={!isEdit}
                      value={formik.values?.nguoiDaiDienCtyKy || null}
                      name="nguoiDaiDienCtyKy"
                      onChange={(selectedOption) => handleChangeSelect(VARIABLE_STRING.NGUOI_DAI_DIEN_KY, selectedOption)}
                      searchFunction={searchNguoiDaiDienKy}
                      searchObject={{}}
                      errors={formik.errors?.nguoiDaiDienCtyKy}
                      touched={formik.touched?.nguoiDaiDienCtyKy}
                    />
                  </Col>
                  <Col xs={4} className="mt-3">
                    <TextValidator
                      lable={lang("CONTRACT.REPRESENTATIVETITLE")}
                      name="nguoiDaiDienCtyChucDanh"
                      value={formik.values?.nguoiDaiDienCtyChucDanhText || ""}
                      type="text"
                      readOnly={true}
                    />
                  </Col>
                  <Col xs={4} className="mt-3">
                    <Form.Check
                      label={lang("CONTRACT.HAS_AUTHORITY_PAPER")}
                      name="coGiayUyQuyen"
                      checked={formik.values?.coGiayUyQuyen}
                      readOnly={!isEdit}
                      onChange={handleCheckBoxChange}
                      className="checkBox custom-form-check"
                    />
                  </Col>
                  <Col xs={4} className="mt-3">
                    <TextValidator
                      lable={lang("CONTRACT.NOTE")}
                      name="ghiChu"
                      value={formik.values?.ghiChu || ""}
                      type="text"
                      readOnly={!isEdit}
                      onChange={formik.handleChange}
                    />
                  </Col>
                  <Col xs={4} className="mt-3">
                    <UploadFile
                      label="CONTRACT.ATTACHMENTS"
                      setValue={handleFile}
                      isReadOnly={isView}
                      fileValue={{
                        id: formik.values.fileId || "",
                        name: formik.values.fileName || ""
                      }}
                      allowFileTypes={`${FILE_TYPE.PDF},${FILE_TYPE.DOC},${FILE_TYPE.DOCX},${FILE_TYPE.XLS},${FILE_TYPE.XLSX}`}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Row>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-center">
          <Button
            className="btn btn-secondary  btn-sm"
            onClick={handleCLoseAddContractAnnex}
          >
            {lang("BTN.CANCEL")}
          </Button>
          {isEdit ? (
            <Button
              className="btn btn-primary btn-sm"
              type="submit"
            >
              {lang("BTN.SAVE")}
            </Button>
          ) : (
            <Button
              variant="primary"
              className="button-primary btn-sm"
              onClick={(e: any) => {
                e.preventDefault();
                setIsEdit(true);
              }}
            >
              {lang("BTN.EDIT")}
            </Button>
          )}
        </Modal.Footer>
      </Form>
    </Modal>
  );
};
export { AddNewContractAnnex };
