import { FC } from "react";
import { Col, Row } from "react-bootstrap";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { RoleAuth } from "./component/RoleAuth";
import { UnitList } from "./component/UnitList";
import { UserList } from "./component/Dialog/UserList";
import "./Manager.scss";
import useMultiLanguage from "../../hook/useMultiLanguage";

const Manager: FC = () => {
  const { lang } = useMultiLanguage();
  return (
    <Row className="manager spaces mt-16">
      <Tabs defaultActiveKey="role_auth" id="uncontrolled-tab-example">
        <Tab
          eventKey="role_auth"
          title={
            <>
              <i className="bi bi-box-fill pe-2 text-primary"></i>
              <span>{lang("ROLE_AUTH")}</span>
            </>
          }
        >
          <RoleAuth />
        </Tab>
        <Tab
          eventKey="unit"
          title={
            <>
              <i className="bi bi-building pe-2 text-primary"></i>
              <span>{lang("UNIT")}</span>
            </>
          }
        >
          <Col xs={12}>
            <UnitList />
          </Col>
        </Tab>
        <Tab
          eventKey="user"
          title={
            <>
              <i className="bi bi-person-fill pe-2 text-primary"></i>
              <span>{lang("USER")}</span>
            </>
          }
        >
          <Col xs={12}>
            <UserList />
          </Col>
        </Tab>
      </Tabs>
    </Row>
    );
};
export { Manager };
