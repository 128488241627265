import { useContext, useState, useEffect } from "react";
import { checkStatus, convertNumberPrice, exportToFile, formatDateTable } from "../../../../utils/FunctionUtils";
import { exportKhauTru } from "../../../../utils/ExportFileServices";
import { EmployeeProfile, IDeduct } from "../../../models/ProfileModels";
import TableCustom from "../../../../component/table-custom/TableCustom";
import { deleteKhauTru, getDeductByEmployee } from "../../../services/DialogServices";
import { TYPE } from "../../../../utils/Constant";
import { toast } from "react-toastify";
import { SUCCESS_CODE } from "../../../../contract/const/ContractConst";
import { DeductDialog } from "../../Dialog/DeductDialog";
import useMultiLanguage from "../../../../../hook/useMultiLanguage";
import { EXPORT_FILE_NAME } from "../../../../../Constant";
import AppContext from "../../../../../AppContext";
import { LIST_STATUS_ALLOWANCE } from "../../../const/DialogChildConstants";

interface Props {
  isView: boolean;
  employeeProfiles: EmployeeProfile;
  salaryId: string;
}

function Deduct(props: Props) {
  const { lang } = useMultiLanguage();
  const { isView, employeeProfiles, salaryId } = props;

  const [deductInfo, setDeductInfo] = useState<IDeduct>({});
  const [openDeductDialog, setOpenDeductDialog] = useState<boolean>(false);
  const [listDeduct, setListDeduct] = useState<IDeduct[]>([]);
  const { setPageLoading } = useContext(AppContext);

  useEffect(() => {
    updateDataDeduct();
  }, [salaryId])

  const updateDataDeduct = async () => {
    try {
      setPageLoading(true);
      let data = await getDeductByEmployee(salaryId || "");
      setListDeduct(data?.data?.data || []);
    } catch (error) {
      toast.error(lang("GENERAL.ERROR"));
    } finally {
      setPageLoading(false);
    }
  };

  const handleCLoseDeductDialog = () => {
    setOpenDeductDialog(false);
    setDeductInfo({} as IDeduct);
  };

  const handleOpenDeductDialog = (data: any) => {
    setOpenDeductDialog(true);
    setDeductInfo(data);
  };

  const handleDeleteItems = async (ids: string) => {
    try {
      const res = await deleteKhauTru(ids);
      if (res?.data?.code === SUCCESS_CODE) {
        toast.success(lang("GENERAL.DELETE_SUCCESS"));
        updateDataDeduct();
        return true;
      } else toast.error(`${res?.data?.message}`);
    } catch (err) {
      toast.error(lang("GENERAL.ERROR"));
    }
  };

  const deductColumns = [
    {
      name: lang("TABLE.INDEX"),
      field: "stt",
      headerStyle: {
        minWidth: "50px"
      },
      render: (row: any, index: number, STT: number) => <span>{STT}</span>
    },
    {
      name: "Trạng thái",
      field: "trangThai",
      headerStyle: {
        minWidth: "140px",
        textAlign: "center",
      },
      render: (row: any, index: number) => (
        <span 
          className={
            `rounded-5xl spaces px-12 py-4 text-white 
            ${checkStatus(LIST_STATUS_ALLOWANCE, Number(row?.trangThai?.code))}`
          }
        >
          {row?.trangThai?.name}
        </span>
      )
    },
    {
      name: lang("ALLOWANCE.NAME"),
      field: "tenKhoanKhauTru",
      headerStyle: {
        minWidth: "200px"
      },
      render: (row: any) => <div>{row?.tenKhoanKhauTru?.name}</div>
    },
    {
      name: lang("ALLOWANCE.VALUE"),
      field: "giaTri",
      headerStyle: {
        minWidth: "150px"
      },
      render: (row: any) => <span>{convertNumberPrice(row?.giaTri || null)}</span>
    },
    {
      name: lang("GENERAL.NOTE"),
      field: "ghiChu",
      headerStyle: {
        minWidth: "200px"
      }
    },
    {
      name: lang("INPUT.HEALTHINSURANCE.EFFECTIVEDATE"),
      field: "ngayHieuLuc",
      headerStyle: {
        minWidth: "200px"
      },
      render: (row: any) => (
        <span>
          <span>{formatDateTable(row?.hieuLucTuNgay)}</span>
          {row?.hieuLucDenNgay ? <span> {" - " + formatDateTable(row?.hieuLucDenNgay)}</span> : ""}
        </span>
      )
    }
  ];

  return (
    <div className="block-content">
      <TableCustom
        title={lang("GENERAL.DEDUCTIONS")}
        isActionTableTab
        data={listDeduct}
        columns={deductColumns}
        handleDelete={handleDeleteItems}
        justFilter={true}
        notDelete={true}
        buttonAdd={!isView}
        buttonExportExcel={true}
        type={TYPE.MULTILINE}
        noPagination={true}
        handleExportExcel={() => 
          exportToFile({
            exportAPI: () => exportKhauTru(String(salaryId)), 
            fileName: EXPORT_FILE_NAME.KHAU_TRU,
            setPageLoading
          })
        }
        handleOpenDialog={() => handleOpenDeductDialog({})}
        handleDoubleClick={handleOpenDeductDialog}
      />
      {openDeductDialog && (
        <DeductDialog
          handleCLoseDeductDialog={handleCLoseDeductDialog}
          deductInfo={deductInfo}
          isView={isView}
          salaryId={salaryId}
          getListDeduct={updateDataDeduct}
        />
      )}
    </div>
  );
}

export default Deduct;
