import axios from "axios";
import { IAttachedPeople, IEmployeeJoin, IExpenses, IWelfare } from "../model/WelfareModel";
import { paramsConfig } from "../../utils/ParamsUtils";
const API_URL = process.env.REACT_APP_API_URL;

// Phuc-loi
export const searchWalfare = (searchData?: object) => {
  let url = `${API_URL}/phuc-loi/page`;
  return axios.get(url, paramsConfig(searchData || {}));
};

export const createWalfare = (data: IWelfare) => {
  let url = `${API_URL}/phuc-loi`;
  return axios.post(url, data);
};

export const updateWalfare = (data: IWelfare, id: string) => {
  let url = `${API_URL}/phuc-loi/${id}`;
  return axios.put(url, data);
};

export const deleteWalfare = (ids: string[] | string) => {
  let url = `${API_URL}/phuc-loi/ids`;
  return axios.delete(url, paramsConfig({ids: ids.toString()}));
};

//Nhan vien tham gia phuc loi
export const createEmployeeJoinWalfare = (data: IEmployeeJoin) => {
  let url = `${API_URL}/phuc-loi-chi-tiet`;
  return axios.post(url, data);
};

export const updateEmployeeJoinWalfare = (data: IEmployeeJoin, id: string) => {
  let url = `${API_URL}/phuc-loi-chi-tiet/${id}`;
  return axios.put(url, data);
};

export const getEmployeeJoinByWalfareId = (walfareId: string) => {
  let url = `${API_URL}/phuc-loi-chi-tiet/phuc-loi/${walfareId}`;
  return axios.get(url);
};

export const deleteEmployeeJoin = (ids: string[] | string) => {
  let url = `${API_URL}/phuc-loi-chi-tiet/ids`;
  return axios.delete(url, paramsConfig({ids: ids.toString()}));
};

//Khoan chi
export const updateExpensesWalfare = (data: IExpenses, id: string) => {
  let url = `${API_URL}/phuc-loi-khoan-chi/${id}`;
  return axios.put(url, data);
};

export const createExpensesWalfare = (data: IExpenses) => {
  let url = `${API_URL}/phuc-loi-khoan-chi`;
  return axios.post(url, data);
};

export const getExpensesByWalfareId = (walfareId: string) => {
  let url = `${API_URL}/phuc-loi-khoan-chi/phuc-loi/${walfareId}`;
  return axios.get(url);
};

export const deleteExpenses = (ids: string[] | string) => {
  let url = `${API_URL}/phuc-loi-khoan-chi/ids`;
  return axios.delete(url, paramsConfig({ids: ids.toString()}));
};

// Danh sach dinh kem
export const updateAttachPeople = (data: IAttachedPeople, id: string) => {
  let url = `${API_URL}/phuc-loi-tham-gia/${id}`;
  return axios.put(url, data);
};

export const createAttachPeople = (data: IAttachedPeople) => {
  let url = `${API_URL}/phuc-loi-tham-gia`;
  return axios.post(url, data);
};

export const getAttachPeople= (expenseId: string) => {
  let url = `${API_URL}/phuc-loi-tham-gia/phuc-loi-chi-tiet/${expenseId}`;
  return axios.get(url);
};

export const deleteAttachPeople = (ids: string[] | string) => {
  let url = `${API_URL}/phuc-loi-tham-gia/ids`;
  return axios.delete(url, paramsConfig({ids: ids.toString()}));
};