/* eslint-disable react-hooks/exhaustive-deps */
import { useFormik } from "formik";
import { FC, useContext, useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import * as Yup from "yup";
import useMultiLanguage from "../../../hook/useMultiLanguage";
import UploadFile from "../../component/FileUpload/UploadFile";
import { FILE_TYPE } from "../../component/FileUpload/constant";
import { GroupButton } from "../../component/GroupButton";
import { MultiSelectEmployee } from "../../component/dialog/MultiSelectEmployee";
import Autocomplete from "../../component/input-field/Autocomplete";
import { RangeDatePicker } from "../../component/input-field/RangeDatePicker";
import { variantStandardStyle } from "../../component/input-field/StyleComponent";
import TextValidator from "../../component/input-field/TextValidator";
import TableCustom from "../../component/table-custom/TableCustom";
import { TYPE_CATEGORY } from "../../constant";
import { SUCCESS_CODE } from "../../contract/const/ContractConst";
import { searchNguoiDaiDienKy } from "../../contract/services/contractServices";
import { IFile } from "../../profile/models/DialogModels";
import { searchPhongBan_DonVi } from "../../profile/services/DialogServices";
import { getAllDonVi } from "../../profile/services/ProfileServices";
import { getAllSimpleValue, searchAllSimpleValue } from "../../services";
import { getListWorkUnit, searchAllEmployee } from "../../utils/CategoryServices";
import { SEARCH_OBJECT_MAX_SIZE, TYPE, VARIABLE_STRING } from "../../utils/Constant";
import { handleBlurDate, hasRole } from "../../utils/FunctionUtils";
import { INIT_TRANSITION, LIST_TRANSFER_OBJECT, LIST_TRANSFER_STATUS, LIST_TRANSFER_STATUS_CODE, TRANSFER_PEOPLE } from "../const/transitionConst";
import { TransitionInFo, danhSachDieuChuyenInfo } from "../models/transitionModel";
import { addNewTransfer, checkTransfer, editTransfer } from "../services/TransitionServices";
import { convertDataSubmit } from "../utils/FunctionUtils";
import AppContext from "../../../AppContext";
import { ROLE } from "../../../Constant";
import ConfirmDialog from "../../component/ConfirmDialog/ConfirmDialog";

interface IProps {
  transferInfo: TransitionInFo;
  isView: boolean;
  handleCloseDialog: () => void;
  handleCloseAndSearch: () => void;
  handleOpenUpdateDialog: () => void;
}
const TransitionForm: FC<IProps> = (props) => {
  const { lang } = useMultiLanguage();
  const {
    transferInfo,
    handleCloseDialog,
    handleCloseAndSearch,
    handleOpenUpdateDialog,
    isView,
  } = props;

  const [transferList, setTransferList] = useState<danhSachDieuChuyenInfo[]>([]);
  const [totalPage, setTotalPage] = useState<number>();
  const [openEmployeeDialog, setOpenEmployeeDialog] = useState<boolean>(false);
  const [isViewOnly, setIsViewOnly] = useState<boolean>(false);
  const [shouldOpenConfirmAddDialog, setShouldOpenConfirmAddDialog] = useState(false);
  const { setPageLoading } = useContext(AppContext);

  const validationSchema = Yup.object().shape({
    doiTuong: Yup.object()
      .required(lang("VALIDATION.REQUIRE"))
      .nullable(),
    employee: Yup.object()
      .required(lang("VALIDATION.REQUIRE"))
      .nullable(),
    loaiDieuDong: Yup.object()
      .required(lang("VALIDATION.REQUIRE"))
      .nullable(),
    donViQuyetDinh: Yup.object()
      .required(lang("VALIDATION.REQUIRE"))
      .nullable(),
    nguoiKy: Yup.object()
      .required(lang("VALIDATION.REQUIRE"))
      .nullable(),
    coHieuLucTuNgay: Yup.date()
      .required(lang("VALIDATION.REQUIRE"))
      .nullable(),
    coHieuLucDenNgay: Yup.date()
      .min(Yup.ref("coHieuLucTuNgay"), lang("VALIDATION.MINDATE") + lang("GENERAL_EFFECTIVE_DATE_START"))
      .nullable(),
    soQuyetDinh: Yup.string()
      .required(lang("VALIDATION.REQUIRE"))
      .nullable()
      .max(255, lang("VALIDATION.MAX255")),
    donViCongTacMoi: Yup.object()
      .required(lang("VALIDATION.REQUIRE"))
      .nullable(),
    khoaPhongMoi: Yup.object()
      .required(lang("VALIDATION.REQUIRE"))
      .nullable(),
    chucVuMoi: Yup.object()
      .required(lang("VALIDATION.REQUIRE"))
      .nullable(),
    trangThai: Yup.object()
      .required(lang("VALIDATION.REQUIRE"))
      .nullable()
  });

  const handleSubmit = async (values: TransitionInFo) => {
    try {
      const dataSubmit = convertDataSubmit(values);
      let { data } = await checkTransfer(dataSubmit)

      if (data?.code !== SUCCESS_CODE) return toast.error(data?.message);
      if (data?.data) {
        setShouldOpenConfirmAddDialog(Boolean(data?.data))
        return;
      }

      dataSubmit.isOverwrite = false;
      handleAddorUpdate(dataSubmit)
    } catch (err) {
      toast.error(lang("GENERAL.ERROR"));
    } finally {
      setPageLoading(false);
    }
  };

  const handleAddorUpdate = async (dataSubmit: any) => {
    try {
      setPageLoading(true);
      const res = transferInfo.id
        ? await editTransfer(transferInfo.id, dataSubmit)
        : await addNewTransfer(dataSubmit);
      if (res?.data?.code === SUCCESS_CODE) {
        toast.success(
          transferInfo.id
            ? lang("TOAST.EDIT.SUCCESS")
            : lang("TOAST.CREATE.SUCCESS")
        );
        handleCloseAndSearch();
      } else {
        toast.error(`${res?.data?.message}`);
      }
    } catch (err) {
      toast.error(lang("GENERAL.ERROR"));
    } finally {
      setPageLoading(false);
      setShouldOpenConfirmAddDialog(false)
    }
  }

  const handleConfirmedAdd = async () => {
    const dataSubmit = convertDataSubmit(formik.values);
    dataSubmit.isOverwrite = true;
    handleAddorUpdate(dataSubmit)
  }

  const formik = useFormik({
    initialValues: INIT_TRANSITION,
    validationSchema,
    onSubmit: handleSubmit
  })

  useEffect(() => {
    getAllTranferList();
  }, [])

  useEffect(() => {
    if (transferInfo?.id) {
      formik.setValues({
        ...transferInfo, loaiDieuDong: {
          id: transferInfo?.loaiDieuDongId,
          value: transferInfo?.loaiDieuDongText
        }
      });
      setIsViewOnly(transferInfo?.trangThai?.code === LIST_TRANSFER_STATUS_CODE.COMPLETED);
    }
    formik.setFieldValue(VARIABLE_STRING.DOI_TUONG, LIST_TRANSFER_OBJECT.find(option => option.code === TRANSFER_PEOPLE.CA_NHAN));
  }, [transferInfo]);

  const handleChangeSelect = (name: string, value: any) => {
    switch (name) {
      case VARIABLE_STRING.DOI_TUONG:  
        formik.setValues(INIT_TRANSITION)
        setTransferList([])
        formik.setFieldValue(name, value);
        break;
      case VARIABLE_STRING.DON_VI_CONG_TAC_MOI:
        formik.setFieldValue("khoaPhongMoi", null);
        formik.setFieldValue(VARIABLE_STRING.DON_VI_CONG_TAC_MOI, value);
        break;
      default:
        formik.setFieldValue(name, value);
        break;
    }
  };

  const handleEmployeeChange = (name: string, employeeInfo: any) => {
    let addInfoHasUserRole = hasRole(ROLE.USER)
      ? {
        donViCongTacMoi: {
          id: employeeInfo.donViCongTacId || "",
          name: employeeInfo.donViCongTacText || ""
        },
        donViQuyetDinh: {
          id: employeeInfo.donViCongTacId || "",
          name: employeeInfo.donViCongTacText || ""
        }
      }
      : {};

    formik.setValues({
      ...formik.values,
      employee: employeeInfo,
      employeeId: employeeInfo.id,
      fullName: employeeInfo.name,
      code: employeeInfo.maNhanVien || "",
      viTriCongViecText: employeeInfo.viTriCongViecText || "",
      viTriCongViecId: employeeInfo.viTriCongViecId || "",
      chucVuCuId: employeeInfo.chucVuId || "",
      chucVuCuText: employeeInfo.chucVuText || "",
      donViCongTacText: employeeInfo.donViCongTacText || "",
      donViCongTacId: employeeInfo.donViCongTacId || "",
      khoaPhongId: employeeInfo.phongBanId || "",
      khoaPhongText: employeeInfo.phongBanText || "",
      ...addInfoHasUserRole
    });
  };

  const hanldeChangeCheckBox = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;
    formik.setFieldValue(name, checked);
  };

  const getAllTranferList = async (searchData?: any) => {
  };

  const handleOpenAddEmployeeDialog = () => {
    setOpenEmployeeDialog(true)
  }

  const handleCloseAddEmployeeDialog = () => [
    setOpenEmployeeDialog(false)
  ]

  const TransferListColumn = [
    {
      name: "STT",
      field: "",
      render: (row: any, index: number, stt: number) => <span>{stt}</span>
    },
    {
      name: lang("TRANSFER.CANBOCCVC"),
      field: "name",
      headerStyle: {
        minWidth: "150px",
      },
      cellStyle: {
        minWidth: "150px",
        textAlign: "left",
      },
    },
    {
      name: lang("INPUT.WORKPROCESS.UNIT.OLD"),
      field: "donViCongTac",
      headerStyle: {
        minWidth: "80px"
      },
      render: (row: any) => <span>{row?.donViCongTacText}</span>
    },
    {
      name: lang("GENERAL.EMPLOYEE.POSITION"),
      field: "chucVu",
      headerStyle: {
        minWidth: "80px"
      },
      render: (row: any) => <span>{row?.chucVuText}</span>
    },
    {
      name: lang("RESIGN.NEW_ORGANIZATION"),
      field: "donViCongTacMoi",
      headerStyle: {
        minWidth: "80px"
      },
      cellStyle: {
        textAlign: "left",
      },
      render: (rowData: any, rowId: any) =>
        <Autocomplete
          name="donViCongTacMoi"
          searchFunction={getListWorkUnit}
          searchObject={{}}
          onChange={(value) => handleChangeSelect(value, VARIABLE_STRING.DON_VI_CONG_TAC_MOI)}
          value={rowData?.donViCongTacMoi || null}
          options={[]}
          styles={variantStandardStyle}
        />
    },
    {
      name: lang("INPUT_APPOINTMENT_NEW_JOB_POSITION"),
      field: "chucVuMoi",
      headerStyle: {
        minWidth: "150px"
      },
      cellStyle: {
        textAlign: "left",
      },
      render: (rowData: any, rowId: any) => {
        return (
          <Autocomplete
            isReadOnly={isView}
            searchFunction={getAllSimpleValue}
            searchObject={TYPE_CATEGORY.chucDanh}
            options={[]}
            value={rowData?.chucVuMoi || null}
            name="chucVuMoi"
            onChange={(value) => handleChangeSelect(value, VARIABLE_STRING.CHUC_VU)}
            getOptionLabel={(option) => option?.value}
            styles={variantStandardStyle}
          />
        )
      }
    },
  ]

  const handleUploadFile = (data: IFile) => {
    formik.setFieldValue("fileId", data.id);
    formik.setFieldValue("fileName", data.name);
  }

  return (
    <div className="transition-form">
      <Form onSubmit={formik.handleSubmit}>
        <Row className="contract-header header-bo spaces py-12">
          <Col xs={12}>
            <div className="profile-title">
              <GroupButton type="btn-back" handleClose={handleCloseDialog} />
              {isView && (
                <GroupButton
                  type="btn-edit"
                  handleSaveEdit={handleOpenUpdateDialog}
                />
              )}
              {!isView && (
                <GroupButton
                  type="btn-save"
                  handleClose={handleCloseDialog}
                />
              )}
            </div>
          </Col>
        </Row>
        <div className="form-container bg-white">
          <div className="p-4 w-100">
            <div className="sub-title-form">{transferInfo?.id ? lang("TRANSFER.EDIT") : lang("TRANSFER.ADD")}</div>
            <Row>
              <Col xs={12} md={4} lg={3} xxl={2}>
                <Autocomplete
                  isRequired
                  lable={lang("TRANSFER_TYPE_OF_GROUP")}
                  options={LIST_TRANSFER_OBJECT}
                  isReadOnly={isView}
                  value={formik.values?.doiTuong || null}
                  name={VARIABLE_STRING.DOI_TUONG}
                  onChange={(selectedOption) => handleChangeSelect(VARIABLE_STRING.DOI_TUONG, selectedOption)}
                  isClearable={false}
                  touched={formik.touched?.doiTuong}
                  errors={formik.errors?.doiTuong}
                />
              </Col>
            </Row>
            {formik?.values?.doiTuong?.code === TRANSFER_PEOPLE.CA_NHAN && (
              <Row>
                <Col xs={12} className="pt-4">
                  <span className="fs-3 fw-bold">Thông tin cán bộ CCVC</span>
                </Col>
                <Col xs={12} md={4} lg={4} xxl={3} className="spaces pt-6">
                  <Autocomplete
                    lable={lang("INPUT_APPOINTMENT_NAME")}
                    isReadOnly={isView}
                    searchFunction={searchAllEmployee}
                    searchObject={{ ...SEARCH_OBJECT_MAX_SIZE, isDangLamViec: true }}
                    options={[]}
                    isRequired
                    value={formik.values?.employee || ""}
                    name="employee"
                    maxMenuHeight={270}
                    onChange={(selectedOption) => handleEmployeeChange("employee", selectedOption)}
                    getOptionLabel={(option) => `${option?.maNhanVien} - ${option?.name}`}
                    errors={formik.errors?.employee}
                    touched={formik.touched?.employee}
                  />
                </Col>
                <Col xs={12} md={4} lg={2} xxl={1} className="spaces pt-6">
                  <TextValidator
                    name="code"
                    lable={lang("PROFILE.CODE")}
                    type="text"
                    value={formik.values?.code}
                    readOnly={true}
                  />
                </Col>
                {!hasRole(ROLE.USER) && (
                  <Col xs={12} md={4} lg={3} xxl={2} className="spaces pt-6">
                    <TextValidator
                      name="donViCongTacText"
                      lable={lang("INPUT.WORKPROCESS.UNIT.OLD")}
                      type="text"
                      value={formik.values?.donViCongTacText}
                      readOnly={true}
                    />
                  </Col>
                )}
                <Col xs={12} md={4} lg={3} xxl={3} className="spaces pt-6">
                  <TextValidator
                    name="khoaPhongText"
                    lable={lang("INPUT.WORKPROCESS.DEPARTMENT.OLD")}
                    type="text"
                    value={formik.values?.khoaPhongText}
                    readOnly={true}
                  />
                </Col>
                <Col xs={12} md={4} lg={3} xxl={3} className="spaces pt-6">
                  <TextValidator
                    name="chucVuCuText"
                    lable={lang("RESIGN.OLDPOSITION")}
                    type="text"
                    value={formik.values?.chucVuCuText}
                    readOnly={true}
                  />
                </Col>
              </Row>
            )}
            <Row>
              <Col xs={12} className="pt-4">
                <span className="fs-3 fw-bold">Thông tin điều chuyển - luân chuyển</span>
              </Col>
              <Col xs={12} md={4} lg={2} xxl={2} className="spaces pt-6">
                <Autocomplete
                  lable={lang("TRANSFER.LOAIDIEUDONG")}
                  isReadOnly={isView}
                  options={[]}
                  value={formik.values?.loaiDieuDong || null}
                  name="loaiDieuDong"
                  isRequired
                  onChange={(selectedOption) => handleChangeSelect("loaiDieuDong", selectedOption)}
                  searchFunction={searchAllSimpleValue}
                  searchObject={{ ...SEARCH_OBJECT_MAX_SIZE, type: TYPE_CATEGORY.loaiDieuDong }}
                  errors={formik.errors?.loaiDieuDong}
                  touched={formik.touched?.loaiDieuDong}
                  getOptionLabel={(option) => option?.value}
                />
              </Col>
              {!hasRole(ROLE.USER) && (
                <Col xs={12} md={4} lg={3} xxl={2} className="spaces pt-6">
                  <Autocomplete
                    name="donViCongTacMoi"
                    lable={lang("RESIGN.NEW_ORGANIZATION")}
                    searchFunction={getAllDonVi}
                    searchObject={SEARCH_OBJECT_MAX_SIZE}
                    onChange={(value) => handleChangeSelect(VARIABLE_STRING.DON_VI_CONG_TAC_MOI, value)}
                    value={formik.values.donViCongTacMoi || null}
                    isRequired
                    isReadOnly={isView}
                    options={[]}
                    errors={formik?.errors?.donViCongTacMoi}
                    touched={formik?.touched?.donViCongTacMoi}
                  />
                </Col>
              )}
              <Col xs={12} md={4} lg={4} xxl={2} className="spaces pt-6">
                <Autocomplete
                  lable={lang("INPUT.WORKPROCESS.DEPARTMENT.NEW")}
                  isReadOnly={isView}
                  searchFunction={searchPhongBan_DonVi}
                  searchObject={{ id: formik.values?.donViCongTacMoi?.id }}
                  options={[]}
                  isRequired
                  value={formik.values?.khoaPhongMoi || null}
                  name="khoaPhongMoi"
                  urlData="data.data"
                  maxMenuHeight={220}
                  onChange={(selectedOption) => handleChangeSelect("khoaPhongMoi", selectedOption)}
                  errors={formik.errors?.khoaPhongMoi}
                  touched={formik.touched?.khoaPhongMoi}
                  dependencies={[formik.values?.donViCongTacMoi]}
                />
              </Col>
              {formik?.values?.doiTuong?.code === TRANSFER_PEOPLE.CA_NHAN && (
                <Col xs={12} md={4} lg={3} xxl={2} className="spaces pt-6">
                  <Autocomplete
                    lable={lang("INPUT_APPOINTMENT_NEW_JOB_POSITION")}
                    isReadOnly={isView}
                    searchFunction={getAllSimpleValue}
                    searchObject={TYPE_CATEGORY.chucDanh}
                    options={[]}
                    isRequired
                    value={formik.values?.chucVuMoi || null}
                    maxMenuHeight={220}
                    name="chucVuMoi"
                    onChange={(selectedOption) => handleChangeSelect("chucVuMoi", selectedOption)}
                    errors={formik.errors?.chucVuMoi}
                    touched={formik.touched?.chucVuMoi}
                    getOptionLabel={(option) => option?.value}
                  />
                </Col>
              )}
              <Col xs={12} md={4} lg={3} xxl={2} className="spaces pt-6">
                <UploadFile
                  isReadOnly={isView}
                  label="CONTRACT.ATTACHMENTS"
                  setValue={handleUploadFile}
                  fileValue={{
                    id: formik.values.fileId || "",
                    name: formik.values.fileName || ""
                  }}
                  allowFileTypes={`${FILE_TYPE.PDF},${FILE_TYPE.DOC},${FILE_TYPE.DOCX},${FILE_TYPE.XLS},${FILE_TYPE.XLSX}`}
                />
              </Col>
              <Col xs={12} md={4} lg={2} xxl={2} className="spaces pt-6">
                <Form.Check
                  label={lang("CONTRACT.PAPER_AUTHORITY")}
                  checked={formik.values?.uyQuyen}
                  onChange={hanldeChangeCheckBox}
                  className="custom-form-check"
                  readOnly={isView}
                  disabled={isView}
                  name="uyQuyen"
                />
              </Col>
              <Col xs={12} md={4} lg={4} xxl={3} className="spaces pt-6">
                <RangeDatePicker
                  isRequired
                  label={lang("ALLOWANCE.TIME_EFFECT")}
                  startDateName="coHieuLucTuNgay"
                  endDateName="coHieuLucDenNgay"
                  isView={isView}
                  handleChange={formik.handleChange}
                  value={formik.values}
                  setFieldValue={formik.setFieldValue}
                  touch={formik.touched}
                  errors={formik.errors}
                />
              </Col>
              <Col xs={12} md={4} lg={3} xxl={2} className="spaces pt-6">
                <Autocomplete
                  isRequired={true}
                  lable={lang("TRANSFER.DONVIQUYETDINH")}
                  isReadOnly={isView || hasRole(ROLE.USER)}
                  options={[]}
                  value={formik.values?.donViQuyetDinh || null}
                  name="donViQuyetDinh"
                  onChange={(selectedOption) => handleChangeSelect("donViQuyetDinh", selectedOption)}
                  searchFunction={getAllDonVi}
                  searchObject={SEARCH_OBJECT_MAX_SIZE}
                  touched={formik.touched?.donViQuyetDinh}
                  errors={formik.errors?.donViQuyetDinh}
                />
              </Col>
              <Col xs={12} md={4} lg={3} xxl={2} className="spaces pt-6">
                <TextValidator
                  name="ngayQuyetDinh"
                  lable={lang("INPUT.WORKPROCESS.DECISION.DATE")}
                  type="date"
                  value={formik.values?.ngayQuyetDinh}
                  readOnly={isView}
                  onChange={formik.handleChange}
                  onBlur={() => handleBlurDate(formik.setFieldValue, formik.values?.ngayQuyetDinh, "ngayQuyetDinh")}
                  errors={formik?.errors?.ngayQuyetDinh}
                  touched={formik?.touched?.ngayQuyetDinh}
                />
              </Col>
              <Col xs={12} md={4} lg={3} xxl={1} className="spaces pt-6">
                <TextValidator
                  name="soQuyetDinh"
                  lable={lang("INPUT_APPOINTMENT_NUMBER")}
                  type="text"
                  value={formik.values?.soQuyetDinh}
                  readOnly={isView}
                  isRequired
                  onChange={formik.handleChange}
                  errors={formik?.errors?.soQuyetDinh}
                  touched={formik?.touched?.soQuyetDinh}
                />
              </Col>
              <Col xs={12} md={4} lg={3} xxl={2} className="spaces pt-6">
                <Autocomplete
                  lable={lang("TRANSFER.NGUOIKI")}
                  isReadOnly={isView}
                  options={[]}
                  isRequired
                  value={formik.values?.nguoiKy || null}
                  name="nguoiKy"
                  searchFunction={searchNguoiDaiDienKy}
                  searchObject={{}}
                  onChange={(selectedOption) => handleChangeSelect("nguoiKy", selectedOption)}
                  errors={formik.errors?.nguoiKy}
                  touched={formik.touched?.nguoiKy}
                />
              </Col>
              <Col xs={12} md={4} lg={3} xxl={2} className="spaces pt-6">
                <Autocomplete
                  lable={lang("GENERAL.STATUS")}
                  isReadOnly={isViewOnly ? isViewOnly : isView}
                  options={LIST_TRANSFER_STATUS}
                  value={formik.values?.trangThai || null}
                  name="trangThai"
                  menuPlacement="top"
                  isRequired
                  onChange={(selectedOption) => handleChangeSelect("trangThai", selectedOption)}
                  errors={formik.errors?.trangThai}
                  touched={formik.touched?.trangThai}
                />
              </Col>
              <Col xs={12} className="spaces pt-6">
                <TextValidator
                  className="h-auto-input"
                  lable={lang("ALLOWANCE.NOTE")}
                  name="ghiChu"
                  value={formik.values.ghiChu}
                  type="text"
                  readOnly={isView}
                  rows={2}
                  onChange={formik.handleChange}
                  as={TYPE.TEXTAREA}
                />
              </Col>
            </Row>
          </div>
        </div>
      </Form>
      {formik?.values?.doiTuong?.code === TRANSFER_PEOPLE.TAP_THE && (
        <Row className="bg-light p-4">
          <Col xs={12}>
            <TableCustom
              title={lang("ISSUE.LIST_EMPLOYEES")}
              data={transferList}
              columns={TransferListColumn}
              fixedColumnsCount={2}
              totalPages={totalPage}
              handleOpenDialog={handleOpenAddEmployeeDialog}
              isActionTableTab
              buttonAdd={!isView}
              buttonExportExcel={true}
              notDelete={false}
              noToolbar={true}
              justFilter={true}
              type={TYPE.MULTILINE}
              noPagination={true}
            />
          </Col>
        </Row>
      )}
      {openEmployeeDialog &&
        <MultiSelectEmployee
          listChecked={transferList}
          setListChecked={setTransferList}
          handleClose={handleCloseAddEmployeeDialog}
        />}

      {shouldOpenConfirmAddDialog && (
        <ConfirmDialog
          show={shouldOpenConfirmAddDialog}
          title={"Xác nhận thêm mới"}
          message={"Người này đang có luân chuyển có thời gian dài hạn. Bạn có muốn gia hạn thời gian đó không?"}
          yes={lang("BTN.CONFIRM")}
          onYesClick={handleConfirmedAdd}
          cancel={lang("BTN.CANCEL")}
          onCancelClick={() => setShouldOpenConfirmAddDialog(false)}
        />
      )}

    </div>
  );
};
export { TransitionForm };
