import { useFormik } from "formik";
import { FC, useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { SUCCESS_CODE } from "../../../contract/const/ContractConst";
import { IuserByUnit } from "../../model/ManagerModel";
import { addNewUser, getListRole, getListUnit, updateUser } from "../../services/ManagerServices";
import Autocomplete from "../../../component/input-field/Autocomplete";
import { searchByPage as getAllProfile } from "../../../profile/services/ProfileServices";
import TextValidator from "../../../component/input-field/TextValidator";
import { INIT_USER } from "../const/ManagerCost";
import useMultiLanguage from "../../../../hook/useMultiLanguage";
import { ROLE } from "../../../../Constant";
import { REGEX, SEARCH_OBJECT_MAX_SIZE, VARIABLE_STRING } from "../../../utils/Constant";

interface IProps {
  handleCloseDialog: () => void;
  userInfo: IuserByUnit;
  handleReload: () => Promise<void>;
}
const UserDialog: FC<IProps> = (props) => {
  const { handleCloseDialog, userInfo, handleReload } = props;
  const { lang } = useMultiLanguage();
  const [isUser, setIsUser] = useState<boolean>(false);

  const validationSchema = Yup.object().shape({
    employee: isUser ? Yup.object().required(lang("VALIDATION.REQUIRE")).nullable() : Yup.object().nullable(),
    user: Yup.object().shape({
      organization: Yup.object().required(lang("VALIDATION.REQUIRE")).nullable(),
      email: Yup.string().email(lang("VALIDATION.EMAILINVALID")).nullable(),
      phone: Yup.string().matches(REGEX.CHECK_PHONE, lang("VALIDATION.ISPHONE")).nullable(),
      password: !userInfo?.user?.id
        ? Yup.string().required(lang("VALIDATION.REQUIRE")).min(8, lang("REQUIRE_8")).nullable()
        : Yup.mixed().nullable(),
      confirmPassword: !userInfo?.user?.id
        ? Yup.string()
            .required(lang("VALIDATION.REQUIRE"))
            .oneOf([Yup.ref("password"), null], lang("PASSWORD_INVALID"))
            .nullable()
        : Yup.mixed().nullable(),
      username: Yup.string().required(lang("VALIDATION.REQUIRE")).nullable(),
      roles: Yup.array().of(Yup.object()).required(lang("VALIDATION.REQUIRE")).nullable()
    })
  });
  const handleSubmit = async (values: IuserByUnit) => {
    const data: any = {
      ...values,
      user: {
        ...values.user,
        accountNonExpired: true,
        accountNonLocked: true,
        active: true,
        changePassword: userInfo?.user?.id ? false : true,
        credentialsNonExpired: true,
        justCreated: true,
        roles: [values.user?.roles[0]?.name]
      },
      orgId: values?.user?.organization?.id,
      employeeId: formik.values?.employee?.id
    };
    try {
      const res = userInfo?.user?.id ? await updateUser(userInfo?.user?.id, data) : await addNewUser(data);
      if (res?.data?.code === SUCCESS_CODE) {
        toast.success(userInfo.id ? lang("TOAST.EDIT.SUCCESS") : lang("TOAST.CREATE.SUCCESS"));
        handleReload();
        handleCloseDialog();
      } else {
        toast.error(`${res?.data?.message}`);
      }
    } catch (err) {
      toast.error(lang("GENERAL.ERROR"));
    }
  };

  const formik = useFormik({
    initialValues: INIT_USER,
    validationSchema,
    onSubmit: handleSubmit
  });

  useEffect(() => {
    if (userInfo?.user?.id) {
      formik?.setValues(userInfo);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo]);

  useEffect(() => {
    if (formik?.values?.user?.roles?.[0]?.name === ROLE.USER) {
      setIsUser(true);
    } else {
      setIsUser(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik?.values?.user?.roles]);

  const handleChangeSelect = (name: string, data: any) => {
    switch (name) {
      case VARIABLE_STRING.USER_ROLES:
        formik.setFieldValue(name, data);
        formik.setFieldValue("employee", null);
        break;
      default:
        formik.setFieldValue(name, data);
    }
  };

  return (
    <>
      <Modal
        show={true}
        onHide={handleCloseDialog}
        size="xl"
        centered
      >
        <Form onSubmit={formik.handleSubmit}>
          <Modal.Header closeButton>
            <Modal.Title>
              {lang(!userInfo?.user?.id ? "CATEGORY.ADD" : "CATEGORY.EDIT")}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row className="px-6">
              <Col xs={4}>
                <TextValidator
                  name="user.email"
                  lable={lang("USER.EMAIL")}
                  type={"text"}
                  onChange={formik.handleChange}
                  value={formik?.values?.user?.email}
                  errors={formik?.errors?.user?.email}
                  touched={formik?.touched?.user?.email}
                />
              </Col>
              <Col xs={4}>
                <TextValidator
                  name="user.phone"
                  lable={lang("USER.PHONE")}
                  type={"text"}
                  onChange={formik.handleChange}
                  value={formik?.values?.user?.phone}
                  errors={formik?.errors?.user?.phone}
                  touched={formik?.touched?.user?.phone}
                />
              </Col>
              <Col xs={4}>
                <Autocomplete
                  isRequired
                  lable={lang("UNIT")}
                  options={[]}
                  value={formik?.values?.user?.organization || null}
                  name="user.organization"
                  onChange={(selectedOption) => handleChangeSelect("user.organization", selectedOption)}
                  searchFunction={getListUnit}
                  searchObject={{}}
                  errors={formik?.errors?.organization}
                  touched={formik?.touched?.organization}
                />
              </Col>
            </Row>
            <Row className="px-6">
              <Col
                xs={4}
                className="pt-4"
              >
                <TextValidator
                  name="user.username"
                  lable={lang("USER.ACCOUNT")}
                  type={"text"}
                  onChange={formik.handleChange}
                  value={formik?.values?.user?.username}
                  errors={formik?.errors?.user?.username}
                  touched={formik?.touched?.user?.username}
                  isRequired
                />
              </Col>
              {!userInfo?.user?.id && (
                <>
                  <Col
                    xs={4}
                    className="pt-4"
                  >
                    <TextValidator
                      name="user.password"
                      lable={lang("USER.PASSWORD")}
                      type={"password"}
                      onChange={formik.handleChange}
                      value={formik?.values?.user?.password}
                      touched={formik?.touched?.user?.password}
                      errors={formik?.errors?.user?.password}
                      isRequired
                    />
                  </Col>
                  <Col
                    xs={4}
                    className="pt-4"
                  >
                    <TextValidator
                      name="user.confirmPassword"
                      lable={lang("USER.RE_PASSWORD")}
                      type={"password"}
                      onChange={formik.handleChange}
                      value={formik?.values?.user?.confirmPassword}
                      touched={formik?.touched?.user?.confirmPassword}
                      errors={formik?.errors?.user?.confirmPassword}
                      isRequired
                    />
                  </Col>
                </>
              )}

              <Col
                xs={4}
                className="pt-4"
              >
                <Autocomplete
                  isRequired
                  lable={lang("AUTH")}
                  options={[]}
                  value={formik?.values?.user?.roles?.[0] || null}
                  name="user.roles"
                  onChange={(selectedOption) => handleChangeSelect("user.roles", [selectedOption])}
                  searchFunction={getListRole}
                  searchObject={{}}
                  errors={formik?.errors?.user?.roles}
                  touched={formik?.touched?.user?.roles}
                />
              </Col>

              {isUser && (
                <Col
                  xs={4}
                  className="pt-4"
                >
                  <Autocomplete
                    isRequired
                    lable={lang("SELECT.EMPLOYEE")}
                    options={[]}
                    value={formik?.values?.employee || null}
                    name="employee"
                    onChange={(selectedOption) => handleChangeSelect("employee", selectedOption)}
                    searchFunction={getAllProfile}
                    searchObject={SEARCH_OBJECT_MAX_SIZE}
                    errors={formik?.errors?.employee}
                    touched={formik?.touched?.employee}
                  />
                </Col>
              )}
            </Row>
          </Modal.Body>
          <Modal.Footer className="d-flex justify-content-center">
            <Button
              className="btn btn-secondary  btn-sm"
              onClick={handleCloseDialog}
            >
              {lang("BTN.CANCEL")}
            </Button>
            <Button
              className="btn btn-primary btn-sm"
              type="submit"
            >
              {lang("BTN.SAVE")}
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};
export { UserDialog };
