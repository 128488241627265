import { ILang } from "../../../hook/useMultiLanguage";
import { TYPE } from "../../utils/Constant";
import { IDispatchBook } from "../models/dispatchBookModel";

export const DISPATCH_BOOK_FORMS = [
  {
    code: 1,
    name: "Công văn đến"
  },
  {
    code: 2,
    name: "Công văn đi"
  }
];

export const DISPATCH_BOOK_EXPORT_WORD_CODES = {
  DISPATCH_ARRIVED: 1,
  DISPTACH_SEND: 2
}

export const DISPATCH_BOOK_EXPORT_WORD_LIST = [
  {
    code: DISPATCH_BOOK_EXPORT_WORD_CODES.DISPATCH_ARRIVED,
    name: "Sổ theo dõi công văn đến"
  },
  {
    code: DISPATCH_BOOK_EXPORT_WORD_CODES.DISPTACH_SEND,
    name: "Sổ theo dõi công văn đi"
  }
];

export const ListSearch = (lang: ILang) => {
  return [
    {
      name: lang("DISPATCH_BOOK.DAY"),
      field: "ngayNhanGui",
      type: TYPE.DATE
    },
    {
      name: lang("DISPATCH_BOOK.NUMBER"),
      field: "soCongVan",
      type: TYPE.TEXT
    },
    {
      name: lang("DISPATCH_BOOK.AGENCY_AND_SENDING_DEPARTMENT"),
      field: "boPhanGui",
      type: TYPE.TEXT
    },
    {
      name: lang("DISPATCH_BOOK.AGENCY_AND_RECEIVE_DEPARTMENT"),
      field: "boPhanNhan",
      type: TYPE.TEXT
    },
    {
      name: lang("DISPATCH_BOOK.PEOPLE_RECEIVE"),
      field: "nguoiNhan",
      type: TYPE.TEXT
    },
    {
      name: lang("DISPATCH_BOOK.PEOPLE_SEND"),
      field: "nguoiGui",
      type: TYPE.TEXT
    },
    {
      name: lang("DISPATCH_BOOK.FORM"),
      field: "dangCongVan",
      type: TYPE.SELECT,
      listOption: DISPATCH_BOOK_FORMS
    }
  ];
};

export const INIT_DISPATCH_BOOK: IDispatchBook = {
  boPhanGui: "",
  boPhanNhan: "",
  dangCongVan: null,
  ghiChu: "",
  ngayNhanGui: "",
  nguoiGui: "",
  nguoiNhan: "",
  noiDungCongVan: "",
  soBan: null,
  soCongVan: ""
};
