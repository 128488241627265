import { formatDateTable } from "../../../utils/FunctionUtils";

export const QualificationColumn = () => {
  return [
    {
      name: "STT",
      field: "stt",
      headerStyle: {
        minWidth: "40px"
      },
      render: (row: any, index: number, numericalOrder: number) => (
        <span>{numericalOrder}</span>
      )
    },
    {
      name: "Từ ngày",
      field: "tuNgay",
      headerStyle: {
        minWidth: "90px"
      },
      render: (row: any) => <span>{formatDateTable(row?.tuNgay)}</span>
    },
    {
      name: "Đến ngày",
      field: "denNgay",
      headerStyle: {
        minWidth: "90px"
      },
      render: (row: any) => <span>{formatDateTable(row?.denNgay)}</span>
    },
    {
      name: "Cơ sở đào tạo",
      field: "coSoDaoTaoText",
      headerStyle: {
        minWidth: "250px"
      },
      cellStyle: {
        textAlign: "left"
      },
      render: (row: any) => <span>{row?.coSoDaoTaoKhac ? row?.coSoDaoTaoKhac : row?.coSoDaoTaoText }</span>
    },
    {
      name: "Chuyên ngành đào tạo",
      field: "chuyenNganhDaoTaoText",
      headerStyle: {
        minWidth: "200px"
      },
      cellStyle: {
        textAlign: "left"
      },
      render: (row: any) => <span>{row?.chuyenNganhDaoTaoKhac ? row?.chuyenNganhDaoTaoKhac : row?.chuyenNganhDaoTaoText }</span>
    },
    {
      name: "Hình thức đào tạo",
      field: "hinhThucDaoTao",
      headerStyle: {
        minWidth: "200px"
      },
      cellStyle: {
        textAlign: "left"
      },
      render: (row: any) => <span>{row?.hinhThucDaoTao?.name}</span>
    },
    {
      name: "Tốt nghiệp loại",
      field: "xepLoai",
      headerStyle: {
        minWidth: "150px"
      },
      render: (row: any) => <span>{row?.xepLoai?.name}</span>
    }
  ];
};
