import { useFormik } from "formik";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import Autocomplete from "../../component/input-field/Autocomplete";
import TextValidator from "../../component/input-field/TextValidator";
import { TYPE_CATEGORY } from "../../constant";
import { SUCCESS_CODE } from "../../contract/const/ContractConst";
import { getAllSimpleValue } from "../../services";
import { TYPE, VARIABLE_STRING } from "../../utils/Constant";
import { EmployeeProfile } from "../models/ProfileModels";
import { getAllLichSuLuongById, updateBank } from "../services/DialogServices";
import useMultiLanguage from "../../../hook/useMultiLanguage";
import { IBank } from "../models/DialogModels";
import { INIT_BANK } from "../const/DialogConstant";
import { REF_TAB } from "../const/ProfileConst";
import AppContext from "../../../AppContext";
import TableCustom from "../../component/table-custom/TableCustom";
import { exportToFile, formatDateTable } from "../../utils/FunctionUtils";
import { exportLichSuLuong } from "../../utils/ExportFileServices";
import { EXPORT_FILE_NAME } from "../../../Constant";
import SalaryDevelopmentDialog from "./Dialog/SalaryDevelopmentDialog";

type IProps = {
  identify: string;
  activeTab: string;
  isView: boolean;
  employeeProfiles: EmployeeProfile;
  handleGetEmployee: (id: string) => Promise<void>;
  formikRef: any;
  handleToggleIsView: () => void;
};

export const SalaryInformation: React.FC<IProps> = (props) => {
  const { formikRef, identify, isView, employeeProfiles, handleGetEmployee, activeTab, handleToggleIsView } = props;
  const { lang } = useMultiLanguage();
  const { setPageLoading } = useContext(AppContext);
  const [listData, setListData] = useState([]);
  const [shouldOpenCreateDialog, setShouldOpenCreateDialog] = useState<boolean>(false);
  const [salaryInfo, setSalaryInfo] = useState({});
  
  const updateDataSalaryDevelop = useCallback(async () => {
    if(identify && activeTab === REF_TAB.TT_LUONG) {
      try {
        const { data, status } = await getAllLichSuLuongById(identify);
        if (status === SUCCESS_CODE && data?.code === SUCCESS_CODE) {
          setListData(data?.data || []);
        }
      } catch (error) {
        toast.error(lang("GENERAL.ERROR"));
      }
    }
  }, [activeTab, identify, lang]);

  useEffect(() => {
    updateDataSalaryDevelop();
  }, [identify, activeTab, updateDataSalaryDevelop]);

  const handleFormSubmit = async (values: IBank) => {
    try {
      setPageLoading(true);
      const dataSubmit: IBank = {
        ...values,
        nganHangId: values?.nganHang?.id || "",
        nganHangText: String(values?.nganHang?.value || "")
      };
      if (!employeeProfiles?.id) return;
      const data = await updateBank(dataSubmit, employeeProfiles?.id);
      if (data?.data?.code === SUCCESS_CODE) {
        toast.success(lang("TOAST.EDIT.SUCCESS"));
        employeeProfiles?.id && handleGetEmployee(employeeProfiles.id);
        handleToggleIsView();
      }
    } catch (err) {
      toast.error(lang("GENERAL.ERROR"));
    } finally {
      setPageLoading(false);
    }
  };

  const formik = useFormik({
    initialValues: INIT_BANK,
    onSubmit: handleFormSubmit
  });

  const handleChangeSelect = (name: string, value: any) => {
    formik.setFieldValue(name, value);
  };

  useEffect(() => {
    if (employeeProfiles?.id) {
      formik.setValues({
        ...formik.values,
        chiNhanh: employeeProfiles?.chiNhanh || "",
        nganHang: employeeProfiles?.nganHang || null,
        tkNganHang: employeeProfiles?.tkNganHang || ""
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [employeeProfiles]);

  useEffect(() => {
    if (formikRef) {
      formikRef.current = formik;
    }
  }, [formik, formikRef]);
  
  const handleCloseCreateDialog = () => {
    setShouldOpenCreateDialog(false);
    setSalaryInfo({});
  };

  const handleOpenCreateDialog = (data: any) => {
    setShouldOpenCreateDialog(true);
    setSalaryInfo(data);
  };

  const columns = [
    {
      name: lang("TABLE.INDEX"),
      field: "stt",
      headerStyle: {
        minWidth: "50px"
      },
      render: (_row: any, _index: number, STT: number) => <span>{STT}</span>
    },
    {
      name: lang("INPUT.DEPARTMENTS"),
      field: "phongBanText",
      headerStyle: {
        minWidth: "200px"
      }
    },
    {
      name: lang("JOB.POSITION"),
      field: "chucVuText",
      headerStyle: {
        minWidth: "200px"
      }
    },
    {
      name: lang("INPUT.JOB_POSITION"),
      field: "viTriCongViecText",
      headerStyle: {
        minWidth: "250px"
      }
    },
    {
      name: lang("INPUT.HEALTHINSURANCE.EFFECTIVEDATE"),
      field: "ngayHieuLuc",
      headerStyle: {
        minWidth: "200px"
      },
      render: (row: any) => (
        <span>
          <span>{formatDateTable(row?.ngayHuongLuongTuNgay)}</span>
          {row?.ngayHuongLuongDenNgay ? <span> {" - " + formatDateTable(row?.ngayHuongLuongDenNgay)}</span> : ""}
        </span>
      )
    },
    {
      name: lang("GENERAL.EMPLOYEE.BASIC_SALARY"),
      field: "luongCoBan",
      headerStyle: {
        minWidth: "200px"
      }
    },
    {
      name: lang("INPUT.SALARY.RATE"),
      field: "phanTramHuong",
      headerStyle: {
        minWidth: "160px"
      },
      render: (row: any) => <span>{row?.phanTramHuong?.name}</span>
    },
    {
      name: lang("GENERAL.EMPLOYEE.INSURANCE_SALARY"),
      field: "luongDongBaoHiem",
      headerStyle: {
        minWidth: "160px"
      }
    },
    {
      name: lang("GENERAL.NOTE"),
      field: "ghiChu",
      headerStyle: {
        minWidth: "160px"
      }
    }
  ];

  return (
    <div className="form-info">
      <Form
        className="form-info"
        onSubmit={formik.handleSubmit}
      >
        <div className="block-content">
          <span className="text-header">{lang("SALARY.BANK.INFOMATION")}</span>
          <Row className="g-4">
            <Col lg={6} xl={4} xxl={3}>
              <TextValidator
                lable={lang("GENERAL.EMPLOYEE.BANK_NUMBER")}
                name="tkNganHang"
                value={formik.values?.tkNganHang || ""}
                type="text"
                readOnly={isView}
                onChange={formik.handleChange}
              />
            </Col>
            <Col lg={6} xl={4} xxl={3} className="z-index-2">
              <Autocomplete
                lable={lang("INPUT.SALARY.BANK")}
                name="nganHang"
                value={formik.values?.nganHang || null}
                searchFunction={getAllSimpleValue}
                searchObject={TYPE_CATEGORY.nganHang}
                isReadOnly={isView}
                onChange={(value) => handleChangeSelect(VARIABLE_STRING.NGAN_HANG, value)}
                options={[]}
                getOptionLabel={(option) => option?.value}
              />
            </Col>
            <Col lg={6} xl={4} xxl={3}>
              <TextValidator
                lable={lang("GENERAL.EMPLOYEE.RANK_BRANCH")}
                name="chiNhanh"
                value={formik.values?.chiNhanh || ""}
                type="text"
                readOnly={isView}
                onChange={formik.handleChange}
              />
            </Col>
          </Row>
        </div>
      </Form>
      <div className="block-content">
        <TableCustom
          title={lang("SALARY.DEVELOPMENT")}
          isActionTableTab
          data={listData}
          columns={columns}
          justFilter={true}
          type={TYPE.MULTILINE}
          buttonExportExcel={true}
          notDelete={true}
          noPagination={true}
          buttonAdd={!isView}
          handleDoubleClick={handleOpenCreateDialog}
          handleOpenDialog={(row) => handleOpenCreateDialog({})}
          handleExportExcel={() => 
            exportToFile({
              exportAPI: () => exportLichSuLuong(identify ?? ""), 
              fileName: EXPORT_FILE_NAME.LICH_SU_LUONG,
              setPageLoading
            })
          }
        />
        {shouldOpenCreateDialog && (
          <SalaryDevelopmentDialog
            isView={isView}
            salaryInfo={salaryInfo}
            handleClose={handleCloseCreateDialog}
            show={shouldOpenCreateDialog}
            employeeProfiles={employeeProfiles}
            updateParentTable={updateDataSalaryDevelop}
          />
        )}
      </div>
    </div>
  );
};
