import { useFormik } from "formik";
import { ChangeEvent, useContext, useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import * as Yup from "yup";
import Autocomplete from "../../component/input-field/Autocomplete";
import TextValidator from "../../component/input-field/TextValidator";
import { SUCCESS_CODE } from "../../contract/const/ContractConst";
import {
  searchAllProvince,
  searchCommuneByDistrict,
  searchDistrictByProvince,
} from "../../services";
import { REGEX, SEARCH_OBJECT_MAX_SIZE, VARIABLE_STRING } from "../../utils/Constant";
import { INIT_CONTACT, REF_TAB } from "../const/ProfileConst";
import { IContact } from "../models/ProfileModels";
import { handleAddContactInfo } from "../services/ProfileServices";
import { combineAddressDetail, convertDataInfoDto } from "../utils/FunctionUtils";
import useMultiLanguage from "../../../hook/useMultiLanguage";
import AppContext from "../../../AppContext";

export const ContactInformation = (props: any) => {
  const {
    identify,
    employeeProfiles,
    isView,
    handleGetEmployee,
    formikRef,
    nationalityDefault,
    nationality,
    handleToggleIsView,
    activeTab
  } = props;
  const { lang } = useMultiLanguage();
  const { setPageLoading } = useContext(AppContext);

  const [isHkForeign, setIsHkForeign] = useState<boolean>(false);
  const [isHnForeign, setIsHnForeign] = useState<boolean>(false);

  useEffect(() => {
    formik.setFieldValue(VARIABLE_STRING.HK_QUOC_GIA, nationalityDefault);
    formik.setFieldValue(VARIABLE_STRING.HN_QUOC_GIA, nationalityDefault);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nationalityDefault]);

  useEffect(() => {
    if (identify && activeTab === REF_TAB.TT_LIEN_HE) {
      formik.setValues({
        ...employeeProfiles,
        [VARIABLE_STRING.HK_QUOC_GIA]: employeeProfiles?.hkQuocGiaText ? employeeProfiles[VARIABLE_STRING.HK_QUOC_GIA] : nationalityDefault,
        [VARIABLE_STRING.HN_QUOC_GIA]: employeeProfiles?.hnQuocGiaText ? employeeProfiles[VARIABLE_STRING.HN_QUOC_GIA] : nationalityDefault
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [employeeProfiles, activeTab]);

  const validationSchema = Yup.object().shape({
    phone: Yup.string()
      .required(lang("VALIDATION.REQUIRE"))
      .matches(REGEX.CHECK_PHONE, lang("VALIDATION.ISPHONE"))
      .nullable(),
    otherPhone: Yup.string()
      .matches(REGEX.CHECK_PHONE, lang("VALIDATION.ISPHONE"))
      .nullable(),
    emailCoQuan: Yup.string()
      .email(lang("VALIDATION.EMAILINVALID"))
      .nullable(),
    emailCaNhan: Yup.string()
      .email(lang("VALIDATION.EMAILINVALID"))
      .nullable(),
    hnQuocGia: Yup.object()
      .required(lang("VALIDATION.REQUIRE"))
      .nullable(),
    hnTinh: Yup.object()
      .concat(isHnForeign 
        ? Yup.object().nullable()
        : Yup.object().required(lang("VALIDATION.REQUIRE")).nullable()
      )
      .nullable(),
    hnHuyen: Yup.object()
      .concat(isHnForeign 
        ? Yup.object().nullable()
        : Yup.object().required(lang("VALIDATION.REQUIRE")).nullable()
      )
      .nullable(),
    hnXa: Yup.object()
      .concat(isHnForeign 
        ? Yup.object().nullable()
        : Yup.object().required(lang("VALIDATION.REQUIRE")).nullable()
      )
      .nullable(),
    hnDiachi: isHnForeign 
      ? Yup.string().required(lang("VALIDATION.REQUIRE")).nullable()
      : Yup.string().nullable(),
    hkQuocGia: Yup.object()
      .required(lang("VALIDATION.REQUIRE"))
      .nullable(),
    hkTinh: Yup.object()
      .concat(isHkForeign 
        ? Yup.object().nullable()
        : Yup.object().required(lang("VALIDATION.REQUIRE")).nullable()
      )
      .nullable(),
    hkHuyen: Yup.object()
      .concat(isHkForeign 
        ? Yup.object().nullable()
        : Yup.object().required(lang("VALIDATION.REQUIRE")).nullable()
      )
      .nullable(),
    hkXa: Yup.object()
      .concat(isHkForeign 
        ? Yup.object().nullable()
        : Yup.object().required(lang("VALIDATION.REQUIRE")).nullable()
      )
      .nullable(),
    hkDiachi: isHkForeign 
      ? Yup.string().required(lang("VALIDATION.REQUIRE")).nullable()
      : Yup.string().nullable(),
  });

  const handleFormSubmit = async (values: IContact) => {
    try {
      setPageLoading(true);
      const dataSubmit = convertDataInfoDto(values);
      const data = await handleAddContactInfo(dataSubmit, employeeProfiles?.id);
      if (data?.data?.code === SUCCESS_CODE) {
        toast.success(lang("TOAST.EDIT.SUCCESS"));
        handleToggleIsView()
        handleGetEmployee(employeeProfiles?.id);
      } else {
        toast.error(data?.data?.message);
      }
    } catch (error) {
      toast.error(lang("GENERAL.ERROR"));
    } finally {
      setPageLoading(false);
    }
  };

  const formik = useFormik({
    initialValues: INIT_CONTACT,
    validationSchema,
    onSubmit: handleFormSubmit
  });



  const { hkXa, hkHuyen, hkQuocGia, hkTinh, hkSoNha, hkDiachi,
    hnXa, hnHuyen, hnQuocGia, hnTinh, hnSoNha,
    ttXa, ttHuyen, ttTinh, ttSoNha,
    queQuanXa, queQuanHuyen, queQuanTinh, queQuanDiaChi,
    noiSinhXa, noiSinhHuyen, noiSinhTinh, noiSinhDiaChi,
    hnGiongHoKhau
  } = formik.values;

  useEffect(() => {
    if(hkQuocGia?.name === VARIABLE_STRING.VIET_NAM) {
      let dataAddress = {
        houseNumber: hkSoNha,
        wards: hkXa?.name,
        districts: hkHuyen?.name,
        province: hkTinh?.name,
        national: hkQuocGia?.name
      };
      let addressDetail = combineAddressDetail(dataAddress);
      formik.setFieldValue(VARIABLE_STRING.HK_DIA_CHI, addressDetail);
    }
  }, [hkXa, hkHuyen, hkQuocGia, hkTinh, hkSoNha]);

  useEffect(() => {
    if(hnQuocGia?.name === VARIABLE_STRING.VIET_NAM) {
        let dataAddress = {
        houseNumber: hnSoNha,
        wards: hnXa?.name,
        districts: hnHuyen?.name,
        province: hnTinh?.name,
        national: hnQuocGia?.name
      };
      let addressDetail = combineAddressDetail(dataAddress);
      formik.setFieldValue(VARIABLE_STRING.HN_DIA_CHI, addressDetail);
    }
  }, [hnXa, hnHuyen, hnQuocGia, hnTinh, hnSoNha]);

  useEffect(() => {
    let dataAddress = {
      houseNumber: queQuanDiaChi,
      wards: queQuanXa?.name,
      districts: queQuanHuyen?.name,
      province: queQuanTinh?.name
    };
    let addressDetail = combineAddressDetail(dataAddress);
    formik.setFieldValue(VARIABLE_STRING.QQ_DCCT, addressDetail);
  }, [queQuanXa, queQuanHuyen, queQuanTinh, queQuanDiaChi]);

  useEffect(() => {
    let dataAddress = {
      houseNumber: noiSinhDiaChi,
      wards: noiSinhXa?.name,
      districts: noiSinhHuyen?.name,
      province: noiSinhTinh?.name
    };
    let addressDetail = combineAddressDetail(dataAddress);
    formik.setFieldValue(VARIABLE_STRING.NS_DCCT, addressDetail);
  }, [noiSinhXa, noiSinhHuyen, noiSinhTinh, noiSinhDiaChi]);

  useEffect(() => {
    let dataAddress = {
      houseNumber: ttSoNha,
      wards: ttXa?.name,
      districts: ttHuyen?.name,
      province: ttTinh?.name
    };
    let addressDetail = combineAddressDetail(dataAddress);
    formik.setFieldValue(VARIABLE_STRING.TT_DIA_CHI, addressDetail);
  }, [ttXa, ttHuyen, ttTinh, ttSoNha]);

  const handleChangeSelect = (name: string, value: any) => {
    switch (name) {
      case VARIABLE_STRING.HK_QUOC_GIA:
        formik.setValues({
          ...formik.values,
          [name]: value,
          hkTinh: null,
          hkHuyen: null,
          hkXa: null,
          hkSoNha: "",
          hkDiachi: value?.name || "",
          hnQuocGia: hnGiongHoKhau ? value : hnQuocGia
        });
        break;
      case VARIABLE_STRING.HN_QUOC_GIA:
        formik.setValues({
          ...formik.values,
          [name]: value,
          hnTinh: null,
          hnHuyen: null,
          hnXa: null,
          hnSoNha: "",
          hnDiachi: value?.name || ""
        });
        break;
      case VARIABLE_STRING.HK_TINH:
        formik.setValues({
          ...formik.values,
          [name]: value,
          hkHuyen: null,
          hkXa: null,
          hkSoNha: "",
          hnTinh: hnGiongHoKhau ? value : hnTinh
        });
        break;
      case VARIABLE_STRING.QQ_TINH:
        formik.setFieldValue(name, value);
        formik.setFieldValue(VARIABLE_STRING.QQ_HUYEN, null);
        formik.setFieldValue(VARIABLE_STRING.QQ_XA, null);
        break;
      case VARIABLE_STRING.NS_TINH:
        formik.setFieldValue(name, value);
        formik.setFieldValue(VARIABLE_STRING.NS_HUYEN, null);
        formik.setFieldValue(VARIABLE_STRING.NS_XA, null);
        break;
      case VARIABLE_STRING.HN_TINH:
        formik.setFieldValue(name, value);
        formik.setFieldValue(VARIABLE_STRING.HN_HUYEN, null);
        formik.setFieldValue(VARIABLE_STRING.HN_XA, null);
        break;
      case VARIABLE_STRING.TT_TINH:
        formik.setFieldValue(name, value);
        formik.setFieldValue(VARIABLE_STRING.TT_HUYEN, null);
        formik.setFieldValue(VARIABLE_STRING.TT_XA, null);
        break;
      case VARIABLE_STRING.HK_HUYEN:
        formik.setValues({
          ...formik.values,
          [name]: value,
          hkXa: null,
          hkSoNha: "",
          hnHuyen: hnGiongHoKhau ? value : hnHuyen
        });
        break;
      case VARIABLE_STRING.QQ_HUYEN:
        formik.setFieldValue(name, value);
        formik.setFieldValue(VARIABLE_STRING.QQ_XA, null);
        break;
      case VARIABLE_STRING.NS_HUYEN:
        formik.setFieldValue(name, value);
        formik.setFieldValue(VARIABLE_STRING.NS_XA, null);
        break;
      case VARIABLE_STRING.HN_HUYEN:
        formik.setFieldValue(name, value);
        formik.setFieldValue(VARIABLE_STRING.HN_XA, null);
        break;
      case VARIABLE_STRING.TT_HUYEN:
        formik.setFieldValue(name, value);
        formik.setFieldValue(VARIABLE_STRING.TT_XA, null);
        break;
      case VARIABLE_STRING.HK_XA:
        formik.setValues({
          ...formik.values,
          [name]: value,
          hkSoNha: "",
          hnXa: hnGiongHoKhau ? value : hnXa
        });
        break;
      default:
        formik.setFieldValue(name, value);
        break;
    }
  };

  const handleCheckbox = (event: ChangeEvent<HTMLInputElement>) => {
    let { checked, name } = event?.target;
    switch (name) {
      case VARIABLE_STRING.HN_GIONG_HO_KHAU:
        if(checked) {
          formik.setValues({
            ...formik.values,
            [name]: checked,
            hnQuocGia: formik.values?.hkQuocGia,
            hnTinh: formik.values?.hkTinh,
            hnHuyen: formik.values?.hkHuyen,
            hnXa: formik.values?.hkXa,
            hnSoNha: formik.values?.hkSoNha,
            hnDiachi: formik.values?.hkDiachi
          });
        } else {
          formik.setFieldValue(name, checked);
        }
        break;
      case VARIABLE_STRING.HK_LA_CHU_HO:
        formik.setValues({
          ...formik.values,
          hkLaChuHo: checked
        });
        break;
    }
  };

  useEffect(() => {
    if (formikRef) {
      formikRef.current = formik;
    }
  }, [formik, formikRef]);
  
  useEffect(() => {
    if(!hkQuocGia || hkQuocGia?.name === VARIABLE_STRING.VIET_NAM) {
      setIsHkForeign(false);
    } else {
      setIsHkForeign(true);
    }
  }, [hkQuocGia]);

  useEffect(() => {
    if(!hnQuocGia || hnQuocGia?.name === VARIABLE_STRING.VIET_NAM) {
      setIsHnForeign(false);
    } else {
      setIsHnForeign(true);
    }
  }, [hnQuocGia]);

  const handleBlur = (event: any) => {
    const { name, value } = event.target;
    if(hnGiongHoKhau) {
      if(name === VARIABLE_STRING.HK_SO_NHA) {
        formik.setFieldValue(VARIABLE_STRING.HN_SO_NHA, value);
      }
    }
  }
  
  useEffect(() => {
    if(hnGiongHoKhau && isHnForeign) {
      formik.setFieldValue(VARIABLE_STRING.HN_DIA_CHI, hkDiachi);
    }
  }, [hkDiachi, isHnForeign]);

  return (
    <div className="basic-info-wrapper">
      <Form
        onSubmit={formik.handleSubmit}
        className="form-info"
      >
        <div className="block-content">
          <span className="text-header">{lang("PROFILE.CONTACT.INFO")}</span>
          <Row className="g-4">
            <Col md={6} lg={4} xl={2} xxl={2}>
              <TextValidator
                lable={lang("GENERAL.PHONE")}
                name="phone"
                value={formik.values?.phone || ""}
                type="text"
                readOnly={isView}
                onChange={formik.handleChange}
                isRequired
                errors={formik.errors?.phone}
                touched={formik.touched?.phone}
              />
            </Col>
            <Col md={6} lg={4} xl={2} xxl={2}>
              <TextValidator
                lable={lang("GENERAL.OTHER_PHONE")}
                name="otherPhone"
                value={formik.values?.otherPhone || ""}
                type="text"
                readOnly={isView}
                onChange={formik.handleChange}
                errors={formik.errors?.otherPhone}
                touched={formik.touched?.otherPhone}
              />
            </Col>
            <Col md={6} lg={4} xl={4} xxl={3}>
              <TextValidator
                lable={lang("GENERAL.PERSONAL_EMAIL")}
                name="emailCaNhan"
                value={formik.values?.emailCaNhan || ""}
                type="text"
                readOnly={isView}
                onChange={formik.handleChange}
                errors={formik.errors?.emailCaNhan}
                touched={formik.touched?.emailCaNhan}
              />
            </Col>
            <Col md={6} lg={4} xl={4} xxl={3}>
              <TextValidator
                lable={lang("GENERAL.ORGAN_EMAIL")}
                name="emailCoQuan"
                value={formik.values?.emailCoQuan || ""}
                type="text"
                readOnly={isView}
                onChange={formik.handleChange}
                errors={formik.errors?.emailCoQuan}
                touched={formik.touched?.emailCoQuan}
              />
            </Col>
          </Row>
        </div>
        <div className="block-content">
          <span className="text-header">{lang("TITLE.PERMANENT_RESIDENCE")}</span>
          <Row className="g-4">
            <Col md={6} lg={4} xl={3} xxl={2}>
              <Autocomplete
                lable={lang("GENERAL.NATIONAL")}
                isReadOnly={isView}
                options={nationality || []}
                isRequired
                value={formik.values?.hkQuocGia || null}
                name="hkQuocGia"
                onChange={(selectedOption) => handleChangeSelect(VARIABLE_STRING.HK_QUOC_GIA, selectedOption)}
                errors={formik.errors?.hkQuocGia}
                touched={formik.touched?.hkQuocGia}
              />
            </Col>
            {!isHkForeign && (
              <>
                <Col md={6} lg={4} xl={3} xxl={2}>
                  <Autocomplete
                    lable={lang("GENERAL.PROVINCE")}
                    isReadOnly={isView}
                    options={[]}
                    isRequired
                    value={formik.values?.hkTinh || null}
                    name="hkTinh"
                    onChange={(selectedOption) => handleChangeSelect(VARIABLE_STRING.HK_TINH, selectedOption)}
                    searchFunction={searchAllProvince}
                    searchObject={SEARCH_OBJECT_MAX_SIZE}
                    errors={formik.errors?.hkTinh}
                    touched={formik.touched?.hkTinh}
                  />
                </Col>
                <Col md={6} lg={4} xl={3} xxl={2}>
                  <Autocomplete
                    lable={lang("GENERAL.DISTRICT")}
                    isReadOnly={isView}
                    options={[]}
                    isRequired
                    value={formik.values?.hkHuyen || null}
                    name="hkHuyen"
                    onChange={(selectedOption) => handleChangeSelect(VARIABLE_STRING.HK_HUYEN, selectedOption)}
                    searchFunction={searchDistrictByProvince}
                    searchObject={{ ...SEARCH_OBJECT_MAX_SIZE, id: formik.values?.hkTinh?.id }}
                    dependencies={[formik.values?.hkTinh?.id]}
                    errors={formik.errors?.hkHuyen}
                    touched={formik.touched?.hkHuyen}
                  />
                </Col>
                <Col md={6} lg={6} xl={3} xxl={2}>
                  <Autocomplete
                    lable={lang("GENERAL.COMMUNE")}
                    isReadOnly={isView}
                    options={[]}
                    isRequired
                    value={formik.values?.hkXa || null}
                    name="hkXa"
                    onChange={(selectedOption) => handleChangeSelect(VARIABLE_STRING.HK_XA, selectedOption)}
                    searchFunction={searchCommuneByDistrict}
                    searchObject={{ ...SEARCH_OBJECT_MAX_SIZE, id: formik.values?.hkHuyen?.id }}
                    dependencies={[formik.values?.hkTinh?.id, formik.values?.hkHuyen?.id]}
                    errors={formik.errors?.hkXa}
                    touched={formik.touched?.hkXa}
                  />
                </Col>
                <Col md={6} lg={6} xl={6} xxl={4}>
                  <TextValidator
                    lable={lang("GENERAL.ADDRESS.SPECIFIC")}
                    name="hkSoNha"
                    value={formik.values?.hkSoNha || ""}
                    type="text"
                    readOnly={isView}
                    onChange={formik.handleChange}
                    onBlur={handleBlur}
                  />
                </Col>
                <Col md={6} lg={6} xl={3} xxl={2}>
                  <TextValidator
                    lable={lang("GENERAL.HOUSEHOLD_CODE")}
                    name="hkMaSoHoGiaDinh"
                    value={formik.values?.hkMaSoHoGiaDinh || ""}
                    type="text"
                    readOnly={isView}
                    onChange={formik.handleChange}
                  />
                </Col>
                <Col md={6} lg={3} xl={3} xxl={2}>
                  <Form.Check
                    label={lang("GENERAL.ISHOUSEHOLDER")}
                    className="custom-form-check spaces ml-16"
                    name="hkLaChuHo"
                    checked={formik.values?.hkLaChuHo}
                    disabled={isView}
                    onChange={handleCheckbox}
                  />
                </Col>
              </>
            )}
            <Col md={12} lg={9} xl={isHkForeign ? 9 : 12} xxl={!isHkForeign ? 8 : 10}>
              <TextValidator
                isRequired={isHkForeign}
                className="flex-1"
                lable={lang("GENERAL.PERMANENT_RESIDENCE")}
                name="hkDiachi"
                value={formik.values?.hkDiachi || ""}
                onChange={formik.handleChange}
                type="text"
                readOnly={isView || !isHkForeign}
                errors={formik.errors?.hkDiachi}
                touched={formik.touched?.hkDiachi}              />
            </Col>
          </Row>
        </div>
        <div className="block-content">
          <span className="text-header">{lang("TITLE.ADDRESS.CURRENT")}</span>
          <Row className="g-4">
            <Col md={6} lg={4} xl={3} xxl={2}>
              <Form.Check
                label={lang("GENERAL.PERMANENT_RESIDENCE_SAME")}
                className="checkBox custom-form-check"
                name="hnGiongHoKhau"
                checked={formik.values?.hnGiongHoKhau}
                onChange={handleCheckbox}
                disabled={isView}
              />
            </Col>
            <Col md={6} lg={4} xl={3} xxl={2}>
              <Autocomplete
                lable={lang("GENERAL.NATIONAL")}
                isReadOnly={isView || formik.values?.hnGiongHoKhau}
                options={nationality || []}
                isRequired
                value={formik.values?.hnQuocGia || null}
                name="hnQuocGia"
                onChange={(selectedOption) => handleChangeSelect(VARIABLE_STRING.HN_QUOC_GIA, selectedOption)}
                errors={formik.errors?.hnQuocGia}
                touched={formik.touched?.hnQuocGia}
              />
            </Col>
            {!isHnForeign && (
              <>
                <Col md={6} lg={4} xl={3} xxl={2}>
                  <Autocomplete
                    lable={lang("GENERAL.PROVINCE")}
                    isReadOnly={isView || formik.values?.hnGiongHoKhau}
                    options={[]}
                    isRequired
                    value={formik.values?.hnTinh || null}
                    name="hnTinh"
                    onChange={(selectedOption) => handleChangeSelect(VARIABLE_STRING.HN_TINH, selectedOption)}
                    searchFunction={searchAllProvince}
                    searchObject={SEARCH_OBJECT_MAX_SIZE}
                    errors={formik.errors?.hnTinh}
                    touched={formik.touched?.hnTinh}
                  />
                </Col>
                <Col md={6} lg={4} xl={3} xxl={2}>
                  <Autocomplete
                    lable={lang("GENERAL.DISTRICT")}
                    isReadOnly={isView || formik.values?.hnGiongHoKhau}
                    options={[]}
                    isRequired
                    value={formik.values?.hnHuyen || null}
                    name="hnHuyen"
                    onChange={(selectedOption) => handleChangeSelect(VARIABLE_STRING.HN_HUYEN, selectedOption)}
                    searchFunction={searchDistrictByProvince}
                    searchObject={{ ...SEARCH_OBJECT_MAX_SIZE, id: formik.values?.hnTinh?.id }}
                    dependencies={[formik.values?.hnTinh?.id]}
                    errors={formik.errors?.hnHuyen}
                    touched={formik.touched?.hnHuyen}
                  />
                </Col>
                <Col md={6} lg={4} xl={3} xxl={2}>
                  <Autocomplete
                    lable={lang("GENERAL.COMMUNE")}
                    isReadOnly={isView || formik.values?.hnGiongHoKhau}
                    options={[]}
                    isRequired
                    value={formik.values?.hnXa || null}
                    name="hnHuyen"
                    onChange={(selectedOption) => handleChangeSelect(VARIABLE_STRING.HN_XA, selectedOption)}
                    searchFunction={searchCommuneByDistrict}
                    searchObject={{ ...SEARCH_OBJECT_MAX_SIZE, id: formik.values?.hnHuyen?.id }}
                    dependencies={[formik.values?.hnTinh?.id, formik.values?.hnHuyen?.id]}
                    errors={formik.errors?.hnHuyen}
                    touched={formik.touched?.hnHuyen}
                  />
                </Col>
                <Col md={6} lg={4} xl={3} xxl={2}>
                  <TextValidator
                    lable={lang("GENERAL.ADDRESS.SPECIFIC")}
                    name="hnSoNha"
                    value={formik.values?.hnSoNha || ""}
                    type="text"
                    readOnly={isView || formik.values?.hnGiongHoKhau}
                    onChange={formik.handleChange}
                  />
                </Col>
              </>
            )}
            <Col md={12} lg={12} xl={6} xxl={!isHnForeign ? 6 : 8}>
              <TextValidator
                isRequired={isHnForeign}
                className="grid-column-4"
                lable={lang("GENERAL.ADDRESS.CURRENT")}
                name="hnDiachi"
                value={formik.values?.hnDiachi || ""}
                onChange={formik.handleChange}
                type="text"
                readOnly={isView || !isHnForeign || formik.values?.hnGiongHoKhau}
                errors={formik.errors?.hnDiachi}
                touched={formik.touched?.hnDiachi}
              />
            </Col>
          </Row>
        </div>
        {!isHkForeign && (
          <>
            <div className="block-content">
              <span className="text-header">{lang("TITLE.ADDRESS.HOMETOWN")}</span>
              <Row className="g-4">
                <Col md={12} lg={12} xl={12} xxl={6}>
                  <TextValidator
                    lable={lang("GENERAL.ADDRESS.BY_ORIGINAL_PROFILE")}
                    name="quequanTheoHoSoGoc"
                    value={formik?.values?.quequanTheoHoSoGoc || ""}
                    type="text"
                    readOnly={isView}
                    onChange={formik.handleChange}
                  />
                </Col>
              </Row>
              <span className="text-lable-input lable mb-2 mt-7">{lang("CONTACT.CURENT_ADMIT_UNIT")}</span>
              <Row className="g-4">
                <Col md={6} lg={6} xl={3} xxl={2}>
                  <Autocomplete
                    lable={lang("GENERAL.PROVINCE")}
                    isReadOnly={isView}
                    options={[]}
                    value={formik.values?.queQuanTinh || null}
                    name="queQuanTinh"
                    onChange={(selectedOption) => handleChangeSelect(VARIABLE_STRING.QQ_TINH, selectedOption)}
                    searchFunction={searchAllProvince}
                    searchObject={SEARCH_OBJECT_MAX_SIZE}
                    errors={formik.errors?.queQuanTinh}
                    touched={formik.touched?.queQuanTinh}
                  />
                </Col>
                <Col md={6} lg={6} xl={3} xxl={2}>
                  <Autocomplete
                    lable={lang("GENERAL.DISTRICT")}
                    isReadOnly={isView}
                    options={[]}
                    value={formik.values?.queQuanHuyen || null}
                    name="queQuanHuyen"
                    onChange={(selectedOption) => handleChangeSelect(VARIABLE_STRING.QQ_HUYEN, selectedOption)}
                    searchFunction={searchDistrictByProvince}
                    searchObject={{ ...SEARCH_OBJECT_MAX_SIZE, id: formik.values?.queQuanTinh?.id }}
                    dependencies={[formik.values?.queQuanTinh?.id]}
                    errors={formik.errors?.queQuanHuyen}
                    touched={formik.touched?.queQuanHuyen}
                  />
                </Col>
                <Col md={6} lg={6} xl={3} xxl={2}>
                  <Autocomplete
                    lable={lang("GENERAL.COMMUNE")}
                    isReadOnly={isView}
                    options={[]}
                    value={formik.values?.queQuanXa || null}
                    name="queQuanXa"
                    onChange={(selectedOption) => handleChangeSelect(VARIABLE_STRING.QQ_XA, selectedOption)}
                    searchFunction={searchCommuneByDistrict}
                    searchObject={{ ...SEARCH_OBJECT_MAX_SIZE, id: formik.values?.queQuanHuyen?.id }}
                    dependencies={[formik.values?.queQuanTinh?.id, formik.values?.queQuanHuyen?.id]}
                    errors={formik.errors?.queQuanXa}
                    touched={formik.touched?.queQuanXa}
                  />
                </Col>
                <Col md={6} lg={6} xl={3} xxl={2}>
                  <TextValidator
                    lable={lang("GENERAL.ADDRESS.SPECIFIC")}
                    name="queQuanDiaChi"
                    value={formik.values?.queQuanDiaChi || ""}
                    type="text"
                    readOnly={isView}
                    onChange={formik.handleChange}
                  />
                </Col>
                <Col md={12} lg={12} xl={12} xxl={4}>
                  <TextValidator
                    lable={lang("GENERAL.ADDRESS.DETAIL")}
                    name="queQuanDiaChiChiTiet"
                    value={formik.values?.queQuanDiaChiChiTiet || ""}
                    type="text"
                    readOnly={true}
                    className="grid-column-2"
                  />
                </Col>
              </Row>
            </div>
            <div className="block-content">
              <span className="text-header">{lang("INPUT.PLACEOFBIRTH")}</span>
              <Row className="g-4">
                <Col md={6} lg={6} xl={3} xxl={2}>
                  <Autocomplete
                    lable={lang("GENERAL.PROVINCE")}
                    isReadOnly={isView}
                    options={[]}
                    value={formik.values?.noiSinhTinh || null}
                    name="noiSinhTinh"
                    onChange={(selectedOption) => handleChangeSelect(VARIABLE_STRING.NS_TINH, selectedOption)}
                    searchFunction={searchAllProvince}
                    searchObject={SEARCH_OBJECT_MAX_SIZE}
                    errors={formik.errors?.noiSinhTinh}
                    touched={formik.touched?.noiSinhTinh}
                  />
                </Col>
                <Col md={6} lg={6} xl={3} xxl={2}>
                  <Autocomplete
                    lable={lang("GENERAL.DISTRICT")}
                    isReadOnly={isView}
                    options={[]}
                    value={formik.values?.noiSinhHuyen || null}
                    name="noiSinhHuyen"
                    onChange={(selectedOption) => handleChangeSelect(VARIABLE_STRING.NS_HUYEN, selectedOption)}
                    searchFunction={searchDistrictByProvince}
                    searchObject={{ ...SEARCH_OBJECT_MAX_SIZE, id: formik.values?.noiSinhTinh?.id }}
                    dependencies={[formik.values?.noiSinhTinh?.id]}
                    errors={formik.errors?.noiSinhHuyen}
                    touched={formik.touched?.noiSinhHuyen}
                  />
                </Col>
                <Col md={6} lg={6} xl={3} xxl={2}>
                  <Autocomplete
                    lable={lang("GENERAL.COMMUNE")}
                    isReadOnly={isView}
                    options={[]}
                    value={formik.values?.noiSinhXa || null}
                    name="noiSinhXa"
                    onChange={(selectedOption) => handleChangeSelect(VARIABLE_STRING.NS_XA, selectedOption)}
                    searchFunction={searchCommuneByDistrict}
                    searchObject={{ ...SEARCH_OBJECT_MAX_SIZE, id: formik.values?.noiSinhHuyen?.id }}
                    dependencies={[formik.values?.noiSinhTinh?.id, formik.values?.noiSinhHuyen?.id]}
                    errors={formik.errors?.noiSinhXa}
                    touched={formik.touched?.noiSinhXa}
                  />
                </Col>
                <Col md={6} lg={6} xl={3} xxl={2}>
                  <TextValidator
                    lable={lang("GENERAL.ADDRESS.SPECIFIC")}
                    name="noiSinhDiaChi"
                    value={formik.values?.noiSinhDiaChi || ""}
                    type="text"
                    readOnly={isView}
                    onChange={formik.handleChange}
                  />
                </Col>
                <Col md={12} lg={12} xl={12} xxl={4}>
                  <TextValidator
                    lable={lang("GENERAL.ADDRESS.DETAIL")}
                    name="noiSinhDiaChiChiTiet"
                    value={formik.values?.noiSinhDiaChiChiTiet || ""}
                    type="text"
                    readOnly={true}
                    className="grid-column-2"
                  />
                </Col>
              </Row>
            </div>
          </>
        )}
        <div className="block-content">
          <span className="text-header">{lang("TITLE.ADDRESS.TEMPORARY")}</span>
          <Row className="g-4">
            <Col md={6} lg={6} xl={3} xxl={2}>
              <Autocomplete
                lable={lang("GENERAL.PROVINCE")}
                isReadOnly={isView}
                options={[]}
                value={formik.values?.ttTinh || null}
                name="ttTinh"
                menuPlacement="top"
                onChange={(selectedOption) => handleChangeSelect(VARIABLE_STRING.TT_TINH, selectedOption)}
                searchFunction={searchAllProvince}
                searchObject={SEARCH_OBJECT_MAX_SIZE}
                errors={formik.errors?.ttTinh}
                touched={formik.touched?.ttTinh}
              />
            </Col>
            <Col md={6} lg={6} xl={3} xxl={2}>
              <Autocomplete
                lable={lang("GENERAL.DISTRICT")}
                isReadOnly={isView}
                options={[]}
                value={formik.values?.ttHuyen || null}
                name="ttHuyen"
                menuPlacement="top"
                onChange={(selectedOption) => handleChangeSelect(VARIABLE_STRING.TT_HUYEN, selectedOption)}
                searchFunction={searchDistrictByProvince}
                searchObject={{ ...SEARCH_OBJECT_MAX_SIZE, id: formik.values?.ttTinh?.id }}
                dependencies={[formik.values?.ttTinh?.id]}
                errors={formik.errors?.ttHuyen}
                touched={formik.touched?.ttHuyen}
              />
            </Col>
            <Col md={6} lg={6} xl={3} xxl={2}>
              <Autocomplete
                lable={lang("GENERAL.COMMUNE")}
                isReadOnly={isView}
                options={[]}
                value={formik.values?.ttXa || null}
                name="ttXa"
                menuPlacement="top"
                onChange={(selectedOption) => handleChangeSelect(VARIABLE_STRING.TT_XA, selectedOption)}
                searchFunction={searchCommuneByDistrict}
                searchObject={{ ...SEARCH_OBJECT_MAX_SIZE, id: formik.values?.ttHuyen?.id }}
                dependencies={[formik.values?.ttTinh?.id, formik.values?.ttHuyen?.id]}
                errors={formik.errors?.ttXa}
                touched={formik.touched?.ttXa}
              />
            </Col>
            <Col md={6} lg={6} xl={3} xxl={2}>
              <TextValidator
                lable={lang("GENERAL.ADDRESS.SPECIFIC")}
                name="ttSoNha"
                value={formik.values?.ttSoNha || ""}
                type="text"
                readOnly={isView}
                onChange={formik.handleChange}
              />
            </Col>
            <Col md={12} lg={12} xl={12} xxl={4}>
              <TextValidator
                lable={lang("GENERAL.ADDRESS.DETAIL")}
                name="ttDiachi"
                value={formik.values?.ttDiachi || ""}
                type="text"
                readOnly={true}
                className="grid-column-2"
              />
            </Col>
          </Row>
        </div>
      </Form>
    </div>
  );
};
