/* eslint-disable react-hooks/exhaustive-deps */
import { useFormik } from "formik";
import { useContext, useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import * as Yup from "yup";
import useMultiLanguage from "../../../hook/useMultiLanguage";
import Autocomplete from "../../component/input-field/Autocomplete";
import TextValidator from "../../component/input-field/TextValidator";
import { TYPE_CATEGORY } from "../../constant";
import { SUCCESS_CODE } from "../../contract/const/ContractConst";
import { getAllSimpleValue } from "../../services";
import { RESPONSE_STATUS_CODE, SEARCH_OBJECT_MAX_SIZE, VARIABLE_STRING } from "../../utils/Constant";
import { formatDateTable, handleBlurDate } from "../../utils/FunctionUtils";
import { LIST_STATUS_NV } from "../const/DialogChildConstants";
import { HAS_RETIRED_CODE, listContractType, natureOfWork } from "../const/DialogConstant";
import { INIT_WORK, REF_TAB } from "../const/ProfileConst";
import "../profile.scss";
import { getContractByEmployee, getNguoiQuanLy, handleAddWorkInfo } from "../services/ProfileServices";
import { convertDataInfoDto } from "../utils/FunctionUtils";
import AppContext from "../../../AppContext";
import TableCustom from "../../component/table-custom/TableCustom";

export const WorkInformation = (props: any) => {
  const { employeeProfiles, activeTab, isView, handleGetEmployee, identify, formikRef, handleToggleIsView } = props;
  const { lang } = useMultiLanguage();
  const [isWorking, setIsWorking] = useState(true);
  const [isCollaborator, setIsCollaborator] = useState<boolean>(false);
  const { setPageLoading } = useContext(AppContext);

  const updateContractType = async () => {
    if (identify) {
      try {
        const { data } = await getContractByEmployee(identify);
        if (data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
          formik.setFieldValue(VARIABLE_STRING.LOAI_HOP_DONG, data?.data?.loaiHopDong || null);
        }
      } catch (error) {
        toast.error(lang("GENERAL.ERROR"));
      }
    }
  };

  const validationSchema = Yup.object().shape({
    donViCongTac: Yup.object().required(lang("VALIDATION.REQUIRE")).nullable(),
    phongBan: Yup.object().required(lang("VALIDATION.REQUIRE")).nullable(),
    viTriCongViec: Yup.object().required(lang("VALIDATION.REQUIRE")).nullable(),
    chucVu: Yup.object().required(lang("VALIDATION.REQUIRE")).nullable(),
    trangThaiLaoDong: Yup.object().required(lang("VALIDATION.REQUIRE")).nullable(),
    tinhChatLaoDong: Yup.object().required(lang("VALIDATION.REQUIRE")).nullable(),
    ngayThuViec: Yup.date()
      .when("ngayChinhThuc", {
        is: (ngayChinhThuc: Date | null) => ngayChinhThuc && ngayChinhThuc < (new Date()),
        then: Yup.date()
          .max(
            Yup.ref("ngayChinhThuc"),
            lang("VALIDATE.TRIAL_DATE_BEFORE_OFFICIAL_DATE")
          )
          .nullable()
      })
      .max(new Date(), lang("VALIDATION.INVALID_TRIAL_DATE"))
      .required(lang("VALIDATION.REQUIRE"))
      .nullable(),
    ngayVaoCoQuan: Yup.date()
      .when("ngayThuViec", {
        is: (ngayThuViec: Date | null) => ngayThuViec && ngayThuViec < (new Date()),
        then: Yup.date()
          .max(
            Yup.ref("ngayThuViec"),
            lang("VALIDATE.APPRENTICE_DATE_BEFORE_TRIAL_DATE")
          )
          .nullable()
      })
      .max(new Date(), lang("VALIDATION.INVALID_APPRENTICE_DATE"))
      .nullable(),
    ngayChinhThuc: Yup.date()
      .max(new Date(), lang("VALIDATION.INVALID_OFFICIAL_DATE"))
      .required(lang("VALIDATION.REQUIRE"))
      .nullable(),
  });

  useEffect(() => {
    if (activeTab !== REF_TAB.TT_CONG_VIEC) return;
    if (identify) {
      formik.setValues(employeeProfiles);
      updateContractType();
    } else {
      formik.setValues(INIT_WORK);
    }
  }, [employeeProfiles, activeTab]);

  const handleFormSubmit = async (values: any) => {
    try {
      setPageLoading(true);
      const dataInfo = convertDataInfoDto(values);
      const data = await handleAddWorkInfo(dataInfo, employeeProfiles?.id);
      if (data?.data?.code === SUCCESS_CODE) {
        toast.success(lang("TOAST.EDIT.SUCCESS"));
        handleToggleIsView();
        handleGetEmployee(employeeProfiles?.id);
      } else {
        toast.error(data?.data?.message);
      }
    } catch (err) {
      toast.error(lang("GENERAL.ERROR"));
    } finally {
      setPageLoading(false);
    }
  };

  const formik = useFormik({
    initialValues: INIT_WORK,
    validationSchema,
    onSubmit: handleFormSubmit
  });

  const handleChangeSelect = (name: string, value: any) => {
    formik.setFieldValue(name, value);
  };

  const handleChangeCheckBox = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { checked, name } = event.target;
    formik.setFieldValue(name, checked);
  };

  useEffect(() => {
    if (formikRef) {
      formikRef.current = formik;
    }
  }, [formik, formikRef]);

  const handleChangeTrangThaiLaoDong = (value: any) => {
    switch (value?.code) {
      case HAS_RETIRED_CODE:
        formik.setFieldValue(VARIABLE_STRING.TRANG_THAI_LAO_DONG, value);
        setIsWorking(false);
        break;
      default:
        formik.setValues({
          ...formik.values,
          [VARIABLE_STRING.TRANG_THAI_LAO_DONG]: value,
          [VARIABLE_STRING.NGAY_NGHI_VIEC]: "",
          [VARIABLE_STRING.LY_DO_NGHI]: ""
        });
        setIsWorking(true);
        break;
    }
  };

  const columnsConcurrently = [
    {
      name: "STT",
      field: "",
      render: (row: any, index: number, stt: number) => <span>{stt}</span>
    },
    {
      name: lang("CONCURRENTLY.DEPARTMENT"),
      field: "phongBanMoiText",
      headerStyle: {
        minWidth: "150px",
        textAlign: "left"
      }
    },
    {
      name: lang("CONCURRENTLY.POSITION"),
      field: "chucVuMoiText",
      headerStyle: {
        minWidth: "150px",
        textAlign: "left"
      }
    },
    {
      name: lang("CONCURRENTLY.TIME"),
      field: "time",
      headerStyle: {
        minWidth: "150px"
      },
      cellStyle: {
        minWidth: "150px",
        maxWidth: "150px",
      },
      render: (row: any) => <span>{formatDateTable(row?.tuNgay)} - {formatDateTable(row?.denNgay) || "hiện tại"}</span>
    }
  ]

  return (
    <div className="">
      <Form
        className="form-info flex-column"
        onSubmit={formik.handleSubmit}
      >
        <div className="block-content">
          <span className="text-header">{lang("PROFILE.TITLE")}</span>
          <Row className="g-4 spaces z-index-2">
            <Col xs={12} sm={12} md={6} lg={4} xl={4} xxl={3}>
              <TextValidator
                isRequired
                lable={lang("GENERAL.CURRENT_ORGANIZATION")}
                name="donViCongTac"
                type="text"
                value={formik.values?.donViCongTac?.name || ""}
                readOnly={true}
              />
            </Col>
            <Col xs={12} sm={12} md={6} lg={4} xl={4} xxl={2}>
              <TextValidator
                isRequired
                lable={lang("INPUT.DEPARTMENTS")}
                name="phongBan"
                type="text"
                value={formik.values?.phongBan?.name || ""}
                readOnly={true}
              />
            </Col>
            <Col xs={12} sm={12} md={6} lg={4} xl={4} xxl={3}>
              <Autocomplete
                isRequired
                lable={lang("GENERAL.EMPLOYEE.POSITION")}
                name="chucVu"
                value={formik.values?.chucVu || null}
                searchFunction={getAllSimpleValue}
                searchObject={TYPE_CATEGORY.chucDanh}
                isReadOnly={isView || isCollaborator}
                onChange={(value) => handleChangeSelect("chucVu", value)}
                errors={formik.errors?.chucVu}
                touched={formik.touched?.chucVu}
                options={[]}
                getOptionLabel={(option) => option?.value}
              />
            </Col>
            <Col xs={12} sm={12} md={6} lg={4} xl={4} xxl={3}>
              <Autocomplete
                lable={lang("GENERAL.EMPLOYEE.JOB_POSITION")}
                name="viTriCongViec"
                isRequired
                value={formik.values?.viTriCongViec || null}
                searchFunction={getAllSimpleValue}
                searchObject={TYPE_CATEGORY.viTriCongViec}
                isReadOnly={isView || isCollaborator}
                onChange={(selectedOption) => handleChangeSelect("viTriCongViec", selectedOption)}
                options={[]}
                getOptionLabel={(option) => option?.value}
                errors={formik.errors?.viTriCongViec}
                touched={formik.touched?.viTriCongViec}
              />
            </Col>
            <Col xs={12} sm={12} md={6} lg={4} xl={4} xxl={3}>
              <Autocomplete
                lable={lang("GENERAL.EMPLOYEE.CONTRACT_TYPE")}
                name="loaiHopDong"
                value={formik.values?.loaiHopDong || null}
                options={listContractType}
                isReadOnly={true}
              />
            </Col>
            <Col xs={12} sm={12} md={6} lg={4} xl={4} xxl={3}>
              <Autocomplete
                lable={lang("GENERAL.EMPLOYEE.MANAGER")}
                name="nguoiQuanLy"
                value={formik.values?.nguoiQuanLy || ""}
                searchFunction={getNguoiQuanLy}
                searchObject={SEARCH_OBJECT_MAX_SIZE}
                isReadOnly={isView}
                onChange={(selectedOption) => handleChangeSelect(VARIABLE_STRING.NGUOI_QUAN_LY, selectedOption)}
                options={[]}
              />
            </Col>
            <Col xs={12} sm={12} md={6} lg={4} xl={4} xxl={2}>
              <TextValidator
                lable={lang("GENERAL.EMPLOYEE.PROBATIONARY_START_DATE")}
                name="ngayVaoCoQuan"
                type="date"
                value={formik.values?.ngayVaoCoQuan || ""}
                readOnly={isView}
                onChange={formik.handleChange}
                onBlur={() => handleBlurDate(formik.setFieldValue, formik.values?.ngayVaoCoQuan, "ngayVaoCoQuan")}
                errors={formik.errors?.ngayVaoCoQuan}
                touched={formik.touched?.ngayVaoCoQuan}
              />
            </Col>
            <Col xs={12} sm={12} md={6} lg={4} xl={4} xxl={2}>
              <TextValidator
                lable={lang("DATE.TRIAL")}
                name="ngayThuViec"
                type="date"
                isRequired
                value={formik.values?.ngayThuViec || ""}
                readOnly={isView}
                onChange={formik.handleChange}
                onBlur={() => handleBlurDate(formik.setFieldValue, formik.values?.ngayThuViec, "ngayThuViec")}
                errors={formik.errors?.ngayThuViec}
                touched={formik.touched?.ngayThuViec}
              />
            </Col>
            <Col xs={12} sm={12} md={6} lg={4} xl={4} xxl={2}>
              <TextValidator
                lable={lang("GENERAL.EMPLOYEE.OFFICIAL_DATE")}
                name="ngayChinhThuc"
                type="date"
                isRequired
                value={formik.values?.ngayChinhThuc || ""}
                readOnly={isView}
                onChange={formik.handleChange}
                onBlur={() => handleBlurDate(formik.setFieldValue, formik.values?.ngayChinhThuc, "ngayChinhThuc")}
                errors={formik.errors?.ngayChinhThuc}
                touched={formik.touched?.ngayChinhThuc}
              />
            </Col>
            <Col xs={12} sm={12} md={6} lg={4} xl={4} xxl={2}>
              <TextValidator
                isRequired={!isWorking}
                lable={lang("GENERAL.EMPLOYEE.RESIGN_DATE")}
                name="ngayNghiViec"
                type="date"
                value={formik.values?.ngayNghiViec || ""}
                readOnly={isView || isWorking}
                // disabled={isWorking}
                onChange={formik.handleChange}
                errors={formik.errors?.ngayNghiViec}
                touched={formik.touched?.ngayNghiViec}
              />
            </Col>
            <Col xs={12} sm={12} md={6} lg={4} xl={4} xxl={6}>
              <TextValidator
                lable={lang("GENERAL.EMPLOYEE.RESIGN_REASON")}
                name="lyDoNghi"
                value={formik.values?.lyDoNghi || ""}
                type="text"
                readOnly={isView || isWorking}
                // disabled={isWorking}
                onChange={formik.handleChange}
                errors={formik.errors?.lyDoNghi}
                touched={formik.touched?.lyDoNghi}
              />
            </Col>
            <Col xs={12} sm={12} md={6} lg={4} xl={4} xxl={2}>
              <TextValidator
                lable={lang("INPUT.RETIRE_INFO.EXPECTED_RETIREMENT_DATE")}
                name="ngayNghiHuuDuKien"
                value={formik.values?.ngayNghiHuuDuKien || ""}
                type="date"
                readOnly={isView || isWorking}
                // disabled={isWorking}
                onChange={formik.handleChange}
                errors={formik.errors?.ngayNghiHuuDuKien}
                touched={formik.touched?.ngayNghiHuuDuKien}
              />
            </Col>
            <Col xs={12} sm={12} md={6} lg={4} xl={4} xxl={2}>
              <TextValidator
                lable={lang("INPUT.RETIRE_INFO.OFFICIAL_RETIREMENT_DATE")}
                name="ngayNghiHuuChinhThuc"
                value={formik.values?.ngayNghiHuuChinhThuc || ""}
                type="date"
                readOnly={true}
                // disabled={isWorking}
                onChange={formik.handleChange}
                errors={formik.errors?.ngayNghiHuuChinhThuc}
                touched={formik.touched?.ngayNghiHuuChinhThuc}
              />
            </Col>
            <Col xs={12} sm={12} md={6} lg={4} xl={4} xxl={2}>
              <Autocomplete
                lable={lang("GENERAL.EMPLOYEE.NATURE_OF_WORK")}
                name="tinhChatLaoDong"
                isRequired
                value={formik.values?.tinhChatLaoDong || null}
                options={natureOfWork}
                isReadOnly={isView || isCollaborator}
                onChange={(selectedOption) => handleChangeSelect(VARIABLE_STRING.TINH_CHAT_LAO_DONG, selectedOption)}
                touched={formik.touched?.tinhChatLaoDong}
                errors={formik.errors?.tinhChatLaoDong}
              />
            </Col>
            <Col xs={12} sm={12} md={6} lg={4} xl={4} xxl={2}>
              <Autocomplete
                isRequired
                lable={lang("GENERAL.EMPLOYEE.ACTIVE_STATUS")}
                name="trangThaiLaoDong"
                value={formik.values?.trangThaiLaoDong || null}
                options={LIST_STATUS_NV}
                isReadOnly={isView}
                onChange={(selectedOption) => handleChangeTrangThaiLaoDong(selectedOption)}
                errors={formik.errors?.trangThaiLaoDong}
                touched={formik.touched?.trangThaiLaoDong}
              />
            </Col>
            <Col xs={12} sm={12} md={6} lg={4} xl={4} xxl={2}>
              <Form.Check
                label={lang("GENERAL.EMPLOYEE.HAS_UNION")}
                name="thamGiaCongDoan"
                className="checkBox custom-form-check"
                checked={formik.values?.thamGiaCongDoan}
                onChange={handleChangeCheckBox}
                disabled={isView}
              />
            </Col>
          </Row>
          <Row className="spaces mt-12">
            {
              employeeProfiles?.dsChucVuKiemNhiem?.length > 0 &&
              <TableCustom
                id="listWorkProcess"
                title={lang("CONCURRENTLY.LIST")}
                data={employeeProfiles?.dsChucVuKiemNhiem || []}
                isActionTableTab
                columns={columnsConcurrently}
                buttonAdd={false}
                buttonExportExcel={false}
                noToolbar={true}
                noPagination={true}
                dependencies={activeTab}
              />
            }
          </Row>
        </div>
      </Form>
    </div>
  );
};
