import useMultiLanguage from "../../../../hook/useMultiLanguage";
import TableCollapseCustom, { columnNamesType } from "../../../component/table-collapse-custom/TableCollapseCustom";

interface IProps {
  setSelectedData: any;
  selectedData: Array<any>;
  setDataChecked: any;
  prefixNameChildren?: string;
  columnsCustom?: columnNamesType[];
}

function EmployeeSelectedTable(props: IProps) {
  const { lang } = useMultiLanguage();
  const { selectedData, setDataChecked, setSelectedData, prefixNameChildren = "listEmployee", columnsCustom } = props;

  const columns: columnNamesType[] = columnsCustom
    ? [...columnsCustom]
    : [
        {
          name: lang("INPUT.CODE"),
          field: "custom",
          headerCellProps: {
            minWidth: "200px"
          },
          bodyCellProps: {
            textAlign: "left"
          },
          render: (row: any) => (
            <span>
              <p className="color-steel-blue fw-600 spaces p-0 m-0">{row?.maNhanVien}</p>
              <p className="spaces p-0 m-0">{row?.name}</p>
            </span>
          )
        },
        {
          name: lang("INPUT.SALARY.POSITION"),
          field: "chucVuText",
          headerCellProps: {
            minWidth: "200px"
          },
          bodyCellProps: {
            textAlign: "left"
          }
        },
        {
          name: lang("GENERAL.EMPLOYEE.JOB_POSITION"),
          field: "viTriCongViecText",
          headerCellProps: {
            minWidth: "200px"
          },
          bodyCellProps: {
            textAlign: "left"
          }
        }
      ];

  return (
    <div className="table-modal-h">
      <TableCollapseCustom
        columnNameList={columns}
        data={selectedData}
        nameParent="phongBanText"
        nameChildren={prefixNameChildren}
        selectData={setDataChecked}
        setData={setSelectedData}
        selectionMode="multiple"
        isSelect={true}
        noToolbar={true}
        uniquePrefix="employeeId"
      />
    </div>
  );
}

export default EmployeeSelectedTable;
