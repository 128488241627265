import { Modal, Button, Row, Col, Form } from "react-bootstrap";
import useMultiLanguage from "../../../../../hook/useMultiLanguage";
import Autocomplete from "../../../../component/input-field/Autocomplete";
import TextValidator from "../../../../component/input-field/TextValidator";
import UploadFile from "../../../../component/FileUpload/UploadFile";
import { FILE_TYPE } from "../../../../component/FileUpload/constant";
import { useEffect, useState } from "react";
import { BonusInfo } from "../../../../bonus/model/BonusModel";
import { INIT_BONUS_INFO } from "../../../../bonus/consts/BonusConst";
import { convertDataUI } from "../../../../bonus/utils/utils";

interface IProps {
  open: boolean;
  handleClose: () => void;
  bonusInfo: any;
  isView?: boolean;
}

function BonusViewDialog(props: IProps) {
  const { open, handleClose, isView = true, bonusInfo } = props;
  const { lang } = useMultiLanguage();

  const [data, setData] = useState<BonusInfo>(INIT_BONUS_INFO);

  useEffect(() => {
    if (bonusInfo?.id) {
      setData(convertDataUI(bonusInfo));
    }
  }, [bonusInfo]);

  return (
    <Modal
      show={open}
      onHide={handleClose}
      centered
      size="xl"
    >
      <Modal.Header
        closeButton
        className="spaces py-12"
      >
        <Modal.Title className="spaces fs-20 fw-600">{lang("BONUS.INFO")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row className="pt-2 gap-col-modal">
          <Col xs={4}>
            <Autocomplete
              isRequired
              name="doiTuongKhenThuong"
              lable={lang("APPRECIATIVE.DOITUONGKHENTHUONGTEXT")}
              isReadOnly={isView}
              value={data?.doiTuongKhenThuong || null}
              options={[]}
            />
          </Col>
          <Col xs={4}>
            <TextValidator
              isRequired
              name="dotKhenThuong"
              lable={lang("APPRECIATIVE.NAME")}
              readOnly={isView}
              type="text"
              value={data?.dotKhenThuong || ""}
            />
          </Col>
          <Col xs={4}>
            <Autocomplete
              isRequired
              name="loaiKhenThuong"
              lable={lang("APPRECIATIVE.LOAIKHENTHUONGTEXT")}
              isReadOnly={isView}
              value={data?.loaiKhenThuong || null}
              options={[]}
            />
          </Col>
          <Col xs={4}>
            <TextValidator
              isRequired
              name="namKhenThuong"
              lable={lang("APPRECIATIVE.YEAR")}
              readOnly={isView}
              type="number"
              value={data?.namKhenThuong || ""}
            />
          </Col>
          <Col xs={4}>
            <Autocomplete
              isRequired
              name="hinhThucKhenThuong"
              lable={lang("APPRECIATIVE.HINHTHUCKHENTHUONGTEXT")}
              isReadOnly={isView}
              value={data?.hinhThucKhenThuong || null}
              options={[]}
              getOptionLabel={(options) => options?.value}
            />
          </Col>
          <Col xs={4}>
            <TextValidator
              name="noiDungKhenThuong"
              lable={lang("APPRECIATIVE.CONTENT")}
              readOnly={isView}
              type="text"
              value={data?.noiDungKhenThuong || ""}
            />
          </Col>
          <Col xs={4}>
            <Autocomplete
              name="danhHieuThiDua"
              lable={lang("BONUS.EMULATION")}
              isReadOnly={isView}
              value={data?.danhHieuThiDua || null}
              options={[]}
              getOptionLabel={(options) => options?.value}
            />
          </Col>
          <Col xs={4}>
            <Autocomplete
              isRequired
              name="capQuyetDinh"
              lable={lang("APPRECIATIVE.DECISION_LEVEL")}
              isReadOnly={isView}
              value={data?.capQuyetDinh || null}
              options={[]}
            />
          </Col>
          <Col xs={4}>
            <TextValidator
              name="ngayBanHanh"
              lable={lang("APPRECIATIVE.NGAYQUYETDINH")}
              readOnly={isView}
              type="date"
              value={data?.ngayBanHanh || ""}
            />
          </Col>
          <Col xs={4}>
            <TextValidator
              isRequired
              name="ngayKhenThuong"
              lable={lang("APPRECIATIVE.DATE")}
              readOnly={isView}
              type="date"
              value={data?.ngayKhenThuong || ""}
            />
          </Col>
          <Col xs={4}>
            <TextValidator
              name="soQuyetDinh"
              lable={lang("APPRECIATIVE.SOQUYETDINH")}
              readOnly={isView}
              type="text"
              value={data?.soQuyetDinh || ""}
            />
          </Col>
          <Col xs={4}>
            <Autocomplete
              name="donViBanHanh"
              lable={lang("APPRECIATIVE.AGENCIES_UNIT_DECITION")}
              isReadOnly={isView}
              value={data?.donViBanHanh || null}
              options={[]}
            />
          </Col>
          <Col xs={4}>
            <UploadFile
              label="CONTRACT.ATTACHMENTS"
              setValue={() => {}}
              fileValue={{
                id: data?.fileId || "",
                name: data?.fileName || ""
              }}
              allowFileTypes={`${FILE_TYPE.PDF},${FILE_TYPE.DOC},${FILE_TYPE.DOCX},${FILE_TYPE.XLS},${FILE_TYPE.XLSX}`}
              isReadOnly={true}
            />
          </Col>
          <Col xs={4}>
            <Autocomplete
              name="trangThai"
              lable={lang("APPRECIATIVE.TRANGTHAITEXT")}
              isReadOnly={isView}
              value={data?.trangThai || null}
              options={[]}
            />
          </Col>
          <Col xs={4}>
            <Autocomplete
              name="nguoiKyQuyetDinh"
              lable={lang("APPRECIATIVE.PERSON_SINGING")}
              isReadOnly={isView}
              value={data?.nguoiKyQuyetDinh || null}
              options={[]}
            />
          </Col>
          <Col xs={5}>
            <Row>
              <Col xs={6}>
                <Form.Check
                  label={lang("APPRECIATIVE.SALARY_AUJUSTED")}
                  name="daXetDieuChinhLuong"
                  checked={data?.daXetDieuChinhLuong}
                  disabled={isView}
                  className="checkBox custom-form-check"
                />
              </Col>
              <Col xs={6}>
                <Form.Check
                  label={lang("APPRECIATIVE.MAX")}
                  name="khenThuongCaoNhat"
                  checked={data?.khenThuongCaoNhat}
                  disabled={isView}
                  className="checkBox custom-form-check"
                />
              </Col>
            </Row>
          </Col>
          <Col xs={7}>
            <Row>
              <Col xs={6}>
                <Form.Check
                  label={lang("APPRECIATIVE.SALARY_AUJUSTMENT_TIME")}
                  name="ahtgDieuChinhLuong"
                  checked={data?.ahtgDieuChinhLuong}
                  disabled={isView}
                  className="checkBox custom-form-check"
                />
              </Col>
              <Col xs={6}>
                <TextValidator
                  isRequired={data?.ahtgDieuChinhLuong}
                  name="thoiGianHuongLuongKeoDai"
                  lable={lang("APPRECIATIVE.SALARY_AFFECT_TIME")}
                  readOnly={isView}
                  type="number"
                  value={data?.thoiGianHuongLuongKeoDai || ""}
                />
              </Col>
            </Row>
          </Col>
          <Col xs={12}>
            <TextValidator
              name="ghiChu"
              lable={lang("ALLOWANCE.NOTE")}
              as="textarea"
              rows="3"
              readOnly={isView}
              value={data?.ghiChu || ""}
            />
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer className="flex-center">
        <Button
          variant="outline-secondary"
          className="button-secondary btn-sm"
          onClick={handleClose}
        >
          {lang("BTN.CANCEL")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default BonusViewDialog;
