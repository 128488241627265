import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import useMultiLanguage from "../../../../hook/useMultiLanguage";
import { useContext, useEffect, useState } from "react";
import { handleBlurDate } from "../../../utils/FunctionUtils";
import { RESPONSE_STATUS_CODE, SEARCH_OBJECT_MAX_SIZE, TYPE, VARIABLE_STRING } from "../../../utils/Constant";
import { getAllDonVi, getEmployeeProfileById, searchAllEmployee } from "../../../profile/services/ProfileServices";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { useFormik } from "formik";
import { IFile } from "../../../profile/models/DialogModels";
import TableCustom from "../../../component/table-custom/TableCustom";
import Autocomplete from "../../../component/input-field/Autocomplete";
import TextValidator from "../../../component/input-field/TextValidator";
import { FILE_TYPE } from "../../../component/FileUpload/constant";
import UploadFile from "../../../component/FileUpload/UploadFile";
import { searchNguoiDaiDienKy } from "../../../contract/services/contractServices";
import AppContext from "../../../../AppContext";
import { TransitionInFo, danhSachDieuChuyenInfo } from "../../../transition/models/transitionModel";
import { convertDataSubmit } from "../../../transition/utils/FunctionUtils";
import { addNewTransfer } from "../../../transition/services/TransitionServices";
import {
  INIT_TRANSITION,
  LIST_TRANSFER_OBJECT,
  LIST_TRANSFER_STATUS,
  LIST_TRANSFER_STATUS_CODE,
  TRANSFER_PEOPLE
} from "../../../transition/const/transitionConst";
import { getAllSimpleValue, getListWorkUnit } from "../../../utils/CategoryServices";
import { variantStandardStyle } from "../../../component/input-field/StyleComponent";
import { TYPE_CATEGORY } from "../../../constant";
import { searchAllSimpleValue } from "../../../services";
import { searchPhongBan_DonVi } from "../../../profile/services/DialogServices";
import { RangeDatePicker } from "../../../component/input-field/RangeDatePicker";
import { MultiSelectEmployee } from "../../../component/dialog/MultiSelectEmployee";
import { ColCustom, RowCustom } from "../../../component/GridComponent";

interface IProps {
  open: boolean;
  handleClose: () => void;
  transferInfo: TransitionInFo;
  isView: boolean;
  handleReload: () => any;
  warningInfo: any;
}

function TransitionWarningDialog(props: IProps) {
  const { lang } = useMultiLanguage();
  const { setPageLoading } = useContext(AppContext);
  const { transferInfo, isView, handleReload, open, handleClose, warningInfo } = props;

  const [transferList, setTransferList] = useState<danhSachDieuChuyenInfo[]>([]);
  const [openEmployeeDialog, setOpenEmployeeDialog] = useState<boolean>(false);
  const [isViewOnly, setIsViewOnly] = useState<boolean>(false);

  const validationSchema = Yup.object().shape({
    doiTuong: Yup.object().required(lang("VALIDATION.REQUIRE")).nullable(),
    employee: Yup.object().required(lang("VALIDATION.REQUIRE")).nullable(),
    loaiDieuDong: Yup.object().required(lang("VALIDATION.REQUIRE")).nullable(),
    viTriCongViecText: Yup.string().required(lang("VALIDATION.REQUIRE")).nullable(),
    chucVuCuText: Yup.string().required(lang("VALIDATION.REQUIRE")).nullable(),
    donViCongTacText: Yup.string().required(lang("VALIDATION.REQUIRE")).nullable(),
    khoaPhongText: Yup.string().required(lang("VALIDATION.REQUIRE")).nullable(),
    donViQuyetDinh: Yup.object().required(lang("VALIDATION.REQUIRE")).nullable(),
    nguoiKy: Yup.object().required(lang("VALIDATION.REQUIRE")).nullable(),
    coHieuLucTuNgay: Yup.date().required(lang("VALIDATION.REQUIRE")).nullable(),
    coHieuLucDenNgay: Yup.date()
      .min(Yup.ref("coHieuLucTuNgay"), lang("VALIDATION.MINDATE") + lang("GENERAL_EFFECTIVE_DATE_START"))
      .nullable(),
    soQuyetDinh: Yup.string().required(lang("VALIDATION.REQUIRE")).nullable().max(255, lang("VALIDATION.MAX255")),
    donViCongTacMoi: Yup.object().required(lang("VALIDATION.REQUIRE")).nullable(),
    khoaPhongMoi: Yup.object().required(lang("VALIDATION.REQUIRE")).nullable(),
    chucVuMoi: Yup.object().required(lang("VALIDATION.REQUIRE")).nullable(),
    trangThai: Yup.object().required(lang("VALIDATION.REQUIRE")).nullable()
  });

  const handleSubmit = async (values: TransitionInFo) => {
    const dataSubmit = convertDataSubmit(values);
    try {
      setPageLoading(true);
      const res = await addNewTransfer(dataSubmit);
      if (res?.data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
        toast.success(lang("TOAST.CREATE.SUCCESS"));
        await handleReload();
        handleClose();
      } else {
        toast.error(`${res?.data?.message}`);
      }
    } catch (err) {
      toast.error(lang("GENERAL.ERROR"));
    } finally {
      setPageLoading(false);
    }
  };

  const formik = useFormik({
    initialValues: INIT_TRANSITION,
    validationSchema,
    onSubmit: handleSubmit
  });

  useEffect(() => {
    if (transferInfo?.id) {
      formik.setValues({
        ...transferInfo,
        loaiDieuDong: {
          id: transferInfo?.loaiDieuDongId,
          value: transferInfo?.loaiDieuDongText
        }
      });
      setIsViewOnly(transferInfo?.trangThai?.code === LIST_TRANSFER_STATUS_CODE.COMPLETED);
    }
    formik.setFieldValue(
      "doiTuong",
      LIST_TRANSFER_OBJECT.find((option) => option.code === TRANSFER_PEOPLE.CA_NHAN)
    );
  }, [transferInfo]);

  useEffect(() => {
    if (warningInfo?.id) {
      getEmployeeInfo(warningInfo?.employeeId);
    }
  }, [warningInfo]);

  const getEmployeeInfo = async (id: string) => {
    try {
      setPageLoading(true);
      const { data } = await getEmployeeProfileById(id);
      if (data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
        handleEmployeeChange(data?.data);
      }
    } catch (error) {
      console.error(error);
      toast.error(lang("GENERAL.ERROR"));
    } finally {
      setPageLoading(false);
    }
  };

  const handleChangeSelect = (name: string, value: any) => {
    switch (name) {
      case "doiTuong":
        formik.setValues(INIT_TRANSITION);
        setTransferList([]);
        formik.setFieldValue(name, value);
        break;
      case "donViCongTacMoi":
        formik.setFieldValue("khoaPhongMoi", null);
        formik.setFieldValue("donViCongTacMoi", value);
        break;
      default:
        formik.setFieldValue(name, value);
        break;
    }
  };

  const handleEmployeeChange = (employeeInfo: any) => {
    formik.setValues({
      ...formik.values,
      employee: employeeInfo,
      employeeId: employeeInfo.id,
      fullName: employeeInfo.name,
      code: employeeInfo.maNhanVien || "",
      viTriCongViecText: employeeInfo.viTriCongViecText || "",
      viTriCongViecId: employeeInfo.viTriCongViecId || "",
      chucVuCuId: employeeInfo.chucVuId || "",
      chucVuCuText: employeeInfo.chucVuText || "",
      donViCongTacText: employeeInfo.donViCongTacText || "",
      donViCongTacId: employeeInfo.donViCongTacId || "",
      khoaPhongId: employeeInfo.phongBanId || "",
      khoaPhongText: employeeInfo.phongBanText || ""
    });
  };

  const handleChangeCheckBox = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;
    formik.setFieldValue(name, checked);
  };

  const handleOpenAddEmployeeDialog = () => {
    setOpenEmployeeDialog(true);
  };

  const handleCloseAddEmployeeDialog = () => {
    setOpenEmployeeDialog(false);
  };

  const TransferListColumn = [
    {
      name: "STT",
      field: "",
      render: (row: any, index: number, stt: number) => <span>{stt}</span>
    },
    {
      name: lang("TRANSFER.CANBOCCVC"),
      field: "name",
      headerStyle: {
        minWidth: "150px"
      },
      cellStyle: {
        minWidth: "150px",
        textAlign: "left"
      }
    },
    {
      name: lang("INPUT.WORKPROCESS.UNIT.OLD"),
      field: "donViCongTac",
      headerStyle: {
        minWidth: "80px"
      },
      render: (row: any) => <span>{row?.donViCongTacText}</span>
    },
    {
      name: lang("GENERAL.EMPLOYEE.POSITION"),
      field: "chucVu",
      headerStyle: {
        minWidth: "80px"
      },
      render: (row: any) => <span>{row?.chucVuText}</span>
    },
    {
      name: lang("INPUT.WORKPROCESS.UNIT.NEW"),
      field: "donViCongTacMoi",
      headerStyle: {
        minWidth: "80px"
      },
      cellStyle: {
        textAlign: "left"
      },
      render: (rowData: any, rowId: any) => (
        <Autocomplete
          name="donViCongTacMoi"
          searchFunction={getListWorkUnit}
          searchObject={{}}
          onChange={(value) => handleChangeSelect(value, VARIABLE_STRING.DON_VI_CONG_TAC_MOI)}
          value={rowData?.donViCongTacMoi || null}
          options={[]}
          styles={variantStandardStyle}
        />
      )
    },
    {
      name: lang("INPUT.WORKPROCESS.ROLE.NEW"),
      field: "chucVuMoi",
      headerStyle: {
        minWidth: "150px"
      },
      cellStyle: {
        textAlign: "left"
      },
      render: (rowData: any, rowId: any) => {
        return (
          <Autocomplete
            isReadOnly={isView}
            searchFunction={getAllSimpleValue}
            searchObject={TYPE_CATEGORY.chucDanh}
            options={[]}
            value={rowData?.chucVuMoi || null}
            name="chucVuMoi"
            onChange={(value) => handleChangeSelect(value, VARIABLE_STRING.CHUC_VU)}
            getOptionLabel={(option) => option?.value}
            styles={variantStandardStyle}
          />
        );
      }
    }
  ];

  const handleUploadFile = (data: IFile) => {
    formik.setFieldValue("fileId", data.id);
    formik.setFieldValue("fileName", data.name);
  };

  return (
    <Modal
      show={open}
      size="xl"
      centered
      aria-labelledby="example-custom-modal-styling-title"
      onHide={handleClose}
      className="modal-size-custom"
    >
      <Form onSubmit={formik.handleSubmit}>
        <Modal.Header closeButton>
          <Modal.Title
            id="example-custom-modal-styling-title"
            className="heading-5"
          >
            {lang("TRANSFER.ADD")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="px-8 py-2 contract-dialog-content">
          <div className="form-container bg-white">
            <div className="p-4 w-100">
              <Row>
                <Col
                  xs={12}
                  md={4}
                  lg={4}
                  xxl={3}
                >
                  <Autocomplete
                    isRequired
                    lable={lang("TRANSFER_TYPE_OF_GROUP")}
                    options={LIST_TRANSFER_OBJECT}
                    isReadOnly={isView}
                    value={formik.values?.doiTuong || null}
                    name="doiTuong"
                    onChange={(selectedOption) => handleChangeSelect("doiTuong", selectedOption)}
                    isClearable={false}
                    touched={formik.touched?.doiTuong}
                    errors={formik.errors?.doiTuong}
                  />
                </Col>
              </Row>
              {formik?.values?.doiTuong?.code === TRANSFER_PEOPLE.CA_NHAN && (
                <RowCustom
                  xs={5}
                  lg={4}
                  xl={4}
                  xxl={5}
                >
                  <ColCustom
                    xs={5}
                    lg={4}
                    xl={4}
                    xxl={5}
                    className="pt-4"
                  >
                    <span className="fs-3 fw-bold">Thông tin cán bộ CCVC</span>
                  </ColCustom>
                  <ColCustom
                    xs={1}
                    className="pt-3"
                  >
                    <Autocomplete
                      lable={lang("INPUT_APPOINTMENT_NAME")}
                      isReadOnly={true}
                      searchFunction={searchAllEmployee}
                      searchObject={{ ...SEARCH_OBJECT_MAX_SIZE, isDangLamViec: true }}
                      options={[]}
                      isRequired={true}
                      value={formik.values?.employee || ""}
                      name="employee"
                      maxMenuHeight={270}
                      onChange={(selectedOption) => handleEmployeeChange(selectedOption)}
                      errors={formik.errors?.employee}
                      touched={formik.touched?.employee}
                      getOptionLabel={(option) => `${option?.maNhanVien} - ${option?.name}`}
                    />
                  </ColCustom>
                  <ColCustom
                    xs={1}
                    className="pt-3"
                  >
                    <TextValidator
                      name="code"
                      lable={lang("PROFILE.CODE")}
                      type="text"
                      value={formik.values?.code}
                      readOnly={true}
                    />
                  </ColCustom>
                  <ColCustom
                    xs={1}
                    className="pt-3"
                  >
                    <TextValidator
                      name="donViCongTacText"
                      lable={lang("INPUT.WORKPROCESS.UNIT.OLD")}
                      type="text"
                      value={formik.values?.donViCongTacText}
                      readOnly={true}
                      isRequired
                      errors={formik?.errors?.donViCongTacText}
                      touched={formik?.touched?.donViCongTacText}
                    />
                  </ColCustom>
                  <ColCustom
                    xs={1}
                    className="pt-3"
                  >
                    <TextValidator
                      name="khoaPhongText"
                      lable={lang("INPUT.WORKPROCESS.DEPARTMENT.OLD")}
                      type="text"
                      value={formik.values?.khoaPhongText}
                      readOnly={true}
                      isRequired
                      errors={formik?.errors?.khoaPhongText}
                      touched={formik?.touched?.khoaPhongText}
                    />
                  </ColCustom>
                  <ColCustom
                    xs={1}
                    className="pt-3"
                  >
                    <TextValidator
                      name="chucVuCuText"
                      lable={lang("RESIGN.OLDPOSITION")}
                      type="text"
                      value={formik.values?.chucVuCuText}
                      readOnly={true}
                      isRequired
                      errors={formik?.errors?.chucVuCuText}
                      touched={formik?.touched?.chucVuCuText}
                    />
                  </ColCustom>
                  <ColCustom
                    xs={1}
                    className="pt-3"
                  >
                    <TextValidator
                      name="viTriCongViecText"
                      lable={lang("INPUT.JOB_POSITION_OLD")}
                      type="text"
                      isRequired
                      value={formik.values?.viTriCongViecText}
                      readOnly={true}
                      errors={formik?.errors?.viTriCongViecText}
                      touched={formik?.touched?.viTriCongViecText}
                    />
                  </ColCustom>
                </RowCustom>
              )}
              <RowCustom
                xs={5}
                lg={4}
                xl={4}
                xxl={5}
              >
                <ColCustom
                  xs={5}
                  lg={4}
                  xl={4}
                  xxl={5}
                  className="pt-4"
                >
                  <span className="fs-3 fw-bold">Thông tin điều chuyển - luân chuyển</span>
                </ColCustom>
                <ColCustom
                  xs={1}
                  className="pt-3"
                >
                  <Autocomplete
                    lable={lang("TRANSFER.LOAIDIEUDONG")}
                    isReadOnly={isView}
                    options={[]}
                    value={formik.values?.loaiDieuDong || null}
                    name="loaiDieuDong"
                    isRequired
                    onChange={(selectedOption) => handleChangeSelect("loaiDieuDong", selectedOption)}
                    searchFunction={searchAllSimpleValue}
                    searchObject={{ ...SEARCH_OBJECT_MAX_SIZE, type: TYPE_CATEGORY.loaiDieuDong }}
                    errors={formik.errors?.loaiDieuDong}
                    touched={formik.touched?.loaiDieuDong}
                    getOptionLabel={(option) => option?.value}
                  />
                </ColCustom>
                <ColCustom
                  xs={1}
                  className="pt-3"
                >
                  <Autocomplete
                    name="donViCongTacMoi"
                    lable={lang("RESIGN.NEW_ORGANIZATION")}
                    searchFunction={getAllDonVi}
                    searchObject={SEARCH_OBJECT_MAX_SIZE}
                    onChange={(value) => handleChangeSelect(VARIABLE_STRING.DON_VI_CONG_TAC_MOI, value)}
                    value={formik.values.donViCongTacMoi || null}
                    isRequired
                    options={[]}
                    errors={formik?.errors?.donViCongTacMoi}
                    touched={formik?.touched?.donViCongTacMoi}
                  />
                </ColCustom>
                <ColCustom
                  xs={1}
                  className="pt-3"
                >
                  <Autocomplete
                    lable={lang("INPUT.WORKPROCESS.DEPARTMENT.NEW")}
                    isReadOnly={isView}
                    searchFunction={searchPhongBan_DonVi}
                    searchObject={{ id: formik.values?.donViCongTacMoi?.id }}
                    options={[]}
                    isRequired
                    value={formik.values?.khoaPhongMoi || null}
                    name="khoaPhongMoi"
                    urlData="data.data"
                    maxMenuHeight={220}
                    onChange={(selectedOption) => handleChangeSelect("khoaPhongMoi", selectedOption)}
                    errors={formik.errors?.khoaPhongMoi}
                    touched={formik.touched?.khoaPhongMoi}
                    dependencies={[formik.values?.donViCongTacMoi]}
                  />
                </ColCustom>
                {formik?.values?.doiTuong?.code === TRANSFER_PEOPLE.CA_NHAN && (
                  <ColCustom
                    xs={1}
                    className="pt-3"
                  >
                    <Autocomplete
                      lable={lang("INPUT_APPOINTMENT_NEW_JOB_POSITION")}
                      isReadOnly={isView}
                      searchFunction={getAllSimpleValue}
                      searchObject={TYPE_CATEGORY.chucDanh}
                      options={[]}
                      isRequired
                      value={formik.values?.chucVuMoi || null}
                      maxMenuHeight={220}
                      name="chucVuMoi"
                      onChange={(selectedOption) => handleChangeSelect("chucVuMoi", selectedOption)}
                      errors={formik.errors?.chucVuMoi}
                      touched={formik.touched?.chucVuMoi}
                      getOptionLabel={(option) => option?.value}
                    />
                  </ColCustom>
                )}
                <ColCustom
                  xs={1}
                  className="pt-3"
                >
                  <UploadFile
                    isReadOnly={isView}
                    label="CONTRACT.ATTACHMENTS"
                    setValue={handleUploadFile}
                    fileValue={{
                      id: formik.values.fileId || "",
                      name: formik.values.fileName || ""
                    }}
                    allowFileTypes={`${FILE_TYPE.PDF},${FILE_TYPE.DOC},${FILE_TYPE.DOCX},${FILE_TYPE.XLS},${FILE_TYPE.XLSX}`}
                  />
                </ColCustom>
                <ColCustom
                  xs={2}
                  className="pt-3"
                >
                  <RowCustom xs={3}>
                    <ColCustom xs={1}>
                      <Form.Check
                        label={lang("CONTRACT.PAPER_AUTHORITY")}
                        checked={formik.values?.uyQuyen}
                        onChange={handleChangeCheckBox}
                        className="custom-form-check"
                        readOnly={isView}
                        disabled={isView}
                        name="uyQuyen"
                      />
                    </ColCustom>
                    <ColCustom xs={2}>
                      <RangeDatePicker
                        isRequired
                        label={lang("ALLOWANCE.TIME_EFFECT")}
                        startDateName="coHieuLucTuNgay"
                        endDateName="coHieuLucDenNgay"
                        isView={isView}
                        handleChange={formik.handleChange}
                        value={formik.values}
                        setFieldValue={formik.setFieldValue}
                        touch={formik.touched}
                        errors={formik.errors}
                      />
                    </ColCustom>
                  </RowCustom>
                </ColCustom>
                <ColCustom
                  xs={1}
                  className="pt-3"
                >
                  <Autocomplete
                    isRequired={true}
                    lable={lang("TRANSFER.DONVIQUYETDINH")}
                    isReadOnly={isView}
                    options={[]}
                    value={formik.values?.donViQuyetDinh || null}
                    name="donViQuyetDinh"
                    onChange={(selectedOption) => handleChangeSelect("donViQuyetDinh", selectedOption)}
                    searchFunction={getAllDonVi}
                    searchObject={SEARCH_OBJECT_MAX_SIZE}
                    touched={formik.touched?.donViQuyetDinh}
                    errors={formik.errors?.donViQuyetDinh}
                  />
                </ColCustom>
                <ColCustom
                  xs={1}
                  className="pt-3"
                >
                  <TextValidator
                    name="ngayQuyetDinh"
                    lable={lang("INPUT.WORKPROCESS.DECISION.DATE")}
                    type="date"
                    value={formik.values?.ngayQuyetDinh}
                    readOnly={isView}
                    onChange={formik.handleChange}
                    onBlur={() => handleBlurDate(formik.setFieldValue, formik.values?.ngayQuyetDinh, "ngayQuyetDinh")}
                    errors={formik?.errors?.ngayQuyetDinh}
                    touched={formik?.touched?.ngayQuyetDinh}
                  />
                </ColCustom>
                <ColCustom
                  xs={1}
                  className="pt-3"
                >
                  <TextValidator
                    name="soQuyetDinh"
                    lable={lang("INPUT_APPOINTMENT_NUMBER")}
                    type="text"
                    value={formik.values?.soQuyetDinh}
                    readOnly={isView}
                    isRequired
                    onChange={formik.handleChange}
                    errors={formik?.errors?.soQuyetDinh}
                    touched={formik?.touched?.soQuyetDinh}
                  />
                </ColCustom>
                <ColCustom
                  xs={1}
                  className="pt-3"
                >
                  <Autocomplete
                    lable={lang("TRANSFER.NGUOIKI")}
                    isReadOnly={isView}
                    options={[]}
                    isRequired
                    value={formik.values?.nguoiKy || null}
                    name="nguoiKy"
                    searchFunction={searchNguoiDaiDienKy}
                    searchObject={{}}
                    onChange={(selectedOption) => handleChangeSelect("nguoiKy", selectedOption)}
                    errors={formik.errors?.nguoiKy}
                    touched={formik.touched?.nguoiKy}
                  />
                </ColCustom>
                <ColCustom
                  xs={1}
                  className="pt-3"
                >
                  <Autocomplete
                    lable={lang("GENERAL.STATUS")}
                    isReadOnly={isViewOnly ? isViewOnly : isView}
                    options={LIST_TRANSFER_STATUS}
                    value={formik.values?.trangThai || null}
                    name="trangThai"
                    menuPlacement="top"
                    isRequired
                    onChange={(selectedOption) => handleChangeSelect("trangThai", selectedOption)}
                    errors={formik.errors?.trangThai}
                    touched={formik.touched?.trangThai}
                  />
                </ColCustom>
                <ColCustom
                  xs={5}
                  lg={4}
                  xl={4}
                  xxl={5}
                  className="pt-3"
                >
                  <TextValidator
                    className="h-auto-input"
                    lable={lang("ALLOWANCE.NOTE")}
                    name="ghiChu"
                    value={formik.values.ghiChu}
                    type="text"
                    readOnly={isView}
                    rows={3}
                    onChange={formik.handleChange}
                    as={TYPE.TEXTAREA}
                  />
                </ColCustom>
              </RowCustom>
            </div>
          </div>
          {formik?.values?.doiTuong?.code === TRANSFER_PEOPLE.TAP_THE && (
            <Row className="bg-light p-4">
              <Col xs={12}>
                <TableCustom
                  title={lang("ISSUE.LIST_EMPLOYEES")}
                  data={transferList}
                  columns={TransferListColumn}
                  fixedColumnsCount={2}
                  handleOpenDialog={handleOpenAddEmployeeDialog}
                  isActionTableTab
                  buttonAdd={!isView}
                  buttonExportExcel={true}
                  notDelete={false}
                  noToolbar={true}
                  justFilter={true}
                  type={TYPE.MULTILINE}
                  noPagination={true}
                />
              </Col>
            </Row>
          )}
        </Modal.Body>

        <Modal.Footer className="flex-center">
          <Button
            variant="outline-secondary"
            className="button-secondary btn-sm"
            onClick={() => handleClose()}
          >
            {lang("BTN.CANCEL")}
          </Button>
          <Button
            variant="primary"
            className="button-primary btn-sm"
            type="submit"
          >
            {lang("BTN.SAVE")}
          </Button>
        </Modal.Footer>
      </Form>
      {openEmployeeDialog && (
        <MultiSelectEmployee
          listChecked={transferList}
          setListChecked={setTransferList}
          handleClose={handleCloseAddEmployeeDialog}
        />
      )}
    </Modal>
  );
}

export default TransitionWarningDialog;
