import axios, { AxiosResponse } from "axios";
import { APIResponse } from "../../models/models";
import { IAuth, IRole, IUnit, IUser, IuserByUnit } from "../model/ManagerModel";
import { DEFAULT_PAGE_INDEX, MAX_PAGE_SIZE } from "../../utils/PageUtils";
const API_URL = process.env.REACT_APP_API_URL;
const API_V1_URL = process.env.REACT_APP_API_URL + "/api/v1";

export const getListAuth = (searchData?: any): Promise<AxiosResponse<APIResponse>> => {
  let config = {
    params: {
      ...searchData,
      pageSize: searchData?.pageSize || MAX_PAGE_SIZE,
      pageIndex: searchData?.pageIndex || DEFAULT_PAGE_INDEX,
    },
  };
  let url = `${API_URL}/api/v1/authorities/page`;
  return axios.get(url, config);
};
export const getListRole = (searchData?: any): Promise<AxiosResponse<APIResponse>> => {
  let config = {
    params: {
      ...searchData,
      pageSize: searchData?.pageSize || MAX_PAGE_SIZE,
      pageIndex: searchData?.pageIndex || DEFAULT_PAGE_INDEX,
    },  
  };
  let url = `${API_URL}/api/v1/roles/page`;
  return axios.get(url, config);
};
export const getListUnit = (searchData?: any) => {
  let config = {
    params: {
      ...searchData,
      pageSize: searchData?.pageSize || MAX_PAGE_SIZE,
      pageIndex: searchData?.pageIndex || DEFAULT_PAGE_INDEX,
    },  
  };
  let url = `${API_V1_URL}/organizations/page`;
  return axios.get(url, config);
};
export const getListUser = (searchData?: any): Promise<AxiosResponse<APIResponse>> => {
  let config = {
    params: {
      ...searchData,
      pageSize: searchData?.pageSize || 10,
      pageIndex: searchData?.pageIndex || 1,
    },  
  };
  let url = `${API_URL}/user-organization/page`;
  return axios.get(url, config);
};
export const updateUser = (id: string, user: IuserByUnit): Promise<AxiosResponse<APIResponse>> => {
  let url = `${API_URL}/user-organization/${id}`;
  return axios.put(url, user);
};
export const addNewUser = (user: IuserByUnit): Promise<AxiosResponse<APIResponse>> => {
  let url = `${API_URL}/user-organization`;
  return axios.post(url, user);
};
export const deleteUser = (ids: string[]): Promise<AxiosResponse<APIResponse>> => {
  let config = {
    params :{
      ids:ids?.toString()
    }
  }
  let url = `${API_URL}/user-organization/list-id`;
  return axios.delete(url,config);
};
export const updateAuth = (id: string, auth: IAuth): Promise<AxiosResponse<APIResponse>> => {
  let url = `${API_URL}/api/v1/authorities/${id}`;
  return axios.put(url, auth);
};
export const addNewAuth = (auth: IAuth): Promise<AxiosResponse<APIResponse>> => {
  let url = `${API_URL}/api/v1/authorities`;
  return axios.post(url, auth);
};
export const deleteAuth = (ids: string[]): Promise<AxiosResponse<APIResponse>> => {
  let url = `${API_URL}/api/v1/authorities/${ids}`;
  return axios.delete(url);
};
export const updateRole = (id: string, role: IRole): Promise<AxiosResponse<APIResponse>> => {
  let url = `${API_URL}/api/v1/roles/${id}`;
  return axios.put(url, role);
};

export const addNewRole = (role: IRole): Promise<AxiosResponse<APIResponse>> => {
  let url = `${API_URL}/api/v1/roles`;
  return axios.post(url, role);
};

export const deleteRole = (ids: string[]): Promise<AxiosResponse<APIResponse>> => {
  let url = `${API_URL}/api/v1/roles/${ids}`;
  return axios.delete(url);
};
export const getAllRole = (ids: string[]): Promise<AxiosResponse<APIResponse>> => {
  let url = `${API_URL}/api/v1/roles`;
  return axios.get(url);
};
export const updateUnit = (id: string, unit: IUnit): Promise<AxiosResponse<APIResponse>> => {
  let url = `${API_V1_URL}/organizations/${id}`;
  return axios.put(url, unit);
};

export const addNewUnit = (unit: IUnit): Promise<AxiosResponse<APIResponse>> => {
  let url = `${API_V1_URL}/organizations`;
  return axios.post(url, unit);
};

export const deleteUnit = (ids: string[]): Promise<AxiosResponse<APIResponse>> => {
  let config = {
    params :{
      ids :ids?.toString()
    },  
  }
  let url = `${API_V1_URL}/organizations/list-id`;
  return axios.delete(url,config);
};

export const getUserById = (id?: any) => {
  let url = `${API_URL}/user-organization/${id}`;
  return axios.get(url);
};