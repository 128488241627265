/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Image } from "react-bootstrap";
import TextValue from "../../../component/TextValue";
import TableCustom from "../../../component/table-custom/TableCustom";
import { toAbsoluteUrl } from "../../../../../_metronic/helpers";
import { checkObject, formatDateTable } from "../../../utils/FunctionUtils";
import { MAPPING_LEVEL_RELATIONSHIP } from "../../const/ProfileConst";
import { checkEmptyArray } from "../../utils/FunctionUtils";
import TextUnderline from "../../../component/TextUnderline";

type Props = {
  dataEmployee?: any
}

export const Circulars322010TTBCA: React.FC<Props> = ({ dataEmployee: data }) => {
  const [workingProcess, setWorkingProcess] = useState<any>([]);
  const [listParent, setListParent] = useState<any>([]);
  const [listSiblings, setListSiblings] = useState<any>([]);
  const [listMemberInSmallFamily, setListMemberInSmallFamily] = useState<any>([]);
  const [listParentsInLaw, setListParentsInLaw] = useState<any>([]);

  useEffect(() => {
    if (!checkObject(data)) {
      let bc = (data?.chuyenMon || []).map((item: any) => {
        return {
          thoiGian: item?.bcThoiGianDaoTao,
          details: item?.bcCoSoDaoTao
        }
      });

      let qtct = (data?.quaTrinhCongTac || []).map((item: any) => {
        return {
          thoiGian: item?.qtctThoiGianDaoTao,
          details: item?.qtctDetails
        }
      });
      setWorkingProcess([...qtct, ...bc]);

      const combineQuanHeGiaDinh = [...data?.quanHeGiaDinhBt, ...data?.quanHeGiaDinhVc];
      setListParent(combineQuanHeGiaDinh.filter(item => item.qhgdMappingLevel === MAPPING_LEVEL_RELATIONSHIP.PARENT));
      setListSiblings(combineQuanHeGiaDinh.filter(item => item.qhgdMappingLevel === MAPPING_LEVEL_RELATIONSHIP.SIBLING));
      setListMemberInSmallFamily(combineQuanHeGiaDinh.filter(item => item.qhgdMappingLevel === MAPPING_LEVEL_RELATIONSHIP.SMALL_FAMILY));
      setListParentsInLaw(combineQuanHeGiaDinh.filter(item => item.qhgdMappingLevel === MAPPING_LEVEL_RELATIONSHIP.PARENT_IN_LAW));
    }
  }, [data]);
  
  const columnsWorkingProcess = [
    {
      name: "STT",
      field: "",
      cellStyle: {
        textAlign: "center",
      },
      render: (row: any, index: number, stt: number) => <span>{stt}</span>
    },
    {
      name: "Từ tháng năm đến tháng năm",
      field: "thoiGian",
      headerStyle: {
        minWidth: "200px",
        maxWidth: "200px"
      },
      render: (row: any) => row?.thoiGian ? <span>{row?.thoiGian}</span> : <TextValue className="spaces h-25" />},
    {
      name: "Đã học và tốt nghiệp những trường nào, ở đâu hoặc làm những công việc gì trong cơ  quan, đơn  vị, tổ chức nào, ở đâu?",
      field: "details",
      render: (row: any) => row?.details ? <span>{row?.details}</span> : <TextValue className="spaces h-25" />
    }
  ];

  const columnsHistoryProfile = [
    {
      name: "",
      field: "field",
      headerStyle: {
        height: "38px"
      },
      render: () => <TextValue className="spaces h-25" />
    },
    {
      name: "",
      field: "value",
      headerStyle: {
        minWidth: "54px",
        height: "38px"
      },
      render: () => <TextValue className="spaces h-25" />
    }
  ];

  const columnsBonus = [
    {
      name: "STT",
      field: "",
      render: (row: any, index: number, stt: number) => <span>{stt}</span>
    },
    {
      name: "Tháng, năm",
      field: "namKhenThuong",
      render: (row: any) => row?.namKhenThuong ? <span>{row?.namKhenThuong}</span> : <TextValue className="spaces h-25" />},
    {
      name: "Nội dung và hình thức khen thưởng",
      field: "khenThuongDanhHieu",
      render: (row: any) => row?.khenThuongDanhHieu ? <span>{row?.khenThuongDanhHieu}</span> : <TextValue className="spaces h-25" />
    },
    {
      name: "Cấp quyết định",
      field: "khenThuongDanhHieu",
      render: (row: any) => row?.khenThuongDanhHieu ? <span>{row?.khenThuongDanhHieu}</span> : <TextValue className="spaces h-25" />
    }
  ];

  const columnsDiscipline = [
    {
      name: "STT",
      field: "",
      render: (row: any, index: number, stt: number) => <span>{stt}</span>
    },
    {
      name: "Tháng, năm",
      field: "klTuNgay",
      render: (row: any) => row?.klTuNgay ? <span>{formatDateTable(row?.klTuNgay)}</span> : <TextValue className="spaces h-25" />
    },
    {
      name: "Lý do và hình thức kỷ luật",
      field: "klHinhThuc",
      headerStyle: {
        minWidth: "110px",
      },
      render: (row: any) => row?.klHinhThuc?.name ? <span>{row?.klHinhThuc?.name}</span> : <TextValue className="spaces h-25" />
    },
    {
      name: "Cấp quyết định",
      field: "klCoQuanQuyetDinh",
      render: (row: any) => row?.klCoQuanQuyetDinh ? <span>{row?.klCoQuanQuyetDinh}</span> : <TextValue className="spaces h-25" />
    }
  ];

  return (
    <div className="word">
      <p className="spaces text-center fw-light"><i>Mẫu số 02 ban hành kèm theo Thông tư số 32/2010/TT-BCA ngày 29/9/2010 của Bộ Công an</i></p>
      <p className="spaces d-flex flex-space-between heading fw-bold w-900 mx-auto">
        <p>BỘ CÔNG AN</p>
        <div className="text-center">
          <p className="spaces mb-0">CỘNG HOÀ XÃ HỘI CHỦ NGHĨA VIỆT NAM</p>
          <span className="spaces custom-text-underline fs-sm pb-8 px-4">Độc lập  -  Tự do  -  Hạnh phúc</span>
        </div>
      </p>
      <div className="flex spaces mt-20 mb-12 ml-40 flex-space-between flex-middle">
        <div className="image-profile">
          <Image src={toAbsoluteUrl("/media/avatars/blank.png")} alt="Ảnh 4x6cm có đóng dấu giáp lai" className="w-100 h-100" />
        </div>
        <p className="text-center spaces fs-xl fw-bold flex-grow-1">SƠ YẾU LÝ LỊCH</p>
      </div>
      <TextUnderline readOnly label="Họ và tên khai sinh" value={data?.name} />
      <TextUnderline readOnly label="Sinh ngày" value={`${data?.ngaySinh} tháng ${data?.thangSinh} năm ${data?.namSinh}`} />
      <TextUnderline readOnly label="Quê quán" value={data?.queQuan} />
      <TextUnderline readOnly label="Nơi đăng ký hộ khẩu thường trú" value={data?.hkDiachi} />
      <TextUnderline readOnly label="Chứng minh thư nhân dân số" value={data?.soCMNDOrCCCD} />
      <TextUnderline readOnly label="Cấp tại" value={data?.noiCapCMNDOrCCCD} />
      <TextUnderline readOnly label="Ngày cấp" value={data?.ngayCapCMNDOrCCCD} type="date" />
      <TextUnderline readOnly label="Số" className="spaces ml-auto w-200" />
      <TextUnderline readOnly label="Ký hiệu" className="spaces ml-auto w-200" />
      <TextUnderline readOnly label="Năm" className="spaces ml-auto w-200" />
      <p className="spaces text-center mt-12"><b>LƯU HÀNH NỘI BỘ</b></p>

      <TextUnderline readOnly label="Họ và tên khai sinh" value={data?.name} className="spaces mt-36" />
      <TextUnderline readOnly label="Tên gọi khác" value={data?.otherName} />
      <div className="flex">
      <TextUnderline readOnly label="Sinh ngày" value={`${data?.ngaySinh} tháng ${data?.thangSinh} năm ${data?.namSinh}`} />
        <TextUnderline readOnly label="Giới tính (Nam, Nữ)" value={data?.gender} />
      </div>
      <TextUnderline readOnly label="Nơi sinh" value={data?.noiSinh} />
      <TextUnderline readOnly label="Quê quán" value={data?.queQuan} />
      <div className="flex">
        <TextUnderline readOnly label="Dân tộc" value={data?.danTocText} />
        <TextUnderline readOnly label="Quốc tịch" value={data?.quocTich} />
        <TextUnderline readOnly label="Tôn giáo" value={data?.tonGiaoText} />
      </div>
      <TextUnderline readOnly label="Nơi đăng ký hộ khẩu thường trú" value={data?.hkDiachi} />
      <TextUnderline readOnly label="Nơi ở hiện nay" value={data?.hnDiaChi} />
      <TextUnderline readOnly label="Nghề nghiệp" />
      <TextUnderline readOnly label="Trình độ giáo dục phổ thông" value={data?.trinhDoGiaoDucPhoThongText} />
      <TextUnderline readOnly label="Trình độ chuyên môn cao nhất" value={data?.trinhDoChuyenMonCaoNhat} />
      <TextUnderline readOnly label="Lý luận chính trị" value={data?.trinhDoLyLuanChinhTri} />
      <div className="flex">
        <TextUnderline readOnly label="Ngoại ngữ" value={data?.trinhDoNgoaiNgu} />
        <TextUnderline readOnly label="Tin học" value={data?.trinhDoTinHoc} />
      </div>
      <TextUnderline readOnly label="Ngày vào Đoàn Thanh niên Cộng sản Hồ Chí Minh" value={data?.ngayVaoDoan} type="date" />
      <TextUnderline readOnly label="Nơi kết nạp" value={data?.noiKetNapDoan} />
      <div className="flex">
        <TextUnderline readOnly label="Ngày vào Đảng cộng sản Việt Nam" value={data?.ttctNgayVaoDang} type="date" />
        <TextUnderline readOnly label="Ngày chính thức" value={data?.ttctNgayChinhThuc} type="date" />
      </div>
      <TextUnderline readOnly label="Nơi kết nạp" value={data?.noiKetNapDang} />
      <div className="flex">
        <TextUnderline readOnly label="Tình trạng sức khỏe" value={data?.tinhTrangSucKhoe } />
        <TextUnderline readOnly label="Chiều cao" value={data?.chieuCao } />
        <TextUnderline readOnly label="Cân nặng" value={data?.canNang} />
        <TextUnderline readOnly label="Nhóm máu" value={data?.nhomMau} />
      </div>
      <TextUnderline readOnly label="Các bệnh kinh niên, truyền nhiễm" />
      <TextUnderline readOnly label="Gia đình chính sách" value={data?.chinhSach} />

      <p className="text-center spaces mt-36"><b>I - LỊCH SỬ BẢN THÂN</b></p>
      <p className="spaces mb-0"><b>1 - Tóm tắt quá trình học tập, công tác</b></p>
      <TableCustom
        columns={columnsWorkingProcess}
        data={checkEmptyArray(workingProcess)}
        buttonAdd={false}
        buttonExportExcel={false}
        justFilter
        noPagination
      />
      <p className="spaces mt-12 mb-0"><b>2 - Những đặc điểm về lịch sử bản thân</b></p>
      <TableCustom
        columns={columnsHistoryProfile}
        data={checkEmptyArray([])}
        buttonAdd={false}
        buttonExportExcel={false}
        justFilter
        noPagination
      />
      <p className="spaces mt-12 mb-0"><b>3 - Khen thưởng</b></p>
      <TableCustom
        columns={columnsBonus}
        data={checkEmptyArray(data?.khenThuong)}
        buttonAdd={false}
        buttonExportExcel={false}
        justFilter
        noPagination
      />
      <p className="spaces mt-12 mb-0"><b>4 - Kỷ luật</b></p>
      <TableCustom
        columns={columnsDiscipline}
        data={checkEmptyArray(data?.kyLuat)}
        buttonAdd={false}
        buttonExportExcel={false}
        justFilter
        noPagination
      />

      <p className="text-center spaces mt-36"><b>II. QUAN HỆ GIA ĐÌNH</b></p>
      <div className="border-wrapper">
        <p className="suggest fs-sm">Ghi rõ họ tên, năm sinh, quê quán, nơi đăng ký hộ khẩu thường trú, chỗ ở hiện nay, 
          dân tộc, tôn giáo, nghề nghiệp, chức vụ, thái độ chính trị của từng người
        </p>
        <div>
          <p>1 - Bố, mẹ:</p>
          <div className="spaces pl-24">
            {listParent.map((item: any, index: number) => (
              <p key={index + 1}>- Quan hệ: {item.qhgdQuanHe}, Họ tên: {item.qhgdTen}, Năm Sinh {item.qhgdNamSinh}, {item.qhgdDetails}</p>
            ))}
          </div>
          <p>2 - Anh, chị, em ruột:</p>
          <div className="spaces pl-24">
            {listSiblings.map((item: any, index: number) => (
              <p key={index + 1}>- Quan hệ: {item.qhgdQuanHe}, Họ tên: {item.qhgdTen}, Năm Sinh {item.qhgdNamSinh}, {item.qhgdDetails}</p>
            ))}
          </div>
          <p>3 - Vợ (chồng), các con (kể cả con nuôi nếu có):</p>
          <div className="spaces pl-24">
            {listMemberInSmallFamily.map((item: any, index: number) => (
              <p key={index + 1}>- Quan hệ: {item.qhgdQuanHe}, Họ tên: {item.qhgdTen}, Năm Sinh {item.qhgdNamSinh}, {item.qhgdDetails}</p>
            ))}
          </div>
          <p>4 - Bố, mẹ vợ hoặc chồng:</p>
          <div className="spaces pl-24">
            {listParentsInLaw.map((item: any, index: number) => (
              <p key={index + 1}>- Quan hệ: {item.qhgdQuanHe}, Họ tên: {item.qhgdTen}, Năm Sinh {item.qhgdNamSinh}, {item.qhgdDetails}</p>
            ))}
          </div>
        </div>
      </div>

      <p className="text-center spaces mt-36"><b>III. CAM ĐOAN VỀ LÝ LỊCH CỦA BÀN THÂN</b></p>
      <div className="border-wrapper">
        <div>
          <TextUnderline readOnly rows={4} value={""} />
          <div className="mt-3 flex flex-end text-align-right">
            <div>
              <p className="mb-0 flex flex-end gap-0">
                <TextValue label="" className="spaces w-80" />, ngày
                <TextValue label="" className="spaces w-48" /> tháng
                <TextValue label="" className="spaces w-48" /> năm
                <TextValue label="" className="spaces w-48" />
              </p>
              <p className="text-center spaces mb-100"><i>(Ký và ghi rõ họ tên)</i></p>
            </div>
          </div>
        </div>
      </div>

      <p className="text-center spaces mx-auto mt-36 mb-0"><b>IV. XÁC NHẬN CỦA UBND PHƯỜNG, XÃ, THỊ TRẤN <br />NƠI ĐĂNG KÍ HỘ KHẨU THƯỜNG TRÚ</b></p>
      <p className="text-center"><i>(Ghi rõ nhận xét, xác nhận về phần nào trong bản lý lịch)</i></p>
      <div className="border-wrapper">
        <div>
          <TextUnderline readOnly rows={7} value={""} />
        </div>
      </div>
      <div className="mt-3 flex flex-end text-align-right">
        <div>
          <p className="mb-0 flex flex-end gap-0">
            <TextValue label="" className="spaces w-80" />, ngày
            <TextValue label="" className="spaces w-48" /> tháng
            <TextValue label="" className="spaces w-48" /> năm
            <TextValue label="" className="spaces w-48" />
          </p>
          <p className="mb-0 flex flex-end gap-0">
            <b><i>UBND phường, xã, thị trấn</i></b>
            <TextValue label="" className="spaces w-80 inline-block custom-text-value" />
          </p>
          <p className="text-center spaces mb-100"><i>(Ghi rõ họ tên, chức vụ, ký và đóng dấu)</i></p>
        </div>
      </div>

      <p className="text-center spaces fs-lg mx-auto mt-36 mb-0"><b>PHẦN NỘI DUNG</b></p>
      <p className="text-center spaces mx-auto fs-sm mb-0"><b>DO CƠ QUAN SỬ DỤNG LAO ĐỘNG HỢP ĐỒNG <br />CHỊU TRÁCH NHIỆM THỰC HIỆN</b></p>
      <p className="text-center spaces mx-auto"><i>(Chỉ áp dụng đối với người lao động ký kết hợp đồng lao động không xác định thời hạn và hợp đồng lao động xác định thời hạn từ đủ 12 tháng đến 36 tháng)</i></p>
      <p className="text-center spaces mx-auto mt-28 mb-0"><b>NHẬN XÉT, XÁC NHẬN CỦA CÔNG AN PHƯỜNG, XÃ, THỊ TRẤN</b></p>
      <p className="text-center spaces mx-auto"><i>(Ghi rõ nhận xét, xác nhận về phần nào trong bản lý lịch)</i></p>
      <div className="border-wrapper">
        <div>
          <TextUnderline readOnly rows={5} value={""} />
        </div>
      </div>
      <div className="mt-3 flex flex-end text-align-right">
        <div>
          <p className="mb-0 flex flex-end gap-0">
            <TextValue label="" className="spaces w-80" />, ngày
            <TextValue label="" className="spaces w-48" /> tháng
            <TextValue label="" className="spaces w-48" /> năm
            <TextValue label="" className="spaces w-48" />
          </p>
          <p className="mb-0 flex flex-end gap-0">
            <b><i>Trưởng Công an xã, phường, thị trấn</i></b>
            <TextValue label="" className="spaces w-80 inline-block custom-text-value" />
          </p>
          <p className="text-center spaces mb-100"><i>(Ghi rõ họ tên, chức vụ, ký và đóng dấu)</i></p>
        </div>
      </div>

      <p className="text-center spaces mx-auto mt-28 mb-0">
        <b>NHẬN XÉT, XÁC NHẬN <br />CỦA CÔNG AN QUẬN, HUYỆN, THỊ XÃ, THÀNH PHỐ THUỘC TỈNH</b></p>
      <p className="text-center spaces mx-auto"><i>(Ghi rõ nhận xét, xác nhận về phần nào trong bản lý lịch)*</i></p>
      <div className="border-wrapper">
        <div>
          <TextUnderline readOnly rows={5} value={""} />
        </div>
      </div>
      <div className="mt-3 flex flex-end text-align-right">
        <div>
          <p className="mb-0 flex flex-end gap-0">
            <TextValue label="" className="spaces w-80" />, ngày
            <TextValue label="" className="spaces w-48" /> tháng
            <TextValue label="" className="spaces w-48" /> năm
            <TextValue label="" className="spaces w-48" />
          </p>
          <p className="mb-0 flex flex-end gap-0">
            <b><i>Trưởng Công an quận, huyện, thị xã, thành phố</i></b>
            <TextValue label="" className="spaces w-80 inline-block custom-text-value" />
          </p>
          <p className="text-center spaces mb-100"><i>(Ghi rõ họ tên, chức vụ, ký và đóng dấu)</i></p>
        </div>
      </div>

      <p className="text-center spaces fs-lg mx-auto mt-36 mb-0"><b>PHẦN NỘI DUNG</b></p>
      <p className="text-center spaces mx-auto fs-sm mb-0"><b>DO CƠ QUAN SỬ DỤNG LAO ĐỘNG HỢP ĐỒNG THỰC HIỆN</b></p>
      <p className="text-center spaces mx-auto"><i>(áp dụng đối với người lao động ký kết HĐLĐ xác định thời hạn từ đủ 12 tháng đến 36 tháng)</i></p>
      <p className="text-center spaces mx-auto mt-28 mb-0"><b>NHẬN XÉT, XÁC NHẬN <br />CỦA CÔNG AN QUẬN, HUYỆN, THỊ XÃ, THÀNH PHỐ THUỘC TỈNH</b></p>
      <p className="text-center spaces mx-auto"><i>(Ghi rõ nhận xét, xác nhận về phần nào trong bản lý lịch)</i></p>
      <div className="border-wrapper">
        <div>
          <TextUnderline readOnly rows={10} value={""} />
        </div>
      </div>
      <div className="mt-3 flex flex-end text-align-right">
        <div>
          <p className="mb-0 flex flex-end gap-0">
            <TextValue label="" className="spaces w-80" />, ngày
            <TextValue label="" className="spaces w-48" /> tháng
            <TextValue label="" className="spaces w-48" /> năm
            <TextValue label="" className="spaces w-48" />
          </p>
          <p className="mb-0 flex flex-end gap-0">
            <b><i>Trưởng Công an quận, huyện, thị xã, thành phố</i></b>
            <TextValue label="" className="spaces w-80 inline-block custom-text-value" />
          </p>
          <p className="text-center spaces mb-100"><i>(Ghi rõ họ tên, chức vụ, ký và đóng dấu)</i></p>
        </div>
      </div>

      <p className="text-center spaces mx-auto mt-28 mb-0"><b>NHẬN XÉT, XÁC NHẬN CỦA CÔNG AN PHƯỜNG, XÃ, THỊ TRẤN</b></p>
      <p className="text-center spaces mx-auto"><i> (Ghi rõ nhận xét, xác nhận về phần nào trong bản lý lịch)</i></p>
      <div className="border-wrapper">
        <div>
          <TextUnderline readOnly rows={6} value={""} />
        </div>
      </div>
      <div className="mt-3 flex flex-end text-align-right">
        <div>
          <p className="mb-0 flex flex-end gap-0">
            <TextValue label="" className="spaces w-80" />, ngày
            <TextValue label="" className="spaces w-48" /> tháng
            <TextValue label="" className="spaces w-48" /> năm
            <TextValue label="" className="spaces w-48" />
          </p>
          <p className="mb-0 flex flex-end gap-0">
            <b><i>Trưởng Công an xã, phường, thị trấn</i></b>
            <TextValue label="" className="spaces w-80" />
          </p>
          <p className="text-center spaces mb-100"><i>(Ghi rõ họ tên, chức vụ, ký và đóng dấu)</i></p>
        </div>
      </div>

      <div className="border-wrapper">
        <div>
          <p className="text-center"><b>HƯỚNG DẪN GHI LÝ LỊCH</b></p>
          <p className="spaces text-indent-40 mb-2">Người lao động có trách nhiệm khai báo đầy đủ các thông tin về bản thân, có dán ảnh kiểu chứng minh
            nhân dân cỡ 4x6 cm, đóng dấu giáp lại và lấy xác nhận của UBND phường, xã, thị trấn nơi đăng ký hộ 
            khẩu thường trú theo các mục trong mẫu Sơ yếu lý lịch.
          </p>
          <p className="spaces text-indent-40 mb-2">Ảnh màu (4x6 cm) được chụp trong thời gian một năm tính đến ngày khai lý lịch.</p>
          <p className="spaces text-indent-40 mb-2">1- Họ và tên khai sinh: viết chữ in hoa đúng với họ và tên trong giấy khai sinh.</p>
          <p className="spaces text-indent-40 mb-2">2- Tên gọi khác: là tên gọi khác hoặc bí danh khác đã dùng trong hoạt động cách mạng
            trong lĩnh vực báo chí, văn học nghệ thuật... (nếu có).
          </p>
          <p className="spaces text-indent-40 mb-2">3- Sinh ngày: ghi đầy đủ ngày, tháng, năm sinh đúng như trong giấy khai sinh.</p>
          <p className="spaces text-indent-40 mb-2">Giới  tính: ghi giới tính là Nam hoặc Nữ.</p>
          <p className="spaces text-indent-40 mb-2">4- Nơi sinh: tên xã (hoặc phường, thị trấn), huyện (hoặc quận, thành phố thuộc tỉnh), 
            tỉnh (hoặc thành phố trực thuộc Trung ương) nơi được sinh ra (ghi đúng như trong giấy khai sinh). Nếu có thay đổi địa danh đơn 
            vị hành chính thì ghi &lt;tên cũ&gt;, nay là &lt;tên mới&gt;.
          </p>
          <p className="spaces text-indent-40 mb-2">5- Quê quán: ghi theo Giấy khai sinh hoặc Chứng minh thư nhân dân (việc xác định quê 
            quán theo quy định của pháp luật và hướng dẫn của Bộ Tư pháp). Ghi rõ tên xã (hoặc phường, thị trấn), huyện (hoặc quận, thành phố 
            thuộc tỉnh), tỉnh (hoặc thành phố trực thuộc Trung ương).
          </p>
          <p className="spaces text-indent-40 mb-2">6- Dân tộc: ghi rõ tên dân tộc theo quy định của Nhà nước như: Kinh, Tày, Nùng, Thái, Mường, Mông, Ê đê...</p>
          <p className="spaces text-indent-40 mb-2">7- Tôn giáo: đang theo tôn giáo nào thì ghi tên tôn giáo đó như: Công giáo, Phật giáo, Hồi giáo, Cao đài, Hoà 
            hảo... Nếu không theo tôn giáo nào thì không được bỏ trống mà ghi là "không".
          </p>
          <p className="spaces text-indent-40 mb-2">9- Nơi ở hiện nay: ghi đầy đủ số nhà, đường phố, thành phố hoặc xóm, thôn, xã, huyện tỉnh nơi mình đang ở hiện tại.</p>
          <p className="spaces text-indent-40 mb-2">10- Nghề nghiệp: ghi rõ đã làm nghề gì để kiếm sống trước khi được tuyển vào Công an nhân dân. Nếu chưa có nghề, sống 
            phụ thuộc vào gia đình thì ghi cụ thể là "không nghề nghiệp".
          </p>
          <p className="spaces text-indent-40 mb-2">11- Trình độ giáo dục phổ thông: đã tốt nghiệp lớp mấy/thuộc hệ nào.</p>
          <p className="spaces text-indent-40 mb-2">12- Trình độ chuyên môn cao nhất: Tiến sỹ khoa học, Tiến sỹ, Thạc sĩ, 
            cử nhân, kỹ sư, cao đẳng, trung cấp, sơ cấp... thuộc chuyên ngành đào tạo nào.
          </p>
          <p className="spaces text-indent-40 mb-2">13- Lý luận chính trị: cao cấp, trung cấp, sơ cấp hay tương đương.</p>
          <p className="spaces text-indent-40 mb-2">14- Ngoại ngữ: tên ngoại ngữ + trình độ đào tạo A, B, C, D...</p>
          <p className="spaces text-indent-40 mb-2">15- Tin học: Trình độ A, B, C, kỹ sư, cử nhân, Thạc sỹ, Tiến sĩ.</p>
          <p className="spaces text-indent-40 mb-2">16- Gia đình chính sách: con thương binh, con liệt sỹ, người nhiễm chất độc da cam Dioxin</p>
        </div>
      </div>
    </div>
  );
};
