import { formatDateTable } from "../../../utils/FunctionUtils";

export const canhBaoNhanSuDenTuoiNghiHuu = (handleDecideToRetire: any) => {
  return (
    [
      {
        name: "Trạng thái",
        field: "",
        headerCellProps: {
          minWidth: 150
        },
        render: (row: any) => <span className={`${row?.trangThaiHetHanNghiHuu?.bgColor} warning-status`}>{row?.trangThaiHetHanNghiHuu?.name}</span>
      },
      {
        name: "Ra quyết định",
        field: "",
        headerCellProps: {
          minWidth: 100
        },
        bodyCellProps: {
          textAlign: "center"
        },
        render: (row: any) => {
          return (
            <span
              onClick={(e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
                e.stopPropagation();
                handleDecideToRetire && handleDecideToRetire(row);
              }}
            >
              <i className="bi bi-pencil-square text-primary fs-3"></i>
            </span>
          );
        }
      },
      {
        name: "Mã nhân viên",
        field: "maNhanVien",
        headerCellProps: {
          minWidth: 120
        },
        bodyCellProps: {
          textAlign: "left"
        },
      },
      {
        name: "Họ và tên",
        field: "name",
        headerCellProps: {
          minWidth: 200
        }
      },
      {
        name: "Giới tính",
        field: "gender",
        headerCellProps: {
          minWidth: 80
        },
        bodyCellProps: {
          textAlign: "center"
        },
        render: (row: any) => <span>{row.gender?.name}</span>
      },
      {
        name: "Ngày sinh",
        field: "birthDate",
        headerCellProps: {
          minWidth: 100
        },
        bodyCellProps: {
          textAlign: "center"
        },
        render: (row: any) => <span>{formatDateTable(row?.birthDate)}</span>
      },
      {
        name: "Tuổi",
        field: "tuoi",
        headerCellProps: {
          minWidth: 50
        },
        bodyCellProps: {
          textAlign: "center"
        },
      },
      {
        name: "Chức vụ",
        field: "chucVuText",
        headerCellProps: {
          minWidth: 250
        }
      },
      {
        name: "Phòng/Ban",
        field: "phongBanText",
        headerCellProps: {
          minWidth: 300
        }
      },
      {
        name: "Ngày nghỉ hưu dự kiến",
        field: "ngayNghiHuuDuKien",
        headerCellProps: {
          minWidth: 150
        },
        bodyCellProps: {
          textAlign: "center"
        },
        render: (row: any) => <span>{formatDateTable(row?.ngayNghiHuuDuKien)}</span>
      },
      {
        name: "Số tháng còn lại",
        field: "soThangConLai",
        headerCellProps: {
          minWidth: 120
        },
        bodyCellProps: {
          textAlign: "center"
        },
      },
      {
        name: "Ngày quyết định nghỉ hưu",
        field: "ngayQuyetDinhNghiHuu",
        headerCellProps: {
          minWidth: 170
        },
        bodyCellProps: {
          textAlign: "center"
        },
        render: (row: any) => <span>{formatDateTable(row?.ngayQuyetDinhNghiHuu)}</span>
      }
    ]
  )
}