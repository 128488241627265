import { useEffect, useState } from "react";
import useMultiLanguage from "../../../../../hook/useMultiLanguage";
import TableCustom, { columnNamesType } from "../../../../component/table-custom/TableCustom";
import { MISSION_STATUS, RESPONSE_STATUS_CODE } from "../../../../utils/Constant";
import { toast } from "react-toastify";
import BonusViewDialog from "./BonusViewDialog";
import { getBonus } from "../../../../bonus/services/BonusService";
import { checkStatus } from "../../../../utils/FunctionUtils";
interface Iprops {
  isCallApi: boolean;
  employeeProfiles: any;
}

function BonusTable(props: Iprops) {
  const { lang } = useMultiLanguage();
  const { isCallApi, employeeProfiles } = props;

  const [bonusList, setBonusList] = useState<any[]>([]);
  const [totalElements, setTotalElements] = useState<number>(0);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [numberOfElements, setNumberOfElements] = useState<number>(0);
  const [bonusItem, setBonusItem] = useState({});
  const [shouldOpenViewDialog, setShouldBonusViewDialog] = useState<boolean>(false);

  useEffect(() => {
    updatePageData();
  }, [isCallApi])

  const updatePageData = async (searchObj?: any) => {
    if (!isCallApi) return;
    try {
      const searchData = {
        ...searchObj,
        employeeId: employeeProfiles?.id
      }
      const { data } = await getBonus(searchData);
      if (data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
        setBonusList(data?.data?.content || []);
        setTotalPages(data?.data?.totalPages || 0);
        setTotalElements(data?.data?.totalElements || 0);
        setNumberOfElements(data?.data?.numberOfElements || 0);
      }
    } catch (error) {
      toast.error(lang("GENERAL.ERROR"));
    }
  };

  const handleExportExcel = () => {};

  const handleOpenDialog = (row: any) => {
    setShouldBonusViewDialog(true);
    setBonusItem(row);
  };

  const handleCloseDialog = () => {
    setShouldBonusViewDialog(false);
  };

  const columns: columnNamesType[] = [
    {
      name: lang("TABLE.INDEX"),
      field: "stt",
      headerStyle: {
        minWidth: "100px"
      },
      render: (row: any, index: number, STT: number) => <span>{STT}</span>
    },
    {
      name: lang("GENERAL.STATUS"),
      field: "trangThai",
      headerStyle: {
        minWidth: "200px"
      },
      render: (row: any) => (
        <span className={`${checkStatus(MISSION_STATUS, row?.trangThai?.code)} status`}>
          {row?.trangThai?.name}
        </span>
      )
    },
    {
      name: lang("APPRECIATIVE.NAME"),
      field: "dotKhenThuong",
      headerStyle: {
        minWidth: "200px"
      }
    },
    {
      name: lang("APPRECIATIVE.YEAR"),
      field: "namKhenThuong",
      headerStyle: {
        minWidth: "200px"
      }
    },
    {
      name: lang("APPRECIATIVE.LOAIKHENTHUONGTEXT"),
      field: "loaiKhenThuong",
      headerStyle: {
        minWidth: "200px"
      },
      render: (row) => <span>{row?.loaiKhenThuong?.name}</span>
    },
    {
      name: lang("APPRECIATIVE.DOITUONGKHENTHUONGTEXT"),
      field: "doiTuongKhenThuong",
      headerStyle: {
        minWidth: "200px"
      },
      render: (row) => <span>{row?.doiTuongKhenThuong?.name}</span>
    },
    {
      name: lang("APPRECIATIVE.HINHTHUCKHENTHUONGTEXT"),
      field: "hinhThucKhenThuongText",
      headerStyle: {
        minWidth: "400px"
      }
    },
    {
      name: lang("APPRECIATIVE.SOQUYETDINH"),
      field: "soQuyetDinh",
      headerStyle: {
        minWidth: "200px"
      }
    },
    {
      name: lang("APPRECIATIVE.AGENCIES_UNIT_DECITION"),
      field: "donViBanHanhText",
      headerStyle: {
        minWidth: "280px"
      }
    }
  ];

  return (
    <div>
      <TableCustom
        title={lang("GENERAL.APPRECIATIVE.LIST")}
        isActionTableTab
        data={bonusList}
        columns={columns}
        handleOpenDialog={handleOpenDialog}
        handleExportExcel={handleExportExcel}
        fixedColumnsCount={4}
        handleDoubleClick={handleOpenDialog}
        totalPages={totalPages}
        totalElements={totalElements}
        numberOfElements={numberOfElements}
        notDelete
        noPagination
      />
      {shouldOpenViewDialog && (
        <BonusViewDialog
          open={shouldOpenViewDialog}
          handleClose={handleCloseDialog}
          bonusInfo={bonusItem}
        />
      )}
    </div>
  );
}

export default BonusTable;
