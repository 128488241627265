import { useFormik } from "formik";
import React, { useContext, useEffect } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import * as Yup from "yup";
import AppContext from "../../../../AppContext";
import useMultiLanguage from "../../../../hook/useMultiLanguage";
import { RangeDatePicker } from "../../../component/input-field/RangeDatePicker";
import TextValidator from "../../../component/input-field/TextValidator";
import { RESPONSE_STATUS_CODE } from "../../../utils/Constant";
import { convertNumberPrice, convertTextPrice } from "../../../utils/FunctionUtils";
import { INIT_INSURANCE_PROCESS } from "../../const/ProfileConst";
import TextArea from "../../../component/input-field/TextArea";
import { addInsuranceProcess, updateInsuranceProcess } from "../../services/DialogServices";

interface Props {
  handleClose: () => void;
  isView: boolean;
  insuranceProcessInfo: any;
  show: boolean;
  employeeProfiles: any;
  updateParentTable: any;
}

function InsuranceProcessDialog(props: Props) {
  const { handleClose, isView, insuranceProcessInfo, show, employeeProfiles, updateParentTable } = props;
  const { lang } = useMultiLanguage();
  const { setPageLoading } = useContext(AppContext);

  const { SUCCESS } = RESPONSE_STATUS_CODE;
  
  useEffect(() => {
    formik.setValues({
      ...formik.values,
      ...insuranceProcessInfo
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [insuranceProcessInfo]);

  const handleFormSubmit = async (values: any) => {
    try {
      setPageLoading(true);
      let dataSubmit = {
        ...values,
        employeeId: employeeProfiles?.id,
      };
      const { data } = dataSubmit?.id ? 
        await updateInsuranceProcess(dataSubmit.id, dataSubmit) : 
        await addInsuranceProcess(dataSubmit);
      if (data?.code === SUCCESS) {
        const message = dataSubmit?.id
          ? "TOAST.EDIT.SUCCESS"
          : "TOAST.ADD.SUCCESS";
        toast.success(lang(message));
        setPageLoading(false);
        updateParentTable();
        handleClose();
      } else toast.error(`${data?.message}`);
    } catch (error) {
      toast.error(lang("GENERAL.ERROR"));
    } finally {
      setPageLoading(false)
    }
  };

  const validationSchema = Yup.object().shape({
    tuThang: Yup.date()
      .when("denThang", {
        is: (denThang: Date | null) => denThang,
        then: Yup.date()
          .max(
            Yup.ref("denThang"),
            lang("VALIDATION.MAXDATE") +
              lang("INPUT.INSURANCE.MONTH.END")
          )
          .nullable()
      })  
      .required(lang("VALIDATION.REQUIRE")).nullable(),
    denThang: Yup.date().required(lang("VALIDATION.REQUIRE")).nullable(),
    mucDong: Yup.number().required(lang("VALIDATION.REQUIRE")).nullable(),
    tenCoQuanDonVi: Yup.string().required(lang("VALIDATION.REQUIRE")).nullable(),
    tyLeDong: Yup.string().required(lang("VALIDATION.REQUIRE")).nullable(),
  });
  
  const formik = useFormik({
    initialValues: INIT_INSURANCE_PROCESS,
    validationSchema,
    onSubmit: handleFormSubmit
  });

  const handleChangeMoney = (event: React.ChangeEvent<any>) => {
    const {name, value} = event.target;
    formik.setFieldValue(name, convertTextPrice(value));
  }

  return (
    <Modal
      show={show}
      size="xl"
      centered
      aria-labelledby="example-custom-modal-styling-title"
      onHide={() => handleClose()}
      className="modal-scroll"
    >
      <Modal.Header closeButton className="py-4">
        <Modal.Title
          id="example-custom-modal-styling-title"
          className="heading-5"
        >
          {(insuranceProcessInfo && insuranceProcessInfo?.id) ? lang("INPUT.INSURANCE.PROCESS.EDIT") : lang("INPUT.INSURANCE.PROCESS.ADD")}
        </Modal.Title>
      </Modal.Header>
      <Form
        className="form-info mb-0"
        onSubmit={formik.handleSubmit}
      >
        <Modal.Body className="spaces pt-6 pb-16">
          <Row>
            <Col xs={4} xxl={3} className="pt-3">
              <TextValidator
                isRequired
                lable={lang("GENERAL.ENSURANCE.NUMBER")}
                name="soSoBhxh"
                value={formik.values?.soSoBhxh || ""}
                type="text"
                readOnly={true}
                onChange={formik.handleChange}
              />
            </Col>
            <Col xs={4} xxl={3} className="pt-3">
              <TextValidator
                isRequired
                lable={lang("INPUT.FULLNAME")}
                name="name"
                value={employeeProfiles.name}
                type="text"
                readOnly={true}
              />
            </Col>
            <Col xs={4} xxl={4} className="pt-3">
              <RangeDatePicker
                isRequired
                label={lang("INPUT.INSURANCE.TIME")}
                startDateName="tuThang"
                endDateName="denThang"
                isView={isView}
                handleChange={formik.handleChange}
                value={formik.values}
                setFieldValue={formik.setFieldValue}
                touch={formik.touched}
                errors={formik.errors}
              />
            </Col>
            <Col xs={4} xxl={2} className="pt-3">
              <TextValidator
                isRequired
                lable={lang("INPUT.INSURANCE.SALARY")}
                name="mucDong"
                value={convertNumberPrice(formik.values?.mucDong)}
                type="text"
                readOnly={isView}
                onChange={handleChangeMoney}
                errors={formik.errors?.mucDong}
                touched={formik.touched?.mucDong}
              />
            </Col>
            <Col xs={8} xxl={12} className="pt-3">
              <TextArea
                isRequired
                lable={lang("INPUT.INSURANCE.MORE_INFO")}
                name="tenCoQuanDonVi"
                value={formik.values?.tenCoQuanDonVi || ""}
                type="text"
                as="textarea"
                rows="3"
                readOnly={isView}
                onChange={(value: any) => formik.setFieldValue("tenCoQuanDonVi", value)}
                touched={formik.touched?.tenCoQuanDonVi}
                errors={formik.errors?.tenCoQuanDonVi}
              />
            </Col>
          </Row>
          <Row>
            <span className="text-header spaces mt-10">
              {lang("INPUT.INSURANCE.ALLOWANCE")}
            </span>
            <Col xs={4} xxl={3} className="pt-3">
              <TextValidator
                lable={lang("TIME_KEEPING.POSITION")}
                name="phuCapChucVu"
                value={convertNumberPrice(formik.values?.phuCapChucVu)}
                type="text"
                readOnly={isView}
                onChange={handleChangeMoney}
              />
            </Col>
            <Col xs={4} xxl={3} className="pt-3">
              <TextValidator
                lable={lang("SALARY.SENIORITY_ALLOWANCES")}
                name="phuCapTnvk"
                value={convertNumberPrice(formik.values?.phuCapTnvk)}
                type="text"
                readOnly={isView}
                onChange={handleChangeMoney}
              />
            </Col>
            <Col xs={4} xxl={3} className="pt-3">
              <TextValidator
                lable={lang("SALARY.PROFESSIONAL.SENIORITY_ALLOWANCES")}
                name="phuCapTnnn"
                value={formik.values?.phuCapTnnn || ""}
                type="number"
                readOnly={isView}
                onChange={formik.handleChange}
              />
            </Col>
            <Col xs={4} xxl={3} className="pt-3">
              <TextValidator
                lable={lang("INPUT.INSURANCE.AREA")}
                name="phuCapKhuVuc"
                value={convertNumberPrice(formik.values?.phuCapKhuVuc)}
                type="text"
                readOnly={isView}
                onChange={handleChangeMoney}
              />
            </Col>
            <Col xs={4} xxl={3} className="pt-3">
              <TextValidator
                lable={lang("INPUT.INSURANCE.OTHER")}
                name="phuCapKhac"
                value={convertNumberPrice(formik.values?.phuCapKhac)}
                type="text"
                readOnly={isView}
                onChange={handleChangeMoney}
              />
            </Col>
            <Col xs={4} xxl={3} className="pt-3">
              <TextValidator
                lable={lang("INPUT.INSURANCE.RE_ELECTED")}
                name="phuCapTaiCu"
                value={convertNumberPrice(formik.values?.phuCapTaiCu)}
                type="text"
                readOnly={isView}
                onChange={handleChangeMoney}
              />
            </Col>
          </Row>
          <Row>
            <span className="text-header spaces mt-10">
              {lang("INFORMATION.OTHER")}
            </span>
            <Col xs={4} xxl={3} className="pt-3">
              <TextValidator
                isRequired
                lable={lang("GENERAL.ENSURANCE.PERCENT")}
                name="tyLeDong"
                value={formik.values?.tyLeDong || ""}
                type="number"
                readOnly={isView}
                onChange={formik.handleChange}
                errors={formik.errors?.tyLeDong}
                touched={formik.touched?.tyLeDong}
              />
            </Col>
            <Col xs={4} xxl={3} className="pt-3">
              <TextValidator
                lable={lang("INPUT.INSURANCE.SALARY.MAIN")}
                name="luongChinh"
                value={convertNumberPrice(formik.values?.luongChinh)}
                type="text"
                readOnly={isView}
                onChange={handleChangeMoney}
              />
            </Col>
            <Col xs={4} xxl={3} className="pt-3">
              <TextValidator
                lable={lang("INPUT.INSURANCE.SALARY")}
                name="phuCapLuong"
                value={convertNumberPrice(formik.values?.phuCapLuong)}
                type="text"
                readOnly={isView}
                onChange={handleChangeMoney}
              />
            </Col>
            <Col xs={4} xxl={3} className="pt-3">
              <TextValidator
                lable={lang("INPUT.INSURANCE.ADDED_OTHER")}
                name="boSungKhac"
                value={convertNumberPrice(formik.values?.boSungKhac)}
                type="text"
                readOnly={isView}
                onChange={handleChangeMoney}
              />
            </Col>
            <Col xs={4} xxl={3} className="pt-3">
              <TextValidator
                lable={lang("INPUT.INSURANCE.BUDGET.SUPPORT")}
                name="nsnnHoTro"
                value={convertNumberPrice(formik.values?.nsnnHoTro)}
                type="text"
                readOnly={isView}
                onChange={handleChangeMoney}
              />
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer className="flex-center py-3">
          <Button
            variant="outline-secondary"
            className="button-gray-outline btn-sm"
            onClick={() => handleClose()}
          >
            {lang("BTN.CANCEL")}
          </Button>

          {!isView && (
            <Button
              variant="primary"
              className="button-primary btn-sm"
              type="submit"
            >
              {lang("BTN.SAVE")}
            </Button>
          )}
        </Modal.Footer>
      </Form>
    </Modal>
  );
}

export default InsuranceProcessDialog;
