import { IDataPreview } from "../../component/exportWord";
import TextUnderline from "../../component/TextUnderline";
import { LOAI_VI_TRI_CONG_VIEC } from "../../contract/const/ContractConst";
import { TYPE } from "../../utils/Constant";

export function ContractWord({ data }: { data: IDataPreview }) {
  const listCongViec = data?.moTaCongViec ? data?.moTaCongViec?.split('\n') : [];
  
  return (
    <div className="word spaces px-70">
      <div className="spaces text-center flex flex-between align-start">
        <div className="fs-lg flex-column flex-middle">
          <img className="spaces w-100 h-45" src="/media/logos/logoHaiTrieuFood.png" alt="logo hải triều" />
          <p className="fw-600 text-uppercase">công ty tnhh hải triều</p>
          <div>
            <span className="fw-600 text-uppercase">Số: {data?.soHopDong ? data?.soHopDong : ".........................."}/HĐLĐ-HT</span>
          </div>
        </div>
        <div className="spaces fw-600 mt-45">
          <p className="fs-lg">CỘNG HOÀ XÃ HỘI CHỦ NGHĨA VIỆT NAM</p>
          <span className="header-underline fs-lg">Độc lập - Tự do - Hạnh phúc</span>
        </div>
      </div>
      <p className="fs-lg spaces py-16 text-center fw-600 mb-0 ">HỢP ĐỒNG LAO ĐỘNG</p>
      <div className="spaces text-italic fs-md pl-60 mb-16">
        <p>- Căn cứ Bộ luật Lao động số 45/2019/QH14 ngày 20/11/2019 của Quốc hội nước Cộng hòa Xã hội Chủ nghĩa Việt Nam;</p>
        <p>- Căn cứ Nghị định 145/2020/NĐ-CP quy định chi tiết và hướng dẫn thi hành một số nội dung về điều kiện lao động và quan hệ lao động theo các điều, khoản sau đây của Bộ luật Lao động 2019;</p>
        <p>- Căn cứ vào năng lực và nhu cầu của hai bên.</p>
      </div>
      <div className="flex">
        <span>Hôm nay, ngày </span>
        <TextUnderline className={`spaces w-100 ${data?.ngayKyHopDong ? "pb-4" : ""}`} value={data?.ngayKyHopDong} type={TYPE.DATE} isUnderline={Boolean(data?.ngayKyHopDong)} />
        <span className="spaces text-indent-3">tại văn phòng Công ty TNHH Hải Triều. Chúng tôi gồm:</span>
      </div>
      <p>Người sử dụng lao động: CÔNG TY TNHH HẢI TRIỀU.</p>
      <p>Địa chỉ: Lô 2/6 KCN Phan Thiết, Phong Nẫm, Phan Thiết, Bình Thuận - Chứng nhận đăng ký kinh doanh số: 3400494824</p>
      <div className="flex">
        <TextUnderline className="flex-2" label="Đại diện theo pháp luật" value={data?.nguoiDaiDienCtyKyText} />
        <TextUnderline className="flex-1" label="Chức vụ" value={data?.chucVuText} />
      </div>
      <div className="flex">
        <TextUnderline className="flex-2" label="Người lao động" value={data?.fullName} />
        <TextUnderline className="flex-1" label="Ngày sinh" value={data?.birthdate} />
      </div>
      <div className="flex">
        <TextUnderline className="spaces flex-3" label="CMND/CCCD số" value={data?.soCMNDOrCCCD} />
        <TextUnderline className="spaces flex-2" label="Cấp ngày" value={data?.ngayCapCMNDOrCCCD} type={TYPE.DATE} />
        <TextUnderline className="spaces flex-4" label="Nơi cấp" value={data?.noiCapCMNDOrCCCD} />
      </div>
      <div className="flex">
        <TextUnderline className="spaces w-200" label="Giới tính" value={data?.gender?.name} />
        <TextUnderline className="spaces flex-1" label="Trình độ văn hóa" value={data?.trinhDoGiaoDucPhoThongText} />
      </div>
      <TextUnderline label="Cư trú tại" value={data?.hoKhauThuongTru} />
      <p> Thỏa thuận ký kết Hợp đồng lao động và cam kết làm đúng những điều khoản sau đây:</p>
      <div>
        <p className="fw-600">Điều 1: Công việc và địa điểm làm việc</p>
        <p className="fw-600">1.1.	Công việc: </p>
        <div className="spaces pl-20">
          <TextUnderline label="- Chức vụ/chức danh chuyên môn" value={data?.chucVuText} />
          <TextUnderline label="- Bộ Phận" value={data?.phongBanText} />
          <p>- Mô tả công việc: </p>
        </div>
        <div className="spaces text-indent-40">
          {
            listCongViec.map((line: string, index: number) => (
              <p key={index}>{line}</p>
            ))
          }
        </div>
        <div className="fw-600">
          <TextUnderline label="1.2. Địa điểm làm việc" value={data?.diaDiemLamViecText} />
        </div>
      </div>

      <div>
        <p className="fw-600">Điều 2: Thời hạn của hợp đồng</p>
        <div className="spaces pl-20">
          <div className="flex">
            <TextUnderline className="flex-2" label="- Loại hợp đồng:" value={data?.loaiHopDong} />
            <TextUnderline className="flex-1" label=", thời hạn" value={data?.thoiHanHopDong} />
          </div>
          <div className="flex width-60">
            <TextUnderline label="- Bắt đầu từ ngày" value={data?.ngayCoHieuLuc} type={TYPE.DATE} />
            <TextUnderline className="spaces text-indent-0" label="đến hết ngày" value={data?.ngayHetHan} type={TYPE.DATE} />
          </div>
        </div>
      </div>

      <div>
        <p className="fw-600">Điều 3: Chế độ làm việc</p>
        <p className="spaces text-indent-20">- Thời giờ làm việc: 8 giờ/ngày, từ thứ hai đến thứ bảy hàng tuần</p>
        <div className="spaces text-indent-40">
          <div className="spaces pl-40 width-60">
            <TextUnderline label="+ Buổi sáng từ" value={data?.thoiGianLamViecSangText} />
            <TextUnderline label="+ Buổi chiều từ" value={data?.thoiGianLamViecChieuText} />
          </div>
          <p className="spaces text-indent-20">- Được cấp phát những dụng cụ làm việc: theo yêu cầu của công việc.</p>
          <p className="spaces text-indent-20">- Điều kiện an toàn và vệ sinh lao động tại nơi làm việc: theo quy định hiện hành của Nhà nước.</p>
        </div>
      </div>

      {/* Điều 4 */}
      <div>
        <p className="fw-600">Điều 4: Quyền và nghĩa vụ của người lao động</p>
        <p className="fw-600">4.1. Quyền của người lao động</p>
        <p>a) Tiền lương và phụ cấp: </p>
        <div className="spaces text-indent-20">
          <div className="flex spaces w-400 pl-20">
            <TextUnderline label="- Mức lương cơ bản" value={data?.luongCoBan} />
            <p className="spaces text-indent-0">VNĐ</p>
          </div>
          <p>- Các loại phụ cấp và trợ cấp khác: Theo quy định của Công ty.</p>
          <p>- Thuế thu nhập cá nhân sẽ do cá nhân tự chi trả.</p>
          <p>- Hình thức trả lương: Chuyển khoản hoặc tiền mặt.</p>
          <p>- Thời hạn trả lương: 01 tháng 01 lần vào {data?.ngayTraLuongThangText || 0} trong tháng kế tiếp.</p>
        </div>

        {
          (LOAI_VI_TRI_CONG_VIEC.CONG_NHAN || LOAI_VI_TRI_CONG_VIEC.QC_THONG_KE || LOAI_VI_TRI_CONG_VIEC.VAN_PHONG) && <>
            <p> b) Các quyền lợi khác:</p>
            <div className="spaces text-indent-20">
              <p>- Phương tiện đi lại: Tự túc</p>
              <p>- Tiền thưởng: Theo quy định của Công ty.</p>
              <p>- Được trang bị bảo hộ lao động: Theo quy định của Nhà nước về chế độ bảo hộ lao động.</p>
              <p>- Chế độ nghỉ ngơi các ngày lễ, tết, phép năm:</p>
              <div className="spaces text-indent-40">
                <p>+ Người lao động được nghỉ lễ, tết theo luật định; các ngày nghỉ lễ nếu trùng với ngày nghỉ thì sẽ được nghỉ bù vào ngày trước hoặc ngày kế tiếp tùy theo tình hình cụ thể mà Ban lãnh đạo Công ty sẽ chỉ đạo trực tiếp.</p>
                <p>+ Người lao động đã ký HĐLĐ chính thức và có thâm niên công tác 12 tháng thì sẽ được nghỉ phép năm có hưởng lương (01 ngày phép/01 tháng, 12 ngày phép/01 năm); trường hợp có thâm niên làm việc dưới 12 tháng thì thời gian nghỉ hằng năm được tính theo tỷ lệ tương ứng với số thời gian làm việc.</p>
              </div>
              <p>- Các chế độ về BHXH, BHYT, BHTN:</p>
              <div className="spaces text-indent-40">
                <p>+ Thời gian đóng bảo hiểm kể từ ngày ký kết hợp đồng này.</p>
                <p>+ Mức đóng bảo hiểm: Theo mức qui định của nhà nước. Người lao động đóng 10.5%, Người sử dụng lao động đóng 21.5%.</p>
              </div>
              <p>- Điều kiện an toàn và vệ sinh lao động tại nơi làm việc theo quy định của pháp luật hiện hành.</p>
            </div>

            <p className="fw-600">4.2. Nghĩa vụ của người lao động:</p>
            {
              (LOAI_VI_TRI_CONG_VIEC.QC_THONG_KE || LOAI_VI_TRI_CONG_VIEC.VAN_PHONG) ? <div className="spaces text-indent-20">
                <p>- Hoàn thành những công việc đã cam kết trong Hợp đồng này.</p>
                <p>
                  - Trong trường hợp được cử đi đào tạo thì nhân viên phải hoàn thành khoá học đúng thời hạn, phải cam kết sẽ phục vụ lâu dài cho
                  Công ty sau khi kết thúc khoá học và được hưởng nguyên lương, các quyền lợi khác được hưởng như người đi làm.Nếu sau khi kết thúc
                  khóa đào tạo mà nhân viên không tiếp tục hợp tác với Công ty thì nhân viên phải hoàn trả lại 100% phí đào tạo và các khoản chế độ
                  đã được nhận trong thời gian đào tạo.
                </p>
                <p>- Nghiêm túc tuân thủ và tôn trọng các yêu cầu của lãnh đạo, các nội quy và quy định của Công ty.</p>
                <p>
                  - Chịu trách nhiệm với công việc của mình, không tiết lộ khách hàng của công ty Hải Triều, đảm bảo bí mật tất cả các thông tin,
                  số liệu, quy trình, công việc chỉ tiêu trách nhiệm báo cáo với người quản lý trực tiếp.
                </p>
                <p>
                  - Khi chấm dứt hợp đồng lao động đối với hợp đồng lao động xác định thời hạn từ 01 năm đến 03 năm, người lao động phải báo trước
                  và bàn giao công việc cho công ty trong thời gian 30 ngày làm việc. Đối với hợp đồng lao động không xác định thời hạn, người lao
                  động phải báo trước và bàn giao công việc cho công ty trong thời gian 45 ngày làm việc. Người lao động  phải bàn giao công việc,
                  thông tin liên hệ của tất cả khách hàng, tên công ty ( Email, số điện thoại…), các tài khoản sử dụng dưới danh nghĩa công ty
                  Hải Triều (email công ty, gmail công ty, các tài khoản trên các web thương mại điện tử, sản giao dịch điện tử…) lại cho công ty Hải Triều,
                  không được phép sử dụng danh sách khách hàng của công ty Hải Triều để bán cho các công ty cùng ngành.
                </p>
                <p>- Bồi thường vi phạm vật chất: Theo quy định nội bộ cuả Công ty và quy định cuả pháp luật hiện hành.</p>
              </div> : <div className="spaces text-indent-20">
                <p>- Hoàn thành những công việc đã cam kết trong Hợp đồng này.</p>
                <p>
                  - Trong trường hợp được cử đi đào tạo thì nhân viên phải hoàn thành khoá học đúng thời hạn, phải cam kết sẽ phục vụ lâu dài cho
                  Công ty sau khi kết thúc khoá học và được hưởng nguyên lương, các quyền lợi khác được hưởng như người đi làm. Nếu sau khi kết thúc
                  khóa đào tạo mà nhân viên không tiếp tục hợp tác với Công ty thì nhân viên phải hoàn trả lại 100% phí đào tạo và các khoản chế độ đã
                  được nhận trong thời gian đào tạo.
                </p>
                <p>- Nghiêm túc tuân thủ và tôn trọng các yêu cầu của lãnh đạo, các nội quy và quy định của Công ty.</p>
                <p>
                  - Khi chấm dứt hợp đồng lao động đối với hợp đồng lao động xác định thời hạn từ 01 năm đến 03 năm, người lao động phải báo trước và
                  bàn giao công việc cho công ty trong thời gian 30 ngày làm việc. Đối với hợp đồng lao động không xác định thời hạn, người lao động
                  phải báo trước và bàn giao công việc cho công ty trong thời gian 45 ngày làm việc.
                </p>
                <p>- Bồi thường vi phạm vật chất: Theo quy định nội bộ của Công ty và quy định cuả pháp luật hiện hành;</p>
              </div>
            }
          </>
        }

        {
          (LOAI_VI_TRI_CONG_VIEC.KINH_DOANH || LOAI_VI_TRI_CONG_VIEC.THU_MUA_QT) && <>
            <p> b) Các quyền lợi khác:</p>
            <div className="spaces text-indent-20">
              <p>- Phương tiện đi lại: Tự túc</p>
              <p>- Chế độ nâng lương: Theo quy định của Công ty.</p>
              <p>- Tiền thưởng: Theo quy định của Công ty.</p>
              <p>- Được trang bị bảo hộ lao động: Theo quy định của Nhà nước về chế độ bảo hộ lao động.</p>
              <p>- Chế độ nghỉ ngơi: Theo quy định của Công ty và pháp luật về lao động hiện hành.</p>
              <p>- Các chế độ về BHXH, BHYT, BHTN:</p>
              <div className="spaces text-indent-40">
                <p>+ Thời gian đóng bảo hiểm kể từ ngày ký kết hợp đồng này.</p>
                <p>+ Mức đóng bảo hiểm: Theo mức qui định của nhà nước. Người lao động đóng 10.5%, Người sử dụng lao động đóng 21.5%.</p>
              </div>
              <p>- Thiết bị bảo hộ: Người sử dụng lao động phải cung cấp các trang thiết bị bảo hộ cần thiết cho công việc của Người lao động.</p>
            </div>

            <p className="fw-600">4.2. Nghĩa vụ của người lao động:</p>
            <p>a) Nghĩa vụ của người lao động:</p>
            <div className="spaces text-indent-20">
              <p>- Hoàn thành những công việc đã cam kết trong Hợp đồng này.</p>
              <p>
                - Nghiêm túc tuân thủ và tôn trọng thời hạn và các cam kết trong hợp đồng. Bồi thường cho Công ty các chi phí đào tạo và/hoặc các
                cam kết trách nhiệm bằng tiền nêu trong Hợp đồng này hoặc trong quy định của Công ty trong trường hợp chấm dứt hợp đồng lao động
                trước thời hạn mà không được sự đồng ý của lãnh đạo Công ty.
              </p>
              <p>- Nghiêm túc tuân thủ và tôn trọng các yêu cầu của lãnh đạo, các nội quy và quy định của Công ty.</p>
              <p>
                - Chịu trách nhiệm với công việc của mình, không tiết lộ khách hàng của công ty Hải Triều, đảm bảo bí mật tất cả các thông tin, số
                liệu, quy trình, công việc chỉ chịu trách nhiệm báo cáo với người quản lý trực tiếp và Giám đốc.
              </p>
              <p>- Khi chấm dứt hợp đồng, phải báo trước cho công ty 30 ngày và có trách nhiệm</p>
              <div className="spaces pl-40 text-indent-10">
                <p>
                  + Bàn giao thông tin liên hệ của tất cả khách hàng, tên công ty đã tiến hành báo giá , ký kết giao dịch ký hợp đồng với công ty
                  (Email, số điện thoại, tên khách hàng…), các tài khoản sử dụng dưới danh nghĩa công ty Hải Triều  (email công ty, gmail công ty, các
                  tài khoản trên các web thương mại điện tử, sàn giao dịch điện tử…) lại cho công ty Hải Triều, không được phép sử dụng danh sách khách
                  hàng của Hải Triều để bán cho các công ty cùng ngành.
                </p>
                <p>+ Gửi thông báo bàn giao của công ty bằng văn bản đến tất cả các đối tác.</p>
                <p>+ Không được phép sử dụng danh sách khách hàng, mail…  bất kỳ hình ảnh  nào liên quan đến Hải Triều để bán hoặc sale cho các công ty cùng ngành thủy sản.</p>
              </div>
            </div>
            <p>b) Thoả thuận khác:</p>
            {
              LOAI_VI_TRI_CONG_VIEC.THU_MUA_QT ? <div className="spaces text-indent-20">
                <p>
                  - Trong quá trình làm việc cần báo cáo cho người quản lý trực tiếp bằng email mỗi ngày những thông tin liên hệ nhà cung cấp và đơn hàng.
                  Trong quá trình gửi mail cho nhà cung cấp phải gửi cho Giám đốc kinh doanh và Giám đốc điều hành.
                </p>
                <p>
                  - Trong thời gian người lao động làm việc tại Công ty TNHH Hải Triều, tất cả các giá thành nguyên liệu mua vào, thông tin nhà cung cấp, đối tác,
                  kế hoạch thu mua, quảng cáo, chăm sóc nhà cung cấp phải được bảo mật; Tất cả các nhà cung cấp mà người lao động đang phụ trách trong thời gian
                  làm tại Hải Triều là nhà cung cấp của công ty Hải Triều..
                </p>
                <p>- Tuyệt đối không được chia sẻ thông tin về Ban lãnh đạo công ty, các thông tin nội bộ công ty cho bất kỳ ai..</p>
                <p>- Không được phép xóa các hộp thư đến và hộp thư đi trong các email của công ty giao khi chưa nhận xác nhận bằng email từ Ban Giám đốc công ty.</p>
                <p>
                  - Trong thời gian đã ký kết HĐLĐ với Công ty TNHH Hải Triều không được phép làm thêm với các công ty khác cùng ngành thủy sản (tự kinh doanh, hợp tác,
                  kinh doanh tự do..)
                </p>
              </div> : <div className="spaces text-indent-20">
                <p>
                  - Trong quá trình làm việc cần báo cáo cho người quản lý trực tiếp bằng email mỗi ngày những thông tin liên hệ khách hàng và đơn hàng.
                  Trong quá trình gửi mail cho khách hàng phải gửi cho Giám đốc kinh doanh và Giám đốc điều hành.
                </p>
                <p>
                  - Trong thời gian người lao động làm việc tại Công ty TNHH Hải Triều, tất cả các giá thành sản phẩm bán cho khách hàng, thông tin khách hàng,
                  kế hoạch kinh doanh thương mại, quảng cáo, chăm sóc khách hàng phải được bảo mật; Tất cả các khách hàng mà người lao động đang phụ trách trong
                  thời gian làm tại Hải Triều là khách hàng của công ty Hải Triều.
                </p>
                <p>- Tuyệt đối không được chia sẻ thông tin về Ban lãnh đạo công ty, các thông tin nội bộ công ty cho bất kỳ ai.</p>
                <p>- Không được phép xóa các hộp thư đến và hộp thư đi trong các email của công ty giao khi chưa nhận xác nhận bằng email từ Ban Giám đốc công ty. </p>
                <p>
                  - Trong thời gian đã ký kết HĐLĐ với Công ty TNHH Hải Triều không được phép làm thêm với các công ty khác cùng ngành thủy sản (tự kinh doanh, hợp tác,
                  kinh doanh tự do..)
                </p>
              </div>
            }
          </>
        }

        {
          LOAI_VI_TRI_CONG_VIEC.LAI_XE && <>
            <p> b) Các quyền lợi khác:</p>
            <div className="spaces text-indent-20">
              <p>- Phương tiện đi lại: Tự túc</p>
              <p>- Được trang bị bảo hộ lao động: Theo quy định của Nhà nước về chế độ bảo hộ lao động.</p>
              <p>- Chế độ nghỉ ngơi:</p>
              <div className="spaces text-indent-40">
                <p>+ Nghỉ hàng tuần: theo quy định của Công ty.</p>
                <p>+ Nghỉ ngày lễ: các ngày lễ, tết theo quy định của Công ty và Nhà nước.</p>
              </div>
              <p>- Thiết bị bảo hộ: Người sử dụng lao động phải cung cấp các trang thiết bị bảo hộ cần thiết cho công việc của Người lao động.</p>
              <p>- Các chế độ về BHXH, BHYT, BHTN:</p>
              <div className="spaces text-indent-40">
                <p>+ Thời gian đóng bảo hiểm kể từ ngày ký kết hợp đồng này.</p>
                <p>+ Mức đóng bảo hiểm: Theo mức qui định của nhà nước. Người lao động đóng 10.5%, Người sử dụng lao động đóng 21.5%.</p>
              </div>
            </div>

            <p className="fw-600">4.2. Nghĩa vụ của người lao động:</p>
            <p>
              - Lái xe có trách nhiệm bảo quản, giữ gìn xe luôn sạch sẽ (sau mỗi chuyến đi phải dọn vệ sinh, sắp xếp dụng cụ, thiết bị gọn gàng,
              không để những dụng cụ, thiết bị dễ cháy nổ trên xe). </p>
            <p>
              - Có trách nhiệm tuân thủ Luật giao thông hiện hành và các văn bản hướng dẫn thi hành, thực hiện lộ trình công tác với tinh thần
              trách nhiệm cao nhất. Tuyệt đối không được uống rượu bia khi lái xe (kể cả trong khoảng thời gian 8 tiếng trước khi lái xe). Nếu
              xảy ra vấn đề liên quan đến việc vi phạm luật giao thông, vi phạm pháp luật, lái xe sẽ phải hoàn toàn chịu trách nhiệm do hành vi
              của mình gây ra.
            </p>
            <p>
              - Lái xe không được thay đổi bất kỳ thiết bị, bộ phận nào nguyên bản của xe. Không rút xăng  hoặc khai báo sai số lượng xăng đổ vào.
              Không chở thêm những hàng hóa không phải của công ty hoặc chở những hàng cấm từ bên ngoài.
            </p>
            <p>
              - Thường xuyên kiểm tra lốp và báo cáo ghi chép sau mỗi chuyến đi về (như số xăng dầu km, tình trạng lốp, các rủi ro có thể xảy ra
              cần phải chú ý và các kế hoạch đề xuất).
            </p>
            <p>
              - Trong quá trình vận chuyển hàng hóa phải kiểm soát và đảm bảo nhiệt độ đúng quy trình và chịu trách nhiệm về nhiệt độ hàng hóa.
              Tuân thủ việc bảo đảm đủ nhiệt độ -15 mới được cho xe ra khỏi nhà máy. Trường hợp khẩn cấp phải báo cáo kịp thời cho quản lý trực tiếp.
            </p>
            <p>
              - Luôn thể hiện phẩm chất trung thực, thái độ phục vụ niềm nở, lịch sự, nhiệt tình, đảm bảo đáp ứng thời gian công tác khi được điều động
              đột xuất.
            </p>
            <p>- Hoàn thành những công việc đã cam kết trong hợp đồng lao động này và tất cả các nhiệm vụ trong phạm vi mô tả công việc.</p>
            <p>
              - Nắm rõ và chấp hành nghiêm túc kỷ luật lao động, an toàn, vệ sinh lao động, phòng cháy chữa cháy, văn hóa Công ty, nôi quy
              lao động và các chủ trương, chính sách của Công ty.
            </p>
            <p>
              - Nghiêm túc tuân thủ và tôn trọng thời hạn và các cam kết trong hợp đồng. Khi chấm dứt hợp đồng, phải có trách nhiệm bàn giao tài sản cho
              công ty. Trường hợp để mất mát phải chịu trách nhiệm bồi thường.
            </p>
            <p>
              - Chịu trách nhiệm với công việc của mình, không tiết lộ thông tin của công ty Hải Triều, đảm bảo bí mật tất cả các thông tin, số liệu, quy trình,
              công việc.
            </p>
            <p>- Các nghĩa vụ khác theo quy định tại nội quy Công ty.</p>
          </>
        }
      </div>

      {/* Điều 5 */}
      {
        (LOAI_VI_TRI_CONG_VIEC.KINH_DOANH || LOAI_VI_TRI_CONG_VIEC.THU_MUA_QT || LOAI_VI_TRI_CONG_VIEC.LAI_XE) && <div>
          <p className="fw-600">Điều 5: Quyền và nghĩa vụ của Người sử dụng lao động</p>
          <p className="fw-600">5.1. Nghĩa vụ của Người sử dụng lao động</p>
          <div className="spaces text-indent-20">
            <p>- Đảm bảo việc làm và thực hiện đầy đủ các điều đã cam kết trong hợp đồng</p>
            <p>- Thanh toán đầy đủ, đúng thời hạn các chế độ và quyền lợi cho người lao động theo Hợp đồng.</p>
          </div>

          <p className="fw-600">5.2. Quyền hạn của người sử dụng lao động</p>
          {
            LOAI_VI_TRI_CONG_VIEC.LAI_XE ? <div className="spaces text-indent-20">
              <p> - Giao nhiệm vụ và điều hành người lao động hoàn thành công việc theo hợp đồng lao động đã ký kết.</p>
              <p>
                - Có quyền đình chỉ hoặc áp dụng hình thức kỷ luật theo Luật lao động và Nội quy lao động hoặc chấm dứt Hợp đồng đối với Người lao động vi phạm nội quy,
                quy định của Công ty như: lấy thông tin công ty ra bên ngoài làm tổn hại đến công ty, phá hoại chống phá công ty. Hoặc không đáp ứng các yêu cầu về
                sức khỏe cũng như chuyên môn.
              </p>
              <p>- Yêu cầu người lao động cung cấp mọi thông tin liên quan đến công việc của người lao động theo Hợp đồng này.</p>
            </div> : <div className="spaces text-indent-20">
              <p>
                - Có quyền đình chỉ hoặc áp dụng hình thức kỷ luật theo Luật lao động và Nội quy lao động hoặc chấm dứt Hợp đồng đối với Người lao động vi phạm nội quy,
                quy định của Công ty như: lấy thông tin công ty ra bên ngoài làm tổn hại đến công ty, phá hoại chống phá công ty. Hoặc không đáp ứng các
                yêu cầu về sức khỏe cũng như chuyên môn.
              </p>
              <p>
                - Người sử dụng lao động có quyền điều chuyển Người lao động sang nơi làm việc khác mà Người sử dụng lao động thấy cần thiết, phù hợp và theo quy
                định của pháp luật.
              </p>
            </div>
          }

          <p className="fw-600">5.3. Quyền đơn phương chấm dứt hợp đồng lao động của người sử dụng lao động</p>
          <p className="spaces text-indent-20">
            - Người lao động thường xuyên không hoàn thành công việc theo hợp đồng lao động được xác định theo tiêu chí đánh giá mức độ hoàn thành công việc trong
            quy chế của người sử dụng lao động. Quy chế đánh giá mức độ hoàn thành công việc do người sử dụng lao động ban hành nhưng phải tham khảo ý kiến tổ
            chức đại diện người lao động tại cơ sở đối với nơi có tổ chức đại diện người lao động tại cơ sở.
          </p>
        </div>
      }

      {
        (LOAI_VI_TRI_CONG_VIEC.CONG_NHAN || LOAI_VI_TRI_CONG_VIEC.QC_THONG_KE || LOAI_VI_TRI_CONG_VIEC.VAN_PHONG) && <div>
          <p className="fw-600">Điều 5: Quyền và nghĩa vụ của Người sử dụng lao động</p>
          <p className="fw-600">5.1. Nghĩa vụ của Người sử dụng lao động</p>
          <div className="spaces text-indent-20">
            <p>-  Đảm bảo việc làm và thực hiện đầy đủ các điều đã cam kết trong hợp đồng</p>
            <p>- Thanh toán đầy đủ, đúng hạn các chế độ và quyền lợi cho người lao động theo hợp đồng lao động, thỏa ước lao động tập thể (nếu có).</p>
          </div>

          <p className="fw-600">5.2. Quyền hạn của người sử dụng lao động</p>
          <div className="spaces text-indent-20">
            <p>- Điều hành Người lao động hoàn thành công việc theo hợp đồng lao động (bố trí, điều chuyển công việc cho Người lao động theo đúng chức năng chuyên môn).</p>
            <p>
              - Có quyền chuyển tạm thời lao động, ngừng việc, thay đổi, tạm hoãn, chấm dứt HĐLĐ và áp dụng các biện pháp kỷ luật theo quy định của Pháp luật hiện hành
              và theo nội quy của Công ty trong thời gian hợp đồng lao động còn giá trị.
            </p>
            <p>
              - Có quyền đòi bồi thường, khiếu nại với cơ quan liên đới để bảo vệ quyền lợi của mình nếu Người lao động vi phạm Pháp luật hay các điều khoản của hợp
              đồng lao động.
            </p>
          </div>

          <p className="fw-600">5.3. Quyền đơn phương chấm dứt hợp đồng lao động của người sử dụng lao động</p>
          <p className="spaces text-indent-20">
            - Người lao động thường xuyên không hoàn thành công việc theo hợp đồng lao động được
            xác định theo tiêu chí đánh giá mức độ hoàn thành công việc trong quy chế của người sử dụng lao động. Quy chế đánh giá mức độ hoàn
            thành công việc do người sử dụng lao động ban hành nhưng phải tham khảo ý kiến tổ chức đại diện người lao động tại cơ sở đối với nơi
            có tổ chức đại diện người lao động tại cơ sở.
          </p>
        </div>
      }

      {/* Điều 6 */}
      <div>
        <p className="fw-600">Điều 6: Điều khoản chung</p>
        <div className="spaces text-indent-20">
          <p>
            - Những vấn đề về lao động không được thỏa thuận trong hợp đồng này thì áp dụng thỏa ước lao động tập thể của Công ty, trường hợp không được thể
            hiện trong thỏa ước lao động tập thể thì áp dụng quy định của pháp luật lao động.
          </p>
          <p>
            - Khi hai bên có sự thỏa thuận để thay đổi nội dung trong hợp đồng này thì sẽ ký kết phụ lục hợp đồng lao động, phụ lục hợp đồng cũng có giá trị
            như các nội dung của hợp đồng này.
          </p>
          <p>- Hợp đồng này được làm thành 02 bản có giá trị pháp lý như nhau, Người sử dụng lao động giữ 01 bản và Người lao động giữ 01 bản.</p>
        </div>
      </div>

      <div className="flex-center">
        <div className="spaces text-center align-start flex flex-between mt-16 pb-70 width-80">
          <div>
            <p className="fw-600">NGƯỜI LAO ĐỘNG</p>
            <p className="text-italic">(Ký, ghi rõ họ tên)</p>
            <p className="spaces mt-70 fw-600 text-capitalize-first">{data.fullName}</p>
          </div>
          <div>
            <p className="fw-600">NGƯỜI SỬ DỤNG LAO ĐỘNG</p>
            <p className="text-italic">(Ký, ghi rõ họ tên)</p>
            <p className="spaces mt-70 fw-600 text-capitalize-first">{data.nguoiDaiDienCtyKyText}</p>
          </div>
        </div>
      </div>
    </div>
  );
}
