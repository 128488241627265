import { useEffect, useState } from "react";
import useMultiLanguage from "../../../../../hook/useMultiLanguage";
import TableCustom, { columnNamesType } from "../../../../component/table-custom/TableCustom";
import { RESPONSE_STATUS_CODE } from "../../../../utils/Constant";
import { toast } from "react-toastify";
import { convertNumberPrice, formatDateTable } from "../../../../utils/FunctionUtils";
import { PROBLEM_STATUS_LIST } from "../../../../problem/constant/ProblemConst";
import { checkStatus } from "../../../../problem/utils/ProblemUtils";
import { searchProblem } from "../../../../problem/services/ProblemServices";
import ProblemViewDialog from "./ProblemViewDialog";
import { IProblem } from "../../../../problem/models/ProblemModel";

interface Iprops {
  isCallApi: boolean;
  employeeProfiles: any;
}

function ProblemTable(props: Iprops) {
  const { lang } = useMultiLanguage();
  const { isCallApi, employeeProfiles } = props;

  const [problemList, setProblemList] = useState<any[]>([]);
  const [totalElements, setTotalElements] = useState<number>(0);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [numberOfElements, setNumberOfElements] = useState<number>(0);
  const [problemItem, setProblemItem] = useState<IProblem>({} as IProblem);
  const [shouldOpenViewDialog, setShouldOpenViewDialog] = useState<boolean>(false);

  useEffect(() => {
    updatePageData();
  }, [isCallApi]);

  const updatePageData = async (searchObj?: any) => {
    if (!isCallApi) return;
    try {
      const searchData = {
        ...searchObj,
        employeeId: employeeProfiles?.id
      };
      const { data } = await searchProblem(searchData);
      if (data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
        setProblemList(data?.data?.content || []);
        setTotalPages(data?.data?.totalPages || 0);
        setTotalElements(data?.data?.totalElements || 0);
        setNumberOfElements(data?.data?.numberOfElements || 0);
      }
    } catch (error) {
      toast.error(lang("GENERAL.ERROR"));
    }
  };

  const handleExportExcel = () => {};

  const handleOpenDialog = (row: any) => {
    setShouldOpenViewDialog(true);
    setProblemItem(row);
  };

  const handleCloseDialog = () => {
    setShouldOpenViewDialog(false);
  };

  const columns: columnNamesType[] = [
    {
      name: "STT",
      field: "",
      render: (row: any, index: number, stt: number) => <span>{stt}</span>
    },
    {
      name: lang("GENERAL.STATUS"),
      field: "trangThai",
      headerStyle: {
        minWidth: "120px"
      },
      render: (row: any) => (
        <span
          className={`${checkStatus(
            PROBLEM_STATUS_LIST,
            row?.trangThai?.code
          )} rounded-5xl spaces px-12 py-4 text-white`}
        >
          {row?.trangThai?.name}
        </span>
      )
    },
    {
      name: lang("PROBLEM.NAME"),
      field: "tenSuCo",
      headerStyle: {
        minWidth: "200px"
      },
      cellStyle: {
        minWidth: "200px",
        textAlign: "left"
      }
    },
    {
      name: lang("PROBLEM.TYPE"),
      field: "loaiSuCoText",
      headerStyle: {
        minWidth: "200px"
      },
      cellStyle: {
        minWidth: "200px",
        textAlign: "left"
      }
    },
    {
      name: lang("PROBLEM.NAMEDATE_OF_OCCURRED"),
      field: "ngayXayRa",
      headerStyle: {
        minWidth: "200px"
      },
      cellStyle: {
        minWidth: "200px",
        textAlign: "left"
      },
      render: (row) => <span>{formatDateTable(row?.ngayXayRa)}</span>
    },
    {
      name: lang("PROBLEM.FORMS_PROCESSING"),
      field: "hinhThucXuLyText",
      headerStyle: {
        minWidth: "200px"
      },
      cellStyle: {
        minWidth: "200px",
        textAlign: "left"
      }
    },
    {
      name: lang("PROBLEM.TOTAL_VALUE_OF_DAMAGES"),
      field: "giaTriThietHai",
      headerStyle: {
        minWidth: "200px"
      },
      cellStyle: {
        minWidth: "200px",
        textAlign: "left"
      },
      render: (row) => <span>{convertNumberPrice(row?.giaTriThietHai)}</span>
    },
    {
      name: lang("GENERAL.NOTE"),
      field: "note",
      headerStyle: {
        minWidth: "200px"
      },
      cellStyle: {
        minWidth: "200px",
        textAlign: "left"
      }
    }
  ];

  return (
    <div>
      <TableCustom
        title={lang("PROBLEM.LIST")}
        isActionTableTab
        data={problemList || []}
        columns={columns}
        handleOpenDialog={handleOpenDialog}
        handleExportExcel={handleExportExcel}
        fixedColumnsCount={4}
        handleDoubleClick={handleOpenDialog}
        totalPages={totalPages}
        totalElements={totalElements}
        numberOfElements={numberOfElements}
        notDelete
        noPagination
      />
      {shouldOpenViewDialog && (
        <ProblemViewDialog
          open={shouldOpenViewDialog}
          handleClose={handleCloseDialog}
          problemInfo={problemItem}
        />
      )}
    </div>
  );
}

export default ProblemTable;
