const RESPONSE_STATUS_CODE = {
  SUCCESS: 200,
  CREATED: 201,
  NO_CONTENT: 204,
  BAD_REQUEST: 400,
  UNAUTHORIZED: 401,
  FORBIDDEN: 403,
  NOT_FOUND: 404,
  METHOD_NOT_ALLOWED: 405,
  CONFLICT: 409,
  INTERNAL_SERVER_ERROR: 500,
  BAD_GATEWAY: 502,
}

export const KEY_LOCALSTORAGE = {
  CURRENT: "current",
  LIST_SUB_MENU: 'listSubMenu',
  DEFAULT_MODULE: 'profile',
  AUTHORITIES: "authorities",
  LIST_MENU: "listMenu",
  URL_IAMGE_AVATAR: "urlImageAvatar",
  ACCOUNT_EMPLOYEE_ID: "account_employee_id",
  AUTH_LOCAL_STORAGE_KEY: 'kt-auth-react-v'
}

export {RESPONSE_STATUS_CODE}
