import { ITemplateExportWord } from "../../component/exportWord";
import { TYPE_CATEGORY } from "../../constant";
import { OptionReactSelect } from "../../models/models";
import { searchPhongBan } from "../../profile/services/DialogServices";
import { searchAllSimpleValue } from "../../services";
import { SEARCH_OBJECT_MAX_SIZE, TYPE } from "../../utils/Constant";
import { ContractWord } from "../../word-template/contract/ContractWord";
import { ContractTraining } from "../../word-template/contract/ContractTraining";
import { IAllowance } from "../services/models/IAllowance";
import { IContractAnnexInfo, IContractInfo, OptionType } from "../services/models/IContract";

export const contractDuration: OptionReactSelect[] = [
  {
    code: "1",
    name: "1 tháng",
  },
  {
    code: "2",
    name: "2 tháng",
  },
  {
    code: "3",
    name: "3 tháng",
  },
  {
    code: "4",
    name: "6 tháng",
  },
  {
    code: "4",
    name: "1 năm",
  },
  {
    code: "5",
    name: "3 năm",
  },
];
export const contractAnnex: IContractAnnexInfo[] = [];

export const SUCCESS_CODE = 200;

export const administrativeUnits: OptionType[] = [
  {
    title: "Đơn vị 1",
    value: "1",
  },
  {
    title: "Đơn vị 2",
    value: "2",
  },
  {
    title: "Đơn vị 3",
    value: "3",
  },
];

export const workPositions: OptionType[] = [
  {
    title: "Vị trí 1",
    value: "1",
  },
  {
    title: "Vị trí 2",
    value: "2",
  },
  {
    title: "Vị trí 3",
    value: "3",
  },
];

export const contractTypes: OptionReactSelect[] = [
  {
    code: "1",
    name: "Thử việc",
  },
  {
    code: "2",
    name: "Hợp đồng xác định thời hạn",
  },
  {
    code: "3",
    name: "Hợp đồng không xác định thời hạn",
  },
  {
    code: "4",
    name: "Học việc",
  },
  {
    code: "5",
    name: "Hợp đồng mùa vụ",
  },
  {
    code: "6",
    name: "Hợp đồng dịch vụ",
  },
];

export const workForms: OptionReactSelect[] = [
  {
    code: "1",
    name: "Toàn thời gian",
  },
  {
    code: "2",
    name: "Bán thời gian",
  },
  {
    code: "3",
    name: "Cộng tác viên",
  },
];
export const signinStatus = [
  {
    code: 1,
    name: "Chưa ký",
    styleClass: "bg-green"
  },
  {
    code: 2,
    name: "Đã ký",
    styleClass: "bg-gray-700"
  },
];
export const representtativeTitle: OptionType[] = [
  {
    title: "Chức danh 1",
    value: "1",
  },
  {
    title: "Chức danh 2",
    value: "2",
  },
  {
    title: "Chức danh 3",
    value: "3",
  },
];

export const INIT_ALLOWANCE: IAllowance = {
  id: "",
  employeeId: "",
  hopDongLaoDongId: "",
  tenKhoanPhuCap: null,
  tinhCongHuongLuong: false,
  giaTri: null,
  ghiChu: "",
}

export const INIT_CONTACT: IContractInfo = {
  id: "",
  employeeId: "",
  donViKyHopDong: null,
  viTriCongViec: null,
  soHopDong: "",
  tenHopDong: "",
  nguoiDaiDienCtyKy: null,
  nguoiDaiDienCtyChucDanh: null,
  loaiHopDong: null,
  thoiHanHopDong: null,
  hinhThucLamViec: null,
  ngayCoHieuLuc: "",
  ngayHetHan: "",
  ngayKyHopDong: "",
  luongCoBan: "",
  luongDongBaoHiem: "",
  tyLeHuongLuong: "",
  ghiChu: "",
  fileId: "",
  tenNguoiLaoDong: "",
  employeeCode: "",
  trangThaiKy: null,
  trangThaiHopDong: null,
  employee: undefined,
  phongBan: null,
  chucVu: null,
  chucDanh: null,
  bacLuongOption: null,
  heSoLuong: "",
  vienChuc: false,
  hopDongLaoDong: true,
  caLamViec: null,
  giayUyQuyen: false,
  nguoiDaiDienCtyChucDanhText: "",
  nguoiDaiDienCtyChucDanhId: "",
  nguoiDaiDienCtyKyId: "",
  nguoiDaiDienCtyKyText: "",
  moTaCongViec: "",
  diaDiemLamViec: null,
  diaDiemLamViecId: "",
  diaDiemLamViecText: "",
  ngayTraLuongThang: null,
  ngayTraLuongThangId: "",
  ngayTraLuongThangText: "",
  thoiGianLamViec: null,
  thoiGianLamViecId: "",
  thoiGianLamViecText: ""
}

export const INIT_CONTACT_ANNEX_INFO: IContractAnnexInfo = {
  employeeId: "",
  fileId: "",
  fileName: "",
  ghiChu: "",
  hopDongGoc: "",
  hopDongLaoDongId: "",
  id: "",
  luongCoBan: "",
  luongDongBaoHiem: "",
  ngayCoHieuLuc: "",
  ngayCoHieuLucPhuLuc: "",
  ngayHetHan: "",
  ngayKy: "",
  soPhuLuc: "",
  tenPhuLuc: "",
  tyLeHuongLuong: "",
  donViKyHopDong: null,
  viTriCongViec: null,
  nguoiDaiDienCtyKy: null,
  nguoiDaiDienCtyChucDanh: null,
  hinhThucLamViec: null,
  loaiHopDong: null,
  thoiHanHopDong: null,
  trangThaiKy: null,
  bacLuongOption: null,
  heSoLuong: ""
}

export const ListSearch = () => {
  return [
    {
      name: "Số hợp đồng",
      field: "soHopDong",
      type: TYPE.TEXT,
    },
    {
      name: "Mã nhân viên",
      field: "maNhanVien",
      type: TYPE.TEXT
    },
    {
      name: "Họ và tên",
      field: "tenNhanVien",
      type: TYPE.TEXT,
    },
    {
      name: "Ngày ký HĐ",
      field: "ngayKyHopDong",
      type: TYPE.DATE,
    },
    {
      name: "Phòng/Ban",
      field: "phongBanIds",
      searchFunction: searchPhongBan,
      searchObject: { ...SEARCH_OBJECT_MAX_SIZE },
      type: TYPE.SELECT,
      isMulti: true,
    },
    {
      name: "Chức vụ",
      field: "chucVuId",
      searchFunction: searchAllSimpleValue,
      searchObject: { ...SEARCH_OBJECT_MAX_SIZE, type: TYPE_CATEGORY.chucDanh },
      type: TYPE.SELECT,
      optionLabel: "value",
    },
    {
      name: "Vị trí công việc",
      field: "viTriCongViecIds",
      searchFunction: searchAllSimpleValue,
      searchObject: { ...SEARCH_OBJECT_MAX_SIZE, type: TYPE_CATEGORY.viTriCongViec },
      type: TYPE.SELECT,
      optionLabel: "value",
      isMulti: true,
    },
    {
      name: "Loại HĐ",
      field: "loaiHopDong",
      type: TYPE.SELECT,
      listOption: contractTypeHopDong,
    },
    {
      name: "Trạng thái ký",
      field: "trangThaiKy",
      type: TYPE.SELECT,
      listOption: signinStatus,
    },
    {
      name: "Trạng thái HĐ",
      field: "trangThaiHopDong",
      type: TYPE.SELECT,
      listOption: contractStatus,
      isMulti: true,
    },
  ]
} 

export const CODE_HOP_DONG = {
  VIEN_CHUC_DAI_HAN: 1,
  HOP_DONG_DAI_HAN: 5,
  ONE_YEAR: 7
};

export const contractTypeBienChe: OptionReactSelect[] = [
  {
    code: 1,
    name: "Hợp đồng không xác định thời hạn"
  },
  {
    code: 2,
    name: "Hợp đồng có thời hạn"
  },
  {
    code: 3,
    name: "Hợp đồng tập sự"
  },
  {
    code: 4,
    name: "Hợp đồng nghị định 111 (HĐ 68 cũ)"
  }
]

export const contractTypeHopDong: OptionReactSelect[] = [
  {
    code: 5,
    name: "Hợp đồng không xác định thời hạn"
  },
  {
    code: 6,
    name: "Hợp đồng có thời hạn"
  },
  {
    code: 7,
    name: "Thời hạn 1 năm"
  },
  {
    code: 8,
    name: "Cộng tác viên thường xuyên"
  },
  {
    code: 9,
    name: "Cộng tác viên"
  },
  {
    code: 10,
    name: "Hợp đồng thử việc"
  },
  {
    code: 11,
    name: "Hợp đồng học việc"
  },
  {
    code: 12,
    name: "Hợp đồng đào tạo thực hành"
  }
]

export const workShifts:  OptionReactSelect[] = [
  {
    code: 1,
    name: "Không giới hạn số giờ"
  },
  {
    code: 2,
    name: "40h/tuần"
  },
  {
    code: 3,
    name: "44h/tuần"
  },
  {
    code: 4,
    name: "48h/tuần"
  },
  {
    code: 5,
    name: "Theo thỏa thuận"
  }
]

export const codeStatusContract = {
  IN_EFFECT: 1,
  EXPIRE: 2,
  NOT_YET_APPLIED: 3
}

export const contractStatus = [
  {
    code: codeStatusContract.IN_EFFECT,
    name: "Còn hiệu lực",
    styleClass: "bg-green"
  },
  {
    code: codeStatusContract.EXPIRE,
    name: "Hết hiệu lực",
    styleClass: "bg-gray-700"
  },
  {
    code: codeStatusContract.NOT_YET_APPLIED,
    name: "Chưa áp dụng",
    styleClass: "bg-purple",
  }
]

export const DEPARTMENTS_TYPES = {
  PRODUCTION: 1,
  OFFICE: 2
}

export const LOAI_VI_TRI_CONG_VIEC = {
  CONG_NHAN: 1,
  KINH_DOANH: 2,
  LAI_XE: 3,
  QC_THONG_KE: 4,
  THU_MUA_QT: 5,
  VAN_PHONG: 6,
}

export const contractWordExportList: ITemplateExportWord[] = [
  {
    type: LOAI_VI_TRI_CONG_VIEC.CONG_NHAN,
    name: "HĐLĐ công nhân",
    componentPreview: (dataPreview) => <ContractWord data={dataPreview}/>
  },
  {
    type: LOAI_VI_TRI_CONG_VIEC.VAN_PHONG,
    name: "HĐLĐ văn Phòng",
    componentPreview: (dataPreview) => <ContractWord data={dataPreview}/>
  },
  {
    type: LOAI_VI_TRI_CONG_VIEC.THU_MUA_QT,
    name: "HĐLĐ thu mua",
    componentPreview: (dataPreview) => <ContractWord data={dataPreview}/>
  },
  {
    type: LOAI_VI_TRI_CONG_VIEC.QC_THONG_KE,
    name: "HĐLĐ QC thống kê",
    componentPreview: (dataPreview) => <ContractWord data={dataPreview}/>
  },
  {
    type: LOAI_VI_TRI_CONG_VIEC.LAI_XE,
    name: "HĐLĐ lái xe",
    componentPreview: (dataPreview) => <ContractWord data={dataPreview}/>
  },
  {
    type: LOAI_VI_TRI_CONG_VIEC.KINH_DOANH,
    name: "HĐLĐ kinh doanh",
    componentPreview: (dataPreview) => <ContractWord data={dataPreview}/>
  },
] 

export const contractTrainingWord: ITemplateExportWord[] = [
  {
    name: "Hợp đồng đào tạo",
    componentPreview: (dataPreview) => <ContractTraining data={dataPreview}/>
  },
] 

