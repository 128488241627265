import useMultiLanguage, { ILang } from "../../../hook/useMultiLanguage";
import { OptionReactSelect } from "../../models/models";
import { getAllDonVi } from "../../profile/services/ProfileServices";
import { MISSION_STATUS, SEARCH_OBJECT_MAX_SIZE, TYPE } from "../../utils/Constant";
import { IAttachedPeople, IEmployeeJoin, IExpenses, IWelfare } from "../model/WelfareModel";

export const groupWelfare: OptionReactSelect[] = [
  {
    name: "Du lịch",
    code: 1
  },
  {
    name: "Giải trí",
    code: 2
  },
  {
    name: "Tài chính",
    code: 3
  },
  {
    name: "Y tế",
    code: 4
  }
];
export const formEvent: OptionReactSelect[] = [
  {
    name: "Nội bộ",
    code: 1
  },
  {
    name: "Thuê ngoài",
    code: 2
  }
];

export const employeeJoinStatus: OptionReactSelect[] = [
  {
    name: "Chưa xác nhận tham gia",
    code: 1
  },
  {
    name: "Xác nhận tham gia",
    code: 2
  },
  {
    name: "Không tham gia",
    code: 3
  }
];

export const statusListJoin: OptionReactSelect[] = [
  {
    name: "Chưa xác nhận tham gia",
    code: "1"
  },
  {
    name: "Xác nhận tham gia",
    code: "2"
  },
  {
    name: "Từ chối tham gia",
    code: "3"
  },
  {
    name: "Đã tham gia",
    code: "4"
  }
];

export const ListSearch = (lang: ILang) => {
  return [
    {
      name: lang("GENERAL.STATUS"),
      field: "trangThai",
      type: TYPE.SELECT,
      listOption: MISSION_STATUS
    },
    {
      name: lang("WELFARE.NAME"),
      field: "tenPhucLoi",
      type: TYPE.TEXT
    },
    {
      name: lang("WELFARE.GROUP"),
      field: "nhomPhucLoi",
      type: TYPE.SELECT,
      listOption: groupWelfare
    },
    {
      name: lang("WELFARE.APPLY.UNIT"),
      field: "donViApDungId",
      type: TYPE.SELECT,
      searchFunction: getAllDonVi,
      searchObject: { ...SEARCH_OBJECT_MAX_SIZE },
    },
    {
      name: lang("WELFARE.ORGANIZATION.PLACE"),
      field: "diaDiemToChuc",
      type: TYPE.TEXT
    },
    {
      name: lang("WELFARE.ORGANIZATION.PURPOSE"),
      field: "mucDichToChuc",
      type: TYPE.TEXT
    },
    {
      name: lang("WELFARE.IMPLEMENT.FORM"),
      field: "hinhThucThucHien",
      type: TYPE.SELECT,
      listOption: formEvent
    }
  ];
};

export const INIT_WELFARE: IWelfare = {
  diaDiemToChuc: "",
  donViApDungId: "",
  donViApDungText: "",
  hinhThucThucHien: null,
  mucDichToChuc: "",
  ngayBatDau: "",
  ngayKetThuc: "",
  nhomPhucLoi: null,
  soNguoiDuKien: null,
  soTienDvHoTro: null,
  soTienNvDong: null,
  tenPhucLoi: "",
  trangThai: null,
  donViApDung: null,
  phucLoiChiTietRequestDtoList: null,
  phucLoiKhoanChiRequestDtoList: null
};

export const INIT_EMPLOYEE_JOIN: IEmployeeJoin = {
  ten: "",
  donViApDungId: "",
  donViApDungText: "",
  employeeId: "",
  maNhanVien: "",
  phongBanId: "",
  phongBanText: "",
  phucLoiId: "",
  soTienNhanVienDong: null,
  soTienDonViHoTro: null,
  chucVuId: "",
  chucVuText: ""
};

export const INIT_EXPENSES: IExpenses = {
  note: "",
  phucLoiId: "",
  soTien: null,
  tenKhoanChi: "",
  tenNguoiDuyet: "",
  nguoiDuyetId: "",
  nguoiDuyet: null
};

export const INIT_ATTACHED_PEOPLE: IAttachedPeople = {
  ten: "",
  gender: null,
  ngaySinh: "",
  note: "",
  phucLoiChiTietId: "",
  phucLoiId: "",
  quanHe: null,
  soTienDonViHoTro: null,
  soTienDong: null,
  quanHeId: "",
  quanHeText: ""
};
