import { useContext, useEffect, useMemo, useState } from "react";
import { Col, Modal, Row } from "react-bootstrap";
import Autocomplete from "../../component/input-field/Autocomplete";
import { GroupButton } from "../../component/GroupButton";
import { exportToFile, formatDateTable, formatDateTime } from "../../utils/FunctionUtils";
import { exportSoCongVan } from "../../utils/ExportFileServices";
import AppContext from "../../../AppContext";
import { RESPONSE_STATUS_CODE, SEARCH_OBJECT_MAX_SIZE, TYPE } from "../../utils/Constant";
import useMultiLanguage from "../../../hook/useMultiLanguage";
import { DISPATCH_BOOK_EXPORT_WORD_CODES, DISPATCH_BOOK_EXPORT_WORD_LIST } from "../constant/dispatchBookConst";
import { OptionReactSelect } from "../../models/models";
import "../../profile/component/word-template/StyleWord.scss";
import TableGrouping, { IColumns } from "../../component/TableGrouping/TableGrouping";
import { IDispatchBook } from "../models/dispatchBookModel";
import { searchDispatchBook } from "../services/dispatchBookServices";
import { toast } from "react-toastify";
import TextValidator from "../../component/input-field/TextValidator";

interface IProps {
  handleClose: () => void;
  open: boolean;
}

function ExportWord(props: IProps) {
  const { handleClose, open } = props;
  const { lang } = useMultiLanguage();
  const { setPageLoading } = useContext(AppContext);

  const [typeExortWord, setTypeExportWord] = useState<OptionReactSelect>(DISPATCH_BOOK_EXPORT_WORD_LIST[0]);
  const [dataPreview, setDataPreview] = useState<IDispatchBook[]>([]);
  const [startDate, setStartDate] = useState<string | null>(null)
  const [endDate, setEndDate] = useState<string | null>(null)

  useEffect(() => {
    let newDate = new Date();
    setEndDate(formatDateTime(newDate));
    newDate.setDate(1);
    newDate.setMonth(0);
    if (newDate) {
      setStartDate(formatDateTime(newDate));
    }
  }, [])

  useEffect(() => {
    getDataPreview(Number(typeExortWord.code));
  }, [typeExortWord]);

  const getDataPreview = async (type: number) => {
    try {
      setPageLoading(true);
      const { data } = await searchDispatchBook({ ...SEARCH_OBJECT_MAX_SIZE, dangCongVan: type });
      if (data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
        setDataPreview(data?.data?.content || []);
      }
    } catch (error) {
      toast.error(lang("GENERAL.ERROR"));
    } finally {
      setPageLoading(false);
    }
  };

  const handleExportWord = () => {
    let searchObject = {
      ...SEARCH_OBJECT_MAX_SIZE,
      dangCongVan: typeExortWord.code,
      startDate: startDate || null,
      endDate: endDate || null
    }

    exportToFile({
      exportAPI: () => exportSoCongVan(searchObject),
      fileName: lang(titleFormExport),
      type: TYPE.WORD,
      setPageLoading
    });
  };

  const titleFormExport = useMemo(() => {
    const { DISPATCH_ARRIVED } = DISPATCH_BOOK_EXPORT_WORD_CODES;
    return DISPATCH_ARRIVED === typeExortWord.code ? "DISPATCH_BOOK.FLOWING_TO" : "DISPATCH_BOOK.FLOWING_FROM";
  }, [typeExortWord]);

  const columns: IColumns[] = [
    {
      name: "STT",
      field: "",
      render: (_, stt) => <span>{stt}</span>
    },
    {
      name: lang("DISPATCH_BOOK.DAY"),
      field: "ngay",
      render: (row) => <span>{formatDateTable(row?.ngayNhanGui)}</span>
    },
    {
      name: lang("DISPATCH_BOOK.NUMBER_AND_DATE_RELEASE"),
      field: "soCongVan"
    },
    {
      name: lang("DISPATCH_BOOK.BASIC_CONTENT"),
      field: "noiDungCongVan"
    },
    {
      name: lang("DISPATCH_BOOK.AGENCY_AND_SENDING_DEPARTMENT"),
      field: "boPhanGui"
    },
    {
      name: lang("DISPATCH_BOOK.AGENCY_AND_RECEIVE_DEPARTMENT"),
      field: "boPhanNhan"
    },
    {
      name: lang("DISPATCH_BOOK.NUMBER_COP"),
      field: "soBan"
    },
    {
      name: lang("DISPATCH_BOOK.PEOPLE_SEND"),
      field: "nguoiNhan"
    },
    {
      name: lang("DISPATCH_BOOK.PEOPLE_RECEIVE"),
      field: "nguoiGui"
    }
  ];

  return (
    <Modal show={open} size="xl" centered className="custom-modal export-word" onHide={handleClose}>
      <Modal.Header className="spaces px-16 py-10">
        <div className="w-100">
          <div className="spaces flex flex-middle flex-space-between py-12 color-primary text-uppercase">
            <GroupButton type="btn-back" handleClose={handleClose} />
            <button className="button-primary" onClick={handleExportWord}>
              {lang("GENERAL.EXPORT_WORD")}
            </button>
          </div>
          <Row>
            <Col xs={6} className="spaces z-index-10">
              <Autocomplete
                horizontal={true}
                lable={lang("DISPATCH_BOOK.SELECT_TYPE_EXPORT_WORD")}
                options={DISPATCH_BOOK_EXPORT_WORD_LIST}
                value={typeExortWord}
                onChange={(value) => setTypeExportWord(value)}
              />
            </Col>
            <Col xs={6} className="export-word__group-date">
              <TextValidator
                lable="Từ ngày"
                className="date"
                type="date"
                value={startDate}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setStartDate(e.target.value)}
                />
              <TextValidator
                lable="Đến ngày"
                className="date"
                type="date"
                value={endDate}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setEndDate(e.target.value)}
              />
            </Col>
          </Row>
        </div>
      </Modal.Header>
      <Modal.Body className="export-word__content">
        <div className="word">
          <div className="word__header">
            <div className="word__header__item spaces pl-6">
              <p className="text-uppercase font-600">CÔNG TY TNHH HẢI TRIỀU – HAI TRIEU COMPANY LIMITED</p>
              <span>Địa chỉ : Lô 2/6 KCN Phan Thiết, Phong Nẫm , Phan Thiết, Bình Thuận</span>
              <p>
                <span className="font-600">Tel</span> : 0252.3739.666;
              </p>
            </div>
            <div className="word__header__item">
              <p className="text-uppercase">{lang(titleFormExport)}</p>
            </div>
          </div>
          <TableGrouping
            id="export-word-dispatch-book"
            columns={columns}
            data={dataPreview}
            className="table-custom"
          />
          <p className="spaces mt-10">Lần ban hành : 00 , ngày 03/07/2020, TCW005V-F1</p>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default ExportWord;
