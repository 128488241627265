import { FC, useContext, useState, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import AppContext from "../../AppContext";
import { PERMISSIONS, PERMISSION_ABILITY } from "../../Constant";
import useMultiLanguage from "../../hook/useMultiLanguage";
import InputSearch from "../component/input-field/InputSearch";
import TableCustom, { columnNamesType } from "../component/table-custom/TableCustom";
import AdvancedSearch from "../profile/component/Dialog/AdvancedSearch";
import { INIT_INPUT_SEARCH } from "../profile/const/ProfileConst";
import { IItemSearch } from "../profile/models/ProfileModels";
import { RESPONSE_STATUS_CODE, TYPE } from "../utils/Constant";
import { convertSearch, formatDateTable, hasAuthority } from "../utils/FunctionUtils";
import { IDispatchBook } from "./models/dispatchBookModel";
import { ListSearch } from "./constant/dispatchBookConst";
import DispatchBookForm from "./components/DispatchBookForm";
import "./disptachBook.scss";
import ExportWord from "./components/ExportWord";
import { deleteDispatchBook, searchDispatchBook } from "./services/dispatchBookServices";
import { INIT_SEARCH_OBJECT } from "../constant";
import { IObject } from "../models/models";

const DispatchBook: FC = () => {
  const { lang } = useMultiLanguage();
  document.title = `${lang("GENERAL.APP.NAME")} | ${lang("DISPATCH_BOOK")}`;
  const { setPageLoading } = useContext(AppContext);

  const [totalPage, setTotalPage] = useState<number>(0);
  const [totalElements, setTotalElements] = useState<number>(0);
  const [numberOfElements, setNumberOfElements] = useState<number>(0);
  const [isView, setIsView] = useState<boolean>(false);
  const [searchObject, setSearchObject] = useState<IObject>(INIT_SEARCH_OBJECT);
  const [isAdvancedSearch, setIsAdvancedSearch] = useState<boolean>(false);
  const [listInputSearch, setListInputSearch] = useState<IItemSearch[]>([INIT_INPUT_SEARCH]);
  const [listData, setListData] = useState<IDispatchBook[]>([]);
  const [rowInfo, setRowInfo] = useState<IDispatchBook>({} as IDispatchBook);
  const [shouldOpenFormDialog, setShouldOpenFormDialog] = useState<boolean>(false);
  const [shouldOpenExportWord, setShouldOpenExportWord] = useState<boolean>(false);

  useEffect(() => {
    if(searchObject?.pageIndex || searchObject?.pageSize){
      handleSearch();
    }
  }, [searchObject?.pageIndex, searchObject?.pageSize])

  const updatePageData = async (searchData?: IObject) => {
    try {
      setPageLoading(true);
      setSearchObject({ ...searchData });
      const { data } = await searchDispatchBook(searchData);
      if (data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
        setListData(data?.data?.content || []);
        setTotalPage(data?.data?.totalPages || 0);
        setTotalElements(data?.data?.totalElements || 0);
        setNumberOfElements(data?.data?.numberOfElements || 0);
        setIsAdvancedSearch(false);
      }
    } catch (error) {
      toast.error(lang("GENERAL.ERROR"));
    } finally {
      setPageLoading(false);
    }
  };

  const handleOpenAdvancedSearch = () => {
    setIsAdvancedSearch(true);
  };

  const handleOpenDialog = () => {
    setShouldOpenFormDialog(true);
  };

  const handleOpenInfoDialog = (row: IDispatchBook) => {
    setIsView(true);
    setShouldOpenFormDialog(true);
    setRowInfo(row);
  };

  const handleCloseDialog = () => {
    setShouldOpenFormDialog(false);
    setRowInfo({} as IDispatchBook);
    setIsView(false);
  };

  const handleCloseAndSearch = () => {
    handleSearch();
    handleCloseDialog();
  }

  const handleOpenUpdateDialog = () => {
    setIsView(false);
  };

  const handleCloseUpdateDialog = () => {
    setIsView(true);
  };

  const handleSearch = (data?: IObject) => {
    const dataSearch = {
      ...searchObject,
      ...data
    };
    dataSearch.keyword = dataSearch?.keyword?.trim() || "";
    updatePageData(dataSearch);
  };

  const handleAdvancedSearch = (data: IItemSearch[]) => {
    setListInputSearch(data);
    handleSearch({ ...convertSearch(data), pageIndex: 1 });
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchObject({
      ...searchObject,
      keyword: e.target?.value
    });
  };

  const handleDelete = async (ids: string[]) => {
    try {
      const { data } = await deleteDispatchBook(ids);
      if (data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
        toast.success(lang("TOAST.DELETE.SUCCESS"));
        updatePageData(searchObject);
        return true;
      }
    } catch (error) {
      toast.error(lang("GENERAL.ERROR"));
    }
  };

  const columns: columnNamesType[] = [
    {
      name: "STT",
      field: "stt",
      render: (_, index, stt) => <span>{stt}</span>
    },
    {
      name: lang("DISPATCH_BOOK.DAY"),
      field: "ngayNhanGui",
      headerStyle: {
        minWidth: "100px"
      },
      render: (row) => <span>{formatDateTable(row?.ngayNhanGui)}</span>
    },
    {
      name: lang("DISPATCH_BOOK.FORM"),
      field: "dangCongVan",
      headerStyle: {
        minWidth: "200px"
      },
      cellStyle: {
        textAlign: "left"
      },
      render: (row) => <span>{row?.dangCongVan?.name}</span>
    },
    {
      name: lang("DISPATCH_BOOK.NUMBER_AND_DATE_RELEASE"),
      field: "soCongVan",
      headerStyle: {
        minWidth: "200px"
      },
      cellStyle: {
        textAlign: "left"
      }
    },
    {
      name: lang("DISPATCH_BOOK.BASIC_CONTENT"),
      field: "noiDungCongVan",
      headerStyle: {
        minWidth: "350px"
      },
      cellStyle: {
        textAlign: "left"
      }
    },
    {
      name: lang("DISPATCH_BOOK.AGENCY_AND_SENDING_DEPARTMENT"),
      field: "boPhanGui",
      headerStyle: {
        minWidth: "200px"
      },
      cellStyle: {
        textAlign: "left"
      }
    },
    {
      name: lang("DISPATCH_BOOK.AGENCY_AND_RECEIVE_DEPARTMENT"),
      field: "boPhanNhan",
      headerStyle: {
        minWidth: "200px"
      },
      cellStyle: {
        textAlign: "left"
      }
    },
    {
      name: lang("DISPATCH_BOOK.NUMBER_COP"),
      field: "soBan",
      headerStyle: {
        minWidth: "100px"
      }
    },
    {
      name: lang("DISPATCH_BOOK.PEOPLE_SEND"),
      field: "nguoiGui",
      headerStyle: {
        minWidth: "200px"
      },
      cellStyle: {
        textAlign: "left"
      }
    },
    {
      name: lang("DISPATCH_BOOK.PEOPLE_RECEIVE"),
      field: "nguoiNhan",
      headerStyle: {
        minWidth: "200px"
      },
      cellStyle: {
        textAlign: "left"
      }
    }
  ];

  return (
    <div className="spaces container-page flex-1">
      {!shouldOpenFormDialog && (
        <>
          <Row className="spaces mb-12 flex-middle">
            <Col xs={5} xxl={7} className="spaces p-0">
              <h2 className="breadcrumb-title">{lang("DISPATCH_BOOK.LIST")}</h2>
            </Col>
            <Col xs={7} xxl={5} className="flex">
              <div className="spaces mr-10 flex-1">
                <InputSearch
                  className="border-3px"
                  value={searchObject?.keyword}
                  placeholder={lang("DISPATCH_BOOK.SEARCH_PLACEHOLDER")}
                  handleSearch={() => handleSearch({ pageIndex: 1 })}
                  handleChange={handleChange}
                  isEnter
                />
              </div>
              <button className="spaces button-primary mr-10" onClick={() => handleSearch({ pageIndex: 1 })}>
                {lang("GENERAL.SEARCH")}
              </button>
              <button className="spaces button-primary-outline mr-10" onClick={handleOpenAdvancedSearch}>
                {lang("BTN.ADVANCE_SEARCH")}
              </button>

              {isAdvancedSearch && (
                <AdvancedSearch
                  open={isAdvancedSearch}
                  listInputSearch={listInputSearch}
                  handleSearch={handleAdvancedSearch}
                  listOption={ListSearch(lang)}
                  handeleClose={() => setIsAdvancedSearch(false)}
                />
              )}
            </Col>
          </Row>
          <Row className="bg-white">
            <Col xs={12} className="table-custom">
              <TableCustom
                id="dispatch-book"
                data={listData}
                columns={columns}
                searchObject={searchObject}
                setSearchObject={setSearchObject}
                buttonAdd={hasAuthority(PERMISSIONS.SU_CO, PERMISSION_ABILITY.CREATE)}
                buttonExportExcel={false}
                notDelete={true}
                buttonExportWord={true}
                handleExportWord={() => setShouldOpenExportWord(true)}
                type={TYPE.MULTILINE}
                fixedColumnsCount={3}
                totalPages={totalPage}
                totalElements={totalElements}
                numberOfElements={numberOfElements}
                handleOpenDialog={handleOpenDialog}
                handleDoubleClick={handleOpenInfoDialog}
                handleDelete={handleDelete}
              />
            </Col>
          </Row>
        </>
      )}
      {shouldOpenFormDialog && (
        <DispatchBookForm
          isView={isView}
          setIsView={setIsView}
          dataEdit={rowInfo}
          handleCloseDialog={handleCloseDialog}
          handleCloseAndSearch={handleCloseAndSearch}
          handleCloseUpdateDialog={handleCloseUpdateDialog}
          handleOpenUpdateDialog={handleOpenUpdateDialog}
        />
      )}
      {shouldOpenExportWord && (
        <ExportWord open={shouldOpenExportWord} handleClose={() => setShouldOpenExportWord(false)} />
      )}
    </div>
  );
};

export default DispatchBook;
