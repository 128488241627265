import { FC, useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { EXPORT_FILE_NAME, PERMISSIONS, PERMISSION_ABILITY, ROLE } from "../../../../Constant";
import useMultiLanguage from "../../../../hook/useMultiLanguage";
import InputSearch from "../../../component/input-field/InputSearch";
import TableCustom from "../../../component/table-custom/TableCustom";
import AdvancedSearch from "../../../profile/component/Dialog/AdvancedSearch";
import { MISSION_STATUS, RESPONSE_STATUS_CODE, TYPE } from "../../../utils/Constant";
import { checkStatus, convertSearch, formatDateTable, hasAuthority, hasRole, searchDataRoleUser } from "../../../utils/FunctionUtils";
import { FIELD_SEARCH_INTERRUPT, LIST_TEMPLATE_INTERRUPTS } from "../../const/InterruptConst";
import { INIT_INPUT_SEARCH } from "../../const/ResignConst";
import { InterruptInfo } from "../../models/IInterrupt";
import { IItemSearch } from "../../models/IResign";
import { deleteInterrupt, getInterruptFieldsExport, getListInterrupt } from "../../services/InterruptServices";
import { Col, Row } from "react-bootstrap";
import { ExportExcelDialog } from "../../../component/export-excel/ExportExcelDialog";
import { MAX_EXPORT_FIELD_LENGTH } from "../../../profile/const/DialogConstant";
import { exportGianDoan } from "../../../utils/ExportFileServices";
import { INIT_SEARCH_OBJECT } from "../../../constant";
import AppContext from "../../../../AppContext";

interface IProps {
  handleOpenDialog: (row: InterruptInfo) => void;
  handleOpenAddNewDialog: () => void;
  listInterrupt: InterruptInfo[];
  activeTab: string;
  setListInterrupt: React.Dispatch<React.SetStateAction<InterruptInfo[]>>;
  initSearchObject?: Object;
  setInitSearchObject?: React.Dispatch<React.SetStateAction<any>>;
}

const InterruptInformation: FC<IProps> = ({ handleOpenDialog, handleOpenAddNewDialog, listInterrupt, activeTab, setListInterrupt, initSearchObject, setInitSearchObject }) => {
  const { lang } = useMultiLanguage();
  const { setPageLoading } = useContext(AppContext);
  
  document.title = `${lang("GENERAL.APP.NAME")} | ${lang("GENERAL.INTERRUPT")}`;
  const [totalPage, setTotalPage] = useState<number>(0);
  const [totalElements, setTotalElements] = useState<number>(0);
  const [numberOfElements, setNumberOfElements] = useState<number>(0);
  const [searchObject, setSearchObject] = useState<any>(initSearchObject || INIT_SEARCH_OBJECT)
  const [isAdvancedSearch, setIsAdvancedSearch] = useState<boolean>(false);
  const [listInputSearch, setListInputSearch] = useState<any[]>([INIT_INPUT_SEARCH])
  const [shouldOpenExportDialog, setShouldOpenExportDialog] = useState<boolean>(false);
  const [idsSelected, setIdsSelected] = useState<string[]>([]);
  const [dataChecked, setDataChecked] = useState<InterruptInfo[]>([]);

  useEffect(() => {
    handleSearch();
  }, [searchObject?.pageIndex, searchObject?.pageSize])
  
  useEffect(() => {
    if (!dataChecked?.length) return;
    let ids = dataChecked.map((item: InterruptInfo) => item?.id || "");
    setIdsSelected(ids);
  }, [dataChecked])
  
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchObject({
      ...searchObject,
      keyword: e.target?.value
    })
  }

  const handleSearch = (data: any = {}) => {
    const dataSearch: any = {
      ...searchObject,
      ...data
    }

    dataSearch.keyword = dataSearch?.keyword?.trim() || ""
    setInitSearchObject && setInitSearchObject(dataSearch)
    updatePageData(dataSearch);
  }

  const handleAdvancedSearch = (data: IItemSearch[]) => {
    setListInputSearch(data)
    handleSearch({ ...convertSearch(data), pageIndex: 1 })
  }

  const handleOpenAdvancedSearch = () => {
    setIsAdvancedSearch(true)
  }

  const updatePageData = async (searchObject?: any) => {
    try {
      setPageLoading(true);
      setSearchObject({ ...searchObject })
      const { data } = await getListInterrupt(hasRole(ROLE.USER) ? searchDataRoleUser(searchObject) : {...searchObject});
      setListInterrupt(data?.data?.content || []);
      setTotalPage(data?.data?.totalPages || 0);
      setTotalElements(data?.data?.totalElements);
      setNumberOfElements(data?.data?.numberOfElements);
      if (data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
        setIsAdvancedSearch(false)
      }
    } catch (err: any) {
      toast.error(lang("GENERAL.ERROR"));
    } finally {
      setPageLoading(false);
    }
  };

  const handleDelete = async (ids: string[]) => {
    try {
      const { data } = await deleteInterrupt(ids);
      if (data.code === RESPONSE_STATUS_CODE.SUCCESS) {
        toast.success(lang("TOAST.DELETE.SUCCESS"));
        await updatePageData(searchObject);
        return true;
      }
    } catch (error) {
      toast.error(lang("GENERAL.ERROR"));
    }
  }

  const handleOpenExportProfile = () => {
    setShouldOpenExportDialog(true);
  }

  const columns = [
    {
      name: "STT",
      field: "",
      render: (row: any, index: number, stt: number) => <span>{stt}</span>
    },
    {
      name: lang('RESIGN.STATUS'),
      field: "trangThai",
      headerStyle: {
        minWidth: "120px"
      },
      cellStyle: {
        textAlign: "center",
      },
      render: (row: any) => (
        <span className={`${checkStatus(MISSION_STATUS, row?.trangThai?.code)} status`}>
          {row?.trangThai?.name}
        </span>
      )
    },
    {
      name: lang('RESIGN.EMPLOYEECODE'),
      field: "maNhanVien",
      headerStyle: {
        minWidth: "120px"
      },
      cellStyle: {
        textAlign: "center",
      },
      render: (row: any) => <span className="color-steel-blue fw-600">{row?.maNhanVien}</span>
    },
    {
      name: lang('GENERAL.FULLNAME'),
      field: "name",
      headerStyle: {
        minWidth: "200px"
      },
      cellStyle: {
        textAlign: "left",
      },
    },
    {
      name: lang('INPUT.SALARY.DEPARTMENT'),
      field: "phongBanText",
      headerStyle: {
        minWidth: "250px"
      },
      cellStyle: {
        textAlign: "left",
      },
    },
    {
      name: lang('GENERAL.EMPLOYEE.POSITION'),
      field: "chucVuText",
      headerStyle: {
        minWidth: "250px"
      },
      cellStyle: {
        textAlign: "left",
      },
    },
    {
      name: lang('GENERAL.EMPLOYEE.JOB_POSITION'),
      field: "viTriCongViecText",
      headerStyle: {
        minWidth: "270px"
      },
      cellStyle: {
        textAlign: "left",
      },
    },
    {
      name: lang('RESIGN.TYPE_BREAK'),
      field: "loaiNghi",
      headerStyle: {
        minWidth: "150px"
      },
      cellStyle: {
        textAlign: "left",
      },
      render: (row: any) => row?.loaiNghi?.name
    },
    {
      name: lang('TIME_KEEPING.START_DATE'),
      field: "ngayBatDau",
      headerStyle: {
        minWidth: "120px"
      },
      cellStyle: {
        textAlign: "center",
      },
      render: (row: any) => <span>{formatDateTable(row?.ngayBatDau)}</span>
    },
    {
      name: lang('TIME_KEEPING.RETURN_DATE_SHORTHAND'),
      field: "ngayQuayLaiLV",
      headerStyle: {
        minWidth: "120px"
      },
      cellStyle: {
        textAlign: "center",
      },
      render: (row: any) => <span>{formatDateTable(row?.ngayQuayLaiLV)}</span>
    },
    {
      name: lang('GENERAL.EMPLOYEE.RESIGN_REASON'),
      field: "lyDoNghi",
      headerStyle: {
        minWidth: "200px"
      },
      cellStyle: {
        textAlign: "enter",
      },
    },
    {
      name: lang('INPUT.WORKPROCESS.DECISION.NUMBER'),
      field: "soQuyetDinh",
      headerStyle: {
        minWidth: "150px"
      },
      cellStyle: {
        textAlign: "enter",
      },
    }
  ]

  return (
    <div className="resign spaces px-10 py-16">
      <Row className="spaces mb-8 flex-middle ml-10">
        <Col xs={12} md={4} xl={4} xxl={6} className="spaces p-0">
          <h2 className="breadcrumb-title"> Danh sách nghỉ gián đoạn</h2>
        </Col>
        <Col xs={12} md={8} xl={8} xxl={6} className="flex">
          <div className="spaces mr-10 flex-1">
            <InputSearch
              className='border-3px'
              value={searchObject?.keyword}
              placeholder="Tìm kiếm theo mã NV, họ và tên, phòng ban, chức danh và số quyết định"
              isEnter={true}
              handleSearch={() => handleSearch({ pageIndex: 1 })}
              handleChange={handleChange} />
          </div>
          <button className="spaces button-primary mr-10" onClick={() => handleSearch({ pageIndex: 1 })}>Tìm kiếm</button>
          <button className="spaces button-primary-outline mr-10" onClick={handleOpenAdvancedSearch}>Tìm nâng cao</button>

          {isAdvancedSearch &&
            <AdvancedSearch
              open={isAdvancedSearch}
              listInputSearch={listInputSearch}
              handleSearch={handleAdvancedSearch}
              listOption={FIELD_SEARCH_INTERRUPT}
              handeleClose={() => setIsAdvancedSearch(false)}
            />
          }
        </Col>
      </Row>
      <div className="spaces flex-1 pb-18 table-custom">
        <TableCustom
          id="listInterrupt"
          data={listInterrupt}
          columns={columns}
          dependencies={activeTab}
          searchObject={searchObject}
          setSearchObject={setSearchObject}
          buttonAdd={hasAuthority(PERMISSIONS.NGHI_VIEC, PERMISSION_ABILITY.CREATE)}
          buttonExportExcel={hasAuthority(PERMISSIONS.NGHI_VIEC, PERMISSION_ABILITY.VIEW) && !hasRole(ROLE.USER)}
          notDelete={!hasAuthority(PERMISSIONS.NGHI_VIEC, PERMISSION_ABILITY.DELETE)}
          handleExportExcel={handleOpenExportProfile}
          type={TYPE.MULTILINE}
          fixedColumnsCount={4}
          totalPages={totalPage}
          totalElements={totalElements}
          numberOfElements={numberOfElements}
          setDataChecked={setDataChecked}
          handleOpenDialog={handleOpenAddNewDialog}
          handleDoubleClick={(row: any) => handleOpenDialog(row)}
          handleDelete={(ids: string[]) => handleDelete(ids)}
          deleteConditional={[{
            keyPath: "trangThai.code",
            value: 1
          }]}
        />
      </div>
      {shouldOpenExportDialog && (
        <ExportExcelDialog
          handleClose={() => setShouldOpenExportDialog(false)}
          ids={idsSelected}
          showRequestDate
          listTemplates={LIST_TEMPLATE_INTERRUPTS}
          exportAPI={exportGianDoan}
          getFieldsAPI={getInterruptFieldsExport}
          maxExportFields={MAX_EXPORT_FIELD_LENGTH}
          fileName={EXPORT_FILE_NAME.HO_SO}
        />
      )}
    </div>
  );
}

export default InterruptInformation;