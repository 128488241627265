/* eslint-disable react-hooks/exhaustive-deps */
import { AxiosResponse } from "axios";
import { useFormik } from "formik";
import { useContext, useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { toast } from "react-toastify";
import * as Yup from "yup";
import AppContext from "../../../../AppContext";
import useMultiLanguage from "../../../../hook/useMultiLanguage";
import { FILE_TYPE } from "../../../component/FileUpload/constant";
import Autocomplete from "../../../component/input-field/Autocomplete";
import TextValidator from "../../../component/input-field/TextValidator";
import TableCustom from "../../../component/table-custom/TableCustom";
import { TYPE_CATEGORY } from "../../../constant";
import { AddNewContractAnnex } from "../../../contract/components/AddNewContractAnnex";
import {
  CODE_HOP_DONG,
  INIT_CONTACT_ANNEX_INFO,
  codeStatusContract,
  contractStatus,
  contractTypeHopDong,
  contractWordExportList,
  signinStatus,
  workShifts
} from "../../../contract/const/ContractConst";
import { deleteMultipleContractAnnex, getListAnnexByContract } from "../../../contract/services/annexServices";
import { addNewContract, updateContract } from "../../../contract/services/contractServices";
import { IContractAnnexInfo, IContractInfo } from "../../../contract/services/models/IContract";
import { convertDataContactDto, switchContractStatus } from "../../../contract/utils/FunctionUtils";
import { APIResponse, IOrganization, ISimpleValue } from "../../../models/models";
import { getAllSimpleValue, searchAllSimpleValue } from "../../../services";
import "../../../styles/index.scss";
import { searchListWorkUnit } from "../../../utils/CategoryServices";
import { DATA_WORD_TYPES, RESPONSE_STATUS_CODE, SEARCH_OBJECT_MAX_SIZE, TYPE, VARIABLE_STRING } from "../../../utils/Constant";
import { addMoreDay, addMoreYear, convertNumberPrice, convertTextPrice, exportToFile, handleBlurDate, matchTemplateWord } from "../../../utils/FunctionUtils";
import { checkInvalidDate } from "../../../utils/ValidationSchema";
import { searchPhongBan_DonVi } from "../../services/DialogServices";
import { filterObject } from "../../utils/FunctionUtils";
import { ContractAnnexColumn } from "../Column/ContractAnnexColumn";
import { useProfileContext } from "../../ProfileContext";
import UploadFile from "../../../component/FileUpload/UploadFile";
import { IFile } from "../../models/DialogModels";
import { OFFICER_CODE } from "../../const/DialogChildConstants";
import { TYPE_POSITION } from "../../const/ProfileConst";
import { createHopDongTaiKy } from "../../../warning/services/WarningServices";
import { exportHopDongPhuLuc } from "../../../utils/ExportFileServices";
import { EXPORT_FILE_NAME } from "../../../../Constant";
import TrainingContract from "../Partials/TrainingContract/TrainingContract";
import TextArea from "../../../component/input-field/TextArea";
import { ExportWord, ITemplateExportWord } from "../../../component/exportWord";

interface Props {
  shouldOpenContractDialog: boolean;
  handleCloseContractDialog: () => void;
  isView: boolean;
  contractEdit: IContractInfo;
  fetchContractParent: any;
  employeeInfo: any;
  isReSignContract?: boolean;
  warningInfo?: any;
  listNguoiDaiDien?: any[];
}
export const ContractDialog = (props: Props) => {
  const { lang, intl } = useMultiLanguage();
  const { setPageLoading } = useContext(AppContext);

  const {
    shouldOpenContractDialog,
    handleCloseContractDialog,
    contractEdit,
    isView,
    fetchContractParent,
    employeeInfo,
    isReSignContract,
    warningInfo,
    listNguoiDaiDien
  } = props;
  const { isResign } = useProfileContext();

  const [isHopDongDaiHan, setIsHopDongDaiHan] = useState(false);
  const [shouldOpenAddContractAnnex, setShouldOpenAddContractAnnex] = useState<boolean>(false);
  const [annexInfo, setAnnexInfo] = useState<IContractAnnexInfo>(INIT_CONTACT_ANNEX_INFO);
  const [contractAnnexList, setContractAnnexList] = useState<IContractAnnexInfo[]>([]);
  const [isEdit, setIsEdit] = useState<boolean>(!isView);
  const [isCollaborators, setIsCollaborators] = useState<boolean>(false);
  const [isTrainingContract, setIsTrainingContract] = useState<boolean>(false);
  const [workTimesList, setWorkTimesList] = useState<ISimpleValue[]>([]);
  const [dayPaySalaryList, setDayPaySalaryList] = useState<ISimpleValue[]>([]);
  const [departments, setDepartments] = useState<IOrganization[]>([]);
  const [isShowDialogExportWord, setIsShowDialogExportWord] = useState<boolean>(false);
  const [templateWord, setTemplateWord] = useState<ITemplateExportWord[] | null>(null);

  useEffect(() => {
    getWorkTimes();
    getDayPaySalary();
  }, [])

  const getWorkTimes = async () => {
    try {
      setPageLoading(true);
      const { data } = await searchAllSimpleValue({ ...SEARCH_OBJECT_MAX_SIZE, type: TYPE_CATEGORY.thoiGianLamViec });
      if (data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
        setWorkTimesList(data?.data?.content || []);
      }
    } catch (error) {
      toast.error(lang("GENERAL.ERROR"));
    } finally {
      setPageLoading(false);
    }
  }

  const getDayPaySalary = async () => {
    try {
      setPageLoading(true);
      const { data } = await searchAllSimpleValue({ ...SEARCH_OBJECT_MAX_SIZE, type: TYPE_CATEGORY.ngayTraLuongThang });
      if (data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
        setDayPaySalaryList(data?.data?.content || []);
      }
    } catch (error) {
      toast.error(lang("GENERAL.ERROR"));
    } finally {
      setPageLoading(false);
    }
  }

  const validationSchema: any = Yup.object().shape({
    donViKyHopDong: Yup.object().required(lang("VALIDATION.REQUIRE")).nullable(),
    soHopDong: Yup.string().required(lang("VALIDATION.REQUIRE")).nullable(),
    phongBan: Yup.object().required(lang("VALIDATION.REQUIRE")).nullable(),
    chucVu: Yup.object().required(lang("VALIDATION.REQUIRE")).nullable(),
    viTriCongViec: Yup.object().required(lang("VALIDATION.REQUIRE")).nullable(),
    luongCoBan: Yup.string().required(lang("VALIDATION.REQUIRE")).nullable(),
    loaiHopDong: Yup.object()
      .required(lang("VALIDATION.REQUIRE"))
      .nullable(),
    ngayCoHieuLuc: Yup.date()
      .concat(checkInvalidDate(intl))
      .required(lang("VALIDATION.REQUIRE"))
      .when("ngayHetHan", {
        is: (ngayHetHan: Date | null) => ngayHetHan,
        then: Yup.date()
          .max(
            Yup.ref("ngayHetHan"),
            lang("VALIDATION.MAXDATE") +
            lang("CONTRACT.EXPIRATIONDATE")
          )
          .nullable()
      })
      .nullable(),
    ngayHetHan: Yup.date()
      .concat(checkInvalidDate(intl))
      .when(VARIABLE_STRING.LOAI_HOP_DONG, {
        is: (loaiHopDong: any) =>
          loaiHopDong?.code === CODE_HOP_DONG.VIEN_CHUC_DAI_HAN || loaiHopDong?.code === CODE_HOP_DONG.HOP_DONG_DAI_HAN,
        then: Yup.date().nullable(),
        otherwise: Yup.date()
          .required(lang("VALIDATION.REQUIRE"))
          .concat(checkInvalidDate(intl))
          .min(Yup.ref(VARIABLE_STRING.NGAY_CO_HIEU_LUC), lang("VALIDATE.EXPIRATION_DATE_AFTER_EFFECTIVE_DATE"))
          .nullable()
      }),
    ngayKyHopDong: Yup.date()
      .concat(checkInvalidDate(intl))
      .when("ngayHetHan", {
        is: (ngayHetHan: Date | null) => ngayHetHan && ngayHetHan < (new Date()),
        then: Yup.date()
          .max(
            Yup.ref("ngayHetHan"),
            lang("VALIDATION.SIGN_DAY.EQUAL_OF_LESS")
          )
          .nullable()
      })
      .max(new Date(), lang("VALIDATION.INVALID_SIGN_DATE"))
      .nullable(),
    trangThaiHopDong: Yup.object().required(lang("VALIDATION.REQUIRE")).nullable(),
    nguoiDaiDienCtyKy: Yup.object().required(lang("VALIDATION.REQUIRE")).nullable(),
    diaDiemLamViec: Yup.object().required(lang("VALIDATION.REQUIRE")).nullable(),
    moTaCongViec: Yup.string().required(lang("VALIDATION.REQUIRE")).nullable()
  });

  useEffect(() => {
    formik.setValues({
      ...contractEdit,
      employee: employeeInfo || null,
      ...(!contractEdit?.id && {
        donViKyHopDong: null,
        soHopDong: "",
        tenHopDong: "",
        ngayKyHopDong: "",
        trangThaiHopDong: null,
      })
    });
  }, [contractEdit, employeeInfo]);

  const handleSubmit = async (values: IContractInfo) => {
    try {
      setPageLoading(true);
      const dataContract = filterObject(convertDataContactDto(values));
      let res;
      if (isReSignContract) {
        res = await createHopDongTaiKy(dataContract);
      } else {
        res = contractEdit.id
          ? await updateContract(contractEdit.id, dataContract)
          : await addNewContract(dataContract);
      }

      if (res?.data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
        if (isReSignContract) {
          toast.success(lang("TOAST.ADD.RESIGN_CONTRACT"));
        } else {
          toast.success(contractEdit.id
            ? lang("TOAST.EDIT.CONTRACT.SUCCESS")
            : lang("TOAST.ADD.CONTRACT.SUCCESS"));
        }
        handleCloseContractDialog();
        fetchContractParent();
      } else {
        toast.error(res?.data?.message);
      }
    } catch (error) {
      toast.error(lang("GENERAL.ERROR"));
    } finally {
      setPageLoading(false);
    }
  };

  const defaultValues = () => {
    let nguoikyDefault = (listNguoiDaiDien || [])?.find((item: any) => item?.isDefault);
    return {
      ...contractEdit,
      employee: employeeInfo || null,
      ...(!contractEdit?.id && {
        donViKyHopDong: null,
        soHopDong: "",
        loaiHopDong: null,
        ngayCoHieuLuc: isReSignContract ? addMoreDay(warningInfo?.ngayHetHan, 1) : "",
        ngayHetHan: "",
        bacLuongOption: null,
        heSoLuong: "",
        trangThaiKy: null,
        trangThaiHopDong: null,
        nguoiDaiDienCtyKy: nguoikyDefault,
        nguoiDaiDienCtyChucDanhId: nguoikyDefault?.chucVuId,
        nguoiDaiDienCtyChucDanhText: nguoikyDefault?.chucDanh,
        nguoiDaiDienCtyKyId: nguoikyDefault?.id,
        nguoiDaiDienCtyKyText: nguoikyDefault?.name,
        luongCoBan: "",
        diaDiemLamViec: null,
        moTaCongViec: ""
      })
    }
  }

  const formik = useFormik({
    initialValues: defaultValues(),
    validationSchema,
    onSubmit: handleSubmit
  });

  useEffect(() => {
    if (formik.values?.employee?.donViCongTacId) {
      getDepartments();
    }
  }, [formik.values?.employee?.donViCongTacId])

  const getDepartments = async () => {
    try {
      setPageLoading(true);
      const { data } = await searchPhongBan_DonVi({ id: formik.values?.employee?.donViCongTacId });
      if (data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
        setDepartments(data?.data || []);
      }
    } catch (error) {
      toast.error(lang("GENERAL.ERROR"));
    } finally {
      setPageLoading(false);
    }
  }

  useEffect(() => {
    let departmentType = departments.find((phongBan) => formik.values.phongBan?.id === phongBan.id)?.type;
    if (departmentType) {
      let workTimeFinded = workTimesList.find((item) => Number(item.mappingName) === departmentType);
      let dayPayFinded = dayPaySalaryList.find((item) => Number(item.mappingName) === departmentType);
      formik.setValues({
        ...formik.values,
        thoiGianLamViecText: workTimeFinded?.value || "",
        thoiGianLamViecId: workTimeFinded?.id || "",
        ngayTraLuongThangText: dayPayFinded?.value || "",
        ngayTraLuongThangId: dayPayFinded?.id || ""
      })
    }
  }, [formik.values.phongBan?.id, departments, workTimesList, dayPaySalaryList])

  useEffect(() => {
    if (!contractEdit?.id) return;
    formik.setFieldValue(VARIABLE_STRING.LOAI_HOP_DONG, contractEdit?.loaiHopDong);
    const checkHopDongDaiHan = Object.values(CODE_HOP_DONG).some(value => value === Number(contractEdit?.loaiHopDong?.code))

    setIsHopDongDaiHan(checkHopDongDaiHan)
    setTemplateWord(matchTemplateWord(contractWordExportList, Number(contractEdit?.viTriCongViecType || 1)));
    updateDataContractAnnex();
  }, [contractEdit]);

  useEffect(() => {
    let officerType = employeeInfo?.loaiCanBo;
    const conditionalCodes = [OFFICER_CODE.CONG_TAC_VIEN];
    if (officerType?.code) {
      let isCooperator = conditionalCodes.includes(Number(officerType?.code));
      setIsCollaborators(isCooperator);
      if (isCooperator) {
        if (!employeeInfo?.chucVu) {
          const getPosition = async () => {
            const { data } = await getAllSimpleValue(TYPE_CATEGORY.chucDanh);
            const positionCollaborator = data.data?.content?.find((position: any) => position?.mappingName === TYPE_POSITION.CONG_TAC_VIEN);
            formik.setFieldValue("chucVu", positionCollaborator);
          }
          getPosition();
        }
      }
    }
  }, [employeeInfo?.loaiCanBo?.code])

  const hanldeChangeCheckBox = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;

    switch (name) {
      case VARIABLE_STRING.VIEN_CHUC:
        formik.setFieldValue(name, checked);
        formik.setFieldValue(VARIABLE_STRING.HOP_DONG_LAO_DONG, false);
        break;
      case VARIABLE_STRING.HOP_DONG_LAO_DONG:
        formik.setFieldValue(name, checked);
        formik.setFieldValue(VARIABLE_STRING.VIEN_CHUC, false);
        break;
      default:
        formik.setFieldValue(name, checked);
        break;
    }
  };

  const handleChangeSelect = (name: string, value: any) => {
    switch (name) {
      case VARIABLE_STRING.LOAI_HOP_DONG:
        const { VIEN_CHUC_DAI_HAN, HOP_DONG_DAI_HAN, ONE_YEAR } = CODE_HOP_DONG;
        switch (value?.code) {
          case VIEN_CHUC_DAI_HAN:
          case HOP_DONG_DAI_HAN:
            formik.setValues({
              ...formik.values,
              [name]: value,
              ngayHetHan: ""
            });
            setIsHopDongDaiHan(true);
            break;
          case ONE_YEAR:
            setIsHopDongDaiHan(true);
            formik.setValues({
              ...formik.values,
              [name]: value,
              ngayHetHan: addMoreYear(formik.values?.ngayCoHieuLuc, 1)
            });
            break;
          default:
            formik.setFieldValue(VARIABLE_STRING.LOAI_HOP_DONG, value);
            setIsHopDongDaiHan(false);
            break;
        }
        break;
      case VARIABLE_STRING.NGUOI_DAI_DIEN_KY:
        formik.setValues({
          ...formik.values,
          nguoiDaiDienCtyChucDanhId: value?.chucVuId,
          nguoiDaiDienCtyChucDanhText: value?.chucDanh,
          nguoiDaiDienCtyKyId: value?.id,
          nguoiDaiDienCtyKyText: value?.name
        });
        break;
      case VARIABLE_STRING.CHUC_DANH:
        formik.setFieldValue(name, value);
        formik.setFieldValue(VARIABLE_STRING.BAC_LUONG_OPTION, null);
        formik.setFieldValue(VARIABLE_STRING.HE_SO_LUONG, "");
        break;
      case VARIABLE_STRING.BAC_LUONG_OPTION:
        formik.setFieldValue(name, value);
        formik.setFieldTouched(name, false, false);
        formik.setFieldValue(VARIABLE_STRING.HE_SO_LUONG, value?.heSoLuong || "");
        break;
      default:
        formik.setFieldValue(name, value);
        break;
    }
    formik.setFieldValue(name, value);
  };

  const handleChangeNgayCoHieuLuc = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    formik.setValues({
      ...formik.values,
      [name]: value,
      ngayHetHan:
        formik.values?.loaiHopDong?.code === CODE_HOP_DONG.ONE_YEAR ? addMoreYear(value, 1) : formik.values?.ngayHetHan
    });
  };

  const updateDataContractAnnex = async () => {
    let contractId = contractEdit?.id;
    if (!contractId) return;
    try {
      let { data } = await getListAnnexByContract(contractId);
      if (data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
        setContractAnnexList(data?.data);
      }
    } catch (error) {
      console.error(error);
      toast.error(lang("GENERAL.ERROR"));
    }
  };

  const handleOpenDialog = (
    row: any,
    setShouldOpenDialog: (value: React.SetStateAction<boolean>) => void,
    setData: (value: any) => void
  ): void => {
    setShouldOpenDialog(true);
    setData(row);
  };

  const handleCloseDialog = (
    initData: any,
    setShouldOpenDialog: (value: React.SetStateAction<boolean>) => void,
    setData: (value: any) => void
  ): void => {
    setShouldOpenDialog(false);
    setData(initData);
  };

  const handleDeleteItems = async (
    handleDelete: (...params: any) => Promise<AxiosResponse<APIResponse>>,
    handleUpdate: () => Promise<void>,
    ids: string
  ) => {
    try {
      const res = await handleDelete?.(ids);
      if (res?.data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
        toast.success(lang("GENERAL.DELETE_SUCCESS"));
        updateDataContractAnnex();
        return true;
      } else toast.error(`${res?.data?.message}`);
    } catch (err) {
      toast.error(lang("GENERAL.ERROR"));
    }
    await handleUpdate?.();
  };

  useEffect(() => {
    switchContractStatus(employeeInfo?.loaiCanBo, formik);
  }, [employeeInfo]);

  const handleChangeMoney = (event: React.ChangeEvent<any>) => {
    const { name, value } = event.target;
    formik.setFieldValue(name, Number(convertTextPrice(value)));
  };

  const handleFile = (data: IFile) => {
    formik.setFieldValue("fileId", data.id);
    formik.setFieldValue("fileName", data.name);
  }

  useEffect(() => {
    setIsTrainingContract(Boolean(contractEdit?.id) && contractEdit?.trangThaiHopDong?.code === codeStatusContract.IN_EFFECT)
  }, [])

  return (
    <>
      <Modal
        show={shouldOpenContractDialog}
        size="xl"
        centered
        aria-labelledby="example-custom-modal-styling-title"
        onHide={handleCloseContractDialog}
      >
        <Form onSubmit={formik.handleSubmit}>
          <Modal.Header closeButton>
            <Modal.Title
              id="example-custom-modal-styling-title"
              className="heading-5"
            >
              {lang("INFO.CONTRACT")}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="px-8 py-2 contract-dialog-content">
            <div className="text-header py-2">{lang("CONTRACT.INFO")}</div>
            <div className="grid-cols-4">
              <div className="col-span-1">
                <Autocomplete
                  isRequired
                  lable={lang("PROFILE.NAME")}
                  options={[]}
                  name="employee"
                  value={formik.values?.employee || null}
                  isReadOnly={true}
                  touched={formik.touched?.employee}
                  errors={formik.errors?.employee}
                />
              </div>
              <div className="col-span-1">
                <TextValidator
                  lable={lang("INPUT.CODE_NEW")}
                  isRequired
                  name="maNhanVien"
                  value={formik.values?.employee?.maNhanVien || ""}
                  type="text"
                  readOnly={true}
                  onChange={formik.handleChange}
                  touched={formik.touched?.maNhanVien}
                  errors={formik.errors?.maNhanVien}
                />
              </div>
              <div className="col-span-1">
                <Autocomplete
                  isRequired
                  lable={lang("INPUT.DEPARTMENTS")}
                  isReadOnly={!isEdit}
                  options={departments}
                  value={formik.values?.phongBan || null}
                  name="phongBan"
                  onChange={(selectedOption) =>
                    handleChangeSelect("phongBan", selectedOption)
                  }
                  touched={formik.touched?.phongBan}
                  errors={formik.errors?.phongBan}
                />
              </div>
              <div className="col-span-1">
                <Autocomplete
                  isRequired
                  lable={lang("GENERAL.EMPLOYEE.POSITION")}
                  name="chucVu"
                  value={formik.values?.chucVu || null}
                  searchFunction={getAllSimpleValue}
                  searchObject={TYPE_CATEGORY.chucDanh}
                  isReadOnly={!isEdit || isCollaborators}
                  onChange={(selectedOption) =>
                    handleChangeSelect(VARIABLE_STRING.CHUC_VU, selectedOption)
                  }
                  errors={formik.errors?.chucVu}
                  touched={formik.touched?.chucVu}
                  options={[]}
                  getOptionLabel={(option) => option?.value}
                />
              </div>
              <div className="col-span-1">
                <Autocomplete
                  lable={lang("GENERAL.EMPLOYEE.JOB_POSITION")}
                  className="spaces z-index-4"
                  name="viTriCongViec"
                  isRequired
                  value={formik.values?.viTriCongViec || null}
                  searchFunction={getAllSimpleValue}
                  searchObject={TYPE_CATEGORY.viTriCongViec}
                  isReadOnly={!isEdit}
                  onChange={(selectedOption) => handleChangeSelect(VARIABLE_STRING.VI_TRI_CONG_VIEC, selectedOption)}
                  options={[]}
                  getOptionLabel={(option) => option?.value}
                  errors={formik.errors?.viTriCongViec}
                  touched={formik.touched?.viTriCongViec}
                />
              </div>
            </div>
            <div className="text-header py-2">
              {lang("CONTRACT.INFO_DETAIL")}
            </div>
            <div className="grid grid-cols-5">
              <div className="col-span-1">
                <Autocomplete
                  isRequired
                  lable={lang("CONTRACT.SIGNINGUNIT")}
                  options={[]}
                  value={formik.values?.donViKyHopDong || null}
                  name="donViKyHopDong"
                  onChange={(value) =>
                    handleChangeSelect(
                      VARIABLE_STRING.DON_VI_KY_HOP_DONG,
                      value
                    )
                  }
                  searchFunction={searchListWorkUnit}
                  searchObject={SEARCH_OBJECT_MAX_SIZE}
                  touched={formik.touched?.donViKyHopDong}
                  errors={formik.errors?.donViKyHopDong}
                  isReadOnly={!isEdit}
                />
              </div>
              <div className="col-span-1">
                <TextValidator
                  isRequired
                  lable={lang("CONTRACT.NUMBER")}
                  name="soHopDong"
                  value={formik.values?.soHopDong || ""}
                  type="text"
                  readOnly={!isEdit}
                  onChange={formik.handleChange}
                  touched={formik.touched?.soHopDong}
                  errors={formik.errors?.soHopDong}
                />
              </div>
              <div className="col-span-1">
                <TextValidator
                  lable={lang("CONTRACT.NAME")}
                  name="tenHopDong"
                  value={formik.values?.tenHopDong || ""}
                  type="text"
                  readOnly={!isEdit}
                  onChange={formik.handleChange}
                />
              </div>
              <div className="col-span-1">
                <Autocomplete
                  isRequired
                  lable={lang("GENERAL.EMPLOYEE.CONTRACT_TYPE")}
                  options={contractTypeHopDong}
                  onChange={(value) => handleChangeSelect(VARIABLE_STRING.LOAI_HOP_DONG, value)}
                  name="loaiHopDong"
                  value={formik.values?.loaiHopDong || null}
                  isReadOnly={isView}
                  touched={formik.touched?.loaiHopDong}
                  errors={formik.errors?.loaiHopDong}
                  dependencies={[formik.values?.vienChuc, formik.values?.hopDongLaoDong]}
                />
              </div>
              <div className="col-span-1">
                <Autocomplete
                  lable={lang("CONTRACT.WORK_SHIFT")}
                  options={workShifts}
                  onChange={(value) =>
                    handleChangeSelect(VARIABLE_STRING.CA_LAM_VIEC, value)
                  }
                  name="caLamViec"
                  value={formik.values?.caLamViec || null}
                  isReadOnly={!isEdit}
                />
              </div>
              <div className="col-span-1">
                <TextValidator
                  lable={lang("CONTRACT.EFFECTIVEDATE")}
                  name="ngayCoHieuLuc"
                  value={formik.values?.ngayCoHieuLuc || ""}
                  type="date"
                  isRequired
                  readOnly={!isEdit}
                  onChange={handleChangeNgayCoHieuLuc}
                  onBlur={() =>
                    handleBlurDate(
                      formik.setFieldValue,
                      formik.values?.ngayCoHieuLuc,
                      "ngayCoHieuLuc"
                    )
                  }
                  errors={formik.errors?.ngayCoHieuLuc}
                  touched={formik.touched?.ngayCoHieuLuc}
                />
              </div>
              <div className="col-span-1">
                <TextValidator
                  lable={lang("CONTRACT.EXPIRATIONDATE")}
                  name="ngayHetHan"
                  value={formik.values?.ngayHetHan || ""}
                  type="date"
                  readOnly={!isEdit || isHopDongDaiHan}
                  isRequired={!isHopDongDaiHan}
                  onChange={formik.handleChange}
                  onBlur={() =>
                    handleBlurDate(
                      formik.setFieldValue,
                      formik.values?.ngayHetHan,
                      "ngayHetHan"
                    )
                  }
                  errors={formik.errors?.ngayHetHan}
                  touched={formik.touched?.ngayHetHan}
                />
              </div>
              <div className="col-span-1">
                <TextValidator
                  lable={lang("CONTRACT.BASESALARY")}
                  name="luongCoBan"
                  isRequired={true}
                  value={convertNumberPrice(formik.values?.luongCoBan || "")}
                  type="text"
                  readOnly={!isEdit}
                  onChange={handleChangeMoney}
                  errors={formik.errors?.luongCoBan}
                  touched={formik.touched?.luongCoBan}
                />
              </div>
              <div className="col-span-1">
                <TextValidator
                  lable={lang("CONTRACT.SALARY_PERCENT")}
                  name="tyLeHuongLuong"
                  value={formik.values?.tyLeHuongLuong || ""}
                  type="number"
                  readOnly={!isEdit}
                  onChange={formik.handleChange}
                />
              </div>
              <div className="col-span-1">
                <TextValidator
                  lable={lang("CONTRACT.INSURANCECONTRIBUTION")}
                  name="luongDongBaoHiem"
                  value={convertNumberPrice(
                    formik.values?.luongDongBaoHiem || ""
                  )}
                  type="text"
                  readOnly={!isEdit}
                  onChange={handleChangeMoney}
                />
              </div>
              <div className="col-span-1">
                <Autocomplete
                  isRequired
                  lable={lang("CONTRACT.STATUS")}
                  options={contractStatus}
                  onChange={(value) =>
                    handleChangeSelect(
                      VARIABLE_STRING.TRANG_THAI_HOP_DONG,
                      value
                    )
                  }
                  name="trangThaiHopDong"
                  value={formik.values?.trangThaiHopDong || null}
                  isReadOnly={!isEdit}
                  errors={formik.errors?.trangThaiHopDong}
                  touched={formik.touched?.trangThaiHopDong}
                />
              </div>
              <div className="col-span-1">
                <TextValidator
                  lable={lang("CONTRACT.DAY_SALARY_PAY")}
                  name="ngayTraLuongThangText"
                  value={formik.values?.ngayTraLuongThangText || ""}
                  type="text"
                  readOnly={true}
                  onChange={formik.handleChange}
                />
              </div>
              <div className="col-span-1">
                <TextValidator
                  lable={lang("CONTRACT.WORK_TIME")}
                  name="thoiGianLamViecText"
                  value={formik.values?.thoiGianLamViecText || ""}
                  type="text"
                  readOnly={true}
                  onChange={formik.handleChange}
                />
              </div>
              <div className="col-span-2">
                <UploadFile
                  label="CONTRACT.ATTACHMENTS"
                  setValue={handleFile}
                  isReadOnly={!isEdit}
                  fileValue={{
                    id: formik.values.fileId || "",
                    name: formik.values.fileName || ""
                  }}
                  allowFileTypes={`${FILE_TYPE.PDF},${FILE_TYPE.DOC},${FILE_TYPE.DOCX},${FILE_TYPE.XLS},${FILE_TYPE.XLSX}`}
                />
              </div>
              <div className="col-span-3">
                <Autocomplete
                  isRequired
                  lable={lang("CONTRACT.WORK_PLACE")}
                  options={[]}
                  onChange={(value) => handleChangeSelect("diaDiemLamViec", value)}
                  name="diaDiemLamViec"
                  value={formik.values?.diaDiemLamViec}
                  isReadOnly={!isEdit}
                  searchFunction={searchAllSimpleValue}
                  searchObject={{ ...SEARCH_OBJECT_MAX_SIZE, type: TYPE_CATEGORY.diaDiemLamViec }}
                  getOptionLabel={(options) => options?.value}
                  errors={formik.errors?.diaDiemLamViec}
                  touched={formik.touched?.diaDiemLamViec}
                />
              </div>
              <div className="col-span-5">
                <TextArea
                  isRequired
                  lable={lang("CONTRACT.JOB_DESCRIPTION")}
                  name="moTaCongViec"
                  value={formik.values?.moTaCongViec || ""}
                  type="text"
                  as="textarea"
                  rows="3"
                  readOnly={!isEdit}
                  bulletPoints="+"
                  onChange={(value: any) => formik.setFieldValue("moTaCongViec", value)}
                  touched={formik.touched?.moTaCongViec}
                  errors={formik.errors?.moTaCongViec}
                />
              </div>
              <div className="col-span-5">
                <TextValidator
                  lable={lang("CONTRACT.NOTE")}
                  name="ghiChu"
                  value={formik.values?.ghiChu}
                  as={TYPE.TEXTAREA}
                  rows={3}
                  readOnly={!isEdit}
                  onChange={formik.handleChange}
                />
              </div>
            </div>
            <div className="text-header py-2">
              {lang("CONTRACT.SALARY_SIGN")}
            </div>
            <div className="grid-cols-5 pb-4">
              <div className="col-span-1">
                <Form.Check
                  label={lang("CONTRACT.PAPER_AUTHORITY")}
                  checked={formik.values?.giayUyQuyen}
                  onChange={hanldeChangeCheckBox}
                  className="custom-form-check"
                  readOnly={!isEdit}
                  name="giayUyQuyen"
                />
              </div>
              <div className="col-span-1">
                <Autocomplete
                  isRequired
                  lable={lang("CONTRACT.SIGNEDCOMPANYREPRESENTATIVE")}
                  options={listNguoiDaiDien || []}
                  value={formik.values?.nguoiDaiDienCtyKy || null}
                  name={VARIABLE_STRING.NGUOI_DAI_DIEN_KY}
                  onChange={(value) =>
                    handleChangeSelect(VARIABLE_STRING.NGUOI_DAI_DIEN_KY, value)
                  }
                  isReadOnly={!isEdit}
                  errors={formik.errors?.nguoiDaiDienCtyKy}
                  touched={formik.touched?.nguoiDaiDienCtyKy}
                />
              </div>
              <div className="col-span-1">
                <TextValidator
                  lable={lang("CONTRACT.REPRESENTATIVETITLE")}
                  name="nguoiDaiDienCtyChucDanh"
                  value={formik.values?.nguoiDaiDienCtyChucDanhText || ""}
                  type="text"
                  readOnly={true}
                />
              </div>
              <div className="col-span-1">
                <TextValidator
                  lable={lang("ANNEX.SIGNINGDATE")}
                  name="ngayKyHopDong"
                  value={formik.values?.ngayKyHopDong || ""}
                  type="date"
                  readOnly={!isEdit}
                  onBlur={() =>
                    handleBlurDate(
                      formik.setFieldValue,
                      formik.values?.ngayKyHopDong,
                      "ngayKyHopDong"
                    )
                  }
                  onChange={formik.handleChange}
                  errors={formik.errors?.ngayKyHopDong}
                  touched={formik.touched?.ngayKyHopDong}
                />
              </div>
              <div className="col-span-1">
                <Autocomplete
                  lable={lang("CONTRACT.SIGNINGSTATUS")}
                  options={signinStatus}
                  value={formik.values?.trangThaiKy || null}
                  name="trangThaiKy"
                  onChange={(value) =>
                    handleChangeSelect(VARIABLE_STRING.TRANG_THAI_KY, value)
                  }
                  menuPlacement="top"
                  isReadOnly={!isEdit}
                  errors={formik.errors?.trangThaiKy}
                  touched={formik.touched?.trangThaiKy}
                />
              </div>
            </div>
            {contractEdit?.id && (
              <>
                <div className="spaces mt-16">
                  <TrainingContract
                    isView={!isEdit}
                    employeeId={contractEdit?.employeeId}
                    hopDongLaoDongId={contractEdit.id || ""}
                    isDisplayBtnAdd={isTrainingContract}
                  />
                </div>
                <TableCustom
                  id="contractAnnex"
                  title={lang("CONTRACT.ANNEX")}
                  className="pt-4"
                  data={contractAnnexList}
                  columns={ContractAnnexColumn()}
                  handleDelete={(ids: string) =>
                    handleDeleteItems(
                      deleteMultipleContractAnnex,
                      updateDataContractAnnex,
                      ids
                    )
                  }
                  isActionTableTab
                  buttonAdd={isEdit && isTrainingContract}
                  notEdit={isEdit || !isTrainingContract}
                  buttonExportExcel={true}
                  type={TYPE.MULTILINE}
                  fixedColumnsCount={3}
                  noToolbar={true}
                  noPagination={true}
                  notDelete={true}
                  handleExportExcel={() =>
                    exportToFile({
                      exportAPI: () => exportHopDongPhuLuc(contractEdit.id),
                      fileName: EXPORT_FILE_NAME.PHU_LUC_HD,
                      setPageLoading
                    })
                  }
                  handleOpenDialog={(row) =>
                    handleOpenDialog(
                      annexInfo,
                      setShouldOpenAddContractAnnex,
                      setAnnexInfo
                    )
                  }
                  handleDoubleClick={(row: any) =>
                    handleOpenDialog(
                      row,
                      setShouldOpenAddContractAnnex,
                      setAnnexInfo
                    )
                  }
                />
                {/* Tạm ẩn phụ cấp trong hợp đồng */}
                {/* <div className="spaces mt-16">
                  <Allowance
                    isView={!isEdit}
                    isDisplayBtnAdd={
                      formik.values?.trangThaiHopDong?.code !==
                      codeStatusContract.EXPIRE
                    }
                    identify={contractEdit?.employeeId || ""}
                  />
                </div> */}
              </>
            )}
          </Modal.Body>

          <Modal.Footer className="flex-center">
            <Button
              variant="outline-secondary"
              className="button-secondary btn-sm"
              onClick={() => handleCloseContractDialog()}
            >
              {lang("BTN.CANCEL")}
            </Button>
            {!isResign &&
              (isEdit ? (
                <Button
                  variant="primary"
                  className="button-primary btn-sm"
                  type="submit"
                >
                  {lang("BTN.SAVE")}
                </Button>
              ) : (
                <Button
                  variant="primary"
                  className="button-primary btn-sm"
                  onClick={(e: any) => {
                    e.preventDefault();
                    setIsEdit(true);
                  }}
                >
                  {lang("BTN.EDIT")}
                </Button>
              ))}

            {(contractEdit?.id && templateWord)  && (
              <button
                className="button-primary"
                onClick={() => setIsShowDialogExportWord(true)}
                type="button"
              >
                {lang("GENERAL.EXPORT_WORD")}
              </button>
            )}
          </Modal.Footer>
        </Form>
      </Modal>

      {shouldOpenAddContractAnnex && (
        <AddNewContractAnnex
          isView={!isEdit}
          annexInfo={annexInfo}
          contractInfo={contractEdit}
          getListAnnex={updateDataContractAnnex}
          handleCLoseAddContractAnnex={() =>
            handleCloseDialog(
              INIT_CONTACT_ANNEX_INFO,
              setShouldOpenAddContractAnnex,
              setAnnexInfo
            )
          }
          isCollaborators={isCollaborators}
        />
      )}

      {isShowDialogExportWord && (
         <ExportWord
         open={isShowDialogExportWord}
         handleClose={() => setIsShowDialogExportWord(false)}
         templateList={templateWord || []}
         getObj={{
           employeeId: contractEdit?.employeeId,
           hopDongId: contractEdit?.id,
           hopDongType: contractEdit?.viTriCongViecType,
           type: DATA_WORD_TYPES.FACTORY_CONTRACT
         }}
         customFileName={(data, nameBase) => `${data.fullName} (${nameBase})`}
       />
      )}

    </>
  );
};
