import { formatDateTable } from "../../../utils/FunctionUtils";
import CustomTooltip from "../../../component/CustomTooltip";

export const canhBaoHetHanHopDongColumn = (
  handleReSignContract: (row: any) => void,
  handleEndContract: (row: any) => void
) => {
  return [
    {
      name: "Trạng thái",
      field: "",
      headerCellProps: {
        minWidth: 120
      },
      render: (row: any) => (
        <span className={`${row?.trangThaiHetHanHd?.bgColor} warning-status`}>{row?.trangThaiHetHanHd?.name}</span>
      )
    },
    {
      name: "Thao tác",
      field: "custom",
      headerCellProps: {
        minWidth: 80
      },
      render: (row: any) => (
        <div className="flex flex-middle flex-center gap-5">
          {!row?.isKetThucHD && (
            <CustomTooltip
              title={"Tái ký HĐ"}
              placement="right"
            >
              <span
                onClick={(e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
                  e.stopPropagation();
                  handleReSignContract && handleReSignContract(row);
                }}
              >
                <i className="bi bi-file-earmark-plus text-primary fs-2"></i>
              </span>
            </CustomTooltip>
          )}
          <CustomTooltip
            title={"Chấm dứt HĐ"}
            placement="right"
          >
            <span
              onClick={(e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
                e.stopPropagation();
                handleEndContract && handleEndContract(row);
              }}
            >
              <i className="bi bi-clipboard-x text-primary fs-2"></i>
            </span>
          </CustomTooltip>
        </div>
      )
    },
    {
      name: "Họ và tên",
      field: "employeeCode",
      headerCellProps: {
        minWidth: 200
      },
      render: (row: any) => (
        <span>{`${row?.employeeCode || ""} ${row?.employeeCode && row?.tenNguoiLaoDong ? "-" : ""} ${
          row?.tenNguoiLaoDong || ""
        }`}</span>
      )
    },
    {
      name: "Khoa/Phòng",
      field: "phongBan",
      headerCellProps: {
        minWidth: 300
      },
      render: (row: any) => (
        <span>{`${row?.donViKyHopDongText || ""} ${row?.phongBanText && row?.donViKyHopDongText ? "/" : ""} ${
          row?.phongBanText || ""
        }`}</span>
      )
    },
    {
      name: "Chức vụ",
      field: "chucVuText",
      headerCellProps: {
        minWidth: 200
      }
    },
    {
      name: "Loại hợp đồng",
      field: "loaiHopDong",
      headerCellProps: {
        minWidth: 200
      },
      render: (row: any) => <span>{row?.loaiHopDong?.name}</span>
    },
    {
      name: "Ngày hiệu lực",
      field: "ngayCoHieuLuc",
      headerCellProps: {
        minWidth: 100
      },
      bodyCellProps: {
        textAlign: "center"
      },
      render: (row: any) => <span>{formatDateTable(row?.ngayCoHieuLuc)}</span>
    },
    {
      name: "Ngày hết hạn",
      field: "ngayHetHan",
      headerCellProps: {
        minWidth: 100
      },
      bodyCellProps: {
        textAlign: "center"
      },
      render: (row: any) => <span>{formatDateTable(row?.ngayHetHan)}</span>
    }
  ];
};
