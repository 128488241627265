/* eslint-disable react-hooks/exhaustive-deps */
import { useFormik } from "formik";
import { FC, useContext, useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { PERMISSIONS, PERMISSION_ABILITY } from "../../../../Constant";
import useMultiLanguage from "../../../../hook/useMultiLanguage";
import UploadFile from "../../../component/FileUpload/UploadFile";
import { FILE_TYPE } from "../../../component/FileUpload/constant";
import { GroupButton } from "../../../component/GroupButton";
import Autocomplete from "../../../component/input-field/Autocomplete";
import TextValidator from "../../../component/input-field/TextValidator";
import TableCustom from "../../../component/table-custom/TableCustom";
import { SUCCESS_CODE } from "../../../contract/const/ContractConst";
import { searchNguoiDaiDienKy } from "../../../contract/services/contractServices";
import { IFile } from "../../../profile/models/DialogModels";
import { getEmployeeProfileById } from "../../../profile/services/ProfileServices";
import { searchAllEmployee } from "../../../utils/CategoryServices";
import { LIST_MONTH, RESPONSE_STATUS_CODE, SEARCH_OBJECT_MAX_SIZE, TYPE, VARIABLE_STRING } from "../../../utils/Constant";
import { convertNumberPrice, convertTextPrice, getFullYear, handleBlurDate, hasAuthority, numberExceptThisSymbols } from "../../../utils/FunctionUtils";
import { INIT_RECEIVABLE_INFO, INIT_RESIGN_INFO, INSURANCE_TYPE_CODE, RESIGN, listFormOfAdjustment, listResignType, listResignTypeInsurance, listStatusResign } from "../../const/ResignConst";
import { ReceivableInfo, ResignInfo } from "../../models/IResign";
import { addNewResign, editResign } from "../../services/ResignServices";
import { ReceivableDialog } from "./ReceivableDialog";
import { checkInvalidDate } from "../../../utils/ValidationSchema";
import { OptionReactSelect } from "../../../models/models";
import AppContext from "../../../../AppContext";
interface IProps {
  handleClose: () => void;
  handleOpenUpdateDialog: () => void;
  handleCloseUpdateDialog: () => void;
  resignInfo: ResignInfo;
  setResignInfo: (data: ResignInfo) => void;
  isView: boolean;
}

const ResignDialog: FC<IProps> = ({ handleClose, handleCloseUpdateDialog, handleOpenUpdateDialog, resignInfo, isView, setResignInfo }) => {
  const { lang, intl } = useMultiLanguage();
  const [listReceivables, setListReceivables] = useState<ReceivableInfo[]>([]);
  const [shouldOpenDialog, setShouldOpenDialog] = useState<boolean>(false);
  const [receivableInfo, setReceivableInfo] = useState<ReceivableInfo>({} as ReceivableInfo);
  const [isMakeDecision, setIsMakeDecision] = useState<boolean>(resignInfo.raQuyetDinh || false);
  const [isShowMakeDecisionBtn, setIsShowMakeDecisionBtn] = useState<boolean>(false);
  const [isShowBtnSave, setIsShowBtnSave] = useState<boolean>(true);
  const { setPageLoading } = useContext(AppContext);

  useEffect(() => {
    let namKhauTruOption = getFullYear().find(item => Number(item.name) === resignInfo.namKhauTru) || null;
    if (resignInfo) {
      formik.setValues({
        ...resignInfo,
        nguoiKy: resignInfo.nguoiKyId ? {
          id: resignInfo.nguoiKyId,
          name: resignInfo.nguoiKyText
        } : null,
        thangKhauTruOption: LIST_MONTH.find(item => item.code === resignInfo?.thangKhauTru) || null,
        namKhauTruOption
      });
    }
    if (resignInfo?.employeeId) {
      getEmployeeInfo(resignInfo.employeeId);
    }
  }, [resignInfo]);

  useEffect(() => {
    if (!resignInfo?.id) return;
    setListReceivables(resignInfo?.listKhoanThu || []);
  }, [resignInfo])

  useEffect(() => {
    if (!resignInfo?.id) {
      setIsShowMakeDecisionBtn(true);
    } else {
      setIsShowMakeDecisionBtn(!resignInfo?.raQuyetDinh)
    }
  }, [resignInfo])

  const getEmployeeInfo = async (id: string) => {
    try {
      const { data } = await getEmployeeProfileById(id);
      if (data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
        formik.setFieldValue(VARIABLE_STRING.EMPLOYEE, data?.data);
      }
    } catch (error) {
      console.error(error);
      toast.error(lang("GENERAL.ERROR"));
    }
  };

  const validationSchema = Yup.object().shape({
    employee: Yup.object().required(lang("VALIDATION.REQUIRE")).nullable(),
    loaiThoiViec: Yup.object().required(lang("VALIDATION.REQUIRE")).nullable(),
    trangThaiBaoHiem: Yup.object().required(lang("VALIDATION.REQUIRE")).nullable(),
    nguoiKy: Yup.object().required(lang("VALIDATION.REQUIRE")).nullable(),
    soQuyetDinh: Yup.string()
      .required(lang("VALIDATION.REQUIRE"))
      .max(255, lang("VALIDATION.MAX255"))
      .nullable(),
    ngayHieuLuc: Yup.date()
      .concat(checkInvalidDate(intl))
      .required(lang("VALIDATION.REQUIRE"))
      .nullable(),
    thoiGianDungDongBH: Yup.date()
      .concat(checkInvalidDate(intl))
      .when("trangThaiBaoHiem", {
        is: (trangThaiBaoHiem: OptionReactSelect | null) => trangThaiBaoHiem && trangThaiBaoHiem?.code === INSURANCE_TYPE_CODE.DUNG_DONG,
        then: Yup.date()
          .required(lang("VALIDATION.REQUIRE"))
          .nullable()
      })
      .nullable(),
    thoiGianThucHien: Yup.date()
      .concat(checkInvalidDate(intl))
      .required(lang("VALIDATION.REQUIRE"))
      .nullable(),
    ngayQuyetDinh: Yup.date()
      .concat(checkInvalidDate(intl))
      .concat(isMakeDecision
        ? Yup.date().required(lang("VALIDATION.REQUIRE")).nullable()
        : Yup.date().nullable()
      )
      .when("ngayHieuLuc", {
        is: (ngayHieuLuc: Date | null) => ngayHieuLuc,
        then: Yup.date()
          .max(Yup.ref("ngayHieuLuc"), lang("VALIDATION.DECISION_DATE_BEFORE_EFFECTIVE_DATE"))
          .nullable()
      })
      .nullable(),
  });

  const handleSubmit = async (values: ResignInfo) => {
    try {
      setPageLoading(true);
      const _values = convertDataSubmit(values);
      const { data } = resignInfo?.id
        ? await editResign(resignInfo?.id, _values)
        : await addNewResign(_values);
      if (data.code === SUCCESS_CODE) {
        toast.success(
          resignInfo.id
            ? lang("TOAST.EDIT.SUCCESS")
            : lang("TOAST.CREATE.SUCCESS")
        );
        if (resignInfo?.id || isMakeDecision) {
          handleClose();
        } else {
          setResignInfo(data?.data);
        }
      } else {
        toast.error(`${data.message}`);
      }
    } catch (error) {
      toast.error(lang("GENERAL.ERROR"));
    } finally {
      setPageLoading(false);
    }
  };

  const convertDataSubmit = (values: ResignInfo) => {
    for (let index = 0; index < listReceivables.length; index++) {
      delete listReceivables[index].id;
    }
    return {
      ...values,
      maNhanVien: values.employee?.maNhanVien,
      fullName: values.employee?.name,
      phongBanId: values.employee?.phongBanId,
      phongBanText: values.employee?.phongBanText,
      chucVuId: values.employee?.chucVuId,
      chucVuText: values.employee?.chucVuText,
      viTriCongViecId: values.employee?.viTriCongViecId,
      viTriCongViecText: values.employee?.viTriCongViecText,
      thangKhauTru: Number(values.thangKhauTruOption?.code),
      namKhauTru: Number(values.namKhauTruOption?.name),
      nguoiKyId: values.nguoiKy?.id,
      nguoiKyText: values.nguoiKy?.name,
      coPhieuXacNhan: values?.coPhieuXacNhan || false,
      daBoSungHoSo: values?.daBoSungHoSo || false,
      daHoanTatThuTuc: values?.daHoanTatThuTuc || false,
      listKhoanThu: listReceivables,
      raQuyetDinh: isMakeDecision
    }
  }

  const formik = useFormik({
    initialValues: INIT_RESIGN_INFO,
    validationSchema,
    onSubmit: handleSubmit
  })

  const handleChangeSelect = (name: string, value: any) => {
    if(name === VARIABLE_STRING.LOAI_THOI_VIEC) {
      formik.setValues({
        ...formik.values,
        loaiThoiViec: value,
        lyDoThoiViec: value?.name || ""
      })
    }else {
      formik.setFieldValue(name, value);
    }
  };

  const handleFile = (data: IFile) => {
    formik.setFieldValue("fileId", data.id);
    formik.setFieldValue("fileName", data.name);
  }

  const handleChangeCheckBox = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;
    formik.setFieldValue(name, checked);
  };

  const handleChangeEmployee = (employee: any) => {
    let values: ResignInfo = {
      ...formik.values,
      employee: employee,
      employeeId: employee?.id,
      donViCongTacId: employee?.donViCongTacId,
      donViCongTacText: employee?.donViCongTacText,
      donViCongTac: employee?.donViCongTacText
        ? {
          id: employee?.donViCongTacId,
          value: employee?.donViCongTacText
        }
        : null,
      chucDanh: employee?.viTriCongViecText
        ? {
          id: employee?.viTriCongViecId,
          value: employee?.viTriCongViecText
        }
        : null,
      phongBan: employee?.phongBanText
        ? {
          id: employee?.phongBanId,
          name: employee?.phongBanText
        }
        : null,
      chucVu: employee?.chucVuText
        ? {
          id: employee?.chucVuId,
          value: employee?.chucVuText
        }
        : null
    };
    formik.setValues(values);
  };

  const handleAddReceivable = (data: ReceivableInfo) => {
    let id = data?.id;
    let index = listReceivables.findIndex((item: ReceivableInfo) => (item?.id) === id);
    if (index > -1) {
      let _listReceivables = [...listReceivables];
      _listReceivables[index] = data;
      setListReceivables([..._listReceivables]);
    } else {
      setListReceivables([...listReceivables, data])
    }
    handleCloseDialog();
  }

  const handleDeleteReceivables = (ids: string[]) => {
    let _listReceivables = listReceivables.filter((item: ReceivableInfo) => !ids.includes(item?.id || ""));
    setListReceivables([..._listReceivables]);
    return true;
  }

  const handleOpenInfoDialog = (row: ReceivableInfo) => {
    setShouldOpenDialog(true);
    setReceivableInfo(row);
  };

  const handleCloseDialog = () => {
    setShouldOpenDialog(false);
    setReceivableInfo(INIT_RECEIVABLE_INFO);
  };

  const handleMakeDecision = () => {
    setIsMakeDecision(true);
  }

  const handleSave = () => {
    setIsMakeDecision(false);
  }

  const handleChangeMoney = (event: React.ChangeEvent<any>) => {
    const { name, value } = event.target;
    formik.setFieldValue(name, convertTextPrice(value));
  }

  const handleChangeDate = (event: React.ChangeEvent<any>) => {
    const { name, value } = event.target;
    formik.setFieldValue(name, value);
    if (name === VARIABLE_STRING.NGAY_HIEU_LUC) {
      const effectiveDate = value ? new Date(value) : null;
      const currentDate = new Date();
      if (effectiveDate && effectiveDate <= currentDate) {
        setIsShowBtnSave(false);
      } else setIsShowBtnSave(true);
    }
  }

  const columns = [
    {
      name: "STT",
      field: "",
      render: (row: any, index: number, stt: number) => <span>{stt}</span>
    },
    {
      name: lang('GENERAL.RECEIVABLES'),
      field: "khoanThu",
      headerStyle: {
        minWidth: "200px"
      },
      cellStyle: {
        textAlign: "center",
      }
    },
    {
      name: lang('GENERAL.MONEY'),
      field: "giaTri",
      headerStyle: {
        minWidth: "200px"
      },
      cellStyle: {
        textAlign: "center",
      },
    },
    {
      name: lang('INPUT.RETIRE_INFO.NOTE'),
      field: "ghiChu",
      headerStyle: {
        minWidth: "200px"
      },
      cellStyle: {
        textAlign: "left",
      },
    }
  ];

  return (
    <div className="resign-form">
      <Form onSubmit={formik.handleSubmit}>
        <Row className="header-box">
          <Col xs={12}>
            <div className="profile-title spaces py-6">
              <GroupButton type="btn-back" handleClose={handleClose} />
              <div className="flex gap-4">
                {isView && resignInfo?.id && resignInfo?.trangThai?.code !== RESIGN.STATUS.FINISH && !resignInfo.raQuyetDinh && (
                  <GroupButton
                    type="btn-edit"
                    handleSaveEdit={handleOpenUpdateDialog}
                  />
                )}
                {(!isView && resignInfo?.trangThai?.code !== RESIGN.STATUS.FINISH) &&
                  <>
                    {resignInfo?.id && (
                      <Button
                        variant="outline-secondary"
                        className="button-secondary btn-sm btn"
                        onClick={handleCloseUpdateDialog}
                        type="button"
                      >
                        {lang("BTN.CANCEL")}
                      </Button>
                    )}
                    {isShowBtnSave && (
                      <Button className="button-primary btn" type="submit" onClick={handleSave}>{lang("BTN.SAVE")}</Button>
                    )}
                  </>
                }
                {(isShowMakeDecisionBtn && !isView) && (
                  <button className="button-primary" onClick={handleMakeDecision}>{lang("RESIGN.MAKE_DECISION")}</button>
                )}
              </div>
            </div>
          </Col>
        </Row>
        <div className="form-container bg-white">
          <div className="sub-title-form ">{resignInfo?.id ? lang("RESIGN.EDIT") : lang("RESIGN.ADD")}</div>
          <div className="sub-title p-0">
            {lang("PROFILE.TITLE")}
          </div>
          <Row>
            <Col xs={6} md={4} lg={4} xxl={3} className="spaces pt-6">
              <Autocomplete
                isRequired
                lable={lang("GENERAL.FULLNAME")}
                options={[]}
                className="spaces z-index-4"
                onChange={(value) => handleChangeEmployee(value)}
                name="employee"
                value={formik.values?.employee || null}
                searchFunction={searchAllEmployee}
                searchObject={{ ...SEARCH_OBJECT_MAX_SIZE, isDangLamViec: true }}
                isReadOnly={isView}
                errors={formik.errors.employee}
                touched={formik.touched.employee}
                getOptionLabel={(option) => `${option?.maNhanVien} - ${option?.name}`}
              />
            </Col>
            <Col xs={6} md={4} lg={3} xxl={2} className="spaces pt-6">
              <TextValidator
                lable={lang("RESIGN.EMPLOYEECODE")}
                name="maNhanVien"
                value={formik.values?.employee?.maNhanVien || ""}
                type="text"
                readOnly={true}
              />
            </Col>
            <Col xs={6} md={4} lg={5} xxl={2} className="spaces pt-6">
              <TextValidator
                lable={lang("INPUT.WORKPROCESS.UNIT")}
                name="donViCongTac"
                value={formik.values?.employee?.donViCongTacText || ""}
                type="text"
                readOnly={true}
              />
            </Col>
            <Col xs={6} md={4} lg={4} xxl={3} className="spaces pt-6">
              <TextValidator
                lable={lang("INPUT.DEPARTMENTS")}
                name="phongBan"
                value={formik.values?.employee?.phongBanText || ""}
                type="text"
                readOnly={true}
              />
            </Col>
            <Col xs={6} md={4} lg={3} xxl={2} className="spaces pt-6">
              <TextValidator
                lable={lang("GENERAL.EMPLOYEE.POSITION")}
                name="chucVu"
                value={formik.values?.employee?.chucVuText || ""}
                type="text"
                readOnly={true}
              />
            </Col>
            <Col xs={6} md={4} lg={5} xxl={3} className="spaces pt-6">
              <TextValidator
                lable={lang("GENERAL.EMPLOYEE.JOB_POSITION")}
                name="viTriCongViec"
                value={formik.values?.employee?.viTriCongViecText || ""}
                type="text"
                readOnly={true}
              />
            </Col>
          </Row>
          <div className="sub-title">
            {lang("RESIGN.TITLE")}
          </div>
          <Row>
            <Col xs={6} md={4} lg={3} xxl={2} className="spaces pt-6">
              <Autocomplete
                isRequired
                className="spaces z-index-3"
                lable={lang("RESIGN.TYPE")}
                options={listResignType}
                onChange={(value) =>
                  handleChangeSelect(VARIABLE_STRING.LOAI_THOI_VIEC, value)
                }
                name="loaiThoiViec"
                value={formik.values?.loaiThoiViec || null}
                isReadOnly={isView}
                errors={formik.errors?.loaiThoiViec}
                touched={formik.touched?.loaiThoiViec}
              />
            </Col>
            <Col xs={6} md={4} lg={3} xxl={2} className="spaces pt-6">
              <TextValidator
                lable={lang("INPUT.RETIRE_INFO.SUBSIDIZE")}
                name="troCapThoiViec"
                value={convertNumberPrice(formik.values?.troCapThoiViec || null)}
                type="text"
                readOnly={isView}
                onChange={handleChangeMoney}
                errors={formik.errors?.troCapThoiViec}
                touched={formik.touched?.troCapThoiViec}
              />
            </Col>
            <Col xs={6} md={4} lg={3} xxl={2} className="spaces pt-6">
              <TextValidator
                lable={lang("INPUT.RETIRE_INFO.LOST_WORK")}
                name="troCapMatViec"
                value={convertNumberPrice(formik.values?.troCapMatViec || null)}
                type="text"
                readOnly={isView}
                onChange={handleChangeMoney}
                errors={formik.errors?.troCapMatViec}
                touched={formik.touched?.troCapMatViec}
              />
            </Col>
            <Col xs={6} md={4} lg={3} xxl={2} className="spaces pt-6">
              <TextValidator
                lable={lang("INPUT.RETIRE_INFO.PAYABLES")}
                name="cacKhoanTraKhac"
                value={convertNumberPrice(formik.values?.cacKhoanTraKhac || null)}
                type="text"
                onKeyDown={numberExceptThisSymbols}
                readOnly={isView}
                onChange={handleChangeMoney}
                errors={formik.errors?.cacKhoanTraKhac}
                touched={formik.touched?.cacKhoanTraKhac}
              />
            </Col>
            <Col xs={6} md={4} lg={3} xxl={2} className="spaces pt-6">
              <TextValidator
                lable={lang("INPUT.RETIRE_INFO.RECEIVABLES")}
                name="cacKhoanThuKhac"
                value={listReceivables.length}
                type="number"
                readOnly={true}
                onChange={formik.handleChange}
                errors={formik.errors?.cacKhoanThuKhac}
                touched={formik.touched?.cacKhoanThuKhac}
              />
            </Col>
            <Col xs={6} md={4} lg={3} xxl={2} className="spaces pt-6">
              <Autocomplete
                className="spaces z-index-2"
                lable={lang("RESIGN.MONTH_DEDUCTION")}
                options={LIST_MONTH}
                onChange={(value) =>
                  handleChangeSelect(VARIABLE_STRING.THANG_KHAU_TRU_OPTION, value)
                }
                name="thangKhauTruOption"
                value={formik.values?.thangKhauTruOption || null}
                isReadOnly={isView}
              />
            </Col>
            <Col xs={6} md={4} lg={3} xxl={2} className="spaces pt-6 z-index-2">
              <Autocomplete
                className="spaces z-index-1"
                lable={lang("RESIGN.YEAR_DEDUCTION")}
                options={getFullYear()}
                onChange={(value) =>
                  handleChangeSelect(VARIABLE_STRING.NAM_KHAU_TRU_OPTION, value)
                }
                name="namKhauTruOption"
                value={formik.values?.namKhauTruOption || null}
                isReadOnly={isView}
              />
            </Col>
            <Col xs={6} md={4} lg={3} xxl={2} className="spaces pt-6">
              <TextValidator
                isRequired={isMakeDecision}
                lable={lang("INPUT.WORKPROCESS.DECISION.DATE")}
                name="ngayQuyetDinh"
                value={formik.values?.ngayQuyetDinh || ""}
                type="date"
                readOnly={isView}
                onChange={formik.handleChange}
                onBlur={() =>
                  handleBlurDate(
                    formik.setFieldValue,
                    formik.values?.ngayQuyetDinh,
                    "ngayQuyetDinh"
                  )
                }
                errors={formik.errors?.ngayQuyetDinh}
                touched={formik.touched?.ngayQuyetDinh}
              />
            </Col>
            <Col xs={6} md={4} lg={3} xxl={2} className="spaces pt-6">
              <TextValidator
                isRequired
                lable={lang("INPUT.WORKPROCESS.DECISION.NUMBER")}
                name="soQuyetDinh"
                type="text"
                readOnly={isView}
                value={formik?.values?.soQuyetDinh || ""}
                onChange={formik?.handleChange}
                errors={formik?.errors?.soQuyetDinh}
                touched={formik?.touched?.soQuyetDinh}
              />
            </Col>
            <Col xs={6} md={4} lg={3} xxl={2} className="spaces pt-6">
              <TextValidator
                isRequired
                lable={lang("CONTRACT.EFFECTIVEDATE")}
                name="ngayHieuLuc"
                value={formik.values?.ngayHieuLuc || ""}
                type="date"
                readOnly={isView}
                onChange={handleChangeDate}
                onBlur={() =>
                  handleBlurDate(
                    formik.setFieldValue,
                    formik.values?.ngayHieuLuc,
                    "ngayHieuLuc"
                  )
                }
                errors={formik.errors?.ngayHieuLuc}
                touched={formik.touched?.ngayHieuLuc}
              />
            </Col>
            <Col xs={6} md={4} lg={3} xxl={2} className="spaces pt-6">
              <Autocomplete
                isRequired
                lable={lang("APPRECIATIVE.PERSON_SINGING")}
                isReadOnly={isView}
                options={[]}
                value={formik.values?.nguoiKy || null}
                name="nguoiKy"
                onChange={(selectedOption) =>
                  handleChangeSelect("nguoiKy", selectedOption)
                }
                searchFunction={searchNguoiDaiDienKy}
                searchObject={{}}
                touched={formik.touched?.nguoiKy}
                errors={formik.errors?.nguoiKy}
              />
            </Col>
            <Col xs={6} md={4} lg={3} xxl={2} className="spaces pt-6">
              <Autocomplete
                lable={lang("RESIGN.STATUS")}
                options={listStatusResign}
                value={formik.values?.trangThai}
                isReadOnly={true}
                name="trangThai"
                menuPlacement="top"
                onChange={(selectedOption) => handleChangeSelect("trangThai", selectedOption)}
                errors={formik.errors?.trangThai}
                touched={formik.touched?.trangThai}
              />
            </Col>
            <Col xs={6} md={4} lg={3} xxl={2} className="spaces pt-6">
              <UploadFile
                isReadOnly={isView}
                label="INPUT.QUALIFICATION.FILE"
                setValue={handleFile}
                fileValue={{
                  id: formik.values.fileId || "",
                  name: formik.values.fileName || ""
                }}
                allowFileTypes={`${FILE_TYPE.PDF},${FILE_TYPE.DOC},${FILE_TYPE.DOCX},${FILE_TYPE.XLS},${FILE_TYPE.XLSX}`}
              />
            </Col>
            <Col xs={6} md={4} lg={3} xxl={2} className="spaces pt-6">
              <Form.Check
                label={lang("RESIGN.EXISTED_COMFIRM_SALARY")}
                checked={formik.values?.coPhieuXacNhan || false}
                onChange={handleChangeCheckBox}
                className="custom-form-check"
                readOnly={isView}
                disabled={isView}
                name="coPhieuXacNhan"
              />
            </Col>
            <Col xs={6} md={4} lg={3} xxl={2} className="spaces pt-6">
              <Form.Check
                label={lang("RESIGN.ADDED_PROFILE")}
                checked={formik.values?.daBoSungHoSo || false}
                onChange={handleChangeCheckBox}
                className="custom-form-check"
                readOnly={isView}
                disabled={isView}
                name="daBoSungHoSo"
              />
            </Col>
            <Col xs={6} md={4} lg={3} xxl={2} className="spaces pt-6">
              <Form.Check
                label={lang("RESIGN.COMPLETED_PROCEDURE")}
                checked={formik.values?.daHoanTatThuTuc}
                onChange={handleChangeCheckBox}
                className="custom-form-check mt-8"
                readOnly={isView}
                disabled={isView}
                name="daHoanTatThuTuc"
              />
            </Col>
            <Col xs={12} xl={6} className="spaces pt-6">
              <TextValidator
                lable={lang("RESIGN.REASON")}
                name="lyDoThoiViec"
                type="text"
                as="textarea"
                rows="3"
                value={formik.values?.lyDoThoiViec}
                readOnly={isView}
                onChange={formik.handleChange}
              />
            </Col>
            <Col xs={12} xl={6} className="spaces pt-6">
              <TextValidator
                lable={lang("INPUT.QUALIFICATION.NOTE")}
                name="ghiChu"
                type="text"
                as="textarea"
                rows="3"
                value={formik.values?.ghiChu}
                readOnly={isView}
                onChange={formik.handleChange}
              />
            </Col>
          </Row>
          <Row>
            <TableCustom
              isActionTableTab
              id="listReceivables"
              title={lang("INPUT.RETIRE_INFO.RECEIVABLES")}
              data={listReceivables}
              columns={columns}
              buttonAdd={!isView && hasAuthority(PERMISSIONS.NGHI_VIEC, PERMISSION_ABILITY.CREATE)}
              notDelete={isView || !hasAuthority(PERMISSIONS.NGHI_VIEC, PERMISSION_ABILITY.DELETE)}
              type={(!isView && hasAuthority(PERMISSIONS.NGHI_VIEC, PERMISSION_ABILITY.DELETE)) ? TYPE.MULTILINE : ""}
              fixedColumnsCount={0}
              noPagination
              handleOpenDialog={() => !isView && handleOpenInfoDialog(INIT_RECEIVABLE_INFO)}
              handleDoubleClick={(row: any) => !isView ? handleOpenInfoDialog(row) : () => { }}
              handleDelete={async (ids: string[]) => handleDeleteReceivables(ids)}
            />
          </Row>
          <div className="sub-title">
            {lang("INPUT.RETIRE_INFO.INSURANCE_INFO")}
          </div>
          <Row>
            <Col xs={6} md={4} lg={3} xxl={2} className="spaces pt-6">
              <Autocomplete
                isRequired
                lable={lang("INPUT.RETIRE_INFO.INSURANCE_STATUS")}
                options={listResignTypeInsurance}
                onChange={(value) =>
                  handleChangeSelect(VARIABLE_STRING.TRANG_THAI_BAO_HIEM, value)
                }
                menuPlacement="top"
                name="trangThaiBaoHiem"
                value={formik.values?.trangThaiBaoHiem || null}
                isReadOnly={isView}
                errors={formik.errors?.trangThaiBaoHiem}
                touched={formik.touched?.trangThaiBaoHiem}
              />
            </Col>
            <Col xs={6} md={4} lg={3} xxl={2} className="spaces pt-6">
              <TextValidator
                isRequired={formik.values?.trangThaiBaoHiem?.code === INSURANCE_TYPE_CODE.DUNG_DONG}
                lable={lang("INPUT.INSURANCE.STOP_TIME")}
                name="thoiGianDungDongBH"
                value={formik.values?.thoiGianDungDongBH || ""}
                type="date"
                readOnly={isView}
                onChange={formik.handleChange}
                onBlur={() =>
                  handleBlurDate(
                    formik.setFieldValue,
                    formik.values?.thoiGianDungDongBH,
                    "thoiGianDungDongBH"
                  )
                }
                errors={formik.errors?.thoiGianDungDongBH}
                touched={formik.touched?.thoiGianDungDongBH}
              />
            </Col>
            <Col xs={6} md={4} lg={3} xxl={2} className="spaces pt-6">
              <Autocomplete
                lable={lang("INPUT.INSURANCE.ADJUST")}
                options={listFormOfAdjustment}
                onChange={(value) =>
                  handleChangeSelect(VARIABLE_STRING.HINH_THUC_DIEU_CHINH, value)
                }
                menuPlacement="top"
                name="hinhThucDieuChinh"
                value={formik.values?.hinhThucDieuChinh || null}
                isReadOnly={isView}
              />
            </Col>
            <Col xs={6} md={4} lg={3} xxl={2} className="spaces pt-6">
              <TextValidator
                isRequired
                lable={lang("IMPLEMENTATION.TIME")}
                name="thoiGianThucHien"
                value={formik.values?.thoiGianThucHien || ""}
                type="date"
                readOnly={isView}
                onChange={formik.handleChange}
                onBlur={() =>
                  handleBlurDate(
                    formik.setFieldValue,
                    formik.values?.thoiGianThucHien,
                    "thoiGianThucHien"
                  )
                }
                errors={formik.errors?.thoiGianThucHien}
                touched={formik.touched?.thoiGianThucHien}
              />
            </Col>
          </Row>
        </div>
      </Form>
      {shouldOpenDialog && (
        <ReceivableDialog
          receivableInfo={receivableInfo}
          handleClose={handleCloseDialog}
          isView={isView}
          handleDialogSubmit={handleAddReceivable}
        />
      )}
    </div>
  );
};
export { ResignDialog };

