//@ts-nocheck
import { formatDateTable } from "../../../utils/FunctionUtils";

export const CertificateColumn = () => {
  return [
    {
      name: "STT",
      field: "stt",
      headerStyle: {
        minWidth: "40px"
      },
      render: (row: any, index: number, numericalOrder: number) => (
        <span>{numericalOrder}</span>
      )
    },
    {
      name: "Từ ngày",
      field: "thoiGianHieuLucTuNgay",
      headerStyle: {
        minWidth: "90px"
      },
      render: (row: any) => <span>{formatDateTable(row?.thoiGianHieuLucTuNgay)}</span>
    },
    {
      name: "Đến ngày",
      field: "thoiGianHieuLucDenNgay",
      headerStyle: {
        minWidth: "90px"
      },
      render: (row: any) => <span>{formatDateTable(row?.thoiGianHieuLucDenNgay)}</span>
    },
    {
      name: "Cơ sở chứng chỉ hành nghề",
      field: "soCCHN",
      headerStyle: {
        minWidth: "200px"
      },
      cellStyle: {
        textAlign: "left"
      },
    },
    {
      name: "Số chứng chỉ hành nghề",
      field: "noiCap",
      headerStyle: {
        minWidth: "180px"
      },
      cellStyle: {
        textAlign: "left"
      },
    },
    {
      name: "Cơ sở đào tạo",
      field: "coSoDaoTaoText",
      headerStyle: {
        minWidth: "250px"
      },
      cellStyle: {
        textAlign: "left"
      },
      render: (row: any) => <span>{row?.coSoDaoTaoKhac ? row?.coSoDaoTaoKhac : row?.coSoDaoTaoText }</span>
    },
    {
      name: "Nơi cấp",
      field: "noiCap",
      headerStyle: {
        minWidth: "150px"
      },
      cellStyle: {
        textAlign: "left"
      },
    },
    {
      name: "Chuyên ngành đào tạo",
      field: "chuyenNganh",
      headerStyle: {
        minWidth: "200px"
      },
      cellStyle: {
        textAlign: "left"
      },
    }
  ];
};
