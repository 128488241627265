import { formatDateTable, romanize } from "../../utils/FunctionUtils";
import { REPORT_KEY, REPORT_TYPE } from "../consts/reportConsts";
import { IReportInfo } from "../models/reportModels";

export const convertDataReport = (dataObj: Object | Array<any>) => {
  let dataConverted: any[] = [];
  if(Array.isArray(dataObj)) {
    dataObj.forEach((item) => {
      dataConverted.push({
        phongBanText: item?.phongBanText || "",
        data: item?.data || []
      });
    });
  } else {
    Object.entries(dataObj).forEach(([key, value]) => {
      dataConverted.push({
        phongBanText: REPORT_KEY[key] || "",
        data: value
      });
    });
  }

  return dataConverted;
};

export const templateTitleReport = (reportInfo: IReportInfo, options: any) => {
  switch (reportInfo?.mappingName) {
    case REPORT_TYPE.QUY_LUONG:
      return `Dự toán biên chế và quỹ tiền lương quý ${options?.quarter ? romanize(options.quarter) : ""} năm ${options?.year}`;
    case REPORT_TYPE.TONG_HOP_VI_PHAM:
      return `Báo cáo tổng hợp vi phạm (${formatDateTable(options.tuNgay)} - ${formatDateTable(options.denNgay)})`
    case REPORT_TYPE.TAI_NAN_LAO_DONG:
      return `Báo cáo tai nạn lao động (${formatDateTable(options.tuNgay)} - ${formatDateTable(options.denNgay)})`
    default:
      return reportInfo?.value || "";
  }
};

export const getHeadOfMonth = (date: string | Date) => {
  if(!date || isNaN(new Date(date).getTime())) return "";
  const currentDate = new Date(date);
  currentDate.setDate(1);
  let newYear = currentDate.getFullYear();
  let newMonth = currentDate.getMonth() + 1;
  let newDay = currentDate.getDate();

  return `${newYear}-${String(newMonth).padStart(2, "0")}-${String(newDay).padStart(2, "0")}`;
}

export const getHeadOfYear = (date: string | Date) => {
  if(!date || isNaN(new Date(date).getTime())) return "";
  const currentDate = new Date(date);
  currentDate.setMonth(0);
  currentDate.setDate(1);
  let newYear = currentDate.getFullYear();
  let newMonth = currentDate.getMonth() + 1;
  let newDay = currentDate.getDate();

  return `${newYear}-${String(newMonth).padStart(2, "0")}-${String(newDay).padStart(2, "0")}`;
}