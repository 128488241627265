import { RemindBirthdayTable, RemindEmployeeNoContractTable } from "../components/table";
import { IRemindHeader } from "../models/remindModels";

export enum REMIND_TYPE_CODES {
  BIRTH_DAY = 1,
  NOT_YET_CONTRACT = 2
}

export const REMIND_TYPES = [
  {
    code: REMIND_TYPE_CODES.BIRTH_DAY,
    name: "Sinh nhật"
  },
  {
    code: REMIND_TYPE_CODES.NOT_YET_CONTRACT,
    name: "Chưa có hợp đồng lao động"
  }
];

export const REMIND_HEDER_LIST: IRemindHeader[] = [
  {
    codeType: REMIND_TYPE_CODES.BIRTH_DAY,
    pathIcon: "/media/icons/birthday-cake.svg",
    tilte: "Sinh nhật nhân viên",
    amount: 0,
    remindTable: () => <RemindBirthdayTable isDialogMode={true} />
  },
  {
    codeType: REMIND_TYPE_CODES.NOT_YET_CONTRACT,
    pathIcon: "/media/icons/contract.svg",
    tilte: "Nhân viên chưa có hợp đồng",
    amount: 0,
    remindTable: () => <RemindEmployeeNoContractTable isDialogMode={true} />
  }
];
