import { FC, useContext, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import { PERMISSIONS, PERMISSION_ABILITY, ROLE, EXPORT_FILE_NAME } from "../../Constant";
import useMultiLanguage from "../../hook/useMultiLanguage";
import InputSearch from "../component/input-field/InputSearch";
import TableCustom, { columnNamesType } from "../component/table-custom/TableCustom";
import AdvancedSearch from "../profile/component/Dialog/AdvancedSearch";
import { IItemSearch } from "../profile/models/ProfileModels";
import { COMPLETED_STATUS, COMPLETED_STATUS_CODE, RESPONSE_STATUS_CODE, TYPE } from "../utils/Constant";
import { exportBoNhiem } from "../utils/ExportFileServices";
import { convertSearch, formatDateTable, hasAuthority, hasRole, searchDataRoleUser, exportToFile, checkStatus } from "../utils/FunctionUtils";
import AppointmentForm from "./components/AppointmentForm";
import { INIT_INPUT_SEARCH, ListSearch } from "./const/AppointmentConst";
import { IAppointment } from "./models/IAppointment";
import { deleteAppointment, getListAppointment } from "./services/AppointmentServices";
import AppContext from "../../AppContext";
import "./AppointmentList.scss"
import { INIT_SEARCH_OBJECT } from "../constant";

const AppointmentList: FC = () => {
  const { lang } = useMultiLanguage();
  const { setPageLoading } = useContext(AppContext);

  const [listAppointment, setListAppointment] = useState<IAppointment[]>([]);
  const [openAppointmentForm, setOpenAppointmentForm] = useState<boolean>();
  const [appointmentInfo, setAppointmentInfo] = useState<IAppointment>({} as IAppointment);
  const [totalPage, setTotalPage] = useState<number>(0);
  const [totalElements, setTotalElements] = useState<number>(0);
  const [numberOfElements, setNumberOfElements] = useState<number>(0);
  const [isView, setIsView] = useState<boolean>(false);
  const [searchObject, setSearchObject] = useState<any>(INIT_SEARCH_OBJECT);
  const [isAdvancedSearch, setIsAdvancedSearch] = useState<boolean>(false);
  const [listInputSearch, setListInputSearch] = useState<any[]>([INIT_INPUT_SEARCH]);

  useEffect(() => {
    if(searchObject?.pageIndex || searchObject?.pageSize) {
      handleSearch();
    }
  }, [searchObject?.pageIndex, searchObject?.pageSize])

  const handleOpenAppointmentForm = (info: any) => {
    setIsView(true);
    setOpenAppointmentForm(true);
    setAppointmentInfo(info);
  };

  const handleClose = () => {
    setOpenAppointmentForm(false);
    setIsView(false);
    setAppointmentInfo({} as IAppointment);
  };

  const handleCloseAndSearch = () => {
    handleSearch();
    handleClose();
  }

  const getAllAppointment = async (searchData?: any) => {
    try {
      setPageLoading(true);
      setSearchObject({ ...searchData })
      const { data } = await getListAppointment(hasRole(ROLE.USER) ? searchDataRoleUser(searchData) : {...searchData});
      if (data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
        setListAppointment(data?.data?.content || []);
        setTotalPage(data?.data?.totalPages);
        setTotalElements(data?.data?.totalElements);
        setNumberOfElements(data?.data?.numberOfElements);
        setIsAdvancedSearch(false);
      }else{
        toast.error(data?.message);
      }
      setPageLoading(false);
    } catch (err) {
      setPageLoading(false);
      toast.error(lang("GENERAL.ERROR"));
    }
  };

  const handleOpenUpdateDialog = () => {
    setOpenAppointmentForm(true)
  }

  const handleCloseUpdateDialog = () => {
    setOpenAppointmentForm(false);
    setIsView(false);
  }

  const columns: columnNamesType[] = [
    {
      name: "STT",
      field: "",
      render: (row: any, index: number, stt: number) => <span>{stt}</span>
    },
    {
      name: lang("GENERAL.STATUS"),
      field: "trangThai",
      headerStyle: {
        minWidth: "200px"
      },
      render: (row: any) => <span className={`${checkStatus(COMPLETED_STATUS, row?.trangThai?.code)} status`}>
        {row?.trangThai?.name}
      </span>
    },
    {
      name: lang("INPUT_APPOINTMENT_CODE"),
      field: "maNhanVien",
      headerStyle: {
        minWidth: "200px"
      },
      render: (row: any) => <span className="color-steel-blue fw-600">{row?.maNhanVien}</span>
    },
    {
      name: lang("INPUT_APPOINTMENT_NAME"),
      field: "ten",
      headerStyle: {
        minWidth: "200px"
      },
      cellStyle: {
        textAlign: "left"
      },
    },
    {
      name: lang("INPUT_APPOINTMENT_WORK_UNINT"),
      field: "donViCongTacText",
      headerStyle: {
        minWidth: "200px"
      },
      cellStyle: {
        textAlign: "left"
      },
    },
    {
      name: lang("INPUT_APPOINTMENT_UNIT_DEPARTMENT"),
      field: "phongBanText",
      headerStyle: {
        minWidth: "200px"
      },
      cellStyle: {
        textAlign: "left"
      },
    },
    {
      name: lang("INPUT_APPOINTMENT_JOB_POSITION"),
      field: "chucVuText",
      headerStyle: {
        minWidth: "200px"
      },
      cellStyle: {
        textAlign: "left"
      },
    },
    {
      name: lang("INPUT_APPOINTMENT_DATE_START"),
      field: "thoiGianHieuLucTuNgay",
      headerStyle: {
        minWidth: "100px"
      },
      render: (row: any) => <span>{formatDateTable(row?.thoiGianHieuLucTuNgay)}</span>
    },
    {
      name: lang("INPUT_APPOINTMENT_DATE_END"),
      field: "thoiGianHieuLucDenNgay",
      headerStyle: {
        minWidth: "100px"
      },
      render: (row: any) => <span>{formatDateTable(row?.thoiGianHieuLucDenNgay)}</span>
    },
    {
      name: lang("INPUT_APPOINTMENT_WORK_UNINT_NEW"),
      field: "donViCongTacMoiText",
      headerStyle: {
        minWidth: "200px"
      },
      cellStyle: {
        textAlign: "left"
      },
    },
    {
      name: lang("INPUT_APPOINTMENT_UNIT_DEPARTMENT_NEW"),
      field: "phongBanMoiText",
      headerStyle: {
        minWidth: "200px"
      },
      cellStyle: {
        textAlign: "left"
      },
    },
    {
      name: lang("INPUT_APPOINTMENT_NEW_JOB_POSITION_NEW"),
      field: "chucVuMoiText",
      headerStyle: {
        minWidth: "200px"
      },
      cellStyle: {
        textAlign: "left"
      },
    },
    {
      name: lang("INPUT_APPOINTMENT_NUMBER"),
      field: "soQuyetDinh",
      headerStyle: {
        minWidth: "200px"
      },
      cellStyle: {
        textAlign: "left"
      },
    }
  ]

  const handleSearch = (data: any = {}) => {
    const dataSearch: any = {
      ...searchObject,
      ...data
    };
    dataSearch.keyword = dataSearch?.keyword?.trim() || ""
    getAllAppointment(dataSearch);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchObject({
      ...searchObject,
      keyword: e.target?.value
    });
  };

  const handleOpenAdvancedSearch = () => {
    setIsAdvancedSearch(true);
  };

  const handleAdvancedSearch = (data: IItemSearch[]) => {
    setListInputSearch(data);
    handleSearch({ ...convertSearch(data), pageIndex: 1 });
  };

  const handleDeleteAppointment = async (ids: string[]) => {
    try {
      const { data } = await deleteAppointment(ids)
      if (data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
        toast.success(lang("TOAST.DELETE.APPOINTMENT.SUCCESS"))
        getAllAppointment(searchObject);
        return true;
      }else{
        toast.error(data?.message)
      }
    }
    catch (err: any) {
      toast.error(err)
    }
  }

  return (
    <div className="appointment spaces px-8 py-12 flex-1">
      {!openAppointmentForm ? (
        <>
          <Row className="spaces mb-12 flex-middle">
           <Col xs={5} xl={6} xxl={7} className="p-0">
             <h2 className="breadcrumb-title">{lang("GENERAL_APPOINTMENT_LIST")}</h2>
           </Col>
           <Col xs={7} xl={6} xxl={5} className="flex">
             <div className="spaces mr-10 flex-1">
               <InputSearch
                 className="border-3px"
                 value={searchObject?.keyword}
                 placeholder="Tìm kiếm theo mã NV, họ và tên"
                 handleSearch={() => handleSearch({ pageIndex: 1 })}
                 handleChange={handleChange}
                 isEnter
               />
             </div>
             <button
               className="spaces button-primary mr-10"
               onClick={() => handleSearch({ pageIndex: 1 })}
             >
               Tìm kiếm
             </button>
             <button
               className="spaces button-primary-outline mr-10"
               onClick={handleOpenAdvancedSearch}
             >
               Tìm nâng cao
             </button>

             {isAdvancedSearch && (
               <AdvancedSearch
                 open={isAdvancedSearch}
                 listInputSearch={listInputSearch}
                 handleSearch={handleAdvancedSearch}
                 listOption={ListSearch(lang)}
                 handeleClose={() => setIsAdvancedSearch(false)}
               />
             )}
           </Col>
         </Row>
         <Row className="bg-white">
          <Col className="table-custom">
            <TableCustom
              id="appointment-list"
              data={listAppointment}
              columns={columns}
              searchObject={searchObject}
              setSearchObject={setSearchObject}
              buttonAdd={hasAuthority(PERMISSIONS.BO_NHIEM, PERMISSION_ABILITY.CREATE)}
              buttonExportExcel={hasAuthority(PERMISSIONS.BO_NHIEM, PERMISSION_ABILITY.VIEW) && !hasRole(ROLE.USER)}
              notDelete={!hasAuthority(PERMISSIONS.BO_NHIEM, PERMISSION_ABILITY.DELETE)}
              handleExportExcel={(ids: string[]) => 
                exportToFile({
                  exportAPI: () => exportBoNhiem({ ids, ...searchObject }),
                  fileName: EXPORT_FILE_NAME.BO_NHIEM,
                  setPageLoading
                })
              }
              type={TYPE.MULTILINE}
              fixedColumnsCount={4}
              totalPages={totalPage}
              totalElements={totalElements}
              numberOfElements={numberOfElements}
              handleOpenDialog={handleOpenUpdateDialog}
              handleDoubleClick={(row: any) => handleOpenAppointmentForm(row)}
              handleDelete={(ids: string[]) => handleDeleteAppointment(ids)}
              deleteConditional={[
                {
                  keyPath: "trangThai.code",
                  value: COMPLETED_STATUS_CODE.UNFULFILLED,
                },
                {
                  keyPath: 'xacNhanBoNhiem',
                  value: false
                }
              ]}
            />
          </Col>
         </Row>
        </>
      ) : (
        <AppointmentForm
          handleClose={handleClose}
          handleCloseAndSearch={handleCloseAndSearch}
          appointmentInfo={appointmentInfo}
          handleOpenUpdateDialog={handleOpenUpdateDialog}
          handleCloseUpdateDialog={handleCloseUpdateDialog}
          isView={isView}
          setIsView={setIsView}
        />
      )}
    </div>
  )
};

export { AppointmentList };

