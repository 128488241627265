/* eslint-disable react-hooks/exhaustive-deps */
import { useFormik } from "formik";
import React, { useContext, useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { FILE_TYPE } from "../../component/FileUpload/constant";
import Autocomplete from "../../component/input-field/Autocomplete";
import TextValidator from "../../component/input-field/TextValidator";
import TableCustom from "../../component/table-custom/TableCustom";
import { TYPE_CATEGORY } from "../../constant";
import {
  CODE_HOP_DONG,
  SUCCESS_CODE,
  contractStatus,
  contractTypeHopDong,
  signinStatus,
  workShifts
} from "../../contract/const/ContractConst";
import { addNewContract, searchNguoiDaiDienKy, updateContract } from "../../contract/services/contractServices";
import { IContractInfo } from "../../contract/services/models/IContract";
import { convertDataContactDto, convertDataUI } from "../../contract/utils/FunctionUtils";
import { getAllSimpleValue, searchAllSimpleValue } from "../../services";
import { searchListWorkUnit } from "../../utils/CategoryServices";
import { RESPONSE_STATUS_CODE, SEARCH_OBJECT_MAX_SIZE, TYPE, VARIABLE_STRING } from "../../utils/Constant";
import { addMoreYear, convertNumberPrice, convertTextPrice, handleBlurDate } from "../../utils/FunctionUtils";
import { REF_TAB } from "../const/ProfileConst";
import { EmployeeProfile } from "../models/ProfileModels";
import "../profile.scss";
import { searchPhongBan_DonVi } from "../services/DialogServices";
import { getContractByEmployee, getContractsByEmployee } from "../services/ProfileServices";
import { convertDataContract } from "../utils/FunctionUtils";
import { ContractColumn } from "./Column/ContractColumn";
import { ContractDialog } from "./Dialog/ContractDialog";
import useMultiLanguage from "../../../hook/useMultiLanguage";
import { checkInvalidDate } from "../../utils/ValidationSchema";
import UploadFile from "../../component/FileUpload/UploadFile";
import { IFile } from "../models/DialogModels";
import AppContext from "../../../AppContext";
import { IOrganization, ISimpleValue } from "../../models/models";
import TextArea from "../../component/input-field/TextArea";

type IProps = {
  isView: boolean;
  identify: string;
  formikRef: any;
  activeTab: string;
  employeeProfiles?: EmployeeProfile;
  handleToggleIsView: () => void;
};

export const ContractInformation: React.FC<IProps> = ({ isView, identify, formikRef, activeTab, employeeProfiles, handleToggleIsView }) => {
  const { lang, intl } = useMultiLanguage();
  const { setPageLoading } = useContext(AppContext);

  const [isHopDongDaiHan, setIsHopDongDaiHan] = useState<boolean>(false);
  const [isCollaborators, setIsCollaborators] = useState<boolean>(false);
  const [shouldOpenContractDialog, setShouldOpenContractDialog] = useState<boolean>(false);
  const [contract, setContract] = useState<IContractInfo>({} as IContractInfo);
  const [initContract, setInitContract] = useState<IContractInfo>({} as IContractInfo);
  const [contractList, setContractList] = useState<IContractInfo[]>([]);
  const [totalPage, setTotalPage] = useState<number>(0);
  const [totalElements, setTotalElements] = useState<number>(0);
  const [numberOfElements, setNumberOfElements] = useState<number>(0);
  const [workTimesList, setWorkTimesList] = useState<ISimpleValue[]>([]);
  const [dayPaySalaryList, setDayPaySalaryList] = useState<ISimpleValue[]>([]);
  const [departments, setDepartments] = useState<IOrganization[]>([]);
  const [listNguoiDaiDien, setListNguoiDaiDien] = useState<any[]>([]);

  useEffect(() => {
    getWorkTimes();
    getDayPaySalary();
  }, [])

  const getWorkTimes = async () => {
    try {
      setPageLoading(true);
      const { data } = await searchAllSimpleValue({ ...SEARCH_OBJECT_MAX_SIZE, type: TYPE_CATEGORY.thoiGianLamViec });
      if (data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
        setWorkTimesList(data?.data?.content || []);
      }
    } catch (error) {
      toast.error(lang("GENERAL.ERROR"));
    } finally {
      setPageLoading(false);
    }
  }

  const getDayPaySalary = async () => {
    try {
      setPageLoading(true);
      const { data } = await searchAllSimpleValue({ ...SEARCH_OBJECT_MAX_SIZE, type: TYPE_CATEGORY.ngayTraLuongThang });
      if (data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
        setDayPaySalaryList(data?.data?.content || []);
      }
    } catch (error) {
      toast.error(lang("GENERAL.ERROR"));
    } finally {
      setPageLoading(false);
    }
  }

  const getContractInfoByEmployee = async () => {
    if (identify) {
      try {
        const { data } = await getContractByEmployee(identify);
        if (data?.code === SUCCESS_CODE) {
          const checkHopDongDaiHan = Object.values(CODE_HOP_DONG).some(value => value === Number(data?.data?.loaiHopDong?.code))
          setIsHopDongDaiHan(checkHopDongDaiHan)
        }
        return data?.data;
      } catch (error) {
        console.error(error);
      }
    }
  };

  useEffect(() => {
    if (!employeeProfiles?.id) return;
    const initContract = {
      employee: employeeProfiles,
      employeeCode: employeeProfiles?.code,
      phongBan: {
        id: employeeProfiles?.phongBanId,
        name: employeeProfiles?.phongBanText
      },
      viTriCongViec: employeeProfiles?.viTriCongViecId ? {
        id: employeeProfiles?.viTriCongViecId,
        value: employeeProfiles?.viTriCongViecText
      } : null,
      chucVu: employeeProfiles?.chucVu,
      chucDanh: employeeProfiles?.viTriCongViec,
      tenNguoiLaoDong: employeeProfiles?.name,
      employeeId: employeeProfiles?.id
    };

    setContract(initContract as IContractInfo);
    setInitContract(initContract as IContractInfo);
  }, [employeeProfiles]);

  const validationSchema = Yup.object().shape({
    donViKyHopDong: Yup.object().required(lang("VALIDATION.REQUIRE")).nullable(),
    soHopDong: Yup.string().required(lang("VALIDATION.REQUIRE")).nullable(),
    phongBan: Yup.object().shape({}).required(lang("VALIDATION.REQUIRE")).nullable(),
    chucVu: Yup.object().shape({}).required(lang("VALIDATION.REQUIRE")).nullable(),
    viTriCongViec: Yup.object().required(lang("VALIDATION.REQUIRE")).nullable(),
    luongCoBan: Yup.string().required(lang("VALIDATION.REQUIRE")).nullable(),
    ngayCoHieuLuc: Yup.date()
      .concat(checkInvalidDate(intl))
      .required(lang("VALIDATION.REQUIRE"))
      .when("ngayHetHan", {
        is: (ngayHetHan: Date | null) => ngayHetHan,
        then: Yup.date()
          .max(
            Yup.ref("ngayHetHan"),
            lang("VALIDATION.MAXDATE") +
            lang("CONTRACT.EXPIRATIONDATE")
          )
          .nullable()
      })
      .nullable(),
    ngayHetHan: Yup.date()
      .concat(checkInvalidDate(intl))
      .when(VARIABLE_STRING.LOAI_HOP_DONG, {
        is: (loaiHopDong: any) =>
          loaiHopDong?.code === CODE_HOP_DONG.VIEN_CHUC_DAI_HAN || loaiHopDong?.code === CODE_HOP_DONG.HOP_DONG_DAI_HAN,
        then: Yup.date().nullable(),
        otherwise: Yup.date()
          .required(lang("VALIDATION.REQUIRE"))
          .concat(checkInvalidDate(intl))
          .min(Yup.ref(VARIABLE_STRING.NGAY_CO_HIEU_LUC), lang("VALIDATE.EXPIRATION_DATE_AFTER_EFFECTIVE_DATE"))
          .nullable()
      }),
    ngayKyHopDong: Yup.date()
      .concat(checkInvalidDate(intl))
      .when("ngayHetHan", {
        is: (ngayHetHan: Date | null) => ngayHetHan && ngayHetHan < (new Date()),
        then: Yup.date()
          .max(
            Yup.ref("ngayHetHan"),
            lang("VALIDATION.SIGN_DAY.EQUAL_OF_LESS")
          )
          .nullable()
      })
      .max(new Date(), lang("VALIDATION.INVALID_SIGN_DATE"))
      .nullable(),
    loaiHopDong: Yup.object().required(lang("VALIDATION.REQUIRE")).nullable(),
    trangThaiHopDong: Yup.object().required(lang("VALIDATION.REQUIRE")).nullable(),
    nguoiDaiDienCtyKy: Yup.object().required(lang("VALIDATION.REQUIRE")).nullable(),
    diaDiemLamViec: Yup.object().required(lang("VALIDATION.REQUIRE")).nullable(),
    moTaCongViec: Yup.string().required(lang("VALIDATION.REQUIRE")).nullable()
  });

  const handleFormSubmit = async (values: IContractInfo) => {
    const { SUCCESS } = RESPONSE_STATUS_CODE;
    try {
      setPageLoading(true);
      const dataContract = convertDataContactDto(values);
      const { data } = formik.values?.id
        ? await updateContract(formik.values?.id, dataContract)
        : await addNewContract(dataContract);
      if (data?.code === SUCCESS) {
        toast.success(formik.values?.id ? lang("TOAST.EDIT.CONTRACT.SUCCESS") : lang("TOAST.ADD.CONTRACT.SUCCESS"));
        updateDataContract();
        fetchContract();
        handleToggleIsView();
      } else toast.error(`${data?.message}`);
    } catch (error) {
      toast.error(lang("GENERAL.ERROR"));
    } finally {
      setPageLoading(false);
    }
  };

  const formik = useFormik({
    initialValues: {} as IContractInfo,
    validationSchema,
    onSubmit: handleFormSubmit
  });

  useEffect(() => {
    if (formik.values?.employee?.donViCongTacId) {
      getDepartments();
    }
  }, [formik.values?.employee?.donViCongTacId])

  const getDepartments = async () => {
    try {
      setPageLoading(true);
      const { data } = await searchPhongBan_DonVi({ id: formik.values?.employee?.donViCongTacId });
      if (data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
        setDepartments(data?.data || []);
      }
    } catch (error) {
      toast.error(lang("GENERAL.ERROR"));
    } finally {
      setPageLoading(false);
    }
  }

  useEffect(() => {
    let departmentType = departments.find((phongBan) => formik.values.phongBan?.id === phongBan.id)?.type;
    if (departmentType) {
      let workTimeFinded = workTimesList.find((item) => Number(item.mappingName) === departmentType);
      let dayPayFinded = dayPaySalaryList.find((item) => Number(item.mappingName) === departmentType);
      formik.setValues({
        ...formik.values,
        thoiGianLamViecText: workTimeFinded?.value || "",
        thoiGianLamViecId: workTimeFinded?.id || "",
        ngayTraLuongThangText: dayPayFinded?.value || "",
        ngayTraLuongThangId: dayPayFinded?.id || ""
      })
    }
  }, [formik.values.phongBan?.id, departments, workTimesList, dayPaySalaryList])

  const handleChangeSelect = (name: string, value: any) => {
    switch (name) {
      case VARIABLE_STRING.LOAI_HOP_DONG:
        const { VIEN_CHUC_DAI_HAN, HOP_DONG_DAI_HAN, ONE_YEAR } = CODE_HOP_DONG;
        switch (value?.code) {
          case VIEN_CHUC_DAI_HAN:
          case HOP_DONG_DAI_HAN:
            formik.setValues({
              ...formik.values,
              [name]: value,
              ngayHetHan: null
            });
            setIsHopDongDaiHan(true);
            break;
          case ONE_YEAR:
            setIsHopDongDaiHan(true);
            formik.setValues({
              ...formik.values,
              [name]: value,
              ngayHetHan: addMoreYear(formik.values?.ngayCoHieuLuc, 1)
            });
            break;
          default:
            formik.setFieldValue(VARIABLE_STRING.LOAI_HOP_DONG, value);
            setIsHopDongDaiHan(false);
            break;
        }
        break;
      case VARIABLE_STRING.NGUOI_DAI_DIEN_KY:
        formik.setValues({
          ...formik.values,
          nguoiDaiDienCtyChucDanhId: value?.chucVuId,
          nguoiDaiDienCtyChucDanhText: value?.chucDanh,
          nguoiDaiDienCtyKyId: value?.id,
          nguoiDaiDienCtyKyText: value?.name
        });
        formik.setFieldValue(name, value);
        break;
      case VARIABLE_STRING.CHUC_DANH:
        formik.setFieldValue(name, value);
        formik.setFieldValue(VARIABLE_STRING.BAC_LUONG_OPTION, null);
        formik.setFieldValue(VARIABLE_STRING.HE_SO_LUONG, undefined);
        break;
      case VARIABLE_STRING.BAC_LUONG_OPTION:
        formik.setFieldValue(name, value);
        formik.setFieldTouched(name, false, false);
        formik.setFieldValue(VARIABLE_STRING.HE_SO_LUONG, value?.heSoLuong || "");
        break;
      default:
        formik.setFieldValue(name, value);
        break;
    }
  };

  const handleChangeNgayCoHieuLuc = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    formik.setValues({
      ...formik.values,
      [name]: value,
      ngayHetHan:
        formik.values?.loaiHopDong?.code === CODE_HOP_DONG.ONE_YEAR ? addMoreYear(value, 1) : formik.values?.ngayHetHan
    });
  };

  const handleChangeCheckBox = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;

    switch (name) {
      case VARIABLE_STRING.VIEN_CHUC:
        formik.setFieldValue(name, checked);
        formik.setFieldValue(VARIABLE_STRING.HOP_DONG_LAO_DONG, false);
        formik.setFieldValue(VARIABLE_STRING.LOAI_HOP_DONG, null);
        break;
      case VARIABLE_STRING.HOP_DONG_LAO_DONG:
        formik.setFieldValue(name, checked);
        formik.setFieldValue(VARIABLE_STRING.VIEN_CHUC, false);
        formik.setFieldValue(VARIABLE_STRING.LOAI_HOP_DONG, null);
        break;
      default:
        formik.setFieldValue(name, checked);
        break;
    }
  };

  useEffect(() => {
    if (formikRef) {
      formikRef.current = formik;
    }
  }, [formik, formikRef]);

  useEffect(() => {
    if (!identify || activeTab !== REF_TAB.HOP_DONG_LAO_DONG) return;
    getNguoiDaiDienKy();
    fetchContract();
    updateDataContract();
  }, [identify, activeTab, contract]);

  const fetchContract = async () => {
    let nguoikyDefault = (listNguoiDaiDien || [])?.find((item: any) => item?.isDefault);
    let values = {
      employee: employeeProfiles,
      employeeCode: employeeProfiles?.code,
      viTriCongViecText: employeeProfiles?.chucVuText,
      tenNguoiLaoDong: employeeProfiles?.name,
      employeeId: employeeProfiles?.id,
      ...formik.values,
      phongBan: employeeProfiles?.phongBan,
      chucVu: employeeProfiles?.chucVu,
      viTriCongViec: employeeProfiles?.viTriCongViec || null,
      donViKyHopDong: null,
      soHopDong: "",
      trangThaiHopDong: null,
      ngayCoHieuLuc: null,
      ngayHetHan: null,
      ngayKyHopDong: "",
      loaiHopDong: null,
      bacLuongOption: null,
      heSoLuong: "",
      nguoiDaiDienCtyKy: nguoikyDefault,
      nguoiDaiDienCtyChucDanhId: nguoikyDefault?.chucVuId,
      nguoiDaiDienCtyChucDanhText: nguoikyDefault?.chucDanh,
      nguoiDaiDienCtyKyId: nguoikyDefault?.id,
      nguoiDaiDienCtyKyText: nguoikyDefault?.name,
      luongCoBan: "",
      moTaCongViec: "",
      diaDiemLamViec: null
    };
    let response = await getContractInfoByEmployee();
    if (response?.id) {
      formik.setValues(convertDataContract({ ...values, ...convertDataContract(response) }));
    } else {
      formik.setValues(values);
    }
  };

  const getNguoiDaiDienKy = async () => {
    try {
      let { data } = await searchNguoiDaiDienKy()
      if (data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
        setListNguoiDaiDien(data?.data?.content)
      }
    } catch (error) { }
  }

  const handleOpenDialog = (
    row: any,
    setShouldOpenDialog: (value: React.SetStateAction<boolean>) => void,
    setData: (value: any) => void
  ): void => {
    let id = row?.id;
    if (employeeProfiles?.code) {
      setShouldOpenDialog(true);
      setData(id ? convertDataUI(row) : row);
    } else {
      toast.warning(lang("MESSAGE.BASIC.FIRST"));
    }
  };

  const updateDataContract = async () => {
    if (!identify || activeTab !== REF_TAB.HOP_DONG_LAO_DONG) return;
    try {
      setPageLoading(true);
      let { data } = await getContractsByEmployee(identify);
      if (data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
        setContractList(data?.data);
        setTotalPage(data?.totalPages);
        setTotalElements(data?.totalElements);
        setNumberOfElements(data?.numberOfElements);
      }
    } catch (error) {
      console.error(error);
      toast.error(lang("GENERAL.ERROR"));
    } finally {
      setPageLoading(false);
    }
  };

  const handleCloseContractDialog = (): void => {
    setShouldOpenContractDialog(false);
    updateDataContract();
    setContract(initContract as IContractInfo);
  };

  const handleChangeMoney = (event: React.ChangeEvent<any>) => {
    const { name, value } = event.target;
    formik.setFieldValue(name, convertTextPrice(value));
  };

  const handleFile = (data: IFile) => {
    formik.setFieldValue("fileId", data.id);
    formik.setFieldValue("fileName", data.name);
  }

  return (
    <div className="basic-info-wrapper addNewContract h-full">
      <Form
        onSubmit={formik.handleSubmit}
        className="form-info contract flex-column"
      >
        <>
          <div className="block-content">
            <span className="text-header">{lang("CONTRACT.INFO")}</span>
            <Row className="g-4">
              <Col md={6} lg={6} xl={4} xxl={2}>
                <Autocomplete
                  isRequired
                  lable={lang("PROFILE.NAME")}
                  options={[]}
                  name="employee"
                  value={formik.values?.employee || null}
                  isReadOnly={true}
                  errors={formik.errors.employee}
                  touched={formik.touched.employee}
                />
              </Col>
              <Col md={6} lg={6} xl={3} xxl={2}>
                <TextValidator
                  lable={lang("INPUT.CODE_NEW")}
                  name="maNhanVien"
                  isRequired
                  value={employeeProfiles?.maNhanVien || ""}
                  type="text"
                  onChange={formik.handleChange}
                  readOnly={true}
                />
              </Col>
              <Col md={7} lg={7} xl={5} xxl={3}>
                <Autocomplete
                  isRequired
                  lable={lang("INPUT.DEPARTMENTS")}
                  className="spaces z-index-5"
                  isReadOnly={isView}
                  options={departments}
                  value={formik.values?.phongBan || null}
                  name="phongBan"
                  onChange={(selectedOption) => handleChangeSelect("phongBan", selectedOption)}
                  touched={formik.touched?.phongBan}
                  errors={formik.errors?.phongBan}
                />
              </Col>
              <Col md={6} lg={6} xl={4} xxl={2}>
                <Autocomplete
                  isRequired
                  className="spaces z-index-4"
                  lable={lang("GENERAL.EMPLOYEE.POSITION")}
                  name="chucVu"
                  value={formik.values?.chucVu || null}
                  searchFunction={getAllSimpleValue}
                  searchObject={TYPE_CATEGORY.chucDanh}
                  isReadOnly={isView || isCollaborators}
                  onChange={(selectedOption) => handleChangeSelect(VARIABLE_STRING.CHUC_VU, selectedOption)}
                  errors={formik.errors?.chucVu}
                  touched={formik.touched?.chucVu}
                  options={[]}
                  getOptionLabel={(option) => option?.value}
                />
              </Col>
              <Col md={7} lg={7} xl={4} xxl={3}>
                <Autocomplete
                  lable={lang("GENERAL.EMPLOYEE.JOB_POSITION")}
                  className="spaces z-index-4"
                  name="viTriCongViec"
                  isRequired
                  value={formik.values?.viTriCongViec || null}
                  searchFunction={getAllSimpleValue}
                  searchObject={TYPE_CATEGORY.viTriCongViec}
                  isReadOnly={isView}
                  onChange={(selectedOption) => handleChangeSelect(VARIABLE_STRING.VI_TRI_CONG_VIEC, selectedOption)}
                  options={[]}
                  getOptionLabel={(option) => option?.value}
                  errors={formik.errors?.viTriCongViec}
                  touched={formik.touched?.viTriCongViec}
                />
              </Col>
            </Row>
          </div>
          <div className="block-content mt-4">
            <span className="text-header">{lang("CONTRACT.INFO_DETAIL")}</span>
            <Row className="g-4">
              <Col md={6} lg={6} xl={4} xxl={3}>
                <Autocomplete
                  isRequired
                  className="spaces z-index-3"
                  lable={lang("CONTRACT.SIGNINGUNIT")}
                  options={[]}
                  value={formik.values?.donViKyHopDong || null}
                  name="donViKyHopDong"
                  onChange={(value) => handleChangeSelect(VARIABLE_STRING.DON_VI_KY_HOP_DONG, value)}
                  searchFunction={searchListWorkUnit}
                  searchObject={SEARCH_OBJECT_MAX_SIZE}
                  touched={formik.touched?.donViKyHopDong}
                  errors={formik.errors?.donViKyHopDong}
                  isReadOnly={isView}
                />
              </Col>
              <Col md={6} lg={6} xl={4} xxl={2}>
                <TextValidator
                  isRequired
                  lable={lang("CONTRACT.NUMBER")}
                  name="soHopDong"
                  value={formik.values?.soHopDong || ""}
                  type="text"
                  readOnly={isView}
                  onChange={formik.handleChange}
                  touched={formik.touched?.soHopDong}
                  errors={formik.errors?.soHopDong}
                />
              </Col>
              <Col md={6} lg={6} xl={4} xxl={2}>
                <TextValidator
                  lable={lang("CONTRACT.NAME")}
                  name="tenHopDong"
                  value={formik.values?.tenHopDong || ""}
                  type="text"
                  readOnly={isView}
                  onChange={formik.handleChange}
                />
              </Col>
              <Col md={6} lg={6} xl={4} xxl={3}>
                <Autocomplete
                  className="spaces z-index-2"
                  isRequired
                  lable={lang("GENERAL.EMPLOYEE.CONTRACT_TYPE")}
                  options={contractTypeHopDong}
                  onChange={(value) => handleChangeSelect(VARIABLE_STRING.LOAI_HOP_DONG, value)}
                  name="loaiHopDong"
                  value={formik.values?.loaiHopDong || null}
                  isReadOnly={isView}
                  touched={formik.touched?.loaiHopDong}
                  errors={formik.errors?.loaiHopDong}
                  dependencies={[formik.values?.vienChuc, formik.values?.hopDongLaoDong]}
                />
              </Col>
              <Col md={6} lg={6} xl={4} xxl={2}>
                <Autocomplete
                  className="spaces z-index-3"
                  lable={lang("CONTRACT.WORK_SHIFT")}
                  options={workShifts}
                  onChange={(value) => handleChangeSelect(VARIABLE_STRING.CA_LAM_VIEC, value)}
                  name="caLamViec"
                  value={formik.values?.caLamViec || null}
                  isReadOnly={isView}
                />
              </Col>
              <Col md={6} lg={6} xl={4} xxl={2}>
                <TextValidator
                  lable={lang("CONTRACT.EFFECTIVEDATE")}
                  name="ngayCoHieuLuc"
                  value={formik.values?.ngayCoHieuLuc || ""}
                  type="date"
                  isRequired
                  readOnly={isView}
                  onChange={handleChangeNgayCoHieuLuc}
                  onBlur={() => handleBlurDate(formik.setFieldValue, formik.values?.ngayCoHieuLuc, "ngayCoHieuLuc")}
                  errors={formik.errors?.ngayCoHieuLuc}
                  touched={formik.touched?.ngayCoHieuLuc}
                />
              </Col>
              <Col md={6} lg={6} xl={4} xxl={2}>
                <TextValidator
                  lable={lang("CONTRACT.EXPIRATIONDATE")}
                  name="ngayHetHan"
                  value={formik.values?.ngayHetHan || ""}
                  type="date"
                  readOnly={isView || isHopDongDaiHan}
                  isRequired={!isHopDongDaiHan}
                  onChange={formik.handleChange}
                  onBlur={() => handleBlurDate(formik.setFieldValue, formik.values?.ngayHetHan, "ngayHetHan")}
                  errors={formik.errors?.ngayHetHan}
                  touched={formik.touched?.ngayHetHan}
                />
              </Col>
              <Col md={6} lg={6} xl={4} xxl={2}>
                <TextValidator
                  lable={lang("CONTRACT.BASESALARY")}
                  name="luongCoBan"
                  value={convertNumberPrice(formik.values?.luongCoBan)}
                  type="text"
                  isRequired={true}
                  readOnly={isView}
                  onChange={handleChangeMoney}
                  errors={formik.errors?.luongCoBan}
                  touched={formik.touched?.luongCoBan}
                />
              </Col>
              <Col md={6} lg={6} xl={4} xxl={2}>
                <TextValidator
                  lable={lang("CONTRACT.SALARY_PERCENT")}
                  name="tyLeHuongLuong"
                  value={formik.values?.tyLeHuongLuong || ""}
                  type="number"
                  readOnly={isView}
                  onChange={formik.handleChange}
                />
              </Col>
              <Col md={6} lg={6} xl={4} xxl={2}>
                <TextValidator
                  lable={lang("CONTRACT.INSURANCECONTRIBUTION")}
                  name="luongDongBaoHiem"
                  value={convertNumberPrice(formik.values?.luongDongBaoHiem)}
                  type="text"
                  readOnly={isView}
                  onChange={handleChangeMoney}
                />
              </Col>
              <Col md={6} lg={6} xl={4} xxl={2}>
                <Autocomplete
                  isRequired
                  lable={lang("CONTRACT.STATUS")}
                  options={contractStatus}
                  onChange={(value) => handleChangeSelect(VARIABLE_STRING.TRANG_THAI_HOP_DONG, value)}
                  name="trangThaiHopDong"
                  value={formik.values?.trangThaiHopDong || null}
                  isReadOnly={isView}
                  errors={formik.errors?.trangThaiHopDong}
                  touched={formik.touched?.trangThaiHopDong}
                />
              </Col>
              <Col md={6} lg={6} xl={4} xxl={2}>
                <TextValidator
                  lable={lang("CONTRACT.DAY_SALARY_PAY")}
                  name="ngayTraLuongThangText"
                  value={formik.values?.ngayTraLuongThangText || ""}
                  type="text"
                  readOnly={true}
                  onChange={formik.handleChange}
                />
              </Col>
              <Col md={6} lg={6} xl={4} xxl={2}>
                <TextValidator
                  lable={lang("CONTRACT.WORK_TIME")}
                  name="thoiGianLamViecText"
                  value={formik.values?.thoiGianLamViecText || ""}
                  type="text"
                  readOnly={true}
                  onChange={formik.handleChange}
                />
              </Col>
              <Col md={6} lg={6} xl={4} xxl={2}>
                <UploadFile
                  label="CONTRACT.ATTACHMENTS"
                  setValue={handleFile}
                  isReadOnly={isView}
                  fileValue={{
                    id: formik.values.fileId || "",
                    name: formik.values.fileName || ""
                  }}
                  allowFileTypes={`${FILE_TYPE.PDF},${FILE_TYPE.DOC},${FILE_TYPE.DOCX},${FILE_TYPE.XLS},${FILE_TYPE.XLSX}`}
                />
              </Col>
              <Col xs={12} xl={8} xxl={6} className="spaces pt-6">
                <Autocomplete
                  isRequired
                  lable={lang("CONTRACT.WORK_PLACE")}
                  options={[]}
                  onChange={(value) => handleChangeSelect("diaDiemLamViec", value)}
                  name="diaDiemLamViec"
                  value={formik.values?.diaDiemLamViec}
                  isReadOnly={isView}
                  searchFunction={searchAllSimpleValue}
                  searchObject={{ ...SEARCH_OBJECT_MAX_SIZE, type: TYPE_CATEGORY.diaDiemLamViec }}
                  getOptionLabel={(options) => options?.value}
                  errors={formik.errors?.diaDiemLamViec}
                  touched={formik.touched?.diaDiemLamViec}
                />
              </Col>
              <Col xs={12} xl={6} xxl={6}>
                <TextArea
                  isRequired
                  className="w-100"
                  lable={lang("CONTRACT.JOB_DESCRIPTION")}
                  name="moTaCongViec"
                  value={formik.values?.moTaCongViec || ""}
                  type="text"
                  as="textarea"
                  rows="2"
                  readOnly={isView}
                  bulletPoints="+"
                  onChange={(value: any) => formik.setFieldValue("moTaCongViec", value)}
                  touched={formik.touched?.moTaCongViec}
                  errors={formik.errors?.moTaCongViec}
                />
              </Col>
              <Col xs={12} xl={6} xxl={6}>
                <TextValidator
                  lable={lang("CONTRACT.NOTE")}
                  name="ghiChu"
                  value={formik.values?.ghiChu}
                  as="textarea"
                  readOnly={isView}
                  onChange={formik.handleChange}
                  rows={2}
                />
              </Col>
            </Row>
          </div>
          <div className="block-content mt-4">
            <span className="text-header">{lang("CONTRACT.SALARY_SIGN")}</span>
            <Row className="g-4">
              <Col md={6} lg={6} xl={4} xxl={2}>
                <Form.Check
                  label={lang("CONTRACT.PAPER_AUTHORITY")}
                  checked={formik.values?.giayUyQuyen}
                  onChange={handleChangeCheckBox}
                  className="custom-form-check"
                  readOnly={isView}
                  name="giayUyQuyen"
                  disabled={isView}
                />
              </Col>
              <Col md={6} lg={6} xl={4} xxl={3}>
                <Autocomplete
                  lable={lang("CONTRACT.SIGNEDCOMPANYREPRESENTATIVE")}
                  options={listNguoiDaiDien}
                  value={formik.values?.nguoiDaiDienCtyKy || null}
                  isRequired
                  name="nguoiDaiDienCtyKy"
                  onChange={(value) => handleChangeSelect(VARIABLE_STRING.NGUOI_DAI_DIEN_KY, value)}
                  isReadOnly={isView}
                  errors={formik.errors?.nguoiDaiDienCtyKy}
                  touched={formik.touched?.nguoiDaiDienCtyKy}
                />
              </Col>
              <Col md={6} lg={6} xl={4} xxl={3}>
                <TextValidator
                  lable={lang("CONTRACT.REPRESENTATIVETITLE")}
                  name="nguoiDaiDienCtyChucDanh"
                  value={formik.values?.nguoiDaiDienCtyChucDanhText || ""}
                  type="text"
                  readOnly={true}
                />
              </Col>
              <Col md={6} lg={6} xl={4} xxl={2}>
                <TextValidator
                  lable={lang("ANNEX.SIGNINGDATE")}
                  name="ngayKyHopDong"
                  value={formik.values?.ngayKyHopDong || ""}
                  type="date"
                  readOnly={isView}
                  onBlur={() => handleBlurDate(formik.setFieldValue, formik.values?.ngayKyHopDong, "ngayKyHopDong")}
                  onChange={formik.handleChange}
                  errors={formik.errors?.ngayKyHopDong}
                  touched={formik.touched?.ngayKyHopDong}
                />
              </Col>
              <Col md={6} lg={6} xl={4} xxl={2}>
                <Autocomplete
                  menuPlacement="top"
                  lable={lang("CONTRACT.SIGNINGSTATUS")}
                  options={signinStatus}
                  value={formik.values?.trangThaiKy || null}
                  name="trangThaiKy"
                  onChange={(value) => handleChangeSelect(VARIABLE_STRING.TRANG_THAI_KY, value)}
                  isReadOnly={isView}
                />
              </Col>
            </Row>
          </div>
        </>
      </Form>
      <div className="form-info">
        <div className="block-content">
          <TableCustom
            id="contract"
            title={lang("INFO.CONTRACT.LIST")}
            data={contractList}
            columns={ContractColumn(lang)}
            isActionTableTab
            dependencies={activeTab}
            buttonAdd={!isView}
            notDelete={true}
            noToolbar={true}
            justFilter={true}
            type={TYPE.MULTILINE}
            fixedColumnsCount={4}
            totalPages={totalPage}
            totalElements={totalElements}
            numberOfElements={numberOfElements}
            handleOpenDialog={(row) => handleOpenDialog(contract, setShouldOpenContractDialog, setContract)}
            handleDoubleClick={(row) => handleOpenDialog(row, setShouldOpenContractDialog, setContract)}
            noPagination
          />
        </div>
      </div>
      {shouldOpenContractDialog && (
        <ContractDialog
          contractEdit={contract}
          listNguoiDaiDien={listNguoiDaiDien}
          handleCloseContractDialog={handleCloseContractDialog}
          isView={isView}
          shouldOpenContractDialog={shouldOpenContractDialog}
          fetchContractParent={fetchContract}
          employeeInfo={employeeProfiles}
        />
      )}
    </div>
  );
};
