import { GroupButton } from "../../component/GroupButton";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Button, Col, Form, Row } from "react-bootstrap";
import useMultiLanguage from "../../../hook/useMultiLanguage";
import { useContext, useEffect } from "react";
import AppContext from "../../../AppContext";
import TextValidator from "../../component/input-field/TextValidator";
import { RESPONSE_STATUS_CODE, TYPE, VARIABLE_STRING } from "../../utils/Constant";
import Autocomplete from "../../component/input-field/Autocomplete";
import { toast } from "react-toastify";
import { IDispatchBook } from "../models/dispatchBookModel";
import { DISPATCH_BOOK_FORMS, INIT_DISPATCH_BOOK } from "../constant/dispatchBookConst";
import { addNewDispatchBook, updateDispatchBook } from "../services/dispatchBookServices";
import { handleBlurDate } from "../../utils/FunctionUtils";

type IProps = {
  dataEdit: IDispatchBook;
  isView: boolean;
  setIsView: (value: boolean) => void;
  handleCloseDialog: () => void;
  handleCloseAndSearch: () => void;
  handleOpenUpdateDialog: () => void;
  handleCloseUpdateDialog: () => void;
};

function DispatchBookForm(props: IProps) {
  const { dataEdit,
    isView,
    handleCloseDialog,
    handleCloseAndSearch,
    handleCloseUpdateDialog,
    handleOpenUpdateDialog
  } = props;
  const { lang } = useMultiLanguage();
  const { setPageLoading } = useContext(AppContext);

  useEffect(() => {
    if (dataEdit?.id) {
      formik.setValues(dataEdit);
    }
  }, [dataEdit]);

  const handleSubmit = async (values: IDispatchBook) => {
    try {
      setPageLoading(true);
      const { data } = await (!dataEdit?.id ? addNewDispatchBook(values) : updateDispatchBook(values, dataEdit?.id));
      if (data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
        let message = dataEdit?.id ? "TOAST.PROBLEM.EDIT_SUCCESS" : "TOAST.PROBLEM.ADD_SUCCESS";
        toast.success(lang(message));
        handleCloseAndSearch();
      } else {
        toast.error(data?.message);
      }
    } catch (error) {
      toast.error(lang("GENERAL.ERROR"));
    } finally {
      setPageLoading(false);
    }
  };

  const validationSchema = Yup.object().shape({
    boPhanGui: Yup.string().required(lang("VALIDATION.REQUIRE")).nullable(),
    boPhanNhan: Yup.string().required(lang("VALIDATION.REQUIRE")).nullable(),
    dangCongVan: Yup.object().required(lang("VALIDATION.REQUIRE")).nullable(),
    noiDungCongVan: Yup.string().required(lang("VALIDATION.REQUIRE")).nullable(),
    ngayNhanGui: Yup.string().required(lang("VALIDATION.REQUIRE")).nullable(),
    nguoiGui: Yup.string().required(lang("VALIDATION.REQUIRE")).nullable(),
    nguoiNhan: Yup.string().required(lang("VALIDATION.REQUIRE")).nullable(),
    soBan: Yup.number().required(lang("VALIDATION.REQUIRE")).nullable(),
    soCongVan: Yup.string().required(lang("VALIDATION.REQUIRE")).nullable()
  });

  const formik = useFormik({
    initialValues: INIT_DISPATCH_BOOK,
    onSubmit: handleSubmit,
    validationSchema
  });

  const handleChangeSelect = (name: string, value: any) => {
    formik.setFieldValue(name, value);
  };

  return (
    <div className="problem-form">
      <Form onSubmit={formik.handleSubmit}>
        <Row className="contract-header header-bo spaces py-12">
          <Col xs={12}>
            <div className="profile-title">
              <GroupButton type="btn-back" handleClose={handleCloseDialog} />
              <div className="flex">
                {isView ? (
                  <GroupButton type="btn-edit" handleSaveEdit={handleOpenUpdateDialog} />
                ) : (
                  <>
                    {dataEdit?.id && (
                      <Button
                        variant="outline-secondary"
                        className="button-primary-outline me-2"
                        onClick={handleCloseUpdateDialog}
                        type="button"
                      >
                        {lang("BTN.CANCEL")}
                      </Button>
                    )}
                    <GroupButton type="btn-save" handleClose={handleCloseUpdateDialog} />
                  </>
                )}
              </div>
            </div>
          </Col>
        </Row>
        <div className="form-container bg-white">
          <Row className="p-4 w-100">
            <Col xs={12} className="relative z-index-2">
              <div className="sub-title-form">
                {dataEdit?.id ? lang("DISPATCH_BOOK.EDIT") : lang("DISPATCH_BOOK.ADD")}
              </div>
            </Col>
            <Col xs={6} md={4} lg={3} xxl={2} className="spaces pt-6">
              <TextValidator
                lable={lang("DISPATCH_BOOK.DAY")}
                name="ngayNhanGui"
                value={formik.values?.ngayNhanGui}
                type="date"
                readOnly={isView}
                onChange={formik.handleChange}
                onBlur={() => handleBlurDate(formik.setFieldValue, formik.values?.ngayNhanGui, "ngayNhanGui")}
                isRequired
                errors={formik.errors?.ngayNhanGui}
                touched={formik.touched?.ngayNhanGui}
              />
            </Col>
            <Col xs={6} md={4} lg={3} xxl={2} className="spaces pt-6">
              <Autocomplete
                lable={lang("DISPATCH_BOOK.FORM")}
                isReadOnly={isView}
                isRequired
                options={DISPATCH_BOOK_FORMS}
                name="dangCongVan"
                value={formik.values?.dangCongVan}
                onChange={(value) => handleChangeSelect(VARIABLE_STRING.DANG_CONG_VAN, value)}
                errors={formik.errors?.dangCongVan}
                touched={formik.touched?.dangCongVan}
              />
            </Col>
            <Col xs={6} md={4} lg={3} xxl={2} className="spaces pt-6">
              <TextValidator
                lable={lang("DISPATCH_BOOK.NUMBER_COP")}
                name="soBan"
                value={formik.values?.soBan}
                type="number"
                readOnly={isView}
                onChange={formik.handleChange}
                isRequired
                errors={formik.errors?.soBan}
                touched={formik.touched?.soBan}
              />
            </Col>
            <Col xs={6} md={4} lg={3} xxl={3} className="spaces pt-6">
              <TextValidator
                lable={lang("DISPATCH_BOOK.NUMBER_AND_DATE_RELEASE")}
                name="soCongVan"
                value={formik.values?.soCongVan}
                type="text"
                readOnly={isView}
                onChange={formik.handleChange}
                isRequired
                errors={formik.errors?.soCongVan}
                touched={formik.touched?.soCongVan}
              />
            </Col>
            <Col xs={6} md={4} lg={4} xxl={3} className="spaces pt-6">
              <TextValidator
                lable={lang("DISPATCH_BOOK.AGENCY_AND_SENDING_DEPARTMENT")}
                name="boPhanGui"
                value={formik.values?.boPhanGui}
                type="text"
                readOnly={isView}
                onChange={formik.handleChange}
                isRequired
                errors={formik.errors?.boPhanGui}
                touched={formik.touched?.boPhanGui}
              />
            </Col>
            <Col xs={6} md={4} lg={4} xxl={3} className="spaces pt-6">
              <TextValidator
                lable={lang("DISPATCH_BOOK.AGENCY_AND_RECEIVE_DEPARTMENT")}
                name="boPhanNhan"
                value={formik.values?.boPhanNhan}
                type="text"
                readOnly={isView}
                onChange={formik.handleChange}
                isRequired
                errors={formik.errors?.boPhanNhan}
                touched={formik.touched?.boPhanNhan}
              />
            </Col>
            <Col xs={6} md={4} lg={4} xxl={2} className="spaces pt-6">
              <TextValidator
                lable={lang("DISPATCH_BOOK.PEOPLE_SEND")}
                name="nguoiGui"
                value={formik.values?.nguoiGui}
                type="text"
                readOnly={isView}
                onChange={formik.handleChange}
                isRequired
                errors={formik.errors?.nguoiGui}
                touched={formik.touched?.nguoiGui}
              />
            </Col>
            <Col xs={6} md={4} lg={4} xxl={2} className="spaces pt-6">
              <TextValidator
                lable={lang("DISPATCH_BOOK.PEOPLE_RECEIVE")}
                name="nguoiNhan"
                value={formik.values?.nguoiNhan}
                type="text"
                readOnly={isView}
                onChange={formik.handleChange}
                isRequired
                errors={formik.errors?.nguoiNhan}
                touched={formik.touched?.nguoiNhan}
              />
            </Col>
            <Col xs={12} md={12} lg={8} xl={8} xxl={5} className="spaces pt-6">
              <TextValidator
                lable={lang("DISPATCH_BOOK.CONTENT")}
                name="noiDungCongVan"
                value={formik.values?.noiDungCongVan}
                type="text"
                readOnly={isView}
                onChange={formik.handleChange}
                isRequired
                errors={formik.errors?.noiDungCongVan}
                touched={formik.touched?.noiDungCongVan}
              />
            </Col>
            <Col xs={12} className="spaces pt-6">
              <TextValidator
                lable={lang("GENERAL.NOTE")}
                name="ghiChu"
                value={formik.values?.ghiChu}
                type="text"
                readOnly={isView}
                onChange={formik.handleChange}
                as={TYPE.TEXTAREA}
                rows={5}
                errors={formik.errors?.ghiChu}
                touched={formik.touched?.ghiChu}
              />
            </Col>
          </Row>
        </div>
      </Form>
    </div>
  );
}

export default DispatchBookForm;
