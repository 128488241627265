import { useFormik } from "formik";
import { IConcurrently } from "../models/concurrentlyModel";
import * as Yup from "yup";
import { Col, Row, Form } from "react-bootstrap";
import { GroupButton } from "../../component/GroupButton";
import Autocomplete from "../../component/input-field/Autocomplete";
import TextValidator from "../../component/input-field/TextValidator";
import { RangeDatePicker } from "../../component/input-field/RangeDatePicker";
import { INIT_CONCURRENTLY } from "../constants/concurrentlyConst";
import useMultiLanguage from "../../../hook/useMultiLanguage";
import { MISSION_STATUS, MISSION_STATUS_CODE, RESPONSE_STATUS_CODE, SEARCH_OBJECT_MAX_SIZE, TYPE, VARIABLE_STRING } from "../../utils/Constant";
import { TYPE_CATEGORY } from "../../constant";
import { getAllSimpleValue } from "../../services";
import { searchPhongBan_DonVi } from "../../profile/services/DialogServices";
import { handleBlurDate } from "../../utils/FunctionUtils";
import { searchAllEmployee } from "../../utils/CategoryServices";
import UploadFile from "../../component/FileUpload/UploadFile";
import { FILE_TYPE } from "../../component/FileUpload/constant";
import { IFile } from "../../profile/models/DialogModels";
import { EmployeeProfile } from "../../profile/models/ProfileModels";
import { useContext, useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";
import { getEmployeeProfileById } from "../../profile/services/ProfileServices";
import { checkInvalidDate } from "../../utils/ValidationSchema";
import AppContext from "../../../AppContext";
import { addConcurrently, updateConcurrently } from "../services/concurrentlyServices";
import { STATUS_NV } from "../../profile/const/DialogChildConstants";

interface IProps {
  concurrentlyInfo: IConcurrently;
  handleClose: () => void;
  handleCloseAndSearch: () => void;
  handleCloseUpdateDialog: () => void;
  isView: boolean;
  setIsView: (value: boolean) => void;
}

function ConcurrentlyForm(props: IProps) {
  const { concurrentlyInfo, handleClose, handleCloseAndSearch,  handleCloseUpdateDialog, isView, setIsView } = props;
  const { setPageLoading } = useContext(AppContext);
  const { lang, intl } = useMultiLanguage();
  const [isShowBtnSave, setIsShowBtnSave] = useState<boolean>(true);
  const [isAcceptConcurrently, setIsAcceptConcurrently] = useState<boolean>(Boolean(concurrentlyInfo?.xacNhanKiemNhiem));
  const isShowAcceptConcurrentlyBtn = useMemo(() => !concurrentlyInfo?.id || !concurrentlyInfo?.xacNhanKiemNhiem, [concurrentlyInfo?.id, concurrentlyInfo?.xacNhanKiemNhiem]);
  
  const validationSchema = Yup.object().shape({
    employee: Yup.object()
      .required(lang("VALIDATION.REQUIRE"))
      .nullable(),
    maNhanVien: Yup.string()
      .required(lang("VALIDATION.REQUIRE")),
    phongBanText: Yup.string().required(lang("VALIDATION.REQUIRE")),
    chucVuText: Yup.string().required(lang("VALIDATION.REQUIRE")),
    phongBanMoi: Yup.object().required(lang("VALIDATION.REQUIRE")).nullable(),
    chucVuMoi: Yup.object().required(lang("VALIDATION.REQUIRE")).nullable(),
    soQuyetDinh: Yup.string().required(lang("VALIDATION.REQUIRE")),
    tuNgay: Yup.date()
      .concat(checkInvalidDate(intl))
      .required(lang("VALIDATION.REQUIRE"))
      .nullable(),
    denNgay: Yup.date()
      .concat(checkInvalidDate(intl))
      .when("tuNgay", {
        is: (tuNgay: Date | null) => tuNgay,
        then: Yup.date()
          .min(
            Yup.ref("tuNgay"),
            lang("VALIDATION.MINDATE") +
            lang("GENERAL_EFFECTIVE_DATE_START")
          )
          .nullable()
      })
      .nullable(),
    trangThai: Yup.object().required(lang("VALIDATION.REQUIRE")).nullable(),
  });

  const convertDataSubmit = (data: IConcurrently) => {
    return {
      ...data,
      chucVuMoiId: data?.chucVuMoi?.id || "",
      chucVuMoiText: String(data.chucVuMoi?.value),
      phongBanMoiId: data?.phongBanMoi?.id || "",
      phongBanMoiText: data.phongBanMoi?.name || "",
      xacNhanKiemNhiem: isAcceptConcurrently
    }
  }
  
  const handleSubmit = async (values: IConcurrently) => {
    try {
      setPageLoading(true);
      const res = concurrentlyInfo.id
        ? await updateConcurrently(concurrentlyInfo.id, convertDataSubmit(values))
        : await addConcurrently(convertDataSubmit(values));
      if (res?.data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
        toast.success(
          concurrentlyInfo.id
            ? lang("TOAST.EDIT.SUCCESS")
            : lang("TOAST.CREATE.SUCCESS")
        );
        handleCloseAndSearch();
      } else {
        toast.error(`${res?.data?.message}`);
      }
    } catch (err) {
      toast.error(lang("GENERAL.ERROR"));
    } finally {
      setPageLoading(false)
    }
  };

  const formik = useFormik({
    initialValues: INIT_CONCURRENTLY,
    onSubmit: handleSubmit,
    validationSchema
  });
  
  const getEmployeeInfo = async (id: string) => {
    try {
      const { data } = await getEmployeeProfileById(id);
      if (data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
        formik.setFieldValue(VARIABLE_STRING.EMPLOYEE, data?.data);
      } else {
        toast.error(data?.message);
      }
    } catch (error) {
      console.error(error);
      toast.error(lang("GENERAL.ERROR"));
    }
  };

  useEffect(() => {
    if(!concurrentlyInfo?.id) {
      formik.setValues(INIT_CONCURRENTLY);
    } else {
      let values = {
        ...concurrentlyInfo,
        phongBanMoi: concurrentlyInfo?.phongBanMoiId ? {
          id: concurrentlyInfo?.phongBanMoiId,
          name: concurrentlyInfo?.phongBanMoiText
        } : null,
        chucVuMoi: concurrentlyInfo?.chucVuMoiId ? {
          id: concurrentlyInfo?.chucVuMoiId,
          value: concurrentlyInfo?.chucVuMoiText
        } : null
      }
      formik.setValues(values);
    }
    if (concurrentlyInfo?.employeeId) {
      getEmployeeInfo(concurrentlyInfo.employeeId);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [concurrentlyInfo]);

  const handleChangeSelect = (name: string, value: any) => {
    formik.setFieldValue(name, value);
  };

  const handleEmployeeChange = (employee: EmployeeProfile) => {
    let values: IConcurrently = {
      ...formik.values,
      employee,
      name: employee?.name || "",
      employeeId: employee?.id || "",
      maNhanVien: employee?.maNhanVien || "",
      phongBanId: employee?.phongBanId || "",
      phongBanText: employee?.phongBanText || "",
      chucVuId: employee?.chucVuId || "",
      chucVuText: employee?.chucVuText || "",
    };
    formik.setValues(values);
  };

  const handleFile = (data: IFile) => {
    formik.setFieldValue("fileId", data.id);
    formik.setFieldValue("fileName", data.name);
  };

  const handleAcceptConcurrently = () => {
    setIsAcceptConcurrently(true);
    formik.handleSubmit();
  }

  const handleChangeDate = (event: React.ChangeEvent<any>) => {
    const { name, value } = event.target;
    formik.setFieldValue(name, value);
    if (name === VARIABLE_STRING.TU_NGAY) {
      const effectiveDate = value ? new Date(value) : null;
      const currentDate = new Date();
      if (effectiveDate && effectiveDate <= currentDate) {
        setIsShowBtnSave(false);
      } else setIsShowBtnSave(true);
    }
  }

  return (
    <div className="addNewContract h-full">
      <Row className="contract-header header-box h-full">
        <Col xs={12}>
          <div className="profile-title spaces py-12">
            <GroupButton
              type="btn-back"
              handleClose={handleClose}
            />
            <div className="flex gap-4">
              {(!isView && concurrentlyInfo?.trangThai?.code === MISSION_STATUS_CODE.UNFULFILLED && concurrentlyInfo?.id) && (
                <GroupButton
                  type="btn-cancel"
                  handleCancel={handleCloseUpdateDialog}
                />
              )}
              {isView && concurrentlyInfo?.id && concurrentlyInfo?.trangThai?.code !== MISSION_STATUS_CODE.COMPLETED && !concurrentlyInfo.xacNhanKiemNhiem && (
                <GroupButton
                  type="btn-edit"
                  handleSaveEdit={() => setIsView(false)}
                />
              )}
              {(!isView && isShowBtnSave && (concurrentlyInfo?.trangThai?.code === MISSION_STATUS_CODE.UNFULFILLED || !concurrentlyInfo?.id)) && (
                <GroupButton
                  type="btn-save"
                  handleSubmit={formik.handleSubmit}
                  value={formik.values}
                />
              )}
              {isShowAcceptConcurrentlyBtn && (
                <button className="button-primary" type="button" onClick={handleAcceptConcurrently}>{lang("CONCURRENTLY.ACCEPT")}</button>
              )}
            </div>
          </div>
        </Col>
      </Row>

      <div className="layout-v2 flex align-items-start">
        <div className="flex-1 overflow-auto h-100">
          <Form onSubmit={formik.handleSubmit} className="form-container add-contract">
            <div className="sub-title-form">{concurrentlyInfo?.id ? lang("CONCURRENTLY.EDIT") : lang("CONCURRENTLY.ADD")}</div>
            <div className="sub-title pb-2 pt-0">{lang("APPOINTMENT_INFO_EMPLOYEE")}</div>
            <Row className="g-4">
              <Col sm={4} md={4} lg={4} xl={3} xxl={3}>
                <Autocomplete
                  isRequired
                  lable={lang("GENERAL.FULLNAME")}
                  options={[]}
                  className="spaces"
                  onChange={(value) => handleEmployeeChange(value)}
                  name="employee"
                  value={formik.values?.employee || null}
                  searchFunction={searchAllEmployee}
                  searchObject={{ 
                    ...SEARCH_OBJECT_MAX_SIZE,
                    trangThaiLamViec: Object.values(STATUS_NV).filter(status => status !== STATUS_NV.THOI_VIEC).join(',') 
                  }}
                  isReadOnly={isView}
                  errors={formik.errors.employee}
                  touched={formik.touched.employee}
                  getOptionLabel={(option) => `${option?.maNhanVien} - ${option?.name}`}
                />
              </Col>
              <Col sm={3} md={3} lg={3} xl={2} xxl={2}>
                <TextValidator
                  name="maNhanVien"
                  lable={lang("INPUT_APPOINTMENT_CODE")}
                  type="text"
                  value={formik.values?.maNhanVien}
                  readOnly={true}
                  errors={formik.errors?.maNhanVien}
                  touched={formik.touched?.maNhanVien}
                  isRequired
                />
              </Col>
              <Col sm={6} md={5} lg={5} xl={4} xxl={3}>
                <TextValidator
                  name="phongBanText"
                  lable={lang("INPUT_APPOINTMENT_UNIT_DEPARTMENT")}
                  type="text"
                  value={formik.values?.phongBanText}
                  readOnly={true}
                  isRequired
                  errors={formik.errors?.phongBanText}
                  touched={formik.touched?.phongBanText}
                />
              </Col>
              <Col sm={6} md={5} lg={5} xl={3} xxl={4}>
                <TextValidator
                  name="chucVuText"
                  lable={lang("INPUT_APPOINTMENT_JOB_POSITION")}
                  type="text"
                  value={formik.values?.chucVuText}
                  readOnly={true}
                  isRequired
                  errors={formik?.errors?.chucVuText}
                  touched={formik?.touched?.chucVuText}
                />
              </Col>
            </Row>
            <div className="sub-title pb-2">{lang("CONCURRENTLY.INFOMATION")}</div>
            <Row className="g-4">
              <Col sm={6} md={5} lg={5} xl={5} xxl={4}>
                <Autocomplete
                  lable={lang("INPUT_APPOINTMENT_UNIT_DEPARTMENT_NEW")}
                  isReadOnly={isView}
                  searchFunction={searchPhongBan_DonVi}
                  searchObject={{ id: formik.values?.employee?.donViCongTacId }}
                  options={[]}
                  isRequired
                  value={formik.values?.phongBanMoi}
                  name={VARIABLE_STRING.PHONG_BAN_MOI}
                  onChange={(selectedOption) => handleChangeSelect(VARIABLE_STRING.PHONG_BAN_MOI, selectedOption)}
                  urlData="data.data"
                  errors={formik.errors?.phongBanMoi}
                  touched={formik.touched?.phongBanMoi}
                  dependencies={[formik.values?.employee?.donViCongTacId]}
                />
              </Col>
              <Col sm={6} md={4} lg={4} xl={4} xxl={3}>
                <Autocomplete
                  lable={lang("INPUT_APPOINTMENT_NEW_JOB_POSITION_NEW")}
                  isReadOnly={isView}
                  searchFunction={getAllSimpleValue}
                  searchObject={TYPE_CATEGORY.chucDanh}
                  options={[]}
                  isRequired
                  value={formik.values?.chucVuMoi}
                  name={VARIABLE_STRING.CHUC_VU_MOI}
                  onChange={(selectedOption) => handleChangeSelect(VARIABLE_STRING.CHUC_VU_MOI, selectedOption)}
                  errors={formik.errors?.chucVuMoi}
                  touched={formik.touched?.chucVuMoi}
                  getOptionLabel={(option) => option?.value}
                />
              </Col>
              <Col sm={6} md={3} lg={3} xl={3} xxl={2}>
                <Autocomplete
                  lable={lang("GENERAL.STATUS")}
                  isRequired
                  isReadOnly={isView}
                  options={MISSION_STATUS}
                  value={formik.values?.trangThai || null}
                  name="trangThai"
                  onChange={(selectedOption) => handleChangeSelect(VARIABLE_STRING.TRANG_THAI, selectedOption)}
                  errors={formik.errors?.trangThai}
                  touched={formik.touched?.trangThai}
                />
              </Col>
              <Col sm={4} md={3} lg={3} xl={2} xxl={3}>
                <TextValidator
                  name="soQuyetDinh"
                  lable={lang("INPUT_APPOINTMENT_NUMBER")}
                  type="text"
                  value={formik.values?.soQuyetDinh}
                  readOnly={isView}
                  isRequired
                  onChange={formik.handleChange}
                  errors={formik?.errors?.soQuyetDinh}
                  touched={formik?.touched?.soQuyetDinh}
                />
              </Col>
              <Col sm={6} md={3} lg={2} xl={3} xxl={2}>
                <TextValidator
                  name="ngayQuyetDinh"
                  lable={lang("INPUT_APPOINTMENT_CONFIRM_DATE")}
                  type="date"
                  value={formik.values?.ngayQuyetDinh}
                  readOnly={isView}
                  onChange={formik.handleChange}
                  onBlur={() => handleBlurDate(formik.setFieldValue, formik.values?.ngayQuyetDinh, "ngayQuyetDinh")}
                  errors={formik?.errors?.ngayQuyetDinh}
                  touched={formik?.touched?.ngayQuyetDinh}
                />
              </Col>
              <Col sm={6} md={3} lg={4} xl={4} xxl={4}>
                <RangeDatePicker
                  label={lang("CONCURRENTLY.EFFECTIVE.TIME")}
                  startDateName="tuNgay"
                  endDateName="denNgay"
                  isView={isView}
                  handleChange={handleChangeDate}
                  value={formik.values}
                  setFieldValue={formik.setFieldValue}
                  touch={formik.touched}
                  errors={formik.errors}
                  isRequired
                />
              </Col>
              <Col sm={6} md={3} lg={3} xl={3} xxl={3}>
                <UploadFile
                  label="INPUT_APPOINTMENT_ATTACHMENTS"
                  setValue={handleFile}
                  isReadOnly={isView}
                  fileValue={{
                    id: formik.values?.fileId || "",
                    name: formik.values?.fileName || ""
                  }}
                  allowFileTypes={`${FILE_TYPE.PDF},${FILE_TYPE.DOC},${FILE_TYPE.DOCX},${FILE_TYPE.XLS},${FILE_TYPE.XLSX}`}
                />
              </Col>
              <Col xs={12}>
                <TextValidator
                  className="h-auto-input spaces"
                  lable={lang("ALLOWANCE.NOTE")}
                  name="ghiChu"
                  value={formik.values.ghiChu}
                  type="text"
                  readOnly={isView}
                  rows={5}
                  onChange={formik.handleChange}
                  as={TYPE.TEXTAREA}
                />
              </Col>
            </Row>
          </Form>
        </div>
      </div>
    </div>
  );
}

export default ConcurrentlyForm;
