import { IDismissed } from "../model/IDismissed";

export const convertDataUI = (data: IDismissed) => {
  let dataConvert = {
    ...data,
    donViCongTacMoi: data?.donViCongTacMoiId
      ? {
        id: data?.donViCongTacMoiId,
        name: data?.donViCongTacMoiText
      }
      : null,
    phongBanMoi: data?.phongBanMoiId
      ? {
        id: data?.phongBanMoiId,
        name: data?.phongBanMoiText
      }
      : null,
    chucVuMoi: data?.chucVuMoiId
      ? {
        id: data?.chucVuMoiId,
        value: data?.chucVuMoiText
      }
      : null,
    employee: data?.employeeId
      ? {
        id: data?.employeeId,
        name: data?.fullName
      }
      : null,
  };

  return dataConvert;
};