/* eslint-disable react-hooks/exhaustive-deps */
import { useFormik } from "formik";
import { FC, useContext, useEffect, useMemo } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import * as Yup from "yup";
import useMultiLanguage from "../../../../hook/useMultiLanguage";
import UploadFile from "../../../component/FileUpload/UploadFile";
import { FILE_TYPE } from "../../../component/FileUpload/constant";
import { GroupButton } from "../../../component/GroupButton";
import Autocomplete from "../../../component/input-field/Autocomplete";
import TextValidator from "../../../component/input-field/TextValidator";
import { SUCCESS_CODE } from "../../../contract/const/ContractConst";
import { IFile } from "../../../profile/models/DialogModels";
import { searchAllEmployee } from "../../../utils/CategoryServices";
import { MISSION_STATUS_CODE, RESPONSE_STATUS_CODE, SEARCH_OBJECT_MAX_SIZE, VARIABLE_STRING } from "../../../utils/Constant";
import { convertNumberPrice, convertTextPrice, handleBlurDate, useCustomIntl } from "../../../utils/FunctionUtils";
import { INIT_INTERRUPT_INFO, listInterruptType } from "../../const/InterruptConst";
import { INSURANCE_TYPE_CODE, listFormOfAdjustment, listResignTypeInsurance } from "../../const/ResignConst";
import { InterruptInfo } from "../../models/IInterrupt";
import { addNewInterrupt, editInterrupt } from "../../services/InterruptServices";
import { getEmployeeProfileById } from "../../../profile/services/ProfileServices";
import { searchNguoiDaiDienKy } from "../../../contract/services/contractServices";
import { checkInvalidDate } from "../../../utils/ValidationSchema";
import { OptionReactSelect } from "../../../models/models";
import AppContext from "../../../../AppContext";

interface IProps {
  handleClose: () => void;
  handleOpenUpdateDialog: () => void;
  handleCloseUpdateDialog: () => void;
  interruptInfo: InterruptInfo;
  listInterrupt: InterruptInfo[];
  isView: boolean;
}

const InterruptDialog: FC<IProps> = ({ handleClose, handleCloseUpdateDialog, handleOpenUpdateDialog, interruptInfo, listInterrupt, isView }) => {
  const { lang, intl } = useMultiLanguage();
  const { setPageLoading } = useContext(AppContext);

  const isUnFinished = useMemo(() => !interruptInfo?.trangThai || interruptInfo?.trangThai?.code === MISSION_STATUS_CODE.UNFULFILLED, [interruptInfo?.trangThai]);

  const validationSchema = Yup.object().shape({
    employee: Yup.object().required(lang("VALIDATION.REQUIRE")).nullable(),
    loaiNghi: Yup.object().required(lang("VALIDATION.REQUIRE")).nullable(),
    ngayBatDau: Yup.date()
      .when("ngayQuayLaiLV", {
        is: (ngayQuayLaiLV: Date | null) => ngayQuayLaiLV,
        then: Yup.date()
          .max(
            Yup.ref("ngayQuayLaiLV"),
            lang("VALIDATION.MAXDATE") +
            lang("TIME_KEEPING.RETURN_DATE")
          )
          .nullable()
      })
      .required(lang("VALIDATION.REQUIRE"))
      .nullable(),
    ngayQuayLaiLV: Yup.date()
      .required(lang("VALIDATION.REQUIRE"))
      .nullable(),
    soQuyetDinh: Yup.string()
      .max(255, lang("VALIDATION.MAX255"))
      .nullable(),
    nguoiKy: Yup.object().required(lang("VALIDATION.REQUIRE")).nullable(),
    trangThaiBaoHiem: Yup.object().required(lang("VALIDATION.REQUIRE")).nullable(),
    thoiGianThucHien: Yup.date()
      .concat(checkInvalidDate(intl))
      .required(lang("VALIDATION.REQUIRE")).nullable(),
    thoiGianDungDongBH: Yup.date()
      .when("trangThaiBaoHiem", {
        is: (trangThaiBaoHiem: OptionReactSelect | null) => trangThaiBaoHiem && trangThaiBaoHiem?.code === INSURANCE_TYPE_CODE.DUNG_DONG,
        then: Yup.date()
          .required(lang("VALIDATION.REQUIRE"))
          .nullable()
      })
      .nullable(),
  });
  
  const handleSubmit = async (values: InterruptInfo) => {
    try {
      setPageLoading(true);
      let _values = convertDataSubmit(values);
      const res = interruptInfo?.id
        ? await editInterrupt(interruptInfo?.id, _values)
        : await addNewInterrupt(_values);
      if (res.data.code === SUCCESS_CODE) {
        toast.success(
          interruptInfo.id
            ? lang("TOAST.EDIT.SUCCESS")
            : lang("TOAST.CREATE.SUCCESS")
        );
        handleClose();
      } else {
        toast.error(`${res.data.message}`);
      }
    } catch (error) {
      toast.error(lang("GENERAL.ERROR"));
    } finally {
      setPageLoading(false);
    }
  };

  const convertDataSubmit = (values: InterruptInfo) => {
    return{
      ...values,
      name: values.employee?.name,
      maNhanVien: values.employee?.maNhanVien,
      phongBanId: values.employee?.phongBanId,
      phongBanText: values.employee?.phongBanText,
      chucVuId: values.employee?.chucVuId,
      chucVuText: values.employee?.chucVuText,
      viTriCongViecId: values.employee?.viTriCongViecId,
      viTriCongViecText: values.employee?.viTriCongViecText,
      nguoiKyId: values.nguoiKy?.id,
      nguoiKyText: values.nguoiKy?.name,
    }
  }

  useEffect(() => {
    if (interruptInfo?.employeeId) {
      getEmployeeInfo(interruptInfo.employeeId);
    }
  }, [interruptInfo]);

  const getEmployeeInfo = async (id: string) => {
    try {
      const { data } = await getEmployeeProfileById(id);
      if (data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
        formik.setFieldValue(VARIABLE_STRING.EMPLOYEE, data?.data);
      }
    } catch (error) {
      toast.error(lang("GENERAL.ERROR"));
    }
  };

  const formik = useFormik<InterruptInfo>({
    initialValues: interruptInfo?.id ? {...interruptInfo, nguoiKy: {
      id: interruptInfo?.nguoiKyId,
      name: interruptInfo?.nguoiKyText
    }} : INIT_INTERRUPT_INFO,
    validationSchema,
    onSubmit: handleSubmit
  })

  const handleChangeSelect = (name: string, value: any) => {
    formik.setFieldValue(name, value);
  };

  const handleFile = (data: IFile) => {
    formik.setFieldValue("fileId", data.id);
    formik.setFieldValue("fileName", data.name);
  }

  const handleChangeEmployee = (employee: any) => {
    let values: InterruptInfo = {
      ...formik.values,
      employee: employee,
      employeeCode: employee?.maNhanVien,
      employeeId: employee?.id,
      viTriCongViec: employee?.viTriCongViecText
        ? {
          id: employee?.viTriCongViecId,
          value: employee?.viTriCongViecText
        }
        : null,
      phongBan: employee?.phongBanText
        ? {
          id: employee?.phongBanId,
          name: employee?.phongBanText
        }
        : null,
      chucVu: employee?.chucVuText
        ? {
          id: employee?.chucVuId,
          value: employee?.chucVuText
        }
        : null
    };
    formik.setValues(values);
  };

  const handleChangeMoney = (event: React.ChangeEvent<any>) => {
    const { name, value } = event.target;
    formik.setFieldValue(name, convertTextPrice(value));
  };

  return (
    <div className="h-full">
      <Row className="header-box sp">
        <Col xs={12}>
          <div className="profile-title spaces py-12">
            <GroupButton type="btn-back" handleClose={handleClose} />
            <div className="flex gap-4">
              {(!isView && isUnFinished && interruptInfo?.id) && (
                <Button
                  variant="outline-secondary"
                  className="button-secondary btn-sm btn"
                  onClick={handleCloseUpdateDialog}
                >
                  {lang("BTN.CANCEL")}
                </Button>
              )}
              {isView && interruptInfo?.id && isUnFinished && (
                <GroupButton
                  type="btn-edit"
                  handleSaveEdit={handleOpenUpdateDialog}
                />
              )}
              {!isView && isUnFinished && (
                <GroupButton
                  type="btn-save"
                  handleClose={handleCloseUpdateDialog}
                  handleSubmit={formik.handleSubmit}
                  value={formik.values}
                />
              )}
            </div>
          </div>
        </Col>
      </Row>

      <Form onSubmit={formik.handleSubmit} className="form-container bg-white">
        <div className="sub-title-form ">{interruptInfo?.id ? lang("INTERRUPT.EDIT") : lang("INTERRUPT.ADD")}</div>
        <div className="sub-title p-0">
          {lang("PROFILE.TITLE")}
        </div>
        <Row>
          <Col xs={6} md={4} lg={4} xxl={3} className="pt-3">
            <Autocomplete
              isRequired
              lable={lang("GENERAL.FULLNAME")}
              options={[]}
              onChange={(value) => handleChangeEmployee(value)}
              name="employee"
              value={formik.values?.employee || null}
              searchFunction={searchAllEmployee}
              searchObject={{ ...SEARCH_OBJECT_MAX_SIZE, isDangLamViec: true }}
              isReadOnly={isView}
              errors={formik.errors.employee}
              touched={formik.touched.employee}
              getOptionLabel={(option) => `${option?.maNhanVien} - ${option?.name}`}
            />
          </Col>
          <Col xs={6} md={4} lg={3} xxl={3} className="pt-3">
            <TextValidator
              lable={lang("RESIGN.EMPLOYEECODE")}
              name="maNhanVien"
              type="text"
              readOnly={true}
              value={formik?.values?.employee?.maNhanVien || ""}
            />
          </Col>
          <Col xs={6} md={4} lg={5} xxl={3} className="pt-3">
            <TextValidator
              lable={lang("INPUT.DEPARTMENTS")}
              name="phongBan"
              value={formik.values?.employee?.phongBanText || ""}
              type="text"
              readOnly={true}
            />
          </Col>
          <Col xs={6} md={4} lg={4} xxl={3} className="pt-3">
            <TextValidator
              lable={lang("JOB.POSITION")}
              name="chucVu"
              value={formik.values?.employee?.chucVuText || ""}
              type="text"
              readOnly={true}
            />
          </Col>
          <Col xs={6} md={4} lg={4} xxl={3} className="pt-3">
            <TextValidator
              lable={lang("GENERAL.EMPLOYEE.JOB_POSITION")}
              name="viTriCongViec"
              type="text"
              value={formik.values?.employee?.viTriCongViecText || ""}
              readOnly={true}
            />
          </Col>
        </Row>
        <div className="sub-title">
          {lang("INTERRUPT.TITLE")}
        </div>
        <Row>
          <Col xs={6} md={4} xl={3} xxl={2} className="pt-3">
            <Autocomplete
              isRequired
              lable={lang("RESIGN.TYPE_BREAK")}
              options={listInterruptType}
              onChange={(value) =>
                handleChangeSelect(VARIABLE_STRING.LOAI_NGHI, value)
              }
              name="loaiNghi"
              value={formik.values?.loaiNghi || null}
              isReadOnly={isView}
              errors={formik.errors?.loaiNghi}
              touched={formik.touched?.loaiNghi}
            />
          </Col>
          <Col xs={6} md={4} xl={3} xxl={2} className="pt-3">
            <TextValidator
              isRequired
              lable={useCustomIntl("TIME_KEEPING.START_DATE")}
              name="ngayBatDau"
              value={formik.values?.ngayBatDau || ""}
              type="date"
              readOnly={isView}
              onChange={formik.handleChange}
              errors={formik.errors?.ngayBatDau}
              touched={formik.touched?.ngayBatDau}
            />
          </Col>
          <Col xs={6} md={4} xl={3} xxl={2} className="pt-3">
            <TextValidator
              isRequired
              lable={useCustomIntl("TIME_KEEPING.RETURN_DATE")}
              name="ngayQuayLaiLV"
              value={formik.values?.ngayQuayLaiLV || ""}
              type="date"
              readOnly={isView}
              onChange={formik.handleChange}
              errors={formik.errors?.ngayQuayLaiLV}
              touched={formik.touched?.ngayQuayLaiLV}
            />
          </Col>
          <Col xs={6} md={4} xl={3} xxl={2} className="pt-3">
            <TextValidator
              lable={lang("INPUT.WORKPROCESS.DECISION.NUMBER")}
              name="soQuyetDinh"
              type="text"
              readOnly={isView}
              value={formik?.values?.soQuyetDinh || ""}
              onChange={formik?.handleChange}
            />
          </Col>
          <Col xs={6} md={4} xl={3} xxl={2} className="pt-3">
            <Autocomplete
              isRequired
              lable={lang("APPRECIATIVE.PERSON_SINGING")}
              isReadOnly={isView}
              options={[]}
              value={formik.values?.nguoiKy || null}
              name="nguoiKy"
              onChange={(selectedOption) =>
                handleChangeSelect("nguoiKy", selectedOption)
              }
              searchFunction={searchNguoiDaiDienKy}
              searchObject={{}}
              touched={formik.touched?.nguoiKy}
              errors={formik.errors?.nguoiKy}
            />
          </Col>
          <Col xs={6} md={4} xl={3} xxl={2} className="pt-3">
            <UploadFile
              isReadOnly={isView}
              label="INPUT.QUALIFICATION.FILE"
              setValue={handleFile}
              fileValue={{
                id: formik.values.fileId || "",
                name: formik.values.fileName || ""}}
              allowFileTypes={`${FILE_TYPE.PDF},${FILE_TYPE.DOC},${FILE_TYPE.DOCX},${FILE_TYPE.XLS},${FILE_TYPE.XLSX}`}
            />
          </Col>
          <Col xs={12} xl={6} xxl={12} className="pt-3">
            <TextValidator
              lable={useCustomIntl("GENERAL.EMPLOYEE.RESIGN_REASON")}
              name="lyDoNghi"
              value={formik.values?.lyDoNghi || ""}
              as="textarea"
              type="text"
              rows="2"
              readOnly={isView}
              onChange={formik.handleChange}
              errors={formik.errors?.lyDoNghi}
              touched={formik.touched?.lyDoNghi}
            />
          </Col>
        </Row>
        <div className="sub-title">
          {lang("INPUT.RETIRE_INFO.INSURANCE_INFO")}
        </div>
        <Row>
          <Col xs={6} md={4} lg={3} xxl={2} className="pt-3">
            <Autocomplete
              isRequired
              lable={lang("INPUT.RETIRE_INFO.INSURANCE_STATUS")}
              options={listResignTypeInsurance}
              onChange={(value) =>
                handleChangeSelect(VARIABLE_STRING.TRANG_THAI_BAO_HIEM, value)
              }
              menuPlacement="top"
              name="trangThaiBaoHiem"
              value={formik.values?.trangThaiBaoHiem || null}
              isReadOnly={isView}
              errors={formik.errors?.trangThaiBaoHiem}
              touched={formik.touched?.trangThaiBaoHiem}
            />
          </Col>
          <Col xs={6} md={4} lg={3} xxl={2} className="pt-3">
            <TextValidator
              isRequired={formik.values?.trangThaiBaoHiem?.code === INSURANCE_TYPE_CODE.DUNG_DONG}
              lable={lang("INPUT.INSURANCE.STOP_TIME")}
              name="thoiGianDungDongBH"
              value={formik.values?.thoiGianDungDongBH || ""}
              type="date"
              readOnly={isView}
              onChange={formik.handleChange}
              onBlur={() =>
                handleBlurDate(
                  formik.setFieldValue,
                  formik.values?.thoiGianDungDongBH,
                  "thoiGianDungDongBH"
                )
              }
              errors={formik.errors?.thoiGianDungDongBH}
              touched={formik.touched?.thoiGianDungDongBH}
            />
          </Col>
          <Col xs={6} md={4} lg={3} xxl={2} className="pt-3">
            <Autocomplete
              lable={lang("INPUT.INSURANCE.ADJUST")}
              options={listFormOfAdjustment}
              onChange={(value) =>
                handleChangeSelect(VARIABLE_STRING.HINH_THUC_DIEU_CHINH, value)
              }
              menuPlacement="top"
              name="hinhThucDieuChinh"
              value={formik.values?.hinhThucDieuChinh || null}
              isReadOnly={isView}
            />
          </Col>
          <Col xs={6} md={4} lg={3} xxl={2} className="pt-3">
            <TextValidator
              isRequired
              lable={lang("IMPLEMENTATION.TIME")}
              name="thoiGianThucHien"
              value={formik.values?.thoiGianThucHien || ""}
              type="date"
              readOnly={isView}
              onChange={formik.handleChange}
              onBlur={() =>
                handleBlurDate(
                  formik.setFieldValue,
                  formik.values?.thoiGianThucHien,
                  "thoiGianThucHien"
                )
              }
              errors={formik.errors?.thoiGianThucHien}
              touched={formik.touched?.thoiGianThucHien}
            />
          </Col>
          <Col xs={6} md={4} lg={3} xxl={2} className="pt-3">
            <TextValidator
              lable={lang("INTERRUPT.INSURANCE_MONEY")}
              name="soTienBhxhThanhToan"
              value={convertNumberPrice(formik.values?.soTienBhxhThanhToan || "")}
              type="text"
              readOnly={isView}
              onChange={handleChangeMoney}
              errors={formik.errors?.soTienBhxhThanhToan}
              touched={formik.touched?.soTienBhxhThanhToan}
            />
          </Col>
        </Row>
      </Form>
    </div>
  );
};
export { InterruptDialog };
