import { useFormik } from "formik";
import { FC } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import * as Yup from "yup";
import useMultiLanguage from "../../../../hook/useMultiLanguage";
import TextValidator from "../../../component/input-field/TextValidator";
import { ReceivableInfo } from "../../models/IResign";
import { convertNumberPrice, convertTextPrice, generateRandomId } from "../../../utils/FunctionUtils";
interface IProps {
  handleClose: () => void;
  receivableInfo: ReceivableInfo;
  isView?: boolean;
  handleDialogSubmit: (value: ReceivableInfo) => void;
}
const ReceivableDialog: FC<IProps> = (props) => {
  const { lang } = useMultiLanguage();
  const { handleClose, isView = false, receivableInfo, handleDialogSubmit } = props;

  const validationSchema = Yup.object().shape({
    khoanThu: Yup.string()
      .required(lang("VALIDATION.REQUIRE"))
      .nullable(),
    giaTri: Yup.number()
      .required(lang("VALIDATION.REQUIRE"))
      .nullable(),
  });

  const handleSubmit = async (values: ReceivableInfo) => {
    let _values = {
      ...values,
      ...((!values?.id) && {
        id: generateRandomId()
      })
    }
    handleDialogSubmit(_values);
    handleClose();
  };

  const formik = useFormik<ReceivableInfo>({
    initialValues: receivableInfo,
    validationSchema,
    onSubmit: handleSubmit
  })

  const handleChangeMoney = (event: React.ChangeEvent<any>) => {
    const { name, value } = event.target;
    formik.setFieldValue(name, Number(convertTextPrice(value)));
  };

  return (
    <>
      <Modal
        show={true}
        size="xl"
        centered
        aria-labelledby="example-custom-modal-styling-title"
        onHide={handleClose}
        className="custom-modal"
      >
        <Form onSubmit={formik.handleSubmit}>
          <Modal.Header closeButton>
            <Modal.Title
              id="example-custom-modal-styling-title"
              className="heading-5"
            >
              {lang(!receivableInfo?.id ? "RECEIVABLE.ADD" : "RECEIVABLE.EDIT")}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col xs={12}>
                <Row className="px-6 ">
                  <Col xs={4}>
                    <TextValidator
                      isRequired
                      lable={lang("GENERAL.RECEIVABLES")}
                      name="khoanThu"
                      type="text"
                      readOnly={isView}
                      onChange={formik.handleChange}
                      value={formik?.values?.khoanThu || ""}
                      errors={formik?.errors?.khoanThu}
                      touched={formik.touched?.khoanThu}
                    />
                    <TextValidator
                      isRequired
                      className="spaces mt-16"
                      name="giaTri"
                      lable={lang("GENERAL.ALLOWANCE.VALUE")}
                      type="text"
                      readOnly={isView}
                      onChange={handleChangeMoney}
                      value={convertNumberPrice(formik.values?.giaTri || null)}
                      errors={formik?.errors?.giaTri}
                      touched={formik?.touched?.giaTri}
                    />
                  </Col>
                  <Col xs={8}>
                    <TextValidator
                      className="receivable-note"
                      lable={lang("INPUT.QUALIFICATION.NOTE")}
                      name="ghiChu"
                      type="text"
                      as="textarea"
                      rows="4"
                      value={formik.values?.ghiChu}
                      readOnly={isView}
                      onChange={formik.handleChange}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Modal.Body>
          {!isView && (
            <Modal.Footer className="flex-center">
              <Button
                variant="outline-secondary"
                className="button-secondary btn-sm"
                onClick={() => handleClose()}
              >
                {lang("BTN.CANCEL")}
              </Button>

              <Button
                variant="primary"
                className="button-primary btn-sm"
                type="submit"
              >
                {lang("BTN.SAVE")}
              </Button>
            </Modal.Footer>
          )}
        </Form>
      </Modal>
    </>
  );
};
export { ReceivableDialog };

