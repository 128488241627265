import { useFormik } from "formik";
import { FC, useEffect } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import * as Yup from "yup";
import { IRole } from "../../model/ManagerModel";
import { addNewRole, getListAuth, updateRole } from "../../services/ManagerServices";
import { SUCCESS_CODE } from "../../../contract/const/ContractConst";
import { toast } from "react-toastify";
import useMultiLanguage from "../../../../hook/useMultiLanguage";
import { INIT_ROLE } from "../const/ManagerCost";
import TextValidator from "../../../component/input-field/TextValidator";
import Autocomplete from "../../../component/input-field/Autocomplete";
import { heightSelectMutil } from "../../../component/input-field/StyleComponent";
interface IProps {
  handleCloseDialog: () => void;
  roleInfo: IRole;
  handleReload: () => Promise<void>;
}
const RoleDialog: FC<IProps> = (props) => {
  const { handleCloseDialog, roleInfo, handleReload } = props;
  const { lang } = useMultiLanguage();

  useEffect(() => {
    if (roleInfo?.id) {
      let authoritieConverted = roleInfo?.authorities?.map((item) => ({name: item}));
      formik.setValues({
        ...roleInfo,
        authorities: authoritieConverted || []
      });
    }
  }, [roleInfo]);

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(lang("VALIDATION.REQUIRE")).nullable(),
    authorities: Yup.array().of(Yup.object()).required(lang("VALIDATION.REQUIRE")).nullable()
  });

  const handleSubmit = async (values: IRole) => {
    try {
      let authorities = values?.authorities ? values?.authorities?.map((item: any) => item?.name) : [];
      let dataSubmit = { ...values, authorities };
      const res = roleInfo.id ? await updateRole(roleInfo.id, dataSubmit) : await addNewRole(dataSubmit);
      if (res?.data?.code === SUCCESS_CODE) {
        let message = roleInfo?.id ? "TOAST.EDIT.SUCCESS" : "TOAST.CREATE.SUCCESS";
        toast.success(lang(message));
        handleReload();
        handleCloseDialog();
      } else {
        toast.error(`${res?.data?.message}`);
      }
    } catch (err) {
      toast.error(lang("GENERAL.ERROR"));
    }
  };

  const formik = useFormik({
    initialValues: INIT_ROLE,
    onSubmit: handleSubmit,
    validationSchema
  });

  const handleChangeSelect = (name: string, value: any) => {
    formik.setFieldValue(name, value);
  };
  
  return (
    <Modal
      show={true}
      onHide={handleCloseDialog}
      size="lg"
      centered
    >
      <Form onSubmit={formik.handleSubmit}>
        <Modal.Header closeButton>
          <Modal.Title>{lang(!roleInfo?.id ? "ROLE.ADD" : "ROLE.EDIT")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row className="px-8">
            <Col xs={6}>
              <TextValidator
                isRequired
                name="name"
                lable={lang("AUTH.NAME")}
                type="text"
                value={formik.values?.name || ""}
                onChange={formik.handleChange}
                errors={formik.errors?.name}
                touched={formik.touched?.name}
              />
            </Col>
            <Col xs={6}>
              <TextValidator
                name="description"
                lable={lang("ROLE.DES")}
                type="text"
                value={formik.values?.description || ""}
                onChange={formik.handleChange}
                errors={formik.errors?.description}
                touched={formik.touched?.description}
              />
            </Col>
          </Row>
          <Row className="px-8 pt-6">
            <Col xs={12}>
              <Autocomplete
                isRequired
                lable={lang("AUTH")}
                closeMenuOnSelect={false}
                name="kyNangMemList"
                value={formik.values?.authorities || []}
                options={[]}
                isMulti={true}
                searchFunction={getListAuth}
                onChange={(value: any) => handleChangeSelect("authorities", value)}
                searchObject={{}}
                getOptionValue={(option) => option?.name}
                styles={heightSelectMutil("auto")}
                errors={formik.errors?.authorities}
                touched={formik.touched?.authorities}
                getOptionLabel={(option) => option?.name}
              />
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-center">
          <Button
            className="btn btn-secondary btn-sm"
            onClick={handleCloseDialog}
          >
            {lang("BTN.CANCEL")}
          </Button>
          <Button
            className="btn btn-primary btn-sm"
            type="submit"
          >
            {lang("BTN.SAVE")}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};
export { RoleDialog };
