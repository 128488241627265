import { EmployeeProfile } from "../../../profile/models/ProfileModels";

export const removeChecked = (data: any[]) => {
  return data
    ? data.map((item: any) => {
        let { isChecked, ...dataRemoved } = item;
        return dataRemoved;
      })
    : data;
};

export const convertEmployeeId = (employeeList: any[]) => {
  return employeeList
    ? employeeList.map((employee) => {
        let { id, employeeId, ...employeeFilter } = employee;
        return { ...employeeFilter, employeeId: id || employeeId};
      })
    : [];
};
