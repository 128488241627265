import { useContext, useEffect, useState } from "react";
import { checkStatus, exportToFile, formatDateTable } from "../../../../utils/FunctionUtils";
import { exportPhuCap } from "../../../../utils/ExportFileServices";
import TableCustom from "../../../../component/table-custom/TableCustom";
import { deleteTrainingContract, getTrainingContractById } from "../../../services/DialogServices";
import { RESPONSE_STATUS_CODE, TYPE } from "../../../../utils/Constant";
import { toast } from "react-toastify";
import { contractStatus } from "../../../../contract/const/ContractConst";
import useMultiLanguage from "../../../../../hook/useMultiLanguage";
import { EXPORT_FILE_NAME } from "../../../../../Constant";
import AppContext from "../../../../../AppContext";
import { ITrainingContract } from "../../../models/DialogModels";
import { INIT_TRAINING_CONTRACT } from "../../../const/DialogConstant";
import { TrainingContractDialog } from "../../Dialog/TrainingContractDialog";

interface Props {
  isView: boolean;
  employeeId: string | undefined;
  hopDongLaoDongId: string;
  isDisplayBtnAdd?: boolean;
}

function TrainingContract(props: Props) {
  const { lang } = useMultiLanguage();
  const { isView, employeeId, hopDongLaoDongId, isDisplayBtnAdd = true } = props;

  const [openTrainingContractDialog, setOpenTrainingContractDialog] = useState<boolean>(false);
  const [trainingContractInfo, setTrainingContractInfo] = useState<ITrainingContract>(INIT_TRAINING_CONTRACT);
  const [listTrainingContract, setListTrainingContract] = useState<ITrainingContract[]>([]);
  const { setPageLoading } = useContext(AppContext);

  useEffect(() => {
    updatePageData();
  }, [hopDongLaoDongId])

  const handleOpenTrainingContractDialog = (data: any = {}) => {
    setOpenTrainingContractDialog(true);
    setTrainingContractInfo(data);
  };

  const handleCloseTrainingContractDialog = (isAddAndUpdate?: boolean) => {
    setTrainingContractInfo(INIT_TRAINING_CONTRACT);
    setOpenTrainingContractDialog(false);
    isAddAndUpdate && updatePageData();
  };

  const updatePageData = async () => {
    try {
      const { data } = await getTrainingContractById(hopDongLaoDongId || "");
      if(data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
        setListTrainingContract(data?.data || []);
      }else toast.error(data?.message);
    } catch (error) {
      toast.error(lang("GENERAL.ERROR"));
    }
  };

  const handleDeleteItems = async (ids: string) => {
    try {
      const res = await deleteTrainingContract(ids);
      if (res?.data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
        toast.success(lang("GENERAL.DELETE_SUCCESS"));
        updatePageData();
        return true;
      } else toast.error(`${res?.data?.message}`);
    } catch (error) {
      toast.error(lang("GENERAL.ERROR"));
    }
  };

  const columnsTrainingContract = [
    {
      name: lang("TABLE.INDEX"),
      field: "stt",
      render: (row: any, index: number, stt: number) => <span>{stt}</span>
    },
    {
      name: lang("GENERAL.STATUS"),
      field: "trangThaiHopDong",
      headerStyle: {
        minWidth: "120px"
      },
      cellStyle: {
        textAlign: "center" 
      },
      render: (row: any) => 
        <span 
          className={`${checkStatus(contractStatus, row?.trangThaiHopDong?.code)} status`}>
          {row?.trangThaiHopDong?.name}
        </span>
    },
    {
      name: lang("CONTRACT.NUMBER"),
      field: "soHopDong",
      headerStyle: {
        minWidth: "120px"
      }
    },
    {
      name: lang("FULL.NAME"),
      field: "tenNhanVien",
      headerStyle: {
        minWidth: "200px"
      }
    },
    {
      name: lang("TRAINING_CONTRACT.DIGITAL_TRAINING_COURSE"),
      field: "khoaDaoTao",
      headerStyle: {
        minWidth: "150px"
      }
    },
    {
      name: lang("TRAINING_CONTRACT.TIME_TRAINING"),
      field: "thoiGianKhoaHoc",
      headerStyle: {
        minWidth: "300px"
      },
      render: (row: any) => <span>{`${formatDateTable(row?.thoiGianDaoTaoTuNgay)} - ${formatDateTable(row?.thoiGianDaoTaoDenNgay)}`}</span>
    },
    {
      name: lang("ANNEX.SIGNINGDATE"),
      field: "ngayKyHopDong",
      headerStyle: {
        minWidth: "120px"
      },
      render: (row: any) => <span>{formatDateTable(row?.ngayKyHopDong)}</span>
    },
    {
      name: lang("CONTRACT.SIGNINGSTATUS"),
      field: "trangThaiKy",
      headerStyle: {
        minWidth: "150px"
      },
      render: (row: any) => <span>{row?.trangThaiKy?.name}</span>
    }
  ];

  return (
    <div className="block-content">
      <TableCustom
        id="trainingContract"
        title={lang("TRAINING_CONTRACT.TITLE")}
        isActionTableTab
        buttonAdd={!isView && isDisplayBtnAdd}
        buttonExportExcel={false}
        notDelete={true}
        notEdit={isView}
        noToolbar={true}
        data={listTrainingContract}
        columns={columnsTrainingContract}
        fixedColumnsCount={4}
        handleDelete={handleDeleteItems}
        justFilter={true}
        type={TYPE.MULTILINE}
        noPagination={true}
        handleOpenDialog={() => handleOpenTrainingContractDialog(INIT_TRAINING_CONTRACT)}
        handleExportExcel={() => 
          exportToFile({
            exportAPI: () => exportPhuCap(String(hopDongLaoDongId)), 
            fileName: EXPORT_FILE_NAME.PHU_CAP,
            setPageLoading
          })
        }
        handleDoubleClick={handleOpenTrainingContractDialog}
      />

      {openTrainingContractDialog && (
        <TrainingContractDialog
          employeeId={employeeId}
          hopDongLaoDongId={hopDongLaoDongId}
          isView={isView}
          updatePageData={updatePageData}
          handleCloseTrainingContractDialog={handleCloseTrainingContractDialog}
          trainingContractInfo={trainingContractInfo}
        />
      )}
    </div>
  );
}

export default TrainingContract;
