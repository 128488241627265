import { FC } from "react";
import { Button, Modal } from "react-bootstrap";
import "./confirmDialog.scss";

interface Props {
  show: boolean;
  onCloseClick?: () => void;
  onYesClick?: () => void;
  onCancelClick?: () => void;
  title?: string;
  message?: string;
  yes?: string;
  cancel?: string;
  close?: string;
  isView?: boolean;
}

const ConfirmDialog: FC<Props> = (props) => {
  const { show, onCloseClick, onYesClick, onCancelClick, title, message, yes, cancel, close, isView } = props;
  const lines = message ? message?.split('\n') : [];

  return (
    <Modal
      show={show}
      onHide={onCloseClick}
      centered
      animation
      aria-labelledby="example-custom-modal-styling-title"
      className="custom-modal confirm-dialog"
    >
      <Modal.Header className="bg-primary p-3">
        <Modal.Title className="text-white modal-title h4">{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="d-flex p-3">
        <div className='spaces m-0 ml-16'>{
          lines.map((line: string, index: number) => (
            <h5 className="spaces m-0" key={index}>{line}</h5>
          ))
        }</div>
      </Modal.Body>

      <Modal.Footer className="flex-center py-4">
        {!isView && yes && (
          <Button
            className="button-primary"
            type="submit"
            onClick={onYesClick}
          >
            {yes}
          </Button>
        )}
        {cancel && (
          <Button
            className="button-primary-outline"
            onClick={onCancelClick}
          >
            {cancel}
          </Button>
        )}
        {close && (
          <Button
            className="button-primary-outline"
            onClick={onCloseClick}
          >
            {close}
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmDialog;
