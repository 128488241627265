import InputSearch from "../../../component/input-field/InputSearch";
import AdvancedSearch from "../../../profile/component/Dialog/AdvancedSearch";
import { FC, useContext, useEffect, useState } from "react";
import TableCustom from "../../../component/table-custom/TableCustom";
import useMultiLanguage from "../../../../hook/useMultiLanguage";
import { IItemSearch, ResignInfo } from "../../models/IResign";
import { FIELD_SEARCH_RESIGN, INIT_INPUT_SEARCH, listStatusResign } from "../../const/ResignConst";
import { deleteResign, getListResign } from "../../services/ResignServices";
import { toast } from "react-toastify";
import { RESPONSE_STATUS_CODE, TYPE } from "../../../utils/Constant";
import { exportThoiViec } from "../../../utils/ExportFileServices";
import { checkObject, checkStatus, convertSearch, exportToFile, formatDateTable, hasAuthority, hasRole, searchDataRoleUser } from "../../../utils/FunctionUtils";
import { EXPORT_FILE_NAME, PERMISSIONS, PERMISSION_ABILITY, ROLE } from "../../../../Constant";
import { Col, Row } from "react-bootstrap";
import AppContext from "../../../../AppContext";
import { INIT_SEARCH_OBJECT } from "../../../constant";

interface IProps {
  handleOpenDialog: (row: ResignInfo) => void;
  handleOpenAddNewDialog: () => void;
  listResign: ResignInfo[];
  setListResign: React.Dispatch<React.SetStateAction<ResignInfo[]>>;
  initSearchObject?: Object;
  setInitSearchObject?: React.Dispatch<React.SetStateAction<any>>;
}

const ResignInformation: FC<IProps> = (props) => {
  const {
    handleOpenDialog,
    handleOpenAddNewDialog,
    listResign,
    setListResign,
    initSearchObject,
    setInitSearchObject,
  } = props;
  
  const { lang } = useMultiLanguage();
  const { setPageLoading } = useContext(AppContext);

  document.title = `${lang("GENERAL.APP.NAME")} | ${lang("GENERAL.RESIGN")}`;
  const [totalPage, setTotalPage] = useState<number>(0);
  const [totalElements, setTotalElements] = useState<number>(0);
  const [numberOfElements, setNumberOfElements] = useState<number>(0);
  const [searchObject, setSearchObject] = useState<any>(!checkObject(initSearchObject) ? initSearchObject  : INIT_SEARCH_OBJECT);
  const [isAdvancedSearch, setIsAdvancedSearch] = useState<boolean>(false);
  const [listInputSearch, setListInputSearch] = useState<any[]>([INIT_INPUT_SEARCH])

  useEffect(() => {
    if(searchObject?.pageIndex || searchObject?.pageSize){
      handleSearch();
    }
  }, [searchObject?.pageIndex, searchObject?.pageSize, ])

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchObject({
      ...searchObject,
      keyword: e.target?.value
    })
  }

  const handleSearch = (data: any = {}) => {
    const dataSearch: any = {
      ...searchObject,
      ...data
    }
    dataSearch.keyword = dataSearch?.keyword?.trim() || ""
    setInitSearchObject && setInitSearchObject(dataSearch);
    updatePageData(dataSearch)
  }

  const handleAdvancedSearch = (data: IItemSearch[]) => {
    setListInputSearch(data)
    handleSearch({ ...convertSearch(data), pageIndex: 1 })
  }

  const handleOpenAdvancedSearch = () => {
    setIsAdvancedSearch(true)
  }

  const updatePageData = async (searchObject?: any) => {
    try {
      setPageLoading(true);
      setSearchObject({ ...searchObject })
      const { data } = await getListResign(hasRole(ROLE.USER) ? searchDataRoleUser(searchObject) : { ...searchObject });
      setListResign(data?.data?.content || []);
      setTotalPage(data?.data?.totalPages || 0);
      setTotalElements(data?.data?.totalElements);
      setNumberOfElements(data?.data?.numberOfElements);
      if (data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
        setIsAdvancedSearch(false)
      }
    } catch (err: any) {
      toast.error(lang("GENERAL.ERROR"));
    } finally {
      setPageLoading(false);
    }
  };

  const handleDelete = async (ids: string[]) => {
    try {
      const { data } = await deleteResign(ids);
      if (data.code === RESPONSE_STATUS_CODE.SUCCESS) {
        toast.success(lang("TOAST.DELETE.SUCCESS"));
        await updatePageData(searchObject);
        return true;
      }
      toast.error(data?.message)
    } catch (error) {
      toast.error(lang("GENERAL.ERROR"));
    }
  }

  const columns = [
    {
      name: "STT",
      field: "",
      render: (row: any, index: number, stt: number) => <span>{stt}</span>
    },
    {
      name: lang('RESIGN.STATUS'),
      field: "trangThai",
      headerStyle: {
        minWidth: "120px"
      },
      cellStyle: {
        textAlign: "center",
      },
      render: (row: any) => (
        <span className={`${checkStatus(listStatusResign, row?.trangThai?.code)} status`}>
          {row?.trangThai?.name}
        </span>
      )
    },
    {
      name: lang('RESIGN.EMPLOYEECODE'),
      field: "maNhanVien",
      headerStyle: {
        minWidth: "120px"
      },
      cellStyle: {
        textAlign: "center",
      },
      render: (row: any) => <span className="color-steel-blue fw-600">{row?.maNhanVien}</span>
    },
    {
      name: lang('GENERAL.FULLNAME'),
      field: "fullName",
      headerStyle: {
        minWidth: "200px"
      },
      cellStyle: {
        textAlign: "left",
      },
    },
    {
      name: lang('INPUT.SALARY.DEPARTMENT'),
      field: "phongBanText",
      headerStyle: {
        minWidth: "200px"
      },
      cellStyle: {
        textAlign: "left",
      },
    },
    {
      name: lang('GENERAL.EMPLOYEE.POSITION'),
      field: "chucVuText",
      headerStyle: {
        minWidth: "220px"
      },
      cellStyle: {
        textAlign: "left",
      },
    },
    {
      name: lang('GENERAL.EMPLOYEE.JOB_POSITION'),
      field: "viTriCongViecText",
      headerStyle: {
        minWidth: "270px"
      },
      cellStyle: {
        textAlign: "left",
      },
    },
    {
      name: lang('RESIGN.TYPE'),
      field: "loaiThoiViec",
      headerStyle: {
        minWidth: "200px"
      },
      cellStyle: {
        textAlign: "left",
      },
      render: (row: any) => row?.loaiThoiViec?.name
    },
    {
      name: lang('RESIGN.DATE'),
      field: "ngayHieuLuc",
      headerStyle: {
        minWidth: "110px"
      },
      cellStyle: {
        textAlign: "center",
      },
      render: (row: any) => <span>{formatDateTable(row?.ngayHieuLuc)}</span>
    },
    {
      name: lang('INPUT.WORKPROCESS.DECISION.NUMBER'),
      field: "soQuyetDinh",
      headerStyle: {
        minWidth: "150px"
      },
      cellStyle: {
        textAlign: "enter",
      },
    }
  ];

  return (
    <div className="spaces px-10 py-16">
      <Row className="spaces mb-8 flex-middle ml-10">
        <Col xs={4} xxl={6} className="spaces p-0">
          <h2 className="breadcrumb-title">Danh sách thôi việc</h2>
        </Col>
        <Col xs={8} xxl={6} className="flex">
          <div className="spaces mr-10 flex-1">
            <InputSearch
              className='border-3px'
              value={searchObject?.keyword}
              placeholder="Tìm kiếm theo mã NV, họ và tên, phòng ban, chức vụ và chức danh"
              isEnter={true}
              handleSearch={() => handleSearch({ pageIndex: 1 })}
              handleChange={handleChange}
            />
          </div>
          <button className="spaces button-primary mr-10" onClick={() => handleSearch({ pageIndex: 1 })}>Tìm kiếm</button>
          <button className="spaces button-primary-outline mr-10" onClick={handleOpenAdvancedSearch}>Tìm nâng cao</button>

          {isAdvancedSearch &&
            <AdvancedSearch
              open={isAdvancedSearch}
              listInputSearch={listInputSearch}
              handleSearch={handleAdvancedSearch}
              listOption={FIELD_SEARCH_RESIGN}
              handeleClose={() => setIsAdvancedSearch(false)}
            />
          }
        </Col>
      </Row>
      <div className="spaces flex-1 pb-18 table-custom">
        <TableCustom
          id="listResign"
          data={listResign}
          columns={columns}
          searchObject={searchObject}
          setSearchObject={setSearchObject}
          buttonAdd={hasAuthority(PERMISSIONS.NGHI_VIEC, PERMISSION_ABILITY.CREATE)}
          buttonExportExcel={hasAuthority(PERMISSIONS.NGHI_VIEC, PERMISSION_ABILITY.VIEW) && !hasRole(ROLE.USER)}
          notDelete={!hasAuthority(PERMISSIONS.NGHI_VIEC, PERMISSION_ABILITY.DELETE)}
          handleExportExcel={(ids: string[]) =>
            exportToFile({
              exportAPI: () => exportThoiViec({ ids, ...searchObject }),
              fileName: EXPORT_FILE_NAME.NGHI_VIEC,
              setPageLoading
            })
          }
          type={TYPE.MULTILINE}
          fixedColumnsCount={4}
          totalPages={totalPage}
          totalElements={totalElements}
          numberOfElements={numberOfElements}
          handleOpenDialog={handleOpenAddNewDialog}
          handleDoubleClick={handleOpenDialog}
          handleDelete={(ids: string[]) => handleDelete(ids)}
          deleteConditional={[
            {
              keyPath: "raQuyetDinh",
              value: false
            }]}
        />
      </div>
    </div>
  );
}

export default ResignInformation;