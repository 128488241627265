import { useFormik } from "formik";
import { FC, useEffect } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import * as Yup from "yup";
import { IAuth } from "../../model/ManagerModel";
import { toast } from "react-toastify";
import { SUCCESS_CODE } from "../../../contract/const/ContractConst";
import { addNewAuth, updateAuth } from "../../services/ManagerServices";
import useMultiLanguage from "../../../../hook/useMultiLanguage";
import TextValidator from "../../../component/input-field/TextValidator";
import { INIT_AUTH } from "../const/ManagerCost";

interface IProps {
  handleCloseDialog: () => void;
  authInfo: IAuth;
  handleReload: () => Promise<void>;
}
const AuthDialog: FC<IProps> = (props) => {
  const { handleCloseDialog, authInfo, handleReload } = props;
  const { lang } = useMultiLanguage();

  useEffect(() => {
    if(authInfo?.id) {
      formik.setValues(authInfo);
    }
  }, [authInfo])

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(lang("VALIDATION.REQUIRE")).nullable(),
    code: Yup.string().required(lang("VALIDATION.REQUIRE")).nullable()
  });

  const handleSubmit = async (values: IAuth) => {
    try {
      const res = authInfo.id ? await updateAuth(authInfo.id, values) : await addNewAuth(values);
      if (res?.data?.code === SUCCESS_CODE) {
        let message = authInfo.id ? "TOAST.EDIT.SUCCESS" : "TOAST.CREATE.SUCCESS";
        toast.success(lang(message));
        handleReload();
        handleCloseDialog();
      } else {
        toast.error(`${res?.data?.message}`);
      }
    } catch (err) {
      toast.error(lang("GENERAL.ERROR"));
    }
  };

  const formik = useFormik({
    initialValues: INIT_AUTH,
    onSubmit: handleSubmit,
    validationSchema
  });

  return (
    <Modal
      show={true}
      onHide={handleCloseDialog}
      size="lg"
      centered
    >
      <Form onSubmit={formik.handleSubmit}>
        <Modal.Header closeButton>
          <Modal.Title>{lang(!authInfo?.id ? "AUTH.ADD" : "AUTH.EDIT")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row className="px-8">
            <Col xs={6}>
              <TextValidator
                isRequired
                name="name"
                lable={lang("AUTH.NAME")}
                type="text"
                value={formik.values?.name || ""}
                onChange={formik.handleChange}
                errors={formik.errors?.name}
                touched={formik.touched?.name}
              />
            </Col>
            <Col xs={6}>
              <TextValidator
                isRequired
                name="code"
                lable={lang("AUTH.CODE")}
                type="text"
                value={formik.values?.code || ""}
                onChange={formik.handleChange}
                errors={formik.errors?.code}
                touched={formik.touched?.code}
              />
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-center">
          <Button
            className="btn btn-secondary btn-sm"
            onClick={handleCloseDialog}
            type="button"
          >
            {lang("BTN.CANCEL")}
          </Button>
          <Button
            className="btn btn-primary btn-sm"
            type="submit"
          >
            {lang("BTN.SAVE")}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};
export { AuthDialog };
