import { Modal, Button } from "react-bootstrap";
import useMultiLanguage from "../../../../hook/useMultiLanguage";
import { IRemindHeader } from "../../models/remindModels";

interface IProps {
  open: boolean;
  handleClose: () => void;
  remindInfo: IRemindHeader;
}

function RemindViewDialog(props: IProps) {
  const { open, handleClose, remindInfo } = props;
  const { lang } = useMultiLanguage();

  return (
    <Modal show={open} onHide={handleClose} centered size="xl">
      <Modal.Header closeButton className="spaces py-12">
        <Modal.Title className="spaces fs-20 fw-600">{remindInfo.tilte}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{remindInfo.remindTable()}</Modal.Body>
      <Modal.Footer className="flex-center">
        <Button variant="outline-secondary" className="button-secondary btn-sm" onClick={handleClose}>
          {lang("BTN.CLOSE")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default RemindViewDialog;
