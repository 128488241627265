import { FC, Suspense, lazy } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import TopBarProgress from "react-topbar-progress-indicator";
import { getCSSVariableValue } from "../../_metronic/assets/ts/_utils";
import { WithChildren } from "../../_metronic/helpers";
import { MasterLayout } from "../../_metronic/layout/MasterLayout";
import { PERMISSIONS, PERMISSION_ABILITY } from "../Constant";
import { AppointmentList } from "../modules/appointment-list/AppointmentList";
import Bonus from "../modules/bonus/Bonus";
import { Contract } from "../modules/contract/Contract";
import Discipline from "../modules/discipline/Discipline";
import { DismissedList } from "../modules/dismissed-list/DismissedList";
import { Manager } from "../modules/manager/Manager";
import { Profile } from "../modules/profile/Profile";
import { Resign } from "../modules/resign/Resign";
import Statistical from "../modules/statistical/Statistical";
import Transition from "../modules/transition/Transition";
import { hasAuthority } from "../modules/utils/FunctionUtils";
import Warning from "../modules/warning/Warning";
import { WelfareList } from "../modules/welfare-list/WelfareList";
import { HomePage } from "../pages/Homepage/HomePage";
import Report from "../modules/report/Report";
import Problem from "../modules/problem/Problem";
import DispatchBook from "../modules/dispatch-book/DispatchBook";
import { Concurrently } from "../modules/concurrently/Concurrently";
import Insurance from "../modules/insurance/Insurance";
import Remind from "../modules/remind/Remind";

interface PrivateRouteProps {
  auth: string;
  ability: string;
  component: React.ComponentType<any>;
  redirect: string;
}

const PrivateRoutes = () => {
  // const ChatPage = lazy(() => import("../modules/apps/chat/ChatPage"));

  const PrivateRoute: React.FC<PrivateRouteProps> = ({ auth, ability, component: Component, redirect, }) => {
    return hasAuthority(auth, ability) ? (<Component />) : (<Navigate to={redirect} />);
  };

  return (
    <Routes>
      <Route index element={<Navigate to="/home" />} />
      <Route path="/*" element={<HomePage />} />
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path="auth/*" element={<Navigate to="/home" />} />
        {/* Pages */}
        <Route path="personal-information/statistical" element={<PrivateRoute auth={PERMISSIONS.TONG_QUAN} ability={PERMISSION_ABILITY.VIEW} component={Statistical} redirect="/home" />} />
        <Route path="personal-information/remind" element={<PrivateRoute auth={PERMISSIONS.REMIND} ability={PERMISSION_ABILITY.VIEW} component={Remind} redirect="/home" />} />
        <Route path="personal-information/warning" element={<PrivateRoute auth={PERMISSIONS.TONG_QUAN} ability={PERMISSION_ABILITY.VIEW} component={Warning} redirect="/home" />} />
        <Route path="personal-information/profile" element={<PrivateRoute auth={PERMISSIONS.EMPLOYEE} ability={PERMISSION_ABILITY.VIEW} component={Profile} redirect="/home" />} />
        <Route path="personal-information/contract" element={<PrivateRoute auth={PERMISSIONS.HOP_DONG} ability={PERMISSION_ABILITY.VIEW} component={Contract} redirect="/home" />} />
        <Route path="personal-information/resign" element={<PrivateRoute auth={PERMISSIONS.NGHI_VIEC} ability={PERMISSION_ABILITY.VIEW} component={Resign} redirect="/home" />} />
        <Route path="personal-information/transition" element={<PrivateRoute auth={PERMISSIONS.THUYEN_CHUYEN} ability={PERMISSION_ABILITY.VIEW} component={Transition} redirect="/home" />} />
        <Route path="personal-information/appreciative" element={<PrivateRoute auth={PERMISSIONS.KHEN_THUONG} ability={PERMISSION_ABILITY.VIEW} component={Bonus} redirect="/home" />} />
        <Route path="personal-information/issue" element={<PrivateRoute auth={PERMISSIONS.SU_CO} ability={PERMISSION_ABILITY.VIEW} component={Discipline} redirect="/home" />} />
        <Route path="personal-information/problem" element={<PrivateRoute auth={PERMISSIONS.SU_CO} ability={PERMISSION_ABILITY.VIEW} component={Problem} redirect="/home" />} />
        <Route path="personal-information/dispatch-book" element={<PrivateRoute auth={PERMISSIONS.SU_CO} ability={PERMISSION_ABILITY.VIEW} component={DispatchBook} redirect="/home" />} />
        <Route path="personal-information/appointment" element={<PrivateRoute auth={PERMISSIONS.BO_NHIEM} ability={PERMISSION_ABILITY.VIEW} component={AppointmentList} redirect="/home" />} />
        <Route path="personal-information/dismissed" element={<PrivateRoute auth={PERMISSIONS.MIEN_NHIEM} ability={PERMISSION_ABILITY.VIEW} component={DismissedList} redirect="/home" />} />
        <Route path="personal-information/welfare" element={<PrivateRoute auth={PERMISSIONS.PHUC_LOI} ability={PERMISSION_ABILITY.VIEW} component={WelfareList} redirect="/home" />} />
        <Route path="personal-information/concurrently" element={<PrivateRoute auth={PERMISSIONS.PHUC_LOI} ability={PERMISSION_ABILITY.VIEW} component={Concurrently} redirect="/home" />} />
        <Route path="personal-information/manager" element={<PrivateRoute auth={PERMISSIONS.AUTHORITY} ability={PERMISSION_ABILITY.VIEW} component={Manager} redirect="/home" />} />
        <Route path="personal-information/insurance" element={<PrivateRoute auth={PERMISSIONS.BAO_HIEM_Y_TE} ability={PERMISSION_ABILITY.VIEW} component={Insurance} redirect="/home" />} />
        <Route path="personal-information/report" element={<PrivateRoute auth={PERMISSIONS.BAO_CAO} ability={PERMISSION_ABILITY.VIEW} component={Report} redirect="/home" />} />
        {/* Time keeping */}
        {/* <Route path="timekeeping" element={<PrivateRoute auth={PERMISSIONS.CHAM_CONG} ability={PERMISSION_ABILITY.VIEW} component={TimeKeeping} redirect="/home" />} /> */}
        {/* Lazy Modules */}
        {/* <Route
          path="apps/chat/*"
          element={
            <SuspensedView>
              <ChatPage />
            </SuspensedView>
          }
        /> */}
        {/* Page Not Found */}
        <Route path="*" element={<Navigate to="/error/404" />} />
      </Route>
    </Routes>
  );
};

const SuspensedView: FC<WithChildren> = ({ children }) => {
  const baseColor = getCSSVariableValue("--kt-primary");
  TopBarProgress.config({
    barColors: {
      "0": baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  });
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>;
};

export { PrivateRoutes };

