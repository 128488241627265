import { IDataPreview } from "../../component/exportWord";
import TextUnderline from "../../component/TextUnderline";
import { TYPE } from "../../utils/Constant";

export function ContractTraining({ data }: { data: IDataPreview }) {

  return (
    <div className="word spaces px-70">
      <div className="spaces text-center flex flex-between align-start">
        <div className="fs-lg flex-column flex-middle">
          <img className="spaces w-100 h-45" src="/media/logos/logoHaiTrieuFood.png" alt="logo hải triều" />
          <p className="fw-600 text-uppercase">công ty tnhh hải triều</p>
          <div>
            <span className="fw-600 text-uppercase">Số: {data?.soHopDong ? data?.soHopDong : ".........................."}/HĐĐTN-HT</span>
          </div>
        </div>
        <div className="spaces fw-600 mt-45">
          <p className="fs-lg">CỘNG HOÀ XÃ HỘI CHỦ NGHĨA VIỆT NAM</p>
          <span className="header-underline fs-lg">Độc lập - Tự do - Hạnh phúc</span>
        </div>
      </div>
      <p className="fs-lg spaces py-16 text-center fw-600 mb-0 ">HỢP ĐỒNG ĐÀO TẠO NGHỀ</p>
      <div className="spaces text-italic fs-md pl-60 mb-16">
        <p>- Căn cứ Bộ luật Lao động số 45/2019/QH14 ngày 20/11/2019 của Quốc hội nước Cộng hòa Xã hội Chủ nghĩa Việt Nam;</p>
        <p>- Căn cứ Bộ luật Giáo dục và nghề nghiệp số 74/2014/QH13 ngày 27/11/2014 của Quốc hội nước Cộng hòa Xã hội Chủ nghĩa Việt Nam;</p>
        <p>- Căn cứ Quy chế đào tạo của Công ty TNHH Hải Triều;</p>
        <p>- Căn cứ vào năng lực và nhu cầu của hai bên;</p>
      </div>
      <div className="flex">
        <span>Hôm nay, ngày </span> 
        <TextUnderline className="spaces w-100" value={data?.ngayKyHopDong} type={TYPE.DATE} />
        <span className="spaces text-indent-3">tại văn phòng Công ty TNHH Hải Triều. Chúng tôi gồm:</span>
      </div>
      <p>Người sử dụng lao động: CÔNG TY TNHH HẢI TRIỀU.</p>
      <p>Địa chỉ: Lô 2/6 KCN Phan Thiết, Phong Nẫm, Phan Thiết, Bình Thuận - Chứng nhận đăng ký kinh doanh số: 3400494824</p>
      <div className="flex">
        <TextUnderline className="flex-2" label="Đại diện theo pháp luật" value={data?.nguoiDaiDienCtyKyText} />
        <TextUnderline className="flex-1" label="Chức vụ" value={data?.chucVuText} />
      </div>
      <div className="flex">
        <TextUnderline className="flex-2" label="Người lao động (Ông/Bà)" value={data?.fullName} />
        <TextUnderline className="flex-1" label="Ngày sinh" value={data?.birthdate} />
      </div>
      <div className="flex">
        <TextUnderline className="spaces flex-3" label="CMND/CCCD số" value={data?.soCMNDOrCCCD} />
        <TextUnderline className="spaces flex-2" label="Cấp ngày" value={data?.ngayCapCMNDOrCCCD} type={TYPE.DATE} />
        <TextUnderline className="spaces flex-4" label="Nơi cấp" value={data?.noiCapCMNDOrCCCD} />
      </div>
      <div className="flex">
        <TextUnderline className="spaces w-200" label="Giới tính" value={data?.gender?.name} />
        <TextUnderline className="spaces flex-1" label="Trình độ văn hóa" value={data?.trinhDoGiaoDucPhoThongText} />
      </div>
      <TextUnderline label="Cư trú tại" value={data?.hoKhauThuongTru} />
      <p> Thỏa thuận ký kết Hợp đồng lao động và cam kết làm đúng những điều khoản sau đây:</p>
      <div>
        <div>
          <p className="fw-600">Điều 1: Nghề đào tạo và địa chỉ đào tạo:</p>
          <div className="spaces pl-20">
            <div className="flex spaces text-indent-20">
              <TextUnderline label="- Công ty cử" value={data?.fullName} />
              <TextUnderline label="Chức vụ" value={data?.chucVuText} />
            </div>
            <div className="flex spaces text-indent-20">
              <TextUnderline label="- Bộ phận" value={data?.phongBanText} />
              <TextUnderline label="đi đào tạo theo khóa đào tạo số" value={data?.khoaDaoTao?.name} />,
            </div>
            <div className="spaces flex text-indent-4">
              <TextUnderline className="spaces w-200" label="ngày " value={data?.thoiGianDaoTaoTuNgay} type={TYPE.DATE} />
              <TextUnderline className="flex-1" label="Thời gian bắt đầu khóa học kể từ ngày " value={data?.thoiGianDaoTaoTuNgay} type={TYPE.DATE} />
              <TextUnderline className="spaces w-200" label="đến ngày" value={data?.thoiGianDaoTaoDenNgay} type={TYPE.DATE} />
            </div>
            <TextUnderline label="- Đơn vị đào tạo" value={data?.donViDaoTao} />
            <TextUnderline label="- Cơ sở" value={""} />
          </div>
        </div>

        <div>
          <p className="fw-600">Điều 2: Chế độ học nghề:</p>
          <div className="spaces pl-20">
            <div className="flex">
              <TextUnderline className="spaces w-300" label="- Thời gian học nghề" value={data?.thoiGianHocNghe} />
              <p>ngày</p>
              <p className='unti-icon'>(</p>
              <TextUnderline className="spaces w-200" label="từ ngày" value={data?.thoiGianDaoTaoTuNgay} type={TYPE.DATE} />
              <TextUnderline className="spaces w-200" label="đến ngày" value={data?.thoiGianDaoTaoDenNgay} type={TYPE.DATE} />
              <p className='unti-icon'>)</p>
            </div>
            <div className="flex text-indent-20">
              <TextUnderline className="spaces w-300" label="- Thời gian học trong ngày" value={""} />
              <p>giờ/ngày.</p>
            </div>
            <div className="flex spaces w-500 text-indent-20">
              <p className="spaces w-130">+ Buổi sáng từ</p>
              <TextUnderline className="spaces w-40" label="" value={""} />
              <p className="spaces w-24 text-indent-0">giờ</p>
              <TextUnderline className="spaces w-40" label="" value={""} />
              <p className="spaces w-65 text-indent-0">phút đến</p>
              <TextUnderline className="spaces w-40" label="" value={""} />
              <p className="spaces w-24 text-indent-0">giờ</p>
              <TextUnderline className="spaces w-40" label="" value={""} />
              <p className="spaces w-25 text-indent-0">phút.</p>
            </div>
            <div className="flex spaces w-500 text-indent-20">
              <p className="spaces w-130">+ Buổi đến từ</p>
              <TextUnderline className="spaces w-40" label="" value={""} />
              <p className="spaces w-24 text-indent-0">giờ</p>
              <TextUnderline className="spaces w-40" label="" value={""} />
              <p className="spaces w-65 text-indent-0">phút đến</p>
              <TextUnderline className="spaces w-40" label="" value={""} />
              <p className="spaces w-24 text-indent-0">giờ</p>
              <TextUnderline className="spaces w-40" label="" value={""} />
              <p className="spaces w-25 text-indent-0">phút.</p>
            </div>
            <p>- Chế độ nghỉ ngày: Chủ nhật và tất cả các ngày Lễ Tết theo quy định của Nhà nước.</p>
            <p>- Người lao động được cấp phát:</p>
            <p className="spaces text-indent-20">+ Các tài liệu phân Đại cương và chuyên ngành liên quan đến chương trình đào tạo.</p>
            <p>- Người lao động được học trong điều kiện an toàn và vệ sinh theo quy định hiện hành của Nhà nước.</p>
          </div>
        </div>

        <div>
          <p className="fw-600">Điều 3: Chi phí đào tạo:</p>
          <p className="spaces text-indent-20">- Chi phí đào tạo bao gồm:</p>
          <div className="spaces text-indent-40">
            <div className="flex spaces w-400 pl-40">
              <TextUnderline label="+ Chi phí khóa đào tạo là " value={data?.chiPhiDaoTao} />
              <p className="spaces text-indent-0">đồng</p>
            </div>
            <p>+ Các trang thiết bị, các chi phí phụ cấp khác hỗ trợ cho người lao động trong quá trình đào tạo.</p>
            <p>+ Tiền lương, tiền đóng bảo hiểm xã hội, bảo hiểm y tế, bảo hiểm thất nghiệp cho người lao động trong thời gian đào tạo.</p>
          </div>
        </div>

        <div>
          <p className="fw-600">Điều 4: Trách nhiệm hoàn trả chi phí đào tạo:</p>
          <p className="spaces text-indent-20">
            - Người lao động không thực hiện đúng theo thời hạn đã cam kết trong hợp đồng đào tạo, thì phải
            bồi thường chi phí đào tạo theo điều 3 của hợp đồng này.
          </p>
        </div>

        <div>
          <p className="fw-600">Điều 5: Thời hạn người lao động cam kết phải làm việc cho người sử dụng lao động sau khi được đào tạo:</p>
          <div className="spaces text-indent-20">
            <p>- Sau khi hoàn thành chương trình đào tạo nghề, người lao động cam kết làm việc cho công ty với thời hạn 24 tháng.</p>
            <p>- Nếu người lao động nghỉ việc trước thời hạn cam kết:</p>
            <div className="spaces text-indent-40">
              <p>+ Trường hợp 1: Nếu người lao động nghỉ việc trước 12 tháng sau khi được đào tạo, sẽ hoàn trả toàn bộ chi phí đào tạo.</p>
              <p>
                + Trường hợp 2: Nếu người lao động nghỉ việc trong khoảng thời gian từ 12 - 24 tháng sau khi được đào tạo, sẽ báo trước 02 tháng trước khi nghỉ.
                Nếu không báo trước, sẽ đền bù một nửa chi phí đào tạo.
              </p>
            </div>
          </div>
        </div>

        <div>
          <p className="fw-600">Điều 6: Nghĩa vụ, quyền hạn và quyền lợi của người lao động:</p>
          <p className="fw-600">6.1. Nghĩa vụ:</p>
          <p className="spaces text-indent-20">
            - Trong quá trình đào tạo tuyệt đối chấp hành nội quy, quy chế của công ty và quy định của Pháp luật. Nếu vi phạm phải bồi thường toàn bộ học phí của khoá
            học và những tổn thất gây ra, đồng thời phải chịu trách nhiệm trước pháp luật.
          </p>
          <p className="fw-600">6.2. Quyền hạn:</p>
          <div className="spaces text-indent-20">
            <p>- Người lao động có quyền đề xuất, đóng góp, xây dựng ý kiến để đảm bảo quyền lợi cho mình và Công ty ngày càng phát triển. </p>
            <p>- Người lao động sử dụng kiến thức được học nâng cao tay nghề, kỹ năng nghề để phục vụ công việc tốt hơn và tăng năng suất lao động.</p>
          </div>
          <p className="fw-600">6.3. Quyền lợi:</p>
          <div className="spaces text-indent-20">
            <p>
              - Người lao động sẽ được học tập lý thuyết và thực hành nghề đảm bảo đủ thời gian, nội dung chất lượng theo chương trình đào tạo của Công ty đã đề
              ra theo quy định của Bộ LĐTBXH.
            </p>
            <p>- Người lao động được kiểm tra trình độ lý thuyết, tay nghề và được cấp chứng chỉ theo quy định.</p>
          </div>
        </div>

        <div>
          <p className="fw-600">Điều 7: Nghĩa vụ và quyền hạn của người sử dụng lao động:</p>
          <p className="fw-600">7.1. Nghĩa vụ:</p>
          <div className="spaces text-indent-20">
            <p>- Người sử dụng lao động phải làm đầy đủ thủ tục nhập học và đóng học phí theo quy định để người lao động được vào học.</p>
            <p>- Thực hiện đầy đủ những điều kiện cần thiết đã cam kết trong hợp đồng đào tạo nghề để người lao động học tập đạt hiệu quả, bảo đảm theo hợp đồng đã ký.</p>
          </div>
          <p className="fw-600">7.2. Quyền hạn:</p>
          <div className="spaces text-indent-20">
            <p>
              - Công ty có quyền điều chuyển người lao động giữa các bộ phận và thay đổi, tạm hoãn, kỷ luật và chấm dứt hợp đồng với các trường hợp người lao động vi
              phạm hợp đồng theo quy định của Pháp luật.
            </p>
            <p>
              - Người lao động được hoặc phải chấm dứt Hợp đồng đào tạo nghề trước thời hạn trong các trường hợp (có đầy đủ giấy tờ hợp pháp và có căn cứ rõ ràng)
              sau đây: đi nghĩa vụ quân sự, lý do sức khoẻ, vi phạm Pháp luật của Nhà nước và nội quy của công ty.
            </p>
          </div>
        </div>

        <div>
          <p className="fw-600">Điều 8: Điều khoản chung:</p>
          <div className="spaces text-indent-20">
            <p>
              - Khi hai bên có sự thỏa thuận để thay đổi nội dung trong hợp đồng này thì sẽ ký kết phụ lục hợp đồng lao động, phụ lục hợp đồng cũng có giá trị như các nội
              dung của hợp đồng này.
            </p>
            <p>- Hợp đồng này được làm thành 02 bản có giá trị pháp lý như nhau, Người sử dụng lao động giữ 01 bản và Người lao động giữ 01 bản.</p>
          </div>
        </div>

        <div className="flex-center">
          <div className="spaces text-center align-start flex flex-between mt-16 pb-70 width-80">
            <div>
              <p className="fw-600">NGƯỜI LAO ĐỘNG</p>
              <p className="text-italic">(Ký, ghi rõ họ tên)</p>
              <p className="spaces mt-70 fw-600 text-capitalize-first">{data.fullName}</p>
            </div>
            <div>
              <p className="fw-600">NGƯỜI SỬ DỤNG LAO ĐỘNG</p>
              <p className="text-italic">(Ký, ghi rõ họ tên)</p>
              <p className="spaces mt-70 fw-600 text-capitalize-first">{data.nguoiDaiDienCtyKyText}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
