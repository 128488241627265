import { IDismissed } from "../model/IDismissed";
import axios from "axios";
import { paramsConfig } from "../../utils/ParamsUtils";
const API_URL = process.env.REACT_APP_API_URL;

export const getListDismissed = (searchData:any) => {
  let url = `${API_URL}/mien-nhiem/page`;
  return axios.get(url, paramsConfig(searchData));
};

export const addNewDismissed = (dismissed: IDismissed) => {
  let url = `${API_URL}/mien-nhiem`;
  return axios.post(url, dismissed);
};

export const editDismissed = (id: string, dismissed: IDismissed) => {
  let url = `${API_URL}/mien-nhiem/${id}`;
  return axios.put(url, dismissed);
};

export const deleteDismissed = (ids: string[]) => {
  let url = `${API_URL}/mien-nhiem/mienNhiemIds?ids=${ids.toString()}`;
  return axios.delete(url);
};
