/* eslint-disable react-hooks/exhaustive-deps */
import { useFormik } from "formik";
import { FC, useContext, useEffect, useState } from "react";
import {Col, Modal, Row, Form, Button } from "react-bootstrap";
import { toast } from "react-toastify";
import * as Yup from "yup";
import Autocomplete from "../../../component/input-field/Autocomplete";
import TextValidator from "../../../component/input-field/TextValidator";
import { SUCCESS_CODE } from "../../../contract/const/ContractConst";
import { IAllowance, ILoaiPhuCap } from "../../../contract/services/models/IAllowance";
import { addPhuCap, updatePhuCap } from "../../services/DialogServices";
import { INIT_ALLOWANCE, coGiaHan, heSoPhanTramGiaTri, loaiPhuCapList } from "./../../../contract/const/AllowanceConst";
import useMultiLanguage from "../../../../hook/useMultiLanguage";
import { RangeDatePicker } from "../../../component/input-field/RangeDatePicker";
import AppContext from "../../../../AppContext";
import { useProfileContext } from "../../ProfileContext";
import { convertNumberPrice, convertTextPrice } from "../../../utils/FunctionUtils";
import { GroupButton } from "../../../component/GroupButton";
import { LIST_STATUS_ALLOWANCE } from "../../const/DialogChildConstants";
import { VARIABLE_STRING } from "../../../utils/Constant";
import { checkInvalidDate } from "../../../utils/ValidationSchema";
import "../../profile.scss"

interface IProps {
  handleCLoseAllowanceDialog: () => void;
  allowanceInfo: IAllowance;
  isView: boolean;
  getListAllowance: () => Promise<void>;
  salaryId: string | undefined;
}

const EmployeeAllowanceDialog: FC<IProps> = (props) => {
  const { handleCLoseAllowanceDialog, getListAllowance, allowanceInfo, salaryId, isView } = props;
  const { lang, intl } = useMultiLanguage();
  const { setPageLoading } = useContext(AppContext);
  const { isResign } = useProfileContext();

  const [isEdit, setIsEdit] = useState<boolean>(!isView);

  const validationSchema = Yup.object().shape({
    tenKhoanPhuCap: Yup.object().required(lang("VALIDATION.REQUIRE")).nullable(),
    giaTri: Yup.string()
      .required(lang("VALIDATION.REQUIRE"))
      .nullable(),
    thoiGianHieuLucBatDau: Yup.date()
      .concat(checkInvalidDate(intl))
      .required(lang("VALIDATION.REQUIRE"))
      .nullable(),
    thoiGianHieuLucKetThuc: Yup.date()
      .concat(checkInvalidDate(intl))
      .when("thoiGianHieuLucBatDau", {
        is: (thoiGianHieuLucBatDau: Date | null) => thoiGianHieuLucBatDau,
        then: Yup.date()
          .min(
            Yup.ref("thoiGianHieuLucBatDau"),
            lang("VALIDATION.MINDATE") +
            lang("INPUT.EFFECTIVEDATE_START")
          )
          .nullable()
      })
      .nullable(),
    trangThai: Yup.object().required(lang("VALIDATION.REQUIRE")).nullable()
  });

  const handleSubmit = async (values: IAllowance) => {
    try {
      setPageLoading(true);
      values.lichSuLuongId = String(salaryId);
      const res = allowanceInfo?.id ? await updatePhuCap(allowanceInfo?.id, values) : await addPhuCap(values);
      if (res?.data?.code === SUCCESS_CODE) {
        toast.success(allowanceInfo?.id ? lang("TOAST.EDIT.SUCCESS") : lang("TOAST.CREATE.SUCCESS"));
        await getListAllowance();
        handleCLoseAllowanceDialog();
        setPageLoading(false);
      } else {
        toast.error(`${res?.data?.message}`);
        setPageLoading(false);
      }
    } catch (err) {
      toast.error(lang("GENERAL.ERROR"));
      setPageLoading(false);
    }
  };

  const formik = useFormik({
    initialValues: INIT_ALLOWANCE,
    onSubmit: handleSubmit,
    validationSchema
  });

  useEffect(() => {
    if (allowanceInfo?.id) {
      formik.setValues(allowanceInfo);
    } else {
      formik.setValues(INIT_ALLOWANCE);
    }
  }, [allowanceInfo]);

  const handleChangeLoaiPhuCap = (value: ILoaiPhuCap) => {
    let hinhThucHuong = "";

    switch (value.heSoPhanTramGiaTri) {
      case heSoPhanTramGiaTri.COEFFICIENT:
        hinhThucHuong = lang("ALLOWANCE.COEFFICIENT");
        break;
      case heSoPhanTramGiaTri.PERCENT:
        hinhThucHuong = lang("ALLOWANCE.PERCENT");
        break;
      case heSoPhanTramGiaTri.MONEY:
        hinhThucHuong = lang("ALLOWANCE.MONEY");
        break;
      default:
        hinhThucHuong = lang("ALLOWANCE.COEFFICIENT");
        break;
    }

    let coGiaHanValue: boolean = false;
    let phuCapBHXH: boolean = false;

    switch (value.coGiaHan) {
      case coGiaHan.KHONG_TICH:
        coGiaHanValue = false;
        phuCapBHXH = false;
        break;
      case coGiaHan.BHXH:
        coGiaHanValue = false;
        phuCapBHXH = true;
        break;
      case coGiaHan.CO_GIA_HAN:
        coGiaHanValue = true;
        phuCapBHXH = false;
        break;
      case coGiaHan.BOTH:
        coGiaHanValue = true;
        phuCapBHXH = true;
        break;
      default:
        coGiaHanValue = false;
        phuCapBHXH = false;
        break;
    }

    formik.setValues({
      ...formik.values,
      tenKhoanPhuCap: value,
      coGiaHan: coGiaHanValue,
      phuCapBHXH,
      hinhThucHuong
    });
  };

  const handleChangeMoney = (event: React.ChangeEvent<any>) => {
    const { name, value } = event.target;
    formik.setFieldValue(name, Number(convertTextPrice(value)));
  };

  const handleChangeSelect = (name: string, value: any) => {
    formik.setFieldValue(name, value);
  };

  return (
    <Modal
      show={true}
      onHide={handleCLoseAllowanceDialog}
      size="lg"
      className="custom-modal profile modal-size-custom"
      centered
    >
      <Form onSubmit={formik.handleSubmit}>
        <Modal.Header closeButton>
          <Modal.Title>
            {lang(`${allowanceInfo?.id ? "GENERAL.ALLOWANCE.UPDATE" : "GENERAL.ALLOWANCE.ADD"}`)}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row className="gap-col-modal">
            <Col xs={3}>
              <Autocomplete
                lable={lang("ALLOWANCE.TYPE")}
                name="tenKhoanPhuCap"
                options={loaiPhuCapList}
                value={formik.values?.tenKhoanPhuCap || null}
                isReadOnly={!isEdit}
                onChange={(value) => handleChangeLoaiPhuCap(value)}
                isRequired
                errors={formik.errors?.tenKhoanPhuCap}
                touched={formik.touched?.tenKhoanPhuCap}
              />
            </Col>
            <Col xs={2}>
              <TextValidator
                lable={lang("ALLOWANCE.VALUE")}
                isRequired
                name="giaTri"
                type="text"
                readOnly={!isEdit}
                onChange={handleChangeMoney}
                value={convertNumberPrice(formik.values?.giaTri || null)}
                errors={formik.errors?.giaTri}
                touched={formik.touched?.giaTri}
              />
            </Col>
            <Col xs={4}>
              <RangeDatePicker
                label={lang("INPUT.HEALTHINSURANCE.EFFECTIVEDATE")}
                isRequired
                startDateName="thoiGianHieuLucBatDau"
                endDateName="thoiGianHieuLucKetThuc"
                isView={!isEdit}
                handleChange={formik.handleChange}
                value={formik.values}
                setFieldValue={formik.setFieldValue}
                touch={formik.touched}
                errors={formik.errors}
              />
            </Col>
            <Col xs={3}>
              <Autocomplete
                lable={lang("GENERAL.STATUS")}
                isReadOnly={!isEdit}
                options={LIST_STATUS_ALLOWANCE}
                value={formik.values?.trangThai || null}
                name="trangThai"
                isRequired
                onChange={(selectedOption) => handleChangeSelect(VARIABLE_STRING.TRANG_THAI, selectedOption)}
                errors={formik.errors?.trangThai}
                touched={formik.touched?.trangThai}
              />
            </Col>
            <Col xs={12}>
              <TextValidator
                lable={lang("ALLOWANCE.NOTE")}
                name="ghiChu"
                value={formik.values?.ghiChu || ""}
                as="textarea"
                rows={3}
                readOnly={!isEdit}
                onChange={formik.handleChange}
              />
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer className="flex-center">
          <GroupButton 
            type="btn-cancel"
            handleCancel={handleCLoseAllowanceDialog}
          />
          {!isResign &&
            (isEdit ? (
              <Button
                variant="primary"
                className="button-primary btn-sm"
                type="submit"
              >
                {lang("BTN.SAVE")}
              </Button>
            ) : (
              <Button
                variant="primary"
                className="button-primary btn-sm"
                onClick={(e: any) => {
                  e.preventDefault();
                  setIsEdit(true);
                }}
              >
                {lang("BTN.EDIT")}
              </Button>
            ))}
        </Modal.Footer>
      </Form>
    </Modal>
  );
};
export { EmployeeAllowanceDialog };
