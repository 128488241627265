/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useState, useContext, useEffect } from "react";
import { IDismissed } from "./model/IDismissed";
import { DismissedForm } from "./components/DismissedForm";
import { deleteDismissed, getListDismissed } from "./services/DismissedServices";
import { toast } from "react-toastify";
import { checkStatus, convertSearch, exportToFile, formatDateTable, hasAuthority, hasRole, searchDataRoleUser } from "../utils/FunctionUtils";
import { exportMienNhiem } from "../utils/ExportFileServices";
import { EXPORT_FILE_NAME, PERMISSIONS, PERMISSION_ABILITY, ROLE } from "../../Constant";
import { Col, Row } from "react-bootstrap";
import InputSearch from "../component/input-field/InputSearch";
import { convertDataUI } from "./utils/FunctionUtils";
import { IItemSearch } from "../profile/models/ProfileModels";
import { INIT_INPUT_SEARCH } from "../profile/const/ProfileConst";
import AdvancedSearch from "../profile/component/Dialog/AdvancedSearch";
import { ListSearch } from "./const/DismissedConst";
import TableCustom, { columnNamesType } from "../component/table-custom/TableCustom";
import useMultiLanguage from "../../hook/useMultiLanguage";
import { COMPLETED_STATUS, COMPLETED_STATUS_CODE, RESPONSE_STATUS_CODE, TYPE } from "../utils/Constant";
import AppContext from "../../AppContext";
import "./DismissedList.scss"
import { INIT_SEARCH_OBJECT } from "../constant";

const DismissedList: FC = () => {
  const { lang } = useMultiLanguage();
  const { setPageLoading } = useContext(AppContext);

  document.title = `${lang("GENERAL.APP.NAME")} | ${lang("GENERAL.DISMISSED")}`;
  const [dismissedInfo, setDismissedInfo] = useState<IDismissed>({} as IDismissed);
  const [dismissedList, setDismissedList] = useState<IDismissed[]>([]);
  const [totalPage, setTotalPage] = useState<number>(0);
  const [totalElements, setTotalElements] = useState<number>(0);
  const [numberOfElements, setNumberOfElements] = useState<number>(0);
  const [searchObject, setSearchObject] = useState<any>(INIT_SEARCH_OBJECT);
  const [isView, setIsView] = useState<boolean>(false)
  const [isAdvancedSearch, setIsAdvancedSearch] = useState<boolean>(false);
  const [listInputSearch, setListInputSearch] = useState<any[]>([INIT_INPUT_SEARCH]);
  const [openDismissedForm, setOpenDismissedForm] = useState<boolean>(false);

  useEffect(() => {
    if (searchObject?.pageIndex || searchObject?.pageSize) {
      handleSearch();
    }
  }, [searchObject?.pageIndex, searchObject?.pageSize])

  const handleAdvancedSearch = (data: IItemSearch[]) => {
    setListInputSearch(data);
    handleSearch({ ...convertSearch(data), pageIndex: 1 });
  };

  const handleOpenUpdateDialog = () => {
    setIsView(false);
  };

  const handleCloseUpdateDialog = () => {
    setIsView(true);
  };

  const handleOpenInfoDialog = (row: any) => {
    setIsView(true);
    setOpenDismissedForm(true);
    setDismissedInfo(convertDataUI(row));
  };

  const handleCloseDialog = () => {
    setOpenDismissedForm(false);
    setDismissedInfo({} as IDismissed);
    setIsView(false);
  };

  const handleCloseAndSearch = () =>{
    handleSearch();
    handleCloseDialog();
  }

  const handleOpenDialog = () => {
    setOpenDismissedForm(true);
  };

  const updatePageData = async (searchData?: any) => {
    try {
      setPageLoading(true);
      setSearchObject({ ...searchData });
      const { data } = await getListDismissed(hasRole(ROLE.USER) ? searchDataRoleUser(searchData) : { ...searchData });
      if (data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
        setDismissedList(data?.data?.content || []);
        setTotalPage(data?.data?.totalPages);
        setTotalElements(data?.data?.totalElements);
        setNumberOfElements(data?.data?.numberOfElements);
        setIsAdvancedSearch(false);
      } else {
        toast.error(data?.message);
      }
    } catch (error) {
      toast.error(lang("GENERAL.ERROR"));
    } finally {
      setPageLoading(false);
    }
  };

  const handleSearch = (data: any = {}) => {
    const dataSearch: any = {
      ...searchObject,
      ...data
    };
    dataSearch.keyword = dataSearch?.keyword?.trim() || ""
    updatePageData(dataSearch);
  };

  const handleOpenAdvancedSearch = () => {
    setIsAdvancedSearch(true);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchObject({
      ...searchObject,
      keyword: e.target?.value
    });
  };

  const handleDeleteDismissed = async (ids: string[]) => {
    try {
      const { data } = await deleteDismissed(ids)
      if (data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
        toast.success(lang("GENERAL.DELETE_SUCCESS"))
        updatePageData(searchObject);
        return true;
      } else {
        toast.error(data?.message)
      }
    }
    catch (err: any) {
      toast.error(err)
    }
  }

  const columns: columnNamesType[] = [
    {
      name: "STT",
      field: "",
      render: (row: any, index: number, stt: number) => <span>{stt}</span>
    },
    {
      name: lang("GENERAL.STATUS"),
      field: "",
      headerStyle: {
        minWidth: "140px"
      },
      render: (row: any) => <span className={`${checkStatus(COMPLETED_STATUS, row?.trangThai?.code)} status`}>
        {row?.trangThai?.name}
      </span>
    },
    {
      name: lang("PROFILE.CODE"),
      field: "maNhanVien",
      headerStyle: {
        minWidth: "120px"
      },
      render: (row: any) => <span className="color-steel-blue fw-600">{row?.maNhanVien}</span>
    },
    {
      name: lang("PROFILE.NAME"),
      field: "ten",
      headerStyle: {
        minWidth: "200px"
      },
      cellStyle: {
        textAlign: "left"
      },
    },
    {
      name: lang("GENERAL.CURRENT_ORGANIZATION"),
      field: "donViCongTacText",
      headerStyle: {
        minWidth: "200px"
      },
      cellStyle: {
        textAlign: "left"
      },
    },
    {
      name: lang("INPUT_DISMISSED_UNIT_DEPARTMENT"),
      field: "phongBanText",
      headerStyle: {
        minWidth: "200px"
      },
      cellStyle: {
        textAlign: "left"
      },
    },
    {
      name: lang("INPUT_DISMISSED_JOB_POSITION"),
      field: "chucVuText",
      headerStyle: {
        minWidth: "200px"
      },
      cellStyle: {
        textAlign: "left"
      },
    },
    {
      name: lang("INPUT_APPOINTMENT_DATE_START"),
      field: "thoiGianHieuLucTuNgay",
      headerStyle: {
        minWidth: "100px"
      },
      render: (row: any) => <span>{formatDateTable(row?.thoiGianHieuLucTuNgay)}</span>
    },
    {
      name: lang("INPUT_APPOINTMENT_DATE_END"),
      field: "thoiGianHieuLucDenNgay",
      headerStyle: {
        minWidth: "100px"
      },
      render: (row: any) => <span>{formatDateTable(row?.thoiGianHieuLucDenNgay)}</span>
    },
    {
      name: lang("INPUT_DISMISSED_WORK_UNIT_NEW"),
      field: "donViCongTacMoiText",
      headerStyle: {
        minWidth: "200px"
      },
      cellStyle: {
        textAlign: "left"
      },
    },
    {
      name: lang("INPUT_DISMISSED_UNIT_DEPARTMENT_NEW"),
      field: "phongBanMoiText",
      headerStyle: {
        minWidth: "200px"
      },
      cellStyle: {
        textAlign: "left"
      },
    },
    {
      name: lang("INPUT_DISMISSED_JOB_POSITION_NEW"),
      field: "chucVuMoiText",
      headerStyle: {
        minWidth: "200px"
      },
      cellStyle: {
        textAlign: "left"
      },
    },
    {
      name: lang("INPUT.WORKPROCESS.DECISION.NUMBER"),
      field: "soQuyetDinh",
      headerStyle: {
        minWidth: "200px"
      },
      cellStyle: {
        textAlign: "left"
      },
    },
  ]

  return (
    <div className="dismissed container-page flex-1">
      {!openDismissedForm &&
        <>
          <Row className="spaces mb-12 flex-middle">
            <Col xs={5} xl={6} xxl={7} className="spaces p-0">
              <h2 className="breadcrumb-title">{lang("GENERAL.DISMISSED.LIST")}</h2>
            </Col>
            <Col xs={7} xl={6} xxl={5} className="flex">
              <div className="spaces mr-10 flex-1">
                <InputSearch
                  className="border-3px"
                  value={searchObject?.keyword}
                  placeholder="Tìm kiếm theo mã NV, họ và tên"
                  handleSearch={() => handleSearch({ pageIndex: 1 })}
                  handleChange={handleChange}
                  isEnter
                />
              </div>
              <button
                className="spaces button-primary mr-10"
                onClick={() => handleSearch({ pageIndex: 1 })}
              >
                Tìm kiếm
              </button>
              <button
                className="spaces button-primary-outline mr-10"
                onClick={handleOpenAdvancedSearch}
              >
                Tìm nâng cao
              </button>

              {isAdvancedSearch && (
                <AdvancedSearch
                  open={isAdvancedSearch}
                  listInputSearch={listInputSearch}
                  handleSearch={handleAdvancedSearch}
                  listOption={ListSearch(lang)}
                  handeleClose={() => setIsAdvancedSearch(false)}
                />
              )}
            </Col>
          </Row>
          <Row className="bg-white">
            <Col xs={12} className="table-custom">
              <TableCustom
                id="dismissed-list"
                data={dismissedList}
                columns={columns}
                searchObject={searchObject}
                setSearchObject={setSearchObject}
                buttonAdd={hasAuthority(PERMISSIONS.MIEN_NHIEM, PERMISSION_ABILITY.CREATE)}
                buttonExportExcel={hasAuthority(PERMISSIONS.MIEN_NHIEM, PERMISSION_ABILITY.VIEW) && !hasRole(ROLE.USER)}
                notDelete={!hasAuthority(PERMISSIONS.MIEN_NHIEM, PERMISSION_ABILITY.DELETE)}
                handleExportExcel={(ids: string[]) =>
                  exportToFile({
                    exportAPI: () => exportMienNhiem({ ids, ...searchObject }),
                    fileName: EXPORT_FILE_NAME.MIEN_NHIEM,
                    setPageLoading
                  })
                }
                type={TYPE.MULTILINE}
                fixedColumnsCount={4}
                totalPages={totalPage}
                totalElements={totalElements}
                numberOfElements={numberOfElements}
                handleOpenDialog={handleOpenDialog}
                handleDoubleClick={handleOpenInfoDialog}
                handleDelete={(ids: string[]) => handleDeleteDismissed(ids)}
                deleteConditional={[
                  {
                    keyPath: "trangThai.code",
                    value: COMPLETED_STATUS_CODE.UNFULFILLED,
                  },
                  {
                    keyPath: "xacNhanMienNhiem",
                    value: false
                  }
                ]}
              />
            </Col>
          </Row>
        </>
      }
      {openDismissedForm && (
        <DismissedForm
          isView={isView}
          setIsView={setIsView}
          dismissedInfo={dismissedInfo}
          handleCloseDialog={handleCloseDialog}
          handleCloseAndSearch={handleCloseAndSearch}
          handleCloseUpdateDialog={handleCloseUpdateDialog}
          handleOpenUpdateDialog={handleOpenUpdateDialog}
        />
      )}
    </div>
  );
};
export { DismissedList };
