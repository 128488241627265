import "./listMenuVertical.scss";
interface Iprops {
  listData: any[];
  fieldTitle: string;
  activeIndex: number;
  handleChangeActiveItem: (index: number, dataActive: any) => void;
}

function ListMenuVertical(props: Iprops) {
  const { listData, fieldTitle, activeIndex, handleChangeActiveItem } = props;
  return (
    <div className="menu-vertical">
      <ul className="menu-vertical__container">
        {listData?.map((item, index) => (
          <li
            className="nav-item"
            key={index}
            onClick={() => handleChangeActiveItem(index, item)}
            title={item[fieldTitle]}
          >
            <a className={`nav-link ${index === activeIndex ? "active" : ""}`}>
              <span className="line-clamp-1">{item[fieldTitle]}</span>
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default ListMenuVertical;
