import { FC, useContext, useEffect, useState } from "react";
import { IUnit } from "../model/ManagerModel";
import { Row as RowTable } from "react-table";
import { toast } from "react-toastify";
import { deleteUnit, getListUnit } from "../services/ManagerServices";
import { UnitDialog } from "./Dialog/UnitDialog";
import TableCustom, { columnNamesType } from "../../component/table-custom/TableCustom";
import useMultiLanguage from "../../../hook/useMultiLanguage";
import { RESPONSE_STATUS_CODE, TYPE } from "../../utils/Constant";
import { IObject } from "../../models/models";
import { INIT_SEARCH_OBJECT } from "../../constant";
import AppContext from "../../../AppContext";

const UnitList: FC = () => {
  const { lang } = useMultiLanguage();
  const { setPageLoading } = useContext(AppContext);

  const [unitList, setUnitList] = useState<IUnit[]>([]);
  const [unitInfo, setUnitInfo] = useState<IUnit>({} as IUnit);
  const [totalPage, setTotalPage] = useState<number>(0);
  const [totalElements, setTotalElements] = useState<number>(0);
  const [numberOfElements, setNumberOfElements] = useState<number>(0);
  const [openUnitDialog, setOpenUnitDialog] = useState<boolean>(false);
  const [searchObject, setSearchObject] = useState<IObject>(INIT_SEARCH_OBJECT);

  useEffect(() => {
    loadListUnit(searchObject);
  }, [searchObject?.pageIndex, searchObject?.pageSize])

  const handleOpenUnitDialog = (data: RowTable<IUnit>) => {
    setOpenUnitDialog(true);
    setUnitInfo(data?.original);
  };

  const handleCloseUnitDialog = () => {
    setOpenUnitDialog(false);
    setUnitInfo({} as IUnit);
  };

  const loadListUnit = async (searchObject?: any) => {
    try {
      setPageLoading(true);
      const res = await getListUnit({ ...searchObject });
      const { data } = res?.data || {};
      setUnitList(data?.content);
      setTotalPage(data?.totalPages || 0);
      setTotalElements(data?.totalElements || 0);
      setNumberOfElements(data?.numberOfElements || 0);
    } catch (err) {
      toast.error(lang("GENERAL.ERROR"));
    } finally {
      setPageLoading(false);
    }
  };

  const handleDeleteUnit= async (ids: string[]) => {
    try {
      const res = await deleteUnit(ids);
      if (res?.data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
        toast.success(lang("GENERAL.DELETE_SUCCESS"));
        return true;
      } else toast.error(`${res?.data?.message}`);
    } catch (error) {
      toast.error(lang("GENERAL.ERROR"));
    }
  }

  const columns: columnNamesType[] = [
    {
      name: lang("TABLE.INDEX"),
      field: "stt",
      render: (row, index, stt) => <span>{stt}</span>
    },
    {
      name: lang("UNIT.NAME"),
      field: "name",
      headerStyle: {
        minWidth: "150px"
      }
    },
    {
      name: lang("UNIT.CODE"),
      field: "code",
      headerStyle: {
        minWidth: "250px"
      }
    },
    {
      name: lang("UNIT.TYPE"),
      field: "orgType",
      headerStyle: {
        minWidth: "150px"
      }
    }
  ];

  return (
    <div>
      <TableCustom
        title={lang("UNIT.LIST")}
        isActionTableTab
        data={unitList}
        columns={columns}
        searchObject={searchObject}
        setSearchObject={setSearchObject}
        handleDelete={(ids: string[]) => handleDeleteUnit(ids)}
        noToolbar={true}
        buttonAdd={true}
        handleOpenDialog={() => {
          // setOpenUnitDialog(true);
        }}
        justFilter={false}
        notDelete={true}
        totalPages={totalPage}
        totalElements={totalElements}
        numberOfElements={numberOfElements}
        unSelectedAll={true}
        type={TYPE.MULTILINE}
      />
      {openUnitDialog && (
        <UnitDialog
          handleCloseDialog={handleCloseUnitDialog}
          unitInfo={unitInfo}
          handleReload={loadListUnit}
        />
      )}
    </div>
  );
};
export { UnitList };
