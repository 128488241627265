import { IColumnsTotal } from "../../component/TableGrouping/TableGrouping";
import { convertNumberPrice } from "../../utils/FunctionUtils";

export const columnSalaryFundTotal: IColumnsTotal[] = [
  {
    colSpan: 7,
    isTitle: true,
    field: "",
    cellStyle: {
      fontSize: "15px",
      fontWeight: "bold",
      textAlign: "left",
      paddingLeft: "16px"
    }
  },
  {
    field: "heSoBacLuong",
    render: (row) => <span>{row?.heSoBacLuong?.toFixed(2)}</span>
  },
  {
    field: "tienPhuCapTnvk",
    render: (row) => <span>{convertNumberPrice(row?.tienPhuCapTnvk)}</span>
  },
  {
    field: "tienPhuCapChucVu",
    render: (row) => <span>{convertNumberPrice(row?.tienPhuCapChucVu)}</span>
  },
  {
    field: "tienPhuCapTrachNhiem",
    render: (row) => <span>{convertNumberPrice(row?.tienPhuCapTrachNhiem)}</span>
  },
  {
    field: "tienPhuCapDocHai",
    render: (row) => <span>{convertNumberPrice(row?.tienPhuCapDocHai)}</span>
  },
  {
    field: "tienPhuCapUuDai",
    render: (row) => <span>{convertNumberPrice(row?.tienPhuCapUuDai)}</span>
  },
  {
    field: "tienPhuCapKhac",
    render: (row) => <span>{convertNumberPrice(row?.tienPhuCapKhac)}</span>
  },
  {
    field: "tongSo",
    render: (row) => <span>{convertNumberPrice(row?.tongSo)}</span>
  },
  {
    field: "luongTheoNgachBac",
    render: (row) => <span>{convertNumberPrice(row?.luongTheoNgachBac)}</span>
  },
  {
    field: "phuCapTnvk",
    render: (row) => <span>{convertNumberPrice(row?.phuCapTnvk)}</span>
  },
  {
    field: "tongSoPhuCap",
    render: (row) => <span>{convertNumberPrice(row?.tongSoPhuCap)}</span>
  },
  {
    field: "phuCapChucVu",
    render: (row) => <span>{row?.phuCapChucVu?.toFixed(2)}</span>
  },
  {
    field: "phuCapTrachNhiem",
    render: (row) => <span>{row?.phuCapChucVu?.toFixed(2)}</span>
  },
  {
    field: "phuCapDocHai",
    render: (row) => <span>{row?.phuCapChucVu?.toFixed(2)}</span>
  },
  {
    field: "phuCapUuDai",
    render: (row) => <span>{row?.phuCapChucVu?.toFixed(2)}</span>
  },
  {
    field: "phuCapKhac",
    render: (row) => <span>{row?.phuCapChucVu?.toFixed(2)}</span>
  },
  {
    field: "ghiChu"
  }
];
