import { GroupButton } from "../../component/GroupButton";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Button, Col, Form, Row } from "react-bootstrap";
import useMultiLanguage from "../../../hook/useMultiLanguage";
import { convertNumberPrice, convertTextPrice, exportToFile, formatDateTable, handleBlurDate } from "../../utils/FunctionUtils";
import { useContext, useEffect, useState } from "react";
import AppContext from "../../../AppContext";
import TextValidator from "../../component/input-field/TextValidator";
import { RESPONSE_STATUS_CODE, TYPE, VARIABLE_STRING } from "../../utils/Constant";
import Autocomplete from "../../component/input-field/Autocomplete";
import { IFile } from "../../profile/models/DialogModels";
import UploadFile from "../../component/FileUpload/UploadFile";
import { FILE_TYPE } from "../../component/FileUpload/constant";
import { ColCustom, RowCustom } from "../../component/GridComponent";
import TableCollapseCustom, {
  columnNamesType as columnNamesTypeCollapse
} from "../../component/table-collapse-custom/TableCollapseCustom";
import { columnNamesType } from "../../component/table-custom/TableCustom";
import SelectEmployeeByDepartment from "../../component/SelectEmployeeByDepartment/SelectEmployeeByDepartment";
import { STATUS_NV } from "../../profile/const/DialogChildConstants";
import SelectEmployeeDialog from "../../component/SelectEmployeeDialog/SelectEmployeeDialog";
import { toast } from "react-toastify";
import { IEmployeeSelect, IEmployeeSelectList, IInsurance, IPropsForm } from "../models/insuranceModel";
import {
  INSURANCE_OBJECTS,
  INSURANCE_OBJECTS_CODE,
  INSURANCE_STATUS_CODE,
  INSURANCE_STATUS_LIST,
  INIT_INSURANCE,
  INSURANCE_TYPES
} from "../constant/isuranceConst";
import { convertDataEmployeeSelect, convertDataEmployeeSingle, convertSaveDataEmployee } from "../utils/insuranceUtils";
import { RangeDatePicker } from "../../component/input-field/RangeDatePicker";
import { OptionReactSelect } from "../../models/models";
import { addNewInsurance, getInsuranceById, updateInsurance } from "../services/insuranceServices";
import { KTSVG } from "../../../../_metronic/helpers";
import { EXPORT_FILE_NAME } from "../../../Constant";
import { exportBaoHiemPhucLoiChiTiet } from "../../utils/ExportFileServices";
import { checkInvalidDate } from "../../utils/ValidationSchema";

function CombineHumanInsuranceForm(props: IPropsForm<IInsurance>) {
  const {
    dataEdit,
    isView,
    handleCloseDialog,
    handleCloseUpdateDialog,
    handleOpenUpdateDialog,
    handleCloseAndSearch
  } = props;
  const { lang, intl } = useMultiLanguage();
  const { setPageLoading } = useContext(AppContext);

  const [employeeChecked, setEmployeeChecked] = useState<IEmployeeSelect[]>([]);
  const [employeeList, setEmployeeList] = useState<IEmployeeSelectList[]>([]);
  const [shouldOpenSelectEmployeeDialog, setShouldOpenSelectEmployeeDialog] = useState<boolean>(false);
  const [shouldOpenEmployeeListSigleDialog, setShouldOpenEmployeeListSigleDialog] = useState<boolean>(false);

  useEffect(() => {
    if (dataEdit?.id) {
      formik.setValues(dataEdit);
      getEmployeeList();
    }
  }, [dataEdit]);

  const getEmployeeList = async () => {
    try {
      setPageLoading(true);
      const { data } = await getInsuranceById(dataEdit?.baoHiemId);
      if (data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
        setEmployeeList(data?.data?.thamGiaList);
      }
    } catch (error) {
      toast.error(lang("GENERAL.ERROR"));
    } finally {
      setPageLoading(false);
    }
  };

  const handleSubmit = async (values: IInsurance) => {
    if (employeeList?.length === 0) return toast.warning(lang("TOAST.PROBLEM.PLEASE_ADD_EMPLOYEE_LIST"));
    if (
      employeeList?.length === 1 &&
      employeeList[0]?.data?.length === 1 &&
      formik.values?.doiTuong?.code === INSURANCE_OBJECTS_CODE.GROUP
    ) {
      return toast.warning(lang("TOAST.EMPLOYEE.GROUP_BIGEST_TWO"));
    }

    try {
      setPageLoading(true);
      const dataSubmit: IInsurance = {
        ...values,
        thamGiaList: employeeList?.map((item: any) => item?.data)?.flat(),
        type: INSURANCE_TYPES.COMBINE_HUMAN
      };
      const { data } = await (!dataEdit?.id
        ? addNewInsurance(dataSubmit)
        : updateInsurance(dataSubmit, dataEdit?.baoHiemId));

      if (data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
        let message = dataEdit?.id ? "TOAST.PROBLEM.EDIT_SUCCESS" : "TOAST.PROBLEM.ADD_SUCCESS";
        toast.success(lang(message));
        handleCloseAndSearch();
      } else {
        toast.error(data?.message);
      }
    } catch (error) {
      toast.error(lang("GENERAL.ERROR"));
    } finally {
      setPageLoading(false);
    }
  };

  const validationSchema = Yup.object().shape({
    doiTuong: Yup.object().required(lang("VALIDATION.REQUIRE")).nullable(),
    trangThai: Yup.object().required(lang("VALIDATION.REQUIRE")).nullable(),
    mucMua: Yup.number()
      .required(lang("VALIDATION.REQUIRE"))
      .min(1, lang("INSURANCE.VADIDATION.MIN_BUY_LEVEL"))
      .nullable(),
    soTienCongTyHoTro: Yup.number()
      .required(lang("VALIDATION.REQUIRE"))
      .max(Yup.ref("mucMua"), lang("INSURANCE.VADIDATION.MAX_COMPANY_SUPPORT"))
      .nullable(),
    soTienNhanVienDong: Yup.number().required(lang("VALIDATION.REQUIRE")).nullable(),
    hieuLucTuNgay: Yup.date()
      .concat(checkInvalidDate(intl))
      .when(VARIABLE_STRING.HIEU_LUC_DEN_NGAY, {
        is: (hieuLucDenNgay: Date | null) => hieuLucDenNgay,
        then: Yup.date()
          .max(
            Yup.ref(VARIABLE_STRING.HIEU_LUC_DEN_NGAY),
            lang("INSURANCE.VADIDATION.MAX_END_DATE")
          )
          .nullable()
      })
      .nullable(),
    hieuLucDenNgay: Yup.date()
      .when(VARIABLE_STRING.HIEU_LUC_TU_NGAY, {
        is: (hieuLucTuNgay: Date | null) => hieuLucTuNgay,
        then: Yup.date()
          .max(
            Yup.ref(VARIABLE_STRING.HIEU_LUC_TU_NGAY),
            lang("INSURANCE.VADIDATION.MIN_END_DATE")
          )
          .nullable()
      })
      .concat(checkInvalidDate(intl))
      .nullable(),
  });

  const formik = useFormik({
    initialValues: INIT_INSURANCE,
    onSubmit: handleSubmit,
    validationSchema
  });

  const handleChangeSelect = (name: string, value: OptionReactSelect) => {
    switch (name) {
      case VARIABLE_STRING.DOI_TUONG:
        setEmployeeList([]);
        formik.setFieldValue(name, value);
        break;
      default:
        formik.setFieldValue(name, value);
        break;
    }
  };

  const handleFile = (data: IFile) => {
    formik.setFieldValue("fileId", data.id);
    formik.setFieldValue("fileName", data.name);
  };

  const handleDeleteEmployeeSelect = () => {
    const newSelectedData: IEmployeeSelectList[] = [];
    employeeList?.forEach((row) => {
      if (!row?.isParentChecked) {
        newSelectedData.push({
          ...row,
          data: (row?.data || []).filter(
            (oldItem) => !employeeChecked?.some((removeItem) => oldItem?.employeeId === removeItem?.employeeId)
          )
        });
      }
    });

    setEmployeeList(newSelectedData);
    setEmployeeChecked([]);
  };

  const handleSetEmployeeSelect = (data: IEmployeeSelectList[]) => {
    setEmployeeList(
      convertSaveDataEmployee(data, {
        moneyCompanySupport: formik.values.soTienCongTyHoTro,
        moneyEmployeePay: formik.values.soTienNhanVienDong
      })
    );
  };

  const handleSaveEmployeeSingle = (employees: IEmployeeSelect[]) => {
    setEmployeeList(
      convertDataEmployeeSingle(employees, formik.values.soTienCongTyHoTro, formik.values.soTienNhanVienDong)
    );
  };

  const handleUpdateMoneySuportEmployeeList = () => {
    setEmployeeList(
      convertSaveDataEmployee(employeeList, {
        moneyCompanySupport: formik.values.soTienCongTyHoTro
      })
    );
  };

  const handleUpdateMoneyPayEmployeeList = () => {
    setEmployeeList(
      convertSaveDataEmployee(employeeList, {
        moneyEmployeePay: formik.values.soTienNhanVienDong
      })
    );
  };

  const handleOpenEmployeeSelectDialog = () => {
    if (formik.values?.doiTuong?.code === INSURANCE_OBJECTS_CODE.PERSONAL) {
      setShouldOpenEmployeeListSigleDialog(true);
    } else {
      setShouldOpenSelectEmployeeDialog(true);
    }
  };

  const handleChangeFieldTable = (
    event: React.ChangeEvent<HTMLInputElement>,
    index: number,
    parentIndex: number,
    name: "employeeSoTienCongTyHoTro" | "employeeSoTienDong" | "lanMua"
  ) => {
    const { value } = event.target;
    let cloneEmployeeList = employeeList;
    cloneEmployeeList[parentIndex].data[index][name] = Number(convertTextPrice(value));
    setEmployeeList([...cloneEmployeeList]);
  };

  const handleChangeMoney = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    let valueConverted = Number(convertTextPrice(value));

    switch (name) {
      case VARIABLE_STRING.MUC_MUA:
        let calcEmployee = valueConverted - Number(formik.values.soTienCongTyHoTro);
        formik.setValues({
          ...formik.values,
          [name]: valueConverted,
          soTienNhanVienDong: calcEmployee > 0 ? calcEmployee : 0
        });
        break;
      case VARIABLE_STRING.SO_TIEN_CTY_HO_TRO:
        let calcEmployeeSuport = Number(formik.values.mucMua) - valueConverted;
        formik.setValues({
          ...formik.values,
          [name]: valueConverted,
          soTienNhanVienDong: calcEmployeeSuport > 0 ? calcEmployeeSuport : 0
        });
        break;
      default:
        formik.setFieldValue(name, valueConverted);
        break;
    }
  };

  const handleExport = () => {
    exportToFile({
      exportAPI: () => exportBaoHiemPhucLoiChiTiet({ id: dataEdit?.baoHiemId, type: INSURANCE_TYPES.COMBINE_HUMAN }),
      fileName: EXPORT_FILE_NAME.DETAIL_KET_HOP_CON_NGUOI,
      setPageLoading
    });
  };

  const employeeColumns: columnNamesTypeCollapse[] = [
    {
      name: "STT",
      field: "",
      render: (_, index) => <span>{index + 1}</span>
    },
    {
      name: lang("EMPLOYEE.ID"),
      field: "maNhanVien",
      headerCellProps: {
        minWidth: "100px"
      }
    },
    {
      name: lang("GENERAL.FULLNAME"),
      field: "tenNhanVien",
      headerCellProps: {
        minWidth: "200px"
      },
      bodyCellProps: {
        minWidth: "200px",
        textAlign: "left"
      }
    },
    {
      name: lang("INPUT.BIRTHDAY"),
      field: "ngaySinh",
      headerCellProps: {
        minWidth: "120px"
      },
      bodyCellProps: {
        textAlign: "center"
      },
      render: (row) => <span>{formatDateTable(row?.ngaySinh)}</span>
    },
    {
      name: lang("INPUT.SALARY.DEPARTMENT"),
      field: "phongBanText",
      headerCellProps: {
        minWidth: "300px"
      },
      bodyCellProps: {
        textAlign: "left"
      }
    },
    {
      name: lang("INSURANCE.POSITION"),
      field: "chucVuText",
      headerCellProps: {
        minWidth: "200px"
      },
      bodyCellProps: {
        textAlign: "left"
      }
    },
    {
      name: lang("INSURANCE.MONEY_COMPANY_PAY"),
      field: "field",
      headerCellProps: {
        minWidth: "200px"
      },
      bodyCellProps: {
        textAlign: "left"
      },
      render: (row, index, parentIndex) => (
        <span onClick={(event) => event.stopPropagation()}>
          <TextValidator
            name="employeeSoTienCongTyHoTro"
            lable={lang("INSURANCE.MONEY_COMPANY_PAY")}
            type="text"
            value={convertNumberPrice(row?.employeeSoTienCongTyHoTro, true)}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              handleChangeFieldTable(event, index, parentIndex, "employeeSoTienCongTyHoTro")
            }
            readOnly={isView}
            showLabel={false}
          />
        </span>
      )
    },
    {
      name: lang("INSURANCE.MONEY_EMPLOYEE_PAY"),
      field: "field",
      headerCellProps: {
        minWidth: "200px"
      },
      bodyCellProps: {
        textAlign: "left"
      },
      render: (row, index, parentIndex) => (
        <span onClick={(event) => event.stopPropagation()}>
          <TextValidator
            name="employeeSoTienDong"
            lable={lang("INSURANCE.MONEY_EMPLOYEE_PAY")}
            type="text"
            value={convertNumberPrice(row?.employeeSoTienDong, true)}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              handleChangeFieldTable(event, index, parentIndex, "employeeSoTienDong")
            }
            readOnly={isView}
            showLabel={false}
          />
        </span>
      )
    },
    {
      name: lang("INSURANCE.BUY_TIMES"),
      field: "field",
      headerCellProps: {
        minWidth: "200px"
      },
      bodyCellProps: {
        textAlign: "left"
      },
      render: (row, index, parentIndex) => (
        <span onClick={(event) => event.stopPropagation()}>
          <TextValidator
            name="lanMua"
            lable={lang("INSURANCE.BUY_TIMES")}
            type="number"
            value={row?.lanMua}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              handleChangeFieldTable(event, index, parentIndex, "lanMua")
            }
            readOnly={isView}
            showLabel={false}
          />
        </span>
      )
    }
  ];

  const columnsSelected: columnNamesTypeCollapse[] = [
    {
      name: lang("GENERAL.FULLNAME"),
      field: "custom",
      headerCellProps: {
        minWidth: "200px"
      },
      bodyCellProps: {
        textAlign: "left"
      },
      render: (row) => (
        <span>
          <p className="color-steel-blue fw-600 spaces p-0 m-0">{row?.maNhanVien}</p>
          <p className="spaces p-0 m-0">{row?.tenNhanVien}</p>
        </span>
      )
    },
    {
      name: lang("INPUT.BIRTHDAY"),
      field: "ngaySinh",
      headerCellProps: {
        minWidth: "80px"
      },
      bodyCellProps: {
        textAlign: "center"
      },
      render: (row) => <span>{formatDateTable(row?.ngaySinh)}</span>
    },
    {
      name: lang("INPUT.SALARY.DEPARTMENT"),
      field: "phongBanText",
      headerCellProps: {
        minWidth: "200px"
      },
      bodyCellProps: {
        textAlign: "left"
      }
    },
    {
      name: lang("INSURANCE.POSITION"),
      field: "chucVuText",
      headerCellProps: {
        minWidth: "200px"
      },
      bodyCellProps: {
        textAlign: "left"
      }
    }
  ];

  const columnsSelect: columnNamesType[] = [
    {
      name: "STT",
      field: "stt",
      render: (_, index, stt) => <span>{stt}</span>
    },
    {
      name: lang("GENERAL.FULLNAME"),
      field: "custom",
      headerStyle: {
        minWidth: "200px"
      },
      cellStyle: {
        textAlign: "left"
      },
      render: (row) => (
        <span>
          <p className="color-steel-blue fw-600 spaces p-0 m-0">{row?.maNhanVien}</p>
          <p className="spaces p-0 m-0">{row?.tenNhanVien}</p>
        </span>
      )
    },
    {
      name: lang("INPUT.BIRTHDAY"),
      field: "ngaySinh",
      headerStyle: {
        minWidth: "80px"
      },
      cellStyle: {
        textAlign: "center"
      },
      render: (row) => <span>{formatDateTable(row?.ngaySinh)}</span>
    },
    {
      name: lang("INPUT.SALARY.DEPARTMENT"),
      field: "phongBanText",
      headerStyle: {
        minWidth: "200px"
      },
      cellStyle: {
        textAlign: "left"
      }
    },
    {
      name: lang("INSURANCE.POSITION"),
      field: "chucVuText",
      headerStyle: {
        minWidth: "200px"
      },
      cellStyle: {
        textAlign: "left"
      }
    }
  ];

  return (
    <div className="problem-form">
      <Form onSubmit={formik.handleSubmit}>
        <Row className="contract-header header-bo spaces py-12">
          <Col xs={12}>
            <div className="profile-title">
              <GroupButton type="btn-back" handleClose={handleCloseDialog} />
              <div className="flex">
                {dataEdit?.id && (
                  <button
                    className="spaces flex flex-middle table-btn outline mr-16"
                    onClick={handleExport}
                    type="button"
                  >
                    <KTSVG path="/media/icons/export-excel.svg" /> {lang("BTN.EXPORT")}
                  </button>
                )}
                {formik.values?.trangThai?.code !== INSURANCE_STATUS_CODE.COMPLETED &&
                  (isView ? (
                    <GroupButton type="btn-edit" handleSaveEdit={handleOpenUpdateDialog} />
                  ) : (
                    <>
                      {dataEdit.id && (
                        <Button
                          variant="outline-secondary"
                          className="button-primary-outline me-2"
                          onClick={handleCloseUpdateDialog}
                          type="button"
                        >
                          {lang("BTN.CANCEL")}
                        </Button>
                      )}
                      <GroupButton type="btn-save" handleClose={handleCloseUpdateDialog} />
                    </>
                  ))}
              </div>
            </div>
          </Col>
        </Row>
        <div className="form-container bg-white">
          <Row className="p-4 w-100">
            <Col xs={12} className="relative z-index-2">
              <div className="sub-title-form">
                {dataEdit?.id ? lang("INSURANCE.COMBINE_HUMAN.EDIT") : lang("INSURANCE.COMBINE_HUMAN.ADD")}
              </div>
              <div className="spaces mb-6">
                <span className="fs-3 fw-bold">{lang("INSURANCE.COMBINE_HUMAN.INFOMATION")}</span>
              </div>
              <RowCustom xs={1} sm={1} md={2} lg={3} xl={4} xxl={5}>
                <ColCustom>
                  <Row>
                    <Col xs={6}>
                      <Autocomplete
                        isRequired
                        lable={lang("INSURANCE.OBJECT")}
                        options={INSURANCE_OBJECTS}
                        isReadOnly={isView}
                        value={formik.values?.doiTuong}
                        name="doiTuong"
                        onChange={(value) => handleChangeSelect(VARIABLE_STRING.DOI_TUONG, value)}
                        isClearable={false}
                        touched={formik.touched?.doiTuong}
                        errors={formik.errors?.doiTuong}
                      />
                    </Col>
                    <Col xs={6}>
                      <TextValidator
                        lable={lang("INSURANCE.BUY_DATE")}
                        type="date"
                        name="ngayMua"
                        value={formik.values?.ngayMua}
                        onChange={formik.handleChange}
                        onBlur={() => handleBlurDate(formik.setFieldValue, formik.values?.ngayMua, "ngayMua")}
                        readOnly={isView}
                        touched={formik.touched?.ngayMua}
                        errors={formik.errors?.ngayMua}
                      />
                    </Col>
                  </Row>
                </ColCustom>
                <ColCustom>
                  <TextValidator
                    lable={lang("INSURANCE.CONĐITION_A")}
                    type="text"
                    name="dieuKienA"
                    value={formik.values?.dieuKienA}
                    onChange={formik.handleChange}
                    readOnly={isView}
                    touched={formik.touched?.dieuKienA}
                    errors={formik.errors?.dieuKienA}
                  />
                </ColCustom>
                <ColCustom>
                  <TextValidator
                    lable={lang("INSURANCE.CONĐITION_B")}
                    type="text"
                    name="dieuKienB"
                    value={formik.values?.dieuKienB}
                    onChange={formik.handleChange}
                    readOnly={isView}
                    touched={formik.touched?.dieuKienB}
                    errors={formik.errors?.dieuKienB}
                  />
                </ColCustom>
                <ColCustom>
                  <TextValidator
                    lable={lang("INSURANCE.CONĐITION_C")}
                    type="text"
                    name="dieuKienC"
                    value={formik.values?.dieuKienC}
                    onChange={formik.handleChange}
                    readOnly={isView}
                    touched={formik.touched?.dieuKienC}
                    errors={formik.errors?.dieuKienC}
                  />
                </ColCustom>
                <ColCustom>
                  <TextValidator
                    lable={lang("INSURANCE.BUY_LEVEL")}
                    isRequired
                    type="text"
                    name="mucMua"
                    value={convertNumberPrice(formik.values?.mucMua, true)}
                    onChange={handleChangeMoney}
                    readOnly={isView}
                    touched={formik.touched?.mucMua}
                    errors={formik.errors?.mucMua}
                  />
                </ColCustom>
                <ColCustom>
                  <TextValidator
                    lable={lang("INSURANCE.COMPANY_SUPPORT")}
                    isRequired
                    type="text"
                    name="soTienCongTyHoTro"
                    value={convertNumberPrice(formik.values?.soTienCongTyHoTro, true)}
                    onChange={handleChangeMoney}
                    readOnly={isView}
                    touched={formik.touched?.soTienCongTyHoTro}
                    errors={formik.errors?.soTienCongTyHoTro}
                    isIconSync
                    titleIconSync="Cập nhập cho danh sách nhân viên"
                    handleIconSync={handleUpdateMoneySuportEmployeeList}
                  />
                </ColCustom>
                <ColCustom>
                  <TextValidator
                    lable={lang("INSURANCE.EMPLOYEE_PAY")}
                    isRequired
                    type="text"
                    name="soTienNhanVienDong"
                    value={convertNumberPrice(formik.values?.soTienNhanVienDong, true)}
                    onChange={handleChangeMoney}
                    readOnly={isView}
                    touched={formik.touched?.soTienNhanVienDong}
                    errors={formik.errors?.soTienNhanVienDong}
                    isIconSync
                    titleIconSync="Cập nhập cho danh sách nhân viên"
                    handleIconSync={handleUpdateMoneyPayEmployeeList}
                  />
                </ColCustom>
                <ColCustom>
                  <RangeDatePicker
                    label={lang("INSURANCE.DATE_EFFECT")}
                    startDateName="hieuLucTuNgay"
                    endDateName="hieuLucDenNgay"
                    isView={isView}
                    handleChange={formik.handleChange}
                    value={formik.values}
                    setFieldValue={formik.setFieldValue}
                    touch={formik.touched}
                    errors={formik.errors}
                  />
                </ColCustom>
                <ColCustom>
                  <Autocomplete
                    isRequired
                    lable={lang("GENERAL.STATUS")}
                    options={INSURANCE_STATUS_LIST}
                    isReadOnly={isView}
                    value={formik.values?.trangThai || null}
                    name="trangThai"
                    onChange={(value) => handleChangeSelect(VARIABLE_STRING.TRANG_THAI, value)}
                    isClearable={false}
                    touched={formik.touched?.trangThai}
                    errors={formik.errors?.trangThai}
                  />
                </ColCustom>
                <ColCustom>
                  <UploadFile
                    label="INPUT_APPOINTMENT_ATTACHMENTS"
                    setValue={handleFile}
                    fileValue={{
                      id: formik.values?.fileId || "",
                      name: formik.values?.fileName || ""
                    }}
                    allowFileTypes={`${FILE_TYPE.PDF},${FILE_TYPE.DOC},${FILE_TYPE.DOCX},${FILE_TYPE.XLS},${FILE_TYPE.XLSX}`}
                    isReadOnly={isView}
                  />
                </ColCustom>
                <ColCustom xs={1} sm={1} md={2} lg={3} xl={4} xxl={5}>
                  <TextValidator
                    lable={lang("GENERAL.NOTE")}
                    type="text"
                    name="note"
                    value={formik.values?.note || ""}
                    onChange={formik.handleChange}
                    readOnly={isView}
                    as={TYPE.TEXTAREA}
                    rows={3}
                    touched={formik.touched?.note}
                    errors={formik.errors?.note}
                  />
                </ColCustom>
              </RowCustom>
            </Col>
          </Row>
          <Row className="p-4 w-100">
            <TableCollapseCustom
              title={lang("PROBLEM.EMPLOYEE_LIST")}
              columnNameList={employeeColumns}
              data={employeeList || []}
              nameParent="phongBanText"
              nameChildren="data"
              selectData={setEmployeeChecked}
              setData={setEmployeeList}
              selectionMode="multiple"
              isSelect={true}
              buttonAdd={!isView}
              handleOpenDialog={handleOpenEmployeeSelectDialog}
              handleDelete={handleDeleteEmployeeSelect}
              notDelete={isView}
              uniquePrefix="employeeId"
              disabledSelect={isView}
            />
          </Row>
        </div>
      </Form>
      {shouldOpenSelectEmployeeDialog && (
        <SelectEmployeeByDepartment
          title={lang("GENERAL.LIST_EMPLOYEE_BY_DEPARTMENT")}
          open={shouldOpenSelectEmployeeDialog}
          handleClose={() => setShouldOpenSelectEmployeeDialog(false)}
          parentSelectedData={employeeList || []}
          setParentSelectedData={handleSetEmployeeSelect}
          funcConvert={convertDataEmployeeSelect}
          prefixNameChildren="data"
          columnsEmployeeDepartment={columnsSelect}
          columnsSelected={columnsSelected}
          searchEmployeeObject={{
            trangThaiLamViec: Object.values(STATUS_NV)
              .filter((status) => status !== STATUS_NV.THOI_VIEC)
              .join(",")
          }}
        />
      )}

      {shouldOpenEmployeeListSigleDialog && (
        <SelectEmployeeDialog
          open={shouldOpenEmployeeListSigleDialog}
          handleClose={() => setShouldOpenEmployeeListSigleDialog(false)}
          handleSaveData={handleSaveEmployeeSingle}
          dataParent={employeeList[0]?.data || []}
          columnsCustom={columnsSelect}
          funcConvert={convertDataEmployeeSelect}
          uniquePrefix="employeeId"
        />
      )}
    </div>
  );
}

export default CombineHumanInsuranceForm;
