import { useFormik } from "formik";
import { useEffect } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import useMultiLanguage from "../../../hook/useMultiLanguage";
import TextValidator from "../../component/input-field/TextValidator";
import { IExpenses } from "../model/WelfareModel";
import Autocomplete from "../../component/input-field/Autocomplete";
import { INIT_EXPENSES } from "../const/WelfareConst";
import { searchNguoiDaiDienKy } from "../../contract/services/contractServices";
import { TYPE, VARIABLE_STRING } from "../../utils/Constant";
import * as Yup from "yup";
import { convertNumberPrice, convertTextPrice } from "../../utils/FunctionUtils";

interface Props {
  handleCloseDialog: () => void;
  isView: boolean;
  expenses: IExpenses;
  handleAdd: (data: IExpenses) => void;
  handleEdit: (data: IExpenses) => void;
  currentIndex: number | null;
}
export const ActualCostDialog = (props: Props) => {
  const { lang } = useMultiLanguage();

  const { handleCloseDialog, expenses, isView, handleAdd, handleEdit, currentIndex } = props;

  useEffect(() => {
    if (expenses) {
      let dataConverted = {
        ...expenses,
        nguoiDuyet: expenses?.nguoiDuyetId
          ? {
              id: expenses?.nguoiDuyetId || "",
              name: expenses?.tenNguoiDuyet || ""
            }
          : null
      };
      formik.setValues(dataConverted);
    }
  }, [expenses]);

  const handleFormSubmit = (values: IExpenses) => {
    if (typeof currentIndex === TYPE.NUMBER) {
      handleEdit(values);
    } else {
      handleAdd(values);
    }
    handleCloseDialog();
  };

  const validationSchema = Yup.object().shape({
    tenKhoanChi: Yup.string().required(lang("VALIDATION.REQUIRE")).nullable(),
    soTien: Yup.string().required(lang("VALIDATION.REQUIRE")).nullable(),
    nguoiDuyet: Yup.object().required(lang("VALIDATION.REQUIRE")).nullable()
  });

  const formik = useFormik({
    initialValues: INIT_EXPENSES,
    onSubmit: handleFormSubmit,
    validationSchema
  });

  const handleChangeSelect = (name: string, value: any) => {
    switch (name) {
      case VARIABLE_STRING.NGUOI_DUYET:
        formik.setValues({
          ...formik.values,
          [name]: value,
          tenNguoiDuyet: value?.name || "",
          nguoiDuyetId: value?.id || ""
        });
        break;
      default:
        formik.setFieldValue(name, value);
        break;
    }
  };

  const handleChangeMoney = (event: React.ChangeEvent<any>) => {
    const { name, value } = event.target;
    formik.setFieldValue(name, Number(convertTextPrice(value)));
  };

  return (
    <Modal
      show={true}
      size="lg"
      centered
      aria-labelledby="example-custom-modal-styling-title"
      onHide={handleCloseDialog}
    >
      <Form onSubmit={formik.handleSubmit}>
        <Modal.Header closeButton>
          <Modal.Title
            id="example-custom-modal-styling-title"
            className="heading-5"
          >
            {lang("WELFARE.ACTUAL_COST")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row className="g-4">
            <Col xs={4}>
              <TextValidator
                name="tenKhoanChi"
                isRequired
                lable={lang("WELFARE.ACTUAL_COST.NAME")}
                type="text"
                value={formik.values?.tenKhoanChi}
                onChange={formik.handleChange}
                readOnly={isView}
                touched={formik.touched?.tenKhoanChi}
                errors={formik.errors?.tenKhoanChi}
              />
            </Col>
            <Col xs={4}>
              <TextValidator
                name="soTien"
                isRequired
                lable={lang("WELFARE.ACTUAL_COST.AMOUNT")}
                type="text"
                onChange={handleChangeMoney}
                value={convertNumberPrice(formik.values?.soTien || null)}
                readOnly={isView}
                touched={formik.touched?.soTien}
                errors={formik.errors?.soTien}
              />
            </Col>
            <Col xs={4}>
              <Autocomplete
                isRequired
                lable={lang("WELFARE.ACTUAL_COST.APPROVED_PEOPLE")}
                options={[]}
                isReadOnly={isView}
                value={formik.values?.nguoiDuyet || null}
                name="nguoiDuyet"
                onChange={(value) => handleChangeSelect(VARIABLE_STRING.NGUOI_DUYET, value)}
                isClearable={false}
                searchFunction={searchNguoiDaiDienKy}
                searchObject={{}}
                touched={formik.touched?.nguoiDuyet}
                errors={formik.errors?.nguoiDuyet}
              />
            </Col>
            <Col xs={12}>
              <TextValidator
                name="note"
                lable={lang("WELFARE.ACTUAL_COST.NOTE")}
                type="text"
                as="textarea"
                rows={3}
                value={formik.values?.note || ""}
                onChange={formik.handleChange}
                readOnly={isView}
              />
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer className="flex-center">
          <Button
            variant="outline-secondary"
            className="button-secondary btn-sm"
            onClick={() => handleCloseDialog()}
          >
            {lang("BTN.CANCEL")}
          </Button>
          {!isView && (
            <Button
              variant="primary"
              className="button-primary btn-sm"
              type="submit"
            >
              {lang("BTN.SAVE")}
            </Button>
          )}
        </Modal.Footer>
      </Form>
    </Modal>
  );
};
