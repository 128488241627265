import { filterObject } from "./FunctionUtils";

export const convertDataSubmit = (data: any, employeeId: any) => {
  const dataConvert = {
    ...data,
    chucVuId: data?.chucVu?.id || "",
    chucVuText: data?.chucVu?.value || "",
    viTriCongViecId: data?.viTriCongViec?.id || "",
    viTriCongViecText: data?.viTriCongViec?.value || "",
    phongBanId: data?.phongBan?.id || "",
    phongBanText: data?.phongBan?.name || "",
    employeeId: employeeId || ""
  };
  return filterObject(dataConvert);
};

export const convertDataUI = (data: any) => {
  const dataConvert = {
    ...data,
    chucVu: data?.chucVuId
      ? {
          id: data?.chucVuId || "",
          value: data?.chucVuText || ""
        }
      : null,
    viTriCongViec: data?.viTriCongViecText
      ? {
          id: data?.viTriCongViecId || "",
          value: data?.viTriCongViecText || ""
        }
      : null,
    phongBan: data?.phongBanText
      ? {
          id: data?.phongBanId || "",
          name: data?.phongBanText || ""
        }
      : null
  };
  return dataConvert;
};