import { IKeyValueString, IQuarter, IReportMapingColumn } from "../models/reportModels";
import { columnAnnualHealth, columnSalaryFund, columnStatistical, columnSummaryOfViolations, columnWorkAccident } from "./reportColumns";
import { columnSalaryFundTotal } from "./reportColumnsRowTotal";

export const itemListAdditional = {
  name: "Tất cả báo cáo",
  id: null
};

export const REPORT_TYPE = {
  TANG_GIAM_CAN_BO: "1",
  LAO_DONG_THEO_CHUC_DANH: "2",
  BAO_HIEM: "3",
  DANH_GIA_KET_QUA_PHAN_LOAI_CAN_BO: "4",
  CHAT_LUONG_CAN_BO: "5",
  CAN_BO_NGHI_HUU_DUNG_QUY_DINH: "6",
  DANH_SACH_LAO_DONG: "7",
  THONG_KE: "8",
  DANH_SACH_DANG_VIEN: "9",
  NHAN_VIEN_THEO_PHONG_BAN: "10",
  DANH_SACH_VIEN_CHUC: "11",
  THEO_LOAI_HOP_DONG_LAO_DONG: "12",
  LUAN_CHUYEN_DIEU_DONG_HANG: "13",
  QUY_LUONG: "14",
  TONG_HOP_VI_PHAM: "15",
  TAI_NAN_LAO_DONG: "16",
  SUC_KHOE_HANG_NAM: "17"
};

export const REPORT_MAPING_COLUMNS: IReportMapingColumn[] = [
  {
    type: REPORT_TYPE.TANG_GIAM_CAN_BO,
    columns: []
  },
  {
    type: REPORT_TYPE.LAO_DONG_THEO_CHUC_DANH,
    columns: []
  },
  {
    type: REPORT_TYPE.BAO_HIEM,
    columns: []
  },
  {
    type: REPORT_TYPE.DANH_GIA_KET_QUA_PHAN_LOAI_CAN_BO,
    columns: []
  },
  {
    type: REPORT_TYPE.CHAT_LUONG_CAN_BO,
    columns: []
  },
  {
    type: REPORT_TYPE.CAN_BO_NGHI_HUU_DUNG_QUY_DINH,
    columns: []
  },
  {
    type: REPORT_TYPE.DANH_SACH_LAO_DONG,
    columns: []
  },
  {
    type: REPORT_TYPE.THONG_KE,
    columns: columnStatistical
  },
  {
    type: REPORT_TYPE.DANH_SACH_DANG_VIEN,
    columns: []
  },
  {
    type: REPORT_TYPE.NHAN_VIEN_THEO_PHONG_BAN,
    columns: []
  },
  {
    type: REPORT_TYPE.DANH_SACH_VIEN_CHUC,
    columns: []
  },
  {
    type: REPORT_TYPE.THEO_LOAI_HOP_DONG_LAO_DONG,
    columns: []
  },
  {
    type: REPORT_TYPE.LUAN_CHUYEN_DIEU_DONG_HANG,
    columns: []
  },
  {
    type: REPORT_TYPE.QUY_LUONG,
    columns: columnSalaryFund,
    columnsTotal: columnSalaryFundTotal,
    isFirstTitleRoman: true
  },
  {
    type: REPORT_TYPE.TONG_HOP_VI_PHAM,
    columns: columnSummaryOfViolations
  },
  {
    type: REPORT_TYPE.TAI_NAN_LAO_DONG,
    columns: columnWorkAccident
  },
  {
    type: REPORT_TYPE.SUC_KHOE_HANG_NAM,
    columns: columnAnnualHealth
  }
];

export const REPORT_KEY: IKeyValueString = {
  congChucVienChuc: "Cán bộ, viên chức",
  hopDong111: "Hợp đồng theo Nghị định 111/2022"
};

export const QUARTER_LIST: IQuarter[] = [
  {
    code: 1,
    name: "Quý 1",
    startDate: "-01-01",
    endDate: "-03-31"
  },
  {
    code: 2,
    name: "Quý 2",
    startDate: "-04-01",
    endDate: "-06-30"
  },
  {
    code: 3,
    name: "Quý 3",
    startDate: "-07-01",
    endDate: "-09-30"
  },
  {
    code: 4,
    name: "Quý 4",
    startDate: "-10-01",
    endDate: "-12-31"
  }
];
