import { columnNamesType } from "../../../component/table-custom/TableCustom";
import { checkStatus, convertNumberPrice, formatDateTable } from "../../../utils/FunctionUtils";
import { INSURANCE_STATUS_LIST } from "../isuranceConst";

export const Insurance24Column = (): columnNamesType[] => {
  return [
    {
      name: "STT",
      field: "stt",
      render: (_, index, stt) => <span>{stt}</span>
    },
    {
      name: "Trạng thái",
      field: "trangThai",
      headerStyle: {
        minWidth: "120px"
      },
      render: (row) => (
        <span className={`${checkStatus(INSURANCE_STATUS_LIST, row?.trangThai?.code)} status`}>
          {row?.trangThai?.name}
        </span>
      )
    },
    {
      name: "Đối tượng",
      field: "doiTuong",
      render: (row) => <span>{row?.doiTuong?.name}</span>
    },
    {
      name: "Ngày mua",
      field: "ngayMua",
      render: (row) => <span>{formatDateTable(row?.ngayMua)}</span>
    },
    {
      name: "Gói mua",
      field: "goiMua"
    },
    {
      name: "Phí mua",
      field: "phiMua",
      render: (row) => <span>{convertNumberPrice(row?.phiMua)}</span>
    },
    {
      name: "Mức mua",
      field: "mucMua",
      render: (row) => <span>{convertNumberPrice(row?.mucMua)}</span>
    },
    {
      name: "Công ty hỗ trợ",
      field: "field",
      render: (row) => <span>{convertNumberPrice(row?.soTienCongTyHoTro)}</span>
    },
    {
      name: "Nhân viên đóng",
      field: "field",
      render: (row) => <span>{convertNumberPrice(row?.soTienNhanVienDong)}</span>
    },
    {
      name: "Ngày hiệu lực",
      field: "field",
      render: (row) => (
        <span>
          {row?.hieuLucTuNgay && row?.hieuLucDenNgay
            ? `${formatDateTable(row?.hieuLucTuNgay)} - ${formatDateTable(row?.hieuLucDenNgay)}`
            : formatDateTable(row?.hieuLucTuNgay)}
        </span>
      )
    }
  ];
};

export const Insurance24ViewColumn = (): columnNamesType[] => {
  return [
    {
      name: "STT",
      field: "stt",
      render: (_, index, stt) => <span>{stt}</span>
    },
    {
      name: "Trạng thái",
      field: "trangThai",
      headerStyle: {
        minWidth: "120px"
      },
      render: (row) => (
        <span className={`${checkStatus(INSURANCE_STATUS_LIST, row?.trangThai?.code)} status`}>
          {row?.trangThai?.name}
        </span>
      )
    },
    {
      name: "Đối tượng",
      field: "doiTuong",
      render: (row) => <span>{row?.doiTuong?.name}</span>
    },
    {
      name: "Ngày mua",
      field: "ngayMua",
      render: (row) => <span>{formatDateTable(row?.ngayMua)}</span>
    },
    {
      name: "Gói mua",
      field: "goiMua"
    },
    {
      name: "Phí mua",
      field: "phiMua",
      render: (row) => <span>{convertNumberPrice(row?.phiMua)}</span>
    },
    {
      name: "Mức mua",
      field: "mucMua",
      render: (row) => <span>{convertNumberPrice(row?.mucMua)}</span>
    },
    {
      name: "Công ty hỗ trợ",
      field: "field",
      render: (row) => <span>{convertNumberPrice(row?.employeeSoTienCongTyHoTro)}</span>
    },
    {
      name: "Nhân viên đóng",
      field: "field",
      render: (row) => <span>{convertNumberPrice(row?.employeeSoTienDong)}</span>
    },
    {
      name: "Lần mua",
      field: "lanMua"
    },
    {
      name: "Ngày hiệu lực",
      field: "field",
      render: (row) => (
        <span>
          {row?.hieuLucTuNgay && row?.hieuLucDenNgay
            ? `${formatDateTable(row?.hieuLucTuNgay)} - ${formatDateTable(row?.hieuLucDenNgay)}`
            : formatDateTable(row?.hieuLucTuNgay)}
        </span>
      )
    }
  ];
};
