import { FC, useContext, useState, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import AppContext from "../../AppContext";
import { EXPORT_FILE_NAME, PERMISSIONS, PERMISSION_ABILITY } from "../../Constant";
import useMultiLanguage from "../../hook/useMultiLanguage";
import InputSearch from "../component/input-field/InputSearch";
import TableCustom, { columnNamesType } from "../component/table-custom/TableCustom";
import AdvancedSearch from "../profile/component/Dialog/AdvancedSearch";
import { INIT_INPUT_SEARCH } from "../profile/const/ProfileConst";
import { IItemSearch } from "../profile/models/ProfileModels";
import { MISSION_STATUS, MISSION_STATUS_CODE, RESPONSE_STATUS_CODE, TYPE } from "../utils/Constant";
import { exportPhucLoi } from "../utils/ExportFileServices";
import { checkStatus, convertSearch, exportToFile, hasAuthority } from "../utils/FunctionUtils";
import { WelfareForm } from "./components/WelfareForm";
import { ListSearch } from "./const/WelfareConst";
import { IWelfare } from "./model/WelfareModel";
import { deleteWalfare, searchWalfare } from "./services/WelfareServices";
import "./welfare.scss";
import { INIT_SEARCH_OBJECT } from "../constant";

const WelfareList: FC = () => {
  const { lang } = useMultiLanguage();
  const { setPageLoading } = useContext(AppContext);

  const [totalPage, setTotalPage] = useState<number>(0);
  const [totalElements, setTotalElements] = useState<number>(0);
  const [numberOfElements, setNumberOfElements] = useState<number>(0);
  const [isView, setIsView] = useState<boolean>(false);
  const [shouldOpenWelfareDialog, setShouldOpenWelfareDialog] = useState<boolean>(false);
  const [searchObject, setSearchObject] = useState<any>(INIT_SEARCH_OBJECT);
  const [isAdvancedSearch, setIsAdvancedSearch] = useState<boolean>(false);
  const [listInputSearch, setListInputSearch] = useState<any[]>([INIT_INPUT_SEARCH]);
  const [listWelfare, setListWelfare] = useState<IWelfare[]>([]);
  const [welfare, setWelfare] = useState<IWelfare>({} as IWelfare);

  useEffect(() => {
    if(searchObject?.pageIndex || searchObject?.pageSize){
      handleSearch();
    }
  }, [searchObject?.pageIndex, searchObject?.pageSize])

  const updatePageData = async (searchData?: any) => {
    try {
      setPageLoading(true);
      setSearchObject({ ...searchData })
      const { data } = await searchWalfare(searchData);
      if (data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
        setListWelfare(data?.data?.content || []);
        setTotalPage(data?.data?.totalPages || 0);
        setTotalElements(data?.data?.totalElements || 0);
        setNumberOfElements(data?.data?.numberOfElements || 0);
        setIsAdvancedSearch(false);
      }
    } catch (error) {
      toast.error(lang("GENERAL.ERROR"));
    } finally {
      setPageLoading(false);
    }
  };

  const handleOpenAdvancedSearch = () => {
    setIsAdvancedSearch(true);
  };

  const handleOpenDialog = () => {
    setShouldOpenWelfareDialog(true);
  };

  const handleOpenInfoDialog = (row: any) => {
    setIsView(true);
    setShouldOpenWelfareDialog(true);
    setWelfare(row);
  };

  const handleCloseDialog = () => {
    setShouldOpenWelfareDialog(false);
    setWelfare({} as IWelfare);
    setIsView(false);
  };

  const handleCloseAndSearch = () => {
    handleSearch();
    handleCloseDialog();
  }

  const handleOpenUpdateDialog = () => {
    setIsView(false);
  };

  const handleCloseUpdateDialog = () => {
    setIsView(true);
  };

  const handleSearch = (data: any = {}) => {
    const dataSearch: any = {
      ...searchObject,
      ...data
    };
    dataSearch.keyword = dataSearch?.keyword?.trim() || ""
    updatePageData(dataSearch);
  };

  const handleAdvancedSearch = (data: IItemSearch[]) => {
    setListInputSearch(data);
    handleSearch({ ...convertSearch(data), pageIndex: 1 });
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchObject({
      keyword: e.target?.value
    });
  };

  const handleDeleteWelfare = async (ids: string[]) => {
    try {
      const { data } = await deleteWalfare(ids);
      if (data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
        toast.success(lang("TOAST.DELETE.SUCCESS"));
        updatePageData(searchObject);
        return true;
      }
    } catch (error) {
      toast.error(lang("GENERAL.ERROR"));
    }
  };

  const columns: columnNamesType[] = [
    {
      name: "STT",
      field: "",
      render: (row: any, index: number, stt: number) => <span>{stt}</span>
    },
    {
      name: lang("GENERAL.STATUS"),
      field: "trangThai",
      headerStyle: {
        minWidth: "120px"
      },
      render: (row: any) => 
        <span className={`${checkStatus(MISSION_STATUS, row?.trangThai?.code)} rounded-5xl spaces px-12 py-4 text-white`}>
          {row?.trangThai?.name}
        </span>
    },
    {
      name: lang("WELFARE.NAME"),
      field: "tenPhucLoi",
      headerStyle: {
        minWidth: "200px"
      },
      cellStyle: {
        textAlign: "left"
      },
      render: (row: any) => <span className="color-steel-blue fw-600">{row?.tenPhucLoi}</span>
    },
    {
      name: lang("WELFARE.GROUP"),
      field: "nhomPhucLoi",
      headerStyle: {
        minWidth: "200px"
      },
      cellStyle: {
        minWidth: "200px",
        textAlign: "left"
      },
      render: (row: any) => <span>{row?.nhomPhucLoi?.name}</span>
    },
    {
      name: lang("WELFARE.APPLY.UNIT"),
      field: "donViApDungText",
      headerStyle: {
        minWidth: "350px"
      },
      cellStyle: {
        textAlign: "left"
      }
    },
    {
      name: lang("WELFARE.ORGANIZATION.PLACE"),
      field: "diaDiemToChuc",
      headerStyle: {
        minWidth: "400px"
      },
      cellStyle: {
        textAlign: "left"
      }
    },
    {
      name: lang("WELFARE.ORGANIZATION.PURPOSE"),
      field: "mucDichToChuc",
      headerStyle: {
        minWidth: "300px"
      },
      cellStyle: {
        textAlign: "left"
      }
    },
    {
      name: lang("WELFARE.IMPLEMENT.FORM"),
      field: "hinhThucThucHien",
      headerStyle: {
        minWidth: "250px"
      },
      cellStyle: {
        textAlign: "left"
      },
      render: (row: any) => <span>{row?.hinhThucThucHien?.name}</span>
    },
    {
      name: lang("WELFARE.EXPECTED.PEOPLE"),
      field: "soNguoiDuKien",
      headerStyle: {
        minWidth: "150px"
      },
      cellStyle: {
        textAlign: "left"
      }
    }
  ];

  return (
    <div className="container-page flex-1">
      {!shouldOpenWelfareDialog && (
        <>
          <Row className="spaces mb-12 flex-middle">
            <Col xs={5} xxl={7} className="spaces p-0">
              <h2 className="breadcrumb-title">{lang("GENERAL.WELFARE.LIST")}</h2>
            </Col>
            <Col
              xs={7} xxl={5}
              className="flex"
            >
              <div className="spaces mr-10 flex-1">
                <InputSearch
                  className="border-3px"
                  value={searchObject?.keyword}
                  placeholder={"Tìm kiếm theo tên phúc lợi"}
                  handleSearch={() => handleSearch({ pageIndex: 1 })}
                  handleChange={handleChange}
                  isEnter
                />
              </div>
              <button
                className="spaces button-primary mr-10"
                onClick={() => handleSearch({ pageIndex: 1 })}
              >
                {lang("GENERAL.SEARCH")}
              </button>
              <button
                className="spaces button-primary-outline mr-10"
                onClick={handleOpenAdvancedSearch}
              >
                {lang("BTN.ADVANCE_SEARCH")}
              </button>

              {isAdvancedSearch && (
                <AdvancedSearch
                  open={isAdvancedSearch}
                  listInputSearch={listInputSearch}
                  handleSearch={handleAdvancedSearch}
                  listOption={ListSearch(lang)}
                  handeleClose={() => setIsAdvancedSearch(false)}
                />
              )}
            </Col>
          </Row>
          <Row className="bg-white">
            <Col xs={12} className="table-custom">
              <TableCustom
                id="welfare-list"
                data={listWelfare}
                columns={columns}
                searchObject={searchObject}
                setSearchObject={setSearchObject}
                buttonAdd={hasAuthority(PERMISSIONS.PHUC_LOI, PERMISSION_ABILITY.CREATE)}
                buttonExportExcel={hasAuthority(PERMISSIONS.PHUC_LOI, PERMISSION_ABILITY.VIEW)}
                notDelete={!hasAuthority(PERMISSIONS.PHUC_LOI, PERMISSION_ABILITY.DELETE)}
                handleExportExcel={(ids: string[]) =>
                  exportToFile({
                    exportAPI: () => exportPhucLoi({ ids, ...searchObject }),
                    fileName: EXPORT_FILE_NAME.PHUC_LOI,
                    setPageLoading
                  })
                }
                type={TYPE.MULTILINE}
                fixedColumnsCount={3}
                totalPages={totalPage}
                totalElements={totalElements}
                numberOfElements={numberOfElements}
                handleOpenDialog={handleOpenDialog}
                handleDoubleClick={handleOpenInfoDialog}
                handleDelete={handleDeleteWelfare}
                deleteConditional={[
                  {
                    keyPath: "trangThai.code",
                    value: MISSION_STATUS_CODE.UNFULFILLED
                  },
                ]}
              />
            </Col>
          </Row>
        </>
      )}
      {shouldOpenWelfareDialog && (
        <WelfareForm
          isView={isView}
          setIsView={setIsView}
          welfare={welfare}
          handleCloseDialog={handleCloseDialog}
          handleCloseAndSearch={handleCloseAndSearch}
          handleCloseUpdateDialog={handleCloseUpdateDialog}
          handleOpenUpdateDialog={handleOpenUpdateDialog}
        />
      )}
    </div>
  );
};
export { WelfareList };
