import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import useMultiLanguage from "../../../../hook/useMultiLanguage";
import { useContext, useEffect, useState } from "react";
import { ReceivableInfo, ResignInfo } from "../../../resign/models/IResign";
import { convertNumberPrice, convertTextPrice, getFullYear, handleBlurDate, hasAuthority, numberExceptThisSymbols } from "../../../utils/FunctionUtils";
import {
  LIST_MONTH,
  RESPONSE_STATUS_CODE,
  SEARCH_OBJECT_MAX_SIZE,
  TYPE,
  VARIABLE_STRING
} from "../../../utils/Constant";
import { getEmployeeProfileById, searchAllEmployee } from "../../../profile/services/ProfileServices";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { useFormik } from "formik";
import {
  INIT_RECEIVABLE_INFO,
  INIT_RESIGN_INFO,
  INSURANCE_TYPE_CODE,
  RESIGN_TYPE,
  listFormOfAdjustment,
  listResignType,
  listResignTypeInsurance,
  listStatusResign
} from "../../../resign/const/ResignConst";
import { IFile } from "../../../profile/models/DialogModels";
import TableCustom from "../../../component/table-custom/TableCustom";
import { PERMISSIONS, PERMISSION_ABILITY } from "../../../../Constant";
import Autocomplete from "../../../component/input-field/Autocomplete";
import TextValidator from "../../../component/input-field/TextValidator";
import { FILE_TYPE } from "../../../component/FileUpload/constant";
import UploadFile from "../../../component/FileUpload/UploadFile";
import { searchNguoiDaiDienKy } from "../../../contract/services/contractServices";
import { ReceivableDialog } from "../../../resign/components/resign/ReceivableDialog";
import { createChamDutHopDong } from "../../services/WarningServices";
import AppContext from "../../../../AppContext";
import { checkInvalidDate } from "../../../utils/ValidationSchema";
import { OptionReactSelect } from "../../../models/models";

interface IProps {
  open: boolean;
  handleClose: () => void;
  resignInfo: ResignInfo | null;
  isView: boolean;
  warningInfo: any;
  handleReload?: () => void;
}

function EndContractDialog(props: IProps) {
  const { open, handleClose, isView, resignInfo, warningInfo, handleReload } = props;
  const { lang, intl } = useMultiLanguage();
  const [listReceivables, setListReceivables] = useState<ReceivableInfo[]>([]);
  const [shouldOpenDialog, setShouldOpenDialog] = useState<boolean>(false);
  const [receivableInfo, setReceivableInfo] = useState<ReceivableInfo>({} as ReceivableInfo);
  const [isMakeDecision, setIsMakeDecision] = useState<boolean>(resignInfo?.raQuyetDinh || false);
  const [isShowBtnSave, setIsShowBtnSave] = useState<boolean>(true);
  const { setPageLoading } = useContext(AppContext);

  useEffect(() => {
    let namKhauTruOption = getFullYear().find((item) => Number(item.name) === resignInfo?.namKhauTru) || null;
    if (resignInfo?.id) {
      formik.setValues({
        ...resignInfo,
        nguoiKy: resignInfo.nguoiKyId
          ? {
              id: resignInfo.nguoiKyId,
              name: resignInfo.nguoiKyText
            }
          : null,
        thangKhauTruOption: LIST_MONTH.find((item) => item.code === resignInfo?.thangKhauTru) || null,
        namKhauTruOption
      });
      setListReceivables(resignInfo?.listKhoanThu || []);
    }

    if (resignInfo?.employeeId) {
      getEmployeeInfo(resignInfo.employeeId);
    }
  }, [resignInfo]);

  useEffect(() => {
    if (warningInfo?.id && !resignInfo?.id) {
      getEmployeeInfo(warningInfo?.employeeId);
    }
  }, [warningInfo]);

  const getEmployeeInfo = async (id: string) => {
    try {
      setPageLoading(true);
      const { data } = await getEmployeeProfileById(id);
      if (data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
        if(resignInfo?.id) {
          formik.setFieldValue(VARIABLE_STRING.EMPLOYEE, data?.data);
        }else {
          handleChangeEmployee(data?.data);
        }
      }
    } catch (error) {
      console.error(error);
      toast.error(lang("GENERAL.ERROR"));
    } finally {
      setPageLoading(false);
    }
  };

  const handleChangeEmployee = (employee: any) => {
    let defaultType = listResignType.find((item) => item.code === RESIGN_TYPE.KET_THUC_HOP_DONG);
    let values: ResignInfo = {
      ...formik.values,
      employee: employee,
      employeeCode: employee?.maNhanVien,
      employeeId: employee?.id,
      donViCongTacId: employee?.donViCongTacId,
      donViCongTacText: employee?.donViCongTacText,
      loaiThoiViec: defaultType,
      donViCongTac: employee?.donViCongTacText
        ? {
            id: employee?.donViCongTacId,
            value: employee?.donViCongTacText
          }
        : null,
      chucDanh: employee?.viTriCongViecText
        ? {
            id: employee?.viTriCongViecId,
            value: employee?.viTriCongViecText
          }
        : null,
      phongBan: employee?.phongBanText
        ? {
            id: employee?.phongBanId,
            name: employee?.phongBanText
          }
        : null,
      chucVu: employee?.chucVuText
        ? {
            id: employee?.chucVuId,
            value: employee?.chucVuText
          }
        : null
    };
    formik.setValues(values);
  };

  const validationSchema = Yup.object().shape({
    employee: Yup.object().required(lang("VALIDATION.REQUIRE")).nullable(),
    loaiThoiViec: Yup.object().required(lang("VALIDATION.REQUIRE")).nullable(),
    loaiThoiViecBH: Yup.object().required(lang("VALIDATION.REQUIRE")).nullable(),
    nguoiKy: Yup.object().required(lang("VALIDATION.REQUIRE")).nullable(),
    soQuyetDinh: Yup.string().required(lang("VALIDATION.REQUIRE")).max(255, lang("VALIDATION.MAX255")).nullable(),
    ngayHieuLuc: Yup.date().required(lang("VALIDATION.REQUIRE")).nullable(),
    thoiGianThucHien: Yup.date().required(lang("VALIDATION.REQUIRE")).nullable(),
    thoiGianDungDongBH: Yup.date()
      .concat(checkInvalidDate(intl))  
      .when("loaiThoiViecBH", {
        is: (loaiThoiViecBH: OptionReactSelect | null) => loaiThoiViecBH && loaiThoiViecBH?.code === INSURANCE_TYPE_CODE.DUNG_DONG,
        then: Yup.date()
          .required(lang("VALIDATION.REQUIRE"))
          .nullable()
      })
      .nullable(),
    ngayQuyetDinh: Yup.date()
      .concat(checkInvalidDate(intl))
      .concat(isMakeDecision 
        ? Yup.date().required(lang("VALIDATION.REQUIRE")).nullable() 
        : Yup.date().nullable()
      )
      .when("ngayHieuLuc", {
        is: (ngayHieuLuc: Date | null) => ngayHieuLuc,
        then: Yup.date()
          .max(Yup.ref("ngayHieuLuc"), lang("VALIDATION.DECISION_DATE_BEFORE_EFFECTIVE_DATE"))
          .nullable()
      })
      .nullable(),
  });

  const handleSubmit = async (values: ResignInfo) => {
    const _values = convertDataSubmit(values);
    const { data } = await createChamDutHopDong(_values);
    if (data.code === RESPONSE_STATUS_CODE.SUCCESS) {
      toast.success(lang("TOAST.END_CONTRACT"));
      handleClose();
      handleReload && handleReload();
    } else {
      toast.error(`${data.message}`);
    }
  };

  const convertDataSubmit = (values: ResignInfo) => {
    for (let index = 0; index < listReceivables.length; index++) {
      delete listReceivables[index].id;
    }
    return {
      ...values,
      code: values.employee?.maNhanVien,
      fullName: values.employee?.name,
      phongBanId: values.employee?.phongBanId,
      phongBanText: values.employee?.phongBanText,
      chucVuId: values.employee?.chucVuId,
      chucVuText: values.employee?.chucVuText,
      viTriCongViecId: values.employee?.viTriCongViecId,
      viTriCongViecText: values.employee?.viTriCongViecText,
      thangKhauTru: Number(values.thangKhauTruOption?.code),
      namKhauTru: Number(values.namKhauTruOption?.name),
      nguoiKyId: values.nguoiKy?.id,
      nguoiKyText: values.nguoiKy?.name,
      coPhieuXacNhan: values?.coPhieuXacNhan || false,
      daBoSungHoSo: values?.daBoSungHoSo || false,
      daHoanTatThuTuc: values?.daHoanTatThuTuc || false,
      listKhoanThu: listReceivables,
      raQuyetDinh: isMakeDecision
    };
  };

  const formik = useFormik({
    initialValues: INIT_RESIGN_INFO,
    validationSchema,
    onSubmit: handleSubmit
  });

  const handleChangeSelect = (name: string, value: any) => {
    formik.setFieldValue(name, value);
  };

  const handleFile = (data: IFile) => {
    formik.setFieldValue("fileId", data.id);
    formik.setFieldValue("fileName", data.name);
  };

  const handleChangeCheckBox = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;
    formik.setFieldValue(name, checked);
  };

  const handleAddReceivable = (data: ReceivableInfo) => {
    let id = data?.id;
    let index = listReceivables.findIndex((item: ReceivableInfo) => (item?.id) === id);
    if(index > -1){
      let _listReceivables = [...listReceivables];
      _listReceivables[index] = data;
      setListReceivables([..._listReceivables]);
    }else{
      setListReceivables([...listReceivables, data])
    }
    handleCloseDialog();
  };

  const handleDeleteReceivables = (ids: string[]) => {
    let _listReceivables = listReceivables.filter((item: ReceivableInfo) => !ids.includes(item?.id || ""));
    setListReceivables([..._listReceivables]);
    return true;
  };

  const handleOpenInfoDialog = (row: ReceivableInfo) => {
    setShouldOpenDialog(true);
    setReceivableInfo(row);
  };

  const handleCloseDialog = () => {
    setShouldOpenDialog(false);
    setReceivableInfo(INIT_RECEIVABLE_INFO);
  };

  const handleChangeMoney = (event: React.ChangeEvent<any>) => {
    const { name, value } = event.target;
    formik.setFieldValue(name, Number(convertTextPrice(value)));
  };

  const handleChangeDate = (event: React.ChangeEvent<any>) => {
    const { name, value } = event.target;
    if(name === VARIABLE_STRING.NGAY_HIEU_LUC) {
      const effectiveDate = value ? new Date(value) : null;
      const currentDate = new Date();
      if(effectiveDate && effectiveDate <= currentDate) {
        setIsShowBtnSave(false);
      } else setIsShowBtnSave(true);
      formik.setFieldValue(name, value);
    }
  }

  const columns = [
    {
      name: "STT",
      field: "",
      render: (row: any, index: number, stt: number) => <span>{stt}</span>
    },
    {
      name: lang("GENERAL.RECEIVABLES"),
      field: "khoanThu",
      headerStyle: {
        minWidth: "200px"
      },
      cellStyle: {
        textAlign: "center"
      }
    },
    {
      name: lang("GENERAL.MONEY"),
      field: "giaTri",
      headerStyle: {
        minWidth: "200px"
      },
      cellStyle: {
        textAlign: "center"
      }
    },
    {
      name: lang("INPUT.RETIRE_INFO.NOTE"),
      field: "ghiChu",
      headerStyle: {
        minWidth: "200px"
      },
      cellStyle: {
        textAlign: "left"
      }
    }
  ];

  return (
    <Modal
      show={open}
      size="xl"
      centered
      aria-labelledby="example-custom-modal-styling-title"
      onHide={handleClose}
      className="modal-size-custom"
    >
      <Form onSubmit={formik.handleSubmit}>
        <Modal.Header closeButton>
          <Modal.Title
            id="example-custom-modal-styling-title"
            className="heading-5"
          >
            {lang("WARNING.END_CONTRACT")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="px-8 py-2 contract-dialog-content">
          <div className="sub-title">{lang("PROFILE.TITLE")}</div>
          <Row>
            <Col
              xs={6}
              md={4}
              lg={4}
              xxl={3}
              className="pt-3"
            >
              <Autocomplete
                isRequired
                lable={lang("GENERAL.FULLNAME")}
                options={[]}
                className="spaces z-index-4"
                onChange={(value) => handleChangeEmployee(value)}
                name="employee"
                value={formik.values?.employee || null}
                searchFunction={searchAllEmployee}
                searchObject={{ ...SEARCH_OBJECT_MAX_SIZE, isDangLamViec: true }}
                isReadOnly={true}
                errors={formik.errors.employee}
                touched={formik.touched.employee}
                getOptionLabel={(option) => `${option?.maNhanVien} - ${option?.name}`}
              />
            </Col>
            <Col
              xs={6}
              md={4}
              lg={3}
              xxl={2}
              className="pt-3"
            >
              <TextValidator
                lable={lang("RESIGN.EMPLOYEECODE")}
                name="maNhanVien"
                value={formik.values?.employee?.maNhanVien || ""}
                type="text"
                readOnly={true}
              />
            </Col>
            <Col
              xs={6}
              md={4}
              lg={5}
              xxl={2}
              className="pt-3"
            >
              <TextValidator
                lable={lang("INPUT.WORKPROCESS.UNIT")}
                name="donViCongTac"
                value={formik.values?.employee?.donViCongTacText || ""}
                type="text"
                readOnly={true}
              />
            </Col>
            <Col
              xs={6}
              md={4}
              lg={4}
              xxl={2}
              className="pt-3"
            >
              <TextValidator
                lable={lang("INPUT.DEPARTMENTS")}
                name="phongBan"
                value={formik.values?.employee?.phongBanText || ""}
                type="text"
                readOnly={true}
              />
            </Col>
            <Col
              xs={6}
              md={4}
              lg={3}
              xxl={2}
              className="pt-3"
            >
              <TextValidator
                lable={lang("GENERAL.EMPLOYEE.POSITION")}
                name="chucVu"
                value={formik.values?.employee?.chucVuText || ""}
                type="text"
                readOnly={true}
              />
            </Col>
            <Col
              xs={6}
              md={4}
              lg={5}
              xxl={3}
              className="pt-3"
            >
              <TextValidator
                lable={lang("GENERAL.EMPLOYEE.JOB_POSITION")}
                name="viTriCongViec"
                value={formik.values?.employee?.viTriCongViecText || ""}
                type="text"
                readOnly={true}
              />
            </Col>
          </Row>
          <div className="sub-title">{lang("RESIGN.TITLE")}</div>
          <Row>
            <Col
              xs={6}
              md={4}
              lg={3}
              xxl={2}
              className="pt-3"
            >
              <Autocomplete
                isRequired
                className="spaces z-index-3"
                lable={lang("RESIGN.TYPE")}
                options={listResignType}
                onChange={(value) => handleChangeSelect(VARIABLE_STRING.LOAI_THOI_VIEC, value)}
                name="loaiThoiViec"
                value={formik.values?.loaiThoiViec || null}
                isReadOnly={true}
                errors={formik.errors?.loaiThoiViec}
                touched={formik.touched?.loaiThoiViec}
              />
            </Col>
            <Col
              xs={6}
              md={4}
              lg={3}
              xxl={2}
              className="pt-3"
            >
              <TextValidator
                lable={lang("INPUT.RETIRE_INFO.SUBSIDIZE")}
                name="troCapThoiViec"
                value={convertNumberPrice(formik.values?.troCapThoiViec || "")}
                type="text"
                onKeyDown={numberExceptThisSymbols}
                readOnly={isView}
                onChange={handleChangeMoney}
                errors={formik.errors?.troCapThoiViec}
                touched={formik.touched?.troCapThoiViec}
              />
            </Col>
            <Col
              xs={6}
              md={4}
              lg={3}
              xxl={2}
              className="pt-3"
            >
              <TextValidator
                lable={lang("INPUT.RETIRE_INFO.LOST_WORK")}
                name="troCapMatViec"
                value={convertNumberPrice(formik.values?.troCapMatViec || "")}
                type="text"
                onKeyDown={numberExceptThisSymbols}
                readOnly={isView}
                onChange={handleChangeMoney}
                errors={formik.errors?.troCapMatViec}
                touched={formik.touched?.troCapMatViec}
              />
            </Col>
            <Col
              xs={6}
              md={4}
              lg={3}
              xxl={2}
              className="pt-3"
            >
              <TextValidator
                lable={lang("INPUT.RETIRE_INFO.PAYABLES")}
                name="cacKhoanTraKhac"
                value={convertNumberPrice(formik.values?.cacKhoanTraKhac || "")}
                type="text"
                onKeyDown={numberExceptThisSymbols}
                readOnly={isView}
                onChange={handleChangeMoney}
                errors={formik.errors?.cacKhoanTraKhac}
                touched={formik.touched?.cacKhoanTraKhac}
              />
            </Col>
            <Col
              xs={6}
              md={4}
              lg={3}
              xxl={2}
              className="pt-3"
            >
              <TextValidator
                lable={lang("INPUT.RETIRE_INFO.RECEIVABLES")}
                name="cacKhoanThuKhac"
                value={listReceivables.length}
                type="number"
                readOnly={true}
                onChange={formik.handleChange}
                errors={formik.errors?.cacKhoanThuKhac}
                touched={formik.touched?.cacKhoanThuKhac}
              />
            </Col>
            <Col
              xs={6}
              md={4}
              lg={3}
              xxl={2}
              className="pt-3"
            >
              <Autocomplete
                className="spaces z-index-2"
                lable={lang("RESIGN.MONTH_DEDUCTION")}
                options={LIST_MONTH}
                onChange={(value) => handleChangeSelect(VARIABLE_STRING.THANG_KHAU_TRU_OPTION, value)}
                name="thangKhauTruOption"
                value={formik.values?.thangKhauTruOption || null}
                isReadOnly={isView}
              />
            </Col>
            <Col
              xs={6}
              md={4}
              lg={3}
              xxl={2}
              className="pt-3 z-index-2"
            >
              <Autocomplete
                className="spaces z-index-1"
                lable={lang("RESIGN.YEAR_DEDUCTION")}
                options={getFullYear()}
                onChange={(value) => handleChangeSelect(VARIABLE_STRING.NAM_KHAU_TRU_OPTION, value)}
                name="namKhauTruOption"
                value={formik.values?.namKhauTruOption || null}
                isReadOnly={isView}
              />
            </Col>
            <Col
              xs={6}
              md={4}
              lg={3}
              xxl={2}
              className="pt-3"
            >
              <TextValidator
                isRequired={isMakeDecision}
                lable={lang("INPUT.WORKPROCESS.DECISION.DATE")}
                name="ngayQuyetDinh"
                value={formik.values?.ngayQuyetDinh || ""}
                type="date"
                readOnly={isView}
                onChange={formik.handleChange}
                onBlur={() => handleBlurDate(formik.setFieldValue, formik.values?.ngayQuyetDinh, "ngayQuyetDinh")}
                errors={formik.errors?.ngayQuyetDinh}
                touched={formik.touched?.ngayQuyetDinh}
              />
            </Col>
            <Col
              xs={6}
              md={4}
              lg={3}
              xxl={2}
              className="pt-3"
            >
              <TextValidator
                isRequired
                lable={lang("INPUT.WORKPROCESS.DECISION.NUMBER")}
                name="soQuyetDinh"
                type="text"
                readOnly={isView}
                value={formik?.values?.soQuyetDinh || ""}
                onChange={formik?.handleChange}
                errors={formik?.errors?.soQuyetDinh}
                touched={formik?.touched?.soQuyetDinh}
              />
            </Col>
            <Col
              xs={6}
              md={4}
              lg={3}
              xxl={2}
              className="pt-3"
            >
              <TextValidator
                lable={lang("CONTRACT.EFFECTIVEDATE")}
                name="ngayHieuLuc"
                value={formik.values?.ngayHieuLuc || ""}
                type="date"
                isRequired
                readOnly={isView}
                onChange={handleChangeDate}
                onBlur={() => handleBlurDate(formik.setFieldValue, formik.values?.ngayHieuLuc, "ngayHieuLuc")}
                errors={formik.errors?.ngayHieuLuc}
                touched={formik.touched?.ngayHieuLuc}
              />
            </Col>
            <Col
              xs={6}
              md={4}
              lg={3}
              xxl={2}
              className="pt-3"
            >
              <Autocomplete
                isRequired
                lable={lang("APPRECIATIVE.PERSON_SINGING")}
                isReadOnly={isView}
                options={[]}
                value={formik.values?.nguoiKy || null}
                name="nguoiKy"
                onChange={(selectedOption) => handleChangeSelect("nguoiKy", selectedOption)}
                searchFunction={searchNguoiDaiDienKy}
                searchObject={{}}
                touched={formik.touched?.nguoiKy}
                errors={formik.errors?.nguoiKy}
              />
            </Col>
            <Col
              xs={6}
              md={4}
              lg={3}
              xxl={2}
              className="pt-3"
            >
              <Autocomplete
                lable={lang("RESIGN.STATUS")}
                options={listStatusResign}
                value={formik.values?.trangThai}
                isReadOnly={true}
                name="trangThai"
                menuPlacement="top"
                onChange={(selectedOption) => handleChangeSelect("trangThai", selectedOption)}
                errors={formik.errors?.trangThai}
                touched={formik.touched?.trangThai}
              />
            </Col>
            <Col
              xs={6}
              md={4}
              lg={3}
              xxl={2}
              className="pt-3"
            >
              <UploadFile
                isReadOnly={isView}
                label="INPUT.QUALIFICATION.FILE"
                setValue={handleFile}
                fileValue={{
                  id: formik.values.fileId || "",
                  name: formik.values.fileName || ""
                }}
                allowFileTypes={`${FILE_TYPE.PDF},${FILE_TYPE.DOC},${FILE_TYPE.DOCX},${FILE_TYPE.XLS},${FILE_TYPE.XLSX}`}
              />
            </Col>
            <Col
              xs={6}
              md={4}
              lg={3}
              xxl={2}
              className="pt-3"
            >
              <Form.Check
                label={lang("RESIGN.EXISTED_COMFIRM_SALARY")}
                checked={formik.values?.coPhieuXacNhan || false}
                onChange={handleChangeCheckBox}
                className="custom-form-check"
                readOnly={isView}
                disabled={isView}
                name="coPhieuXacNhan"
              />
            </Col>
            <Col
              xs={6}
              md={4}
              lg={3}
              xxl={2}
              className="pt-3"
            >
              <Form.Check
                label={lang("RESIGN.ADDED_PROFILE")}
                checked={formik.values?.daBoSungHoSo || false}
                onChange={handleChangeCheckBox}
                className="custom-form-check"
                readOnly={isView}
                disabled={isView}
                name="daBoSungHoSo"
              />
            </Col>
            <Col
              xs={6}
              md={4}
              lg={3}
              xxl={2}
              className="pt-3"
            >
              <Form.Check
                label={lang("RESIGN.COMPLETED_PROCEDURE")}
                checked={formik.values?.daHoanTatThuTuc}
                onChange={handleChangeCheckBox}
                className="custom-form-check mt-8"
                readOnly={isView}
                disabled={isView}
                name="daHoanTatThuTuc"
              />
            </Col>
            <Col
              xs={12}
              xl={6}
              className="pt-3"
            >
              <TextValidator
                // className="reason-resign"
                lable={lang("RESIGN.REASON")}
                name="lyDoThoiViec"
                type="text"
                as="textarea"
                rows="3"
                value={formik.values?.lyDoThoiViec}
                readOnly={isView}
                onChange={formik.handleChange}
              />
            </Col>
            <Col
              xs={12}
              xl={6}
              className="pt-3"
            >
              <TextValidator
                // className="reason-resign"
                lable={lang("INPUT.QUALIFICATION.NOTE")}
                name="ghiChu"
                type="text"
                as="textarea"
                rows="3"
                value={formik.values?.ghiChu}
                readOnly={isView}
                onChange={formik.handleChange}
              />
            </Col>
          </Row>
          <Row>
            <TableCustom
              isActionTableTab
              id="listReceivables"
              title={lang("INPUT.RETIRE_INFO.RECEIVABLES")}
              data={listReceivables}
              columns={columns}
              buttonAdd={!isView && hasAuthority(PERMISSIONS.NGHI_VIEC, PERMISSION_ABILITY.CREATE)}
              notDelete={isView || !hasAuthority(PERMISSIONS.NGHI_VIEC, PERMISSION_ABILITY.DELETE)}
              type={!isView && hasAuthority(PERMISSIONS.NGHI_VIEC, PERMISSION_ABILITY.DELETE) ? TYPE.MULTILINE : ""}
              fixedColumnsCount={0}
              noPagination
              handleOpenDialog={() => !isView && handleOpenInfoDialog(INIT_RECEIVABLE_INFO)}
              handleDoubleClick={(row: any) => (!isView ? handleOpenInfoDialog(row) : () => {})}
              handleDelete={async (ids: string[]) => handleDeleteReceivables(ids)}
            />
          </Row>
          <div className="sub-title">{lang("INPUT.RETIRE_INFO.INSURANCE_INFO")}</div>
          <Row>
            <Col
              xs={6}
              md={4}
              lg={3}
              xxl={2}
              className="pt-3"
            >
              <Autocomplete
                isRequired
                lable={lang("RESIGN.TYPE")}
                options={listResignTypeInsurance}
                onChange={(value) => handleChangeSelect(VARIABLE_STRING.TRANG_THAI_BAO_HIEM, value)}
                menuPlacement="top"
                name="loaiThoiViecBH"
                value={formik.values?.trangThaiBaoHiem || null}
                isReadOnly={isView}
                errors={formik.errors?.trangThaiBaoHiem}
                touched={formik.touched?.trangThaiBaoHiem}
              />
            </Col>
            <Col
              xs={6}
              md={4}
              lg={3}
              xxl={2}
              className="pt-3"
            >
              <TextValidator
                isRequired={formik.values?.trangThaiBaoHiem?.code === INSURANCE_TYPE_CODE.DUNG_DONG}
                lable={lang("INPUT.INSURANCE.STOP_TIME")}
                name="thoiGianDungDongBH"
                value={formik.values?.thoiGianDungDongBH || ""}
                type="date"
                readOnly={isView}
                onChange={formik.handleChange}
                onBlur={() =>
                  handleBlurDate(formik.setFieldValue, formik.values?.thoiGianDungDongBH, "thoiGianDungDongBH")
                }
                errors={formik.errors?.thoiGianDungDongBH}
                touched={formik.touched?.thoiGianDungDongBH}
              />
            </Col>
            <Col
              xs={6}
              md={4}
              lg={3}
              xxl={2}
              className="pt-3"
            >
              <Autocomplete
                lable={lang("INPUT.INSURANCE.ADJUST")}
                options={listFormOfAdjustment}
                onChange={(value) => handleChangeSelect(VARIABLE_STRING.HINH_THUC_DIEU_CHINH, value)}
                menuPlacement="top"
                name="hinhThucDieuChinh"
                value={formik.values?.hinhThucDieuChinh || null}
                isReadOnly={isView}
              />
            </Col>
            <Col
              xs={6}
              md={4}
              lg={3}
              xxl={2}
              className="pt-3"
            >
              <TextValidator
                isRequired
                lable={lang("IMPLEMENTATION.TIME")}
                name="thoiGianThucHien"
                value={formik.values?.thoiGianThucHien || ""}
                type="date"
                readOnly={isView}
                onChange={formik.handleChange}
                onBlur={() => handleBlurDate(formik.setFieldValue, formik.values?.thoiGianThucHien, "thoiGianThucHien")}
                errors={formik.errors?.thoiGianThucHien}
                touched={formik.touched?.thoiGianDungDongBH}
              />
            </Col>
          </Row>
        </Modal.Body>

        <Modal.Footer className="flex-center">
          <Button
            variant="outline-secondary"
            className="button-secondary btn-sm"
            onClick={() => handleClose()}
          >
            {lang("BTN.CANCEL")}
          </Button>

          <Button
            variant="primary"
            className="button-primary btn-sm"
            type="submit"
            onClick={() => setIsMakeDecision(true)}
          >
            {lang("BTN.DECISION")}
          </Button>
          {isShowBtnSave && (
            <Button
              variant="primary"
              className="button-primary btn-sm"
              type="submit"
              onClick={() => setIsMakeDecision(false)}
            >
              {lang("BTN.SAVE")}
            </Button>
          )}
        </Modal.Footer>
      </Form>

      {shouldOpenDialog && (
        <ReceivableDialog
          receivableInfo={receivableInfo}
          handleClose={handleCloseDialog}
          isView={isView}
          handleDialogSubmit={handleAddReceivable}
        />
      )}
    </Modal>
  );
}

export default EndContractDialog;
