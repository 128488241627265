import axios from "axios";
import { paramsConfig } from "../../utils/ParamsUtils";
import { IInsurance } from "../models/insuranceModel";

const API_URL = process.env.REACT_APP_API_URL;

export const searchInsurance = (searchData?: object) => {
  let url = `${API_URL}/thong-tin-bao-hiem/page`;
  return axios.get(url, paramsConfig(searchData || {}));
};

export const addNewInsurance = (data: IInsurance) => {
  const url = API_URL + "/thong-tin-bao-hiem";
  return axios.post(url, data);
};

export const updateInsurance = (data: IInsurance, id: string) => {
  const url = `${API_URL}/thong-tin-bao-hiem/${id}`;
  return axios.put(url, data);
};

export const deleteInsurance = (ids: string[] | string) => {
  let url = `${API_URL}/thong-tin-bao-hiem/ids`;
  return axios.delete(url, paramsConfig({ ids: ids.toString() }));
};

export const getInsuranceById = (id: string) => {
  let url = `${API_URL}/thong-tin-bao-hiem/${id}`;
  return axios.get(url);
};

export const getInsuranceProfile = (employeeId: string, type: number) => {
  let url = `${API_URL}/thong-tin-bao-hiem/employee/${employeeId}`;
  return axios.get(url, paramsConfig({ type }));
};
