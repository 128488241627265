/* eslint-disable react-hooks/exhaustive-deps */
import { useFormik } from "formik";
import { FC, useContext, useEffect, useState } from "react";
import { Col, Modal, Row, Form, Button } from "react-bootstrap";
import { toast } from "react-toastify";
import * as Yup from "yup";
import Autocomplete from "../../../component/input-field/Autocomplete";
import TextValidator from "../../../component/input-field/TextValidator";
import useMultiLanguage from "../../../../hook/useMultiLanguage";
import { FILE_TYPE } from "../../../component/FileUpload/constant";
import { RangeDatePicker } from "../../../component/input-field/RangeDatePicker";
import AppContext from "../../../../AppContext";
import { useProfileContext } from "../../ProfileContext";
import {
  convertNumberPrice,
  convertTextPrice,
  handleBlurDate,
  hasRole,
  justAllowNumber
} from "../../../utils/FunctionUtils";
import { GroupButton } from "../../../component/GroupButton";
import { IFile, ITrainingContract } from "../../models/DialogModels";
import { INIT_TRAINING_CONTRACT } from "../../const/DialogConstant";
import { ROLE } from "../../../../Constant";
import { searchAllEmployee } from "../../services/ProfileServices";
import { DATA_WORD_TYPES, RESPONSE_STATUS_CODE, SEARCH_OBJECT_MAX_SIZE, VARIABLE_STRING } from "../../../utils/Constant";
import { addTrainingContract, searchPhongBan_DonVi, updateTrainingContract } from "../../services/DialogServices";
import { STATUS_NV } from "../../const/DialogChildConstants";
import { getAllSimpleValue } from "../../../services";
import { TYPE_CATEGORY } from "../../../constant";
import { searchListWorkUnit } from "../../../utils/CategoryServices";
import { contractStatus, contractTrainingWord, signinStatus } from "../../../contract/const/ContractConst";
import UploadFile from "../../../component/FileUpload/UploadFile";
import { searchNguoiDaiDienKy } from "../../../contract/services/contractServices";
import { convertDataTraniningContractUI } from "../../utils/FunctionUtils";
import { ExportWord } from "../../../component/exportWord";

interface IProps {
  handleCloseTrainingContractDialog: (isAddAndUpdate?: boolean) => void;
  trainingContractInfo: ITrainingContract;
  isView: boolean;
  updatePageData: () => Promise<void>;
  employeeId: string | undefined;
  hopDongLaoDongId: string;
}

const TrainingContractDialog: FC<IProps> = (props) => {
  const { handleCloseTrainingContractDialog, trainingContractInfo, hopDongLaoDongId, employeeId, isView, updatePageData } = props;
  const { lang } = useMultiLanguage();
  const { setPageLoading } = useContext(AppContext);
  const { isResign } = useProfileContext();
  
  const [isShowDialogExportWord, setIsShowDialogExportWord] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<boolean>(!isView);

  useEffect(() => {
    if (trainingContractInfo?.id) {
      formik.setValues(convertDataTraniningContractUI(trainingContractInfo));
    } else {
      getDefaultEmployee();
    }
  }, [employeeId, trainingContractInfo]);

  const getDefaultEmployee = async () => {
    try {
      setPageLoading(true);
      const searchData = {
        ...SEARCH_OBJECT_MAX_SIZE,
        trangThaiLamViec: Object.values(STATUS_NV)
          .filter((status) => status !== STATUS_NV.THOI_VIEC)
          .join(",")
      };
      const { data } = await searchAllEmployee(searchData);
      if (data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
        let emmployeeDefault = data?.data?.content.find((employee: any) => employee?.id === employeeId);
        handleChangeEmployee(emmployeeDefault || null);
      }
    } catch (error) {
      toast.error(lang("GENERAL.ERROR"));
    } finally {
      setPageLoading(false);
    }
  };

  const validationSchema = Yup.object().shape({
    employee: Yup.object().required(lang("VALIDATION.REQUIRE")).nullable(),
    donViCuDi: Yup.object().required(lang("VALIDATION.REQUIRE")).nullable(),
    donViDaoTao: Yup.string().required(lang("VALIDATION.REQUIRE")).nullable(),
    chiPhiDaoTao: Yup.string().required(lang("VALIDATION.REQUIRE")).nullable(),
    soThangCamKet: Yup.string().required(lang("VALIDATION.REQUIRE")).nullable(),
    thoiGianDaoTaoDenNgay: Yup.date().required(lang("VALIDATION.REQUIRE")).nullable(),
    thoiGianDaoTaoTuNgay: Yup.date().required(lang("VALIDATION.REQUIRE")).nullable(),
    khoaDaoTao: Yup.string().required(lang("VALIDATION.REQUIRE")).nullable(),
    soHopDong: Yup.string().required(lang("VALIDATION.REQUIRE")).nullable(),
    trangThaiHopDong: Yup.object().required(lang("VALIDATION.REQUIRE")).nullable(),
    nguoiDaiDienKy: Yup.object().required(lang("VALIDATION.REQUIRE")).nullable()
  });

  const handleSubmit = async (values: ITrainingContract) => {
    try {
      setPageLoading(true);
      values.employeeId = String(employeeId);
      values.hopDongLaoDongId = hopDongLaoDongId;
      const res = trainingContractInfo?.id
        ? await updateTrainingContract(trainingContractInfo?.id, values)
        : await addTrainingContract(values);
      if (res?.data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
        toast.success(trainingContractInfo?.id ? lang("TOAST.EDIT.SUCCESS") : lang("TOAST.CREATE.SUCCESS"));
        await updatePageData();
        handleCloseTrainingContractDialog(true);
        setPageLoading(false);
      } else {
        toast.error(`${res?.data?.message}`);
        setPageLoading(false);
      }
    } catch (err) {
      toast.error(lang("GENERAL.ERROR"));
      setPageLoading(false);
    }
  };

  const formik = useFormik({
    initialValues: INIT_TRAINING_CONTRACT,
    onSubmit: handleSubmit,
    validationSchema
  });

  const handleChangeEmployee = async (employee: any) => {
    let values: ITrainingContract = {
      ...formik.values,
      employee: employee,
      employeeId: employee?.id,
      tenNhanVien: employee?.name,
      maNhanVien: employee?.maNhanVien,
      chucVuId: employee?.chucVuId || "",
      chucVuText: employee?.chucVuText || "",
      phongBanId: employee?.phongBanId || "",
      phongBanText: employee?.phongBanText || "",
      viTriCongViecId: employee?.viTriCongViecId || "",
      viTriCongViecText: employee?.viTriCongViecText || "",
      phongBan: employee?.phongBanText
        ? {
          id: employee?.phongBanId,
          name: employee?.phongBanText
        }
        : null,
      chucVu: employee?.chucVuText
        ? {
          id: employee?.chucVuId,
          value: employee?.chucVuText
        }
        : null,
      viTriCongViec: employee?.viTriCongViecText
        ? {
          id: employee?.viTriCongViecId,
          value: employee?.viTriCongViecText
        }
        : null
    };
    formik.setValues(values);
  };

  const handleChangeSelect = (name: string, value: any) => {
    switch (name) {
      case VARIABLE_STRING.NGUOI_DAI_DIEN_KY:
        formik.setValues({
          ...formik.values,
          nguoiDaiDienKy: value,
          nguoiDaiDienKyChucDanhId: value?.chucVuId,
          nguoiDaiDienKyChucDanhText: value?.chucDanh,
          nguoiDaiDienKyId: value?.id,
          nguoiDaiDienKyText: value?.name
        });
        break;
      case VARIABLE_STRING.DON_VI_CU_DI:
        formik.setValues({
          ...formik.values,
          donViCuDi: value,
          donViCuDiId: value?.id || "",
          donViCuDiText: value?.name
        });
        break;
      default:
        formik.setFieldValue(name, value);
        break;
    }
  };

  const handleChangeMoney = (event: React.ChangeEvent<any>) => {
    const { name, value } = event.target;
    formik.setFieldValue(name, Number(convertTextPrice(value)));
  };

  const handleChangePositiveNumber = (event: React.ChangeEvent<any>) => {
    if (!event.target?.value?.startsWith("0")) {
      formik.handleChange(event);
    }
  };

  const handleFile = (data: IFile) => {
    formik.setFieldValue("fileId", data.id);
    formik.setFieldValue("fileName", data.name);
  };

  return (
    <Modal show={true} onHide={() => handleCloseTrainingContractDialog()} size="xl" className="custom-modal" centered>
      <Form onSubmit={formik.handleSubmit}>
        <Modal.Header closeButton>
          <Modal.Title>
            {lang(`${trainingContractInfo ? "TRAINING_CONTRACT.ADD" : "TRAINING_CONTRACT.EDIT"}`)}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="sub-title p-0">{lang("CONTRACT.INFO")}</div>
          <Row>
            <Col xs={6} md={5} xl={4} xxl={3} className="spaces pt-6">
              <Autocomplete
                isRequired
                className="spaces z-index-5"
                lable={lang("PROFILE.NAME")}
                options={[]}
                onChange={(value) => handleChangeEmployee(value)}
                name="employee"
                value={formik.values?.employee || null}
                searchFunction={searchAllEmployee}
                searchObject={{
                  ...SEARCH_OBJECT_MAX_SIZE,
                  trangThaiLamViec: Object.values(STATUS_NV)
                    .filter((status) => status !== STATUS_NV.THOI_VIEC)
                    .join(",")
                }}
                isReadOnly={!isEdit || !!employeeId}
                errors={formik.errors.employee}
                touched={formik.touched.employee}
                getOptionLabel={(option) => `${option?.maNhanVien} - ${option?.name}`}
              />
            </Col>
            <Col xs={6} md={5} xl={4} xxl={3} className="spaces pt-6">
              <Autocomplete
                className="spaces z-index-5"
                lable={lang("INPUT.DEPARTMENTS")}
                isReadOnly={true}
                options={[]}
                value={formik.values?.phongBan || null}
                name="phongBan"
                onChange={(selectedOption) => handleChangeSelect("phongBan", selectedOption)}
                urlData="data.data"
                searchFunction={searchPhongBan_DonVi}
                searchObject={{
                  id: formik.values?.employee?.donViCongTacId
                }}
                touched={formik.touched?.phongBan}
                errors={formik.errors?.phongBan}
                dependencies={[formik.values?.employee?.donViCongTacId]}
              />
            </Col>
            <Col xs={6} xl={4} xxl={3} className="spaces pt-6">
              <Autocomplete
                className="spaces z-index-4"
                lable={lang("GENERAL.EMPLOYEE.POSITION")}
                name="chucVu"
                value={formik.values?.chucVu || null}
                searchFunction={getAllSimpleValue}
                searchObject={TYPE_CATEGORY.chucDanh}
                isReadOnly={true}
                onChange={(selectedOption) => handleChangeSelect(VARIABLE_STRING.CHUC_VU, selectedOption)}
                errors={formik.errors?.chucVu}
                touched={formik.touched?.chucVu}
                options={[]}
                getOptionLabel={(option) => option?.value}
              />
            </Col>
            <Col xs={6} xl={4} xxl={3} className="spaces pt-6">
              <Autocomplete
                className="spaces z-index-4"
                lable={lang("GENERAL.EMPLOYEE.JOB_POSITION")}
                name="viTriViecLam"
                value={formik.values?.viTriCongViec || null}
                searchFunction={getAllSimpleValue}
                searchObject={TYPE_CATEGORY.chucDanh}
                isReadOnly={true}
                onChange={(selectedOption) => handleChangeSelect(VARIABLE_STRING.VI_TRI_CONG_VIEC, selectedOption)}
                errors={formik.errors?.viTriCongViec}
                touched={formik.touched?.viTriCongViec}
                options={[]}
                getOptionLabel={(option) => option?.value}
              />
            </Col>
          </Row>
          <div className="sub-title">{lang("CONTRACT.INFO_DETAIL")}</div>
          <Row>
            <Col xs={6} md={4} xl={4} xxl={3} className="spaces pt-6">
              <Autocomplete
                isRequired
                lable={lang("TRAINING_CONTRACT.DISPATCHED_UNIT")}
                options={[]}
                value={formik.values?.donViCuDi || null}
                name="donViCuDi"
                onChange={(value) => handleChangeSelect(VARIABLE_STRING.DON_VI_CU_DI, value)}
                searchFunction={searchListWorkUnit}
                searchObject={SEARCH_OBJECT_MAX_SIZE}
                touched={formik.touched?.donViCuDi}
                errors={formik.errors?.donViCuDi}
                isReadOnly={!isEdit || hasRole(ROLE.USER)}
              />
            </Col>
            <Col xs={6} md={4} xl={4} xxl={2} className="spaces pt-6">
              <TextValidator
                isRequired
                lable={lang("TRAINING_CONTRACT.DIGITAL_TRAINING_COURSE")}
                name="khoaDaoTao"
                value={formik.values?.khoaDaoTao}
                type="text"
                readOnly={!isEdit}
                onChange={formik.handleChange}
                touched={formik.touched?.khoaDaoTao}
                errors={formik.errors?.khoaDaoTao}
              />
            </Col>
            <Col xs={6} md={4} xl={4} xxl={4} className="spaces pt-6">
              <RangeDatePicker
                label={lang("TRAINING_CONTRACT.TIME_TRAINING")}
                startDateName="thoiGianDaoTaoTuNgay"
                endDateName="thoiGianDaoTaoDenNgay"
                isView={!isEdit}
                isRequired
                handleChange={formik.handleChange}
                value={formik.values}
                setFieldValue={formik.setFieldValue}
                touch={formik.touched}
                errors={formik.errors}
              />
            </Col>
            <Col xs={6} md={4} xl={4} xxl={3} className="spaces pt-6">
              <TextValidator
                isRequired
                lable={lang("TRAINING_CONTRACT.TRAINING_UNIT")}
                name="donViDaoTao"
                value={formik.values?.donViDaoTao || ""}
                type="text"
                readOnly={!isEdit}
                onChange={formik.handleChange}
                errors={formik.errors?.donViDaoTao}
                touched={formik.touched?.donViDaoTao}
              />
            </Col>
            <Col xs={6} md={4} xl={4} xxl={3} className="spaces pt-6">
              <TextValidator
                lable={lang("TRAINING_CONTRACT.EXPENSE")}
                name="chiPhiDaoTao"
                isRequired
                value={convertNumberPrice(formik.values?.chiPhiDaoTao || "")}
                type="text"
                readOnly={!isEdit}
                onChange={handleChangeMoney}
                errors={formik.errors?.chiPhiDaoTao}
                touched={formik.touched?.chiPhiDaoTao}
              />
            </Col>
            <Col xs={6} md={4} xl={4} xxl={3} className="spaces pt-6">
              <TextValidator
                isRequired
                name="soThangCamKet"
                lable={lang("TRAINING_CONTRACT.COMMITTED")}
                readOnly={!isEdit}
                type="number"
                value={formik.values?.soThangCamKet || ""}
                onChange={handleChangePositiveNumber}
                onKeyDown={justAllowNumber}
                errors={formik.errors?.soThangCamKet}
                touched={formik.touched?.soThangCamKet}
              />
            </Col>
            <Col xs={6} md={4} xl={4} xxl={3} className="spaces pt-6">
              <Autocomplete
                isRequired
                lable={lang("CONTRACT.STATUS")}
                options={contractStatus}
                onChange={(value) => handleChangeSelect(VARIABLE_STRING.TRANG_THAI_HOP_DONG, value)}
                name="trangThaiHopDong"
                value={formik.values?.trangThaiHopDong || null}
                isReadOnly={!isEdit}
                errors={formik.errors?.trangThaiHopDong}
                touched={formik.touched?.trangThaiHopDong}
              />
            </Col>
            <Col xs={6} md={4} xl={4} xxl={3} className="spaces pt-6">
              <TextValidator
                isRequired
                name="soHopDong"
                lable={lang("CONTRACT.NUMBER")}
                readOnly={!isEdit}
                type="text"
                value={formik.values?.soHopDong || ""}
                onChange={formik.handleChange}
                errors={formik.errors?.soHopDong}
                touched={formik.touched?.soHopDong}
              />
            </Col>
            <Col xs={6} md={4} xl={4} xxl={6} className="spaces pt-6">
              <TextValidator
                name="diaChiDaoTao"
                lable={lang("CONTRACT.ADDRESS_TRAINNING")}
                readOnly={!isEdit}
                type="text"
                value={formik.values?.diaChiDaoTao}
                onChange={formik.handleChange}
              />
            </Col>
            <Col xs={6} md={4} xl={4} xxl={6} className="spaces pt-6">
              <UploadFile
                label="CONTRACT.ATTACHMENTS"
                setValue={handleFile}
                isReadOnly={!isEdit}
                fileValue={{
                  id: formik.values.fileId || "",
                  name: formik.values.fileName || ""
                }}
                allowFileTypes={`${FILE_TYPE.PDF},${FILE_TYPE.DOC},${FILE_TYPE.DOCX},${FILE_TYPE.XLS},${FILE_TYPE.XLSX}`}
              />
            </Col>
            <Col xs={12} className="spaces pt-6">
              <TextValidator
                lable={lang("CONTRACT.CONTENT_TRANNNING")}
                name="noiDungDaoTao"
                value={formik.values?.noiDungDaoTao}
                type="text"
                as="textarea"
                rows={2}
                readOnly={!isEdit}
                onChange={formik.handleChange}
              />
            </Col>
            <Col xs={12} className="spaces pt-6">
              <TextValidator
                lable={lang("CONTRACT.NOTE")}
                name="note"
                value={formik.values?.note}
                type="text"
                as="textarea"
                rows={2}
                readOnly={!isEdit}
                onChange={formik.handleChange}
              />
            </Col>
          </Row>
          <div className="sub-title">{lang("CONTRACT.SALARY_SIGN")}</div>
          <Row>
            <Col xs={6} md={4} xl={3} xxl={3} className="spaces pt-6">
              <Autocomplete
                isRequired
                lable={lang("CONTRACT.SIGNEDCOMPANYREPRESENTATIVE")}
                options={[]}
                value={formik.values?.nguoiDaiDienKy || null}
                name="nguoiDaiDienCtyKy"
                onChange={(value) => handleChangeSelect(VARIABLE_STRING.NGUOI_DAI_DIEN_KY, value)}
                searchFunction={searchNguoiDaiDienKy}
                searchObject={{}}
                isReadOnly={!isEdit}
                errors={formik.errors?.nguoiDaiDienKy}
                touched={formik.touched?.nguoiDaiDienKy}
              />
            </Col>
            <Col xs={6} md={4} xl={3} xxl={3} className="spaces pt-6">
              <TextValidator
                lable={lang("CONTRACT.REPRESENTATIVETITLE")}
                name="nguoiDaiDienCtyChucDanh"
                value={formik.values?.nguoiDaiDienKyChucDanhText || ""}
                type="text"
                readOnly={true}
              />
            </Col>
            <Col xs={6} md={4} xl={3} xxl={2} className="spaces pt-6">
              <TextValidator
                lable={lang("ANNEX.SIGNINGDATE")}
                name="ngayKyHopDong"
                value={formik.values?.ngayKyHopDong || ""}
                type="date"
                readOnly={!isEdit}
                onBlur={() => handleBlurDate(formik.setFieldValue, formik.values?.ngayKyHopDong, "ngayKyHopDong")}
                onChange={formik.handleChange}
                errors={formik.errors?.ngayKyHopDong}
                touched={formik.touched?.ngayKyHopDong}
              />
            </Col>
            <Col xs={6} md={4} xl={3} xxl={2} className="spaces pt-6">
              <Autocomplete
                lable={lang("CONTRACT.SIGNINGSTATUS")}
                options={signinStatus}
                value={formik.values?.trangThaiKy || null}
                name="trangThaiKy"
                onChange={(value) => handleChangeSelect(VARIABLE_STRING.TRANG_THAI_KY, value)}
                isReadOnly={!isEdit}
              />
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer className="flex-center">
          <GroupButton type="btn-cancel" handleCancel={handleCloseTrainingContractDialog} />
          {!isResign &&
            (isEdit ? (
              <Button variant="primary" className="button-primary btn-sm" type="submit">
                {lang("BTN.SAVE")}
              </Button>
            ) : (
              <Button
                variant="primary"
                className="button-primary btn-sm"
                onClick={(e: any) => {
                  e.preventDefault();
                  setIsEdit(true);
                }}
              >
                {lang("BTN.EDIT")}
              </Button>
            ))}
          {trainingContractInfo?.id && (
            <button
              className="button-primary"
              onClick={() => setIsShowDialogExportWord(true)}
              type="button"
            >
              {lang("GENERAL.EXPORT_WORD")}
            </button>
          )}

          {isShowDialogExportWord && (
            <ExportWord
              open={isShowDialogExportWord}
              handleClose={() => setIsShowDialogExportWord(false)}
              templateList={contractTrainingWord}
              getObj={{
                hopDongDaoTaoId: trainingContractInfo?.id,
                type: DATA_WORD_TYPES.FACTORY_CONTRACT_TRAINING
              }}
            />
          )}
        </Modal.Footer>
      </Form>
    </Modal>
  );
};
export { TrainingContractDialog };
