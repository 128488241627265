/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from "react";
import { Row } from "react-table";
import { toast } from "react-toastify";
import { EXPORT_FILE_NAME, PERMISSIONS, PERMISSION_ABILITY } from "../../../Constant";
import useMultiLanguage from "../../../hook/useMultiLanguage";
import TableCustom from "../../component/table-custom/TableCustom";
import { RESPONSE_STATUS_CODE, SEARCH_OBJECT_MAX_SIZE, TYPE } from "../../utils/Constant";
import { exportCongTac } from "../../utils/ExportFileServices";
import { exportToFile, formatDateTable, hasAuthority } from "../../utils/FunctionUtils";
import { INIT_WORKING_PROCESS, REF_TAB } from "../const/ProfileConst";
import { IWorkingProcessInfoOutput, IWorkingProcessInfoOutputDto } from "../models/ProfileModels";
import { deleteQuaTrinhCongTac, getAllQuaTrinhCongTacById } from "../services/DialogServices";
import { convertDataWorkingProcessUI } from "../utils/FunctionUtils";
import { WorkProcessDialog } from "./Dialog/WorkProcessDialog";
import AppContext from "../../../AppContext";

export const WorkingProcessInformation = (props: any) => {
  const { identify, isView, employeeProfiles, activeTab } = props;
  const [shouldOpenWorkProcessDialog, setShouldOpenWorkProcessDialog] = useState<boolean>(false);
  const [workProcess, setWorkProcess] = useState<IWorkingProcessInfoOutput>(INIT_WORKING_PROCESS);
  const [listWorkProcess, setListWorkProcess] = useState<IWorkingProcessInfoOutputDto[]>([]);

  const { lang } = useMultiLanguage();
  const { setPageLoading } = useContext(AppContext);

  useEffect(() => {
    if (!(identify && activeTab === REF_TAB.QUA_TRINH_CONG_TAC)) return
    updateDataWorkProcess();
  }, [employeeProfiles, activeTab]);

  const handleOpenWorkProcessDialog = (data: Row<IWorkingProcessInfoOutputDto>): void => {
    setWorkProcess(convertDataWorkingProcessUI(data));
    setShouldOpenWorkProcessDialog(true);
  };

  const handleCloseWorkProcessDialog = (): void => {
    setShouldOpenWorkProcessDialog(false);
    setWorkProcess(INIT_WORKING_PROCESS);
    updateDataWorkProcess();
  };

  const updateDataWorkProcess = async () => {
    if (identify) {
      try {
        setPageLoading(true);
        const { data } = await getAllQuaTrinhCongTacById(identify, SEARCH_OBJECT_MAX_SIZE);
        setListWorkProcess(data?.data?.content);
      } catch (error: any) {
        toast.error(error);
      } finally {
        setPageLoading(false);
      }
    }
  };

  const handleDelete = async (ids: string[]) => {
    const { data } = await deleteQuaTrinhCongTac(ids)
    if (data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
      toast.success(lang("GENERAL.DELETE_SUCCESS"))
      updateDataWorkProcess();
      return true;
    }
  }

  const columns = [
    {
      name: "STT",
      field: "",
      render: (row: any, index: number, stt: number) => <span>{stt}</span>
    },
    {
      name: lang("INPUT.WORKEXPERIENCE.DATE.START"),
      field: "tuNgay",
      headerStyle: {
        minWidth: "150px"
      },
      cellStyle: {
        minWidth: "150px",
        maxWidth: "150px",
      },
      render: (row: any) => <span>{formatDateTable(row?.tuNgay)}</span>
    },
    {
      name: lang("INPUT.WORKEXPERIENCE.DATE.END"),
      field: "denNgay",
      headerStyle: {
        minWidth: "150px"
      },
      cellStyle: {
        minWidth: "150px",
        maxWidth: "150px",
      },
      render: (row: any) => <span>{formatDateTable(row?.denNgay)}</span>
    },
    {
      name: lang("GENERAL.CURRENT_ORGANIZATION"),
      field: "donViCongTacText",
      headerStyle: {
        minWidth: "200px"
      },
      cellStyle: {
        minWidth: "200px",
        textAlign: "left",
      },
      render: (row: any) => <span>{row?.donViCongTacKhac ? row?.donViCongTacKhac : row?.donViCongTacText}</span>
    },
    {
      name: lang("INPUT.DEPARTMENTS"),
      field: "phongBanText",
      headerStyle: {
        minWidth: "200px"
      },
      cellStyle: {
        minWidth: "200px",
        textAlign: "left",
      },
      render: (row: any) => <span>{row?.phongBanKhac ? row?.phongBanKhac : row?.phongBanText}</span>
    },
    {
      name: lang("INPUT.WORKPROCESS.ROLE"),
      field: "chucVuText",
      headerStyle: {
        minWidth: "200px"
      },
      cellStyle: {
        minWidth: "200px",
        textAlign: "left",
      },
    },
    {
      name: lang("INPUT.JOB_POSITION"),
      field: "viTriCongViecText",
      headerStyle: {
        minWidth: "200px"
      },
      cellStyle: {
        minWidth: "200px",
        textAlign: "left",
      },
    },
    {
      name: lang("INPUT.WORKPROCESS.KIEM_NHIEM"),
      field: "kiemNhiem",
      headerStyle: {
        minWidth: "200px"
      },
      cellStyle: {
        minWidth: "200px",
        textAlign: "left",
      },
    },
    {
      name: lang("INPUT.WORKPROCESS.LABOR_NATURE"),
      field: "tinhChatLaoDong",
      headerStyle: {
        minWidth: "200px"
      },
      cellStyle: {
        minWidth: "200px",
        textAlign: "left",
      },
      render: (row: any) => <span>{row?.tinhChatLaoDong?.name}</span>
    },
    {
      name: lang("INPUT.WORKPROCESS.DECISION_TYPE"),
      field: "loaiThuTucText",
      headerStyle: {
        minWidth: "200px"
      },
      cellStyle: {
        minWidth: "200px",
        textAlign: "left",
      },
    }
  ]

  return (
    <>
      <div className="form-info">
        <div className="block-content">
          <TableCustom
            id="listWorkProcess"
            title={lang("GENERAL.INFO.WORKING.PROCESS")}
            data={listWorkProcess}
            isActionTableTab
            columns={columns}
            buttonAdd={!isView && hasAuthority(PERMISSIONS.EMPLOYEE, PERMISSION_ABILITY.UPDATE)}
            buttonExportExcel={true}
            handleOpenDialog={() => {
              setShouldOpenWorkProcessDialog(true);
            }}
            handleExportExcel={() =>
              exportToFile({
                exportAPI: () => exportCongTac(employeeProfiles.id), 
                fileName: EXPORT_FILE_NAME.QUA_TRINH_CONG_TAC, 
                setPageLoading
              })
            }
            type={TYPE.MULTILINE}
            noToolbar={true}
            noPagination={true}
            handleDoubleClick={handleOpenWorkProcessDialog}
            handleDelete={handleDelete}
            dependencies={activeTab}
          />
        </div>
      </div>

      {shouldOpenWorkProcessDialog && (
        <WorkProcessDialog
          handleCloseWorkProcessDialog={handleCloseWorkProcessDialog}
          identify={identify}
          isView={isView}
          workProcessEdit={workProcess}
        />
      )}
    </>
  );
};
