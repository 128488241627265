import { ILang } from "../../../hook/useMultiLanguage";
import { TYPE_CATEGORY } from "../../constant";
import { OptionReactSelect } from "../../models/models";
import { searchPhongBan } from "../../profile/services/DialogServices";
import { searchAllSimpleValue } from "../../services";
import { SEARCH_OBJECT_MAX_SIZE, TYPE } from "../../utils/Constant";
import { IProblem } from "../models/ProblemModel";

export const PROBLEM_STATUS_CODE = {
  UNFULFILLED: 1,
  PROCESSING: 2,
  COMPLETED: 3
};

export const PROBLEM_STATUS_LIST = [
  {
    name: "Chưa xử lý",
    code: PROBLEM_STATUS_CODE.UNFULFILLED,
    styleClass: "bg-gray-700"
  },
  {
    name: "Đang xử lý",
    code: PROBLEM_STATUS_CODE.PROCESSING,
    styleClass: "bg-orange-dark"
  },
  {
    name: "Đã xử lý",
    code: PROBLEM_STATUS_CODE.COMPLETED,
    styleClass: "bg-green"
  }
];

export const PROBLEM_OBJECTS_CODE = {
  PERSONAL: 1,
  GROUP: 2
};

export const PROBLEM_OBJECTS: OptionReactSelect[] = [
  {
    code: PROBLEM_OBJECTS_CODE.PERSONAL,
    name: "Cá nhân"
  },
  {
    code: PROBLEM_OBJECTS_CODE.GROUP,
    name: "Tập thể"
  }
];

export const INIT_PROBLEM: IProblem = {
  doiTuong: PROBLEM_OBJECTS[0],
  hinhThucXuLyId: "",
  hinhThucXuLyText: "",
  employeeSuCoList: [],
  loaiSuCoId: "",
  loaiSuCoText: "",
  ngayXayRa: "",
  tenSuCo: "",
  trangThai: PROBLEM_STATUS_LIST[0],
  loaiSuCo: null,
  hinhThucXuLy: null,
  thangTruLuongObj: null,
  namTruLuongObj: null,
  soTienViPham: null,
  giaTriThietHai: null,
  xuLy: "",
  soTienBhThanhToan: null,
  soTienXuLyBanDau: null
};

export const PROLEM_FORM_PROCESS_CODES = {
  EXCEPT_SALARY: "TL",
  HANDLE_IN_PLACE: "XLTC"
}

export const PROBLEM_TYPE_CODES = {
  LABOR_ACCIDENT: "TNLD"
}

export const ListSearch = (lang: ILang) => {
  return [
    {
      name: lang("GENERAL.STATUS"),
      field: "trangThai",
      type: TYPE.SELECT,
      listOption: PROBLEM_STATUS_LIST
    },
    {
      name: lang("PROBLEM.NAME"),
      field: "tenSuCo",
      type: TYPE.TEXT
    },
    {
      name: lang("PROBLEM.TYPE"),
      field: "loaiSuCoId",
      type: TYPE.SELECT,
      searchFunction: searchAllSimpleValue,
      searchObject: {
        type: TYPE_CATEGORY.loaiSuCo,
        ...SEARCH_OBJECT_MAX_SIZE
      },
      optionLabel: "value"
    },
    {
      name: lang("PROBLEM.NAMEDATE_OF_OCCURRED"),
      field: "ngayXayRa",
      type: TYPE.DATE
    },
    {
      name: lang("PROBLEM.OBJECT"),
      field: "doiTuong",
      type: TYPE.SELECT,
      listOption: PROBLEM_OBJECTS
    },
    {
      name: lang("PROBLEM.FORMS_PROCESSING"),
      field: "hinhThucXuLyId",
      type: TYPE.SELECT,
      searchFunction: searchAllSimpleValue,
      searchObject: {
        type: TYPE_CATEGORY.hinhThucXuly,
        ...SEARCH_OBJECT_MAX_SIZE
      },
      optionLabel: "value"
    },
    {
      name: lang("GENERAL.FULLNAME"),
      field: "tenNhanVien",
      type: TYPE.TEXT
    },
    {
      name: lang("PROBLEM.RELATED_DEPARTMENTS"),
      field: "phongBanId",
      type: TYPE.SELECT,
      searchFunction: searchPhongBan,
      searchObject: {
        ...SEARCH_OBJECT_MAX_SIZE
      }
    }
  ];
};
