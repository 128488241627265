/* eslint-disable react-hooks/exhaustive-deps */
import { FormikProps } from "formik";
import React, { useContext, useEffect, useLayoutEffect, useRef, useState } from "react";
import { useIntl } from "react-intl";
import { toast } from "react-toastify";
import { KTSVG } from "../../../_metronic/helpers";
import { PERMISSIONS, PERMISSION_ABILITY, ROLE } from "../../Constant";
import { useAuth } from "../auth";
import { GroupButton } from "../component/GroupButton";
import { searchNationality } from "../services";
import { RESPONSE_STATUS_CODE, SEARCH_OBJECT_MAX_SIZE } from "../utils/Constant";
import { hasAuthority, hasRole } from "../utils/FunctionUtils";
import { useProfileContext } from "./ProfileContext";
import { AttachmentsInformation } from "./component/AttachmentsInformation";
import { BasicInformation } from "./component/BasicInformation";
import BonusAndDisciplineInfomation from "./component/BonusAndDisciplineInfomation";
import { ContactInformation } from "./component/ContactInformation";
import { ContractInformation } from "./component/ContractInformation";
import EmployeeInfo from "./component/EmployeeInfo";
import FamilyInformation from "./component/FamilyInformation";
import FamilyPolicyInfomation from "./component/FamilyPolicyInfomation";
import { InsuranceInfomation } from "./component/InsuranceInfomation";
import MedicalInformation from "./component/MedicalInformation";
import MilitaryInformation from "./component/MilitaryInformation";
import PoliticsInformation from "./component/PoliticsInformation";
import { SalaryInformation } from "./component/SalaryInformation";
import { TempPapersInformation } from "./component/TempPapersInformation";
import TitleConferredInfomation from "./component/TitleConferredInfomation";
import { TranningInfomation } from "./component/TranningInfomation";
import VaccinationInformation from "./component/VaccinationInformation";
import { WorkExperienceInformation } from "./component/WorkExperienceInformation";
import { WorkInformation } from "./component/WorkInformation";
import { WorkingProcessInformation } from "./component/WorkingProcessInformation";
import { STATUS_NV } from "./const/DialogChildConstants";
import { REF_TAB, defaultEmployeeProfile, profileTabs } from "./const/ProfileConst";
import { EmployeeProfile } from "./models/ProfileModels";
import { getEmployeeIdByUserId } from "./services/DialogServices";
import { getEmployeeProfileById, handleAddBasicInfo } from "./services/ProfileServices";
import { convertDataUI } from "./utils/FunctionUtils";
import CustomTooltip from "../component/CustomTooltip";
import AppContext from "../../AppContext";
import WelfareInfomation from "./component/WelfareInfomation";
import debounce from 'lodash/debounce';
import ExportWord from "./component/ExportWord";
import ProblemInfomation from "./component/ProblemInfomation";
import "./profile.scss";  
import ListInsuranreInfomation from "./component/ListInsuranreInfomation";

interface IProps {
  handleCloseDialog: () => void;
  idEmployee?: string;
  setIdEmployee: (data: any) => void;
  isView: boolean;
  handleToggleIsView: () => void;
}

export const ProfileAddAndUpdateV2: React.FC<IProps> = ({
  handleCloseDialog,
  idEmployee,
  setIdEmployee,
  isView,
  handleToggleIsView
}) => {
  const intl = useIntl();
  const { setIsResign } = useProfileContext();

  const [employeeProfiles, setEmployeeProfiles] = useState<EmployeeProfile>(defaultEmployeeProfile);
  const [title, setTitle] = useState("PROFILE.ADD");
  const [identify, setIdentify] = useState("");
  const [activeTab, setActiveTab] = useState<string>(REF_TAB.TT_CO_BAN);
  const [dataFromChild, setDataFromChild] = useState({});
  const formikRefBasicInformation = useRef<FormikProps<any>>(null);
  const formikRefContactInformation = useRef<FormikProps<any>>(null);
  const formikRefWorkInformation = useRef<FormikProps<any>>(null);
  const formikRefInsuranceInfomation = useRef<FormikProps<any>>(null);
  const formikRefPoliticsInformation = useRef<FormikProps<any>>(null);
  const formikRefLaborContract = useRef<FormikProps<any>>(null);
  const formikRefSalaryInformation = useRef<FormikProps<any>>(null);
  const formikRefTrainingInfomation = useRef<FormikProps<any>>(null);
  const [activeFormikRef, setActiveFormikRef] = useState<FormikProps<any> | null>();
  const [nationality, setNationality] = useState([]);
  const [nationalityDefault, setNationalityDefault] = useState(null);
  const [isDialogExportWord, setIsDialogExportWord] = useState<boolean>(false);
  const { setPageLoading } = useContext(AppContext);

  const { currentUser } = useAuth();

  const checkID = async () => {
    let employeeId = currentUser?.id ? currentUser?.id : currentUser?.user?.id;
    const { data } = await getEmployeeIdByUserId(employeeId);
    setIdEmployee(data.data?.id);
  };
  useEffect(() => {
    if (hasRole(ROLE.USER)) {
      checkID();
      setTitle("PROFILE.EDIT");
      handleToggleIsView();
    }
  }, [currentUser]);

  const blockEditContract = !hasAuthority(PERMISSIONS.HOP_DONG, PERMISSION_ABILITY.UPDATE) && hasRole(ROLE.USER);
  const blockEditSalary = !hasAuthority(PERMISSIONS.LUONG, PERMISSION_ABILITY.UPDATE) && hasRole(ROLE.USER);

  useEffect(() => {
    updateDataNationality();
  }, []);

  useLayoutEffect(() => {
    const tabList: any = document.querySelector(".tabs-user-info ul");

    if (tabList) {
      const measure = debounce(() => {
        const isScroll = tabList.clientWidth < tabList?.scrollWidth;
        const rightArrow = document.querySelector(".tabs-user-info .arrow-right");
        const leftArrow = document.querySelector(".tabs-user-info .arrow-left");
        if (isScroll) {
          rightArrow?.classList?.remove("hidden");
          const handleShowIcon = () => {
            if (tabList) {
              if (tabList.scrollLeft >= 20) {
                leftArrow?.classList?.remove("hidden");
                tabList.style.marginLeft = "0px";
                tabList.style.marginRight = "24px";
              } else {
                leftArrow?.classList?.add("hidden");
              }

              const maxScrollValue = tabList.scrollWidth - tabList.clientWidth - 20;
              if (tabList.scrollLeft >= maxScrollValue) {
                tabList.style.marginLeft = "24px";
                tabList.style.marginRight = "0px";
                rightArrow?.classList?.add("hidden");
              } else {
                rightArrow?.classList?.remove("hidden");
              }
            }
          };
          rightArrow?.addEventListener("click", () => {
            if (tabList) {
              tabList.scrollLeft += 100;
              handleShowIcon();
            }
          });
          leftArrow?.addEventListener("click", () => {
            if (tabList) {
              tabList.scrollLeft -= 100;
              handleShowIcon();
            }
          });
          tabList?.addEventListener("scroll", handleShowIcon);
        }
      }, 250);

      requestAnimationFrame(measure);

      return () => {
        window.removeEventListener('resize', measure);
      };
    }
  }, []);

  const updateDataNationality = async () => {
    try {
      const { data, status } = await searchNationality(SEARCH_OBJECT_MAX_SIZE);
      if (data?.code === RESPONSE_STATUS_CODE.SUCCESS && status === RESPONSE_STATUS_CODE.SUCCESS) {
        setNationality(data?.data?.content);
        let defaultItem = data?.data?.content?.find((item: any) => item?.name === "Việt Nam");
        setNationalityDefault(defaultItem);
      } else {
        toast.error(data?.message);
      }
    } catch (error) {
      toast.error(intl.formatMessage({ id: "GENERAL.ERROR" }));
    }
  };

  useEffect(() => {
    if (idEmployee) {
      handleGetEmployee(idEmployee);
    }
  }, [idEmployee]);

  const handleGetEmployee = async (id: string) => {
    try {
      setPageLoading(true);
      let { data } = await getEmployeeProfileById(id);
      setEmployeeProfiles(convertDataUI(data?.data));
      setIsResign(data?.data?.trangThaiLaoDong?.code === STATUS_NV.THOI_VIEC);
      setIdentify(id);
    } catch (err: any) {
      toast.error(err);
    } finally {
      setPageLoading(false);
    }
  };

  const handleClose = () => {
    handleCloseDialog();
    setEmployeeProfiles(defaultEmployeeProfile);
  };

  const handleUploadAvatar = (url: string) => {
    setEmployeeProfiles({ ...employeeProfiles, photo: url });
    handleAddBasicInfo(employeeProfiles);
  };

  const handleOpenField = (statusField: boolean, condition: string | number, handleSet: any) => {
    if (statusField === false && !condition) {
      toast.warning(intl.formatMessage({ id: "MESSAGE.BASIC.FIRST" }));
    } else {
      handleSet(!statusField);
    }
  };

  const handleChangeSelect = (name: string, value: string) => {
    setEmployeeProfiles({ ...employeeProfiles, [name]: value });
  };

  const handleTabChange = (tabId: string) => {
    if (tabId !== REF_TAB.TT_CO_BAN && !identify) {
      toast.warning(intl.formatMessage({ id: "MESSAGE.BASIC.FIRST" }));
      return;
    }
    setActiveTab(tabId);
    switch (tabId) {
      case REF_TAB.TT_CO_BAN:
        setActiveFormikRef(formikRefBasicInformation.current);
        break;
      case REF_TAB.TT_LIEN_HE:
        setActiveFormikRef(formikRefContactInformation.current);
        break;
      case REF_TAB.TT_CONG_VIEC:
        setActiveFormikRef(formikRefWorkInformation.current);
        break;
      case REF_TAB.TT_CHINH_TRI:
        setActiveFormikRef(formikRefPoliticsInformation.current);
        break;
      case REF_TAB.BAO_HIEM:
        setActiveFormikRef(formikRefInsuranceInfomation.current);
        break;
      case REF_TAB.TT_LUONG:
        setActiveFormikRef(formikRefSalaryInformation.current);
        break;
      case REF_TAB.HOP_DONG_LAO_DONG:
        setActiveFormikRef(formikRefLaborContract.current);
        break;
      case REF_TAB.DAO_TAO_BOI_DUONG:
        setActiveFormikRef(formikRefTrainingInfomation.current);
        break;
      default:
        setActiveFormikRef(null);
        break;
    }
  };

  useEffect(() => {
    setActiveFormikRef(formikRefBasicInformation.current);
  }, []);

  const handleSubmit = () => {
    if (activeFormikRef) {
      activeFormikRef.submitForm();
    }
  };

  const handleGetDataFromChildren = (data: {}) => {
    setDataFromChild({ ...dataFromChild, ...data });
  };

  const handleExportWord = () => {
    setIsDialogExportWord(true)
  }

  return (
    <>
      <div className="header-container color-primary">
        {!isView ? (
          <>
            {" "}
            <span className="fs-1 fw-bold">{intl.formatMessage({ id: title })}</span>
            <div className="actions">
              {!hasRole(ROLE.USER) && (
                <button
                  className="button-primary-outline me-2 "
                  onClick={handleCloseDialog}
                >
                  {intl.formatMessage({ id: "BTN.BACK" })}
                </button>
              )}
              <button
                className="btn btn-outline-primary btn-sm btn-sm button-primary"
                onClick={handleSubmit}
              >
                {intl.formatMessage({ id: "BTN.SAVE" })}
              </button>
              {idEmployee && <button className="spaces button-primary ml-6" onClick={handleExportWord}>Xuất word</button>}
            </div>
          </>
        ) : (
          <>
            {!hasRole(ROLE.USER) && (
              <div className="ms-2 text-uppercase">
                <GroupButton
                  type="btn-back"
                  handleClose={handleClose}
                ></GroupButton>
              </div>
            )}
            {hasRole(ROLE.USER) && <span className="fs-1 fw-bold">{intl.formatMessage({ id: title })}</span>}
            <div className="me-2 flex">
              {employeeProfiles.trangThaiLaoDong?.code !== STATUS_NV.THOI_VIEC && (
                <GroupButton
                  type="btn-edit"
                  handleSaveEdit={() => {
                    handleToggleIsView();
                    setTitle("PROFILE.EDIT");
                  }}
                  handleClose={handleClose}
                />
              )}
              <button className="spaces button-primary ml-6" onClick={handleExportWord}>Xuất word</button>
            </div>
          </>
        )}
      </div>
      <div className="layout-v2 flex align-items-start">
        <div className="general-user-info flex-column align-items-start align-self-stretch">
          <EmployeeInfo
            isView={isView}
            handleUploadAvatar={handleUploadAvatar}
            employeeProfiles={employeeProfiles}
          />
        </div>
        <div className="tabs-user-info flex-1">
          <div className="d-grid relative">
            <KTSVG
              path="/media/icons/arrow-left.svg"
              className="text-white arrow-left hidden"
              svgClassName={`position-absolute`}
            />
            <ul
              className="nav nav-tabs nav-line-tabs flex-nowrap text-nowrap overflow-hidden overflow-x-auto"
              id="profileTabsUl"
            >
              <li className="nav-item">
                <CustomTooltip
                  title={"Thông tin cơ bản"}
                  placement="bottom"
                >
                  <a
                    className="nav-link active"
                    data-bs-toggle="tab"
                    href={REF_TAB.TT_CO_BAN}
                    onClick={(e: any) => handleTabChange(REF_TAB.TT_CO_BAN)}
                  >
                    <KTSVG path="/media/icons/user.svg" />
                  </a>
                </CustomTooltip>
              </li>
              {profileTabs.map((tab, index) => (
                <li
                  className="nav-item"
                  key={tab.id + index}
                >
                  <CustomTooltip
                    title={tab.name}
                    placement="bottom"
                  >
                    <a
                      className={`nav-link`}
                      data-bs-toggle={identify ? "tab" : ""}
                      href={tab?.ref}
                      onClick={() => handleTabChange(tab.ref)}
                    >
                      <KTSVG path={tab.svgPath} />
                    </a>
                  </CustomTooltip>
                </li>
              ))}
            </ul>
            <KTSVG
              path="/media/icons/arrow-left.svg"
              className="text-white arrow-right hidden"
              svgClassName={`position-absolute`}
            />
          </div>
          <div
            className="tab-content"
            id="myTabContent"
          >
            <div
              className="tab-pane fade show active"
              id="kt_tab_pane_1"
              role="tabpanel"
            >
              <BasicInformation
                identify={identify}
                activeTab={activeTab}
                formikRef={formikRefBasicInformation}
                employeeProfiles={employeeProfiles}
                isView={isView}
                handleOpenField={handleOpenField}
                handleToggleIsView={handleToggleIsView}
                handleGetEmployee={handleGetEmployee}
                handleChangeSelect={handleChangeSelect}
                nationality={nationality}
                nationalityDefault={nationalityDefault}
              />
            </div>
            <div
              className="tab-pane fade"
              id="kt_tab_pane_2"
              role="tabpanel"
            >
              <ContactInformation
                identify={identify}
                activeTab={activeTab}
                formikRef={formikRefContactInformation}
                employeeProfiles={employeeProfiles}
                isView={isView}
                handleOpenField={handleOpenField}
                handleToggleIsView={handleToggleIsView}
                handleGetEmployee={handleGetEmployee}
                handleChangeSelect={handleChangeSelect}
                nationality={nationality}
                nationalityDefault={nationalityDefault}
              />
            </div>
            <div
              className="tab-pane fade"
              id="kt_tab_pane_3"
              role="tabpanel"
            >
              <WorkInformation
                activeTab={activeTab}
                handleGetDataFromChildren={handleGetDataFromChildren}
                identify={identify}
                formikRef={formikRefWorkInformation}
                employeeProfiles={employeeProfiles}
                isView={isView}
                handleGetEmployee={handleGetEmployee}
                handleToggleIsView={handleToggleIsView}
                handleChangeSelect={handleChangeSelect}
              />
            </div>
            <div
              className="tab-pane fade"
              id="kt_tab_pane_4"
              role="tabpanel"
            >
              <TranningInfomation
                isView={isView}
                activeTab={activeTab}
                identify={identify}
                employeeProfiles={employeeProfiles}
                formikRef={formikRefTrainingInfomation}
                handleToggleIsView={handleToggleIsView}
              />
            </div>
            <div
              className="tab-pane fade"
              id="kt_tab_pane_5"
              role="tabpanel"
            >
              <SalaryInformation
                activeTab={activeTab}
                isView={isView}
                identify={identify}
                employeeProfiles={employeeProfiles}
                handleGetEmployee={handleGetEmployee}
                formikRef={formikRefSalaryInformation}
                handleToggleIsView={handleToggleIsView}
              />
            </div>
            <div
              className="tab-pane fade"
              id="kt_tab_pane_6"
              role="tabpanel"
            >
              <ContractInformation
                identify={identify}
                isView={isView}
                formikRef={formikRefLaborContract}
                activeTab={activeTab}
                employeeProfiles={employeeProfiles}
                handleToggleIsView={handleToggleIsView}
              />
            </div>
            <div
              className="tab-pane fade"
              id="kt_tab_pane_7"
              role="tabpanel"
            >
              <WorkingProcessInformation
                handleGetDataFromChildren={handleGetDataFromChildren}
                identify={identify}
                isView={isView}
                employeeProfiles={employeeProfiles}
                activeTab={activeTab}
                handleToggleIsView={handleToggleIsView}
                handleGetEmployee={handleGetEmployee}
              />
            </div>
            <div
              className="tab-pane fade"
              id="kt_tab_pane_8"
              role="tabpanel"
            >
              <PoliticsInformation
                activeTab={activeTab}
                identify={identify}
                employeeProfiles={employeeProfiles}
                formikRef={formikRefPoliticsInformation}
                isView={isView}
                handleToggleIsView={handleToggleIsView}
              />
            </div>
            <div
              className="tab-pane fade"
              id="kt_tab_pane_9"
              role="tabpanel"
            >
              <FamilyInformation
                handleGetDataFromChildren={handleGetDataFromChildren}
                employeeProfiles={employeeProfiles}
                identify={identify}
                isView={isView}
                activeTab={activeTab}
                handleToggleIsView={handleToggleIsView}
                handleGetEmployee={handleGetEmployee}
              />
            </div>
            <div
              className="tab-pane fade"
              id="kt_tab_pane_10"
              role="tabpanel"
            >
              <InsuranceInfomation
                activeTab={activeTab}
                isView={isView}
                formikRef={formikRefInsuranceInfomation}
                identify={identify}
                employeeProfiles={employeeProfiles}
                handleGetEmployee={handleGetEmployee}
                handleToggleIsView={handleToggleIsView}
              />
            </div>
            <div
              className="tab-pane fade"
              id="kt_tab_pane_11"
              role="tabpanel"
            >
              <WorkExperienceInformation
                isView={isView}
                handleGetDataFromChildren={handleGetDataFromChildren}
                identify={identify}
                employeeProfiles={employeeProfiles}
                handleGetEmployee={handleGetEmployee}
                activeTab={activeTab}
                handleToggleIsView={handleToggleIsView}
              />
            </div>
            <div
              className="tab-pane fade"
              id="kt_tab_pane_14"
              role="tabpanel"
            >
              <WelfareInfomation
                isView={isView}
                activeTab={activeTab}
                employeeProfiles={employeeProfiles}
              />
            </div>
            <div
              className="tab-pane fade"
              id="kt_tab_pane_15"
              role="tabpanel"
            >
              <BonusAndDisciplineInfomation
                activeTab={activeTab}
                employeeProfiles={employeeProfiles}
              />
            </div>
            <div
              className="tab-pane fade"
              id="kt_tab_pane_16"
              role="tabpanel"
            >
              <VaccinationInformation
                isView={isView}
                activeTab={activeTab}
                identify={identify}
                employeeProfiles={employeeProfiles}
              />
            </div>
            <div
              className="tab-pane fade"
              id="kt_tab_pane_17"
              role="tabpanel"
            >
              {/* Y tế */}
              <MedicalInformation
                activeTab={activeTab}
                identify={identify}
                employeeProfiles={employeeProfiles}
                isView={isView}
                handleGetEmployee={handleGetEmployee}
                handleToggleIsView={handleToggleIsView}
                handleChangeSelect={handleChangeSelect}
              />
              {/* Quân sự */}
              <MilitaryInformation
                activeTab={activeTab}
                identify={identify}
                employeeProfiles={employeeProfiles}
                isView={isView}
                handleToggleIsView={handleToggleIsView}
                handleGetEmployee={handleGetEmployee}
                handleChangeSelect={handleChangeSelect}
              />
              {/* Danh Hiệu phong tặng */}
              <TitleConferredInfomation
                activeTab={activeTab}
                identify={identify}
                employeeProfiles={employeeProfiles}
                isView={isView}
              />
              {/* Thông tin gia đình chính sách */}
              <FamilyPolicyInfomation
                activeTab={activeTab}
                identify={identify}
                employeeProfiles={employeeProfiles}
                isView={isView}
              />
              {/* Giấy tờ */}
              <TempPapersInformation
                handleGetDataFromChildren={handleGetDataFromChildren}
                identify={identify}
                isView={isView}
                employeeProfiles={employeeProfiles}
                activeTab={activeTab}
                handleGetEmployee={handleGetEmployee}
              />
              {/* Tài liệu đính kèm */}
              <AttachmentsInformation
                handleGetDataFromChildren={handleGetDataFromChildren}
                identify={identify}
                isView={isView}
                employeeProfiles={employeeProfiles}
                activeTab={activeTab}
                handleGetEmployee={handleGetEmployee}
              />
            </div>
            <div
              className="tab-pane fade"
              id="kt_tab_pane_19"
              role="tabpanel"
            >
              <ProblemInfomation
                activeTab={activeTab}
                employeeProfiles={employeeProfiles}
              />
            </div>
            <div
              className="tab-pane fade"
              id="kt_tab_pane_20"
              role="tabpanel"
            >
              <ListInsuranreInfomation
                activeTab={activeTab}
                employeeProfiles={employeeProfiles}
              />
            </div>
          </div>
        </div>
      </div>

      {
        isDialogExportWord &&
        <ExportWord
          handleClose={() => setIsDialogExportWord(false)}
          idEmployee={idEmployee}
        />
      }
    </>
  );
};
