import { Modal, Button, Row, Col, Form } from "react-bootstrap";
import useMultiLanguage from "../../../../../hook/useMultiLanguage";
import Autocomplete from "../../../../component/input-field/Autocomplete";
import TextValidator from "../../../../component/input-field/TextValidator";
import UploadFile from "../../../../component/FileUpload/UploadFile";
import { FILE_TYPE } from "../../../../component/FileUpload/constant";
import { useEffect, useState } from "react";
import { INIT_DISCIPLINE_INFO } from "../../../../discipline/consts/DisciplineConsts";
import { convertDataUI } from "../../../../problem/utils/ProblemUtils";
import { IProblem } from "../../../../problem/models/ProblemModel";
import { PROBLEM_OBJECTS } from "../../../../problem/constant/ProblemConst";
import { convertNumberPrice } from "../../../../utils/FunctionUtils";
import { TYPE } from "../../../../utils/Constant";

interface IProps {
  open: boolean;
  handleClose: () => void;
  problemInfo: IProblem;
  isView?: boolean;
}

function ProblemViewDialog(props: IProps) {
  const { open, handleClose, isView = true, problemInfo } = props;
  const { lang } = useMultiLanguage();

  const [data, setData] = useState<IProblem>({} as IProblem);

  useEffect(() => {
    if (problemInfo?.id) {
      setData(convertDataUI(problemInfo));
    }
  }, [problemInfo]);

  return (
    <Modal
      show={open}
      onHide={handleClose}
      centered
      size="xl"
    >
      <Modal.Header
        closeButton
        className="spaces py-12"
      >
        <Modal.Title className="spaces fs-20 fw-600">{lang("PROBLEM.INFOMATION")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row className="gap-col-modal">
          <Col xs={4}>
            <Autocomplete
              isRequired
              lable={lang("PROBLEM.OBJECT")}
              options={PROBLEM_OBJECTS}
              isReadOnly={isView}
              value={data?.doiTuong || null}
              name="doiTuong"
              isClearable={false}
            />
          </Col>
          <Col xs={4}>
            <TextValidator
              name="ngayXayRa"
              isRequired
              lable={lang("PROBLEM.NAMEDATE_OF_OCCURRED")}
              type="date"
              value={data?.ngayXayRa || ""}
              readOnly={isView}
            />
          </Col>
          <Col xs={4}>
            <TextValidator
              name="tenSuCo"
              isRequired
              lable={lang("PROBLEM.NAME")}
              type="text"
              value={data?.tenSuCo}
              readOnly={isView}
            />
          </Col>
          <Col xs={4}>
            <Autocomplete
              isRequired
              lable={lang("PROBLEM.TYPE")}
              getOptionLabel={(value) => value?.value}
              options={[]}
              isReadOnly={isView}
              value={data?.loaiSuCo || null}
              name="loaiSuCo"
              isClearable={false}
            />
          </Col>
          <Col xs={4}>
            <Autocomplete
              lable={lang("GENERAL.STATUS")}
              isReadOnly={isView}
              isRequired
              options={[]}
              value={data?.trangThai || null}
              name="trangThai"
            />
          </Col>
          <Col xs={4}>
            <Autocomplete
              isRequired
              lable={lang("PROBLEM.FORMS_PROCESSING")}
              getOptionLabel={(value) => value?.value}
              options={[]}
              isReadOnly={isView}
              value={data?.hinhThucXuLy || null}
              name="hinhThucXuLy"
              isClearable={false}
            />
          </Col>
          <Col xs={4}>
            <TextValidator
              name="giaTriThietHai"
              lable={lang("PROBLEM.TOTAL_VALUE_OF_DAMAGES")}
              type="text"
              value={convertNumberPrice(data?.giaTriThietHai || "")}
              readOnly={isView}
            />
          </Col>
          <Col xs={4}>
            <TextValidator
              name="soTienViPham"
              lable={lang("PROBLEM.VIOLATION_AMOUNT")}
              type="text"
              value={convertNumberPrice(data?.soTienViPham || "")}
              readOnly={isView}
            />
          </Col>
          <Col xs={4}>
            <TextValidator
              name="noiXayRa"
              lable={lang("PROBLEM.PLACE_OF_OCCURRED")}
              type="text"
              value={data?.noiXayRa}
              readOnly={isView}
            />
          </Col>
          <Col xs={4}>
            <Autocomplete
              lable={lang("PROBLEM.EXCEPT_MONTHLY_SALARY")}
              options={[]}
              isReadOnly={isView}
              value={data?.thangTruLuongObj || null}
              name="thangTruLuong"
            />
          </Col>
          <Col xs={4}>
            <Autocomplete
              lable={lang("PROBLEM.EXCEPT_YEAR_SALARY")}
              options={[]}
              name="namTruLuongObj"
              value={data?.namTruLuongObj || null}
              isReadOnly={isView}
            />
          </Col>
          <Col xs={4}>
            <UploadFile
              label="INPUT_APPOINTMENT_ATTACHMENTS"
              setValue={() => {}}
              fileValue={{
                id: data?.fileId || "",
                name: data?.fileName || ""
              }}
              allowFileTypes={`${FILE_TYPE.PDF},${FILE_TYPE.DOC},${FILE_TYPE.DOCX},${FILE_TYPE.XLS},${FILE_TYPE.XLSX}`}
              isReadOnly={isView}
            />
          </Col>

          <Col xs={8}>
            <TextValidator
              name="nguyenNhan"
              lable={lang("PROBLEM.REASON")}
              type="text"
              value={data?.nguyenNhan}
              readOnly={isView}
            />
          </Col>
          <Col xs={12}>
            <TextValidator
              className="h-auto-input spaces"
              lable={lang("PROBLEM.DESCRIPTION")}
              name="moTa"
              value={data?.moTa}
              type="text"
              readOnly={isView}
              rows={3}
              as={TYPE.TEXTAREA}
            />
          </Col>
          <Col xs={12}>
            <TextValidator
              className="h-auto-input spaces"
              lable={lang("GENERAL.NOTE")}
              name="note"
              value={data.note}
              type="text"
              readOnly={isView}
              rows={3}
              as={TYPE.TEXTAREA}
            />
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer className="flex-center">
        <Button
          variant="outline-secondary"
          className="button-secondary btn-sm"
          onClick={handleClose}
        >
          {lang("BTN.CANCEL")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ProblemViewDialog;
