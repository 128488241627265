import React, { useEffect, useState } from 'react'
import { Col, Image, Row } from 'react-bootstrap'
import TableCustom from '../../../component/table-custom/TableCustom';
import "./StyleWord.scss"
import TextValue from '../../../component/TextValue';
import { columnsWorkingProcess } from '../Column/ColumnsWorkingProcess';
import { columnsLevel } from '../Column/ColumnsLevel';
import { toAbsoluteUrl } from '../../../../../_metronic/helpers';
import { checkEmptyArray } from '../../utils/FunctionUtils';
import TextUnderline from '../../../component/TextUnderline';
import { checkObject } from '../../../utils/FunctionUtils';

type Props = {
  dataEmployee?: any
}

function VCBNV(props: Props) {
  const { dataEmployee } = props;
  const [data27, setData27] = useState<any>([])

  useEffect(() => {
    if (!checkObject(dataEmployee)) {
      let bc = (dataEmployee?.chuyenMon || []).map((item: any) => {
        return {
          name: item?.bcCoSoDaoTao,
          chuyenNganh: item?.bcChuyenNganh,
          thoiGian: item?.bcThoiGianDaoTao,
          vanBang: item?.bcVanBang,
          hinhThuc: item?.bcHinhThucDaoTao,
        }
      })

      let llct = (dataEmployee?.lyLuanChinhTri || []).map((item: any) => {
        return {
          name: item?.llctCoSoDaoTao,
          thoiGian: item?.llctThoiGianDaoTao,
          vanBang: item?.llctVanBang,
          hinhThuc: item?.llctHinhThucDaoTao,
        }
      })

      let cc = ([...dataEmployee?.ngoaiNgu, ...dataEmployee?.tinHoc]).map((item: any) => {
        return {
          name: item?.ccCoSoDaoTao,
          thoiGian: item?.ccThoiGianDaoTao,
          vanBang: item?.ccVanBang,
          hinhThuc: item?.ccHinhThucDaoTao,
        }
      })

      let qtbd = (dataEmployee?.chucDanhNgheNghiep || []).map((item: any) => {
        return {
          name: item?.qtbdCoSoDaoTao,
          thoiGian: item?.qtctThoiGianDaoTao,
          vanBang: item?.qtbdVanBang,
          hinhThuc: item?.qtbdHinhThucDaoTao,
        }
      })
      setData27([...bc, ...llct, ...cc, ...qtbd])
    }
  }, [dataEmployee])

  const columnsFamilyRelationships = [
    {
      name: "STT",
      field: "",
      render: (row: any, index: number, stt: number) => <span>{stt}</span>
    },
    {
      name: "Mối quan hệ",
      field: "qhgdQuanHe",
      headerStyle: {
        minWidth: "150px",
        textAlign: "center",
      },
      cellStyle: {
        textAlign: "center",
      },
      render: (row: any) => row?.qhgdQuanHe ? <span>{row?.qhgdQuanHe}</span> : <TextValue className="spaces h-25" value="" />
    },
    {
      name: "Họ và tên",
      field: "qhgdTen",
      headerStyle: {
        minWidth: "130px",
        textAlign: "center",
      },
      cellStyle: {
        textAlign: "left",
      },
      render: (row: any) => row?.qhgdTen ? <span>{row?.qhgdTen}</span> : <TextValue className="spaces h-25" value="" />
    },
    {
      name: "Năm sinh",
      field: "qhgdNamSinh",
      headerStyle: {
        minWidth: "50px",
        textAlign: "center",
      },
      cellStyle: {
        textAlign: "center",
      },
      render: (row: any) => row?.qhgdNamSinh ? <span>{row?.qhgdNamSinh}</span> : <TextValue className="spaces h-25" value="" />
    },
    {
      name: "Quê quán, nghề nghiệp, chức danh, chức vụ, đơn vị, công tác, học tập, nơi ở (trong, ngoài nước); thành viên các tổ chức chính trị-xã hội ...?)",
      field: "qhgdDetails",
      headerStyle: {
        minWidth: "500px",
        textAlign: "center",
      },
      cellStyle: {
        textAlign: "left",
      },
      render: (row: any) => row?.qhgdDetails ? <span>{row?.qhgdDetails}</span> : <TextValue className="spaces h-25" value="" />
    },
  ]

  const columnsSalaryHistory = [
    {
      name: "STT",
      field: "",
      render: (row: any, index: number, stt: number) => <span>{stt}</span>
    },
    {
      name: "Tháng/năm",
      field: "lslThoiGianHuongLuong",
      headerStyle: {
        minWidth: "100px",
        textAlign: "center",
      },
      cellStyle: {
        textAlign: "center",
      },
      render: (row: any) => row?.lslThoiGianHuongLuong ? <span>{row?.lslThoiGianHuongLuong}</span> : <TextValue className="spaces h-25" value="" />
    },
    {
      name: "Mã số",
      field: "lslChucDanh",
      headerStyle: {
        minWidth: "100px",
        textAlign: "center",
      },
      cellStyle: {
        textAlign: "center",
      },
      render: (row: any) => row?.lslChucDanh ? <span>{row?.lslChucDanh}</span> : <TextValue className="spaces h-25" value="" />
    },
    {
      name: "Ngạch/bậc",
      field: "lslBacLuong",
      headerStyle: {
        minWidth: "130px",
        maxWidth: "130px",
        textAlign: "center",
      },
      cellStyle: {
        minWidth: "130px",
        maxWidth: "130px",
        textAlign: "center",
      },
      render: (row: any) => row?.lslBacLuong ? <span>{row?.lslBacLuong}</span> : <TextValue className="spaces h-25" value="" />
    },
    {
      name: "Hệ số lương",
      field: "lslHeSo",
      headerStyle: {
        minWidth: "50px",
        textAlign: "center",
      },
      cellStyle: {
        textAlign: "center",
      },
      render: (row: any) => row?.lslHeSo ? <span>{row?.lslHeSo}</span> : <TextValue className="spaces h-25" value="" />
    },
  ]

  return (
    <div className='word'>
      <i className='flex flex-end'>Mẫu HS02-VC/BNV ban hành kèm theo Thông tư số 07/2019/TT-BNV ngày 01/6/2019 của Bộ trưởng Bộ Nội vụ</i>
      <div className='flex'>
        <div className='flex-1'>
          <TextUnderline readOnly label="Cơ quan quản lý viên chức" value="UBND Thành phố Hà Nội" />
          <TextUnderline readOnly label="Đơn vị sử dụng viên chức" value={dataEmployee?.khoaPhong} />
        </div>
        <p className='spaces w-200'></p>
        <TextUnderline className="flex-1" readOnly label="Số hiệu viên chức" value={dataEmployee?.soHieu}/>
      </div>

      <div className='spaces flex pt-16 mb-12'>
        <div className="image-profile spaces mt-16">
          <Image src={toAbsoluteUrl("/media/avatars/blank.png")} alt="Ảnh màu (4 x 6cm)" className="w-100 h-100" />
        </div>
        <div className='flex-1'>
          <p className='spaces mb-18 fw-700 fs-24 flex flex-center' >SƠ YẾU LÝ LỊCH VIÊN CHỨC</p>
          <TextUnderline readOnly label="1) Họ và tên khai sinh (viết chữ in hoa)" className="text-uppercase" value={dataEmployee?.name} />
          <TextUnderline readOnly label="2) Tên gọi khác" value={dataEmployee?.otherName} />
          <Row>
            <Col xs={7} className='flex'>
              <TextUnderline readOnly label="3) Sinh ngày" value={dataEmployee?.ngaySinh} />
              <TextUnderline readOnly label="tháng" value={dataEmployee?.thangSinh} />
              <TextUnderline readOnly label="năm" value={dataEmployee?.namSinh} />
            </Col>
            <Col xs={5}>
              <TextUnderline readOnly label="Giới tính (nam, nữ)" value={dataEmployee?.gender?.name} />
            </Col>
          </Row>
          <div className='flex'>
            <TextUnderline readOnly label="4) Nơi sinh: Xã/Phường" value={dataEmployee?.noiSinhXaText} />
            <TextUnderline readOnly label="Huyện/Quận" value={dataEmployee?.noiSinhHuyenText} />
            <TextUnderline readOnly label="Tỉnh/Thành phố" value={dataEmployee?.noiSinhTinhText} />
          </div>
          <div className='flex'>
            <TextUnderline readOnly label="5) Quê quán: Xã/Phường" value={dataEmployee?.queQuanXaText} />
            <TextUnderline readOnly label="Huyện/Quận" value={dataEmployee?.queQuanHuyenText} />
            <TextUnderline readOnly label="Tỉnh/Thành phố" value={dataEmployee?.queQuanTinhText} />
          </div>
          <div className='flex'>
            <TextUnderline readOnly label="6) Dân tộc" value={dataEmployee?.danTocText} />
            <TextUnderline readOnly label="7) Tôn giáo" value={dataEmployee?.tonGiaoText} />
          </div>
        </div>
      </div>
      <div>
        <TextUnderline readOnly label="8) Nơi đăng ký hộ khẩu thường trú" value={dataEmployee?.hkDiachi} />
        <i>(Số nhà, đường phố, thành phố: xóm, thôn, xã, huyện, tỉnh)</i>
      </div>
      <div>
        <TextUnderline readOnly label="9) Nơi ở hiện nay" value={dataEmployee?.hnDiaChi} />
        <i>(Số nhà, đường phố, thành phố: xóm, thôn, xã, huyện, tỉnh)</i>
      </div>
      <TextUnderline readOnly label="10) Nghề nghiệp khi được tuyển dụng" value={""} />
      <Row>
        <Col xs={5}>
          <TextUnderline readOnly type="date" label="11) Ngày được tuyển dụng" value={dataEmployee?.ngayChinhThuc} />
        </Col>
        <Col xs={7}>
          <TextUnderline readOnly label="Cơ quan, đơn vị tuyển dụng" value={dataEmployee?.donViCongTacText} />
        </Col>
      </Row>
      <div>
        <TextUnderline readOnly label="12.1- Chức danh (chức vụ) công tác hiện tại" value={""} />
        <i>(Về chính quyền hoặc Đảng, đoàn thể)</i>
      </div>
      <div>
        <TextUnderline readOnly label="12.2- Chức danh (chức vụ) kiêm nhiệm" value={""} />
        <i>(Về chính quyền hoặc Đảng, đoàn thể)</i>
      </div>
      <TextUnderline readOnly label="13) Công việc chính được giao" value={""} />
      <div className='flex'>
        <TextUnderline readOnly label="14) Chức danh nghề nghiệp viên chức" value={dataEmployee?.chucDanh} />
        <TextUnderline readOnly label="Mã số" value={dataEmployee?.maSoChucDanh} />
      </div>
      <div className='flex'>
        <TextUnderline readOnly label="Bậc lương" className="spaces w-150" value={dataEmployee?.bacLuong} />
        <TextUnderline readOnly label="Hệ số" className="spaces w-120" value={dataEmployee?.heSoBacLuong} />
        <TextUnderline readOnly className="spaces w-250" label="Ngày hưởng" type="date" value={dataEmployee?.ngayHuongLuongTuNgay} />
        <TextUnderline readOnly label="Phụ cấp chức danh" className="flex-1" value={dataEmployee?.pcChucVu} />
        <TextUnderline readOnly label="Phụ cấp khác" className="flex-1" value={dataEmployee?.pcKhac} />
      </div>
      <TextUnderline readOnly label="15.1) Trình độ giáo dục phổ thông (đã tốt nghiệp lớp mấy/thuộc hệ nào)" value={dataEmployee?.trinhDoGiaoDucPhoThongText} />
      <div>
        <TextUnderline readOnly label="15.2) Trình độ chuyên môn cao nhất" value={dataEmployee?.trinhDoNghiepVuChuyenNganh} />
        <i>(TSKH, TS, Th.s, cử nhân, kỹ sư, cao đẳng, trung cấp, sơ cấp, chuyên ngành)</i>
      </div>
      <div className='flex'>
        <div className='spaces flex-1 pr-16'>
          <TextUnderline readOnly label="15.3) Lý luận chính trị" value={dataEmployee?.trinhDoLyLuanChinhTri} />
          <i>(Cử nhân, cao cấp, trung cấp, sơ cấp)</i>
        </div>
        <div className='flex-1'>
          <TextUnderline readOnly label="15.4) Quản lý nhà nước" value={dataEmployee?.trinhDoQuanLyNhaNuoc} />
          <i>(Chuyên viên cao cấp, chuyên viên chính, chuyên viên, cán sự.)</i>
        </div>
      </div>
      <TextUnderline readOnly label="15.5) Trình độ nghiệp vụ theo chuyên ngành" value={dataEmployee?.trinhDoNghiepVuChuyenNganh} />
      <div className='flex'>
        <div className='spaces flex-1 pr-16'>
          <TextUnderline readOnly label="15.6-Ngoại ngữ" value={dataEmployee?.trinhDoNgoaiNgu} />
          <i>(Tên ngoại ngữ + Trình độ: TS, Ths, ĐH, Bậc 1 đến bậc 6)</i>
        </div>
        <div className='flex-1'>
          <TextUnderline readOnly label="15.7-Tin học" value={dataEmployee?.trinhDoTinHoc} />
          <i>(Trình độ: TS, Ths, ĐH, Kỹ năng 01 đến Kỹ năng 15)</i>
        </div>
      </div>
      <Row>
        <Col xs={7}>
          <TextUnderline readOnly label="16) Ngày vào Đảng Cộng sản Việt Nam" type="date" value={dataEmployee?.ttctNgayVaoDang} />
        </Col>
        <Col xs={5}>
          <TextUnderline readOnly label="Ngày chính thức" type="date" value={dataEmployee?.ttctNgayChinhThuc} />
        </Col>
      </Row>
      <div>
        <TextUnderline readOnly label="17) Ngày tham gia tổ chức  chính trị - xã hội" type="date" value={dataEmployee?.ngayVaoDoan} />
        <i>(Ngày tham gia tổ chức: Đoàn, Hội, …. và  làm việc gì trong tổ chức đó)</i>
      </div>
      <Row>
        <Col xs={3}>
          <TextUnderline readOnly label="18) Ngày nhập ngũ" type="date" value={dataEmployee?.ngayNhapNgu} />
        </Col>
        <Col xs={3} >
          <TextUnderline readOnly label="Ngày xuất ngũ" type="date" value={dataEmployee?.ngayXuatNgu} />
        </Col>
        <Col xs={6}>
          <TextUnderline readOnly label="Quân hàm cao nhất" value={dataEmployee?.capBacQuanSuText} />
        </Col>
      </Row>
      <div>
        <TextUnderline readOnly label="19.1) Danh hiệu được phong tặng cao nhất" value={dataEmployee?.danhHieu?.name} />
        <i>(Anh hùng lao động, anh hùng lực lượng vũ trang: nhà giáo, thầy thuốc, nghệ sĩ nhân dân ưu tú,…)</i>
      </div>
      <div className='flex'>
        <TextUnderline readOnly label="19.2) Học hàm được phong (Giáo sư, Phó giáo sư)" className="flex-2" value={dataEmployee?.hocHam?.name} />
        <TextUnderline readOnly label="Năm được phong" className="flex-1" value={dataEmployee?.tddtNamPhongTang} />
      </div>
      <TextUnderline readOnly label="20) Sở trường công tác" value={""} />
      <div className='flex'>
        <div className='spaces flex-1 pr-16'>
          <TextUnderline readOnly label="21) Khen thưởng" value={dataEmployee?.khenThuongCaoNhat} />
          <i>(Hình thức cao nhất, năm nào?)</i>
        </div>
        <div className='flex-1'>
          <TextUnderline readOnly label="22) Kỷ luật " value={dataEmployee?.kyLuatCaoNhat} />
          <i>(về đảng, chính quyền, đoàn thể hình thức cao nhất, năm nào?)</i>
        </div>
      </div>
      <div className='flex'>
        <TextUnderline readOnly label="23) Tình trạng sức khoẻ" className=" flex-1" value={dataEmployee?.tinhTrangSucKhoe} />
        <div className='flex-middle'>
          <TextUnderline readOnly label="Chiều cao" className="spaces w-150" value={dataEmployee?.chieuCao} />
          <p className='unti-icon'>cm,</p>
        </div>
        <div className='flex-middle'>
          <TextUnderline readOnly label="Cân nặng" className="spaces w-150 ml-5" value={dataEmployee?.canNang} />
          <p className='unti-icon'>kg,</p>
        </div>
        <TextUnderline readOnly label="Nhóm máu" className="spaces w-150 ml-5" value={dataEmployee?.nhomMau?.name} />
      </div>
      <Row>
        <Col xs={5} className='flex'>
          <TextUnderline readOnly label="24) Là thương binh hạng" value={dataEmployee?.hangThuongBinh} />
        </Col>
        <Col xs={7}>
          <TextUnderline readOnly label="Là con gia đình chính sách" value={dataEmployee?.chinhSach?.name} />
        </Col>
        <i>(Con thương binh, con liệt sĩ, người nhiễm chất độc da cam, Dioxin)</i>
      </Row>
      <Row>
        <Col xs={6}>
          <TextUnderline readOnly label="25) Số chứng minh nhân dân/Căn cước công dân" value={dataEmployee?.soCMNDOrCCCD} />
        </Col>
        <Col xs={3}>
          <TextUnderline readOnly label="Ngày cấp" type="date" value={dataEmployee?.ngayCapCMNDOrCCCD} />
        </Col>
        <Col xs={3} className='flex'>
          <TextUnderline readOnly label="Nơi cấp" value={dataEmployee?.noiCapCMNDOrCCCD} />
        </Col>
      </Row>
      <TextUnderline readOnly label="26) Số BHXH" value={dataEmployee?.soBhxh} />
      <div>
        <p className='text-uppercase spaces mt-6'>27) ĐÀO TẠO,  BỒI DƯỠNG VỀ CHUYÊN MÔN NGHIỆP VỤ, LÝ LUẬN CHÍNH TRỊ,  NGOẠI NGỮ, TIN HỌC</p>
        <TableCustom
          id=""
          data={checkEmptyArray(data27)}
          columns={columnsLevel()}
          buttonAdd={false}
          buttonExportExcel={false}
          notDelete={false}
          justFilter={true}
          noPagination={true}
        />
        <p><i>
          Ghi chú:
          - Hình thức đào tạo: Chính quy, tại chức, chuyên tu, từ xa, liên thông, bồi dưỡng.
          - Văn bẳng, chứng chỉ: TSKH, TS, chuyên khoa II, Ths, chuyên khoa I, đại học, cao đẳng, trung cấp, sơ cấp, chứng chỉ,...
        </i></p>
      </div>

      <div>
        <p>28) TÓM TẮT QUÁ TRÌNH CÔNG TÁC</p>
        <TableCustom
          id=""
          data={checkEmptyArray(dataEmployee?.quaTrinhCongTac)}
          columns={columnsWorkingProcess()}
          buttonAdd={false}
          buttonExportExcel={false}
          notDelete={false}
          justFilter={true}
          noPagination={true}
        />
      </div>

      <div>
        <p className='spaces mt-6'>29) ĐẶC ĐIỂM LỊCH SỬ BẢN THÂN</p>
        <TextUnderline
          rows={2}
          readOnly
          label="- Khai rõ: bị bắt, bị tù (từ ngày tháng năm nào đến ngày tháng năm nào, ở đâu?), đã khai báo cho ai, những vấn đề gì? Bản thân có làm việc trong chế độ cũ (cơ quan, đơn vị nào, địa điểm, chức danh, chức vụ, thời gian làm việc…)"
          value={""}
        />
        <TextUnderline
          rows={2}
          readOnly
          label="- Tham gia hoặc có quan hệ với các tổ chức chính trị, kinh tế, xã hội nào ở nước ngoài (làm gì, tổ chức nào, đặt trụ sở ở đâu ..?)"
          value={""}
        />
        <TextUnderline
          rows={2}
          readOnly
          label="- Có thân nhân (Cha, Mẹ, Vợ, Chồng, con, anh chị em ruột) ở nước ngoài (làm gì, địa chỉ …)?"
          value={""}
        />
      </div>

      <div>
        <p className='spaces mt-6'>30) QUAN HỆ GIA ĐÌNH</p>
        <div>
          <p className='spaces pl-16'>Về bản thân: Cha, Mẹ, Vợ (hoặc chồng), các con, anh chị em ruột</p>
          <TableCustom
            id=""
            data={checkEmptyArray(dataEmployee?.quanHeGiaDinhBt)}
            columns={columnsFamilyRelationships}
            buttonAdd={false}
            buttonExportExcel={false}
            notDelete={false}
            justFilter={true}
            noPagination={true}
          />
        </div>
        <div>
          <p className='spaces pl-16'>Về bên vợ (hoặc chồng): Cha, Mẹ, anh chị em ruột:</p>
          <TableCustom
            id=""
            data={checkEmptyArray(dataEmployee?.quanHeGiaDinhVc)}
            columns={columnsFamilyRelationships}
            buttonAdd={false}
            buttonExportExcel={false}
            notDelete={false}
            justFilter={true}
            noPagination={true}
          />
        </div>
      </div>

      <div>
        <p className='spaces mt-6'>31) DIỄN BIẾN QUÁ TRÌNH LƯƠNG CỦA VIÊN CHỨC</p>
        <p className='spaces pl-16'>-Quá trình lương của bản thân: </p>
        <TableCustom
          id=""
          data={checkEmptyArray(dataEmployee?.lichSuLuong)}
          columns={columnsSalaryHistory}
          buttonAdd={false}
          buttonExportExcel={false}
          notDelete={false}
          justFilter={true}
          noPagination={true}
        />
      </div>
      <div>
        <p className='spaces mt-6'>32) NHẬN XÉT, ĐÁNH GIÁ CỦA ĐƠN VỊ SỬ DỤNG VIÊN CHỨC</p>
        <TextUnderline rows={5} readOnly />
      </div>
      <div className="flex text-center gap-10 fs-sm spaces mt-40">
        <div className="flex-1">
          <p className="spaces mb-0"><b>Người Khai</b></p>
          <p className="spaces mb-0">Tôi xin cam đoan những lời <br />khai trên đây là đúng sự thật</p>
          <p className="spaces mb-0"><i>(Ký tên, ghi rõ họ tên)</i></p>
        </div>
        <div className="flex-1">
          <div>
            <p className="mb-0 flex flex-center flex-align-items-end gap-0">
              <TextValue label="" className="spaces w-80" />, ngày
              <TextValue label="" className="spaces w-48" /> tháng
              <TextValue label="" className="spaces w-48" /> năm
              <TextValue label="" className="spaces w-48" />
            </p>
            <p className="mb-0">
              <b>Thủ trưởng đơn vị sử dụng viên chức</b>
            </p>
            <p className="text-center spaces mb-100"><i>(Ký tên, đóng dấu)</i></p>
          </div>
        </div>
      </div>
    </div >
  )
}

export default VCBNV