import TextValue from "../../../component/TextValue"

export const columnFamilyRelationships = () => {
  return (
    [
      {
        name: "STT",
        field: "",
        render: (row: any, index: number, stt: number) => <span>{stt}</span>
      },
      {
        name: "Quan hệ",
        field: "qhgdQuanHe",
        headerStyle: {
          minWidth: "100px",
          textAlign: "center",
        },
        cellStyle: {
          textAlign: "left",
        },
        render: (row: any) => row?.qhgdQuanHe ? <span>{row?.qhgdQuanHe}</span> : <TextValue className="spaces h-25" value="" />
      },
      {
        name: "Họ và tên",
        field: "qhgdTen",
        headerStyle: {
          minWidth: "200px",
          textAlign: "center",
        },
        cellStyle: {
          textAlign: "left",
        },
        render: (row: any) => row?.qhgdTen ? <span>{row?.qhgdTen}</span> : <TextValue className="spaces h-25" value="" />
      },
      {
        name: "Năm sinh",
        field: "qhgdNamSinh",
        headerStyle: {
          minWidth: "100px",
          textAlign: "center",
        },
        cellStyle: {
          textAlign: "center",
        },
        render: (row: any) => row?.qhgdNamSinh ? <span>{row?.qhgdNamSinh}</span> : <TextValue className="spaces h-25" value="" />
      },
      {
        name: "Quê quán, nghề nghiệp, chức danh, chức vụ, đơn vị, công tác, học tập, nơi ở (trong, ngoài nước); thành viên các tổ chức chính trị-xã hội ...",
        field: "qhgdDetails",
        headerStyle: {
          minWidth: "500px",
          textAlign: "center",
        },
        cellStyle: {
          textAlign: "left",
        },
        render: (row: any) => row?.qhgdDetails ? <span>{row?.qhgdDetails}</span> : <TextValue className="spaces h-25" value="" />
      },
    ]
  )
}