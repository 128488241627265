import { FC, useContext, useEffect, useState } from "react";
import { IuserByUnit } from "../../model/ManagerModel";
import { toast } from "react-toastify";
import { getListUser, getUserById } from "../../services/ManagerServices";
import { UserDialog } from "./UserDialog";
import { deleteUser } from "./../../services/ManagerServices";
import useMultiLanguage from "../../../../hook/useMultiLanguage";
import TableCustom, { columnNamesType } from "../../../component/table-custom/TableCustom";
import { RESPONSE_STATUS_CODE, TYPE } from "../../../utils/Constant";
import { IObject } from "../../../models/models";
import { INIT_SEARCH_OBJECT } from "../../../constant";
import AppContext from "../../../../AppContext";

const UserList: FC = () => {
  const { lang } = useMultiLanguage();
  const { setPageLoading } = useContext(AppContext);

  const [userList, setUserList] = useState<IuserByUnit[]>([]);
  const [userInfo, setUserInfo] = useState<IuserByUnit>({} as IuserByUnit);
  const [totalPage, setTotalPage] = useState<number>(0);
  const [totalElements, setTotalElements] = useState<number>(0);
  const [numberOfElements, setNumberOfElements] = useState<number>(0);
  const [openUserDialog, setOpenUserDialog] = useState<boolean>(false);
  const [searchObject, setSearchObject] = useState<IObject>(INIT_SEARCH_OBJECT);

  useEffect(() => {
    loadListUser(searchObject)
  }, [searchObject?.pageIndex, searchObject?.pageSize])

  const handleOpenUserDialog = async (row: any) => {
    const { data } = await getUserById(row?.id);
    const result = data?.data;
    result.user.roles = [{ name: result?.user?.roles[0] }];
    setUserInfo(result);
    setOpenUserDialog(true);
  };

  const handleCloseUserDialog = () => {
    setOpenUserDialog(false);
    setUserInfo({} as IuserByUnit);
  };

  const loadListUser = async (searchObject?: any) => {
    try {
      setPageLoading(true);
      const { data } = await getListUser({ ...searchObject });
      setUserList(data?.data?.content);
      setTotalPage(data?.data?.totalPages || 0);
      setTotalElements(data?.data?.totalElements || 0);
      setNumberOfElements(data?.data?.numberOfElements || 0);
    } catch (err) {
      toast.error(lang("GENERAL.ERROR"));
    } finally {
      setPageLoading(false);
    }
  };

  const handleDeleteUser = async (ids: string[]) => {
    try {
      const res = await deleteUser(ids);
      if (res?.data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
        toast.success(lang("GENERAL.DELETE_SUCCESS"));
        return true;
      } else toast.error(`${res?.data?.message}`);
    } catch (error) {
      toast.error(lang("GENERAL.ERROR"));
    }
  }

  const columns: columnNamesType[] = [
    {
      name: lang("TABLE.INDEX"),
      field: "stt",
      render: (row, index, stt) => <span>{stt}</span>
    },
    {
      name: lang("UNIT"),
      field: "organization?.name",
      headerStyle: {
        minWidth: "150px"
      },
      render: (row) => <span>{row?.organization?.name}</span>
    },
    {
      name: lang("USER.NAME"),
      field: "username",
      headerStyle: {
        minWidth: "150px"
      },
      render: (row) => <span>{row?.username}</span>
    },
    {
      name: lang("USER.ROLE"),
      field: "roles",
      headerStyle: {
        minWidth: "150px"
      },
      render: (row) => <span>{row?.roles?.toString()}</span>
    },
    {
      name: lang("USER.EMAIL"),
      field: "roles",
      headerStyle: {
        minWidth: "150px"
      },
      render: (row) => <span>{row?.email}</span>
    },
    {
      name: lang("USER.LAST_LOGIN"),
      field: "lastLoginTime",
      headerStyle: {
        minWidth: "150px"
      },
      render: (row) => <span>{row?.lastLoginTime}</span>
    }
  ];

  return (
    <div>
      <TableCustom
        title={lang("USER.LIST")}
        data={userList}
        columns={columns}
        handleDelete={(ids: string[]) => handleDeleteUser(ids)}
        handleDoubleClick={handleOpenUserDialog}
        searchObject={searchObject}
        setSearchObject={setSearchObject}
        isActionTableTab
        noToolbar={true}
        buttonAdd={true}
        handleOpenDialog={() => {
          setOpenUserDialog(true);
        }}
        justFilter={false}
        notDelete={true}
        totalPages={totalPage}
        totalElements={totalElements}
        numberOfElements={numberOfElements}
        unSelectedAll={true}
        type={TYPE.MULTILINE}
      />
      {openUserDialog && (
        <UserDialog
          handleCloseDialog={handleCloseUserDialog}
          userInfo={userInfo}
          handleReload={loadListUser}
        />
      )}
    </div>
  );
};
export { UserList };
