import { FC, useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import { IAuth, IRole } from "../model/ManagerModel";
import { deleteRole, getListAuth, getListRole } from "../services/ManagerServices";
import { toast } from "react-toastify";
import { AuthDialog } from "./Dialog/AuthDialog";
import { deleteAuth } from "./../services/ManagerServices";
import { RoleDialog } from "./Dialog/RoleDialog";
import { hasAuthority } from "../../utils/FunctionUtils";
import { PERMISSIONS, PERMISSION_ABILITY } from "../../../Constant";
import TableCustom from "../../component/table-custom/TableCustom";
import { RESPONSE_STATUS_CODE, SEARCH_OBJECT_MAX_SIZE, TYPE } from "../../utils/Constant";
import useMultiLanguage from "../../../hook/useMultiLanguage";

const RoleAuth: FC = () => {
  const { lang } = useMultiLanguage();

  const [authList, setAuthList] = useState<IAuth[]>([]);
  const [authInfo, setAuthInfo] = useState<IAuth>({} as IAuth);
  const [roleInfo, setRoleInfo] = useState<IRole>({} as IRole);
  const [openAuthDialog, setOpenAuthDialog] = useState<boolean>(false);
  const [openRoleDialog, setOpenRoleDialog] = useState<boolean>(false);
  const [roleList, setRoleList] = useState<IRole[]>([]);
  const [totalPage2, setTotalPage2] = useState<number>(0);
  const [totalElements2, setTotalElements2] = useState<number>(0);
  const [numberOfElements2, setNumberOfElements2] = useState<number>(0);
  const [totalPage, setTotalPage] = useState<number>(0);
  const [totalElements, setTotalElements] = useState<number>(0);
  const [numberOfElements, setNumberOfElements] = useState<number>(0);

  useEffect(() => {
    loadListAuth();
    loadListRole();
  }, [])

  const handleOpenAuthDialog = (data: IAuth) => {
    setOpenAuthDialog(true);
    setAuthInfo(data);
  };
  const handleCloseAuthDialog = () => {
    setOpenAuthDialog(false);
    setAuthInfo({} as IAuth);
  };
  const handleOpenRoleDialog = (data: IRole) => {
    setOpenRoleDialog(true);
    setRoleInfo(data);
  };
  const handleCloseRoleDialog = () => {
    setOpenRoleDialog(false);
    setRoleInfo({} as IRole);
  };
  const loadListAuth = async (searchObject?: any) => {
    try {
      const { data } = await getListAuth(SEARCH_OBJECT_MAX_SIZE);
      setAuthList(data?.data?.content);
      setTotalPage2(data?.data?.totalPages);
      setTotalElements2(data?.data?.totalElements);
      setNumberOfElements2(data?.data?.numberOfElements);
    } catch (err) {
      toast.error(lang("GENERAL.ERROR"));
    }
  };
  const loadListRole = async (searchObject?: any) => {
    try {
      const { data } = await getListRole({ ...searchObject });
      setRoleList(data?.data?.content);
      setTotalPage(data?.data?.totalPages);
      setTotalElements(data?.data?.totalElements);
      setNumberOfElements(data?.data?.numberOfElements);
    } catch (err) {
      toast.error(lang("GENERAL.ERROR"));
    }
  };

  const handleDeleteRole = async (ids: string[]) => {
    try {
      const res = await deleteRole(ids);
      if (res?.data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
        toast.success(lang("GENERAL.DELETE_SUCCESS"));
        return true;
      } else toast.error(`${res?.data?.message}`);
    } catch (error) {
      toast.error(lang("GENERAL.ERROR"));
    }
  }

  const authColumn = [
    {
      name: "STT",
      field: "",
      render: (row: any, index: number, stt: number) => <span>{stt}</span>
    },
    {
      name: lang("AUTH.NAME"),
      field: "name",
      cellStyle: {
        textAlign: "left"
      }
    }
  ];

  const roleColumn = [
    {
      name: "STT",
      field: "",
      render: (row: any, index: number, stt: number) => <span>{stt}</span>
    },
    {
      name: lang("ROLE.NAME"),
      field: "name",
      cellStyle: {
        textAlign: "left",
        minWidth: "170px"
      }
    },
    {
      name: lang("AUTH"),
      field: "authorities",
      cellStyle: {
        textAlign: "left",
        minWidth: "400px"
      },
      render: (row: any, index: number, stt: number) =>
        row.authorities?.length > 0 &&
        row.authorities.map((item: any, index: number) => (
          <span key={index}>
            <span>{item}</span>
            <span className="color-red spaces fw-600">; </span>
          </span>
        ))
    }
  ];
  return (
    <>
      <Row className="role-auth">
        <Col xs={8}>
          <div className="table-custom-role">
            <TableCustom
              title={lang("ROLE.LIST")}
              isActionTableTab
              id="roleList"
              data={roleList}
              columns={roleColumn}
              buttonAdd={hasAuthority(PERMISSIONS.ROLE, PERMISSION_ABILITY.CREATE)}
              notEdit={!hasAuthority(PERMISSIONS.ROLE, PERMISSION_ABILITY.UPDATE)}
              notDelete={!hasAuthority(PERMISSIONS.ROLE, PERMISSION_ABILITY.DELETE)}
              type={TYPE.MULTILINE}
              totalPages={totalPage}
              totalElements={totalElements}
              numberOfElements={numberOfElements}
              handleOpenDialog={() => {
                // setOpenRoleDialog(true)
              }}
              handleDoubleClick={handleOpenRoleDialog}
              handleDelete={(ids: any) => handleDeleteRole(ids)}
            />
          </div>
        </Col>
        <Col xs={4}>
          <div className="table-custom-auth">
            <TableCustom
              title={lang("AUTH.LIST")}
              isActionTableTab
              id="authList"
              data={authList}
              columns={authColumn}
              buttonAdd={hasAuthority(PERMISSIONS.AUTHORITY, PERMISSION_ABILITY.DELETE)}
              notEdit={hasAuthority(PERMISSIONS.AUTHORITY, PERMISSION_ABILITY.UPDATE)}
              notDelete={!hasAuthority(PERMISSIONS.AUTHORITY, PERMISSION_ABILITY.DELETE)}
              type={TYPE.MULTILINE}
              totalPages={totalPage2}
              totalElements={totalElements2}
              numberOfElements={numberOfElements2}
              handleOpenDialog={() => {
                // setOpenAuthDialog(true)
              }}
              handleDoubleClick={handleOpenAuthDialog}
              handleDelete={(ids: any) => deleteAuth(ids)}
              noPagination={true}
            />
          </div>
        </Col>
      </Row>
      {openAuthDialog && (
        <AuthDialog
          handleCloseDialog={handleCloseAuthDialog}
          authInfo={authInfo}
          handleReload={loadListAuth}
        />
      )}
      {openRoleDialog && (
        <RoleDialog
          handleCloseDialog={handleCloseRoleDialog}
          roleInfo={roleInfo}
          handleReload={loadListRole}
        />
      )}
    </>
  );
};
export { RoleAuth };
