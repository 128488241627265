import axios, { AxiosResponse } from "axios";
import { APIResponse } from "../../models/models";
import { BonusInfo } from "../model/BonusModel";
import { paramsConfig } from "../../utils/ParamsUtils";
const API_URL = process.env.REACT_APP_API_URL;

export const getBonus = (searchData?: any): Promise<AxiosResponse<APIResponse>> => {
  let url = `${API_URL}/khen-thuong/page`;
  return axios.get(url, paramsConfig(searchData));
};

export const updateBonus = (id: string, data: BonusInfo): Promise<AxiosResponse<APIResponse>> => {
  let url = `${API_URL}/khen-thuong/${id}`;
  return axios.put(url, data);
};

export const addNewBonus = (data: BonusInfo): Promise<AxiosResponse<APIResponse>> => {
  let url = `${API_URL}/khen-thuong`;
  return axios.post(url, data);
};

export const deleteBonus = (ids: string[]): Promise<AxiosResponse<APIResponse>> => {
  let url = `${API_URL}/khen-thuong/ids?ids=${ids.toString()}`;
  return axios.delete(url);
};

export const getKhenThuongList = (idKhenThuong: string) => {
  let url = `${API_URL}/khen-thuong-list/employee-khen-thuong/${idKhenThuong}`;
  return axios.get(url);
};

