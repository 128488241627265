/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, useContext, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { KTSVG } from "../../../_metronic/helpers";
import AppContext from "../../AppContext";
import { EXPORT_FILE_NAME, ROLE } from "../../Constant";
import useMultiLanguage from "../../hook/useMultiLanguage";
import SelectTree from "../component/SelectTree";
import InputSearch from "../component/input-field/InputSearch";
import TableCustom from "../component/table-custom/TableCustom";
import { INIT_SEARCH_OBJECT, TYPE_CATEGORY } from "../constant";
import "../styles/index.scss";
import { RESPONSE_STATUS_CODE, SEARCH_OBJECT_MAX_SIZE, TYPE } from "../utils/Constant";
import { checkStatus, convertSearch, formatDateTable, hasRole } from "../utils/FunctionUtils";
import { ProfileAddAndUpdateV2 } from "./ProfileAddAndUpdateV2";
import AdvancedSearch from "./component/Dialog/AdvancedSearch";
import { LIST_STATUS_NV } from "./const/DialogChildConstants";
import { FIELD_SEARCH, INIT_INPUT_SEARCH } from "./const/ProfileConst";
import { EmployeeProfile, IItemSearch, ProfileInfo } from "./models/ProfileModels";
import "./profile.scss";
import { exportExcelProfile, getAllDonVi, getAvailableFieldsExport, searchAllEmployee } from "./services/ProfileServices";
import ProfileProvider from "./ProfileContext";
import { ExportExcelDialog } from "../component/export-excel/ExportExcelDialog";
import { MAX_EXPORT_FIELD_LENGTH, defaultCodeExportField } from "./const/DialogConstant";
import { useLocation, useNavigate } from "react-router-dom";
import { getPhongBanByDonVi } from "./utils/FunctionUtils";

export const ProfileContext = React.createContext(() => { });
const Profile: FC = () => {
  const [shouldOpenDialog, setShouldOpenDialog] = useState<boolean>(false);
  const [shouldOpenExportDialog, setShouldOpenExportDialog] = useState<boolean>(false);
  const [isAdvancedSearch, setIsAdvancedSearch] = useState<boolean>(false);
  const [profiles, setProfiles] = useState<ProfileInfo[]>([]);
  const [isViewProfiles, setIsViewProfiles] = useState<boolean>(false);
  const [idEmployee, setIdEmployee] = useState<string>("");
  const [totalPage, setTotalPage] = useState<number>(0);
  const [totalElements, setTotalElements] = useState<number>(0);
  const [numberOfElements, setNumberOfElements] = useState<number>(0);
  const [listInputSearch, setListInputSearch] = useState<any[]>([INIT_INPUT_SEARCH])
  const [searchObject, setSearchObject] = useState<any>(INIT_SEARCH_OBJECT)
  const [listTreeSelect, setListTreeSelect] = useState<any>([])
  const [dataChecked, setDataChecked] = useState<EmployeeProfile[]>([]);
  const [codeCollapses, setCodeCollapses] = useState<string | null>(null);
  const [idSelected, setIdSelected] = useState<string>("");
  const [idsSelected, setIdsSelected] = useState<string[]>([]);
  const { lang } = useMultiLanguage();
  const { setPageLoading } = useContext(AppContext);

  const dataLocation: any = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if(searchObject?.pageIndex && searchObject?.pageSize){
      handleSearch();
    }
  }, [searchObject?.pageIndex, searchObject?.pageSize])

  useEffect(() => {
    if (dataLocation?.state?.reset) {
      handleCloseDialog()
    }
  }, [dataLocation?.state?.reset])

  const showNameTitle = (row: any = null) => {
    if (row?.id) {
      document.title = `${row.maNhanVien ? row.maNhanVien + "-  " : ""} ${row.name}`;
    } else {
      document.title = `${lang('GENERAL.APP.NAME')} | ${lang('GENERAL.PROFILE')}`;
    }
  }

  useEffect(() => {
    if (!dataChecked?.length) return;
    let ids = dataChecked.map((item: EmployeeProfile) => item?.id || "");
    setIdsSelected(ids);
  }, [dataChecked])

  useEffect(() => {
    showNameTitle()
  }, [])

  const handleOpenDialog = (row?: any): void => {
    showNameTitle(row)
    setIsViewProfiles(row?.id ? true : false);
    setIdEmployee(row?.id ? row?.id : "");
    setShouldOpenDialog(true);
    navigate(dataLocation?.pathname, { state: { reset: false } });
  };

  const handleOpenAddNewDialog = () => {
    setIdEmployee("");
    setIsViewProfiles(false);
    setShouldOpenDialog(true);
    navigate(dataLocation?.pathname, { state: { reset: false } });
  };

  const handleCloseDialog = (): void => {
    setShouldOpenDialog(false);
    showNameTitle();
    navigate(dataLocation?.pathname, { state: { reset: false } });
  };

  const handleToggleIsView = () => {
    setIsViewProfiles(!isViewProfiles);
  };

  const updatePageData = async (searchObject: any) => {
    setSearchObject({ ...searchObject })
    setPageLoading(true);
    let { data } = await searchAllEmployee(searchObject);
    setProfiles(data?.data?.content || []);
    setTotalPage(data?.data?.totalPages);
    setTotalElements(data?.data?.totalElements);
    setNumberOfElements(data?.data?.numberOfElements);

    if (data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
      setIsAdvancedSearch(false)
    }
    setPageLoading(false);
  };

  const getDonVi = async () => {
    let treeSelect: any = []
    try {
      let dataDonVi = (await getAllDonVi(SEARCH_OBJECT_MAX_SIZE))?.data?.data?.content
  
      dataDonVi?.forEach((donVi: any) => {
        let itemTreeSelect: any = {
          id: donVi?.id,
          code: donVi?.code,
          name: donVi?.name,
          type: TYPE_CATEGORY.donVi
        }
        treeSelect.push(itemTreeSelect)
      });
      setListTreeSelect(treeSelect);
    } catch (error) {
    }
    return treeSelect;
  };

  useEffect(() => {
    const handleWindowScroll = () => {
      let mucLucWidth = document.getElementById("profile__table-of-content")?.clientWidth;
      let mucLuc = document.getElementById('table-of-profiles');
      if (window.scrollY >= 80 && mucLuc) {
        mucLuc?.classList?.add('fixed');
        mucLuc.style.width = `${mucLucWidth}px`;
      } else if (window.scrollY < 80) {
        mucLuc?.classList?.remove('fixed');
      }
    };
    window.addEventListener('scroll', handleWindowScroll);
    return () => window.removeEventListener("scroll", handleWindowScroll);
  }, []);

  const handleSearch = async (data: any = {}) => {
    let donViCongTacId = searchObject?.donViCongTacId || "";
    if(listTreeSelect.length === 0) {
      const treeSelect = await getDonVi();
      if(treeSelect.length > 0) {
        donViCongTacId = treeSelect[0]?.id;
        setIdSelected(donViCongTacId);
      }
    }
    const dataSearch: any = {
      ...searchObject,
      donViCongTacId,
      ...data
    }

    dataSearch.keyword = dataSearch?.keyword?.trim() || ""
    updatePageData(dataSearch)
  }

  const handleAdvancedSearch = (data: IItemSearch[]) => {
    setListInputSearch(data)
    handleSearch({ ...convertSearch(data), pageIndex: 1 });
  }

  const handleOpenAdvancedSearch = () => {
    setIsAdvancedSearch(true)
  }

  const handelTreeSelect = async (item: any) => {
    if (item?.type === TYPE_CATEGORY.donVi) {
      try {
        if (codeCollapses !== item.code) {
          setPageLoading(true);
          const treeSelect = await getPhongBanByDonVi(listTreeSelect, item);
          setListTreeSelect(treeSelect);
          handleSearch({
            pageIndex: 1,
            phongBanIds: null,
            donViCongTacId: item?.id
          })
        }
      } catch (error) {
      } finally {
        setPageLoading(false);
      }
      return;
    }

    if (item?.type === TYPE_CATEGORY.phongBan) {
      handleSearch({
        pageIndex: 1,
        donViCongTacId: null,
        phongBanIds: item?.id
      })
      return;
    }

    handleSearch({
      donViCongTacId: null,
      phongBanIds: null,
    })
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchObject({
      ...searchObject,
      keyword: e.target?.value
    })
  }

  const handleOpenExportProfile = () => {
    setShouldOpenExportDialog(true);
  }

  const columns = [
    {
      name: "STT",
      field: "",
      render: (row: any, index: number, stt: number) => <span>{stt}</span>
    },
    {
      name: "Trạng thái",
      field: "trangThaiLaoDong",
      headerStyle: {
        minWidth: "130px",
        textAlign: "center",
      },
      cellStyle: {
        textAlign: "center",
      },
      render: (row: any, index: number) => (
        <span className={`${checkStatus(LIST_STATUS_NV, Number(row?.trangThaiLaoDong?.code))} status`} >
          {row?.trangThaiLaoDong?.name}
        </span>
      )
    },
    {
      name: "Mã nhân viên",
      field: "maNhanVien",
      headerStyle: {
        minWidth: "120px"
      },
      render: (row: any) => <span className="color-steel-blue fw-600">{row?.maNhanVien}</span>
    },
    {
      name: "Họ và tên",
      field: "name",
      headerStyle: {
        minWidth: "200px"
      },
      cellStyle: {
        minWidth: "200px",
        textAlign: "left",
      },
    },
    {
      name: "Giới tính",
      field: "gender",
      headerStyle: {
        minWidth: "80px"
      },
      render: (row: any) => <span>{row?.gender?.name}</span>
    },
    {
      name: "Ngày sinh",
      field: "birthDate",
      headerStyle: {
        minWidth: "100px"
      },
      render: (row: any) => <span>{formatDateTable(row?.birthDate)}</span>
    },
    {
      name: "Số ĐTDĐ",
      field: "phone",
      headerStyle: {
        minWidth: "100px"
      },
      cellStyle: {
        minWidth: "100px",
        textAlign: "left",
      },
    },
    {
      name: "CCCD",
      field: "soCMNDOrCCCD",
      headerStyle: {
        minWidth: "100px"
      },
      cellStyle: {
        minWidth: "100px",
        textAlign: "left",
      },
    },
    {
      name: "Phòng/Ban",
      field: "phongBanText",
      headerStyle: {
        minWidth: "200px"
      },
      cellStyle: {
        minWidth: "200px",
        textAlign: "left",
      },
    },
    {
      name: "Chức vụ",
      field: "chucVuText",
      headerStyle: {
        minWidth: "150px"
      },
      cellStyle: {
        minWidth: "150px",
        textAlign: "left",
      },
    },
    {
      name: "Vị trí công việc",
      field: "viTriCongViecText",
      headerStyle: {
        minWidth: "250px"
      },
      cellStyle: {
        minWidth: "250px",
        textAlign: "left",
      },
    },
  ]

  return (
    <ProfileProvider>
      <div className="profile container-page">
        {!shouldOpenDialog && !hasRole(ROLE.USER) && (
          <>
            <Row className="spaces mb-12 flex-middle">
              <Col xs={5} xl={6} xxl={7} className="spaces p-0">
                <h2 className="breadcrumb-title">
                  danh sách hồ sơ nhân viên
                </h2>
              </Col>
              <Col xs={7} xl={6} xxl={5} className="flex">
                <div className="spaces mr-10 flex-1">
                  <InputSearch
                    className="border-3px"
                    value={searchObject?.keyword || ""}
                    placeholder="Tìm kiếm theo mã NV, họ và tên, SĐT và CCCD"
                    isEnter={true}
                    handleSearch={() => handleSearch({ pageIndex: 1 })}
                    handleChange={handleChange}
                  />
                </div>
                <button
                  className="spaces button-primary mr-10"
                  onClick={() => handleSearch({ pageIndex: 1 })}
                >
                  Tìm kiếm
                </button>
                <button
                  className="spaces button-primary-outline mr-10"
                  onClick={handleOpenAdvancedSearch}
                >
                  Tìm nâng cao
                </button>

                {isAdvancedSearch && (
                  <AdvancedSearch
                    open={isAdvancedSearch}
                    listInputSearch={listInputSearch}
                    handleSearch={handleAdvancedSearch}
                    listOption={FIELD_SEARCH}
                    handeleClose={() => setIsAdvancedSearch(false)}
                  />
                )}
              </Col>
            </Row>
            <Row className="bg-white container-profile">
              <Col xs={2} className="box-shadow-menu spaces p-0">
                <div className="spaces p-10">
                  <div className="spaces py-10 flex ">
                    <KTSVG
                      path={"/media/svg/icons/sidebar-reverse.svg"}
                      className="svg-icon-sidebar"
                    />
                    <h2 className="spaces fw-600 pl-8 m-0 ">
                      Đơn vị/Phòng ban
                    </h2>
                  </div>
                  <InputSearch
                    className="spaces h-32.truncate-text p-8"
                    value={""}
                    placeholder="Đơn vị/phòng ban"
                    handleSearch={() => { }}
                    handleChange={() => { }}
                  />
                </div>
                <div className="spaces pl-4 select-tree">
                  <SelectTree
                    codeCollapses={codeCollapses}
                    handleChangeCollapsesCode={setCodeCollapses}
                    idSelected={idSelected}
                    handleChangeSelectId={setIdSelected}
                    // selectTree={DON_VI_PHONG_BAN}
                    selectTree={listTreeSelect}
                    handleRow={handelTreeSelect}
                    isTotal={true}
                  />
                </div>
              </Col>
              <Col xs={10} className="spaces pl-0 table-custom">
                <TableCustom
                  id="profile"
                  data={profiles}
                  columns={columns}
                  searchObject={searchObject}
                  setSearchObject={setSearchObject}
                  buttonAdd={true}
                  buttonExportExcel={true}
                  notDelete={true}
                  handleExportExcel={handleOpenExportProfile}
                  type={TYPE.MULTILINE}
                  fixedColumnsCount={4}
                  totalPages={totalPage}
                  totalElements={totalElements}
                  numberOfElements={numberOfElements}
                  handleOpenDialog={handleOpenAddNewDialog}
                  handleDoubleClick={handleOpenDialog}
                  setDataChecked={setDataChecked}
                  dataChecked={dataChecked}
                />
              </Col>
            </Row>
          </>
        )}
        {(shouldOpenDialog || hasRole(ROLE.USER)) && (
          <ProfileAddAndUpdateV2
            handleCloseDialog={handleCloseDialog}
            idEmployee={idEmployee}
            setIdEmployee={setIdEmployee}
            isView={isViewProfiles}
            handleToggleIsView={handleToggleIsView}
          />
        )}
        {shouldOpenExportDialog && (
          <ExportExcelDialog
            handleClose={() => setShouldOpenExportDialog(false)}
            ids={idsSelected}
            exportAPI={exportExcelProfile}
            getFieldsAPI={getAvailableFieldsExport}
            defaultCodeExportField={defaultCodeExportField}
            maxExportFields={MAX_EXPORT_FIELD_LENGTH}
            fileName={EXPORT_FILE_NAME.HO_SO}
            showSearchFields
          />
        )}
      </div>
    </ProfileProvider>
  );
};
export { Profile };