import { IColumns } from "../../component/TableGrouping/TableGrouping";
import { TYPE } from "../../utils/Constant";
import { convertNumberPrice, formatDateTable } from "../../utils/FunctionUtils";

export const columnStatistical: IColumns[] = [
  {
    name: "STT",
    field: "",
    render: (row, index, stt) => <span>{stt}</span>,
    cellStyle: {
      fontSize: "16px"
    },
    isSticky: true
  },
  {
    name: "Đơn vị",
    field: "donVi",
    headerStyle: {
      minWidth: "300px"
    },
    cellStyle: {
      fontSize: "16px"
    }
  },
  {
    name: "Tổng số nhân lực trong biên chế được giao 2022 (theo TB của SNV)",
    field: "tongSoNhanLucDuocGiao",
    headerStyle: {
      minWidth: "200px"
    },
    cellStyle: {
      fontSize: "16px"
    }
  },
  {
    name: "Tổng số nhân lực trong biên chế hiện có",
    field: "tongSoNhanLucHienCo",
    headerStyle: {
      minWidth: "200px"
    },
    cellStyle: {
      fontSize: "16px"
    }
  },
  {
    name: "Cột kiểm tra độ tuổi",
    field: "kiemTraDoTuoi",
    headerStyle: {
      minWidth: "100px"
    },
    cellStyle: {
      fontSize: "16px"
    }
  },
  {
    name: "Cột kiểm tra trình độ chuyên môn",
    field: "kiemTraTrinhDoChuyenMon",
    headerStyle: {
      minWidth: "120px"
    },
    cellStyle: {
      fontSize: "16px"
    }
  },
  {
    name: "Trong đó",
    field: "",
    child: [
      {
        name: "Công chức, viên chức",
        field: "congChucVienChuc",
        headerStyle: {
          minWidth: "120px"
        },
        cellStyle: {
          fontSize: "16px"
        }
      },
      {
        name: "Hợp đồng 111",
        field: "hopDong111",
        headerStyle: {
          minWidth: "120px"
        },
        cellStyle: {
          fontSize: "16px"
        }
      },
      {
        name: "Nữ",
        field: "gioiTinhNu",
        headerStyle: {
          minWidth: "120px"
        },
        cellStyle: {
          fontSize: "16px"
        }
      },
      {
        name: "Dân  tộc thiểu  số",
        field: "danTocThieuSo",
        headerStyle: {
          minWidth: "120px"
        },
        cellStyle: {
          fontSize: "16px"
        }
      },
      {
        name: "Đảng viên",
        field: "dangVien",
        headerStyle: {
          minWidth: "120px"
        },
        cellStyle: {
          fontSize: "16px"
        }
      },
      {
        name: "Có tôn giáo",
        field: "coTonGiao",
        headerStyle: {
          minWidth: "120px"
        },
        cellStyle: {
          fontSize: "16px"
        }
      },
      {
        name: "Tuổi <30",
        field: "doTuoiDuoi30",
        headerStyle: {
          minWidth: "120px"
        },
        cellStyle: {
          fontSize: "16px"
        }
      },
      {
        name: "Tuổi 30 - 50",
        field: "doTuoi3150",
        headerStyle: {
          minWidth: "120px"
        },
        cellStyle: {
          fontSize: "16px"
        }
      },
      {
        name: "Tuổi ≥ 51 ",
        field: "doTuoiTren50",
        headerStyle: {
          minWidth: "120px"
        },
        cellStyle: {
          fontSize: "16px"
        }
      },
      {
        name: "Thầy thuốc nhân dân",
        field: "thayThuocNhanDan",
        headerStyle: {
          minWidth: "120px"
        },
        cellStyle: {
          fontSize: "16px"
        }
      },
      {
        name: "Thầy thuốc ưu tú",
        field: "thayThuocUuTu",
        headerStyle: {
          minWidth: "120px"
        },
        cellStyle: {
          fontSize: "16px"
        }
      },
      {
        name: "Giáo sư",
        field: "giaoSu",
        headerStyle: {
          minWidth: "120px"
        },
        cellStyle: {
          fontSize: "16px"
        }
      },
      {
        name: "Phó giáo sư",
        field: "phoGiaoSu",
        headerStyle: {
          minWidth: "120px"
        },
        cellStyle: {
          fontSize: "16px"
        }
      },
      {
        name: "Thương binh",
        field: "thuongBinh",
        headerStyle: {
          minWidth: "120px"
        },
        cellStyle: {
          fontSize: "16px"
        }
      },
      {
        name: "Con liệt sỹ",
        field: "conLietSy",
        headerStyle: {
          minWidth: "120px"
        },
        cellStyle: {
          fontSize: "16px"
        }
      },
      {
        name: "Con bà mẹ VNAH",
        field: "conBaMeVNAH",
        headerStyle: {
          minWidth: "120px"
        },
        cellStyle: {
          fontSize: "16px"
        }
      }
    ]
  },
  {
    name: "Tổng số Bác sỹ",
    field: "tongSoBacSi",
    headerStyle: {
      minWidth: "120px"
    },
    cellStyle: {
      fontSize: "16px"
    }
  },
  {
    name: "Trong đó",
    field: "",
    child: [
      {
        name: "Nữ",
        field: "bacSiNu",
        cellStyle: {
          fontSize: "16px"
        }
      },
      {
        name: "Tiến sỹ, CK II",
        field: "bacSiTienSi",
        headerStyle: {
          minWidth: "120px"
        },
        cellStyle: {
          fontSize: "16px"
        }
      },
      {
        name: "Thạc sỹ, CKI",
        field: "bacSiThacSi",
        headerStyle: {
          minWidth: "120px"
        },
        cellStyle: {
          fontSize: "16px"
        }
      },
      {
        name: "Đại học",
        field: "bacSiDaiHoc",
        headerStyle: {
          minWidth: "120px"
        },
        cellStyle: {
          fontSize: "16px"
        }
      }
    ]
  },
  {
    name: "Tổng số Y sỹ",
    field: "tongSoYSi",
    headerStyle: {
      minWidth: "120px"
    },
    cellStyle: {
      fontSize: "16px"
    }
  },
  {
    name: "Trong đó",
    field: "",
    child: [
      {
        name: "Nữ",
        field: "ysiNu",
        cellStyle: {
          fontSize: "16px"
        }
      },
      {
        name: "Y sỹ đa khoa",
        field: "ysiDaKhoa",
        headerStyle: {
          minWidth: "120px"
        },
        cellStyle: {
          fontSize: "16px"
        }
      },
      {
        name: "Y sỹ sản nhi",
        field: "ysiSanNhi",
        headerStyle: {
          minWidth: "120px"
        },
        cellStyle: {
          fontSize: "16px"
        }
      },
      {
        name: "Y sỹ YHCT",
        field: "ysiYhct",
        headerStyle: {
          minWidth: "120px"
        },
        cellStyle: {
          fontSize: "16px"
        }
      }
    ]
  },
  {
    name: "Tổng số Dược sỹ",
    field: "tongSoDuocSi",
    headerStyle: {
      minWidth: "120px"
    },
    cellStyle: {
      fontSize: "16px"
    }
  },
  {
    name: "Trong đó",
    field: "",
    child: [
      {
        name: "Nữ",
        field: "duocSiNu",
        headerStyle: {
          minWidth: "120px"
        },
        cellStyle: {
          fontSize: "16px"
        }
      },
      {
        name: "Tiến sỹ, CK II",
        field: "duocSiTienSi",
        headerStyle: {
          minWidth: "120px"
        },
        cellStyle: {
          fontSize: "16px"
        }
      },
      {
        name: "Thạc sỹ, CKI",
        field: "duocSiThacSi",
        headerStyle: {
          minWidth: "120px"
        },
        cellStyle: {
          fontSize: "16px"
        }
      },
      {
        name: "Đại học",
        field: "duocSiDaiHoc",
        headerStyle: {
          minWidth: "120px"
        },
        cellStyle: {
          fontSize: "16px"
        }
      },
      {
        name: "Cao đẳng",
        field: "duocSiCaoDang",
        headerStyle: {
          minWidth: "120px"
        },
        cellStyle: {
          fontSize: "16px"
        }
      },
      {
        name: "Trung cấp",
        field: "duocSiTrungCap",
        headerStyle: {
          minWidth: "120px"
        },
        cellStyle: {
          fontSize: "16px"
        }
      },
      {
        name: "Sơ cấp, Dược tá",
        field: "duocSiSoCap",
        headerStyle: {
          minWidth: "120px"
        },
        cellStyle: {
          fontSize: "16px"
        }
      }
    ]
  },
  {
    name: "Tổng số YT CC",
    field: "tongSoYtcc",
    headerStyle: {
      minWidth: "120px"
    },
    cellStyle: {
      fontSize: "16px"
    }
  },
  {
    name: "Trong đó",
    field: "",
    child: [
      {
        name: "Nữ",
        field: "ytccNu",
        cellStyle: {
          fontSize: "16px"
        }
      },
      {
        name: "Tiến sỹ, CK II",
        field: "ytccTienSi",
        headerStyle: {
          minWidth: "120px"
        },
        cellStyle: {
          fontSize: "16px"
        }
      },
      {
        name: "Thạc sỹ, CKI",
        field: "ytccThacSi",
        headerStyle: {
          minWidth: "120px"
        },
        cellStyle: {
          fontSize: "16px"
        }
      },
      {
        name: "Đại học",
        field: "ytccDaiHoc",
        headerStyle: {
          minWidth: "120px"
        },
        cellStyle: {
          fontSize: "16px"
        }
      }
    ]
  },
  {
    name: "Tổng số Điều dưỡng",
    field: "tongSoDieuDuong",
    headerStyle: {
      minWidth: "120px"
    },
    cellStyle: {
      fontSize: "16px"
    }
  },
  {
    name: "Trong đó",
    field: "",
    child: [
      {
        name: "Nữ",
        field: "dieuDuongNu",
        cellStyle: {
          fontSize: "16px"
        }
      },
      {
        name: "Thạc sỹ",
        field: "dieuDuongThacSi",
        headerStyle: {
          minWidth: "120px"
        },
        cellStyle: {
          fontSize: "16px"
        }
      },
      {
        name: "Đại học",
        field: "dieuDuongDaiHoc",
        headerStyle: {
          minWidth: "120px"
        },
        cellStyle: {
          fontSize: "16px"
        }
      },
      {
        name: "Cao đẳng",
        field: "dieuDuongCaoDang",
        headerStyle: {
          minWidth: "120px"
        },
        cellStyle: {
          fontSize: "16px"
        }
      },
      {
        name: "Trung cấp",
        field: "dieuDuongTrungCap",
        headerStyle: {
          minWidth: "120px"
        },
        cellStyle: {
          fontSize: "16px"
        }
      },
      {
        name: "Sơ cấp",
        field: "dieuDuongSoCap",
        headerStyle: {
          minWidth: "120px"
        },
        cellStyle: {
          fontSize: "16px"
        }
      }
    ]
  },
  {
    name: "Tổng số Hộ sinh",
    field: "tongSoHoSinh",
    headerStyle: {
      minWidth: "120px"
    },
    cellStyle: {
      fontSize: "16px"
    }
  },
  {
    name: "Trong đó",
    field: "",
    child: [
      {
        name: "Nữ",
        field: "hoSinhNu",
        cellStyle: {
          fontSize: "16px"
        }
      },
      {
        name: "Thạc sỹ",
        field: "hoSinhThacSi",
        headerStyle: {
          minWidth: "120px"
        },
        cellStyle: {
          fontSize: "16px"
        }
      },
      {
        name: "Đại học",
        field: "hoSinhDaiHoc",
        headerStyle: {
          minWidth: "120px"
        },
        cellStyle: {
          fontSize: "16px"
        }
      },
      {
        name: "Cao đẳng",
        field: "hoSinhCaoDang",
        headerStyle: {
          minWidth: "120px"
        },
        cellStyle: {
          fontSize: "16px"
        }
      },
      {
        name: "Trung cấp",
        field: "hoSinhTrungCap",
        headerStyle: {
          minWidth: "120px"
        },
        cellStyle: {
          fontSize: "16px"
        }
      },
      {
        name: "Sơ cấp",
        field: "hoSinhSoCap",
        headerStyle: {
          minWidth: "120px"
        },
        cellStyle: {
          fontSize: "16px"
        }
      }
    ]
  },

  {
    name: "Tổng số Kỹ thuật Y",
    field: "tongSoKyThuatY",
    headerStyle: {
      minWidth: "120px"
    },
    cellStyle: {
      fontSize: "16px"
    }
  },
  {
    name: "Trong đó",
    field: "",
    child: [
      {
        name: "Nữ",
        field: "kyThuatYNu",
        headerStyle: {
          minWidth: "120px"
        },
        cellStyle: {
          fontSize: "16px"
        }
      },
      {
        name: "Thạc sỹ",
        field: "kyThuatYThacSi",
        headerStyle: {
          minWidth: "120px"
        },
        cellStyle: {
          fontSize: "16px"
        }
      },
      {
        name: "Đại học",
        field: "kyThuatYDaiHoc",
        headerStyle: {
          minWidth: "120px"
        },
        cellStyle: {
          fontSize: "16px"
        }
      },
      {
        name: "Cao đẳng",
        field: "kyThuatYCaoDang",
        headerStyle: {
          minWidth: "120px"
        },
        cellStyle: {
          fontSize: "16px"
        }
      },
      {
        name: "Trung cấp",
        field: "kyThuatYTrungCap",
        headerStyle: {
          minWidth: "120px"
        },
        cellStyle: {
          fontSize: "16px"
        }
      },
      {
        name: "Sơ cấp",
        field: "kyThuatYSoCap",
        headerStyle: {
          minWidth: "120px"
        },
        cellStyle: {
          fontSize: "16px"
        }
      }
    ]
  },
  {
    name: "Cán bộ dân số",
    field: "tongSoCanBoDanSo",
    headerStyle: {
      minWidth: "120px"
    },
    cellStyle: {
      fontSize: "16px"
    }
  },
  {
    name: "Trong đó",
    field: "",
    child: [
      {
        name: "Nữ",
        field: "canBoDanSoNu",
        headerStyle: {
          minWidth: "120px"
        },
        cellStyle: {
          fontSize: "16px"
        }
      },
      {
        name: "Thạc sỹ",
        field: "canBoDanSoThacSi",
        headerStyle: {
          minWidth: "120px"
        },
        cellStyle: {
          fontSize: "16px"
        }
      },
      {
        name: "Đại học",
        field: "canBoDanSoDaiHoc",
        headerStyle: {
          minWidth: "120px"
        },
        cellStyle: {
          fontSize: "16px"
        }
      },
      {
        name: "Cao đẳng",
        field: "canBoDanSoCaoDang",
        headerStyle: {
          minWidth: "120px"
        },
        cellStyle: {
          fontSize: "16px"
        }
      },
      {
        name: "Trung cấp",
        field: "canBoDanSoTrungCap",
        headerStyle: {
          minWidth: "120px"
        },
        cellStyle: {
          fontSize: "16px"
        }
      },
      {
        name: "Sơ cấp",
        field: "canBoDanSoSoCap",
        headerStyle: {
          minWidth: "120px"
        },
        cellStyle: {
          fontSize: "16px"
        }
      }
    ]
  },
  {
    name: "Chức danh khác",
    field: "tongSoChucDanhKhac",
    headerStyle: {
      minWidth: "120px"
    },
    cellStyle: {
      fontSize: "16px"
    }
  },
  {
    name: "Trong đó",
    field: "",
    child: [
      {
        name: "Nữ",
        field: "chucDanhKhacNu",
        headerStyle: {
          minWidth: "120px"
        },
        cellStyle: {
          fontSize: "16px"
        }
      },
      {
        name: "Thạc sỹ",
        field: "chucDanhKhacThacSi",
        headerStyle: {
          minWidth: "120px"
        },
        cellStyle: {
          fontSize: "16px"
        }
      },
      {
        name: "Đại học",
        field: "chucDanhKhacDaiHoc",
        headerStyle: {
          minWidth: "120px"
        },
        cellStyle: {
          fontSize: "16px"
        }
      },
      {
        name: "Cao đẳng",
        field: "chucDanhKhacCaoDang",
        headerStyle: {
          minWidth: "120px"
        },
        cellStyle: {
          fontSize: "16px"
        }
      },
      {
        name: "Trung cấp",
        field: "chucDanhKhacTrungCap",
        headerStyle: {
          minWidth: "120px"
        },
        cellStyle: {
          fontSize: "16px"
        }
      },
      {
        name: "Sơ cấp, khác",
        field: "chucDanhKhacSoCap",
        headerStyle: {
          minWidth: "120px"
        },
        cellStyle: {
          fontSize: "16px"
        }
      }
    ]
  }
];

export const columnSalaryFund: IColumns[] = [
  {
    name: "STT",
    field: "stt",
    render: (row, index, stt) => <span>{stt}</span>,
    isSticky: true
  },
  {
    name: "Tên đơn vị",
    field: "name",
    headerStyle: {
      minWidth: "250px"
    },
    cellStyle: {
      textAlign: "left"
    },
    isSticky: true
  },
  {
    name: "Năm sinh",
    field: "",
    child: [
      {
        name: "Nam",
        field: "maleBirthdate",
        headerStyle: {
          minWidth: "100px"
        },
        type: TYPE.DATE
      },
      {
        name: "Nữ",
        field: "femaleBirthdate",
        headerStyle: {
          minWidth: "100px"
        },
        type: TYPE.DATE
      }
    ]
  },
  {
    name: "Ngày tháng năm tuyển dụng",
    field: "ngayTuyenDung",
    headerStyle: {
      minWidth: "150px"
    },
    type: TYPE.DATE
  },
  {
    name: "Tên chức danh nghề nghiệp",
    field: "tenChucDanh",
    headerStyle: {
      minWidth: "250px"
    },
    cellStyle: {
      textAlign: "left"
    }
  },
  {
    name: "Mã số chức danh",
    field: "maSoChucDanh",
    headerStyle: {
      minWidth: "150px"
    },
    cellStyle: {
      textAlign: "left"
    }
  },
  {
    name: "Hệ số lương",
    field: "",
    child: [
      {
        name: "Hệ số lương theo ngạch bậc",
        field: "heSoBacLuong",
        headerStyle: {
          minWidth: "150px"
        },
        type: TYPE.NUMBER_FLOAT
      },
      {
        name: "PCTNVK/bảo lưu",
        field: "tienPhuCapTnvk",
        headerStyle: {
          minWidth: "150px"
        },
        type: TYPE.MONEY
      },
      {
        name: "Hệ số phụ cấp",
        field: "",
        child: [
          {
            name: "Chức vụ",
            field: "tienPhuCapChucVu",
            headerStyle: {
              minWidth: "120px"
            },
            type: TYPE.MONEY
          },
          {
            name: "Trách nhiệm",
            field: "tienPhuCapTrachNhiem",
            headerStyle: {
              minWidth: "120px"
            },
            type: TYPE.MONEY
          },
          {
            name: "Độc hại",
            field: "tienPhuCapDocHai",
            headerStyle: {
              minWidth: "120px"
            },
            type: TYPE.MONEY
          },
          {
            name: "PC ưu đãi",
            field: "tienPhuCapUuDai",
            headerStyle: {
              minWidth: "120px"
            },
            type: TYPE.MONEY
          },
          {
            name: "PC công vụ, PC & và CLBL",
            field: "tienPhuCapKhac",
            headerStyle: {
              minWidth: "200px"
            },
            type: TYPE.MONEY
          }
        ]
      }
    ]
  },
  {
    name: "Quỹ lương 1 tháng",
    field: "",
    child: [
      {
        name: "Tổng số",
        field: "tongSo",
        headerStyle: {
          minWidth: "100px"
        },
        type: TYPE.MONEY
      },
      {
        name: "Tiền lương theo ngạch bậc",
        field: "luongTheoNgachBac",
        headerStyle: {
          minWidth: "150px"
        },
        type: TYPE.MONEY
      },
      {
        name: "PCTNVK",
        field: "phuCapTnvk",
        headerStyle: {
          minWidth: "100px"
        },
        type: TYPE.MONEY
      },
      {
        name: "Phụ cấp",
        field: "",
        child: [
          {
            name: "Tổng số phụ cấp",
            field: "tongSoPhuCap",
            headerStyle: {
              minWidth: "160px"
            },
            type: TYPE.MONEY
          },
          {
            name: "Chia ra",
            field: "",
            child: [
              {
                name: "Chức vụ",
                field: "phuCapChucVu",
                headerStyle: {
                  minWidth: "120px"
                },
                type: TYPE.NUMBER_FLOAT
              },
              {
                name: "Trách nhiệm",
                field: "phuCapTrachNhiem",
                headerStyle: {
                  minWidth: "120px"
                },
                type: TYPE.NUMBER_FLOAT
              },
              {
                name: "Độc hại",
                field: "phuCapDocHai",
                headerStyle: {
                  minWidth: "120px"
                },
                type: TYPE.NUMBER_FLOAT
              },
              {
                name: "PC ưu đãi",
                field: "phuCapUuDai",
                headerStyle: {
                  minWidth: "120px"
                },
                type: TYPE.NUMBER_FLOAT
              },
              {
                name: "PC công vụ, PC &  và CLBL",
                field: "phuCapKhac",
                headerStyle: {
                  minWidth: "200px"
                },
                type: TYPE.NUMBER_FLOAT
              }
            ]
          }
        ]
      }
    ]
  },
  {
    name: "Ghi chú",
    field: "ghiChu",
    headerStyle: {
      minWidth: "100px"
    }
  }
];

export const columnSummaryOfViolations: IColumns[] = [
  {
    name: "STT",
    field: "",
    isSticky: true,
    render: (_row, _index, stt) => <span>{stt}</span>
  },
  {
    name: "Mã nhân viên",
    field: "maNhanVien",
    headerStyle: {
      minWidth: "120px"
    },
    isSticky: true
  },
  {
    name: "Họ và tên",
    field: "tenNhanVien",
    headerStyle: {
      minWidth: "180px"
    },
    cellStyle: {
      textAlign: "left"
    },
    isSticky: true
  },
  {
    name: "Ngày vi phạm",
    field: "ngayXayRa",
    headerStyle: {
      minWidth: "120px"
    },
    type: TYPE.DATE
  },
  {
    name: "Nội dung vi phạm",
    field: "noiDungViPham",
    headerStyle: {
      minWidth: "300px"
    },
    cellStyle: {
      textAlign: "left"
    }
  },
  {
    name: "Thiệt hại",
    field: "giaTriThietHai",
    headerStyle: {
      minWidth: "200px"
    },
    type: TYPE.MONEY
  },
  {
    name: "Số tiền trừ",
    field: "soTienViPham",
    headerStyle: {
      minWidth: "120px"
    },
    type: TYPE.MONEY
  },
  {
    name: "Ghi chú",
    field: "note",
    headerStyle: {
      minWidth: "200px"
    },
    cellStyle: {
      textAlign: "left"
    }
  }
];

export const columnWorkAccident: IColumns[] = [
  {
    name: "STT",
    field: "",
    isSticky: true,
    render: (_row, _index, stt) => <span>{stt}</span>
  },
  {
    name: "Mã nhân viên",
    field: "maNhanVien",
    headerStyle: {
      minWidth: "120px"
    }
  },
  {
    name: "Họ và tên",
    field: "tenNhanVien",
    headerStyle: {
      minWidth: "220px"
    },
    cellStyle: {
      textAlign: "left"
    }
  },
  {
    name: "Phòng/Ban",
    field: "phongBanText",
    headerStyle: {
      minWidth: "200px"
    },
    cellStyle: {
      textAlign: "left"
    }
  },
  {
    name: "Ngày xảy ra",
    field: "ngayXayRa",
    headerStyle: {
      minWidth: "120px"
    },
    type: TYPE.DATE
  },
  {
    name: "Tình hình vết thương",
    field: "noiDungViPham",
    headerStyle: {
      minWidth: "450px"
    },
    cellStyle: {
      textAlign: "left"
    }
  },
  {
    name: "Biện pháp xử lý",
    field: "",
    child: [
      {
        name: "Xử lý tại công ty",
        field: "xuLy",
        headerStyle: {
          minWidth: "300px"
        },
        cellStyle: {
          textAlign: "left"
        }
      },
      {
        name: "Chuyển viện xử lý",
        field: "chuyenVienXuLy",
        headerStyle: {
          minWidth: "300px"
        },
        cellStyle: {
          textAlign: "left"
        }
      }
    ]
  },
  {
    name: "Số tiền xử lý TN ban đầu",
    field: "soTienXuLyBanDau",
    headerStyle: {
      minWidth: "200px"
    },
    type: TYPE.MONEY
  },
  {
    name: "Số tiền BH tai nạn thanh toán",
    field: "soTienBhThanhToan",
    headerStyle: {
      minWidth: "120px"
    },
    type: TYPE.MONEY
  }
];

export const columnAnnualHealth: IColumns[] = [
  {
    name: "STT",
    field: "stt",
    isSticky: true,
    render: (_row, _index, stt) => <span>{stt}</span>
  },
  {
    name: "Mã nhân viên",
    field: "maNhanVien"
  },
  {
    name: "Họ và tên",
    field: "tenNhanVien",
    cellStyle: {
      textAlign: "left"
    }
  },
  {
    name: "Ngày khám",
    field: "ngayKham",
    type: TYPE.DATE
  },
  {
    name: "Số tiền khám",
    field: "soTienKham",
    type: TYPE.MONEY
  },
  {
    name: "Số tiền đơn vị hỗ trợ",
    field: "soTienDonViHoTro",
    type: TYPE.MONEY
  },
  {
    name: "Ghi chú",
    field: "ghiChu",
    headerStyle: {
      minWidth: 300
    },
    cellStyle: {
      textAlign: "left"
    }
  }
]