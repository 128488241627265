import { EmployeeProfile } from "../../../profile/models/ProfileModels";
import { IAuth, IRole, IUser, IuserByUnit } from "../../model/ManagerModel";

export const INIT_USER: IuserByUnit = {
  id: "",
  user: {
    username: "",
    password: "",
    confirmPassword: "",
    roles: []
  } as IUser,
  employee: {} as EmployeeProfile,
  organization: null,
  orgId: "",
  createDate: "",
  createdBy: "",
  modifiedBy: "",
  modifyDate: ""
};

export const INIT_AUTH: IAuth = {
  id: "",
  orgId: "",
  name: "",
  code: ""
};

export const INIT_ROLE: IRole = {
  id: "",
  orgId: "",
  name: "",
  description: "",
  authorities: null
};
