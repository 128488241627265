import axios from "axios";
import { paramsConfig } from "../../utils/ParamsUtils";
import { IDispatchBook } from "../models/dispatchBookModel";

const API_URL = process.env.REACT_APP_API_URL;

export const addNewDispatchBook = (data: IDispatchBook) => {
  const url = API_URL + "/so-cong-van";
  return axios.post(url, data);
}

export const updateDispatchBook = (data: IDispatchBook, id: string) => {
  const url = `${API_URL}/so-cong-van/${id}`;
  return axios.put(url, data);
}

export const searchDispatchBook = (searchData?: object) => {
  let url = `${API_URL}/so-cong-van/page`;
  return axios.get(url, paramsConfig(searchData || {}));
};

export const deleteDispatchBook = (ids: string[] | string) => {
  let url = `${API_URL}/so-cong-van/ids`;
  return axios.delete(url, paramsConfig({ids: ids.toString()}));
};