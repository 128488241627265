/* eslint-disable react-hooks/exhaustive-deps */
import { useFormik } from "formik";
import { FC, useContext, useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import * as Yup from "yup";
import AppContext from "../../../AppContext";
import useMultiLanguage from "../../../hook/useMultiLanguage";
import UploadFile from "../../component/FileUpload/UploadFile";
import { FILE_TYPE } from "../../component/FileUpload/constant";
import { GroupButton } from "../../component/GroupButton";
import SelectEmployeeByDepartment from "../../component/SelectEmployeeByDepartment/SelectEmployeeByDepartment";
import SelectEmployeeDialog from "../../component/SelectEmployeeDialog/SelectEmployeeDialog";
import Autocomplete from "../../component/input-field/Autocomplete";
import TextValidator from "../../component/input-field/TextValidator";
import TableCollapseCustom, { columnNamesType } from "../../component/table-collapse-custom/TableCollapseCustom";
import { TYPE_CATEGORY } from "../../constant";
import { searchNguoiDaiDienKy } from "../../contract/services/contractServices";
import AddAttributeValueDialog from "../../profile/component/Dialog/AddAttributeValueDialog";
import { IFile } from "../../profile/models/DialogModels";
import { getAllDonVi } from "../../profile/services/ProfileServices";
import { searchAllSimpleValue } from "../../utils/CategoryServices";
import { MISSION_STATUS, MISSION_STATUS_CODE, RESPONSE_STATUS_CODE, SEARCH_OBJECT_MAX_SIZE, VARIABLE_STRING } from "../../utils/Constant";
import { covertDateToString, exportToFile, handleBlurDate, justAllowNumber } from "../../utils/FunctionUtils";
import {
  INIT_BONUS_INFO,
  OBJECT_BONUS_CODE,
  bonusDecisionLevel,
  bonusEmulation,
  bonusObject,
  bonusType
} from "../consts/BonusConst";
import { BonusInfo } from "../model/BonusModel";
import { addNewBonus, getKhenThuongList, updateBonus } from "../services/BonusService";
import { convertDataSubmit, convertDataUI } from "../utils/utils";
import { checkInvalidDate } from "../../utils/ValidationSchema";
import { useIntl } from "react-intl";
import { exportKhenThuongChiTiet } from "../../utils/ExportFileServices";
import { EXPORT_FILE_NAME } from "../../../Constant";
import { KTSVG } from "../../../../_metronic/helpers";

interface IProps {
  bonusInfo: BonusInfo;
  listBonus: BonusInfo[];
  handleClose: () => void;
  handleCloseAndSearch: () => void;
  isView: boolean;
  setIsView: (data: any) => void;
}

const BonusDialog: FC<IProps> = (props) => {
  const { lang } = useMultiLanguage();
  const intl = useIntl();
  const { bonusInfo, handleClose, handleCloseAndSearch, isView, setIsView } = props;
  const [shouldOpenBonusListDialog, setShouldOpenBonusListDialog] = useState(false);
  const [shouldOpenBonusListSigleDialog, setShouldOpenBonusListSigleDialog] = useState(false);
  const [employeeSelected, setEmployeeSelected] = useState<any[]>([]);
  const [shouldOpenAddFormOfDialog, setShouldOpenAddFormOfDialog] = useState(false);
  const [dataChecked, setDataChecked] = useState<any[]>([]);
  const [isViewOnly, setIsViewOnly] = useState<boolean>(false);
  const { setPageLoading } = useContext(AppContext);

  useEffect(() => {
    if (bonusInfo?.id) {
      formik.setValues(convertDataUI(bonusInfo));
      setIsViewOnly(bonusInfo?.trangThai?.code === MISSION_STATUS_CODE.COMPLETED);
      getEmployeeSelected();
    } else {
      formik.setFieldValue(VARIABLE_STRING.TRANG_THAI, MISSION_STATUS[0]);
    }
  }, [bonusInfo]);

  const getEmployeeSelected = async () => {
    try {
      setPageLoading(true);
      const { data } = await getKhenThuongList(String(bonusInfo?.id));
      if (data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
        setEmployeeSelected(data?.data);
      }
      setPageLoading(false);
    } catch (error) {
      toast.error(lang("GENERAL.ERROR"));
      setPageLoading(false);
    }
  };

  const handleCloseBonusListDialog = () => {
    setShouldOpenBonusListDialog(false);
  };

  const handleOpenBonusListDialog = () => {
    if (!formik.values?.doiTuongKhenThuong?.code) return toast.warning(lang("TOAST.SELECT_OBJECT_BONUS"));
    formik.values?.doiTuongKhenThuong?.code === OBJECT_BONUS_CODE.GROUP
      ? setShouldOpenBonusListDialog(true)
      : setShouldOpenBonusListSigleDialog(true);
  };

  const validationSchema = Yup.object().shape({
    doiTuongKhenThuong: Yup.object().required(lang("VALIDATION.REQUIRE")).nullable(),
    dotKhenThuong: Yup.string().required(lang("VALIDATION.REQUIRE")).max(255, lang("VALIDATION.MAX255")).nullable(),
    loaiKhenThuong: Yup.object().required(lang("VALIDATION.REQUIRE")).nullable(),
    hinhThucKhenThuong: Yup.object().required(lang("VALIDATION.REQUIRE")).nullable(),
    capQuyetDinh: Yup.object().required(lang("VALIDATION.REQUIRE")).nullable(),
    namKhenThuong: Yup.number()
      .required(lang("VALIDATION.REQUIRE"))
      .min(1900, lang("VALIDATION.MIN_YEAR"))
      .max(9999, lang("VALIDATION.MAX_YEAR"))
      .nullable(),
    ngayKhenThuong: Yup.date().concat(checkInvalidDate(intl)).required(lang("VALIDATION.REQUIRE")),
    thoiGianHuongLuongKeoDai: Yup.number()
      .when("ahtgDieuChinhLuong", {
        is: (ahtgDieuChinhLuong: boolean) => ahtgDieuChinhLuong,
        then: Yup.number().required(lang("VALIDATION.REQUIRE")).nullable(),
      })
      .nullable(),
  });

  const handleSubmit = async (values: any) => {
    if (!(employeeSelected?.length > 0)) return toast.warning(lang("TOAST.SELECT_LIST_BONUS"));
    if (
      !(employeeSelected?.length > 1) &&
      !(employeeSelected[0]?.listEmployee?.length > 1) &&
      formik.values?.doiTuongKhenThuong?.code === OBJECT_BONUS_CODE.GROUP
    )
      return toast.warning(lang("TOAST.GROUP_BIGEST_TWO"));
    try {
      setPageLoading(true);
      const dataSubmit = convertDataSubmit(values, employeeSelected);
      const { data } = bonusInfo?.id ? await updateBonus(bonusInfo.id, dataSubmit) : await addNewBonus(dataSubmit);
      if (data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
        let message = bonusInfo?.id ? "TOAST.EDIT.SUCCESS" : "TOAST.CREATE.SUCCESS";
        toast.success(lang(message));
        handleCloseAndSearch();
      } else {
        toast.error(`${data?.message}`);
      }
    } catch (err) {
      toast.error(lang("GENERAL.ERROR"));
    } finally {
      setPageLoading(false);
    }
  };

  const formik = useFormik({
    initialValues: INIT_BONUS_INFO,
    onSubmit: handleSubmit,
    validationSchema
  });

  const handleChangeSelect = (name: string, value: any) => {
    switch (name) {
      case VARIABLE_STRING.DOI_TUONG_KHEN_THUONG:
        setEmployeeSelected([]);
        formik.setFieldValue(name, value);
        break;
      case VARIABLE_STRING.DANH_HIEU_THI_DUA:
        formik.setValues({
          ...formik.values,
          [name]: value,
          ahtgDieuChinhLuong: Boolean(value.mappingName),
          thoiGianHuongLuongKeoDai: null
        })
        break;
      default:
        formik.setFieldValue(name, value);  
    }
  };

  const handleCheckBoxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;
    if(name === VARIABLE_STRING.ANH_HUONG_THOI_GIAN_DIEU_CHINH_LUONG) {
      formik.setValues({
        ...formik.values,
        [name]: checked,
        thoiGianHuongLuongKeoDai: null
      });
    } else {
      formik.setFieldValue(name, checked);
    }
  };

  const handleCloseAttributeDialog = () => {
    setShouldOpenAddFormOfDialog(false);
  };

  const handleSaveFormOfItem = (newItem: any) => {
    handleChangeSelect(VARIABLE_STRING.HINH_THUC_KHEN_THUONG, newItem);
    handleCloseAttributeDialog();
  };

  const handleSaveEmployeeSingle = (employee: any) => {
    const convertDataTable = [
      {
        phongBanId: employee[0]?.phongBanId || "",
        phongBanText: employee[0]?.phongBanText || "",
        listEmployee: [{ ...employee[0], isChecked: false }]
      }
    ];
    setEmployeeSelected(convertDataTable);
  };

  const handleSetFile = (data: IFile) => {
    formik.setFieldValue("fileId", data.id);
    formik.setFieldValue("fileName", data.name);
  };

  const handleDeleteEmployeeSelect = () => {
    const newSelectedData = employeeSelected.map((row: any) => {
      if (row?.isParentChecked) return null;
      return {
        ...row,
        listEmployee: (row?.listEmployee || []).filter(
          (oldItem: any) => !dataChecked?.some((removeItem: any) => oldItem?.employeeId === removeItem?.employeeId)
        )
      };
    });

    setEmployeeSelected(newSelectedData.filter((item: any) => item !== null));
    setDataChecked([]);
  };

  const columns: columnNamesType[] = [
    {
      name: lang("TABLE.INDEX"),
      field: "index",
      headerCellProps: {
        minWidth: 50
      },
      bodyCellProps: {
        textAlign: "center"
      },
      render: (row: any, index: number) => <span>{index + 1}</span>
    },
    {
      name: lang("INPUT.CODE"),
      field: "maNhanVien",
      headerCellProps: {
        minWidth: 80
      },
      bodyCellProps: {
        textAlign: "center"
      },
      render: (row: any) => <span className="color-steel-blue fw-600">{row?.maNhanVien}</span>
    },
    {
      name: lang("INPUT.FULLNAME"),
      field: "name",
      headerCellProps: {
        minWidth: 200
      }
    },
    {
      name: lang("PROFILE.ROLE"),
      field: "chucVuText",
      headerCellProps: {
        minWidth: 250
      }
    },
    {
      name: lang("GENERAL.EMPLOYEE.JOB_POSITION"),
      field: "viTriCongViecText",
      headerCellProps: {
        minWidth: 150
      }
    }
  ];

  const handleChangePositiveNumber = (event: React.ChangeEvent<any>) => {
    if(!event.target?.value?.startsWith("0")) {
      formik.handleChange(event);
    }
  };

  const handleExport = () => {
    exportToFile({
      exportAPI: () => exportKhenThuongChiTiet(bonusInfo.id || ""),
      fileName: EXPORT_FILE_NAME.CHI_TIET_KHEN_THUONG, 
      setPageLoading
    })
  }

  return (
    <div className="spaces overflow-y-auto overflow-x-hidden bg-transparent">
      <Form
        onSubmit={formik.handleSubmit}
        className="h-full"
      >
        <div className="flex-column min-h-full">
          <div className="spaces flex flex-middle flex-space-between py-12 color-primary text-uppercase">
            <GroupButton
              type="btn-back"
              handleClose={handleClose}
            />
            {/* {!isViewOnly && ( */}
              <div className="flex">
                {bonusInfo?.id && (
                  <button
                    className="spaces flex flex-middle table-btn outline mr-16"
                    onClick={handleExport}
                    type="button"
                  >
                    <KTSVG path="/media/icons/export-excel.svg" />{" "}
                    {lang("BTN.EXPORT")}
                  </button>
                )}
                {isView ? (
                  <GroupButton
                    type="btn-edit"
                    handleSaveEdit={() => setIsView(false)}
                  />
                ) : (
                  <button
                    className="button-primary btn-sm mr-16"
                    type="submit"
                  >
                    {lang("BTN.SAVE")}
                  </button>
                )}
              </div>
            {/* )} */}
          </div>
          <div className="flex-column flex-1 bg-white spaces p-13">
            <Row>
              <Col xs={12} className="sub-title-form">{bonusInfo?.id ? lang("APPRECIATIVE.LIST.EDIT") : lang("APPRECIATIVE.LIST.ADD")}</Col>
              <Col xs={12} ><span className="fs-3 fw-bold">{lang("GENERAL.APPRECIATIVE")}</span></Col>
              <Col xs={12}>
                <Row className="pt-2 gap-col-modal">
                  <Col xs={2} md={4} lg={3} xxl={2}>
                    <Autocomplete
                      isRequired
                      name="doiTuongKhenThuong"
                      lable={lang("APPRECIATIVE.DOITUONGKHENTHUONGTEXT")}
                      isReadOnly={isView}
                      value={formik.values?.doiTuongKhenThuong || null}
                      errors={formik.errors?.doiTuongKhenThuong}
                      touched={formik.touched?.doiTuongKhenThuong}
                      onChange={(value) => handleChangeSelect(VARIABLE_STRING.DOT_KHEN_THUONG, value)}
                      options={bonusObject}
                    />
                  </Col>
                  <Col xs={2} md={4} lg={3} xxl={2}>
                    <TextValidator
                      isRequired
                      name="dotKhenThuong"
                      lable={lang("APPRECIATIVE.NAME")}
                      readOnly={isView}
                      type="text"
                      value={formik.values?.dotKhenThuong || ""}
                      errors={formik.errors?.dotKhenThuong}
                      touched={formik.touched?.dotKhenThuong}
                      onChange={formik.handleChange}
                    />
                  </Col>
                  <Col xs={2} md={4} lg={3} xxl={2}>
                    <Autocomplete
                      isRequired
                      name="loaiKhenThuong"
                      lable={lang("APPRECIATIVE.LOAIKHENTHUONGTEXT")}
                      isReadOnly={isView}
                      value={formik.values?.loaiKhenThuong || null}
                      errors={formik.errors?.loaiKhenThuong}
                      touched={formik.touched?.loaiKhenThuong}
                      onChange={(value) => handleChangeSelect(VARIABLE_STRING.LOAI_KHEN_THUONG, value)}
                      options={bonusType}
                    />
                  </Col>
                  <Col xs={2} md={4} lg={3} xxl={2}>
                    <TextValidator
                      isRequired
                      name="namKhenThuong"
                      lable={lang("APPRECIATIVE.YEAR")}
                      readOnly={isView}
                      type="number"
                      value={formik.values?.namKhenThuong || ""}
                      onChange={formik.handleChange}
                      errors={formik.errors?.namKhenThuong}
                      touched={formik.touched?.namKhenThuong}
                    />
                  </Col>
                  <Col xs={2} md={4} lg={3} xxl={2}>
                    <Autocomplete
                      isRequired
                      name="hinhThucKhenThuong"
                      lable={lang("APPRECIATIVE.HINHTHUCKHENTHUONGTEXT")}
                      isReadOnly={isView}
                      value={formik.values?.hinhThucKhenThuong || null}
                      errors={formik.errors?.hinhThucKhenThuong}
                      touched={formik.touched?.hinhThucKhenThuong}
                      onChange={(value) => handleChangeSelect(VARIABLE_STRING.HINH_THUC_KHEN_THUONG, value)}
                      options={[]}
                      searchFunction={searchAllSimpleValue}
                      searchObject={{ type: TYPE_CATEGORY.hinhThucKhenThuong }}
                      getOptionLabel={(options) => options?.value}
                      isAddNew={true}
                      onAddNew={() => setShouldOpenAddFormOfDialog(true)}
                    />
                  </Col>
                  <Col xs={2} md={4} lg={3} xxl={2}>
                    <TextValidator
                      name="noiDungKhenThuong"
                      lable={lang("APPRECIATIVE.CONTENT")}
                      readOnly={isView}
                      type="text"
                      value={formik.values?.noiDungKhenThuong || ""}
                      onChange={formik.handleChange}
                    />
                  </Col>
                  <Col xs={2} md={4} lg={3} xxl={2}>
                    <Autocomplete
                      lable={lang("BONUS.EMULATION")}
                      value={formik.values?.danhHieuThiDua}
                      name={VARIABLE_STRING.DANH_HIEU_THI_DUA}
                      onChange={(selectedOption) => handleChangeSelect(VARIABLE_STRING.DANH_HIEU_THI_DUA, selectedOption)}
                      isReadOnly={isView}
                      options={bonusEmulation}
                      searchFunction={searchAllSimpleValue}
                      searchObject={{ ...SEARCH_OBJECT_MAX_SIZE, type: TYPE_CATEGORY.danhHieu }}
                      getOptionLabel={(options) => options?.value}
                    />
                  </Col>
                  <Col xs={2} md={4} lg={3} xxl={2}>
                    <Autocomplete
                      isRequired
                      name="capQuyetDinh"
                      lable={lang("APPRECIATIVE.DECISION_LEVEL")}
                      isReadOnly={isView}
                      value={formik.values?.capQuyetDinh || null}
                      onChange={(value) => handleChangeSelect(VARIABLE_STRING.CAP_QUYET_DINH, value)}
                      options={bonusDecisionLevel}
                      errors={formik.errors?.capQuyetDinh}
                      touched={formik.touched?.capQuyetDinh}
                    />
                  </Col>
                  <Col xs={2} md={4} lg={3} xxl={2}>
                    <TextValidator
                      name="ngayBanHanh"
                      lable={lang("APPRECIATIVE.NGAYQUYETDINH")}
                      readOnly={isView}
                      type="date"
                      value={formik.values?.ngayBanHanh || ""}
                      onChange={formik.handleChange}
                      onBlur={() => handleBlurDate(formik.setFieldValue, formik.values?.ngayBanHanh, "ngayBanHanh")}
                    />
                  </Col>
                  <Col xs={2} md={4} lg={3} xxl={2}>
                    <TextValidator
                      isRequired
                      name="ngayKhenThuong"
                      lable={lang("APPRECIATIVE.DATE")}
                      readOnly={isView}
                      type="date"
                      value={covertDateToString(formik.values?.ngayKhenThuong)}
                      onChange={formik.handleChange}
                      onBlur={() =>
                        handleBlurDate(formik.setFieldValue, formik.values?.ngayKhenThuong, "ngayKhenThuong")
                      }
                      errors={formik.errors?.ngayKhenThuong}
                      touched={formik.touched?.ngayKhenThuong}
                    />
                  </Col>
                  <Col xs={2} md={4} lg={3} xxl={2}>
                    <TextValidator
                      name="soQuyetDinh"
                      lable={lang("APPRECIATIVE.SOQUYETDINH")}
                      readOnly={isView}
                      type="text"
                      value={formik.values?.soQuyetDinh || ""}
                      onChange={formik.handleChange}
                    />
                  </Col>
                  <Col xs={2} md={4} lg={3} xxl={2}>
                    <Autocomplete
                      name="donViBanHanh"
                      lable={lang("APPRECIATIVE.AGENCIES_UNIT_DECITION")}
                      isReadOnly={isView}
                      value={formik.values?.donViBanHanh || null}
                      onChange={(value) => handleChangeSelect(VARIABLE_STRING.DON_VI_BAN_HANH, value)}
                      options={[]}
                      searchFunction={getAllDonVi}
                      searchObject={SEARCH_OBJECT_MAX_SIZE}
                    />
                  </Col>
                  <Col xs={6} lg={5} xxl={3}>
                    <Row>
                      <Col xs={6}>
                        <Form.Check
                          label={lang("APPRECIATIVE.SALARY_AUJUSTED")}
                          name="daXetDieuChinhLuong"
                          checked={formik.values?.daXetDieuChinhLuong}
                          disabled={isView}
                          onChange={handleCheckBoxChange}
                          className="checkBox custom-form-check"
                        />
                      </Col>
                      <Col xs={6}>
                        <Form.Check
                          label={lang("APPRECIATIVE.MAX")}
                          name="khenThuongCaoNhat"
                          checked={formik.values?.khenThuongCaoNhat}
                          disabled={isView}
                          onChange={handleCheckBoxChange}
                          className="checkBox custom-form-check"
                        />
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={6} lg={7} xxl={5}>
                    <Row>
                      <Col xs={6} lg={6} xxl={6}>
                        <Form.Check
                          label={lang("APPRECIATIVE.SALARY_AUJUSTMENT_TIME")}
                          name="ahtgDieuChinhLuong"
                          checked={formik.values?.ahtgDieuChinhLuong}
                          disabled={isView}
                          onChange={handleCheckBoxChange}
                          className="checkBox custom-form-check"
                        />
                      </Col>
                      <Col xs={6} lg={6} xxl={6}>
                        <TextValidator
                          isRequired={formik.values?.ahtgDieuChinhLuong}
                          name="thoiGianHuongLuongKeoDai"
                          lable={lang("APPRECIATIVE.SALARY_AFFECT_TIME")}
                          readOnly={isView || !formik.values?.ahtgDieuChinhLuong}
                          type="number"
                          value={formik.values?.thoiGianHuongLuongKeoDai || ""}
                          onChange={handleChangePositiveNumber}
                          onKeyDown={justAllowNumber}
                          errors={formik.errors?.thoiGianHuongLuongKeoDai}
                          touched={formik.touched?.thoiGianHuongLuongKeoDai}
                        />
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={2} md={4} lg={4} xxl={2}>
                    <Autocomplete
                      name="trangThai"
                      lable={lang("APPRECIATIVE.TRANGTHAITEXT")}
                      isReadOnly={isViewOnly ? isViewOnly : isView}
                      value={formik.values?.trangThai || null}
                      onChange={(value) => handleChangeSelect(VARIABLE_STRING.TRANG_THAI, value)}
                      options={MISSION_STATUS}
                    />
                  </Col>
                  <Col xs={2} md={4} lg={4} xxl={2}>
                    <Autocomplete
                      name="nguoiKyQuyetDinh"
                      lable={lang("APPRECIATIVE.PERSON_SINGING")}
                      isReadOnly={isView}
                      value={formik.values?.nguoiKyQuyetDinh || null}
                      onChange={(value) => handleChangeSelect(VARIABLE_STRING.NGUOI_KY_QUYET_DINH, value)}
                      options={[]}
                      searchFunction={searchNguoiDaiDienKy}
                      searchObject={{}}
                    />
                  </Col>
                  <Col xs={2} md={4} lg={4} xxl={2}>
                    <UploadFile
                      isReadOnly={isView}
                      label="CONTRACT.ATTACHMENTS"
                      setValue={handleSetFile}
                      fileValue={{
                        id: formik.values?.fileId || "",
                        name: formik.values?.fileName || ""
                      }}
                      allowFileTypes={`${FILE_TYPE.PDF},${FILE_TYPE.DOC},${FILE_TYPE.DOCX},${FILE_TYPE.XLS},${FILE_TYPE.XLSX}`}
                    />
                  </Col>
                  <Col xs={12} xxl={10}>
                    <TextValidator
                      rows="1"
                      name="ghiChu"
                      lable={lang("ALLOWANCE.NOTE")}
                      as="textarea"
                      readOnly={isView}
                      value={formik.values?.ghiChu || ""}
                      onChange={formik.handleChange}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col xs={12} className="spaces mt-24">
                <TableCollapseCustom
                  title={lang("GENERAL.APPRECIATIVE.LIST")}
                  columnNameList={columns}
                  data={employeeSelected}
                  nameParent="phongBanText"
                  nameChildren="listEmployee"
                  selectData={setDataChecked}
                  setData={setEmployeeSelected}
                  selectionMode="multiple"
                  isSelect={true}
                  buttonAdd={!isView}
                  handleOpenDialog={handleOpenBonusListDialog}
                  handleDelete={handleDeleteEmployeeSelect}
                  notDelete={isView}
                  uniquePrefix="employeeId"
                  disabledSelect={isView}
                />
              </Col>

              {shouldOpenBonusListDialog && (
                <SelectEmployeeByDepartment
                  title={lang("GENERAL.LIST_EMPLOYEE_BY_DEPARTMENT")}
                  open={shouldOpenBonusListDialog}
                  handleClose={handleCloseBonusListDialog}
                  setParentSelectedData={setEmployeeSelected}
                  parentSelectedData={employeeSelected}
                />
              )}

              {shouldOpenBonusListSigleDialog && (
                <SelectEmployeeDialog
                  open={shouldOpenBonusListSigleDialog}
                  handleClose={() => setShouldOpenBonusListSigleDialog(false)}
                  handleSaveData={handleSaveEmployeeSingle}
                  dataParent={employeeSelected[0]?.listEmployee || []}
                />
              )}

              {shouldOpenAddFormOfDialog && (
                <AddAttributeValueDialog
                  onClose={handleCloseAttributeDialog}
                  handleSaveItem={handleSaveFormOfItem}
                  title={"GENERAL.ADD_FORM_OF_BONUS"}
                  typeContent={TYPE_CATEGORY.hinhThucKhenThuong}
                />
              )}
            </Row>
          </div>
        </div>
      </Form>
    </div>
  );
};
export default BonusDialog;
