import { ILang } from "../../../hook/useMultiLanguage";
import { OptionReactSelect } from "../../models/models";
import { getAllDonVi, getAllEmployee } from "../../profile/services/ProfileServices";
import { DisciplineInfo } from "../model/DisciplineModel";
import { SEARCH_OBJECT_MAX_SIZE, TYPE } from "../../utils/Constant";
import { searchNguoiDaiDienKy } from "../../contract/services/contractServices";

export const INIT_DISCIPLINE_INFO: DisciplineInfo = {
  id: "",
  loaiKyLuat: null,
  capQuyetDinh: null,
  hanhVi: "",
  hinhThucKyLuat: null,
  ahtgDieuChinhLuong: false,
  thoiGianKeoDaiDieuChinhLuong: null,
  kyLuatCaoNhat: false,
  tuNgay: "",
  denNgay: "",
  soQuyetDinh: "",
  ghiChu: "",
  file: "",
  trangThai: null,
  employeeKyLuats: null,
  coQuanQuyetDinhId: "",
  coQuanQuyetDinhText: "",
  nguoiKyQuyetDinhId: "",
  nguoiKyQuyetDinhText: "",
  nguoiKyQuyetDinh: null,
  coQuanQuyetDinh: null,
  fileId: "",
  fileName: ""
};

export const decisionLevels: OptionReactSelect[] = [
  {
    code: 1,
    name: "Cấp nhà nước"
  },
  {
    code: 2,
    name: "Cấp Bộ"
  },
  {
    code: 3,
    name: "Cấp Tỉnh"
  },
  {
    code: 4,
    name: "Cấp Huyện"
  },
  {
    code: 5,
    name: "Cấp cơ sở"
  },
  {
    code: 6,
    name: "Khác"
  }
];

export const disciplineFormOfs: OptionReactSelect[] = [
  {
    code: 1,
    name: "Khiển trách"
  },
  {
    code: 2,
    name: "Cảnh cáo"
  },
  {
    code: 3,
    name: "Cách chức"
  },
  {
    code: 4,
    name: "Bãi nhiệm"
  },
  {
    code: 5,
    name: "Hạ bậc lương"
  },
  {
    code: 6,
    name: "Giáng chức"
  },
  {
    code: 7,
    name: "Buộc thôi việc"
  },
  {
    code: 8,
    name: "Khác"
  },
  {
    code: 9,
    name: "Chưa xác định"
  }
];

export const DISCIPLINE_STATUS_CODE = {
  UNFULFILLED: 1,
  PROCESSING: 2,
  COMPLETED: 3
};

export const disciplineStatus = [
  {
    name: "Chưa xử lý",
    code: DISCIPLINE_STATUS_CODE.UNFULFILLED,
    styleClass: "bg-gray-700"
  },
  {
    name: "Đang xử lý",
    code: DISCIPLINE_STATUS_CODE.PROCESSING,
    styleClass: "bg-orange-dark"
  },
  {
    name: "Đã xử lý",
    code: DISCIPLINE_STATUS_CODE.COMPLETED,
    styleClass: "bg-green"
  }
];

export const disciplineType: OptionReactSelect[] = [
  {
    code: 1,
    name: "Đảng"
  },
  {
    code: 2,
    name: "Chính quyền"
  },
  {
    code: 3,
    name: "Đoàn thể"
  },
  {
    code: 4,
    name: "Khác"
  }
];

export const DISCIPLINE_AFFECT_SALARY_STATUS_CODE = [
  {
    code: 1,
    value: 3
  },
  { 
    code: 2,
    value: 6
  },
  { 
    code: 3,
    value: 12
  }
];

export enum AFFECT_SALARY {
  KHAC = 8
}

export const ListSearch = (lang: ILang) => {
  return [
    {
      name: lang("DISCIPLINE.TYPE"),
      field: "loaiKyLuat",
      type: TYPE.SELECT,
      listOption: disciplineType
    },
    {
      name: lang("DISCIPLINE.DECISION_LEVEL"),
      field: "capQuyetDinh",
      type: TYPE.SELECT,
      listOption: decisionLevels
    },
    {
      name: lang("DISCIPLINE.VIOLATION_BEHAVIOR"),
      field: "hanhVi",
      type: TYPE.TEXT
    },
    {
      name: lang("DISCIPLINE.FORM_OF"),
      field: "hinhThucKyLuat",
      type: TYPE.SELECT,
      listOption: disciplineFormOfs
    },
    {
      name: lang("APPRECIATIVE.PERSON_SINGING"),
      field: "nguoiKyQuyetDinhId",
      type: TYPE.SELECT,
      searchFunction: searchNguoiDaiDienKy,
      searchObject: { ...SEARCH_OBJECT_MAX_SIZE }
    },
    {
      name: lang("APPRECIATIVE.AGENCIES_UNIT_DECITION"),
      field: "donViBanHanh",
      type: TYPE.SELECT,
      searchFunction: getAllDonVi,
      searchObject: { ...SEARCH_OBJECT_MAX_SIZE }
    },
    {
      name: lang("INPUT.EMPLOYEE"),
      field: "employeeId",
      type: TYPE.SELECT,
      searchFunction: getAllEmployee,
      searchObject: { ...SEARCH_OBJECT_MAX_SIZE }
    },
    {
      name: lang("APPRECIATIVE.TRANGTHAITEXT"),
      field: "trangThai",
      type: TYPE.SELECT,
      listOption: disciplineStatus
    },
  ];
}