import axios, { AxiosResponse } from "axios";
import { APIResponse } from "../../models/models";
import { IConcurrently } from "../models/concurrentlyModel";
import { paramsConfig } from "../../utils/ParamsUtils";

const API_URL = process.env.REACT_APP_API_URL + "/kiem-nhiem";

export const getListConcurrently = (searchData?: any) => {
  const url = API_URL + "/page";
  return axios.get(url, paramsConfig(searchData));
};

export const addConcurrently = (concurrently: IConcurrently): Promise<AxiosResponse<APIResponse>> => {
  return axios.post(API_URL, concurrently);
};

export const updateConcurrently = (
  id: string,
  concurrently: IConcurrently
): Promise<AxiosResponse<APIResponse>> => {
  const url = `${API_URL}/${id}`;
  return axios.put(url, concurrently);
};

export const deleteConcurrently = (ids: string[]): Promise<AxiosResponse<APIResponse>> => {
  const url = `${API_URL}/ids?ids=${ids.toString()}`;
  return axios.delete(url);
};
