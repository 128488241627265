import { Modal, Button, Row, Col, Form } from "react-bootstrap";
import useMultiLanguage from "../../../../../hook/useMultiLanguage";
import Autocomplete from "../../../../component/input-field/Autocomplete";
import TextValidator from "../../../../component/input-field/TextValidator";
import UploadFile from "../../../../component/FileUpload/UploadFile";
import { FILE_TYPE } from "../../../../component/FileUpload/constant";
import { useEffect, useState } from "react";
import { DisciplineInfo } from "../../../../discipline/model/DisciplineModel";
import { INIT_DISCIPLINE_INFO } from "../../../../discipline/consts/DisciplineConsts";
import { RangeDatePicker } from "../../../../component/input-field/RangeDatePicker";
import { convertDataUI } from "../../../../discipline/utils/util";

interface IProps {
  open: boolean;
  handleClose: () => void;
  disciplineInfo: any;
  isView?: boolean;
}

function DisciplineViewDialog(props: IProps) {
  const { open, handleClose, isView = true, disciplineInfo } = props;
  const { lang } = useMultiLanguage();

  const [data, setData] = useState<DisciplineInfo>(INIT_DISCIPLINE_INFO);

  useEffect(() => {
    if (disciplineInfo?.id) {
      setData(convertDataUI(disciplineInfo));
    }
  }, [disciplineInfo]);

  return (
    <Modal
      show={open}
      onHide={handleClose}
      centered
      size="xl"
    >
      <Modal.Header
        closeButton
        className="spaces py-12"
      >
        <Modal.Title className="spaces fs-20 fw-600">{lang("DISCIPLINE.INFO")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row className="gap-col-modal">
          <Col xs={4}>
            <Autocomplete
              isRequired
              name="loaiKyLuat"
              lable={lang("DISCIPLINE.TYPE")}
              isReadOnly={isView}
              value={data?.loaiKyLuat || null}
              options={[]}
            />
          </Col>
          <Col xs={4}>
            <Autocomplete
              isRequired
              name="capQuyetDinh"
              lable={lang("DISCIPLINE.DECISION_LEVEL")}
              isReadOnly={isView}
              value={data?.capQuyetDinh || null}
              options={[]}
            />
          </Col>
          <Col xs={4}>
            <TextValidator
              isRequired
              name="hanhVi"
              lable={lang("DISCIPLINE.VIOLATION_BEHAVIOR")}
              readOnly={isView}
              type="text"
              value={data?.hanhVi || ""}
            />
          </Col>
          <Col xs={4}>
            <Autocomplete
              isRequired
              name="hinhThucKyLuat"
              lable={lang("DISCIPLINE.FORM_OF")}
              isReadOnly={isView}
              value={data?.hinhThucKyLuat || null}
              options={[]}
            />
          </Col>
          <Col xs={4}>
            <TextValidator
              isRequired
              name="soQuyetDinh"
              lable={lang("INPUT.WORKPROCESS.DECISION.NUMBER")}
              readOnly={isView}
              type="text"
              value={data?.soQuyetDinh || ""}
            />
          </Col>
          <Col xs={4}>
            <Autocomplete
              isRequired
              name="coQuanQuyetDinh"
              lable={lang("DISCIPLINE.ISSUING_AGENCY_UNIT")}
              isReadOnly={isView}
              value={data?.coQuanQuyetDinh || null}
              options={[]}
            />
          </Col>
          <Col xs={4}>
            <Form.Check
              label={lang("APPRECIATIVE.SALARY_AUJUSTMENT_TIME")}
              type="checkbox"
              className="checkBox custom-form-check"
              name="ahtgDieuChinhLuong"
              checked={data?.ahtgDieuChinhLuong}
            />
          </Col>
          <Col xs={4}>
            <TextValidator
              isRequired
              name="thoiGianKeoDaiDieuChinhLuong"
              lable={lang("APPRECIATIVE.SALARY_AUJUSTMENT_EXTENDED_TIME")}
              readOnly={isView}
              type="number"
              value={data?.thoiGianKeoDaiDieuChinhLuong || ""}
            />
          </Col>
          <Col xs={4}>
            <RangeDatePicker
              label={lang("GENERAL.TIME")}
              isRequired
              startDateName="tuNgay"
              endDateName="denNgay"
              isView={isView}
              value={data}
            />
          </Col>
          <Col xs={3}>
            <Form.Check
              label={lang("DISCIPLINE.MOST")}
              type="checkbox"
              className="checkBox custom-form-check"
              name="kyLuatCaoNhat"
              checked={data?.kyLuatCaoNhat}
            />
          </Col>
          <Col xs={3}>
            <Autocomplete
              name="nguoiKyQuyetDinh"
              lable={lang("APPRECIATIVE.PERSON_SINGING")}
              isReadOnly={isView}
              value={data?.nguoiKyQuyetDinh || null}
              options={[]}
            />
          </Col>
          <Col xs={3}>
            <UploadFile
              isReadOnly={isView}
              label="CONTRACT.ATTACHMENTS"
              setValue={() => {}}
              fileValue={{
                id: data?.fileId || "",
                name: data?.fileName || ""
              }}
              allowFileTypes={`${FILE_TYPE.PDF},${FILE_TYPE.DOC},${FILE_TYPE.DOCX},${FILE_TYPE.XLS},${FILE_TYPE.XLSX}`}
            />
          </Col>
          <Col xs={3}>
            <Autocomplete
              name="trangThai"
              lable={lang("GENERAL.STATUS")}
              isReadOnly={isView}
              value={data?.trangThai || null}
              options={[]}
            />
          </Col>
          <Col xs={12}>
            <TextValidator
              name="ghiChu"
              lable={lang("INPUT.WORKPROCESS.NOTE")}
              readOnly={isView}
              type="text"
              as="textarea"
              rows={3}
              value={data?.ghiChu || ""}
            />
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer className="flex-center">
        <Button
          variant="outline-secondary"
          className="button-secondary btn-sm"
          onClick={handleClose}
        >
          {lang("BTN.CANCEL")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default DisciplineViewDialog;
